export const getPefrCalculation = (height: any, value: any): any => {
  try {
    if (height) {
      const cal = height - 100,
        mul = cal * 5,
        pefr = mul + 100,
        half = pefr / 2,
        past = pefr / 5,
        eighty = past * 4;
      if (value >= eighty) {
        return 1;
      } else if (value >= half && value < eighty) {
        return 0.5;
      } else {
        return 0;
      }
    }
    return null;
  } catch (e) {
    throw new Error((e as any)?.message);
  }
};
