// TODO : remove this before making build - might be unstable
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes
} from '@material-ui/core';

import palette from './palette';
import overrides from './overrides';
import typography from './typography';

let theme = createMuiTheme({
  typography,
  palette,
  mixins: {
    toolbar: {
      minHeight: '72px',
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: '64px'
      },
      '@media (min-width:600px)': {
        minHeight: '80px'
      }
    }
  },
  /* @ts-ignore */
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});
theme = responsiveFontSizes(theme);

export default theme;
