import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as QRIconSvg} from '@assets/images/healpha/QR.svg';

const QRIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={QRIconSvg}
      viewBox="0 0 16 16"
    />
  );
};

export default QRIcon;
