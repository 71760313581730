import {
  APP_BOOKED_STATUS_COLOR,
  APP_BOOKED_STATUS_DOT_COLOR,
  APP_TRIAGE_STATUS_COLOR,
  APP_PRIMARY_COLOR,
  APP_CONFIRM_STATUS_COLOR,
  APP_CONFIRM_STATUS_DOT_COLOR,
  APP_COMPLETED_STATUS_COLOR,
  APP_COMPLETED_STATUS_DOT_COLOR,
  APP_RESCHEDULED_STATUS_COLOR,
  APP_RESCHEDULED_STATUS_DOT_COLOR,
  APP_RE_CONSULTING_STATUS_COLOR,
  APP_RE_CONSULTING_STATUS_DOT_COLOR,
  APP_CANCELLED_STATUS_COLOR_DOT_COLOR,
  APP_IN_PROCESS_STATUS_COLOR,
  APP_IN_PROCESS_STATUS_DOT_COLOR,
  APP_CANCELLED_STATUS__COLOR,
  APP_CLOSED_STATUS__COLOR,
  APP_CLOSED_STATUS_COLOR_DOT_COLOR,
  APP_CHECK_IN_STATUS_COLOR,
  APP_CHECK_IN_STATUS_DOT_COLOR,
  APP_CONSULTING_STATUS_COLOR,
  APP_CONSULTING_STATUS_DOT_COLOR,
  APP_UNDER_GOING_STATUS_COLOR,
  APP_UNDER_GOING_STATUS_DOT_COLOR,
  APP_PAID_STATUS_COLOR,
  APP_PAID_STATUS_DOT_COLOR,
  IN_PROGRESS,
  IN_PROGRESS_TEXT_COLOR,
  PAYMENT_DONE,
  PAYMENT_DONE_TEXT_COLOR,
  DEFAULT_WHITE_COLOR,
  IN_TRANSIT,
  ORDER_DECLINE,
  ORDER_DECLINE_TEXT_COLOR,
  PARTIAL_REPORTS,
  DEFAULT_BLACK_COLOR,
  SAMPLE_COLLECTED,
  PARTIALLY_COLLECTED,
  STATUS_COMPLETED_COLOR
} from 'src/style/variable';

export const statusColor = [
  {
    id: 1,
    name: 'Booked',
    color: APP_BOOKED_STATUS_COLOR,
    dotColor: APP_BOOKED_STATUS_DOT_COLOR
  },
  {
    id: 2,
    name: 'Triage',
    color: APP_TRIAGE_STATUS_COLOR,
    dotColor: APP_PRIMARY_COLOR
  },
  {
    id: 3,
    name: 'Completed',
    color: APP_COMPLETED_STATUS_COLOR,
    dotColor: APP_COMPLETED_STATUS_DOT_COLOR
  },
  {
    id: 4,
    name: 'Confirm',
    color: APP_CONFIRM_STATUS_COLOR,
    dotColor: APP_CONFIRM_STATUS_DOT_COLOR
  },
  {
    id: 5,
    name: 'Rescheduled',
    color: APP_RESCHEDULED_STATUS_COLOR,
    dotColor: APP_RESCHEDULED_STATUS_DOT_COLOR
  },
  {
    id: 6,
    name: 'Re Consulting',
    color: APP_RE_CONSULTING_STATUS_COLOR,
    dotColor: APP_RE_CONSULTING_STATUS_DOT_COLOR
  },
  {
    id: 7,
    name: 'Cancelled',
    color: APP_CANCELLED_STATUS__COLOR,
    dotColor: APP_CANCELLED_STATUS_COLOR_DOT_COLOR
  },
  {
    id: 8,
    name: 'Checkedin',
    color: APP_CHECK_IN_STATUS_COLOR,
    dotColor: APP_CHECK_IN_STATUS_DOT_COLOR
  },
  {
    id: 9,
    name: 'Closed',
    color: APP_CLOSED_STATUS__COLOR,
    dotColor: APP_CLOSED_STATUS_COLOR_DOT_COLOR
  },
  {
    id: 10,
    name: 'Consulting',
    color: APP_CONSULTING_STATUS_COLOR,
    dotColor: APP_CONSULTING_STATUS_DOT_COLOR
  },
  {
    id: 11,
    name: 'In Process',
    color: APP_IN_PROCESS_STATUS_COLOR,
    dotColor: APP_IN_PROCESS_STATUS_DOT_COLOR
  },
  {
    id: 12,
    name: 'Undergoing',
    color: APP_UNDER_GOING_STATUS_COLOR,
    dotColor: APP_UNDER_GOING_STATUS_DOT_COLOR
  },
  {
    id: 13,
    name: 'Paid',
    color: APP_PAID_STATUS_COLOR,
    dotColor: APP_PAID_STATUS_DOT_COLOR
  }
];

export const LabPharmacyStatusColor = [
  {
    id: 1,
    name: 'order_placed',
    label: 'In Progress',
    color: IN_PROGRESS,
    textColor: IN_PROGRESS_TEXT_COLOR
  },
  {
    id: 2,
    name: 'order_accepted',
    label: 'Payment Pending',
    color: IN_PROGRESS,
    textColor: IN_PROGRESS_TEXT_COLOR
  },
  {
    id: 3,
    name: 'paid',
    label: 'Payment Done',
    color: PAYMENT_DONE,
    textColor: PAYMENT_DONE_TEXT_COLOR
  },
  {
    id: 4,
    name: 'partially_collected',
    label: 'Partially Collected',
    color: PARTIALLY_COLLECTED,
    textColor: DEFAULT_BLACK_COLOR
  },
  {
    id: 5,
    name: 'sample_collected',
    label: 'Sample Collected',
    color: SAMPLE_COLLECTED,
    textColor: DEFAULT_BLACK_COLOR
  },
  {
    id: 6,
    name: 'order_decline',
    label: 'Order Decline',
    color: ORDER_DECLINE,
    textColor: ORDER_DECLINE_TEXT_COLOR
  },
  {
    id: 7,
    name: 'partial_reports',
    label: 'Partial Report',
    color: PARTIAL_REPORTS,
    textColor: DEFAULT_BLACK_COLOR
  },
  {
    id: 8,
    name: 'delivered',
    label: 'Order Fulfilled',
    color: APP_COMPLETED_STATUS_DOT_COLOR,
    textColor: DEFAULT_WHITE_COLOR
  },
  {
    id: 9,
    name: 'in_transit',
    label: 'Order Is On The Way',
    color: IN_TRANSIT,
    textColor: DEFAULT_WHITE_COLOR
  }
];

export const riskLevelsColors = [
  {
    id: 1,
    name: 'Low',
    color: STATUS_COMPLETED_COLOR
  },
  {
    id: 2,
    name: 'Medium',
    color: APP_IN_PROCESS_STATUS_DOT_COLOR
  },
  {
    id: 3,
    name: 'Critical',
    color: APP_BOOKED_STATUS_DOT_COLOR
  }
];
