import Storage from 'src/utils/Storage';

export default class UserStore {
  static KEYS = {
    DASHBOARD_INFO: 'DASHBOARD_INFO',
    USER_INFO: 'USER_INFO',
    PRACTICE_INFO: 'PRACTICE_INFO',
    PAYMENT_INFO: 'PAYMENT_INFO',
    BRANCH_INFO: 'BRANCH_INFO',
    DOCTOR_INFO: 'DOCTOR_INFO',
    IS_PROFILE_COMPLETED: 'IS_PROFILE_COMPLETED',
    IS_DOC_REG_COMPLETED: 'IS_DOC_REG_COMPLETED',
    IS_PRACTICE_SETUP_COMPLETED: 'IS_PRACTICE_SETUP_COMPLETED',
    IS_BRANCHES_SETUP_COMPLETED: 'IS_PRACTICE_SETUP_COMPLETED',
    SELF_SERVICE_STATUS_DETAILS: 'SELF_SERVICE_STATUS_DETAILS',
    BRANCH_DETAILS: 'BRANCH_DETAILS',
    APPOINTMENTS_VALUES: 'APPOINTMENTS_VALUES',
    PRACTICE_FLAG: 'PRACTICE_FLAG'
  };

  static clearUserInfo(): void {
    Storage.removeItem(UserStore.KEYS.USER_INFO);
  }

  static setUserInfo(data: any): void {
    this.clearUserInfo();
    Storage.setItem(UserStore.KEYS.USER_INFO, data);
  }

  static getUserInfo(): any {
    return Storage.getItem(UserStore.KEYS.USER_INFO, true);
  }

  static clearPracticeFlag(): void {
    Storage.removeItem(UserStore.KEYS.PRACTICE_FLAG);
  }

  static setPracticeFlag(flag: any): void {
    this.clearPracticeFlag();
    Storage.setItem(UserStore.KEYS.PRACTICE_FLAG, flag);
  }

  static getPracticeFlag(): any {
    return Storage.getItem(UserStore.KEYS.PRACTICE_FLAG, true);
  }

  static clearPracticeInfo(): void {
    Storage.removeItem(UserStore.KEYS.PRACTICE_INFO);
  }

  static setPracticeInfo(data: any): void {
    this.clearPracticeInfo();
    Storage.setItem(UserStore.KEYS.PRACTICE_INFO, data);
  }

  static getPracticeInfo(): any {
    Storage.getItem(UserStore.KEYS.PRACTICE_INFO, true);
  }

  static getBranchInfo(): any {
    return Storage.getItem(UserStore.KEYS.BRANCH_INFO, true);
  }

  static clearBranchInfo(): void {
    Storage.removeItem(UserStore.KEYS.BRANCH_INFO);
  }

  static setBranchInfo(data: any): void {
    this.clearBranchInfo();
    Storage.setItem(UserStore.KEYS.BRANCH_INFO, data);
  }

  static clearDashboardInfo(): void {
    Storage.removeItem(UserStore.KEYS.DASHBOARD_INFO);
  }

  static setDashboardInfo(data: any): void {
    this.clearDashboardInfo();
    Storage.setItem(UserStore.KEYS.DASHBOARD_INFO, data);
  }

  static getDashboardInfo(): any {
    return Storage.getItem(UserStore.KEYS.DASHBOARD_INFO, true);
  }

  static clearDoctorInfo(): void {
    Storage.removeItem(UserStore.KEYS.DOCTOR_INFO);
  }

  static setDoctorInfo(data: any): void {
    this.clearDoctorInfo();
    Storage.setItem(UserStore.KEYS.DOCTOR_INFO, data);
  }

  static getSelfServiceStatusDetails(): any {
    return Storage.getItem(UserStore.KEYS.SELF_SERVICE_STATUS_DETAILS, true);
  }

  static clearSelfServiceStatusDetails(): void {
    Storage.removeItem(UserStore.KEYS.SELF_SERVICE_STATUS_DETAILS);
  }

  static setSelfServiceStatusDetails(data: any): void {
    this.clearSelfServiceStatusDetails();
    Storage.setItem(UserStore.KEYS.SELF_SERVICE_STATUS_DETAILS, data);
  }

  static getDoctorInfo(): any {
    return Storage.getItem(UserStore.KEYS.DOCTOR_INFO, true);
  }

  static getBranchDetails(): any {
    return Storage.getItem(UserStore.KEYS.BRANCH_DETAILS, true);
  }

  static clearBranchDetails(): void {
    Storage.removeItem(UserStore.KEYS.BRANCH_DETAILS);
  }

  static setBranchDetails(branchDetails: any): void {
    this.clearBranchDetails();
    Storage.setItem(UserStore.KEYS.BRANCH_DETAILS, branchDetails);
  }

  static getAppointmentValues(): any {
    return Storage.getItem(UserStore.KEYS.APPOINTMENTS_VALUES, true);
  }

  static clearAppointmentValues(): void {
    Storage.removeItem(UserStore.KEYS.APPOINTMENTS_VALUES);
  }

  static setAppointmentValues(appointmentsValues: any): void {
    this.clearAppointmentValues();
    Storage.setItem(UserStore.KEYS.APPOINTMENTS_VALUES, appointmentsValues);
  }
}
