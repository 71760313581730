/* eslint-disable camelcase */
/* @ts-ignore */
import React, {useState, useEffect, useReducer} from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {Dialog} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {Stack, Modal} from '@material-ui/core';
import {appointmentSteps} from 'src/constants/Appointment';
import {
  deletePlanAttachments,
  getPatientCard,
  getVaccinesInformation
} from '@shared/services/PublicService';
import {QrReader} from 'react-qr-reader';
import {
  get,
  indexOf,
  noop
  //  trim
} from 'lodash';
import {useDidUpdateEffect} from './AppointmentSteps/utils';
// import CalendarComponent from './Appointment/CalendarComponent';
import CalendarComponent from './Scheduler/CalendarTabs';
import AuthUtils from 'src/utils/AuthUtils';
// import {getNonHealphaUserBranches} from '@shared/services/CommonService';
import AppointmentDetails from '../Dashboard/AppointmentScreen/AppointmentDetailsComponent/AppointmentDetails/AppointmentDetails';
import moment from 'moment';
import Calendar from '../Dashboard/AppointmentScreen/CalendarComponent/Calendar';
import NewPersonRegistrationComponent from '@components/system/Dialogs/NewPersonRegistration/NewPersonRegistrationComponent';
import NewAppointmentBookingsComponent from './Appointment/AppointmentBooking';
import Icons from '@components/system/MyIcons';
import PatientDetails from '../Dashboard/AppointmentScreen/PatientDetailsComponent/PatientDetails';
import CommonUtils from 'src/utils/CommonUtils';
import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';
import {useHistory} from 'react-router-dom';
import {nurseDashboardServices, UserTypes} from 'src/constants/SystemConstants';
import NursingSectionComponent from '../Dashboard/AppointmentScreen/NursingSection/NursingSectionComponent/NursingSectionComponent';
import ProvideVaccineDialog from '../Dashboard/AppointmentScreen/AppointmentDetailsComponent/PatientCard/ProvideVaccineDialog';
import DeleteAttachmentConfirmationPopUp from '../Dashboard/PatientObservations/Plan/DeleteAttachmentConfirmationPopUp';
import './Appointment.scss';

const useStyles = makeStyles({
  modalWrapper: {
    color: '#000',
    borderRadius: '6px',
    backgroundColor: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'fixed'
  },
  listCard: {
    backgroundColor: '#fff',
    marginBottom: '5px',
    height: '50px',
    borderRadius: '4px'
  }
});

export interface StepsCommonProps {
  data: any;
  dispatch: any;
  doctorId: string;
  branchId: string;
  doctorName?: string;
}
const NewPatientFlow = [
  'stepOne',
  'stepTwo',
  'stepThree',
  'stepFour',
  'stepFive',
  'stepSix'
];

const initialModalState = {
  currentStep: null,
  show: false,
  loading: false,
  allSteps: [],
  formStates: {}
};
export const actionType = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  NEXT_STEP: 'NEXT_STEP'
};
const getNextStep = (currentStep: any, allSteps: Array<string>) => {
  const posCurrentStep = indexOf(allSteps, currentStep);
  return get(
    allSteps,
    `${posCurrentStep >= 0 ? posCurrentStep + 1 : posCurrentStep}`,
    null
  );
};

type State = {
  currentStep: keyof typeof appointmentSteps | null | string;
  show: boolean;
  loading: boolean;
  allSteps: Array<string>;
  formStates: any;
};

type Action = {
  type: string;
  payload: any;
};

const reducer = (state: State, action: Action): State => {
  const nextStep = getNextStep(state.currentStep, state.allSteps);
  const formStates = action.payload;
  switch (action.type) {
    case actionType.OPEN_MODAL:
      return {...state, show: true};
    case actionType.CLOSE_MODAL:
      return {...initialModalState};
    case actionType.NEXT_STEP:
      return {
        ...state,
        show: true,
        currentStep: nextStep,
        formStates: {...state.formStates, ...formStates}
      };
    default:
      return initialModalState;
  }
};
const AppointmentBookingModalFlow = ({
  chosenSteps = NewPatientFlow,
  openModal,
  setClose,
  modalData
}: any): React.ReactElement | null => {
  const styles = useStyles();
  const [state, dispatch] = useReducer(reducer, {
    ...initialModalState,
    currentStep: chosenSteps[0],
    allSteps: chosenSteps,
    formStates: {}
  });
  useEffect(() => {
    dispatch({
      type: openModal ? actionType.OPEN_MODAL : actionType.CLOSE_MODAL,
      payload: null
    });
  }, [openModal]);
  useDidUpdateEffect(() => {
    if (openModal === true && state.show === false) setClose();
  }, [state.show, setClose, openModal]);
  if (!state.currentStep) return null;
  const Component = appointmentSteps[state.currentStep];
  return (
    <Modal
      open={state.show}
      onClose={() => {
        dispatch({type: actionType.CLOSE_MODAL, payload: null});
        setClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className={styles.modalWrapper}>
        <Component {...modalData} data={state.formStates} dispatch={dispatch} />
      </Box>
    </Modal>
  );
};

const Appointment = (props: any): React.ReactElement => {
  const [scanOpen, setScanOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [clickedStatus, setClickedStatus] = useState('');
  const [doctorID, setDoctorID] = useState('');
  const [statusCount, setStatusCount] = useState('');
  // const [templateID, setTemplateID] = useState('');
  const [personID, setPersonID] = useState('');
  const [encounterID, setEncounterID] = useState('');
  const [appointmentID, setAppointmentID] = useState('');
  const [appointmentStatus, setAppointmentStatus] = useState('');
  const [hlpId, setHlpId] = useState('');
  const [appointmentTypeStatus, setAppointmentTypeStatus] = useState('');
  const isNurse = CommonUtils.checkIsNurse();
  const isAdminNurseDashboard = CommonUtils.checkIsAdminNurseDashboard();

  const [vitalsAdded, setVitalsAdded] = useState(false);

  const [showProvideVaccinePopUp, setShowProvideVaccinePopUp] =
    React.useState(false);
  const [vaccineData, setVaccineData] = React.useState([]);
  const [deleteAttachmentId, setDeleteAttachmentId] =
    React.useState<boolean>(false);
  const [deleteType, setDeleteType] = React.useState<any>('');
  const [
    showDeleteAttachmentConfirmationPopUp,
    setShowDeleteAttachmentConfirmationPopUp
  ] = React.useState(false);

  const [patientDetails, setPatientDetails] = useState<any>(null);
  const checkArr: any = [];
  const keys: any = [];
  const branch_id = AuthUtils.getMenuBranchId();
  const docId = AuthUtils.getDoctorId();
  const [todaysDate, setTodaysDate] = useState('');
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const history = useHistory();
  const [isVirtualAppointment, setIsVirtualAppointment] =
    useState<boolean>(false);
  const [virtualAppointmentBranchId, setVirtualAppointmentBranchId] =
    useState('');
  const BASE_ROUTE_USER = `${BASE_ROUTE_PORTAL}/user`;

  const [provideVaccinePassData, setProvideVaccinePassData] = React.useState({
    encId: '',
    docId: '',
    personId: '',
    template: ''
  });

  const [vaccineDataMainArray, setVaccineDataMainArray] = React.useState([]);

  // const [encounterID, setEncounterID] = useState('');
  const [templateID, setTemplateID] = useState('');
  // const [doctorID, setDoctorID] = useState('');
  // const [appointmentStatus, setAppointmentStatus] = useState('');
  let isAdminNurse: any;
  if (AuthUtils.getIsAdminNurse() == 'true') {
    isAdminNurse = true;
  } else {
    isAdminNurse = false;
  }
  const handleAppointmentDetails = (
    id: any,
    is_virtual_appointment?: boolean,
    virtual_branch_id?: any
  ) => {
    setPatientDetails(null);
    setIsVirtualAppointment(
      is_virtual_appointment ? is_virtual_appointment : false
    );
    setVirtualAppointmentBranchId(virtual_branch_id);

    if (id) {
      getPatientCard(null, id)
        .then(res => {
          setAppointmentTypeStatus(
            res?.data?.person_appointment_details?.appointment_type_status
          );
          setPatientDetails(res?.data?.person_appointment_details);
          AuthUtils.setHealphaId(
            res?.data?.person_appointment_details?.appointment?.healpha_id
          );
          AuthUtils.setAppointmentPersonName(
            res?.data?.person_appointment_details?.appointment?.person_details
              ?.full_name
          );
          AuthUtils.setPersonDetails(
            res?.data?.person_appointment_details?.appointment?.person_details
          );
          AuthUtils.setBranchId(
            res?.data?.person_appointment_details?.appointment?.branch_id
          );
          AuthUtils.setAppointmentDetails(
            res?.data?.person_appointment_details
          );
          setDoctorID(
            res?.data?.person_appointment_details?.appointment?.doc_id
          );
          setPersonID(
            res?.data?.person_appointment_details?.appointment?.healpha_id
          );
          setTemplateID(res?.data?.person_appointment_details?.template_id);

          setEncounterID(res?.data?.person_appointment_details?.encounter_id);
          setAppointmentStatus(
            res?.data?.person_appointment_details?.appointment
              ?.appointment_status
          );
          setTodaysDate(
            res?.data?.person_appointment_details?.appointment?.date_start?.split(
              ' '
            )?.[0]
          );
          setAppointmentID(
            res?.data?.person_appointment_details?.appointment?.id
          );
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    }
  };

  const handleGetVaccine = (encId?: any, doc_Id?: any, personId?: any): any => {
    getVaccinesInformation(encId, doc_Id, personId, templateID)
      .then(res => {
        setVaccineData(res?.data?.services);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const closeProvideVaccineDialog = (): void => {
    setShowProvideVaccinePopUp(false);
  };

  const deleteAttachment = (type: any, id: any) => {
    deletePlanAttachments(
      encounterID,
      doctorID,
      personID,
      type,
      deleteAttachmentId
    )
      .then(res => {
        enqueueSnackbar(res?.message, SnackBarConfig('s'));
        // handleGetVaccine();
        handleGetVaccine(
          provideVaccinePassData?.encId,
          provideVaccinePassData?.docId,
          provideVaccinePassData?.personId
        );
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleDeleteAttachment = (type: any, id: any) => {
    setDeleteAttachmentId(id);
    setDeleteType(type);
    setShowDeleteAttachmentConfirmationPopUp(true);
  };

  const closeDeleteAttachmentConfirmationPopUp = (): void => {
    setShowDeleteAttachmentConfirmationPopUp(false);
  };

  const renderShowDeleteAttachmentConfirmationPopUp =
    (): React.ReactElement => {
      return (
        <DeleteAttachmentConfirmationPopUp
          open={showDeleteAttachmentConfirmationPopUp}
          closeDeleteAttachmentConfirmationPopUp={
            closeDeleteAttachmentConfirmationPopUp
          }
          type={deleteType}
          deleteAttachment={deleteAttachment}
        />
      );
    };

  const renderProvideVaccinePopUp = (): React.ReactElement => {
    return (
      <ProvideVaccineDialog
        open={showProvideVaccinePopUp}
        closeProvideVaccineDialog={closeProvideVaccineDialog}
        vaccineData={vaccineData}
        isFromDashboard={true}
        isNurse={true}
        handleGetVaccine={handleGetVaccine}
        handleDeleteAttachment={handleDeleteAttachment}
        doctorID={doctorID}
        provideVaccinePassData={provideVaccinePassData}
        vaccineDataMainArray={vaccineDataMainArray}
        setVaccineDataMainArray={setVaccineDataMainArray}
        isAdminNurse={isAdminNurse}
      />
    );
  };

  // renders the calendar component
  const renderCalendarCard = (): React.ReactElement => {
    return (
      <Grid className="my-3">
        <Calendar
          checkArr={checkArr}
          keys={keys}
          setSelectedDate={setSelectedDate}
          setStatusCount={setStatusCount}
          branchId={branch_id}
          docId={doctorID}
          setClickedStatus={setClickedStatus}
          clickedStatus={clickedStatus}
          handleAppointmentDetails={handleAppointmentDetails}
          isFromVirtualClinic={props?.isFromVirtualClinic}
          isFromClinicalDashboard={true}
          isNurse={isNurse}
          isAdminNurse={isAdminNurse}
        />
      </Grid>
    );
  };

  // const {t} = useTranslation();
  const [modalState, setModalState] = useState<{
    openModal: boolean;
    appointmentFlow: Array<string> | [];
  }>({
    openModal: false,
    appointmentFlow: []
  });
  // const [branchId, setBranchId] = useState<string>('');
  // const [doctorId, setDoctorId] = useState<string>('');
  const [doctorName] = useState<string>('');
  // const {enqueueSnackbar} = useSnackbar();
  // const [clickedStatus, setClickedStatus] = useState('');
  const [appointmentId, setAppointmentId] = useState<any>('');
  const [prescription, setPrescription] = React.useState('');
  const handlePatientDetails = (data: any) => {
    setAppointmentId(data.id);
  };
  const [showPersonSignUpPopup, setShowPersonSignUpPopup] =
    useState<boolean>(false);
  const [
    showNewAppointmentBookingUpPopup,
    setShowNewAppointmentBookingUpPopup
  ] = useState<boolean>(false);

  const openPersonRegPopup = (): void => {
    setShowPersonSignUpPopup(true);
  };

  const closePersonSignUpPopup = (): void => {
    setShowPersonSignUpPopup(false);
  };

  const openNewAppointmentBookingPopup = (): void => {
    setShowNewAppointmentBookingUpPopup(true);
  };

  useEffect(() => {
    window.addEventListener('openNewAppointmentBookingPopup', () => {
      openNewAppointmentBookingPopup();
    });
  }, []);

  const closeNewAppointmentBookingPopup = (): void => {
    setShowNewAppointmentBookingUpPopup(false);
  };

  const checkPermission = () => {
    navigator.mediaDevices
      .getUserMedia({video: true, audio: false})
      .then(stream => {
        setScanOpen(true);
      })
      .catch(err => {
        alert(err + '\n' + 'Please allow the camera permission manually');
      });
  };

  // renders appointment details card component
  const renderAppointmentDetailsCard = (): React.ReactElement => {
    return (
      <Grid className="appointment-grid">
        <AppointmentDetails
          clickedStatus={clickedStatus}
          setClickedStatus={setClickedStatus}
          handlePatientDetails={handlePatientDetails}
          selectedDate={selectedDate?.format('YYYY-MM-DD')}
          branchId={branch_id}
          docId={doctorID}
          isFromClinicalDashboard={true}
          handleAppointmentDetails={
            isNurse || isAdminNurse ? handleAppointmentDetails : noop
          }
          setAppointmentId={setAppointmentId}
          appointmentId={appointmentId}
          setShowPdfFileDialog={noop}
          setPrescription={setPrescription}
          prescription={prescription}
          setShowBackground={() => null}
          setShowProvideVaccinePopUp={
            isNurse || isAdminNurse ? setShowProvideVaccinePopUp : noop
          }
          openNewAppointmentBookingPopup={openNewAppointmentBookingPopup}
          setVaccineData={setVaccineData}
          vaccineData={vaccineData}
          encIdFromNurse={encounterID}
          vitalsAdded={vitalsAdded}
          setVitalsAdded={setVitalsAdded}
          setProvideVaccinePassData={setProvideVaccinePassData}
          statusCount={statusCount}
          isNurse={isNurse}
          isAdminNurse={isAdminNurse}
        />
      </Grid>
    );
  };

  const Scan = () => {
    return (
      <Dialog open={scanOpen} onClose={() => setScanOpen(false)}>
        {scanOpen && (
          <QrReader
            constraints={{facingMode: 'user'}}
            onResult={(result: any, error: any) => {
              if (result) {
                setHlpId(result?.text || '');
                setScanOpen(false);
                openNewAppointmentBookingPopup();
              }
            }}
            containerStyle={{width: '500px', height: '500px'}}
          />
        )}
      </Dialog>
    );
  };

  const renderPersonRegPopup = (): React.ReactElement => {
    return (
      <NewPersonRegistrationComponent
        branchId={branch_id}
        open={showPersonSignUpPopup}
        closePersonSignUpPopup={closePersonSignUpPopup}
        is_virtual_clinic={false}
        is_clinical_dashboard={true}
      />
    );
  };

  const renderNewAppointmentBookingPopup = (): React.ReactElement => {
    return (
      <NewAppointmentBookingsComponent
        branchId={branch_id}
        open={showNewAppointmentBookingUpPopup}
        closeNewBookingsPopup={closeNewAppointmentBookingPopup}
        is_clinical_dashboard={true}
        healphaId={hlpId}
        setHlpId={setHlpId}
        isFromVirtualClinic={props?.isFromVirtualClinic}
      />
    );
  };

  const renderPatientDetailsCard = (): React.ReactElement => {
    AuthUtils.setEncId(patientDetails?.encounter_id);
    AuthUtils.setTemplateNewId(patientDetails?.template_id);
    return (
      <Grid item sm={12} className="patient-details-card">
        {patientDetails && (
          <PatientDetails
            {...patientDetails?.appointment}
            appointmentTypeStatus={appointmentTypeStatus}
            // docName={docName}
            encounterId={patientDetails?.encounter_id}
            version={patientDetails?.version}
            // runOnClick={runOnClick}
            appointmentId={appointmentId}
            templateId={patientDetails?.template_id}
            isFromVirtualClinic={props?.isFromVirtualClinic}
            isVirtualAppointment={isVirtualAppointment}
            virtualAppointmentBranchId={virtualAppointmentBranchId}
            isFromNurseDashboard={'FromAdminDashboard'}
          />
        )}
      </Grid>
    );
  };

  const renderNursingSection = (): React.ReactElement => {
    return (
      <NursingSectionComponent
        docId={doctorID}
        personId={personID}
        tempId={templateID}
        encId={encounterID}
        appointmentStatus={appointmentStatus}
        todaysDate={todaysDate}
        appointmentID={appointmentID}
        patientDetails={patientDetails}
        // vitalsAdded={vitalsAdded}
        setVitalsAdded={setVitalsAdded}
        // runOnClick={runOnClick}
        // appointmentLog={appointmentLog}
      />
    );
  };

  if (AuthUtils.getRoleType() === UserTypes.DOCTOR) {
    enqueueSnackbar(t('YOU_ARE_NOT_AUTHORIZED'), SnackBarConfig('e'));
    history.push({
      pathname: `/${BASE_ROUTE_USER}`
    });
  }

  const conditionForNursingServices = nurseDashboardServices?.includes(
    appointmentStatus?.toLowerCase()
  );
  return (
    <Grid>
      <Grid
        className="appointment-container px-2"
        sx={{
          // For scroll issue
          maxHeight: {
            xs: 'none',
            lg: '88vh'
          },
          overflow: {
            xs: 'scroll',
            lg: 'hidden'
          }
        }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} className="pt-4">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center">
              {!isNurse && !isAdminNurse && (
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" spacing={1}>
                    <div
                      id="scanner"
                      role="button"
                      className="px-0 line-code scannerSize">
                      <Icons.QRCodeIcon
                        className="scanner-position "
                        onClick={() => {
                          checkPermission();
                        }}
                      />
                    </div>
                    {scanOpen && <Scan />}
                    {AuthUtils.getIsOperationalBranch() != 'true' ? (
                      <>
                        <Box className="patient">
                          <Button
                            id="new_patient"
                            size="large"
                            variant="outlined"
                            color="primary"
                            sx={{
                              whiteSpace: 'nowrap'
                            }}
                            className="newPatientsize"
                            onClick={openPersonRegPopup}>
                            {t('CLINIC_NEW_PATIENT')}
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            id="new_appointment"
                            size="large"
                            variant="contained"
                            sx={{
                              whiteSpace: 'nowrap'
                            }}
                            className="newAppointmentsize"
                            onClick={() => {
                              setHlpId('');
                              openNewAppointmentBookingPopup();
                            }}>
                            {t('CLINIC_NEW_APPOINTMENT')}
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <Grid>
                        <Box>
                          <Button
                            id="new_corporate_appointment"
                            size="large"
                            variant="contained"
                            sx={{
                              whiteSpace: 'nowrap'
                            }}
                            className="newAppointmentsize"
                            onClick={() => {
                              setHlpId('');
                              openNewAppointmentBookingPopup();
                            }}>
                            {t('New Corporate Appointment')}
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                {renderCalendarCard()}
                <div className="m-2 pl-2">
                  {renderAppointmentDetailsCard()}
                  {showPersonSignUpPopup && renderPersonRegPopup()}
                  {showNewAppointmentBookingUpPopup &&
                    renderNewAppointmentBookingPopup()}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {!isNurse && !isAdminNurse && (
            <Grid item xs={12} md={8}>
              {/* <CalendarComponent doctorId={doctorId} branchId={branchId} /> */}
              <CalendarComponent
                selectedDate={selectedDate?.format('YYYY-MM-DD')}
                setDoctorID={setDoctorID}
              />
            </Grid>
          )}
          {(isNurse || isAdminNurse) && (
            <Grid item md={8} className="patient-details-grid">
              {renderPatientDetailsCard()}
              <Grid item xs={12}>
                {/* {appointmentId && !isNurse && renderAppointmentLogCard()} */}

                {appointmentId &&
                  encounterID &&
                  (isNurse || isAdminNurseDashboard) &&
                  conditionForNursingServices &&
                  // templateID &&
                  renderNursingSection()}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {modalState.openModal && (
        <AppointmentBookingModalFlow
          chosenSteps={modalState.appointmentFlow}
          openModal={modalState.openModal}
          modalData={{
            doctorId: docId,
            branchId: branch_id,
            doctorName
          }}
          setClose={() =>
            setModalState(state => ({
              ...state,
              openModal: false,
              appointmentFlow: []
            }))
          }
        />
      )}
      {showProvideVaccinePopUp && renderProvideVaccinePopUp()}

      {showDeleteAttachmentConfirmationPopUp &&
        renderShowDeleteAttachmentConfirmationPopUp()}
    </Grid>
  );
};

export default Appointment;
