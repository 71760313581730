import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as ForwardArrowIconSvg} from '@assets/images/healpha/ForwardArrowIcon.svg';

const ForwardArrowIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={ForwardArrowIconSvg}
      viewBox="0 0 27.113 27.113"
    />
  );
};

export default ForwardArrowIcon;
