/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react';
import {Paper} from '@material-ui/core';
import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentTooltip
} from '@devexpress/dx-react-scheduler-material-ui';
import moment from 'moment';
import AuthUtils from 'src/utils/AuthUtils';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import NewAppointmentBookingsComponent from '../Appointment/AppointmentBooking';

// import appointments from './demo-data/today-appointments';
import {
  getAppointmentDetails,
  getAvailableTimeSlots
} from '@shared/services/PublicService';
import AppointmentCard from '../Appointment/AppointmentCard';
import BasicLayoutComponent from '../Appointment/CreateAppointmentModal';

const layoutStyling = {
  width: '25rem',
  top: {
    xs: '0',
    lg: '15.625rem !important'
  },
  right: {
    xs: '0',
    lg: '15rem !important'
  },
  left: 'auto !important'
};
const schedularStyles = {width: '100%', boxShadow: 'none', height: '75vh'};

// const currentDate = moment();
// const dateToday = currentDate.date();

const CalendarWeekWise = ({doctors, selectedDate}: any): React.ReactElement => {
  const startDate = moment().startOf('week').format('YYYY-MM-DD');
  const endDate = moment().endOf('week').format('YYYY-MM-DD');
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const [appointments, setAppointments] = useState<any>([]);
  const [availability, setAvailability] = useState<any>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [cellData, setCellData] = useState<any>({});
  const [
    showNewAppointmentBookingUpPopup,
    setShowNewAppointmentBookingUpPopup
  ] = useState<boolean>(false);

  const doctor_id = doctors?.[0]?.non_healpha_user_id;
  const branch_id = AuthUtils.getMenuBranchId();
  //  const dateCurrent = currentDate.format('YYYY-MM-DD');

  const openNewAppointmentBookingPopup = (): void => {
    setShowNewAppointmentBookingUpPopup(true);
  };

  const closeNewAppointmentBookingPopup = (): void => {
    setShowNewAppointmentBookingUpPopup(false);
  };

  const getDoctorTimings = () => {
    const arr = new Array(7).fill(0);
    setAvailability([]);
    arr.map((i, index) => {
      const date = moment()
        .startOf('week')
        .add(index, 'days')
        .format('yyyy-MM-DD');
      const isBefore = moment(moment().format('yyyy-MM-DD')).isAfter(date);
      if (!isBefore) {
        getAvailableTimeSlots(doctor_id, branch_id, date).then((res: any) => {
          let timings: any = [];
          Object.keys(res.data.free_slots).map((slot: any) => {
            timings = [...timings, ...res.data.free_slots[slot]];
          });
          setAvailability((rest: any) => [
            ...rest,
            {
              date,
              data: timings
            }
          ]);
        });
      } else {
        setAvailability((rest: any) => [
          ...rest,
          {
            date,
            data: []
          }
        ]);
      }
    });
    // getAvailableTimeSlots(doctor_id, branch_id);
  };
  useEffect(getDoctorTimings, [doctor_id]);
  // console.log(availability, 'avail');
  const init = (doc_id: any) => {
    getAppointmentDetails({
      doctor_id: doc_id ? doc_id : doctor_id,
      branch_id,
      date: startDate,
      appointment_checked: '',
      search_text: '',
      end_date: endDate,
      total_appts_req: true
    })
      .then(res => {
        const tmp = res?.data?.appointments.map((i: any) => {
          return {
            title: i?.appointment?.person_details?.full_name,
            startDate: new Date(i?.appointment?.appointment_date_start),
            endDate: new Date(i?.appointment?.appointment_date_end),
            id: i?.appointment?.id,
            location: 'Appointments',
            ...i
          };
        });
        setAppointments(tmp || []);
      })
      .catch((err: any) => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };
  const initListener = () => {
    window.addEventListener('initListener', (e: any) => {
      const doc_id = e.detail.doc_id;
      init(doc_id);
    });
    return () => {
      window.removeEventListener('initListener', () => {
        init('');
      });
    };
  };
  useEffect(initListener, []);

  const closeModal = () => {
    window.addEventListener('closeModal', () => {
      setVisible(false);
    });
    return () => {
      window.removeEventListener('closeModal', () => {
        setVisible(false);
      });
    };
  };
  useEffect(closeModal, []);

  const initLoad = () => {
    init('');
  };
  useEffect(initLoad, [doctor_id, AuthUtils.getAppointmentNotify()]);

  const renderNewAppointmentBookingPopup = (): React.ReactElement => {
    return (
      <NewAppointmentBookingsComponent
        branchId={branch_id}
        open={showNewAppointmentBookingUpPopup}
        closeNewBookingsPopup={closeNewAppointmentBookingPopup}
        is_clinical_dashboard={true}
        selectedDate={cellData?.startDate}
        endDate={cellData?.endDate}
        docId={doctor_id}
        hasTimeSlot
      />
    );
  };

  const Appointment = ({children, style, ...restProps}: any) => (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        backgroundColor: doctors?.[0]?.color || ''
      }}>
      {children}
    </Appointments.Appointment>
  );

  const TimeTableCell = ({onDoubleClick, ...restProps}: any) => {
    const isBefore = moment().isAfter(restProps.startDate);
    const todayAvail = availability.find((i: any) =>
      moment(restProps.startDate).isSame(i.date, 'day')
    );
    const isAvailable = todayAvail?.data?.find((i: any) => {
      const format = 'hh:mm:ss';
      const time = moment(restProps.startDate)
        .set('hour', i.value.slice(0, 2))
        .set('minute', i.value.slice(3, 5));
      const beforeTime = moment(restProps.startDate, format),
        afterTime = moment(restProps.endDate, format);
      return time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime);
    });
    return (
      <WeekView.TimeTableCell
        className={isBefore || !isAvailable ? 'cell-disable' : 'cell'}
        onClick={() => {
          if (isBefore) return;
          setCellData(restProps);
          openNewAppointmentBookingPopup();
          // setModalVisible(true);
        }}
        {...restProps}
      />
    );
  };
  const Layout = ({children, appointmentData, ...restProps}: any) => {
    return (
      <AppointmentTooltip.Layout
        PaperProps={{sx: layoutStyling}}
        className="w-100"
        {...restProps}
        appointmentData={appointmentData}
      />
    );
  };

  const Content = ({children, appointmentData, ...restProps}: any) => {
    return (
      <div className="appointment-view-card">
        <AppointmentCard
          selectedDate={selectedDate}
          appointmentData={appointmentData}
        />
      </div>
    );
  };

  return (
    <Paper sx={schedularStyles} className="schedular-wrapper">
      <Scheduler data={appointments}>
        <WeekView
          cellDuration={15}
          startDayHour={0}
          endDayHour={24}
          timeTableCellComponent={TimeTableCell}
        />
        <Appointments appointmentComponent={Appointment} />
        <AppointmentTooltip
          contentComponent={Content}
          layoutComponent={Layout}
          visible={visible}
          onVisibilityChange={setVisible}
        />
        {showNewAppointmentBookingUpPopup && renderNewAppointmentBookingPopup()}
        <BasicLayoutComponent
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          branch_id={branch_id}
          cellData={cellData}
          doctor_id={doctor_id}
          init={init}
          onCancel={() => setModalVisible(false)}
        />
      </Scheduler>
    </Paper>
  );
};

export default CalendarWeekWise;
