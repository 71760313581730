import {colors} from '@material-ui/core';
import {
  APP_PRIMARY_COLOR,
  DEFAULT_BLACK_COLOR,
  DEFAULT_WHITE_COLOR,
  FONT_PRIMARY_COLOR,
  FONT_TERTIARY_COLOR
} from '../style/variable';

export default {
  black: DEFAULT_BLACK_COLOR,
  white: DEFAULT_WHITE_COLOR,
  primary: {
    contrastText: DEFAULT_WHITE_COLOR,
    dark: APP_PRIMARY_COLOR,
    main: APP_PRIMARY_COLOR,
    light: APP_PRIMARY_COLOR
  },
  secondary: {
    contrastText: DEFAULT_WHITE_COLOR,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  success: {
    contrastText: DEFAULT_WHITE_COLOR,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: DEFAULT_WHITE_COLOR,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: DEFAULT_WHITE_COLOR,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: DEFAULT_WHITE_COLOR,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: FONT_PRIMARY_COLOR,
    secondary: FONT_TERTIARY_COLOR,
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: DEFAULT_WHITE_COLOR
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  border: colors.grey[700]
};
