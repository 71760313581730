/* eslint-disable max-len */

import React from 'react';
import {
  BASE_ROUTE_DOCTOR,
  BASE_ROUTE_BRANCH,
  BASE_ROUTE_PORTAL,
  BASE_ROUTE_PRACTICE
} from './BaseRoute';
import {CustomRouteInterface} from './CustomRoute';
import NavRoutes from './NavRoutes';

const HomePageComponent = React.lazy(
    () => import('../../modules/public/portal/HomePage/HomePageComponent')
  ),
  UserHomeScreen = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Dashboard/HomeScreen/HomeScreenComponent'
      )
  ),
  PatientDashboardScreen = React.lazy(
    () =>
      import('src/modules/shared/Dashboards/PatientDashboard/PatientDashboard')
  ),
  DoctorDashboardScreen = React.lazy(
    () =>
      import('src/modules/shared/Dashboards/DoctorDashBoard/DoctorDashBoard')
  ),
  DoctorLoginPage = React.lazy(
    () =>
      import('src/components/system/LoginAndSignup/DoctorLogin/DoctorLoginPage')
  ),
  OpDashboardScreen = React.lazy(
    () => import('src/modules/shared/Dashboards/OpDashBoard/OpDashBoard')
  ),
  VirtualClinicDashboardScreen = React.lazy(
    () =>
      import(
        'src/modules/shared/Dashboards/VirtualClinicDashBoard/VirtualClinicDashBoard'
      )
  ),
  LandingHomepage = React.lazy(
    () => import('../../modules/landings/LandingHomePage')
  ),
  HospitalViewComponent = React.lazy(
    () =>
      import(
        '../../modules/hospital/modules/HospitalView/HospitalViewComponent'
      )
  ),
  OverViewComponent = React.lazy(
    () =>
      import(
        '../../modules/hospital/modules/HospitalView/Overview/OverviewComponent'
      )
  ),
  DoctorsComponent = React.lazy(
    () =>
      import(
        '../../modules/hospital/modules/HospitalView/Doctors/DoctorsComponent'
      )
  ),
  ServicesComponent = React.lazy(
    () =>
      import(
        '../../modules/hospital/modules/HospitalView/Services/ServicesComponent'
      )
  ),
  FeedbackComponent = React.lazy(
    () =>
      import(
        '../../modules/hospital/modules/HospitalView/Feedback/FeedbackComponent'
      )
  ),
  OtherLocationsComponent = React.lazy(
    () =>
      import(
        '../../modules/hospital/modules/HospitalView/OtherLocations/OtherLocationsComponent'
      )
  ),
  HomeContentComponent = React.lazy(
    () => import('../../modules/public/portal/HomeContent/HomeContentComponent')
  ),
  ForumRepliesComponent = React.lazy(
    () =>
      import('../../modules/public/portal/ForumReplies/ForumRepliesComponent')
  ),
  ForumAskQuestionComponent = React.lazy(
    () =>
      import(
        '../../modules/public/portal/ForumAskQuestion/ForumAskQuestionComponent'
      )
  ),
  ForumAskQuestionComponentShared = React.lazy(
    () =>
      import('../../modules/shared/Dashboards/ForumAskQuestionComponentShared')
  ),
  ForumRepliesComponentShared = React.lazy(
    () => import('../../modules/shared/Dashboards/ForumRepliesComponentShared')
  ),
  DoctorPasswordComponent = React.lazy(
    () =>
      import(
        '../../modules/public/portal/DoctorPassword/DoctorPasswordComponent'
      )
  ),
  DoctorViewComponent = React.lazy(
    () => import('../../modules/doctor/modules/DoctorView/DoctorViewComponent')
  ),
  InfoComponent = React.lazy(
    () => import('../../modules/doctor/modules/DoctorView/Info/InfoComponent')
  ),
  EducationComponent = React.lazy(
    () =>
      import(
        '../../modules/doctor/modules/DoctorView/EducationExperience/EducationExperienceComponent'
      )
  ),
  AwardsComponent = React.lazy(
    () =>
      import(
        '../../modules/doctor/modules/DoctorView/AwardsRecognitions/AwardsRecognitionsComponent'
      )
  ),
  PublicationsComponent = React.lazy(
    () =>
      import(
        '../../modules/doctor/modules/DoctorView/Publications/PublicationsComponent'
      )
  ),
  MembershipComponent = React.lazy(
    () =>
      import(
        '../../modules/doctor/modules/DoctorView/MemberShip/MembershipComponent'
      )
  ),
  FeedbackComponentDoctor = React.lazy(
    () =>
      import(
        '../../modules/doctor/modules/DoctorView/Feedback/FeedbackComponent'
      )
  ),
  DoctorListComponent = React.lazy(
    () => import('../../modules/doctor/modules/DoctorList/DoctorListComponent')
  ),
  HospitalListComponent = React.lazy(
    () =>
      import(
        '../../modules/hospital/modules/HospitalList/HospitalListComponent'
      )
  ),
  UserHomePageComponent = React.lazy(
    () => import('../../modules/user/UserHomePage')
  ),
  UserDashboardComponent = React.lazy(
    () => import('../../modules/user/modules/Dashboard/UserDashboard')
  ),
  MultiLanguageComponent = React.lazy(
    () => import('../../modules/shared/MultiLanguage/Multilanguage')
  ),
  Appointments = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Dashboard/AppointmentScreen/AppointmentDetailsComponent/AppointmentComponent/Appointments'
      )
  ),
  VirtualClinicDashboardComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Dashboard/VirtualClinicDashboard/VirtualClinicDashboardComponent'
      )
  ),
  NurseDashboardComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Dashboard/NurseDashboard/NurseDashboardComponent'
      )
  ),
  PersonWebTimeline = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Dashboard/PersonWebTimline/PersonWebTimeline'
      )
  ),
  DoctorWebTimeline = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Dashboard/DoctorWebTimeline/DoctorWebTimeline'
      )
  ),
  PrescriptionDetails = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Dashboard/ViewPatientHistory/TimelineComponent/SharedPrescriptionDetailsComponent'
      )
  ),
  ViewPatientHistory = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Dashboard/ViewPatientHistory/ViewPatientHistoryComponent/ViewPatientHistory'
      )
  ),
  PatientObservations = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Dashboard/PatientObservations/PatientObservations/PatientObservations'
      )
  ),
  PaymentPageComponent = React.lazy(
    () => import('../../modules/landings/modules/Payment/PaymentPageComponent')
  ),
  SuccessPageComponent = React.lazy(
    () => import('../../modules/landings/modules/Success/SuccessPageComponent')
  ),
  NonHealphaUserLoginComponent = React.lazy(
    () =>
      // import(
      //   '../../modules/public/portal/NonHealphaUserLogin/NonHealphaUserLoginComponent'
      // )
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/DoctorLoginPage'
      )
  ),
  NonHealphaCreateUser = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/CreateUserPage'
      )
  ),
  NonHealphaOpLogin = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/OpUserLogin/Opmain'
      )
  ),
  OpCreateUser = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/OpUserLogin/OpCreateUser'
      )
  ),
  NonHealphaSchoolLogin = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/SchoolUserLogin/SchoolMain'
      )
  ),
  SchoolCreateUser = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/SchoolUserLogin/SchoolCreateUser'
      )
  ),
  NonHealphaCorporateLogin = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/CorporateUserLogin/CorporateMain'
      )
  ),
  CorporateCreateUser = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/CorporateUserLogin/CorporateCreateUser'
      )
  ),
  NonHealphaVirtualLogin = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/VirtualUserLogin/VirtualMain'
      )
  ),
  VirtualCreateUser = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/VirtualUserLogin/VirtualCreateUser'
      )
  ),
  NonHealphaLabLogin = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/LabUserLogin/LabMain'
      )
  ),
  LabCreateUser = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/LabUserLogin/LabCreateUser'
      )
  ),
  NonHealphaPharmaLogin = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/PharmaUserLogin/PharmaMain'
      )
  ),
  PharmaCreateUser = React.lazy(
    () =>
      import(
        '../../../src/components/system/LoginAndSignup/DoctorLogin/PharmaUserLogin/PharmaCreateUser'
      )
  ),
  AllTemplates = React.lazy(
    () => import('../../modules/user/modules/Templates/AllTemplates')
  ),
  AllMicroTemplate = React.lazy(
    () => import('../../modules/user/modules/Templates/AllMicroTemplate')
  ),
  TabTemplates = React.lazy(
    () => import('../../modules/user/modules/CustomTemplates/Tabs')
  ),
  PreviewTemplate = React.lazy(
    () => import('../../modules/user/modules/PreviewTemplate/PreviewTemplate')
  ),
  MedicineTemplate = React.lazy(
    () => import('../../modules/user/modules/Plan/MedicineTemplate')
  ),
  NewTemplate = React.lazy(
    () => import('../../modules/user/modules/MicroTemplate/NewTemplate')
  ),
  Appointment = React.lazy(
    () => import('../../modules/user/modules/ClinicDashboard/Appointment')
  ),
  SymptomsTemplate = React.lazy(
    () => import('../../modules/user/modules/CustomTemplates/SymptompsTemplate')
  ),
  BillingHomePage = React.lazy(
    () => import('../../modules/billing/BillingHomePage')
  ),
  SelectedPatient = React.lazy(
    () =>
      import('../../modules/billing/modules/selectedpatient/SelectedPatient')
  ),
  // access control screens
  ActivateModuleComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/moduleDefination/activateModule/ActivateModuleComponent'
      )
  ),
  NewModuleComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/moduleDefination/newModule/NewModuleComponent'
      )
  ),
  UserRolesComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/usersRoles/userDashboard/UserRolesComponent'
      )
  ),
  PracticeListComponent = React.lazy(
    () =>
      import('../../modules/adminScreens/PracticeList/PracticeListComponent')
  ),
  AddManagePracticeComponent = React.lazy(
    () => import('../../modules/adminScreens/PracticeList/AddPracticeComponent')
  ),
  PracticePlansComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/PracticeBranchplans/practicePlans/PracticePlansComponent'
      )
  ),
  BranchPlansComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/PracticeBranchplans/branchPlans/BranchPlansComponent'
      )
  ),
  BranchTabsComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/PracticeBranchplans/branchSelfViewPlans/BranchTabsComponent'
      )
  ),
  BranchMyCart = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/PracticeBranchplans/branchSelfViewPlans/MyCartPage'
      )
  ),
  PracticeSelectionComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/PracticeBranchplans/practiceSelection/PracticeSelectionComponent'
      )
  ),
  CartComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/PracticeBranchplans/cartItems/CartComponent'
      )
  ),
  AgreementComponent = React.lazy(
    () => import('../../modules/adminScreens/agreementScreens/Agreement')
  ),
  AgreementListComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/agreementScreens/AgreementListComponent'
      )
  ),
  AgreementCreationComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/agreementScreens/AddAgreementComponent'
      )
  ),
  CreateEditModuleComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/moduleDefination/createEditModule/CreateEditModuleComponent'
      )
  ),
  NewUserComponent = React.lazy(
    () =>
      import('../../modules/adminScreens/usersRoles/newUser/NewUserComponent')
  ),
  MenuStructureComponent = React.lazy(
    () =>
      import('../../modules/adminScreens/MenuStructure/MenuStructureComponent')
  ),
  AddNewRoleComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/usersRoles/newUserRoles/NewUserRolesComponent'
      )
  ),
  EditRoleComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/usersRoles/newUserRoles/EditUserRoleComponent'
      )
  ),
  NewSmsPlanComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/creditsInvoices/credits/NewSmsPlanComponent'
      )
  ),
  // SalesNamePopup =React.lazy(
  //   () =>
  //   import(
  //     '../../modules/adminScreens/creditsInvoices/credits/SalesNamePopup'
  //   )
  // ),
  SmsCreditComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/creditsInvoices/credits/SmsCreditComponent'
      )
  ),
  CreditPlanByBranch = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/creditsInvoices/credits/CreditPlanByBranch'
      )
  ),
  SmsTabsComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/creditsInvoices/credits/SmsTabsComponent'
      )
  ),
  AllInvoicesComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/creditsInvoices/Invoices/AllInvoicesComponent'
      )
  ),
  // PaymentDetailsPopup =React.lazy(
  //   () =>
  //   import(
  //     '../../modules/adminScreens/creditsInvoices/Invoices/PaymentDetailsPopup'
  //   )
  // ),
  OnBoardTabComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/usersRoles/onboard/OnBoardTabs/OnBoardTabComponent'
      )
  ),
  EditUserDetailsComponent = React.lazy(
    () =>
      import('../../modules/adminScreens/usersRoles/newUser/EditUserComponent')
  ),
  EditUserComponent = React.lazy(
    () =>
      import('../../modules/adminScreens/usersRoles/newUser/EditUserComponent')
  ),
  HealphaChargesComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/HealphaCharges/HealphaChargesComponent'
      )
  ),
  ManagePracticeTabsComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManagePracticeTabsComponent'
      )
  ),
  ManageUserPracticeAddDetailsComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManagePracticeAddDetailsComponent'
      )
  ),
  ManageAddChildHealthDetailComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManageChildHealthDetailComponent'
      )
  ),
  ManageAddCorporateDetailComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManageCorporateAddDetailComponent'
      )
  ),
  ManageAddVirtualDetailComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManageVirtualAddDetailComponent'
      )
  ),
  ManageLabAddDetailComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManageLabAddDetailComponent'
      )
  ),
  ManagePharmacyAddDetailComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManagePharmacyAddDetailComponent'
      )
  ),
  ManageBranchTabsComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManageBranchTabsComponent'
      )
  ),
  ManageBranchAddDetailsComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManageBranchAddDetailsComponent'
      )
  ),
  BranchChildHealthDetailComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/BranchChildHealthDetailComponent'
      )
  ),
  BranchCorporateDetailComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/BranchCorporateDetailComponent'
      )
  ),
  BranchVirtualDetailComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/BranchVirtualDetailComponent'
      )
  ),
  BranchLabDetailComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/BranchLabDetailComponent'
      )
  ),
  BranchPharmacyDetailComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/BranchPharmacyDetailComponent'
      )
  ),
  ManagePracticeParameterComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManagePracticeParameter'
      )
  ),
  ManageChildHealthParameterComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManageChildHealthParameter'
      )
  ),
  ManageCorporateParameterComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManageCorporateParameter'
      )
  ),
  ManageVirtualClinicParameterComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/managePracticeTabs/ManageVirtualClinicParameters'
      )
  ),
  /* Self Service Components*/
  SelfServiceDashboard = React.lazy(
    () =>
      import(
        '../../modules/user/modules/SelfServiceDashboard/SelfServiceUserDashboard'
      )
  ),
  UserProfileComponent = React.lazy(
    () => import('../../modules/user/modules/Profile/ProfileComponent')
  ),
  UserPracticeComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/PracticeComponent')
  ),
  UserPracticeInfoComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/PracticeInfoComponent')
  ),
  UserPracticeBankDetailsComponent = React.lazy(
    () =>
      import('../../modules/user/modules/Practice/PracticeBankDetailsComponent')
  ),
  UserPracticeAboutComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/PracticeAbout')
  ),
  UserPracticeLocationComponent = React.lazy(
    () =>
      import('../../modules/user/modules/Practice/PracticeLocationComponent')
  ),
  UserPracticeFeaturesComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/PracticeFeatureComponent')
  ),
  UserPracticeAddDetailsComponent = React.lazy(
    () =>
      import('../../modules/user/modules/Practice/PracticeAddDetailsComponent')
  ),
  UserPracticeAddChildHealthDetailComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Practice/AddChildHealthFeatureComponent'
      )
  ),
  UserPracticeAddCorporateDetailComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/AddCorporateDepartment')
  ),
  PracticeVirtualClinicComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/PracticeVirtualClinic')
  ),
  UserPracticeLabAddDetailsComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Practice/PracticeLabAddDetailsComponent'
      )
  ),
  UserPharmacyAddDetailsComponent = React.lazy(
    () =>
      import('../../modules/user/modules/Practice/PharmacyAddDetailsComponent')
  ),
  UserBranchesComponent = React.lazy(
    () => import('../../modules/user/modules/Branches/BranchComponent')
  ),
  UserPaymentComponent = React.lazy(
    () => import('../../modules/user/modules/Payments/PaymentComponent')
  ),
  UserPaymentReviewComponent = React.lazy(
    () => import('../../modules/user/modules/Payments/PaymentReviewComponent')
  ),
  UserPaymentInvoiceComponent = React.lazy(
    () => import('../../modules/user/modules/Payments/PreviewInvoiceComponent')
  ),
  UserPaymentHistoryComponent = React.lazy(
    () => import('../../modules/user/modules/Payments/PaymentHistoryComponent')
  ),
  SelfServiceDoctorComponent = React.lazy(
    () => import('../../modules/user/modules/Doctor/DoctorHomePage')
  ),
  SelfServiceIntermediateReviewComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/ReviewComponent')
  ),
  UserPracticeSetupComponent = React.lazy(
    () =>
      import('../../modules/user/modules/PracticeSetup/PracticeSetupComponent')
  ),
  UserPracticeChildHealthComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/PracticeChildHealthSetup/PracticeChildHealthComponent'
      )
  ),
  UserPracticeChildHealthUsersComponent = React.lazy(
    () =>
      import('../../modules/user/modules/PracticeChildHealthSetup/UserDetails')
  ),
  UserPracticeCorporateUsersComponent = React.lazy(
    () =>
      import('../../modules/user/modules/PracticeCorporateSetup/CorporateUsers')
  ),
  UserPracticeParamatersComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/PracticeSetup/PracticeParametersComponent'
      )
  ),
  UserModuleSetupClasses = React.lazy(
    () =>
      import(
        '../../modules/user/modules/PracticeChildHealthSetup/PracticeSetUpClasses'
      )
  ),
  UserModuleSetupStudents = React.lazy(
    () =>
      import(
        '../../modules/user/modules/PracticeChildHealthSetup/PracticeStudentSetup'
      )
  ),
  UserSchoolParamatersComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/PracticeChildHealthSetup/SchoolParameters'
      )
  ),
  UserPracticeCorporateComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/PracticeCorporateSetup/PracticeCorporateComponent'
      )
  ),
  UserPracticeVirtualClinicComponent = React.lazy(
    () =>
      import('../../modules/user/modules/Practice/PracticeVirtualClinicSetup')
  ),
  UserPracticeLabComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/PracticeLabSetUp')
  ),
  UserPracticePharmacyComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/PracticePharmacySetUp')
  ),
  UserCorporateParamatersComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/CorporateParameters')
  ),
  UserVirtualClinicParamatersComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/VirtualClinicParameter')
  ),
  UserLabParametersComponent = React.lazy(
    () => import('../../modules/user/modules/Practice/LabUsersSetupComponent')
  ),
  UserPharmacyParametersComponent = React.lazy(
    () =>
      import('../../modules/user/modules/Practice/PharmacyUsersSetUpComponent')
  ),
  UserCorporateDepartmentComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/PracticeCorporateSetup/PracticeSetupDepartment'
      )
  ),
  UserCorporateEmployeeComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/PracticeCorporateSetup/PracticeEmployeeSetup'
      )
  ),
  UserPracticeServiceGroupMasterComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/PracticeSetup/ServiceMasterGroupComponent'
      )
  ),
  UserPracticeServiceMasterComponent = React.lazy(
    () =>
      import('../../modules/user/modules/PracticeSetup/ServiceMasterComponent')
  ),
  UserPatientComponent = React.lazy(
    () => import('../../modules/user/modules/PracticeSetup/PatientSetup')
  ),
  UserPracticeServiceUsersComponent = React.lazy(
    () => import('../../modules/user/modules/PracticeSetup/UsersComponent')
  ),
  AddUserComponent = React.lazy(
    () =>
      import('../../modules/adminScreens/usersRoles/newUser/NewUserComponent')
  ),
  AddRoleComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/usersRoles/newUserRoles/NewUserRolesComponent'
      )
  ),
  EditRole = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/usersRoles/newUserRoles/EditUserRoleComponent'
      )
  ),
  UserAuthenticationComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/UserAuthentication/UserAuthenticationComponent'
      )
  ),
  SelfServiceHomePageComponent = React.lazy(
    () => import('../../modules/user/UserHomePageCustom')
  ),
  SelfServicePortal = React.lazy(
    () => import('../../modules/user/SelfServicePortal')
  ),
  SubscriptionComponent = React.lazy(
    () =>
      import('../../modules/user/modules/Subscription/SubscriptionComponent')
  ),
  MySubscriptionsComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Subscription/MySubscription/MySubscriptionComponent'
      )
  ),
  MyCartComponent = React.lazy(
    () =>
      import('../../modules/user/modules/Subscription/MyCart/MyCartComponent')
  ),
  ViewPlansComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/PracticeBranchplans/branchSelfViewPlans/BranchTabsComponent'
      )
  ),
  ViewCartComponent = React.lazy(
    () =>
      import(
        '../../modules/adminScreens/PracticeBranchplans/branchSelfViewPlans/MyCartPage'
      )
  ),
  subscriptionRedirectComponent = React.lazy(
    () =>
      import(
        '../../modules/user/modules/Subscription/subscriptionRedirectComponent'
      )
  );
const BASE_ROUTE_HOME = `${BASE_ROUTE_PORTAL}/home`;
const BASE_ROUTE_USER = `${BASE_ROUTE_PORTAL}/user`;
const BASE_ROUTE_SELF_SERVICE = `${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}`;
const BASE_ROUTE_LANDING = `${BASE_ROUTE_PORTAL}/landing`;
const BRANCH_VIEW_ROUTE = `${BASE_ROUTE_HOME}/${BASE_ROUTE_BRANCH}/view/:branch_id`;
const PRACTICE_VIEW_ROUTE = `${BASE_ROUTE_HOME}/${BASE_ROUTE_PRACTICE}/view/:practice_id`;
const BRANCH_LIST_ROUTE = `${BASE_ROUTE_HOME}/${BASE_ROUTE_BRANCH}/list/`;
const DOCTOR_VIEW_ROUTE = `${BASE_ROUTE_HOME}/${BASE_ROUTE_DOCTOR}/view/:doctor_id`;
const DOCTOR_LIST_ROUTE = `${BASE_ROUTE_HOME}/${BASE_ROUTE_DOCTOR}/list`;
const BASE_ROUTE_MANAGE = `${BASE_ROUTE_HOME}/manage`;

const PortalRoute: CustomRouteInterface = {
  baseName: BASE_ROUTE_PORTAL,
  params: BASE_ROUTE_PORTAL,
  exactFrom: `/${BASE_ROUTE_PORTAL}`,
  redirectTo: `/${BASE_ROUTE_HOME}`,
  routes: [
    {
      path: `/${BASE_ROUTE_HOME}`,
      component: HomePageComponent,
      exactFrom: `/${BASE_ROUTE_HOME}`,
      redirectTo: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.DASHBOARD}`,
      public: true,
      routes: [
        // include static page
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.DASHBOARD}`,
          component: HomeContentComponent
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.REPLIES}/:question_id`,
          component: ForumRepliesComponent
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.ASK_QUESTION}`,
          component: ForumAskQuestionComponent
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_USER}`,
          component: NonHealphaUserLoginComponent
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.CREATE_USER}`,
          component: NonHealphaCreateUser
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_OP_USER}`,
          component: NonHealphaOpLogin
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.OP_CREATE_USER}`,
          component: OpCreateUser
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_SCHOOL_USER}`,
          component: NonHealphaSchoolLogin
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.SCHOOL_CREATE_USER}`,
          component: SchoolCreateUser
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_CORPORATE_USER}`,
          component: NonHealphaCorporateLogin
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.CORPORATE_CREATE_USER}`,
          component: CorporateCreateUser
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_VIRTUAL_USER}`,
          component: NonHealphaVirtualLogin
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.VIRTUAL_CREATE_USER}`,
          component: VirtualCreateUser
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_LAB_USER}`,
          component: NonHealphaLabLogin
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.LAB_CREATE_USER}`,
          component: LabCreateUser
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_PHARMA_USER}`,
          component: NonHealphaPharmaLogin
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.PHARMA_CREATE_USER}`,
          component: PharmaCreateUser
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.REGISTER}`,
          component: DoctorPasswordComponent
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.FORGOT_PASSWORD}`,
          component: DoctorPasswordComponent
        },
        {
          path: `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.FORGOT_PASSWORD_PERSON}`,
          component: DoctorPasswordComponent
        },

        // inlcude branch
        {
          path: `/${BRANCH_VIEW_ROUTE}`,
          component: HospitalViewComponent,
          exactFrom: `/${BRANCH_VIEW_ROUTE}`,
          redirectTo: `/${BRANCH_VIEW_ROUTE}/${NavRoutes.PUBLIC.HOSPITAL.TABS.OVERVIEW}`,
          public: true,
          routes: [
            {
              path: `/${BRANCH_VIEW_ROUTE}/${NavRoutes.PUBLIC.HOSPITAL.TABS.OVERVIEW}`,
              exact: true,
              component: OverViewComponent
            },
            {
              path: `/${BRANCH_VIEW_ROUTE}/${NavRoutes.PUBLIC.HOSPITAL.TABS.DOCTORS}`,
              exact: true,
              component: DoctorsComponent
            },
            {
              path: `/${BRANCH_VIEW_ROUTE}/${NavRoutes.PUBLIC.HOSPITAL.TABS.SERVICES}`,
              exact: true,
              component: ServicesComponent
            },
            {
              path: `/${BRANCH_VIEW_ROUTE}/${NavRoutes.PUBLIC.HOSPITAL.TABS.FEEDBACK}`,
              exact: true,
              component: FeedbackComponent
            },
            {
              path: `/${BRANCH_VIEW_ROUTE}/${NavRoutes.PUBLIC.HOSPITAL.TABS.OTHER_LOCATIONS}`,
              exact: true,
              component: OtherLocationsComponent
            }
          ]
        },
        // include practice
        {
          path: `/${PRACTICE_VIEW_ROUTE}`,
          component: HospitalViewComponent,
          exactFrom: `/${PRACTICE_VIEW_ROUTE}`,
          redirectTo: `/${PRACTICE_VIEW_ROUTE}/${NavRoutes.PUBLIC.HOSPITAL.TABS.OVERVIEW}`,
          public: true,
          routes: [
            {
              path: `/${PRACTICE_VIEW_ROUTE}/${NavRoutes.PUBLIC.HOSPITAL.TABS.OVERVIEW}`,
              exact: true,
              component: OverViewComponent
            },
            {
              path: `/${PRACTICE_VIEW_ROUTE}/${NavRoutes.PUBLIC.HOSPITAL.TABS.DOCTORS}`,
              exact: true,
              component: DoctorsComponent
            },
            {
              path: `/${PRACTICE_VIEW_ROUTE}/${NavRoutes.PUBLIC.HOSPITAL.TABS.FEEDBACK}`,
              exact: true,
              component: FeedbackComponent
            },
            {
              path: `/${PRACTICE_VIEW_ROUTE}/${NavRoutes.PUBLIC.HOSPITAL.TABS.OTHER_LOCATIONS}`,
              exact: true,
              component: OtherLocationsComponent
            }
          ]
        },
        {
          path: `/${DOCTOR_VIEW_ROUTE}`,
          component: DoctorViewComponent,
          exactFrom: `/${DOCTOR_VIEW_ROUTE}`,
          redirectTo: `/${DOCTOR_VIEW_ROUTE}/${NavRoutes.PUBLIC.DOCTOR.TABS.INFO}`,
          public: true,
          routes: [
            {
              path: `/${DOCTOR_VIEW_ROUTE}/${NavRoutes.PUBLIC.DOCTOR.TABS.INFO}`,
              exact: true,
              component: InfoComponent
            },
            {
              path: `/${DOCTOR_VIEW_ROUTE}/${NavRoutes.PUBLIC.DOCTOR.TABS.EDUCATION_EXPERIENCE}`,
              exact: true,
              component: EducationComponent
            },
            {
              path: `/${DOCTOR_VIEW_ROUTE}/${NavRoutes.PUBLIC.DOCTOR.TABS.AWARDS_RECOGNITIONS}`,
              exact: true,
              component: AwardsComponent
            },
            {
              path: `/${DOCTOR_VIEW_ROUTE}/${NavRoutes.PUBLIC.DOCTOR.TABS.PUBLICATIONS}`,
              exact: true,
              component: PublicationsComponent
            },
            {
              path: `/${DOCTOR_VIEW_ROUTE}/${NavRoutes.PUBLIC.DOCTOR.TABS.MEMBERSHIP}`,
              exact: true,
              component: MembershipComponent
            },
            {
              path: `/${DOCTOR_VIEW_ROUTE}/${NavRoutes.PUBLIC.DOCTOR.TABS.FEEDBACK}`,
              exact: true,
              component: FeedbackComponentDoctor
            }
          ]
        },
        {
          path: `/${DOCTOR_LIST_ROUTE}`,
          component: DoctorListComponent
        },
        {
          path: `/${BRANCH_LIST_ROUTE}`,
          component: HospitalListComponent
        },
        {
          path: `/${BASE_ROUTE_MANAGE}/${NavRoutes.PUBLIC.MANAGE.EDIT_USER}`,
          component: EditUserComponent
        }
      ]
    },
    {
      path: `/${BASE_ROUTE_USER}`,
      component: UserHomePageComponent,
      exactFrom: `/${BASE_ROUTE_USER}`,
      redirectTo: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.DASHBOARD}`,
      public: true,
      routes: [
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.DASHBOARD}`,
          component: UserDashboardComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.MULTI_LANGUAGE}`,
          component: MultiLanguageComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.APPOINTMENTS}`,
          component: Appointments
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.VIRTUAL_CLINIC_DASHBOARD}`,
          component: VirtualClinicDashboardComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.NURSE_DASHBOARD}`,
          component: NurseDashboardComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.PERSON_WEB_TIMELINE}`,
          component: PersonWebTimeline
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.DOCTOR_WEB_TIMELINE}`,
          component: DoctorWebTimeline
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.PRESCRIPTION_DETAILS}`,
          component: PrescriptionDetails
        },

        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.HOME_SCREEN}`,
          component: UserHomeScreen
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.PATIENT_DASHBOARD}`,
          component: PatientDashboardScreen
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ASK_QUESTION_SHARED}`,
          component: ForumAskQuestionComponentShared
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.REPLIES_QUESTION_SHARED}/:question_id`,
          component: ForumRepliesComponentShared
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.DOCTOR_DASHBOARD}`,
          component: DoctorDashboardScreen
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.OP_DASHBOARD}`,
          component: OpDashboardScreen
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.VIRTUAL_CLINIC_DASHBOARD_NEW}`,
          component: VirtualClinicDashboardScreen
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.VIEW_PATIENT_HISTORY}`,
          component: ViewPatientHistory
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.OBSERVATIONS}`,
          component: PatientObservations
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ALL_TEMPLATES}`,
          component: AllTemplates
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ALL_MICRO_TEMPLATE}`,
          component: AllMicroTemplate
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.NEW_SMS_PLAN_COMPONENT}`,
          component: NewSmsPlanComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.SMS_CREDIT_COMPONENT}`,
          component: SmsCreditComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.CREDIT_PLAN_BY_BRANCH}`,
          component: CreditPlanByBranch
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.SMS_TABS_COMPONENT}`,
          component: SmsTabsComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.AGREEMENT}`,
          component: AgreementComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.VIEW_AGREEMENTS}`,
          component: AgreementListComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.ADD_AGREEMENT}`,
          component: AgreementCreationComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.ALL_INVOICES_COMPONENT}`,
          component: AllInvoicesComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.CUSTOM_TEMPLATES}`,
          component: TabTemplates
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.PREVIEW_TEMPLATE}`,
          component: PreviewTemplate
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.MEDICINE_TEMPLATE}`,
          component: MedicineTemplate
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.NEW_TEMPLATE}`,
          component: NewTemplate
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.APPOINTMENT}`,
          component: Appointment
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.SYMPTOMPS_TEMPLATE}`,
          component: SymptomsTemplate
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.BILLING.BILLINGHOMEPAGE}`,
          component: BillingHomePage
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.BILLING.BILLING_PATIENT_SCREEN}`,
          component: SelectedPatient
        },
        // Access Control screens
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.LIST_OF_MODULES}`,
          component: ActivateModuleComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.ASSIGN_SCREEN_TO_MODULE}`,
          component: NewModuleComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.USER_ROLES}`,
          component: UserRolesComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.PRACTICE_LIST}`,
          component: PracticeListComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.ADD_PRACTICE}`,
          component: AddManagePracticeComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.PRACTICE_PLANS}`,
          component: PracticePlansComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.BRANCH_PLANS}`,
          component: BranchPlansComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.BRANCH_SELF_PLANS}`,
          component: BranchTabsComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MYCART}`,
          component: BranchMyCart
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.PRACTICE_SELECTION}`,
          component: PracticeSelectionComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.CART_COMPONENT}`,
          component: CartComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.LIST_OF_SCREENS_WITH_MODULES}`,
          component: CreateEditModuleComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.NEW_USER}`,
          component: NewUserComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MENU_STRUCTURE}`,
          component: MenuStructureComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.ADD_NEW_ROLES}`,
          component: AddNewRoleComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.EDIT_NEW_ROLES}`,
          component: EditRoleComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.ONBOARD_TABS}`,
          component: OnBoardTabComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.EDIT_USER_DETAILS}`,
          component: EditUserDetailsComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.HEALPHA_CHARGES}`,
          component: HealphaChargesComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.BASE}`,
          exact: true,
          component: ManagePracticeTabsComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.ADD_DETAILS}`,
          exact: true,
          component: ManageUserPracticeAddDetailsComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.ADD_CHILD_HEALTH}`,
          exact: true,
          component: ManageAddChildHealthDetailComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.ADD_CORPORATE_DETAILS}`,
          exact: true,
          component: ManageAddCorporateDetailComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.ADD_VIRTUAL_CLINIC_DETAILS}`,
          exact: true,
          component: ManageAddVirtualDetailComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.ADD_LAB_DETAILS}`,
          exact: true,
          component: ManageLabAddDetailComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.ADD_PHARMACY_DETAILS}`,
          exact: true,
          component: ManagePharmacyAddDetailComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.BASE}`,
          exact: true,
          component: ManageBranchTabsComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.ADD_DETAIL}`,
          exact: true,
          component: ManageBranchAddDetailsComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.CHILD_HEALTH}`,
          exact: true,
          component: BranchChildHealthDetailComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.CORPORATE_DETAILS}`,
          exact: true,
          component: BranchCorporateDetailComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.VIRTUAL_CLINIC_DETAILS}`,
          exact: true,
          component: BranchVirtualDetailComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.LAB_DETAILS}`,
          exact: true,
          component: BranchLabDetailComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.PHARMACY_DETAILS}`,
          exact: true,
          component: BranchPharmacyDetailComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE_PARAMETER}`,
          exact: true,
          component: ManagePracticeParameterComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_CHILDHEALTH_PARAMETER}`,
          exact: true,
          component: ManageChildHealthParameterComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_CORPORATE_PARAMETER}`,
          exact: true,
          component: ManageCorporateParameterComponent
        },
        {
          path: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_VIRTUAL_CLINIC_PARAMETER}`,
          exact: true,
          component: ManageVirtualClinicParameterComponent
        }
      ]
    },
    {
      path: `/${BASE_ROUTE_LANDING}`,
      component: LandingHomepage,
      exactFrom: `/${BASE_ROUTE_LANDING}`,
      redirectTo: `/${BASE_ROUTE_LANDING}/${NavRoutes.PUBLIC.DASHBOARD}`,
      public: true,
      routes: [
        {
          path: `/${BASE_ROUTE_LANDING}/${NavRoutes.PUBLIC.PAYMENT_SUCCESS}`,
          component: PaymentPageComponent
        },
        {
          path: `/${BASE_ROUTE_LANDING}/${NavRoutes.PUBLIC.SUCCESS}`,
          component: SuccessPageComponent
        }
      ]
    },
    {
      path: `/${BASE_ROUTE_SELF_SERVICE}`,
      component: SelfServicePortal,
      exactFrom: `/${BASE_ROUTE_SELF_SERVICE}`,
      redirectTo: `/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.DASHBOARD}`,
      public: true,
      routes: [
        {
          path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.DOCTOR_LOGINPAGE}`,
          component: DoctorLoginPage
        },
        {
          path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.LOGIN}`,
          component: UserAuthenticationComponent
        },
        {
          path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.SIGN_UP}`,
          component: UserAuthenticationComponent
        },
        {
          path: `/${BASE_ROUTE_SELF_SERVICE}`,
          component: SelfServiceHomePageComponent,
          exactFrom: `/${BASE_ROUTE_SELF_SERVICE}`,
          redirectTo: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.DASHBOARD}`,
          public: true,
          routes: [
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.DASHBOARD}`,
              component: SelfServiceDashboard
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PROFILE}`,
              component: UserProfileComponent
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}`,
              exactFrom: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}`,
              redirectTo: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.INFO}`,
              public: true,
              component: UserPracticeComponent,
              routes: [
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.INFO}`,
                  exact: true,
                  component: UserPracticeInfoComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.BANK_DETAILS}`,
                  exact: true,
                  component: UserPracticeBankDetailsComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ABOUT}`,
                  exact: true,
                  component: UserPracticeAboutComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.LOCATION}`,
                  exact: true,
                  component: UserPracticeLocationComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.FEATURES}`,
                  exact: true,
                  component: UserPracticeFeaturesComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_DETAILS}`,
                  exact: true,
                  component: UserPracticeAddDetailsComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_CHILD_HEALTH}`,
                  exact: true,
                  component: UserPracticeAddChildHealthDetailComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_CORPORATE_DETAILS}`,
                  exact: true,
                  component: UserPracticeAddCorporateDetailComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_VIRTUAL_CLINIC_DETAILS}`,
                  exact: true,
                  component: PracticeVirtualClinicComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_LAB_DETAILS}`,
                  exact: true,
                  component: UserPracticeLabAddDetailsComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_PHARMACY_DETAILS}`,
                  exact: true,
                  component: UserPharmacyAddDetailsComponent
                }
              ]
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}`,
              exactFrom: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}`,
              redirectTo: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.INFO}`,
              public: true,
              component: UserBranchesComponent,
              routes: [
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.INFO}`,
                  exact: true,
                  component: UserPracticeInfoComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.BANK_DETAILS}`,
                  exact: true,
                  component: UserPracticeBankDetailsComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ABOUT}`,
                  exact: true,
                  component: UserPracticeAboutComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.LOCATION}`,
                  exact: true,
                  component: UserPracticeLocationComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.FEATURES}`,
                  exact: true,
                  component: UserPracticeFeaturesComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_DETAILS}`,
                  exact: true,
                  component: UserPracticeAddDetailsComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_CHILD_HEALTH}`,
                  exact: true,
                  component: UserPracticeAddChildHealthDetailComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_CORPORATE_DETAILS}`,
                  exact: true,
                  component: UserPracticeAddCorporateDetailComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_VIRTUAL_CLINIC_DETAILS}`,
                  exact: true,
                  component: PracticeVirtualClinicComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_LAB_DETAILS}`,
                  exact: true,
                  component: UserPracticeLabAddDetailsComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}/${NavRoutes.PUBLIC.USER.PRACTICE.TABS.ADD_PHARMACY_DETAILS}`,
                  exact: true,
                  component: UserPharmacyAddDetailsComponent
                }
              ]
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.INTERMEDIATE_REVIEW}`,
              component: SelfServiceIntermediateReviewComponent
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.DOCTOR}`,
              component: SelfServiceDoctorComponent
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PAYMENTS.BASE}`,
              component: UserPaymentComponent,
              exactFrom: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PAYMENTS.BASE}`,
              redirectTo: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PAYMENTS.BASE}/${NavRoutes.PUBLIC.USER.PAYMENTS.REVIEW}`,
              routes: [
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PAYMENTS.BASE}/${NavRoutes.PUBLIC.USER.PAYMENTS.REVIEW}`,
                  component: UserPaymentReviewComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PAYMENTS.BASE}/${NavRoutes.PUBLIC.USER.PAYMENTS.INVOICE}`,
                  component: UserPaymentInvoiceComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PAYMENTS.BASE}/${NavRoutes.PUBLIC.USER.PAYMENTS.HISTORY}`,
                  component: UserPaymentHistoryComponent
                }
              ]
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}`,
              component: UserPracticeSetupComponent,
              exactFrom: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}`,
              redirectTo: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.PARAMETERS}`,
              routes: [
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.PARAMETERS}`,
                  component: UserPracticeParamatersComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.SERVICE_GROUP}`,
                  component: UserPracticeServiceGroupMasterComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.SERVICE_MASTER}`,
                  component: UserPracticeServiceMasterComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.ADD_USER}`,
                  component: AddUserComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.ADD_ROLE}`,
                  component: AddRoleComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.EDIT_ROLE}`,
                  component: EditRole
                },

                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.PATIENT}`,
                  component: UserPatientComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.USERS}`,
                  component: UserPracticeServiceUsersComponent
                }
              ]
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}`,
              component: UserPracticeChildHealthComponent,
              exactFrom: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}`,
              redirectTo: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.SCHOOL_PARAMETERS}`,
              routes: [
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.SCHOOL_PARAMETERS}`,
                  component: UserSchoolParamatersComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.USERS}`,
                  exact: true,
                  component: UserPracticeChildHealthUsersComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.ADD_CLASSES_DETAILS}`,
                  exact: true,
                  component: UserModuleSetupClasses
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.ADD_STUDENTS_DETAILS}`,
                  exact: true,
                  component: UserModuleSetupStudents
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.ADD_USER}`,
                  component: AddUserComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.ADD_ROLE}`,
                  component: AddRoleComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.EDIT_ROLE}`,
                  component: EditRole
                }
              ]
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_VIRTUAL_CLINIC.BASE}`,
              component: UserPracticeVirtualClinicComponent,
              exactFrom: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_VIRTUAL_CLINIC.BASE}`,
              redirectTo: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_VIRTUAL_CLINIC.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_VIRTUAL_CLINIC.VIRTUAL_CLINIC_PARAMETERS}`,
              routes: [
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_VIRTUAL_CLINIC.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_VIRTUAL_CLINIC.VIRTUAL_CLINIC_PARAMETERS}`,
                  component: UserVirtualClinicParamatersComponent
                }
              ]
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.BASE}`,
              component: UserPracticeLabComponent,
              exactFrom: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.BASE}`,
              redirectTo: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.LAB_PARAMETERS}`,
              routes: [
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.LAB_PARAMETERS}`,
                  component: UserLabParametersComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.ADD_USER}`,
                  component: AddUserComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.ADD_ROLE}`,
                  component: AddRoleComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_LAB.EDIT_ROLE}`,
                  component: EditRole
                }
              ]
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.BASE}`,
              component: UserPracticePharmacyComponent,
              exactFrom: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.BASE}`,
              redirectTo: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.PHARMACY_PARAMETERS}`,
              routes: [
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.PHARMACY_PARAMETERS}`,
                  component: UserPharmacyParametersComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.ADD_USER}`,
                  component: AddUserComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.ADD_ROLE}`,
                  component: AddRoleComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_PHARMACY.EDIT_ROLE}`,
                  component: EditRole
                }
              ]
            },
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}`,
              component: UserPracticeCorporateComponent,
              exactFrom: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}`,
              redirectTo: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.CORPORATE_PARAMETERS}`,
              routes: [
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.CORPORATE_PARAMETERS}`,
                  component: UserCorporateParamatersComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.ADD_EMPLOYEE_DETAILS}`,
                  exact: true,
                  component: UserCorporateEmployeeComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.USERS}`,
                  exact: true,
                  component: UserPracticeCorporateUsersComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.DEPARTMENT}`,
                  exact: true,
                  component: UserCorporateDepartmentComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.ADD_USER}`,
                  component: AddUserComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.ADD_ROLE}`,
                  component: AddRoleComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.EDIT_ROLE}`,
                  component: EditRole
                }
              ]
            },

            // Subscription routing
            {
              path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.BASE}`,
              component: SubscriptionComponent,
              exactFrom: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.BASE}`,
              redirectTo: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.BASE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.MY_SUBSCRIPTIONS}`,
              routes: [
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.BASE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.MY_SUBSCRIPTIONS}`,
                  component: MySubscriptionsComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.BASE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.MY_CART}`,
                  component: MyCartComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.BASE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.VIEW_PLANS}`,
                  component: ViewPlansComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.BASE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.VIEW_CART}`,
                  component: ViewCartComponent
                },
                {
                  path: `/${BASE_ROUTE_SELF_SERVICE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.BASE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.SUBSCRIPTION_REDIRECT_BACK}`,
                  component: subscriptionRedirectComponent
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default PortalRoute;
