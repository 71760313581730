import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

import {getStatusCount} from '@shared/services/PublicService';
import {getVirtualAppointmentStatusList} from '@shared/services/CommonService';
import {CommunicationService} from 'src/service/CommunicationService';

import Icons from '@components/system/MyIcons';
import {DefaultAppointmentStatusCount} from 'src/constants/SystemConstants';
import Status from './Status/Status';
import CalendarNew from './CalendarNew/CalendarNew';
import moment from 'moment';
import {STANDALONE_APPOINTMENT_UPDATE} from 'src/constants/CommunicationServiceConstants';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import './Calendar.scss';

const Calendar = (props: any): React.ReactElement => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const subscription: any = React.useRef(null);
  const [value, setValue] = useState(moment());
  const [dateValue, setDateValue] = React.useState('');
  const [showHide, setShowHide] = useState(false);
  const [statusCountData, setStatusCountData] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] =
    React.useState<number | undefined>(undefined);
  const [defaultShowItemsCount, setDefaultShowItemsCount] =
    React.useState<number>(DefaultAppointmentStatusCount);

  const {
    docId,
    branchId,
    setClickedStatus,
    clickedStatus,
    handleAppointmentDetails
  } = props;

  const getTheSelectedDate = (data: any) => {
    setDateValue(data);
    if (props?.setSelectedDate && typeof props.setSelectedDate === 'function') {
      props.setSelectedDate(moment(data));
    }
  };

  const handleShowHide = (e: any) => {
    if (showHide) {
      setDefaultShowItemsCount(DefaultAppointmentStatusCount);
    } else {
      setDefaultShowItemsCount(statusCountData?.length);
    }
    setShowHide(ev => !showHide);
  };

  const displayStatusCount = (allStatusCount: any) => {
    const newObject: any = [];
    const keyObject: any = [];
    if (allStatusCount) {
      for (const status in allStatusCount) {
        if (status) {
          const d = allStatusCount[status];
          if (d) {
            for (const keys in d) {
              if (keys) {
                keyObject.push(keys);
              }
            }
          }
          break;
        }
      }
    }

    if (keyObject) {
      const object: any = {};
      for (const k of keyObject) {
        object['label'] = k;
      }
      if (allStatusCount) {
        for (const oneStatus in allStatusCount) {
          if (oneStatus) {
            object[oneStatus] = allStatusCount[oneStatus];
            newObject.push(object[oneStatus]);
          }
        }
      }
    }
    return newObject;
  };

  const handleStatusCount = async (val: any) => {
    if (
      (props?.isFromClinicalDashboard || docId) && branchId && dateValue
        ? dateValue
        : val?.date
    ) {
      if (props?.isFromVirtualClinic) {
        getVirtualAppointmentStatusList(
          branchId,
          dateValue ? dateValue : val?.date
        )
          .then(res => {
            const getStatusCountNew = displayStatusCount(
              res.data?.['appointment_status']
            );
            setStatusCountData(getStatusCountNew);
            props.setStatusCount &&
              props.setStatusCount(getStatusCountNew.length);
          })
          .catch(err => {
            enqueueSnackbar(
              err?.message ? err.message : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          });
      } else {
        getStatusCount(
          props?.isNurse ||
            props?.isAdminNurse ||
            (props?.isFrontOffice && !docId)
            ? ''
            : docId,
          branchId,
          dateValue ? dateValue : val?.date
        )
          .then(res => {
            const getStatusCountNew = displayStatusCount(
              res.data?.['appointment_status']
            );
            setStatusCountData(getStatusCountNew);
            props.setStatusCount &&
              props.setStatusCount(getStatusCountNew.length);
          })
          .catch(err => {
            enqueueSnackbar(
              err?.message ? err.message : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          });
      }
    }
  };

  const handleClickedStatus = (data: any, statusCount: any, index: any) => {
    if (selectedIndex !== index) {
      setSelectedIndex(index);
    } else {
      setSelectedIndex(undefined);
    }
  };

  const init = () => {
    handleStatusCount({});
    setSelectedIndex(undefined);
    setShowHide(false);
    setClickedStatus('');
  };

  React.useEffect(init, [docId, branchId, dateValue]);

  React.useEffect(() => {
    subscription.current = CommunicationService.getMessage().subscribe(
      function (message: any) {
        if (
          message?.data?.type === STANDALONE_APPOINTMENT_UPDATE &&
          message?.data?.value === true
        ) {
          handleStatusCount({});
        }
      }
    );

    return () => {
      return subscription?.current?.unsubscribe();
    };
  });

  const setEvent = () => {
    window.addEventListener('handleStatusCount', (e: any) => {
      handleStatusCount({date: e.detail.date});
      getTheSelectedDate(e.detail.date);
    });
    return () => {
      window.removeEventListener('handleStatusCount', () => {
        handleStatusCount({});
      });
    };
  };
  React.useEffect(setEvent, []);

  const renderCalendarNew = (): React.ReactElement => {
    return (
      <Grid xs={12} container item spacing={1}>
        <CalendarNew
          value={value}
          setValue={setValue}
          getTheSelectedDate={getTheSelectedDate}
        />
      </Grid>
    );
  };

  const renderStatusCard = (): React.ReactElement => {
    {
      // console.log('the status', statusCountData);
    }

    return (
      <Grid
        container
        spacing={1}
        className="transitionDiv"
        justifyContent="flex-start">
        {statusCountData
          ?.slice(0, defaultShowItemsCount)
          ?.map((curStatus: any, index: any) => {
            return (
              <Status
                key={index}
                keyGiven={curStatus.key}
                {...curStatus}
                handleClickedStatus={handleClickedStatus}
                selectedIndex={selectedIndex}
                index={index}
                setClickedStatus={setClickedStatus}
                clickedStatus={clickedStatus}
                handleAppointmentDetails={handleAppointmentDetails}
              />
            );
          })}
      </Grid>
    );
  };
  return (
    <Grid item xs={12} className="calendar-wrapper" spacing={2}>
      {renderCalendarNew()}
      {renderStatusCard()}
      <Grid container item xs={12} className="hideIcon">
        <Grid item className="icon" id="drop_arrow">
          {statusCountData?.length > 6 &&
            (showHide ? (
              <Icons.DownArrowBlackIcon
                className="show-hide-icon"
                color="secondary"
                onClick={handleShowHide}
                cursor="pointer"
              />
            ) : (
              <Icons.DownArrowBlackIcon
                onClick={handleShowHide}
                cursor="pointer"
              />
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Calendar;
