import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as CaptureVitalsIconSvg} from '@assets/images/healpha/captureVitals.svg';

const CaptureVitalsIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={CaptureVitalsIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default CaptureVitalsIcon;
