/* eslint-disable camelcase */
import React from 'react';
import {Grid, Typography, IconButton, Checkbox} from '@material-ui/core';
import Icons from '@components/system/MyIcons/index';

import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';

import ShowAttachments from './ShowAttachments';
import UploadAttachmentDialog from 'src/modules/shared/UploadAttachment/UploadAttachmentDialog';
import AuthUtils from 'src/utils/AuthUtils';
import {
  addImagingAttachments,
  updateImagingData
} from '@shared/services/PublicService';
import moment from 'moment';
import CommonUtils from 'src/utils/CommonUtils';
import './../Medicine/ShowMedcineData/ShowMedicineData.scss';

const DisplayImagingData = (props: any): React.ReactElement => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const {
    id,
    service_name,
    description,
    vaccine_details,
    batch_no,
    expiry_date,
    type,
    deleted,
    attachments,
    handleDeleteAttachment,
    isNurse,
    doctorID,
    service_status,
    services,
    service_details
  } = props;
  const [showAttachmentPopUp, setShowAttachmentPopUp] = React.useState(false);
  const [showUploadAttachmentPopUp, setShowUploadAttachmentPopUp] =
    React.useState(false);
  const personId = AuthUtils.getHealphaId();
  const docId = isNurse ? doctorID : AuthUtils.getDoctorId();
  const encId = AuthUtils.getEncId();
  const closeShowUploadAttachmentPopUp = (): void => {
    setShowUploadAttachmentPopUp(false);
  };
  const isStandAloneDoctor = CommonUtils.checkIsStandAloneDoctor();

  const d = new Date(expiry_date);
  const createdDate = moment(d).format('MMM DD, YYYY');

  const handleShowAttachments = () => {
    setShowAttachmentPopUp(true);
  };

  const closeShowAttachmentPopUp = () => {
    setShowAttachmentPopUp(false);
  };

  const renderShowAttachmentPopUp = () => {
    return (
      <ShowAttachments
        open={showAttachmentPopUp}
        closeShowAttachmentPopUp={closeShowAttachmentPopUp}
        attachments={attachments}
        type={type}
        handleDeleteAttachment={handleDeleteAttachment}
      />
    );
  };

  const handleAddAttachment = (file: any, file_name?: any) => {
    return (
      file_name?.length > 0 &&
      addImagingAttachments(encId, docId, personId, file, type, id)
        .then(res => {
          enqueueSnackbar(res?.message, SnackBarConfig('s'));
          props?.handleGetImagingData(type, true);
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        })
    );
  };

  const renderShowUploadAttachmentPopUp = (): React.ReactElement => {
    return (
      <UploadAttachmentDialog
        open={showUploadAttachmentPopUp}
        closeShowUploadAttachmentPopUp={closeShowUploadAttachmentPopUp}
        handleAddAttachment={handleAddAttachment}
      />
    );
  };

  const handleUpdatingService = () => {
    const temp = {service_status: 'Sample Collected'};

    updateImagingData(encId, docId, personId, type, id, temp)
      .then(res => {
        enqueueSnackbar(res?.message, SnackBarConfig('s'));
        props?.handleGetImagingData(type, true);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleUpdateSample = (event: any) => {
    event?.target?.checked && handleUpdatingService();
  };

  const conditionForUpload =
    isNurse &&
    (type == 'nursing' || type == 'lab') &&
    (service_status == null || service_status == '');
  return (
    <Grid
      className={
        deleted
          ? 'inside-medicine-grid delete-strike-through'
          : 'inside-medicine-grid'
      }
      item
      container
      xs={12}
      sm={isNurse && type == 'lab' ? 12 : 6}>
      <Grid item xs={12} className="medicine-info">
        <Grid item xs={1} className="icon-grid">
          {type == 'imaging' ? (
            <IconButton>
              <Icons.ImagingIcon />
            </IconButton>
          ) : type == 'nursing' ? (
            <IconButton>
              <Icons.NursingIcon />
            </IconButton>
          ) : type == 'lab' ? (
            <IconButton>
              <Icons.LabIcon />
            </IconButton>
          ) : (
            <IconButton>
              <Icons.VaccineIcon />
            </IconButton>
          )}
        </Grid>
        <Grid container className="medicine-data">
          <Grid
            item
            xs={isNurse ? (type !== 'nursing' ? 6 : 8) : 10}
            className={
              (type == 'vaccine' ? batch_no : description)
                ? 'medic-box'
                : 'medic-box align-center-to-box'
            }>
            <Typography className="inside-medicines">
              <Grid className="medic-name-grid">
                <Typography className="medic-name">
                  {type == 'vaccine'
                    ? vaccine_details?.vaccine_brand_name
                    : service_name}
                </Typography>
              </Grid>
              <Grid>
                <Typography className="medic-des">
                  {type == 'vaccine'
                    ? batch_no && `Batch_no: ${batch_no} Date: ${createdDate}`
                    : description}
                </Typography>
              </Grid>
            </Typography>
          </Grid>
          {isNurse &&
            type == 'lab' &&
            (services?.payment_status == 'Paid' ||
              service_details?.payment_status == 'Paid') && (
              <Grid
                item
                xs={isNurse && type == 'lab' ? 4 : 10}
                className="medic-box">
                <Checkbox
                  checked={service_status}
                  onChange={(e: any) => handleUpdateSample(e)}
                />{' '}
                {t('SAMPLE_COLLECTED')}
              </Grid>
            )}

          {isNurse &&
            type == 'nursing' &&
            (services?.payment_status == 'Paid' ||
              service_details?.payment_status == 'Paid') && (
              <Grid
                item
                xs={isNurse && type == 'nursing' ? 2 : 10}
                className="medic-box">
                <Checkbox
                  checked={service_status}
                  onChange={(e: any) => handleUpdateSample(e)}
                />
              </Grid>
            )}

          {(isStandAloneDoctor ||
            (isNurse &&
              (services?.payment_status == 'Paid' ||
                service_details?.payment_status == 'Paid'))) && (
            <Grid item container xs={2}>
              {attachments?.length > 0 ? (
                <Grid container>
                  <Grid item xs={6}>
                    <Icons.AddAttachmentIcon
                      id={'attachment_icon' + id}
                      className="attachment-icons"
                      onClick={() => handleShowAttachments()}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Icons.UploadIcon
                      id={'upload_icon_' + id}
                      disabled={conditionForUpload}
                      className="attachment-icons"
                      onClick={() => setShowUploadAttachmentPopUp(true)}
                    />
                  </Grid>
                </Grid>
              ) : (
                <IconButton
                  id={'upload_icon_' + id}
                  disabled={conditionForUpload}
                  onClick={() => setShowUploadAttachmentPopUp(true)}>
                  <Icons.UploadIcon />
                </IconButton>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>

      {showAttachmentPopUp && renderShowAttachmentPopUp()}
      {showUploadAttachmentPopUp && renderShowUploadAttachmentPopUp()}
    </Grid>
  );
};

export default DisplayImagingData;
