import React from 'react';
import {Box, TextField, Autocomplete} from '@material-ui/core';
import {CustomAutoCompleteFieldProps} from '@components/system/CustomAutoCompleteField/CustomAutoCompleteField';

export default function SelectDropdown(
  props: Omit<CustomAutoCompleteFieldProps, 'defaultValue' | 'debounce'> & {
    value: string;
  }
): React.ReactElement {
  // const appointmentTypes = [
  //   {id: '1', text: 'General'},
  //   {id: '2', text: 'Specific'}
  // ];
  const {
    field,
    options,
    id = 'healpha-select-dropdown',
    value,
    placeHolder,
    onChange
  } = props;
  const getOptionLabel = (param: any): any => {
    const option: any = param;
    if (typeof option === 'string') {
      return option ? option : '';
    } else {
      return option && option[field] ? option[field] : '';
    }
  };
  return (
    <Autocomplete
      id={id}
      sx={{width: 300}}
      options={options}
      autoHighlight
      value={value || ''}
      getOptionLabel={option => getOptionLabel(option)}
      disableClearable
      onChange={(e: any, val) => {
        if (onChange && typeof onChange === 'function') {
          onChange(val);
        }
      }}
      renderOption={(properties, option) => (
        <Box component="li" {...properties}>
          {typeof option === 'object'
            ? option[field]
              ? option[field]
              : ''
            : option
            ? option
            : ''}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeHolder}
          variant="outlined"
          sx={{
            border: '1px solid lightgrey',
            borderRadius: '2px',
            p: 2
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off' // disable autocomplete and autofill
          }}
          // required
        />
      )}
    />
  );
}
