import {SET_SELF_SERVICE_STATUS} from '../actions/Index';

const initSelfService = {
  selfServiceStatusData: {
    is_profile_completed: false,
    is_doctor_registration_completed: false,
    is_practice_completed: false,
    is_branches_completed: false
  }
};

export default function (
  state = initSelfService,
  action: any = {}
): typeof initSelfService {
  const {type, payload} = action;

  switch (type) {
    case SET_SELF_SERVICE_STATUS:
      return {
        ...state,
        selfServiceStatusData: payload
      };

    default:
      return state;
  }
}
