import React, {ReactElement, useState, useEffect} from 'react';
import {TextField, Autocomplete, Button} from '@material-ui/core';

import Loader from '@components/framework/loader/Loader';
import useDebounce from 'src/utils/UseDebounce';
import SearchIcon from '../MyIcons/SearchIcon';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import {CommunicationService} from 'src/service/CommunicationService';
import {BACK_NAVIGATION} from 'src/constants/CommunicationServiceConstants';
import {
  APP_PRIMARY_COLOR,
  GET_8_PIXEL_BOX,
  DEFAULT_WHITE_COLOR,
  FONT_SECONDARY_COLOR,
  PLACEHOLDER_TEXT_COLOR
} from 'src/style/variable';
import './CustomAutoCompleteField.scss';

export interface CustomAutoCompleteFieldProps {
  id?: string; // pass the string that will be unique identifier for auto complete field
  className?: string; // pass the string that will be used to add styles to field
  options: Array<any>; // pass the array of items that needs to be display in the dropdown
  field: string; // pass the string which is used to render the option & grid and display the value
  infoField?: string; // pass the string which is used to render along with field as extra info
  placeHolder?: string; // pass the string which displays the place holder in CustomInputField
  onChange?: any; // pass the function which updates the default value
  onInputChange?: any; // pass the function which makes api call and update the options
  defaultValue?: any; // pass the default value that needs to be pre populated
  loading?: boolean; // pass true / false to display the loading information
  disabled?: boolean; // pass true / false to disable the auto complete field
  label?: string; // pass true / false to display the title,
  required?: boolean; // pass true / false to show the * mark,
  debounce: boolean;
  apiCallback?: any;
  onOpen?: any;
  onClose?: any;
  noOptionsText?: string;
  customTextFieldProps?: any;
  size?: any;
  fields?: any;
}

const textFieldProps = {
  height: '3.5rem',
  width: '100%',
  border: `1px solid ${APP_PRIMARY_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.75),
  background: `${DEFAULT_WHITE_COLOR} 0% 0% no-repeat padding-box`,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0
};

const optionProps = {
  marginLeft: GET_8_PIXEL_BOX(1),
  color: FONT_SECONDARY_COLOR,
  width: '100%',
  display: 'block',
  marginBottom: GET_8_PIXEL_BOX(1),
  textTransform: 'capitalize'
};

const CustomAutoCompleteField = (
  props: CustomAutoCompleteFieldProps
): ReactElement => {
  const {
    id,
    options,
    defaultValue,
    field,
    infoField,
    placeHolder,
    onChange,
    onInputChange,
    loading,
    apiCallback,
    debounce,
    noOptionsText,
    customTextFieldProps,
    size,
    className,
    fields
  } = props;

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 750);
  const [searchValue, setSearchValue] = useState<string | null>(defaultValue);
  const subscription: any = React.useRef(null);

  useEffect(
    () => {
      if (
        apiCallback &&
        typeof apiCallback === 'function' &&
        debouncedSearchText
      ) {
        apiCallback(debouncedSearchText, id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchText]
  );

  React.useEffect(() => {
    subscription.current = CommunicationService.getMessage().subscribe(
      function (message: any) {
        if (
          message?.data?.type === BACK_NAVIGATION &&
          message?.data?.value === true
        ) {
          setSearchText('');
          setSearchValue(null);
        }
      }
    );

    return () => {
      return subscription?.current?.unsubscribe();
    };
  });

  const handleOnChange = (event: any, newValue: any): void => {
    if (onChange && typeof onChange === 'function') {
      newValue?.type === 'route_link'
        ? setSearchValue(searchValue)
        : setSearchValue(newValue);
      onChange(newValue, id);
    }
  };

  const handleOnInputChange = (event: any, newInputValue: any): void => {
    if (typeof event?.target?.value === 'string') {
      if (onInputChange && typeof onInputChange === 'function') {
        onInputChange(newInputValue, id);
      }
      if (debounce) {
        setSearchText(event.target.value);
      }
    }
  };

  const getOptionLabel = (param: any): any => {
    const option: any = param;
    if (typeof option === 'string') {
      return option ? option : '';
    } else {
      return fields
        ? option && option[fields]
          ? option[fields]
          : ''
        : option && option[field]
        ? option[field]
        : '';
    }
  };

  const renderInput = (params: any): ReactElement => {
    return (
      <TextField
        autoFocus
        {...params}
        placeholder={placeHolder}
        size={size || 'medium'}
        sx={customTextFieldProps || textFieldProps}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <div>
              <SearchIcon color="disabled" />
            </div>
          ),
          endAdornment: (
            <div>{loading && <Loader color="inherit" size={20} />}</div>
          )
        }}
      />
    );
  };
  const renderOption = (properties: any, option: any): ReactElement => {
    return (
      <div style={optionProps} {...properties}>
        {option?.type === 'route_link' ? (
          <Button
            variant="text"
            className="view-more-button"
            endIcon={<NavigateNextOutlinedIcon />}>
            {option[field]}
          </Button>
        ) : typeof option === 'object' ? (
          fields ? (
            option[fields[0]] ? (
              option[fields[0]] +
              ' ' +
              option[fields[1]] +
              ' ' +
              option[fields[2]] +
              ' ' +
              ' | ' +
              option[fields[3]]
            ) : (
              ''
            )
          ) : option[field] ? (
            option[field]
          ) : (
            ''
          )
        ) : option ? (
          option
        ) : (
          ''
        )}
        {infoField && option[infoField] && (
          <div
            style={{
              color: PLACEHOLDER_TEXT_COLOR,
              lineHeight: GET_8_PIXEL_BOX(4),
              fontSize: '1.2rem'
            }}>
            {option[infoField]}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="custom-auto-complete-wrapper">
      <Autocomplete
        id={id}
        classes={{listbox: className}}
        options={options}
        value={searchValue || null}
        loading={loading}
        noOptionsText={noOptionsText || 'search'}
        renderInput={(params: any) => renderInput(params)}
        renderOption={(properties: any, option: any, state: any) =>
          null || renderOption(properties, option)
        }
        filterOptions={x => x}
        isOptionEqualToValue={(option: any, value: any) =>
          option[field] === value[field]
        }
        getOptionLabel={(option: any) => getOptionLabel(option)}
        onChange={(event: any, newValue: any | null) =>
          handleOnChange(event, newValue)
        }
        onInputChange={(event: any, newInputValue: string) =>
          handleOnInputChange(event, newInputValue)
        }
      />
    </div>
  );
};

export default CustomAutoCompleteField;
