import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import { ReactComponent as CovidCareIconSvg } from '@assets/images/healpha/covid_care.svg';

const CovidCareIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={CovidCareIconSvg} viewBox="0 0 301 235" />
  );
};

export default CovidCareIcon;