import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as RightTickIconSvg} from '@assets/images/healpha/rightTick.svg';

const RightTickIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={RightTickIconSvg}
      viewBox="0 0 24 24"
      fill={rest?.fill}
    />
  );
};

export default RightTickIcon;
