import {baseEnvironment} from './environment.base';
export const environment = {
  ...baseEnvironment,
  API_URL: '/stgEnvUrl',
  dev: false,
  production: false,
  baseUrl: 'https://core.rxconcent.com/api/v1',
  phpUrl: 'https://core.rxconcent.com/api',
  baseHost: 'rxconcent.com',
  encryptLocalStorage: true,
  encryptLocalStorageKey: 'f74808fb03f94ef8f64350a37918c1b0518c',
  azureClientId: '7f26bbad-0d50-4cdc-af9c-f33e42bb43d7',
  azureTenantId: '7cd4c483-9d86-49b2-ab98-97b0446f78af',
  portalUrl: 'https://core.rxconcent.com',
  paymentProvider: 'razorpay',
  paymentProviderKeyId: 'rzp_test_t6ZnfSMZKHcTNk',
  paymentCurrency: 'INR',
  countryCode: 'in',
  dialCode: '+91',
  mobileNumberLength: 10,
  pinCodeNumberLength: 6,
  instanceType: 'coreRx',
  currencyValue: 'Rs',
  languages: [
    {
      key: 'en',
      full_key: 'english',
      label: 'English'
    }
  ],
  country: {
    id: 101,
    sortname: 'IN',
    name: 'India',
    phonecode: 91,
    folderName: 'IND'
  },
  firebase: {
    apiKey: 'AIzaSyCbpdYpFAGMpRDYl5JPy2_qbM0Bs2_36Js',
    authDomain: 'rxconcent.firebaseapp.com',
    databaseURL: 'https://rxconcent.firebaseio.com',
    projectId: 'rxconcent',
    storageBucket: 'rxconcent.appspot.com',
    messagingSenderId: '937197281330',
    appId: '1:937197281330:web:740afda3a24ce3ce81dc2b',
    measurementId: 'G-M1N4VT26VC'
  },
  websiteNavigationUrl: 'https://rxconcent.com'
};
