import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import reduxThunk from 'redux-thunk';
import {SnackbarKey, SnackbarProvider} from 'notistack';
import {CssBaseline, IconButton, ThemeProvider} from '@material-ui/core';

import reducers from '@shared/store/reducers/Index';
import InitSharedRepo from '../../shared/index';

import './i18n';
import theme from './materialtheme';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {MAX_SNACKBARS} from './utils/SnackBarConfig';
import {Cancel} from '@material-ui/icons';
import SnackBar from '@components/framework/snackbar/SnackBar';
import AuthUtils from './utils/AuthUtils';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(reduxThunk))
);

InitSharedRepo.init();
InitSharedRepo.initAfterAuth(AuthUtils.getAuthToken());

const Index = (): React.ReactElement => {
  const notistackRef = React.createRef<any>();

  const onClickDismiss = (key: SnackbarKey) => () => {
    if (notistackRef?.current?.closeSnackbar) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  return (
    <BrowserRouter>
      <Provider store={store}>
        <React.StrictMode>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              ref={notistackRef}
              preventDuplicate
              hideIconVariant
              maxSnack={MAX_SNACKBARS}
              classes={{
                variantSuccess: 'custom-snackbar-class',
                variantError: 'custom-snackbar-class',
                variantWarning: 'custom-snackbar-class',
                variantInfo: 'custom-snackbar-class'
              }}
              action={key => {
                return (
                  <IconButton
                    style={{color: theme.palette.common.white}}
                    onClick={onClickDismiss(key)}>
                    <Cancel />
                  </IconButton>
                );
              }}>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
              <SnackBar />
            </SnackbarProvider>
          </ThemeProvider>
        </React.StrictMode>
      </Provider>
    </BrowserRouter>
  );
};
ReactDOM.render(<Index />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// registerServiceWorker()
