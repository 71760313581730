import {GET_8_PIXEL_BOX} from '../../style/variable';

export default {
  root: {
    transition: 'all 1s ease 0.1s',
    outline: '0 !important'
  },
  outlined: {
    borderRadius: `${GET_8_PIXEL_BOX(1)}`,
    outline: '0 !important',
    fontSize: '1.06rem',
    fontFamily: 'Nunito Sans, Verdana, Tahoma, sans-serif !important',
    fontWeight: 700,
    textTransform: 'none'
  },
  contained: {
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: '#FFFFFF',
    borderRadius: `${GET_8_PIXEL_BOX(1)}`,
    outline: '0 !important',
    fontSize: '1.06rem',
    fontFamily: 'Nunito Sans, Verdana, Tahoma, sans-serif !important',
    fontWeight: 700,
    textTransform: 'none'
  }
};
