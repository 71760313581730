/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';

import {sendOtpToUser, getAllUsers} from '@shared/services/PublicService';
import {environment} from '@shared/environment/environment';

import AuthUtils from 'src/utils/AuthUtils';
import CommonUtils from 'src/utils/CommonUtils';
import Icons from '@components/system/MyIcons/index';
import {
  genderOptionsList,
  salutationOptionsList
} from 'src/constants/FormFields';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import FormCombinationField from '@components/system/CustomFormField/FormCombinationField';
import FormPhoneField from '@components/system/CustomFormField/FormPhoneField';
import FormDatePickerField from '@components/system/CustomFormField/FormDatePickerField';
import RelationShipPopupComponent from '../RelationShipPopup/RelationShipPopupComponent';
import Loader from '@components/framework/loader/Loader';
import OtpScreenComponent from '../OtpScreen/OtpScreenComponent';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {
  DEFAULT_WHITE_COLOR,
  BORDER_TERTIARY_COLOR,
  GET_8_PIXEL_BOX
} from 'src/style/variable';
import './NewPersonRegistrationComponent.scss';
import moment from 'moment';

const textFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  border: `1px solid ${BORDER_TERTIARY_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.5),
  opacity: 1,
  fontSize: '1.2rem'
};

const NewPersonRegistrationComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {is_virtual_clinic = false, is_clinical_dashboard = false} = props;
  const isStandAloneDoctor = CommonUtils.checkIsStandAloneDoctor();
  const [open, setOpen] = React.useState(props?.open);
  const [openRelationPopup, setOpenRelationPopup] = React.useState(false);
  const [openOtpPopup, setOpenOtpPopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [familyHeadObject, setFamilyHeadObject]: any = React.useState({});
  const [values, setValues]: any = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    dob: null,
    gender: '',
    phone_code: environment.dialCode,
    phone_number: '',
    relation: '',
    salutation: 'Mr',
    is_virtual_clinic: isStandAloneDoctor ? false : is_virtual_clinic,
    branch_id: props?.branchId || AuthUtils.getMenuBranchId(),
    user_id: AuthUtils.getDoctorId(),
    doctor_id: isStandAloneDoctor ? AuthUtils.getDoctorId() : null
  });

  const handleClose = () => {
    setOpen(false);
    props?.closePersonSignUpPopup();
  };

  const handleChange = (prop: any) => (event: any) => {
    if (prop === 'first_name' || prop === 'last_name') {
      if (
        /^[a-zA-Z]+$/.test(event?.target?.value) ||
        event?.target?.value === ''
      ) {
        setValues({...values, [prop]: event.target.value});
      }
    } else {
      setValues({...values, [prop]: event.target.value});
    }
  };

  const handleDobChange = (prop: any) => (newValue: any) => {
    setValues({...values, [prop]: newValue});
  };

  const closeOtpPopup = (): void => {
    setOpenOtpPopup(false);
    handleClose();
  };

  const handleCountryChange = (
    value: any,
    country: any,
    e: any,
    formattedValue: any
  ): void => {
    const countryCode = formattedValue?.split(' ')?.[0];
    const phoneNumber = value?.substr(country?.dialCode?.length);

    if (phoneNumber?.length <= 10) {
      setValues({
        ...values,
        phone_code: countryCode,
        phone_number: phoneNumber
      });
    }
  };

  const handleSubmit = (): void => {
    if (
      !values?.first_name ||
      !values?.last_name ||
      !values?.phone_code ||
      !values?.phone_number ||
      !values?.dob ||
      !values?.gender
    ) {
      enqueueSnackbar(
        t(
          is_clinical_dashboard
            ? 'PERSON_DETAILS_REQUEST'
            : 'PERSON_DETAILS_REQ'
        ),
        SnackBarConfig('w')
      );
      return;
    }

    if (values?.phone_number?.length != environment.mobileNumberLength) {
      enqueueSnackbar(
        t('MOB_MAX_LENGTH_WARNING', {
          limit: environment.mobileNumberLength
        }),

        SnackBarConfig('w')
      );
      return;
    }

    if (values?.email && !CommonUtils.isEmailValid(values?.email)) {
      enqueueSnackbar(t('INVALID_EMAIL'), SnackBarConfig('w'));
      return;
    }

    if (!values.dob?._isValid) {
      enqueueSnackbar(
        t('PLEASE_SELECT_CORRECT_DATE_FORMAT'),
        SnackBarConfig('w')
      );
      return;
    }
    const isBefore = moment().isAfter(values.dob);
    if (!isBefore) {
      enqueueSnackbar(t('PLEASE_SELECT_CORRECT_DOB'), SnackBarConfig('w'));
      return;
    }

    const data: any = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      phone_code: values?.phone_code,
      phone_number: values?.phone_number,
      email: values?.email,
      dob: CommonUtils.getDobFormat(values?.dob),
      gender: values?.gender,
      branch_id: values?.branch_id,
      is_virtual_clinic: values?.is_virtual_clinic,
      user_id: values?.user_id
    };

    setIsLoading(true);
    getAllUsers(values?.phone_number)
      .then(res => {
        if (res?.data?.persons?.length > 0) {
          setIsLoading(false);
          setOpen(false);
          setOpenRelationPopup(true);

          res.data.persons?.map((item: any) => {
            if (item?.hlpid == res?.data?.family_head_id) {
              setFamilyHeadObject(item);
            }
          });
        } else {
          sendOtpToUser(data)
            .then(result => {
              setIsLoading(false);
              setOpen(false);
              setOpenOtpPopup(true);
            })
            .catch(err => {
              setIsLoading(false);
              enqueueSnackbar(
                err?.message ? err.message : t('ERROR_ENCOUNTERED'),
                SnackBarConfig('e')
              );
            });
        }
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const renderRelationPopup = (): React.ReactElement => {
    return (
      <RelationShipPopupComponent
        open={openRelationPopup}
        closeRelationPopup={handleClose}
        values={values}
        familyHeadObject={familyHeadObject}
        isFromVirtualClinic={is_virtual_clinic}
        isFromClinicalDashboard={is_clinical_dashboard}
        isFromStandAloneDashboard={isStandAloneDoctor ? true : false}
      />
    );
  };

  const renderOtpPopup = (): React.ReactElement => {
    return (
      <OtpScreenComponent
        open={openOtpPopup}
        values={values}
        closeOtpPopup={closeOtpPopup}
        isFromVirtualClinic={is_virtual_clinic}
        isFromClinicalDashboard={is_clinical_dashboard}
        isFromStandAloneDashboard={isStandAloneDoctor ? true : false}
      />
    );
  };

  const renderRadioButtons = (): React.ReactElement => {
    return (
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="person-gender"
          name="person-gender"
          onChange={handleChange('gender')}
          value={values?.gender}>
          {genderOptionsList?.map((item: any) => {
            return (
              <FormControlLabel
                key={item?.value}
                value={item?.value}
                sx={{marginBottom: 0}}
                className="new-person-radio-button"
                control={
                  <Radio size="medium" id={'gender-radio_' + item?.label} />
                }
                label={t(item?.label)}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  const renderLabel = (label: string): React.ReactElement => {
    return <Typography className="field-label-text">{label}</Typography>;
  };

  const renderFormSection = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="popup-title">
            {t('NEW_PATIENT_ADD_PATIENT')}
          </Typography>
          <Typography className="popup-sub-title">
            {t('CLINIC_PATIENT_DETAILS')}
          </Typography>
          <IconButton
            id="close_patient_popup"
            className="popup-close-icon"
            onClick={handleClose}>
            <Icons.CloseBlackIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item xs={12} md={6}>
            {renderLabel(t('First Name'))}
            <FormCombinationField
              dropDownName={'salutation'}
              dropdownValue={values['salutation']}
              callback={handleChange('salutation')}
              dropDownOptions={salutationOptionsList}
              textFieldCallBack={handleChange('first_name')}
              textFieldName={'first_name'}
              textFieldValue={values['first_name']}
              className="custom-text-filed-height"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {renderLabel(t('Last Name'))}
            <FormTextField
              name="last_name"
              value={values['last_name']}
              callback={handleChange('last_name')}
              className="custom-text-filed-height"
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item xs={12} md={6}>
            {renderLabel(t('Mobile Number'))}
            <FormPhoneField
              name="phone_number"
              callback={handleCountryChange}
              className="custom-phone-input-container"
              phoneNumber={
                (values?.phone_code || values?.phone_number) &&
                values?.phone_code + ' ' + values?.phone_number
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {renderLabel(t('Date of Birth'))}
            <FormDatePickerField
              id="calender"
              name="dob"
              value={values['dob']}
              textFieldProps={textFieldProps}
              Icon={Icons.DOBIcon}
              callback={handleDobChange('dob')}
              InputAdornmentPosition="end"
              className="custom-text-filed-height"
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {renderLabel(t('Gender'))}
          {renderRadioButtons()}
        </Grid>

        <Grid item xs={12}>
          {renderLabel(t('Email(Optional)'))}
          <FormTextField
            name="email"
            value={values['email']}
            callback={handleChange('email')}
            className="custom-text-filed-height"
          />
        </Grid>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            id="new-patient-detais-save"
            variant="contained"
            size="medium"
            style={{marginTop: '0.5rem'}}
            color="primary"
            className="new-person-save-button"
            disabled={isLoading}
            onClick={() => handleSubmit()}>
            {isLoading ? <Loader size={28} /> : t('Save Patient Details')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className="new-person-registration-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        aria-labelledby="new-person-registration-pop-up"
        aria-describedby="new-person-registration"
        classes={{paper: 'new-person-registration-popup'}}>
        <Grid className="new-person-registration-content">
          <DialogContent>{renderFormSection()}</DialogContent>

          <DialogActions
            disableSpacing={true}
            className="new-person-registration-actions">
            {renderDialogActions()}
          </DialogActions>
        </Grid>
      </Dialog>
      {openRelationPopup && renderRelationPopup()}
      {openOtpPopup && renderOtpPopup()}
    </Grid>
  );
};

export default NewPersonRegistrationComponent;
