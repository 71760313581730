import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import DateTimeUtils from '@shared/utils/DateTimeUtils';

class LanguageContent{
  static setLanguage(content: any):void {
  // the translations
  const resources = {
    en: {
      translation: content
    }
  };
  i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      resources,
      fallbackLng: 'en',
      lng: 'en',
      debug: false,
      // keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        format: function (value, format, lng) {
          if (format === 'uppercase') return value.toUpperCase();
          if (format === 'momentDate') return DateTimeUtils.format(value);
          if (format === 'currency') return `$${value.toLocaleString()}`;
          return value;
        },
        escapeValue: false // not needed for react as it escapes by default
      }
    });
  }
}

export default LanguageContent;
