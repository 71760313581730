import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as PatientBookedIconSvg} from '@assets/images/healpha/PatientBooked.svg';

const PatientBookedIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={PatientBookedIconSvg}
      viewBox="0 0 34 34"
    />
  );
};

export default PatientBookedIcon;
