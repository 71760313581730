/* eslint-disable camelcase */
import {GenderTypes} from 'src/constants/FormFields';

export const GirlWeight: any = {
  '0.0': {
    FIELD1: 2.5,
    FIELD2: 2.7,
    FIELD3: 2.9,
    FIELD4: 3.2,
    FIELD5: 3.6,
    FIELD6: 3.9,
    FIELD7: 4
  },
  0.1: {
    FIELD1: 3.3,
    FIELD2: 3.5,
    FIELD3: 3.8,
    FIELD4: 4.2,
    FIELD5: 4.6,
    FIELD6: 5,
    FIELD7: 5.2
  },
  0.2: {
    FIELD1: 4.1,
    FIELD2: 4.3,
    FIELD3: 4.7,
    FIELD4: 5.1,
    FIELD5: 5.6,
    FIELD6: 6,
    FIELD7: 6.3
  },
  0.3: {
    FIELD1: 4.7,
    FIELD2: 5,
    FIELD3: 5.4,
    FIELD4: 5.8,
    FIELD5: 6.4,
    FIELD6: 6.9,
    FIELD7: 7.2
  },
  0.4: {
    FIELD1: 5.2,
    FIELD2: 5.5,
    FIELD3: 5.9,
    FIELD4: 6.4,
    FIELD5: 7,
    FIELD6: 7.5,
    FIELD7: 7.9
  },
  0.5: {
    FIELD1: 5.6,
    FIELD2: 5.9,
    FIELD3: 6.4,
    FIELD4: 6.9,
    FIELD5: 7.5,
    FIELD6: 8.1,
    FIELD7: 8.4
  },
  0.6: {
    FIELD1: 6,
    FIELD2: 6.2,
    FIELD3: 6.7,
    FIELD4: 7.3,
    FIELD5: 7.9,
    FIELD6: 8.5,
    FIELD7: 8.9
  },
  0.7: {
    FIELD1: 6.3,
    FIELD2: 6.5,
    FIELD3: 7,
    FIELD4: 7.6,
    FIELD5: 8.3,
    FIELD6: 8.9,
    FIELD7: 9.4
  },
  0.8: {
    FIELD1: 6.5,
    FIELD2: 6.8,
    FIELD3: 7.3,
    FIELD4: 7.9,
    FIELD5: 8.6,
    FIELD6: 9.3,
    FIELD7: 9.7
  },
  0.9: {
    FIELD1: 6.8,
    FIELD2: 7,
    FIELD3: 7.6,
    FIELD4: 8.2,
    FIELD5: 8.9,
    FIELD6: 9.6,
    FIELD7: 10.1
  },
  '0.10': {
    FIELD1: 7,
    FIELD2: 7.3,
    FIELD3: 7.8,
    FIELD4: 8.5,
    FIELD5: 9.2,
    FIELD6: 9.9,
    FIELD7: 10.4
  },
  0.11: {
    FIELD1: 7.2,
    FIELD2: 7.5,
    FIELD3: 8,
    FIELD4: 8.7,
    FIELD5: 9.5,
    FIELD6: 10.2,
    FIELD7: 10.7
  },
  '1.0': {
    FIELD1: 7.3,
    FIELD2: 7.7,
    FIELD3: 8.2,
    FIELD4: 8.9,
    FIELD5: 9.7,
    FIELD6: 10.5,
    FIELD7: 11
  },
  1.1: {
    FIELD1: 7.5,
    FIELD2: 7.9,
    FIELD3: 8.4,
    FIELD4: 9.2,
    FIELD5: 10,
    FIELD6: 10.8,
    FIELD7: 11.3
  },
  1.2: {
    FIELD1: 7.7,
    FIELD2: 8,
    FIELD3: 8.6,
    FIELD4: 9.4,
    FIELD5: 10.2,
    FIELD6: 11,
    FIELD7: 11.5
  },
  1.3: {
    FIELD1: 7.9,
    FIELD2: 8.2,
    FIELD3: 8.8,
    FIELD4: 9.6,
    FIELD5: 10.4,
    FIELD6: 11.3,
    FIELD7: 11.8
  },
  1.4: {
    FIELD1: 8.1,
    FIELD2: 8.4,
    FIELD3: 9,
    FIELD4: 9.8,
    FIELD5: 10.7,
    FIELD6: 11.5,
    FIELD7: 12.1
  },
  1.5: {
    FIELD1: 8.2,
    FIELD2: 8.6,
    FIELD3: 9.2,
    FIELD4: 10,
    FIELD5: 10.9,
    FIELD6: 11.8,
    FIELD7: 12.3
  },
  1.6: {
    FIELD1: 8.4,
    FIELD2: 8.8,
    FIELD3: 9.4,
    FIELD4: 10.2,
    FIELD5: 11.1,
    FIELD6: 12,
    FIELD7: 12.6
  },
  1.7: {
    FIELD1: 8.6,
    FIELD2: 8.9,
    FIELD3: 9.6,
    FIELD4: 10.4,
    FIELD5: 11.4,
    FIELD6: 12.3,
    FIELD7: 12.9
  },
  1.8: {
    FIELD1: 8.7,
    FIELD2: 9.1,
    FIELD3: 9.8,
    FIELD4: 10.6,
    FIELD5: 11.6,
    FIELD6: 12.5,
    FIELD7: 13.1
  },
  1.9: {
    FIELD1: 8.9,
    FIELD2: 9.3,
    FIELD3: 10,
    FIELD4: 10.9,
    FIELD5: 11.8,
    FIELD6: 12.8,
    FIELD7: 13.4
  },
  '1.10': {
    FIELD1: 9.1,
    FIELD2: 9.5,
    FIELD3: 10.2,
    FIELD4: 11.1,
    FIELD5: 12,
    FIELD6: 13,
    FIELD7: 13.6
  },
  1.11: {
    FIELD1: 9.2,
    FIELD2: 9.7,
    FIELD3: 10.4,
    FIELD4: 11.3,
    FIELD5: 12.3,
    FIELD6: 13.3,
    FIELD7: 13.9
  },
  '2.0': {
    FIELD1: 9.4,
    FIELD2: 9.8,
    FIELD3: 10.6,
    FIELD4: 11.5,
    FIELD5: 12.5,
    FIELD6: 13.5,
    FIELD7: 14.2
  },
  2.1: {
    FIELD1: 9.6,
    FIELD2: 10,
    FIELD3: 10.8,
    FIELD4: 11.7,
    FIELD5: 12.7,
    FIELD6: 13.8,
    FIELD7: 14.4
  },
  2.2: {
    FIELD1: 9.8,
    FIELD2: 10.2,
    FIELD3: 10.9,
    FIELD4: 11.9,
    FIELD5: 12.9,
    FIELD6: 14,
    FIELD7: 14.7
  },
  2.3: {
    FIELD1: 9.9,
    FIELD2: 10.4,
    FIELD3: 11.1,
    FIELD4: 12.1,
    FIELD5: 13.2,
    FIELD6: 14.3,
    FIELD7: 15
  },
  2.4: {
    FIELD1: 10.1,
    FIELD2: 10.5,
    FIELD3: 11.3,
    FIELD4: 12.3,
    FIELD5: 13.4,
    FIELD6: 14.5,
    FIELD7: 15.2
  },
  2.5: {
    FIELD1: 10.2,
    FIELD2: 10.7,
    FIELD3: 11.5,
    FIELD4: 12.5,
    FIELD5: 13.6,
    FIELD6: 14.7,
    FIELD7: 15.5
  },
  2.6: {
    FIELD1: 10.4,
    FIELD2: 10.9,
    FIELD3: 11.7,
    FIELD4: 12.7,
    FIELD5: 13.8,
    FIELD6: 15,
    FIELD7: 15.7
  },
  2.7: {
    FIELD1: 10.5,
    FIELD2: 11,
    FIELD3: 11.9,
    FIELD4: 12.9,
    FIELD5: 14.1,
    FIELD6: 15.2,
    FIELD7: 16
  },
  2.8: {
    FIELD1: 10.7,
    FIELD2: 11.2,
    FIELD3: 12,
    FIELD4: 13.1,
    FIELD5: 14.3,
    FIELD6: 15.5,
    FIELD7: 16.2
  },
  2.9: {
    FIELD1: 10.8,
    FIELD2: 11.3,
    FIELD3: 12.2,
    FIELD4: 13.3,
    FIELD5: 14.5,
    FIELD6: 15.7,
    FIELD7: 16.5
  },
  '2.10': {
    FIELD1: 11,
    FIELD2: 11.5,
    FIELD3: 12.4,
    FIELD4: 13.5,
    FIELD5: 14.7,
    FIELD6: 15.9,
    FIELD7: 16.8
  },
  2.11: {
    FIELD1: 11.1,
    FIELD2: 11.6,
    FIELD3: 12.5,
    FIELD4: 13.7,
    FIELD5: 14.9,
    FIELD6: 16.2,
    FIELD7: 17
  },
  '3.0': {
    FIELD1: 11.3,
    FIELD2: 11.8,
    FIELD3: 12.7,
    FIELD4: 13.9,
    FIELD5: 15.1,
    FIELD6: 16.4,
    FIELD7: 17.3
  },
  3.1: {
    FIELD1: 11.4,
    FIELD2: 11.9,
    FIELD3: 12.9,
    FIELD4: 14,
    FIELD5: 15.3,
    FIELD6: 16.7,
    FIELD7: 17.5
  },
  3.2: {
    FIELD1: 11.6,
    FIELD2: 12.1,
    FIELD3: 13,
    FIELD4: 14.2,
    FIELD5: 15.6,
    FIELD6: 16.9,
    FIELD7: 17.8
  },
  3.3: {
    FIELD1: 11.7,
    FIELD2: 12.2,
    FIELD3: 13.2,
    FIELD4: 14.4,
    FIELD5: 15.8,
    FIELD6: 17.1,
    FIELD7: 18
  },
  3.4: {
    FIELD1: 11.8,
    FIELD2: 12.4,
    FIELD3: 13.4,
    FIELD4: 14.6,
    FIELD5: 16,
    FIELD6: 17.4,
    FIELD7: 18.3
  },
  3.5: {
    FIELD1: 12,
    FIELD2: 12.5,
    FIELD3: 13.5,
    FIELD4: 14.8,
    FIELD5: 16.2,
    FIELD6: 17.6,
    FIELD7: 18.6
  },
  3.6: {
    FIELD1: 12.1,
    FIELD2: 12.7,
    FIELD3: 13.7,
    FIELD4: 15,
    FIELD5: 16.4,
    FIELD6: 17.9,
    FIELD7: 18.8
  },
  3.7: {
    FIELD1: 12.2,
    FIELD2: 12.8,
    FIELD3: 13.9,
    FIELD4: 15.2,
    FIELD5: 16.6,
    FIELD6: 18.1,
    FIELD7: 19.1
  },
  3.8: {
    FIELD1: 12.4,
    FIELD2: 13,
    FIELD3: 14,
    FIELD4: 15.3,
    FIELD5: 16.8,
    FIELD6: 18.3,
    FIELD7: 19.3
  },
  3.9: {
    FIELD1: 12.5,
    FIELD2: 13.1,
    FIELD3: 14.2,
    FIELD4: 15.5,
    FIELD5: 17,
    FIELD6: 18.6,
    FIELD7: 19.6
  },
  '3.10': {
    FIELD1: 12.6,
    FIELD2: 13.2,
    FIELD3: 14.3,
    FIELD4: 15.7,
    FIELD5: 17.3,
    FIELD6: 18.8,
    FIELD7: 19.9
  },
  3.11: {
    FIELD1: 12.8,
    FIELD2: 13.4,
    FIELD3: 14.5,
    FIELD4: 15.9,
    FIELD5: 17.5,
    FIELD6: 19.1,
    FIELD7: 20.1
  },
  '4.0': {
    FIELD1: 12.9,
    FIELD2: 13.5,
    FIELD3: 14.7,
    FIELD4: 16.1,
    FIELD5: 17.7,
    FIELD6: 19.3,
    FIELD7: 20.4
  },
  4.1: {
    FIELD1: 13,
    FIELD2: 13.7,
    FIELD3: 14.8,
    FIELD4: 16.3,
    FIELD5: 17.9,
    FIELD6: 19.5,
    FIELD7: 20.6
  },
  4.2: {
    FIELD1: 13.2,
    FIELD2: 13.8,
    FIELD3: 15,
    FIELD4: 16.4,
    FIELD5: 18.1,
    FIELD6: 19.8,
    FIELD7: 20.9
  },
  4.3: {
    FIELD1: 13.3,
    FIELD2: 13.9,
    FIELD3: 15.1,
    FIELD4: 16.6,
    FIELD5: 18.3,
    FIELD6: 20,
    FIELD7: 21.2
  },
  4.4: {
    FIELD1: 13.4,
    FIELD2: 14.1,
    FIELD3: 15.3,
    FIELD4: 16.8,
    FIELD5: 18.5,
    FIELD6: 20.3,
    FIELD7: 21.4
  },
  4.5: {
    FIELD1: 13.5,
    FIELD2: 14.2,
    FIELD3: 15.4,
    FIELD4: 17,
    FIELD5: 18.7,
    FIELD6: 20.5,
    FIELD7: 21.7
  },
  4.6: {
    FIELD1: 13.7,
    FIELD2: 14.3,
    FIELD3: 15.6,
    FIELD4: 17.2,
    FIELD5: 18.9,
    FIELD6: 20.8,
    FIELD7: 22
  },
  4.7: {
    FIELD1: 13.8,
    FIELD2: 14.5,
    FIELD3: 15.8,
    FIELD4: 17.3,
    FIELD5: 19.1,
    FIELD6: 21,
    FIELD7: 22.2
  },
  4.8: {
    FIELD1: 13.9,
    FIELD2: 14.6,
    FIELD3: 15.9,
    FIELD4: 17.5,
    FIELD5: 19.3,
    FIELD6: 21.2,
    FIELD7: 22.5
  },
  4.9: {
    FIELD1: 14,
    FIELD2: 14.8,
    FIELD3: 16.1,
    FIELD4: 17.7,
    FIELD5: 19.6,
    FIELD6: 21.5,
    FIELD7: 22.7
  },
  '4.10': {
    FIELD1: 14.2,
    FIELD2: 14.9,
    FIELD3: 16.2,
    FIELD4: 17.9,
    FIELD5: 19.8,
    FIELD6: 21.7,
    FIELD7: 23
  },
  4.11: {
    FIELD1: 14.3,
    FIELD2: 15,
    FIELD3: 16.4,
    FIELD4: 18,
    FIELD5: 20,
    FIELD6: 21.9,
    FIELD7: 23.3
  },
  '5.0': {
    FIELD1: 14.4,
    FIELD2: 15.2,
    FIELD3: 16.5,
    FIELD4: 18.2,
    FIELD5: 20.2,
    FIELD6: 22.2,
    FIELD7: 23.5
  },
  5.1: {
    FIELD1: 14.61,
    FIELD2: 15.32,
    FIELD3: 16.62,
    FIELD4: 18.26,
    FIELD5: 20.15,
    FIELD6: 22.11,
    FIELD7: 23.42
  },
  5.2: {
    FIELD1: 14.74,
    FIELD2: 15.45,
    FIELD3: 16.77,
    FIELD4: 18.43,
    FIELD5: 20.35,
    FIELD6: 22.34,
    FIELD7: 23.67
  },
  5.3: {
    FIELD1: 14.87,
    FIELD2: 15.59,
    FIELD3: 16.92,
    FIELD4: 18.61,
    FIELD5: 20.55,
    FIELD6: 22.57,
    FIELD7: 23.92
  },
  5.4: {
    FIELD1: 14.99,
    FIELD2: 15.73,
    FIELD3: 17.07,
    FIELD4: 18.78,
    FIELD5: 20.75,
    FIELD6: 22.8,
    FIELD7: 24.17
  },
  5.5: {
    FIELD1: 15.12,
    FIELD2: 15.86,
    FIELD3: 17.23,
    FIELD4: 18.95,
    FIELD5: 20.95,
    FIELD6: 23.03,
    FIELD7: 24.43
  },
  5.6: {
    FIELD1: 15.25,
    FIELD2: 16,
    FIELD3: 17.38,
    FIELD4: 19.13,
    FIELD5: 21.15,
    FIELD6: 23.26,
    FIELD7: 24.68
  },
  5.7: {
    FIELD1: 15.37,
    FIELD2: 16.13,
    FIELD3: 17.53,
    FIELD4: 19.3,
    FIELD5: 21.35,
    FIELD6: 23.49,
    FIELD7: 24.93
  },
  5.8: {
    FIELD1: 15.5,
    FIELD2: 16.27,
    FIELD3: 17.68,
    FIELD4: 19.47,
    FIELD5: 21.55,
    FIELD6: 23.72,
    FIELD7: 25.18
  },
  5.9: {
    FIELD1: 15.63,
    FIELD2: 16.4,
    FIELD3: 17.83,
    FIELD4: 19.65,
    FIELD5: 21.75,
    FIELD6: 23.95,
    FIELD7: 25.43
  },
  '5.10': {
    FIELD1: 15.75,
    FIELD2: 16.53,
    FIELD3: 17.98,
    FIELD4: 19.82,
    FIELD5: 21.95,
    FIELD6: 24.18,
    FIELD7: 25.68
  },
  5.11: {
    FIELD1: 15.88,
    FIELD2: 16.67,
    FIELD3: 18.13,
    FIELD4: 19.99,
    FIELD5: 22.15,
    FIELD6: 24.41,
    FIELD7: 25.94
  },
  '6.0': {
    FIELD1: 16,
    FIELD2: 16.8,
    FIELD3: 18.28,
    FIELD4: 20.16,
    FIELD5: 22.35,
    FIELD6: 24.65,
    FIELD7: 26.19
  },
  6.1: {
    FIELD1: 16.13,
    FIELD2: 16.94,
    FIELD3: 18.43,
    FIELD4: 20.34,
    FIELD5: 22.56,
    FIELD6: 24.88,
    FIELD7: 26.45
  },
  6.2: {
    FIELD1: 16.26,
    FIELD2: 17.07,
    FIELD3: 18.58,
    FIELD4: 20.51,
    FIELD5: 22.76,
    FIELD6: 25.11,
    FIELD7: 26.7
  },
  6.3: {
    FIELD1: 16.38,
    FIELD2: 17.21,
    FIELD3: 18.74,
    FIELD4: 20.69,
    FIELD5: 22.96,
    FIELD6: 25.35,
    FIELD7: 26.96
  },
  6.4: {
    FIELD1: 16.51,
    FIELD2: 17.35,
    FIELD3: 18.89,
    FIELD4: 20.87,
    FIELD5: 23.17,
    FIELD6: 25.59,
    FIELD7: 27.23
  },
  6.5: {
    FIELD1: 16.64,
    FIELD2: 17.49,
    FIELD3: 19.05,
    FIELD4: 21.05,
    FIELD5: 23.38,
    FIELD6: 25.83,
    FIELD7: 27.49
  },
  6.6: {
    FIELD1: 16.77,
    FIELD2: 17.63,
    FIELD3: 19.21,
    FIELD4: 21.23,
    FIELD5: 23.59,
    FIELD6: 26.08,
    FIELD7: 27.76
  },
  6.7: {
    FIELD1: 16.91,
    FIELD2: 17.77,
    FIELD3: 19.37,
    FIELD4: 21.41,
    FIELD5: 23.81,
    FIELD6: 26.33,
    FIELD7: 28.03
  },
  6.8: {
    FIELD1: 17.04,
    FIELD2: 17.91,
    FIELD3: 19.53,
    FIELD4: 21.6,
    FIELD5: 24.02,
    FIELD6: 26.58,
    FIELD7: 28.31
  },
  6.9: {
    FIELD1: 17.18,
    FIELD2: 18.06,
    FIELD3: 19.69,
    FIELD4: 21.79,
    FIELD5: 24.24,
    FIELD6: 26.83,
    FIELD7: 28.59
  },
  '6.10': {
    FIELD1: 17.32,
    FIELD2: 18.21,
    FIELD3: 19.86,
    FIELD4: 21.98,
    FIELD5: 24.47,
    FIELD6: 27.09,
    FIELD7: 28.88
  },
  6.11: {
    FIELD1: 17.46,
    FIELD2: 18.36,
    FIELD3: 20.03,
    FIELD4: 22.18,
    FIELD5: 24.69,
    FIELD6: 27.36,
    FIELD7: 29.17
  },
  '7.0': {
    FIELD1: 17.6,
    FIELD2: 18.51,
    FIELD3: 20.2,
    FIELD4: 22.37,
    FIELD5: 24.93,
    FIELD6: 27.63,
    FIELD7: 29.46
  },
  7.1: {
    FIELD1: 17.75,
    FIELD2: 18.67,
    FIELD3: 20.38,
    FIELD4: 22.58,
    FIELD5: 25.16,
    FIELD6: 27.9,
    FIELD7: 29.76
  },
  7.2: {
    FIELD1: 17.9,
    FIELD2: 18.83,
    FIELD3: 20.56,
    FIELD4: 22.78,
    FIELD5: 25.4,
    FIELD6: 28.17,
    FIELD7: 30.07
  },
  7.3: {
    FIELD1: 18.05,
    FIELD2: 18.99,
    FIELD3: 20.74,
    FIELD4: 22.99,
    FIELD5: 25.64,
    FIELD6: 28.46,
    FIELD7: 30.38
  },
  7.4: {
    FIELD1: 18.2,
    FIELD2: 19.15,
    FIELD3: 20.92,
    FIELD4: 23.2,
    FIELD5: 25.89,
    FIELD6: 28.74,
    FIELD7: 30.69
  },
  7.5: {
    FIELD1: 18.36,
    FIELD2: 19.32,
    FIELD3: 21.11,
    FIELD4: 23.42,
    FIELD5: 26.14,
    FIELD6: 29.03,
    FIELD7: 31.01
  },
  7.6: {
    FIELD1: 18.52,
    FIELD2: 19.49,
    FIELD3: 21.3,
    FIELD4: 23.64,
    FIELD5: 26.4,
    FIELD6: 29.33,
    FIELD7: 31.33
  },
  7.7: {
    FIELD1: 18.68,
    FIELD2: 19.66,
    FIELD3: 21.5,
    FIELD4: 23.86,
    FIELD5: 26.65,
    FIELD6: 29.63,
    FIELD7: 31.66
  },
  7.8: {
    FIELD1: 18.84,
    FIELD2: 19.84,
    FIELD3: 21.69,
    FIELD4: 24.09,
    FIELD5: 26.92,
    FIELD6: 29.93,
    FIELD7: 32
  },
  7.9: {
    FIELD1: 19.01,
    FIELD2: 20.02,
    FIELD3: 21.89,
    FIELD4: 24.32,
    FIELD5: 27.18,
    FIELD6: 30.24,
    FIELD7: 32.34
  },
  '7.10': {
    FIELD1: 19.18,
    FIELD2: 20.2,
    FIELD3: 22.09,
    FIELD4: 24.55,
    FIELD5: 27.46,
    FIELD6: 30.56,
    FIELD7: 32.69
  },
  7.11: {
    FIELD1: 19.35,
    FIELD2: 20.38,
    FIELD3: 22.3,
    FIELD4: 24.79,
    FIELD5: 27.73,
    FIELD6: 30.88,
    FIELD7: 33.04
  },
  '8.0': {
    FIELD1: 19.53,
    FIELD2: 20.57,
    FIELD3: 22.51,
    FIELD4: 25.03,
    FIELD5: 28.01,
    FIELD6: 31.2,
    FIELD7: 33.39
  },
  8.1: {
    FIELD1: 19.71,
    FIELD2: 20.76,
    FIELD3: 22.72,
    FIELD4: 25.27,
    FIELD5: 28.3,
    FIELD6: 31.53,
    FIELD7: 33.76
  },
  8.2: {
    FIELD1: 19.89,
    FIELD2: 20.95,
    FIELD3: 22.94,
    FIELD4: 25.52,
    FIELD5: 28.59,
    FIELD6: 31.87,
    FIELD7: 34.12
  },
  8.3: {
    FIELD1: 20.07,
    FIELD2: 21.15,
    FIELD3: 23.16,
    FIELD4: 25.77,
    FIELD5: 28.88,
    FIELD6: 32.21,
    FIELD7: 34.5
  },
  8.4: {
    FIELD1: 20.26,
    FIELD2: 21.35,
    FIELD3: 23.38,
    FIELD4: 26.03,
    FIELD5: 29.18,
    FIELD6: 32.55,
    FIELD7: 34.88
  },
  8.5: {
    FIELD1: 20.45,
    FIELD2: 21.55,
    FIELD3: 23.61,
    FIELD4: 26.29,
    FIELD5: 29.48,
    FIELD6: 32.9,
    FIELD7: 35.26
  },
  8.6: {
    FIELD1: 20.64,
    FIELD2: 21.75,
    FIELD3: 23.84,
    FIELD4: 26.55,
    FIELD5: 29.78,
    FIELD6: 33.26,
    FIELD7: 35.65
  },
  8.7: {
    FIELD1: 20.83,
    FIELD2: 21.96,
    FIELD3: 24.07,
    FIELD4: 26.82,
    FIELD5: 30.1,
    FIELD6: 33.62,
    FIELD7: 36.05
  },
  8.8: {
    FIELD1: 21.03,
    FIELD2: 22.17,
    FIELD3: 24.31,
    FIELD4: 27.09,
    FIELD5: 30.41,
    FIELD6: 33.98,
    FIELD7: 36.45
  },
  8.9: {
    FIELD1: 21.23,
    FIELD2: 22.38,
    FIELD3: 24.55,
    FIELD4: 27.36,
    FIELD5: 30.73,
    FIELD6: 34.35,
    FIELD7: 36.85
  },
  '8.10': {
    FIELD1: 21.43,
    FIELD2: 22.6,
    FIELD3: 24.79,
    FIELD4: 27.64,
    FIELD5: 31.05,
    FIELD6: 34.72,
    FIELD7: 37.26
  },
  8.11: {
    FIELD1: 21.63,
    FIELD2: 22.82,
    FIELD3: 25.03,
    FIELD4: 27.92,
    FIELD5: 31.38,
    FIELD6: 35.1,
    FIELD7: 37.68
  },
  '9.0': {
    FIELD1: 21.84,
    FIELD2: 23.04,
    FIELD3: 25.28,
    FIELD4: 28.2,
    FIELD5: 31.7,
    FIELD6: 35.48,
    FIELD7: 38.1
  },
  9.1: {
    FIELD1: 22.05,
    FIELD2: 23.26,
    FIELD3: 25.53,
    FIELD4: 28.49,
    FIELD5: 32.04,
    FIELD6: 35.87,
    FIELD7: 38.52
  },
  9.2: {
    FIELD1: 22.26,
    FIELD2: 23.48,
    FIELD3: 25.78,
    FIELD4: 28.78,
    FIELD5: 32.37,
    FIELD6: 36.26,
    FIELD7: 38.95
  },
  9.3: {
    FIELD1: 22.47,
    FIELD2: 23.71,
    FIELD3: 26.04,
    FIELD4: 29.07,
    FIELD5: 32.71,
    FIELD6: 36.65,
    FIELD7: 39.38
  },
  9.4: {
    FIELD1: 22.69,
    FIELD2: 23.94,
    FIELD3: 26.29,
    FIELD4: 29.37,
    FIELD5: 33.05,
    FIELD6: 37.05,
    FIELD7: 39.81
  },
  9.5: {
    FIELD1: 22.91,
    FIELD2: 24.17,
    FIELD3: 26.55,
    FIELD4: 29.67,
    FIELD5: 33.4,
    FIELD6: 37.45,
    FIELD7: 40.25
  },
  9.6: {
    FIELD1: 23.13,
    FIELD2: 24.41,
    FIELD3: 26.82,
    FIELD4: 29.97,
    FIELD5: 33.75,
    FIELD6: 37.85,
    FIELD7: 40.7
  },
  9.7: {
    FIELD1: 23.35,
    FIELD2: 24.64,
    FIELD3: 27.08,
    FIELD4: 30.27,
    FIELD5: 34.1,
    FIELD6: 38.26,
    FIELD7: 41.15
  },
  9.8: {
    FIELD1: 23.57,
    FIELD2: 24.88,
    FIELD3: 27.35,
    FIELD4: 30.58,
    FIELD5: 34.46,
    FIELD6: 38.68,
    FIELD7: 41.6
  },
  9.9: {
    FIELD1: 23.8,
    FIELD2: 25.13,
    FIELD3: 27.62,
    FIELD4: 30.89,
    FIELD5: 34.83,
    FIELD6: 39.1,
    FIELD7: 42.06
  },
  '9.10': {
    FIELD1: 24.03,
    FIELD2: 25.38,
    FIELD3: 27.9,
    FIELD4: 31.21,
    FIELD5: 35.19,
    FIELD6: 39.52,
    FIELD7: 42.53
  },
  9.11: {
    FIELD1: 24.27,
    FIELD2: 25.63,
    FIELD3: 28.18,
    FIELD4: 31.53,
    FIELD5: 35.57,
    FIELD6: 39.95,
    FIELD7: 43
  },
  '10.0': {
    FIELD1: 24.51,
    FIELD2: 25.88,
    FIELD3: 28.47,
    FIELD4: 31.86,
    FIELD5: 35.94,
    FIELD6: 40.39,
    FIELD7: 43.48
  },
  10.1: {
    FIELD1: 25.1,
    FIELD2: 26.58,
    FIELD3: 29.46,
    FIELD4: 33.41,
    FIELD5: 38.46,
    FIELD6: 44.35,
    FIELD7: 48.73
  },
  10.2: {
    FIELD1: 25.33,
    FIELD2: 26.84,
    FIELD3: 29.76,
    FIELD4: 33.76,
    FIELD5: 38.88,
    FIELD6: 44.85,
    FIELD7: 49.29
  },
  10.3: {
    FIELD1: 25.57,
    FIELD2: 27.1,
    FIELD3: 30.06,
    FIELD4: 34.12,
    FIELD5: 39.3,
    FIELD6: 45.35,
    FIELD7: 49.85
  },
  10.4: {
    FIELD1: 25.81,
    FIELD2: 27.36,
    FIELD3: 30.36,
    FIELD4: 34.47,
    FIELD5: 39.73,
    FIELD6: 45.86,
    FIELD7: 50.41
  },
  10.5: {
    FIELD1: 26.05,
    FIELD2: 27.62,
    FIELD3: 30.66,
    FIELD4: 34.83,
    FIELD5: 40.15,
    FIELD6: 46.36,
    FIELD7: 50.98
  },
  10.6: {
    FIELD1: 26.3,
    FIELD2: 27.89,
    FIELD3: 30.97,
    FIELD4: 35.19,
    FIELD5: 40.58,
    FIELD6: 46.87,
    FIELD7: 51.54
  },
  10.7: {
    FIELD1: 26.55,
    FIELD2: 28.16,
    FIELD3: 31.28,
    FIELD4: 35.55,
    FIELD5: 41.02,
    FIELD6: 47.38,
    FIELD7: 52.11
  },
  10.8: {
    FIELD1: 26.8,
    FIELD2: 28.43,
    FIELD3: 31.59,
    FIELD4: 35.92,
    FIELD5: 41.45,
    FIELD6: 47.89,
    FIELD7: 52.68
  },
  10.9: {
    FIELD1: 27.06,
    FIELD2: 28.71,
    FIELD3: 31.9,
    FIELD4: 36.28,
    FIELD5: 41.88,
    FIELD6: 48.41,
    FIELD7: 53.25
  },
  '10.10': {
    FIELD1: 27.31,
    FIELD2: 28.98,
    FIELD3: 32.22,
    FIELD4: 36.65,
    FIELD5: 42.32,
    FIELD6: 48.92,
    FIELD7: 53.82
  },
  10.11: {
    FIELD1: 27.57,
    FIELD2: 29.26,
    FIELD3: 32.53,
    FIELD4: 37.02,
    FIELD5: 42.75,
    FIELD6: 49.43,
    FIELD7: 54.39
  },
  '11.0': {
    FIELD1: 27.84,
    FIELD2: 29.54,
    FIELD3: 32.85,
    FIELD4: 37.39,
    FIELD5: 43.19,
    FIELD6: 49.94,
    FIELD7: 54.96
  },
  11.1: {
    FIELD1: 28.1,
    FIELD2: 29.83,
    FIELD3: 33.17,
    FIELD4: 37.76,
    FIELD5: 43.62,
    FIELD6: 50.45,
    FIELD7: 55.53
  },
  11.2: {
    FIELD1: 28.37,
    FIELD2: 30.11,
    FIELD3: 33.49,
    FIELD4: 38.13,
    FIELD5: 44.06,
    FIELD6: 50.97,
    FIELD7: 56.09
  },
  11.3: {
    FIELD1: 28.64,
    FIELD2: 30.4,
    FIELD3: 33.82,
    FIELD4: 38.5,
    FIELD5: 44.49,
    FIELD6: 51.47,
    FIELD7: 56.66
  },
  11.4: {
    FIELD1: 28.91,
    FIELD2: 30.69,
    FIELD3: 34.14,
    FIELD4: 38.88,
    FIELD5: 44.93,
    FIELD6: 51.98,
    FIELD7: 57.22
  },
  11.5: {
    FIELD1: 29.18,
    FIELD2: 30.98,
    FIELD3: 34.47,
    FIELD4: 39.25,
    FIELD5: 45.36,
    FIELD6: 52.49,
    FIELD7: 57.78
  },
  11.6: {
    FIELD1: 29.46,
    FIELD2: 31.27,
    FIELD3: 34.79,
    FIELD4: 39.62,
    FIELD5: 45.79,
    FIELD6: 52.99,
    FIELD7: 58.34
  },
  11.7: {
    FIELD1: 29.74,
    FIELD2: 31.57,
    FIELD3: 35.12,
    FIELD4: 39.99,
    FIELD5: 46.22,
    FIELD6: 53.49,
    FIELD7: 58.9
  },
  11.8: {
    FIELD1: 30.02,
    FIELD2: 31.86,
    FIELD3: 35.44,
    FIELD4: 40.36,
    FIELD5: 46.65,
    FIELD6: 53.99,
    FIELD7: 59.45
  },
  11.9: {
    FIELD1: 30.3,
    FIELD2: 32.16,
    FIELD3: 35.77,
    FIELD4: 40.73,
    FIELD5: 47.07,
    FIELD6: 54.49,
    FIELD7: 60
  },
  '11.10': {
    FIELD1: 30.58,
    FIELD2: 32.46,
    FIELD3: 36.1,
    FIELD4: 41.1,
    FIELD5: 47.5,
    FIELD6: 54.98,
    FIELD7: 60.55
  },
  11.11: {
    FIELD1: 30.87,
    FIELD2: 32.76,
    FIELD3: 36.42,
    FIELD4: 41.46,
    FIELD5: 47.92,
    FIELD6: 55.47,
    FIELD7: 61.09
  },
  '12.0': {
    FIELD1: 31.15,
    FIELD2: 33.05,
    FIELD3: 36.75,
    FIELD4: 41.83,
    FIELD5: 48.33,
    FIELD6: 55.95,
    FIELD7: 61.63
  },
  12.1: {
    FIELD1: 31.44,
    FIELD2: 33.35,
    FIELD3: 37.07,
    FIELD4: 42.19,
    FIELD5: 48.75,
    FIELD6: 56.43,
    FIELD7: 62.16
  },
  12.2: {
    FIELD1: 31.72,
    FIELD2: 33.65,
    FIELD3: 37.4,
    FIELD4: 42.55,
    FIELD5: 49.16,
    FIELD6: 56.9,
    FIELD7: 62.69
  },
  12.3: {
    FIELD1: 32.01,
    FIELD2: 33.95,
    FIELD3: 37.72,
    FIELD4: 42.91,
    FIELD5: 49.56,
    FIELD6: 57.37,
    FIELD7: 63.21
  },
  12.4: {
    FIELD1: 32.3,
    FIELD2: 34.25,
    FIELD3: 38.05,
    FIELD4: 43.26,
    FIELD5: 49.97,
    FIELD6: 57.84,
    FIELD7: 63.72
  },
  12.5: {
    FIELD1: 32.59,
    FIELD2: 34.55,
    FIELD3: 38.37,
    FIELD4: 43.62,
    FIELD5: 50.36,
    FIELD6: 58.29,
    FIELD7: 64.23
  },
  12.6: {
    FIELD1: 32.88,
    FIELD2: 34.85,
    FIELD3: 38.69,
    FIELD4: 43.97,
    FIELD5: 50.76,
    FIELD6: 58.75,
    FIELD7: 64.73
  },
  12.7: {
    FIELD1: 33.17,
    FIELD2: 35.15,
    FIELD3: 39,
    FIELD4: 44.31,
    FIELD5: 51.14,
    FIELD6: 59.19,
    FIELD7: 65.23
  },
  12.8: {
    FIELD1: 33.46,
    FIELD2: 35.45,
    FIELD3: 39.32,
    FIELD4: 44.65,
    FIELD5: 51.53,
    FIELD6: 59.63,
    FIELD7: 65.72
  },
  12.9: {
    FIELD1: 33.74,
    FIELD2: 35.74,
    FIELD3: 39.63,
    FIELD4: 44.99,
    FIELD5: 51.9,
    FIELD6: 60.06,
    FIELD7: 66.2
  },
  '12.10': {
    FIELD1: 34.03,
    FIELD2: 36.04,
    FIELD3: 39.94,
    FIELD4: 45.33,
    FIELD5: 52.27,
    FIELD6: 60.49,
    FIELD7: 66.67
  },
  12.11: {
    FIELD1: 34.32,
    FIELD2: 36.33,
    FIELD3: 40.25,
    FIELD4: 45.66,
    FIELD5: 52.64,
    FIELD6: 60.9,
    FIELD7: 67.14
  },
  '13.0': {
    FIELD1: 34.61,
    FIELD2: 36.63,
    FIELD3: 40.56,
    FIELD4: 45.98,
    FIELD5: 53,
    FIELD6: 61.31,
    FIELD7: 67.59
  },
  13.1: {
    FIELD1: 34.89,
    FIELD2: 36.92,
    FIELD3: 40.86,
    FIELD4: 46.31,
    FIELD5: 53.35,
    FIELD6: 61.71,
    FIELD7: 68.04
  },
  13.2: {
    FIELD1: 35.18,
    FIELD2: 37.21,
    FIELD3: 41.16,
    FIELD4: 46.62,
    FIELD5: 53.7,
    FIELD6: 62.11,
    FIELD7: 68.48
  },
  13.3: {
    FIELD1: 35.46,
    FIELD2: 37.49,
    FIELD3: 41.45,
    FIELD4: 46.93,
    FIELD5: 54.04,
    FIELD6: 62.49,
    FIELD7: 68.92
  },
  13.4: {
    FIELD1: 35.74,
    FIELD2: 37.78,
    FIELD3: 41.75,
    FIELD4: 47.24,
    FIELD5: 54.37,
    FIELD6: 62.87,
    FIELD7: 69.34
  },
  13.5: {
    FIELD1: 36.02,
    FIELD2: 38.06,
    FIELD3: 42.03,
    FIELD4: 47.54,
    FIELD5: 54.69,
    FIELD6: 63.24,
    FIELD7: 69.75
  },
  13.6: {
    FIELD1: 36.3,
    FIELD2: 38.34,
    FIELD3: 42.32,
    FIELD4: 47.84,
    FIELD5: 55.01,
    FIELD6: 63.6,
    FIELD7: 70.16
  },
  13.7: {
    FIELD1: 36.57,
    FIELD2: 38.62,
    FIELD3: 42.6,
    FIELD4: 48.13,
    FIELD5: 55.32,
    FIELD6: 63.95,
    FIELD7: 70.56
  },
  13.8: {
    FIELD1: 36.85,
    FIELD2: 38.89,
    FIELD3: 42.88,
    FIELD4: 48.41,
    FIELD5: 55.63,
    FIELD6: 64.3,
    FIELD7: 70.94
  },
  13.9: {
    FIELD1: 37.12,
    FIELD2: 39.16,
    FIELD3: 43.15,
    FIELD4: 48.69,
    FIELD5: 55.92,
    FIELD6: 64.63,
    FIELD7: 71.32
  },
  '13.10': {
    FIELD1: 37.39,
    FIELD2: 39.43,
    FIELD3: 43.42,
    FIELD4: 48.96,
    FIELD5: 56.21,
    FIELD6: 64.95,
    FIELD7: 71.69
  },
  13.11: {
    FIELD1: 37.65,
    FIELD2: 39.69,
    FIELD3: 43.68,
    FIELD4: 49.23,
    FIELD5: 56.49,
    FIELD6: 65.27,
    FIELD7: 72.04
  },
  '14.0': {
    FIELD1: 37.91,
    FIELD2: 39.95,
    FIELD3: 43.94,
    FIELD4: 49.49,
    FIELD5: 56.76,
    FIELD6: 65.58,
    FIELD7: 72.39
  },
  14.1: {
    FIELD1: 38.17,
    FIELD2: 40.21,
    FIELD3: 44.19,
    FIELD4: 49.75,
    FIELD5: 57.03,
    FIELD6: 65.87,
    FIELD7: 72.73
  },
  14.2: {
    FIELD1: 38.43,
    FIELD2: 40.46,
    FIELD3: 44.44,
    FIELD4: 49.99,
    FIELD5: 57.29,
    FIELD6: 66.16,
    FIELD7: 73.06
  },
  14.3: {
    FIELD1: 38.68,
    FIELD2: 40.71,
    FIELD3: 44.69,
    FIELD4: 50.24,
    FIELD5: 57.54,
    FIELD6: 66.44,
    FIELD7: 73.38
  },
  14.4: {
    FIELD1: 38.93,
    FIELD2: 40.96,
    FIELD3: 44.93,
    FIELD4: 50.47,
    FIELD5: 57.78,
    FIELD6: 66.71,
    FIELD7: 73.69
  },
  14.5: {
    FIELD1: 39.17,
    FIELD2: 41.2,
    FIELD3: 45.16,
    FIELD4: 50.7,
    FIELD5: 58.01,
    FIELD6: 66.97,
    FIELD7: 73.99
  },
  14.6: {
    FIELD1: 39.41,
    FIELD2: 41.44,
    FIELD3: 45.39,
    FIELD4: 50.93,
    FIELD5: 58.24,
    FIELD6: 67.22,
    FIELD7: 74.28
  },
  14.7: {
    FIELD1: 39.65,
    FIELD2: 41.67,
    FIELD3: 45.61,
    FIELD4: 51.14,
    FIELD5: 58.46,
    FIELD6: 67.46,
    FIELD7: 74.56
  },
  14.8: {
    FIELD1: 39.88,
    FIELD2: 41.89,
    FIELD3: 45.83,
    FIELD4: 51.35,
    FIELD5: 58.67,
    FIELD6: 67.7,
    FIELD7: 74.83
  },
  14.9: {
    FIELD1: 40.11,
    FIELD2: 42.12,
    FIELD3: 46.05,
    FIELD4: 51.56,
    FIELD5: 58.87,
    FIELD6: 67.92,
    FIELD7: 75.09
  },
  '14.10': {
    FIELD1: 40.33,
    FIELD2: 42.33,
    FIELD3: 46.25,
    FIELD4: 51.76,
    FIELD5: 59.07,
    FIELD6: 68.14,
    FIELD7: 75.35
  },
  14.11: {
    FIELD1: 40.55,
    FIELD2: 42.55,
    FIELD3: 46.46,
    FIELD4: 51.95,
    FIELD5: 59.26,
    FIELD6: 68.35,
    FIELD7: 75.59
  },
  '15.0': {
    FIELD1: 40.77,
    FIELD2: 42.76,
    FIELD3: 46.65,
    FIELD4: 52.14,
    FIELD5: 59.44,
    FIELD6: 68.55,
    FIELD7: 75.83
  },
  15.1: {
    FIELD1: 40.98,
    FIELD2: 42.96,
    FIELD3: 46.85,
    FIELD4: 52.32,
    FIELD5: 59.62,
    FIELD6: 68.74,
    FIELD7: 76.06
  },
  15.2: {
    FIELD1: 41.18,
    FIELD2: 43.16,
    FIELD3: 47.03,
    FIELD4: 52.49,
    FIELD5: 59.79,
    FIELD6: 68.93,
    FIELD7: 76.27
  },
  15.3: {
    FIELD1: 41.38,
    FIELD2: 43.35,
    FIELD3: 47.21,
    FIELD4: 52.66,
    FIELD5: 59.95,
    FIELD6: 69.1,
    FIELD7: 76.48
  },
  15.4: {
    FIELD1: 41.58,
    FIELD2: 43.54,
    FIELD3: 47.39,
    FIELD4: 52.82,
    FIELD5: 60.11,
    FIELD6: 69.27,
    FIELD7: 76.69
  },
  15.5: {
    FIELD1: 41.77,
    FIELD2: 43.72,
    FIELD3: 47.56,
    FIELD4: 52.98,
    FIELD5: 60.26,
    FIELD6: 69.44,
    FIELD7: 76.88
  },
  15.6: {
    FIELD1: 41.95,
    FIELD2: 43.9,
    FIELD3: 47.73,
    FIELD4: 53.13,
    FIELD5: 60.4,
    FIELD6: 69.59,
    FIELD7: 77.07
  },
  15.7: {
    FIELD1: 42.13,
    FIELD2: 44.07,
    FIELD3: 47.89,
    FIELD4: 53.28,
    FIELD5: 60.54,
    FIELD6: 69.74,
    FIELD7: 77.25
  },
  15.8: {
    FIELD1: 42.31,
    FIELD2: 44.24,
    FIELD3: 48.04,
    FIELD4: 53.42,
    FIELD5: 60.68,
    FIELD6: 69.88,
    FIELD7: 77.42
  },
  15.9: {
    FIELD1: 42.48,
    FIELD2: 44.4,
    FIELD3: 48.2,
    FIELD4: 53.56,
    FIELD5: 60.8,
    FIELD6: 70.02,
    FIELD7: 77.59
  },
  '15.10': {
    FIELD1: 42.64,
    FIELD2: 44.56,
    FIELD3: 48.34,
    FIELD4: 53.69,
    FIELD5: 60.93,
    FIELD6: 70.16,
    FIELD7: 77.75
  },
  15.11: {
    FIELD1: 42.8,
    FIELD2: 44.72,
    FIELD3: 48.48,
    FIELD4: 53.82,
    FIELD5: 61.05,
    FIELD6: 70.28,
    FIELD7: 77.9
  },
  '16.0': {
    FIELD1: 42.96,
    FIELD2: 44.86,
    FIELD3: 48.62,
    FIELD4: 53.95,
    FIELD5: 61.16,
    FIELD6: 70.4,
    FIELD7: 78.05
  },
  16.1: {
    FIELD1: 43.11,
    FIELD2: 45.01,
    FIELD3: 48.75,
    FIELD4: 54.07,
    FIELD5: 61.27,
    FIELD6: 70.52,
    FIELD7: 78.2
  },
  16.2: {
    FIELD1: 43.25,
    FIELD2: 45.15,
    FIELD3: 48.88,
    FIELD4: 54.18,
    FIELD5: 61.38,
    FIELD6: 70.64,
    FIELD7: 78.33
  },
  16.3: {
    FIELD1: 43.39,
    FIELD2: 45.28,
    FIELD3: 49,
    FIELD4: 54.29,
    FIELD5: 61.49,
    FIELD6: 70.75,
    FIELD7: 78.47
  },
  16.4: {
    FIELD1: 43.52,
    FIELD2: 45.41,
    FIELD3: 49.12,
    FIELD4: 54.4,
    FIELD5: 61.59,
    FIELD6: 70.85,
    FIELD7: 78.6
  },
  16.5: {
    FIELD1: 43.65,
    FIELD2: 45.53,
    FIELD3: 49.24,
    FIELD4: 54.51,
    FIELD5: 61.69,
    FIELD6: 70.96,
    FIELD7: 78.72
  },
  16.6: {
    FIELD1: 43.78,
    FIELD2: 45.66,
    FIELD3: 49.35,
    FIELD4: 54.61,
    FIELD5: 61.78,
    FIELD6: 71.06,
    FIELD7: 78.84
  },
  16.7: {
    FIELD1: 43.9,
    FIELD2: 45.77,
    FIELD3: 49.46,
    FIELD4: 54.71,
    FIELD5: 61.88,
    FIELD6: 71.16,
    FIELD7: 78.96
  },
  16.8: {
    FIELD1: 44.02,
    FIELD2: 45.88,
    FIELD3: 49.57,
    FIELD4: 54.81,
    FIELD5: 61.97,
    FIELD6: 71.26,
    FIELD7: 79.08
  },
  16.9: {
    FIELD1: 44.13,
    FIELD2: 45.99,
    FIELD3: 49.67,
    FIELD4: 54.91,
    FIELD5: 62.06,
    FIELD6: 71.35,
    FIELD7: 79.19
  },
  '16.10': {
    FIELD1: 44.23,
    FIELD2: 46.1,
    FIELD3: 49.77,
    FIELD4: 55,
    FIELD5: 62.15,
    FIELD6: 71.45,
    FIELD7: 79.3
  },
  16.11: {
    FIELD1: 44.34,
    FIELD2: 46.2,
    FIELD3: 49.87,
    FIELD4: 55.09,
    FIELD5: 62.24,
    FIELD6: 71.54,
    FIELD7: 79.41
  },
  '17.0': {
    FIELD1: 44.44,
    FIELD2: 46.29,
    FIELD3: 49.96,
    FIELD4: 55.18,
    FIELD5: 62.33,
    FIELD6: 71.64,
    FIELD7: 79.52
  },
  17.1: {
    FIELD1: 44.53,
    FIELD2: 46.39,
    FIELD3: 50.05,
    FIELD4: 55.27,
    FIELD5: 62.42,
    FIELD6: 71.73,
    FIELD7: 79.62
  },
  17.2: {
    FIELD1: 44.62,
    FIELD2: 46.48,
    FIELD3: 50.14,
    FIELD4: 55.36,
    FIELD5: 62.5,
    FIELD6: 71.82,
    FIELD7: 79.72
  },
  17.3: {
    FIELD1: 44.71,
    FIELD2: 46.57,
    FIELD3: 50.23,
    FIELD4: 55.45,
    FIELD5: 62.59,
    FIELD6: 71.92,
    FIELD7: 79.83
  },
  17.4: {
    FIELD1: 44.79,
    FIELD2: 46.65,
    FIELD3: 50.31,
    FIELD4: 55.53,
    FIELD5: 62.68,
    FIELD6: 72.01,
    FIELD7: 79.93
  },
  17.5: {
    FIELD1: 44.87,
    FIELD2: 46.73,
    FIELD3: 50.4,
    FIELD4: 55.62,
    FIELD5: 62.77,
    FIELD6: 72.11,
    FIELD7: 80.03
  },
  17.6: {
    FIELD1: 44.95,
    FIELD2: 46.81,
    FIELD3: 50.48,
    FIELD4: 55.71,
    FIELD5: 62.86,
    FIELD6: 72.21,
    FIELD7: 80.13
  },
  17.7: {
    FIELD1: 45.03,
    FIELD2: 46.89,
    FIELD3: 50.56,
    FIELD4: 55.79,
    FIELD5: 62.96,
    FIELD6: 72.31,
    FIELD7: 80.24
  },
  17.8: {
    FIELD1: 45.1,
    FIELD2: 46.96,
    FIELD3: 50.64,
    FIELD4: 55.88,
    FIELD5: 63.05,
    FIELD6: 72.41,
    FIELD7: 80.34
  },
  17.9: {
    FIELD1: 45.17,
    FIELD2: 47.03,
    FIELD3: 50.72,
    FIELD4: 55.97,
    FIELD5: 63.15,
    FIELD6: 72.51,
    FIELD7: 80.44
  },
  '17.10': {
    FIELD1: 45.23,
    FIELD2: 47.11,
    FIELD3: 50.8,
    FIELD4: 56.05,
    FIELD5: 63.24,
    FIELD6: 72.61,
    FIELD7: 80.54
  },
  17.11: {
    FIELD1: 45.3,
    FIELD2: 47.17,
    FIELD3: 50.87,
    FIELD4: 56.14,
    FIELD5: 63.34,
    FIELD6: 72.72,
    FIELD7: 80.65
  },
  '18.0': {
    FIELD1: 45.36,
    FIELD2: 47.24,
    FIELD3: 50.95,
    FIELD4: 56.23,
    FIELD5: 63.44,
    FIELD6: 72.83,
    FIELD7: 80.75
  },
  18.1: {
    FIELD1: 45.42,
    FIELD2: 47.31,
    FIELD3: 51.03,
    FIELD4: 56.32,
    FIELD5: 63.55,
    FIELD6: 72.94,
    FIELD7: 80.86
  },
  18.2: {
    FIELD1: 45.48,
    FIELD2: 47.37,
    FIELD3: 51.1,
    FIELD4: 56.41,
    FIELD5: 63.65,
    FIELD6: 73.05,
    FIELD7: 80.96
  },
  18.3: {
    FIELD1: 45.53,
    FIELD2: 47.43,
    FIELD3: 51.18,
    FIELD4: 56.5,
    FIELD5: 63.76,
    FIELD6: 73.16,
    FIELD7: 81.07
  },
  18.4: {
    FIELD1: 45.59,
    FIELD2: 47.5,
    FIELD3: 51.25,
    FIELD4: 56.59,
    FIELD5: 63.87,
    FIELD6: 73.28,
    FIELD7: 81.17
  },
  18.5: {
    FIELD1: 45.64,
    FIELD2: 47.56,
    FIELD3: 51.33,
    FIELD4: 56.69,
    FIELD5: 63.98,
    FIELD6: 73.39,
    FIELD7: 81.28
  },
  18.6: {
    FIELD1: 45.69,
    FIELD2: 47.62,
    FIELD3: 51.4,
    FIELD4: 56.78,
    FIELD5: 64.09,
    FIELD6: 73.51,
    FIELD7: 81.39
  },
  18.7: {
    FIELD1: 45.74,
    FIELD2: 47.68,
    FIELD3: 51.48,
    FIELD4: 56.87,
    FIELD5: 64.2,
    FIELD6: 73.63,
    FIELD7: 81.49
  },
  18.8: {
    FIELD1: 45.79,
    FIELD2: 47.73,
    FIELD3: 51.55,
    FIELD4: 56.97,
    FIELD5: 64.32,
    FIELD6: 73.75,
    FIELD7: 81.6
  },
  18.9: {
    FIELD1: 45.84,
    FIELD2: 47.79,
    FIELD3: 51.63,
    FIELD4: 57.07,
    FIELD5: 64.43,
    FIELD6: 73.87,
    FIELD7: 81.71
  },
  '18.10': {
    FIELD1: 45.88,
    FIELD2: 47.85,
    FIELD3: 51.7,
    FIELD4: 57.16,
    FIELD5: 64.55,
    FIELD6: 74,
    FIELD7: 81.81
  },
  18.11: {
    FIELD1: 45.93,
    FIELD2: 47.9,
    FIELD3: 51.77,
    FIELD4: 57.26,
    FIELD5: 64.66,
    FIELD6: 74.12,
    FIELD7: 81.92
  },
  '19.0': {
    FIELD1: 45.97,
    FIELD2: 47.95,
    FIELD3: 51.85,
    FIELD4: 57.35,
    FIELD5: 64.78,
    FIELD6: 74.24,
    FIELD7: 82.02
  },
  19.1: {
    FIELD1: 46.01,
    FIELD2: 48,
    FIELD3: 51.92,
    FIELD4: 57.45,
    FIELD5: 64.89,
    FIELD6: 74.36,
    FIELD7: 82.12
  },
  19.2: {
    FIELD1: 46.05,
    FIELD2: 48.05,
    FIELD3: 51.99,
    FIELD4: 57.54,
    FIELD5: 65.01,
    FIELD6: 74.47,
    FIELD7: 82.22
  },
  19.3: {
    FIELD1: 46.09,
    FIELD2: 48.1,
    FIELD3: 52.06,
    FIELD4: 57.63,
    FIELD5: 65.12,
    FIELD6: 74.59,
    FIELD7: 82.32
  },
  19.4: {
    FIELD1: 46.12,
    FIELD2: 48.15,
    FIELD3: 52.12,
    FIELD4: 57.72,
    FIELD5: 65.23,
    FIELD6: 74.7,
    FIELD7: 82.41
  },
  19.5: {
    FIELD1: 46.16,
    FIELD2: 48.19,
    FIELD3: 52.18,
    FIELD4: 57.8,
    FIELD5: 65.33,
    FIELD6: 74.81,
    FIELD7: 82.5
  },
  19.6: {
    FIELD1: 46.19,
    FIELD2: 48.23,
    FIELD3: 52.24,
    FIELD4: 57.88,
    FIELD5: 65.43,
    FIELD6: 74.91,
    FIELD7: 82.59
  },
  19.7: {
    FIELD1: 46.21,
    FIELD2: 48.27,
    FIELD3: 52.3,
    FIELD4: 57.96,
    FIELD5: 65.52,
    FIELD6: 75.01,
    FIELD7: 82.67
  },
  19.8: {
    FIELD1: 46.24,
    FIELD2: 48.3,
    FIELD3: 52.35,
    FIELD4: 58.03,
    FIELD5: 65.61,
    FIELD6: 75.1,
    FIELD7: 82.74
  },
  19.9: {
    FIELD1: 46.26,
    FIELD2: 48.33,
    FIELD3: 52.39,
    FIELD4: 58.09,
    FIELD5: 65.69,
    FIELD6: 75.18,
    FIELD7: 82.81
  },
  '19.10': {
    FIELD1: 46.27,
    FIELD2: 48.36,
    FIELD3: 52.43,
    FIELD4: 58.15,
    FIELD5: 65.76,
    FIELD6: 75.26,
    FIELD7: 82.88
  },
  19.11: {
    FIELD1: 46.29,
    FIELD2: 48.38,
    FIELD3: 52.47,
    FIELD4: 58.2,
    FIELD5: 65.83,
    FIELD6: 75.32,
    FIELD7: 82.93
  },
  '20.0': {
    FIELD1: 46.29,
    FIELD2: 48.38,
    FIELD3: 52.48,
    FIELD4: 58.22,
    FIELD5: 65.85,
    FIELD6: 75.35,
    FIELD7: 82.95
  }
};

export const BoyWeight: any = {
  '0.0': {
    FIELD1: 2.6,
    FIELD2: 2.8,
    FIELD3: 3,
    FIELD4: 3.3,
    FIELD5: 3.7,
    FIELD6: 4,
    FIELD7: 4.2
  },
  0.1: {
    FIELD1: 3.6,
    FIELD2: 3.8,
    FIELD3: 4.1,
    FIELD4: 4.5,
    FIELD5: 4.9,
    FIELD6: 5.3,
    FIELD7: 5.5
  },
  0.2: {
    FIELD1: 4.5,
    FIELD2: 4.7,
    FIELD3: 5.1,
    FIELD4: 5.6,
    FIELD5: 6,
    FIELD6: 6.5,
    FIELD7: 6.8
  },
  0.3: {
    FIELD1: 5.2,
    FIELD2: 5.5,
    FIELD3: 5.9,
    FIELD4: 6.4,
    FIELD5: 6.9,
    FIELD6: 7.4,
    FIELD7: 7.7
  },
  0.4: {
    FIELD1: 5.8,
    FIELD2: 6,
    FIELD3: 6.5,
    FIELD4: 7,
    FIELD5: 7.6,
    FIELD6: 8.1,
    FIELD7: 8.4
  },
  0.5: {
    FIELD1: 6.2,
    FIELD2: 6.5,
    FIELD3: 7,
    FIELD4: 7.5,
    FIELD5: 8.1,
    FIELD6: 8.6,
    FIELD7: 9
  },
  0.6: {
    FIELD1: 6.6,
    FIELD2: 6.9,
    FIELD3: 7.4,
    FIELD4: 7.9,
    FIELD5: 8.5,
    FIELD6: 9.1,
    FIELD7: 9.5
  },
  0.7: {
    FIELD1: 6.9,
    FIELD2: 7.2,
    FIELD3: 7.7,
    FIELD4: 8.3,
    FIELD5: 8.9,
    FIELD6: 9.5,
    FIELD7: 9.9
  },
  0.8: {
    FIELD1: 7.2,
    FIELD2: 7.5,
    FIELD3: 8,
    FIELD4: 8.6,
    FIELD5: 9.3,
    FIELD6: 9.9,
    FIELD7: 10.3
  },
  0.9: {
    FIELD1: 7.4,
    FIELD2: 7.7,
    FIELD3: 8.3,
    FIELD4: 8.9,
    FIELD5: 9.6,
    FIELD6: 10.2,
    FIELD7: 10.6
  },
  '0.10': {
    FIELD1: 7.7,
    FIELD2: 8,
    FIELD3: 8.5,
    FIELD4: 9.2,
    FIELD5: 9.9,
    FIELD6: 10.5,
    FIELD7: 10.9
  },
  0.11: {
    FIELD1: 7.9,
    FIELD2: 8.2,
    FIELD3: 8.7,
    FIELD4: 9.4,
    FIELD5: 10.1,
    FIELD6: 10.8,
    FIELD7: 11.2
  },
  '1.0': {
    FIELD1: 8.1,
    FIELD2: 8.4,
    FIELD3: 9,
    FIELD4: 9.6,
    FIELD5: 10.4,
    FIELD6: 11.1,
    FIELD7: 11.5
  },
  1.1: {
    FIELD1: 8.2,
    FIELD2: 8.6,
    FIELD3: 9.2,
    FIELD4: 9.9,
    FIELD5: 10.6,
    FIELD6: 11.4,
    FIELD7: 11.8
  },
  1.2: {
    FIELD1: 8.4,
    FIELD2: 8.8,
    FIELD3: 9.4,
    FIELD4: 10.1,
    FIELD5: 10.9,
    FIELD6: 11.6,
    FIELD7: 12.1
  },
  1.3: {
    FIELD1: 8.6,
    FIELD2: 9,
    FIELD3: 9.6,
    FIELD4: 10.3,
    FIELD5: 11.1,
    FIELD6: 11.9,
    FIELD7: 12.3
  },
  1.4: {
    FIELD1: 8.8,
    FIELD2: 9.1,
    FIELD3: 9.8,
    FIELD4: 10.5,
    FIELD5: 11.3,
    FIELD6: 12.1,
    FIELD7: 12.6
  },
  1.5: {
    FIELD1: 8.9,
    FIELD2: 9.3,
    FIELD3: 10,
    FIELD4: 10.7,
    FIELD5: 11.6,
    FIELD6: 12.4,
    FIELD7: 12.9
  },
  1.6: {
    FIELD1: 9.1,
    FIELD2: 9.5,
    FIELD3: 10.1,
    FIELD4: 10.9,
    FIELD5: 11.8,
    FIELD6: 12.6,
    FIELD7: 13.1
  },
  1.7: {
    FIELD1: 9.3,
    FIELD2: 9.7,
    FIELD3: 10.3,
    FIELD4: 11.1,
    FIELD5: 12,
    FIELD6: 12.9,
    FIELD7: 13.4
  },
  1.8: {
    FIELD1: 9.4,
    FIELD2: 9.8,
    FIELD3: 10.5,
    FIELD4: 11.3,
    FIELD5: 12.2,
    FIELD6: 13.1,
    FIELD7: 13.6
  },
  1.9: {
    FIELD1: 9.6,
    FIELD2: 10,
    FIELD3: 10.7,
    FIELD4: 11.5,
    FIELD5: 12.5,
    FIELD6: 13.3,
    FIELD7: 13.9
  },
  '1.10': {
    FIELD1: 9.8,
    FIELD2: 10.2,
    FIELD3: 10.9,
    FIELD4: 11.8,
    FIELD5: 12.7,
    FIELD6: 13.6,
    FIELD7: 14.2
  },
  1.11: {
    FIELD1: 9.9,
    FIELD2: 10.3,
    FIELD3: 11.1,
    FIELD4: 12,
    FIELD5: 12.9,
    FIELD6: 13.8,
    FIELD7: 14.4
  },
  '2.0': {
    FIELD1: 10.1,
    FIELD2: 10.5,
    FIELD3: 11.3,
    FIELD4: 12.2,
    FIELD5: 13.1,
    FIELD6: 14.1,
    FIELD7: 14.7
  },
  2.1: {
    FIELD1: 10.2,
    FIELD2: 10.7,
    FIELD3: 11.4,
    FIELD4: 12.4,
    FIELD5: 13.3,
    FIELD6: 14.3,
    FIELD7: 14.9
  },
  2.2: {
    FIELD1: 10.4,
    FIELD2: 10.8,
    FIELD3: 11.6,
    FIELD4: 12.5,
    FIELD5: 13.6,
    FIELD6: 14.6,
    FIELD7: 15.2
  },
  2.3: {
    FIELD1: 10.5,
    FIELD2: 11,
    FIELD3: 11.8,
    FIELD4: 12.7,
    FIELD5: 13.8,
    FIELD6: 14.8,
    FIELD7: 15.4
  },
  2.4: {
    FIELD1: 10.7,
    FIELD2: 11.1,
    FIELD3: 12,
    FIELD4: 12.9,
    FIELD5: 14,
    FIELD6: 15,
    FIELD7: 15.7
  },
  2.5: {
    FIELD1: 10.8,
    FIELD2: 11.3,
    FIELD3: 12.1,
    FIELD4: 13.1,
    FIELD5: 14.2,
    FIELD6: 15.2,
    FIELD7: 15.9
  },
  2.6: {
    FIELD1: 11,
    FIELD2: 11.4,
    FIELD3: 12.3,
    FIELD4: 13.3,
    FIELD5: 14.4,
    FIELD6: 15.5,
    FIELD7: 16.2
  },
  2.7: {
    FIELD1: 11.1,
    FIELD2: 11.6,
    FIELD3: 12.4,
    FIELD4: 13.5,
    FIELD5: 14.6,
    FIELD6: 15.7,
    FIELD7: 16.4
  },
  2.8: {
    FIELD1: 11.2,
    FIELD2: 11.7,
    FIELD3: 12.6,
    FIELD4: 13.7,
    FIELD5: 14.8,
    FIELD6: 15.9,
    FIELD7: 16.6
  },
  2.9: {
    FIELD1: 11.4,
    FIELD2: 11.9,
    FIELD3: 12.8,
    FIELD4: 13.8,
    FIELD5: 15,
    FIELD6: 16.1,
    FIELD7: 16.9
  },
  '2.10': {
    FIELD1: 11.5,
    FIELD2: 12,
    FIELD3: 12.9,
    FIELD4: 14,
    FIELD5: 15.2,
    FIELD6: 16.3,
    FIELD7: 17.1
  },
  2.11: {
    FIELD1: 11.6,
    FIELD2: 12.2,
    FIELD3: 13.1,
    FIELD4: 14.2,
    FIELD5: 15.4,
    FIELD6: 16.6,
    FIELD7: 17.3
  },
  '3.0': {
    FIELD1: 11.8,
    FIELD2: 12.3,
    FIELD3: 13.2,
    FIELD4: 14.3,
    FIELD5: 15.6,
    FIELD6: 16.8,
    FIELD7: 17.5
  },
  3.1: {
    FIELD1: 11.9,
    FIELD2: 12.4,
    FIELD3: 13.4,
    FIELD4: 14.5,
    FIELD5: 15.8,
    FIELD6: 17,
    FIELD7: 17.8
  },
  3.2: {
    FIELD1: 12,
    FIELD2: 12.6,
    FIELD3: 13.5,
    FIELD4: 14.7,
    FIELD5: 15.9,
    FIELD6: 17.2,
    FIELD7: 18
  },
  3.3: {
    FIELD1: 12.2,
    FIELD2: 12.7,
    FIELD3: 13.7,
    FIELD4: 14.8,
    FIELD5: 16.1,
    FIELD6: 17.4,
    FIELD7: 18.2
  },
  3.4: {
    FIELD1: 12.3,
    FIELD2: 12.8,
    FIELD3: 13.8,
    FIELD4: 15,
    FIELD5: 16.3,
    FIELD6: 17.6,
    FIELD7: 18.4
  },
  3.5: {
    FIELD1: 12.4,
    FIELD2: 13,
    FIELD3: 14,
    FIELD4: 15.2,
    FIELD5: 16.5,
    FIELD6: 17.8,
    FIELD7: 18.6
  },
  3.6: {
    FIELD1: 12.5,
    FIELD2: 13.1,
    FIELD3: 14.1,
    FIELD4: 15.3,
    FIELD5: 16.7,
    FIELD6: 18,
    FIELD7: 18.9
  },
  3.7: {
    FIELD1: 12.7,
    FIELD2: 13.2,
    FIELD3: 14.3,
    FIELD4: 15.5,
    FIELD5: 16.9,
    FIELD6: 18.2,
    FIELD7: 19.1
  },
  3.8: {
    FIELD1: 12.8,
    FIELD2: 13.4,
    FIELD3: 14.4,
    FIELD4: 15.7,
    FIELD5: 17.1,
    FIELD6: 18.4,
    FIELD7: 19.3
  },
  3.9: {
    FIELD1: 12.9,
    FIELD2: 13.5,
    FIELD3: 14.6,
    FIELD4: 15.8,
    FIELD5: 17.3,
    FIELD6: 18.6,
    FIELD7: 19.5
  },
  '3.10': {
    FIELD1: 13,
    FIELD2: 13.6,
    FIELD3: 14.7,
    FIELD4: 16,
    FIELD5: 17.4,
    FIELD6: 18.9,
    FIELD7: 19.8
  },
  3.11: {
    FIELD1: 13.2,
    FIELD2: 13.8,
    FIELD3: 14.9,
    FIELD4: 16.2,
    FIELD5: 17.6,
    FIELD6: 19.1,
    FIELD7: 20
  },
  '4.0': {
    FIELD1: 13.3,
    FIELD2: 13.9,
    FIELD3: 15,
    FIELD4: 16.3,
    FIELD5: 17.8,
    FIELD6: 19.3,
    FIELD7: 20.2
  },
  4.1: {
    FIELD1: 13.4,
    FIELD2: 14,
    FIELD3: 15.2,
    FIELD4: 16.5,
    FIELD5: 18,
    FIELD6: 19.5,
    FIELD7: 20.4
  },
  4.2: {
    FIELD1: 13.5,
    FIELD2: 14.2,
    FIELD3: 15.3,
    FIELD4: 16.7,
    FIELD5: 18.2,
    FIELD6: 19.7,
    FIELD7: 20.7
  },
  4.3: {
    FIELD1: 13.7,
    FIELD2: 14.3,
    FIELD3: 15.4,
    FIELD4: 16.8,
    FIELD5: 18.4,
    FIELD6: 19.9,
    FIELD7: 20.9
  },
  4.4: {
    FIELD1: 13.8,
    FIELD2: 14.4,
    FIELD3: 15.6,
    FIELD4: 17,
    FIELD5: 18.6,
    FIELD6: 20.1,
    FIELD7: 21.1
  },
  4.5: {
    FIELD1: 13.9,
    FIELD2: 14.6,
    FIELD3: 15.7,
    FIELD4: 17.2,
    FIELD5: 18.8,
    FIELD6: 20.3,
    FIELD7: 21.4
  },
  4.6: {
    FIELD1: 14,
    FIELD2: 14.7,
    FIELD3: 15.9,
    FIELD4: 17.3,
    FIELD5: 19,
    FIELD6: 20.6,
    FIELD7: 21.6
  },
  4.7: {
    FIELD1: 14.1,
    FIELD2: 14.8,
    FIELD3: 16,
    FIELD4: 17.5,
    FIELD5: 19.2,
    FIELD6: 20.8,
    FIELD7: 21.8
  },
  4.8: {
    FIELD1: 14.3,
    FIELD2: 14.9,
    FIELD3: 16.2,
    FIELD4: 17.7,
    FIELD5: 19.3,
    FIELD6: 21,
    FIELD7: 22.1
  },
  4.9: {
    FIELD1: 14.4,
    FIELD2: 15.1,
    FIELD3: 16.3,
    FIELD4: 17.8,
    FIELD5: 19.5,
    FIELD6: 21.2,
    FIELD7: 22.3
  },
  '4.10': {
    FIELD1: 14.5,
    FIELD2: 15.2,
    FIELD3: 16.5,
    FIELD4: 18,
    FIELD5: 19.7,
    FIELD6: 21.4,
    FIELD7: 22.5
  },
  4.11: {
    FIELD1: 14.6,
    FIELD2: 15.3,
    FIELD3: 16.6,
    FIELD4: 18.2,
    FIELD5: 19.9,
    FIELD6: 21.6,
    FIELD7: 22.8
  },
  '5.0': {
    FIELD1: 14.7,
    FIELD2: 15.5,
    FIELD3: 16.7,
    FIELD4: 18.3,
    FIELD5: 20.1,
    FIELD6: 21.9,
    FIELD7: 23
  },
  5.1: {
    FIELD1: 15.01,
    FIELD2: 15.71,
    FIELD3: 16.97,
    FIELD4: 18.51,
    FIELD5: 20.22,
    FIELD6: 21.92,
    FIELD7: 23.02
  },
  5.2: {
    FIELD1: 15.15,
    FIELD2: 15.85,
    FIELD3: 17.12,
    FIELD4: 18.68,
    FIELD5: 20.41,
    FIELD6: 22.14,
    FIELD7: 23.26
  },
  5.3: {
    FIELD1: 15.29,
    FIELD2: 16,
    FIELD3: 17.28,
    FIELD4: 18.86,
    FIELD5: 20.61,
    FIELD6: 22.37,
    FIELD7: 23.5
  },
  5.4: {
    FIELD1: 15.42,
    FIELD2: 16.14,
    FIELD3: 17.44,
    FIELD4: 19.03,
    FIELD5: 20.81,
    FIELD6: 22.59,
    FIELD7: 23.75
  },
  5.5: {
    FIELD1: 15.56,
    FIELD2: 16.29,
    FIELD3: 17.6,
    FIELD4: 19.21,
    FIELD5: 21.02,
    FIELD6: 22.82,
    FIELD7: 23.99
  },
  5.6: {
    FIELD1: 15.71,
    FIELD2: 16.44,
    FIELD3: 17.76,
    FIELD4: 19.39,
    FIELD5: 21.22,
    FIELD6: 23.05,
    FIELD7: 24.24
  },
  5.7: {
    FIELD1: 15.85,
    FIELD2: 16.59,
    FIELD3: 17.93,
    FIELD4: 19.58,
    FIELD5: 21.42,
    FIELD6: 23.28,
    FIELD7: 24.49
  },
  5.8: {
    FIELD1: 15.99,
    FIELD2: 16.74,
    FIELD3: 18.09,
    FIELD4: 19.76,
    FIELD5: 21.63,
    FIELD6: 23.51,
    FIELD7: 24.74
  },
  5.9: {
    FIELD1: 16.14,
    FIELD2: 16.89,
    FIELD3: 18.26,
    FIELD4: 19.95,
    FIELD5: 21.84,
    FIELD6: 23.75,
    FIELD7: 24.99
  },
  '5.10': {
    FIELD1: 16.29,
    FIELD2: 17.05,
    FIELD3: 18.43,
    FIELD4: 20.13,
    FIELD5: 22.05,
    FIELD6: 23.99,
    FIELD7: 25.25
  },
  5.11: {
    FIELD1: 16.44,
    FIELD2: 17.2,
    FIELD3: 18.6,
    FIELD4: 20.32,
    FIELD5: 22.27,
    FIELD6: 24.23,
    FIELD7: 25.51
  },
  '6.0': {
    FIELD1: 16.59,
    FIELD2: 17.36,
    FIELD3: 18.77,
    FIELD4: 20.51,
    FIELD5: 22.48,
    FIELD6: 24.47,
    FIELD7: 25.77
  },
  6.1: {
    FIELD1: 16.74,
    FIELD2: 17.52,
    FIELD3: 18.94,
    FIELD4: 20.71,
    FIELD5: 22.7,
    FIELD6: 24.71,
    FIELD7: 26.03
  },
  6.2: {
    FIELD1: 16.89,
    FIELD2: 17.68,
    FIELD3: 19.11,
    FIELD4: 20.9,
    FIELD5: 22.91,
    FIELD6: 24.95,
    FIELD7: 26.29
  },
  6.3: {
    FIELD1: 17.04,
    FIELD2: 17.84,
    FIELD3: 19.29,
    FIELD4: 21.09,
    FIELD5: 23.13,
    FIELD6: 25.2,
    FIELD7: 26.56
  },
  6.4: {
    FIELD1: 17.19,
    FIELD2: 18,
    FIELD3: 19.46,
    FIELD4: 21.29,
    FIELD5: 23.35,
    FIELD6: 25.45,
    FIELD7: 26.83
  },
  6.5: {
    FIELD1: 17.35,
    FIELD2: 18.16,
    FIELD3: 19.64,
    FIELD4: 21.48,
    FIELD5: 23.57,
    FIELD6: 25.7,
    FIELD7: 27.1
  },
  6.6: {
    FIELD1: 17.5,
    FIELD2: 18.32,
    FIELD3: 19.82,
    FIELD4: 21.68,
    FIELD5: 23.8,
    FIELD6: 25.95,
    FIELD7: 27.37
  },
  6.7: {
    FIELD1: 17.66,
    FIELD2: 18.49,
    FIELD3: 20,
    FIELD4: 21.88,
    FIELD5: 24.02,
    FIELD6: 26.2,
    FIELD7: 27.64
  },
  6.8: {
    FIELD1: 17.82,
    FIELD2: 18.65,
    FIELD3: 20.18,
    FIELD4: 22.08,
    FIELD5: 24.25,
    FIELD6: 26.46,
    FIELD7: 27.92
  },
  6.9: {
    FIELD1: 17.97,
    FIELD2: 18.82,
    FIELD3: 20.36,
    FIELD4: 22.28,
    FIELD5: 24.47,
    FIELD6: 26.72,
    FIELD7: 28.2
  },
  '6.10': {
    FIELD1: 18.13,
    FIELD2: 18.98,
    FIELD3: 20.54,
    FIELD4: 22.48,
    FIELD5: 24.7,
    FIELD6: 26.98,
    FIELD7: 28.48
  },
  6.11: {
    FIELD1: 18.29,
    FIELD2: 19.15,
    FIELD3: 20.72,
    FIELD4: 22.69,
    FIELD5: 24.93,
    FIELD6: 27.24,
    FIELD7: 28.77
  },
  '7.0': {
    FIELD1: 18.45,
    FIELD2: 19.32,
    FIELD3: 20.9,
    FIELD4: 22.89,
    FIELD5: 25.17,
    FIELD6: 27.5,
    FIELD7: 29.05
  },
  7.1: {
    FIELD1: 18.61,
    FIELD2: 19.48,
    FIELD3: 21.08,
    FIELD4: 23.1,
    FIELD5: 25.4,
    FIELD6: 27.77,
    FIELD7: 29.34
  },
  7.2: {
    FIELD1: 18.77,
    FIELD2: 19.65,
    FIELD3: 21.27,
    FIELD4: 23.3,
    FIELD5: 25.64,
    FIELD6: 28.04,
    FIELD7: 29.64
  },
  7.3: {
    FIELD1: 18.93,
    FIELD2: 19.82,
    FIELD3: 21.45,
    FIELD4: 23.51,
    FIELD5: 25.87,
    FIELD6: 28.31,
    FIELD7: 29.93
  },
  7.4: {
    FIELD1: 19.09,
    FIELD2: 19.99,
    FIELD3: 21.64,
    FIELD4: 23.72,
    FIELD5: 26.11,
    FIELD6: 28.58,
    FIELD7: 30.23
  },
  7.5: {
    FIELD1: 19.24,
    FIELD2: 20.15,
    FIELD3: 21.82,
    FIELD4: 23.93,
    FIELD5: 26.35,
    FIELD6: 28.86,
    FIELD7: 30.53
  },
  7.6: {
    FIELD1: 19.4,
    FIELD2: 20.32,
    FIELD3: 22.01,
    FIELD4: 24.14,
    FIELD5: 26.59,
    FIELD6: 29.13,
    FIELD7: 30.83
  },
  7.7: {
    FIELD1: 19.56,
    FIELD2: 20.49,
    FIELD3: 22.19,
    FIELD4: 24.35,
    FIELD5: 26.83,
    FIELD6: 29.41,
    FIELD7: 31.14
  },
  7.8: {
    FIELD1: 19.72,
    FIELD2: 20.66,
    FIELD3: 22.38,
    FIELD4: 24.56,
    FIELD5: 27.08,
    FIELD6: 29.7,
    FIELD7: 31.45
  },
  7.9: {
    FIELD1: 19.88,
    FIELD2: 20.83,
    FIELD3: 22.57,
    FIELD4: 24.77,
    FIELD5: 27.32,
    FIELD6: 29.98,
    FIELD7: 31.76
  },
  '7.10': {
    FIELD1: 20.04,
    FIELD2: 21,
    FIELD3: 22.75,
    FIELD4: 24.99,
    FIELD5: 27.57,
    FIELD6: 30.27,
    FIELD7: 32.08
  },
  7.11: {
    FIELD1: 20.2,
    FIELD2: 21.16,
    FIELD3: 22.94,
    FIELD4: 25.2,
    FIELD5: 27.82,
    FIELD6: 30.56,
    FIELD7: 32.4
  },
  '8.0': {
    FIELD1: 20.36,
    FIELD2: 21.33,
    FIELD3: 23.13,
    FIELD4: 25.42,
    FIELD5: 28.07,
    FIELD6: 30.85,
    FIELD7: 32.72
  },
  8.1: {
    FIELD1: 20.52,
    FIELD2: 21.5,
    FIELD3: 23.32,
    FIELD4: 25.63,
    FIELD5: 28.33,
    FIELD6: 31.15,
    FIELD7: 33.05
  },
  8.2: {
    FIELD1: 20.68,
    FIELD2: 21.67,
    FIELD3: 23.51,
    FIELD4: 25.85,
    FIELD5: 28.58,
    FIELD6: 31.45,
    FIELD7: 33.38
  },
  8.3: {
    FIELD1: 20.84,
    FIELD2: 21.84,
    FIELD3: 23.7,
    FIELD4: 26.07,
    FIELD5: 28.84,
    FIELD6: 31.75,
    FIELD7: 33.72
  },
  8.4: {
    FIELD1: 21,
    FIELD2: 22.01,
    FIELD3: 23.89,
    FIELD4: 26.29,
    FIELD5: 29.1,
    FIELD6: 32.05,
    FIELD7: 34.06
  },
  8.5: {
    FIELD1: 21.15,
    FIELD2: 22.18,
    FIELD3: 24.08,
    FIELD4: 26.51,
    FIELD5: 29.36,
    FIELD6: 32.36,
    FIELD7: 34.4
  },
  8.6: {
    FIELD1: 21.31,
    FIELD2: 22.35,
    FIELD3: 24.27,
    FIELD4: 26.74,
    FIELD5: 29.62,
    FIELD6: 32.67,
    FIELD7: 34.75
  },
  8.7: {
    FIELD1: 21.47,
    FIELD2: 22.52,
    FIELD3: 24.47,
    FIELD4: 26.96,
    FIELD5: 29.89,
    FIELD6: 32.99,
    FIELD7: 35.1
  },
  8.8: {
    FIELD1: 21.63,
    FIELD2: 22.69,
    FIELD3: 24.66,
    FIELD4: 27.19,
    FIELD5: 30.16,
    FIELD6: 33.31,
    FIELD7: 35.45
  },
  8.9: {
    FIELD1: 21.79,
    FIELD2: 22.86,
    FIELD3: 24.85,
    FIELD4: 27.41,
    FIELD5: 30.43,
    FIELD6: 33.63,
    FIELD7: 35.81
  },
  '8.10': {
    FIELD1: 21.95,
    FIELD2: 23.04,
    FIELD3: 25.05,
    FIELD4: 27.64,
    FIELD5: 30.7,
    FIELD6: 33.96,
    FIELD7: 36.18
  },
  8.11: {
    FIELD1: 22.11,
    FIELD2: 23.21,
    FIELD3: 25.25,
    FIELD4: 27.88,
    FIELD5: 30.98,
    FIELD6: 34.29,
    FIELD7: 36.55
  },
  '9.0': {
    FIELD1: 22.28,
    FIELD2: 23.38,
    FIELD3: 25.45,
    FIELD4: 28.11,
    FIELD5: 31.26,
    FIELD6: 34.62,
    FIELD7: 36.93
  },
  9.1: {
    FIELD1: 22.44,
    FIELD2: 23.56,
    FIELD3: 25.65,
    FIELD4: 28.35,
    FIELD5: 31.54,
    FIELD6: 34.96,
    FIELD7: 37.31
  },
  9.2: {
    FIELD1: 22.61,
    FIELD2: 23.74,
    FIELD3: 25.85,
    FIELD4: 28.59,
    FIELD5: 31.83,
    FIELD6: 35.31,
    FIELD7: 37.7
  },
  9.3: {
    FIELD1: 22.77,
    FIELD2: 23.92,
    FIELD3: 26.06,
    FIELD4: 28.83,
    FIELD5: 32.12,
    FIELD6: 35.66,
    FIELD7: 38.09
  },
  9.4: {
    FIELD1: 22.94,
    FIELD2: 24.1,
    FIELD3: 26.26,
    FIELD4: 29.07,
    FIELD5: 32.42,
    FIELD6: 36.01,
    FIELD7: 38.49
  },
  9.5: {
    FIELD1: 23.11,
    FIELD2: 24.28,
    FIELD3: 26.47,
    FIELD4: 29.32,
    FIELD5: 32.72,
    FIELD6: 36.37,
    FIELD7: 38.89
  },
  9.6: {
    FIELD1: 23.28,
    FIELD2: 24.47,
    FIELD3: 26.69,
    FIELD4: 29.57,
    FIELD5: 33.02,
    FIELD6: 36.73,
    FIELD7: 39.3
  },
  9.7: {
    FIELD1: 23.46,
    FIELD2: 24.66,
    FIELD3: 26.9,
    FIELD4: 29.83,
    FIELD5: 33.33,
    FIELD6: 37.1,
    FIELD7: 39.72
  },
  9.8: {
    FIELD1: 23.63,
    FIELD2: 24.85,
    FIELD3: 27.12,
    FIELD4: 30.09,
    FIELD5: 33.64,
    FIELD6: 37.48,
    FIELD7: 40.14
  },
  9.9: {
    FIELD1: 23.81,
    FIELD2: 25.04,
    FIELD3: 27.34,
    FIELD4: 30.35,
    FIELD5: 33.96,
    FIELD6: 37.86,
    FIELD7: 40.57
  },
  '9.10': {
    FIELD1: 23.99,
    FIELD2: 25.23,
    FIELD3: 27.57,
    FIELD4: 30.62,
    FIELD5: 34.28,
    FIELD6: 38.25,
    FIELD7: 41
  },
  9.11: {
    FIELD1: 24.17,
    FIELD2: 25.43,
    FIELD3: 27.79,
    FIELD4: 30.89,
    FIELD5: 34.6,
    FIELD6: 38.64,
    FIELD7: 41.44
  },
  '10.0': {
    FIELD1: 24.35,
    FIELD2: 25.63,
    FIELD3: 28.02,
    FIELD4: 31.16,
    FIELD5: 34.93,
    FIELD6: 39.03,
    FIELD7: 41.89
  },
  10.1: {
    FIELD1: 25.14,
    FIELD2: 26.43,
    FIELD3: 28.93,
    FIELD4: 32.4,
    FIELD5: 36.94,
    FIELD6: 42.42,
    FIELD7: 46.67
  },
  10.2: {
    FIELD1: 25.34,
    FIELD2: 26.65,
    FIELD3: 29.18,
    FIELD4: 32.71,
    FIELD5: 37.32,
    FIELD6: 42.88,
    FIELD7: 47.19
  },
  10.3: {
    FIELD1: 25.55,
    FIELD2: 26.87,
    FIELD3: 29.45,
    FIELD4: 33.03,
    FIELD5: 37.7,
    FIELD6: 43.35,
    FIELD7: 47.71
  },
  10.4: {
    FIELD1: 25.75,
    FIELD2: 27.1,
    FIELD3: 29.71,
    FIELD4: 33.35,
    FIELD5: 38.09,
    FIELD6: 43.82,
    FIELD7: 48.23
  },
  10.5: {
    FIELD1: 25.96,
    FIELD2: 27.32,
    FIELD3: 29.98,
    FIELD4: 33.67,
    FIELD5: 38.49,
    FIELD6: 44.29,
    FIELD7: 48.76
  },
  10.6: {
    FIELD1: 26.17,
    FIELD2: 27.56,
    FIELD3: 30.26,
    FIELD4: 34,
    FIELD5: 38.89,
    FIELD6: 44.77,
    FIELD7: 49.3
  },
  10.7: {
    FIELD1: 26.39,
    FIELD2: 27.79,
    FIELD3: 30.53,
    FIELD4: 34.34,
    FIELD5: 39.29,
    FIELD6: 45.25,
    FIELD7: 49.83
  },
  10.8: {
    FIELD1: 26.6,
    FIELD2: 28.03,
    FIELD3: 30.82,
    FIELD4: 34.68,
    FIELD5: 39.7,
    FIELD6: 45.74,
    FIELD7: 50.37
  },
  10.9: {
    FIELD1: 26.82,
    FIELD2: 28.27,
    FIELD3: 31.1,
    FIELD4: 35.02,
    FIELD5: 40.12,
    FIELD6: 46.23,
    FIELD7: 50.91
  },
  '10.10': {
    FIELD1: 27.04,
    FIELD2: 28.52,
    FIELD3: 31.39,
    FIELD4: 35.37,
    FIELD5: 40.53,
    FIELD6: 46.73,
    FIELD7: 51.46
  },
  10.11: {
    FIELD1: 27.27,
    FIELD2: 28.77,
    FIELD3: 31.68,
    FIELD4: 35.72,
    FIELD5: 40.96,
    FIELD6: 47.22,
    FIELD7: 52.01
  },
  '11.0': {
    FIELD1: 27.5,
    FIELD2: 29.02,
    FIELD3: 31.98,
    FIELD4: 36.07,
    FIELD5: 41.38,
    FIELD6: 47.73,
    FIELD7: 52.56
  },
  11.1: {
    FIELD1: 27.73,
    FIELD2: 29.28,
    FIELD3: 32.28,
    FIELD4: 36.43,
    FIELD5: 41.81,
    FIELD6: 48.23,
    FIELD7: 53.11
  },
  11.2: {
    FIELD1: 27.97,
    FIELD2: 29.54,
    FIELD3: 32.58,
    FIELD4: 36.8,
    FIELD5: 42.25,
    FIELD6: 48.74,
    FIELD7: 53.66
  },
  11.3: {
    FIELD1: 28.21,
    FIELD2: 29.8,
    FIELD3: 32.89,
    FIELD4: 37.17,
    FIELD5: 42.69,
    FIELD6: 49.25,
    FIELD7: 54.22
  },
  11.4: {
    FIELD1: 28.45,
    FIELD2: 30.07,
    FIELD3: 33.21,
    FIELD4: 37.54,
    FIELD5: 43.13,
    FIELD6: 49.77,
    FIELD7: 54.78
  },
  11.5: {
    FIELD1: 28.7,
    FIELD2: 30.34,
    FIELD3: 33.52,
    FIELD4: 37.92,
    FIELD5: 43.58,
    FIELD6: 50.28,
    FIELD7: 55.34
  },
  11.6: {
    FIELD1: 28.95,
    FIELD2: 30.62,
    FIELD3: 33.85,
    FIELD4: 38.3,
    FIELD5: 44.03,
    FIELD6: 50.8,
    FIELD7: 55.9
  },
  11.7: {
    FIELD1: 29.21,
    FIELD2: 30.9,
    FIELD3: 34.17,
    FIELD4: 38.68,
    FIELD5: 44.49,
    FIELD6: 51.33,
    FIELD7: 56.47
  },
  11.8: {
    FIELD1: 29.47,
    FIELD2: 31.18,
    FIELD3: 34.5,
    FIELD4: 39.07,
    FIELD5: 44.94,
    FIELD6: 51.85,
    FIELD7: 57.03
  },
  11.9: {
    FIELD1: 29.73,
    FIELD2: 31.47,
    FIELD3: 34.84,
    FIELD4: 39.47,
    FIELD5: 45.41,
    FIELD6: 52.38,
    FIELD7: 57.6
  },
  '11.10': {
    FIELD1: 30,
    FIELD2: 31.76,
    FIELD3: 35.18,
    FIELD4: 39.87,
    FIELD5: 45.87,
    FIELD6: 52.91,
    FIELD7: 58.17
  },
  11.11: {
    FIELD1: 30.27,
    FIELD2: 32.06,
    FIELD3: 35.52,
    FIELD4: 40.27,
    FIELD5: 46.34,
    FIELD6: 53.44,
    FIELD7: 58.74
  },
  '12.0': {
    FIELD1: 30.55,
    FIELD2: 32.36,
    FIELD3: 35.87,
    FIELD4: 40.67,
    FIELD5: 46.81,
    FIELD6: 53.98,
    FIELD7: 59.3
  },
  12.1: {
    FIELD1: 30.83,
    FIELD2: 32.67,
    FIELD3: 36.22,
    FIELD4: 41.08,
    FIELD5: 47.29,
    FIELD6: 54.51,
    FIELD7: 59.87
  },
  12.2: {
    FIELD1: 31.12,
    FIELD2: 32.98,
    FIELD3: 36.58,
    FIELD4: 41.5,
    FIELD5: 47.76,
    FIELD6: 55.05,
    FIELD7: 60.44
  },
  12.3: {
    FIELD1: 31.41,
    FIELD2: 33.29,
    FIELD3: 36.94,
    FIELD4: 41.92,
    FIELD5: 48.24,
    FIELD6: 55.59,
    FIELD7: 61.01
  },
  12.4: {
    FIELD1: 31.7,
    FIELD2: 33.61,
    FIELD3: 37.3,
    FIELD4: 42.34,
    FIELD5: 48.73,
    FIELD6: 56.12,
    FIELD7: 61.58
  },
  12.5: {
    FIELD1: 32,
    FIELD2: 33.94,
    FIELD3: 37.67,
    FIELD4: 42.76,
    FIELD5: 49.21,
    FIELD6: 56.66,
    FIELD7: 62.15
  },
  12.6: {
    FIELD1: 32.31,
    FIELD2: 34.26,
    FIELD3: 38.04,
    FIELD4: 43.19,
    FIELD5: 49.7,
    FIELD6: 57.2,
    FIELD7: 62.72
  },
  12.7: {
    FIELD1: 32.62,
    FIELD2: 34.6,
    FIELD3: 38.42,
    FIELD4: 43.62,
    FIELD5: 50.19,
    FIELD6: 57.74,
    FIELD7: 63.28
  },
  12.8: {
    FIELD1: 32.93,
    FIELD2: 34.93,
    FIELD3: 38.8,
    FIELD4: 44.05,
    FIELD5: 50.68,
    FIELD6: 58.29,
    FIELD7: 63.85
  },
  12.9: {
    FIELD1: 33.25,
    FIELD2: 35.27,
    FIELD3: 39.18,
    FIELD4: 44.49,
    FIELD5: 51.17,
    FIELD6: 58.83,
    FIELD7: 64.41
  },
  '12.10': {
    FIELD1: 33.57,
    FIELD2: 35.62,
    FIELD3: 39.57,
    FIELD4: 44.93,
    FIELD5: 51.67,
    FIELD6: 59.37,
    FIELD7: 64.98
  },
  12.11: {
    FIELD1: 33.89,
    FIELD2: 35.97,
    FIELD3: 39.96,
    FIELD4: 45.37,
    FIELD5: 52.16,
    FIELD6: 59.91,
    FIELD7: 65.54
  },
  '13.0': {
    FIELD1: 34.22,
    FIELD2: 36.32,
    FIELD3: 40.36,
    FIELD4: 45.81,
    FIELD5: 52.66,
    FIELD6: 60.45,
    FIELD7: 66.1
  },
  13.1: {
    FIELD1: 34.56,
    FIELD2: 36.68,
    FIELD3: 40.75,
    FIELD4: 46.26,
    FIELD5: 53.15,
    FIELD6: 60.99,
    FIELD7: 66.66
  },
  13.2: {
    FIELD1: 34.9,
    FIELD2: 37.04,
    FIELD3: 41.15,
    FIELD4: 46.71,
    FIELD5: 53.65,
    FIELD6: 61.53,
    FIELD7: 67.22
  },
  13.3: {
    FIELD1: 35.24,
    FIELD2: 37.4,
    FIELD3: 41.56,
    FIELD4: 47.16,
    FIELD5: 54.15,
    FIELD6: 62.06,
    FIELD7: 67.78
  },
  13.4: {
    FIELD1: 35.59,
    FIELD2: 37.77,
    FIELD3: 41.96,
    FIELD4: 47.61,
    FIELD5: 54.64,
    FIELD6: 62.6,
    FIELD7: 68.33
  },
  13.5: {
    FIELD1: 35.94,
    FIELD2: 38.14,
    FIELD3: 42.37,
    FIELD4: 48.06,
    FIELD5: 55.14,
    FIELD6: 63.13,
    FIELD7: 68.89
  },
  13.6: {
    FIELD1: 36.29,
    FIELD2: 38.52,
    FIELD3: 42.78,
    FIELD4: 48.51,
    FIELD5: 55.64,
    FIELD6: 63.67,
    FIELD7: 69.44
  },
  13.7: {
    FIELD1: 36.65,
    FIELD2: 38.89,
    FIELD3: 43.19,
    FIELD4: 48.96,
    FIELD5: 56.13,
    FIELD6: 64.2,
    FIELD7: 69.99
  },
  13.8: {
    FIELD1: 37.01,
    FIELD2: 39.27,
    FIELD3: 43.6,
    FIELD4: 49.42,
    FIELD5: 56.63,
    FIELD6: 64.73,
    FIELD7: 70.53
  },
  13.9: {
    FIELD1: 37.37,
    FIELD2: 39.65,
    FIELD3: 44.02,
    FIELD4: 49.87,
    FIELD5: 57.12,
    FIELD6: 65.25,
    FIELD7: 71.07
  },
  '13.10': {
    FIELD1: 37.74,
    FIELD2: 40.04,
    FIELD3: 44.43,
    FIELD4: 50.33,
    FIELD5: 57.61,
    FIELD6: 65.78,
    FIELD7: 71.61
  },
  13.11: {
    FIELD1: 38.1,
    FIELD2: 40.42,
    FIELD3: 44.85,
    FIELD4: 50.78,
    FIELD5: 58.1,
    FIELD6: 66.3,
    FIELD7: 72.15
  },
  '14.0': {
    FIELD1: 38.48,
    FIELD2: 40.81,
    FIELD3: 45.27,
    FIELD4: 51.23,
    FIELD5: 58.59,
    FIELD6: 66.82,
    FIELD7: 72.69
  },
  14.1: {
    FIELD1: 38.85,
    FIELD2: 41.2,
    FIELD3: 45.69,
    FIELD4: 51.68,
    FIELD5: 59.08,
    FIELD6: 67.33,
    FIELD7: 73.22
  },
  14.2: {
    FIELD1: 39.22,
    FIELD2: 41.59,
    FIELD3: 46.1,
    FIELD4: 52.13,
    FIELD5: 59.56,
    FIELD6: 67.85,
    FIELD7: 73.75
  },
  14.3: {
    FIELD1: 39.6,
    FIELD2: 41.98,
    FIELD3: 46.52,
    FIELD4: 52.58,
    FIELD5: 60.04,
    FIELD6: 68.36,
    FIELD7: 74.27
  },
  14.4: {
    FIELD1: 39.98,
    FIELD2: 42.37,
    FIELD3: 46.94,
    FIELD4: 53.03,
    FIELD5: 60.52,
    FIELD6: 68.86,
    FIELD7: 74.79
  },
  14.5: {
    FIELD1: 40.36,
    FIELD2: 42.77,
    FIELD3: 47.35,
    FIELD4: 53.47,
    FIELD5: 60.99,
    FIELD6: 69.36,
    FIELD7: 75.31
  },
  14.6: {
    FIELD1: 40.74,
    FIELD2: 43.16,
    FIELD3: 47.77,
    FIELD4: 53.91,
    FIELD5: 61.46,
    FIELD6: 69.86,
    FIELD7: 75.83
  },
  14.7: {
    FIELD1: 41.12,
    FIELD2: 43.55,
    FIELD3: 48.18,
    FIELD4: 54.35,
    FIELD5: 61.93,
    FIELD6: 70.35,
    FIELD7: 76.34
  },
  14.8: {
    FIELD1: 41.5,
    FIELD2: 43.94,
    FIELD3: 48.59,
    FIELD4: 54.79,
    FIELD5: 62.39,
    FIELD6: 70.84,
    FIELD7: 76.84
  },
  14.9: {
    FIELD1: 41.88,
    FIELD2: 44.33,
    FIELD3: 49,
    FIELD4: 55.22,
    FIELD5: 62.85,
    FIELD6: 71.33,
    FIELD7: 77.35
  },
  '14.10': {
    FIELD1: 42.26,
    FIELD2: 44.72,
    FIELD3: 49.41,
    FIELD4: 55.65,
    FIELD5: 63.3,
    FIELD6: 71.81,
    FIELD7: 77.84
  },
  14.11: {
    FIELD1: 42.64,
    FIELD2: 45.11,
    FIELD3: 49.81,
    FIELD4: 56.07,
    FIELD5: 63.75,
    FIELD6: 72.28,
    FIELD7: 78.34
  },
  '15.0': {
    FIELD1: 43.02,
    FIELD2: 45.5,
    FIELD3: 50.21,
    FIELD4: 56.49,
    FIELD5: 64.19,
    FIELD6: 72.75,
    FIELD7: 78.83
  },
  15.1: {
    FIELD1: 43.39,
    FIELD2: 45.88,
    FIELD3: 50.61,
    FIELD4: 56.91,
    FIELD5: 64.63,
    FIELD6: 73.22,
    FIELD7: 79.31
  },
  15.2: {
    FIELD1: 43.76,
    FIELD2: 46.26,
    FIELD3: 51,
    FIELD4: 57.32,
    FIELD5: 65.06,
    FIELD6: 73.67,
    FIELD7: 79.79
  },
  15.3: {
    FIELD1: 44.14,
    FIELD2: 46.64,
    FIELD3: 51.39,
    FIELD4: 57.72,
    FIELD5: 65.49,
    FIELD6: 74.13,
    FIELD7: 80.27
  },
  15.4: {
    FIELD1: 44.51,
    FIELD2: 47.01,
    FIELD3: 51.78,
    FIELD4: 58.12,
    FIELD5: 65.91,
    FIELD6: 74.57,
    FIELD7: 80.74
  },
  15.5: {
    FIELD1: 44.87,
    FIELD2: 47.38,
    FIELD3: 52.16,
    FIELD4: 58.51,
    FIELD5: 66.32,
    FIELD6: 75.02,
    FIELD7: 81.2
  },
  15.6: {
    FIELD1: 45.23,
    FIELD2: 47.75,
    FIELD3: 52.53,
    FIELD4: 58.9,
    FIELD5: 66.73,
    FIELD6: 75.45,
    FIELD7: 81.66
  },
  15.7: {
    FIELD1: 45.59,
    FIELD2: 48.11,
    FIELD3: 52.9,
    FIELD4: 59.28,
    FIELD5: 67.13,
    FIELD6: 75.88,
    FIELD7: 82.11
  },
  15.8: {
    FIELD1: 45.95,
    FIELD2: 48.47,
    FIELD3: 53.27,
    FIELD4: 59.66,
    FIELD5: 67.53,
    FIELD6: 76.3,
    FIELD7: 82.56
  },
  15.9: {
    FIELD1: 46.3,
    FIELD2: 48.82,
    FIELD3: 53.63,
    FIELD4: 60.03,
    FIELD5: 67.91,
    FIELD6: 76.72,
    FIELD7: 83
  },
  '15.10': {
    FIELD1: 46.64,
    FIELD2: 49.17,
    FIELD3: 53.98,
    FIELD4: 60.39,
    FIELD5: 68.29,
    FIELD6: 77.13,
    FIELD7: 83.44
  },
  15.11: {
    FIELD1: 46.98,
    FIELD2: 49.51,
    FIELD3: 54.33,
    FIELD4: 60.75,
    FIELD5: 68.66,
    FIELD6: 77.53,
    FIELD7: 83.87
  },
  '16.0': {
    FIELD1: 47.32,
    FIELD2: 49.85,
    FIELD3: 54.67,
    FIELD4: 61.1,
    FIELD5: 69.03,
    FIELD6: 77.92,
    FIELD7: 84.29
  },
  16.1: {
    FIELD1: 47.64,
    FIELD2: 50.18,
    FIELD3: 55,
    FIELD4: 61.44,
    FIELD5: 69.39,
    FIELD6: 78.31,
    FIELD7: 84.71
  },
  16.2: {
    FIELD1: 47.97,
    FIELD2: 50.5,
    FIELD3: 55.32,
    FIELD4: 61.77,
    FIELD5: 69.74,
    FIELD6: 78.69,
    FIELD7: 85.12
  },
  16.3: {
    FIELD1: 48.28,
    FIELD2: 50.82,
    FIELD3: 55.64,
    FIELD4: 62.1,
    FIELD5: 70.08,
    FIELD6: 79.06,
    FIELD7: 85.52
  },
  16.4: {
    FIELD1: 48.59,
    FIELD2: 51.13,
    FIELD3: 55.96,
    FIELD4: 62.42,
    FIELD5: 70.42,
    FIELD6: 79.43,
    FIELD7: 85.92
  },
  16.5: {
    FIELD1: 48.9,
    FIELD2: 51.43,
    FIELD3: 56.26,
    FIELD4: 62.73,
    FIELD5: 70.74,
    FIELD6: 79.79,
    FIELD7: 86.3
  },
  16.6: {
    FIELD1: 49.19,
    FIELD2: 51.73,
    FIELD3: 56.56,
    FIELD4: 63.03,
    FIELD5: 71.06,
    FIELD6: 80.13,
    FIELD7: 86.68
  },
  16.7: {
    FIELD1: 49.48,
    FIELD2: 52.02,
    FIELD3: 56.85,
    FIELD4: 63.33,
    FIELD5: 71.38,
    FIELD6: 80.48,
    FIELD7: 87.06
  },
  16.8: {
    FIELD1: 49.76,
    FIELD2: 52.3,
    FIELD3: 57.13,
    FIELD4: 63.62,
    FIELD5: 71.68,
    FIELD6: 80.81,
    FIELD7: 87.42
  },
  16.9: {
    FIELD1: 50.04,
    FIELD2: 52.57,
    FIELD3: 57.41,
    FIELD4: 63.9,
    FIELD5: 71.98,
    FIELD6: 81.14,
    FIELD7: 87.78
  },
  '16.10': {
    FIELD1: 50.3,
    FIELD2: 52.84,
    FIELD3: 57.68,
    FIELD4: 64.17,
    FIELD5: 72.27,
    FIELD6: 81.46,
    FIELD7: 88.13
  },
  16.11: {
    FIELD1: 50.56,
    FIELD2: 53.09,
    FIELD3: 57.94,
    FIELD4: 64.44,
    FIELD5: 72.55,
    FIELD6: 81.77,
    FIELD7: 88.46
  },
  '17.0': {
    FIELD1: 50.81,
    FIELD2: 53.35,
    FIELD3: 58.19,
    FIELD4: 64.7,
    FIELD5: 72.82,
    FIELD6: 82.07,
    FIELD7: 88.8
  },
  17.1: {
    FIELD1: 51.05,
    FIELD2: 53.59,
    FIELD3: 58.43,
    FIELD4: 64.95,
    FIELD5: 73.09,
    FIELD6: 82.36,
    FIELD7: 89.12
  },
  17.2: {
    FIELD1: 51.29,
    FIELD2: 53.82,
    FIELD3: 58.67,
    FIELD4: 65.2,
    FIELD5: 73.35,
    FIELD6: 82.65,
    FIELD7: 89.43
  },
  17.3: {
    FIELD1: 51.51,
    FIELD2: 54.05,
    FIELD3: 58.9,
    FIELD4: 65.43,
    FIELD5: 73.6,
    FIELD6: 82.93,
    FIELD7: 89.73
  },
  17.4: {
    FIELD1: 51.73,
    FIELD2: 54.27,
    FIELD3: 59.13,
    FIELD4: 65.67,
    FIELD5: 73.85,
    FIELD6: 83.2,
    FIELD7: 90.03
  },
  17.5: {
    FIELD1: 51.94,
    FIELD2: 54.49,
    FIELD3: 59.34,
    FIELD4: 65.89,
    FIELD5: 74.09,
    FIELD6: 83.46,
    FIELD7: 90.31
  },
  17.6: {
    FIELD1: 52.15,
    FIELD2: 54.69,
    FIELD3: 59.55,
    FIELD4: 66.11,
    FIELD5: 74.32,
    FIELD6: 83.71,
    FIELD7: 90.59
  },
  17.7: {
    FIELD1: 52.35,
    FIELD2: 54.89,
    FIELD3: 59.76,
    FIELD4: 66.32,
    FIELD5: 74.54,
    FIELD6: 83.96,
    FIELD7: 90.86
  },
  17.8: {
    FIELD1: 52.54,
    FIELD2: 55.08,
    FIELD3: 59.96,
    FIELD4: 66.52,
    FIELD5: 74.76,
    FIELD6: 84.2,
    FIELD7: 91.11
  },
  17.9: {
    FIELD1: 52.72,
    FIELD2: 55.27,
    FIELD3: 60.15,
    FIELD4: 66.72,
    FIELD5: 74.97,
    FIELD6: 84.43,
    FIELD7: 91.36
  },
  '17.10': {
    FIELD1: 52.89,
    FIELD2: 55.45,
    FIELD3: 60.33,
    FIELD4: 66.92,
    FIELD5: 75.18,
    FIELD6: 84.65,
    FIELD7: 91.6
  },
  17.11: {
    FIELD1: 53.06,
    FIELD2: 55.62,
    FIELD3: 60.51,
    FIELD4: 67.11,
    FIELD5: 75.38,
    FIELD6: 84.87,
    FIELD7: 91.83
  },
  '18.0': {
    FIELD1: 53.23,
    FIELD2: 55.79,
    FIELD3: 60.69,
    FIELD4: 67.29,
    FIELD5: 75.58,
    FIELD6: 85.08,
    FIELD7: 92.05
  },
  18.1: {
    FIELD1: 53.38,
    FIELD2: 55.95,
    FIELD3: 60.85,
    FIELD4: 67.47,
    FIELD5: 75.76,
    FIELD6: 85.28,
    FIELD7: 92.26
  },
  18.2: {
    FIELD1: 53.54,
    FIELD2: 56.11,
    FIELD3: 61.02,
    FIELD4: 67.64,
    FIELD5: 75.95,
    FIELD6: 85.47,
    FIELD7: 92.46
  },
  18.3: {
    FIELD1: 53.68,
    FIELD2: 56.26,
    FIELD3: 61.18,
    FIELD4: 67.81,
    FIELD5: 76.13,
    FIELD6: 85.66,
    FIELD7: 92.65
  },
  18.4: {
    FIELD1: 53.82,
    FIELD2: 56.4,
    FIELD3: 61.33,
    FIELD4: 67.98,
    FIELD5: 76.31,
    FIELD6: 85.84,
    FIELD7: 92.84
  },
  18.5: {
    FIELD1: 53.96,
    FIELD2: 56.54,
    FIELD3: 61.49,
    FIELD4: 68.14,
    FIELD5: 76.48,
    FIELD6: 86.02,
    FIELD7: 93.01
  },
  18.6: {
    FIELD1: 54.09,
    FIELD2: 56.68,
    FIELD3: 61.63,
    FIELD4: 68.3,
    FIELD5: 76.65,
    FIELD6: 86.19,
    FIELD7: 93.18
  },
  18.7: {
    FIELD1: 54.22,
    FIELD2: 56.82,
    FIELD3: 61.78,
    FIELD4: 68.46,
    FIELD5: 76.81,
    FIELD6: 86.36,
    FIELD7: 93.34
  },
  18.8: {
    FIELD1: 54.34,
    FIELD2: 56.95,
    FIELD3: 61.92,
    FIELD4: 68.61,
    FIELD5: 76.97,
    FIELD6: 86.52,
    FIELD7: 93.49
  },
  18.9: {
    FIELD1: 54.46,
    FIELD2: 57.07,
    FIELD3: 62.06,
    FIELD4: 68.76,
    FIELD5: 77.13,
    FIELD6: 86.67,
    FIELD7: 93.64
  },
  '18.10': {
    FIELD1: 54.58,
    FIELD2: 57.2,
    FIELD3: 62.19,
    FIELD4: 68.91,
    FIELD5: 77.28,
    FIELD6: 86.83,
    FIELD7: 93.78
  },
  18.11: {
    FIELD1: 54.69,
    FIELD2: 57.32,
    FIELD3: 62.33,
    FIELD4: 69.05,
    FIELD5: 77.44,
    FIELD6: 86.97,
    FIELD7: 93.92
  },
  '19.0': {
    FIELD1: 54.8,
    FIELD2: 57.43,
    FIELD3: 62.46,
    FIELD4: 69.19,
    FIELD5: 77.59,
    FIELD6: 87.12,
    FIELD7: 94.05
  },
  19.1: {
    FIELD1: 54.91,
    FIELD2: 57.55,
    FIELD3: 62.58,
    FIELD4: 69.34,
    FIELD5: 77.73,
    FIELD6: 87.26,
    FIELD7: 94.18
  },
  19.2: {
    FIELD1: 55.01,
    FIELD2: 57.66,
    FIELD3: 62.71,
    FIELD4: 69.47,
    FIELD5: 77.88,
    FIELD6: 87.4,
    FIELD7: 94.31
  },
  19.3: {
    FIELD1: 55.1,
    FIELD2: 57.76,
    FIELD3: 62.83,
    FIELD4: 69.61,
    FIELD5: 78.02,
    FIELD6: 87.54,
    FIELD7: 94.44
  },
  19.4: {
    FIELD1: 55.2,
    FIELD2: 57.86,
    FIELD3: 62.95,
    FIELD4: 69.74,
    FIELD5: 78.17,
    FIELD6: 87.68,
    FIELD7: 94.57
  },
  19.5: {
    FIELD1: 55.28,
    FIELD2: 57.96,
    FIELD3: 63.06,
    FIELD4: 69.87,
    FIELD5: 78.31,
    FIELD6: 87.82,
    FIELD7: 94.7
  },
  19.6: {
    FIELD1: 55.36,
    FIELD2: 58.05,
    FIELD3: 63.17,
    FIELD4: 70,
    FIELD5: 78.45,
    FIELD6: 87.97,
    FIELD7: 94.83
  },
  19.7: {
    FIELD1: 55.44,
    FIELD2: 58.14,
    FIELD3: 63.27,
    FIELD4: 70.12,
    FIELD5: 78.59,
    FIELD6: 88.11,
    FIELD7: 94.97
  },
  19.8: {
    FIELD1: 55.51,
    FIELD2: 58.22,
    FIELD3: 63.37,
    FIELD4: 70.24,
    FIELD5: 78.72,
    FIELD6: 88.26,
    FIELD7: 95.11
  },
  19.9: {
    FIELD1: 55.56,
    FIELD2: 58.29,
    FIELD3: 63.46,
    FIELD4: 70.35,
    FIELD5: 78.86,
    FIELD6: 88.41,
    FIELD7: 95.27
  },
  '19.10': {
    FIELD1: 55.61,
    FIELD2: 58.35,
    FIELD3: 63.54,
    FIELD4: 70.46,
    FIELD5: 78.99,
    FIELD6: 88.56,
    FIELD7: 95.44
  },
  19.11: {
    FIELD1: 55.65,
    FIELD2: 58.39,
    FIELD3: 63.61,
    FIELD4: 70.55,
    FIELD5: 79.12,
    FIELD6: 88.72,
    FIELD7: 95.62
  },
  '20.0': {
    FIELD1: 55.66,
    FIELD2: 58.41,
    FIELD3: 63.64,
    FIELD4: 70.6,
    FIELD5: 79.18,
    FIELD6: 88.81,
    FIELD7: 95.71
  }
};

export const getWeightCalculation = (
  cal_age: any,
  gender: any,
  value: any
): any => {
  try {
    if (cal_age <= 20.0) {
      let calculation: any = '';
      if (gender === GenderTypes.MALE) {
        calculation = BoyWeight?.[cal_age];
      } else {
        calculation = GirlWeight?.[cal_age];
      }
      if (calculation) {
        if (value >= calculation.FIELD3 && value <= calculation.FIELD5) {
          return 1;
        } else if (
          (value >= calculation.FIELD2 && value < calculation.FIELD3) ||
          (value > calculation.FIELD5 && value <= calculation.FIELD6)
        ) {
          return 0.5;
        } else if (value < calculation.FIELD2 || value > calculation.FIELD6) {
          return 0;
        }
      }
    } else {
      if (value >= 63.64 && value <= 79.18) {
        return 1;
      } else if (
        (value >= 58.41 && value < 63.64) ||
        (value > 79.18 && value <= 88.81)
      ) {
        return 0.5;
      } else if (value < 58.41 || value > 88.81) {
        return 0;
      }
    }
    return null;
  } catch (e) {
    throw new Error((e as any)?.message);
  }
};
