import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as DropDownBigIconSvg} from '@assets/images/healpha/DownFilled.svg';

const DropDownBigIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={DropDownBigIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default DropDownBigIcon;
