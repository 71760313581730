import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as GenderIconSvg} from '@assets/images/healpha/Gender.svg';

const GenderIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={GenderIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default GenderIcon;
