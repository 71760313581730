/* eslint-disable camelcase */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const PersonCheckedInDialog = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(props?.open);

  const handleClose = (): void => {
    setOpen(false);
    props?.closePopup(props?.selectedQrAppointId);
  };

  const renderHeader = () => {
    return (
      <Typography className="class_heading">
        {t('YOU_HAVE_CHECKED_IN_WAIT_FOR_YOUR_TURN')}
      </Typography>
    );
  };

  const renderActionButtons = () => {
    return (
      <Grid className="ActionsBtn">
        <Button onClick={handleClose} className="can-btn">
          {t('OK')}
        </Button>
      </Grid>
    );
  };

  return (
    <Dialog
      className="main-wrapper"
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '400px',
            maxHeight: '400px'
          }
        }
      }}>
      <DialogTitle>{renderHeader()}</DialogTitle>
      <DialogActions>{renderActionButtons()}</DialogActions>
    </Dialog>
  );
};

export default PersonCheckedInDialog;
