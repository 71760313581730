import React from 'react';
import {Grid, Typography, Avatar} from '@material-ui/core';

import './DialogLeftSideIllustrationsComponent.scss';

const DialogLeftSideIllustrationsComponent = (
  props: any
): React.ReactElement => {
  return (
    <Grid className="left-side-illustration-wrapper" item container spacing={2}>
      <Grid item xs={12}>
        <Typography className="left-side-illustration-title">
          {props?.title}
        </Typography>
      </Grid>

      <Grid
        item
        container
        direction="column"
        spacing={2}
        xs={12}
        style={{marginTop: props?.isFromReg ? '-6rem' : '-2rem'}}>
        {props?.specialtiesList.map((ele: any, index: any) => {
          return (
            <Grid
              container
              item
              key={index}
              style={{margin: '0.5rem 0'}}
              alignItems="center">
              <Grid item xs={2}>
                <Avatar variant="rounded" className="avatar-styles">
                  <ele.icon />
                </Avatar>
              </Grid>

              <Grid item xs={10}>
                <Typography className="left-side-speciality-text">
                  {ele?.text}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      <Grid item xs={12} container alignItems="flex-end">
        <Typography className="left-side-illustration-footer-text">
          {props?.footerText}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DialogLeftSideIllustrationsComponent;
