/* eslint-disable camelcase */
import React, {useState} from 'react';
import {
  Box,
  Grid,
  FormControl,
  // InputLabel,
  // OutlinedInput,
  TextField,
  IconButton,
  FormLabel,
  Button,
  // Stack,
  InputAdornment,
  // Icon,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
// import clsx from 'clsx';
import CloseBlackIcon from '@components/system/MyIcons/CloseBlackIcon';
import {actionType, StepsCommonProps} from '../Appointment';
import './AppointmentSteps.scss';
import {
  LocalizationProvider,
  MobileDatePicker,
  DesktopDatePicker
  // DatePicker
} from '@material-ui/lab';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import Icons from '@components/system/MyIcons/index';
import {environment} from '@shared/environment/environment';
import {every, isEmpty} from 'lodash';
import {sendOtpToUser} from '@shared/services/PublicService';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles({
  container: {
    flexWrap: 'nowrap',
    flexDirection: 'column'
  },
  header: {},
  headerTitle: {
    display: 'flex',
    flexDirection: 'column'
  }
});

type FormOneState = {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  dateOfBirth: string;
  gender: string;
  email: string;
  phoneCode: string;
};

function StepOne({data, dispatch}: StepsCommonProps): React.ReactElement {
  const styles = useStyles();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [formOneState, setFormOneState] = useState<FormOneState>({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    dateOfBirth: '',
    gender: '',
    email: '',
    phoneCode: environment.dialCode
  });
  const setFormValues = (key: keyof typeof formOneState, value: any): void => {
    setFormOneState((state: any) => ({
      ...state,
      [key]: value
    }));
  };
  const isValidForm = (): boolean => {
    return every(formOneState, (value, _) => !isEmpty(value));
  };
  const onSubmitHandler = (e: any): void => {
    e.preventDefault();
    const otpData = {
      phone_code: formOneState.phoneCode,
      phone_number: formOneState.mobileNumber
    };
    if (isValidForm()) {
      setLoading(true);
      sendOtpToUser(otpData)
        .then(res => {
          if (res?.status_code === 200) {
            setLoading(false);
            dispatch({
              type: actionType.NEXT_STEP,
              payload: {...data, formOne: formOneState}
            });
          } else {
            throw new Error(res?.message);
          }
        })
        .catch(err => {
          setLoading(false);
        });
    }
  };
  return (
    <form onSubmit={onSubmitHandler}>
      <Grid container p={1} className={styles.container}>
        {/* <Box className={clsx()}> */}
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          className={styles.header}>
          <Grid item>
            <Box className={styles.headerTitle}>
              <Box fontWeight="bold">{t('CLINIC_ADD_PATIENT')}</Box>
              <Box>{t('CLINIC_PATIENT_DETAILS')}</Box>
            </Box>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() =>
                dispatch({type: actionType.CLOSE_MODAL, payload: null})
              }>
              <CloseBlackIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item sx={{justifyContent: 'space-between'}}>
            <FormControl className="outlined-textfield" variant="standard">
              <FormLabel htmlFor="step-one-first-name">First Name</FormLabel>
              <TextField
                id="step-one-first-name"
                color="secondary"
                size="small"
                sx={{
                  border: '1px solid lightgrey',
                  borderRadius: '2px',
                  p: 2
                }}
                value={formOneState.firstName}
                onChange={event =>
                  setFormValues('firstName', event.target.value)
                }
                required
              />
            </FormControl>
            <FormControl className="outlined-textfield" variant="standard">
              <FormLabel htmlFor="step-one-last-name">Last Name</FormLabel>
              <TextField
                id="step-one-last-name"
                color="secondary"
                size="small"
                sx={{
                  border: '1px solid lightgrey',
                  borderRadius: '2px',
                  p: 2
                }}
                value={formOneState.lastName}
                onChange={event =>
                  setFormValues('lastName', event.target.value)
                }
                required
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item sx={{justifyContent: 'space-between'}}>
            <FormControl className="outlined-textfield" variant="standard">
              <FormLabel htmlFor="step-one-mobile-number">
                Mobile Number
              </FormLabel>
              <TextField
                id="step-one-mobile-number"
                color="secondary"
                size="small"
                sx={{
                  border: '1px solid lightgrey',
                  borderRadius: '2px',
                  p: 2
                }}
                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                value={formOneState.mobileNumber}
                onChange={event =>
                  setFormValues('mobileNumber', event.target.value)
                }
                required
              />
            </FormControl>
            <FormControl className="outlined-textfield" variant="standard">
              <FormLabel htmlFor="step-one-dob">Date of Birth</FormLabel>
              <LocalizationProvider dateAdapter={MomentAdapter}>
                {/* <Stack spacing={3}> */}
                <Grid sx={{display: {xs: 'block', lg: 'none'}}}>
                  <MobileDatePicker
                    value={formOneState.dateOfBirth}
                    onChange={newValue => {
                      setFormValues('dateOfBirth', newValue);
                    }}
                    openPickerIcon={<Icons.DOBIcon />}
                    InputAdornmentProps={{position: 'start'}}
                    renderInput={params => (
                      <TextField
                        id="step-one-dob"
                        color="secondary"
                        placeholder="Select Date"
                        size="small"
                        sx={{
                          border: '1px solid lightgrey',
                          borderRadius: '2px',
                          p: 2
                        }}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icons.DOBIcon />
                            </InputAdornment>
                          )
                        }}
                        {...params}
                        required
                      />
                    )}
                    // renderInput={({ inputRef, inputProps, InputProps }) => (
                    //   <TextField
                    //     id="step-one-dob"
                    //     color="secondary"
                    //     sx={{
                    //       border: '1px solid lightgrey',
                    //       borderRadius: '2px',
                    //       p: 2
                    //     }}
                    //     variant='outlined'
                    //     ref={inputRef}
                    //     onChange={inputProps?.onChange}
                    //     type={inputProps?.typeof}
                    //     InputProps={{
                    //       // ...inputProps,
                    //       ...InputProps,
                    //       startAdornment: <InputAdornment position="start"><Icons.DOBIcon /></InputAdornment>,
                    //     }}
                    //     // required
                    //   />
                    // )}
                  />
                </Grid>
                <Grid sx={{display: {xs: 'none', lg: 'block'}}}>
                  <DesktopDatePicker
                    value={formOneState.dateOfBirth}
                    onChange={newValue => {
                      setFormValues('dateOfBirth', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        id="step-one-dob"
                        color="secondary"
                        size="small"
                        sx={{
                          border: '1px solid lightgrey',
                          borderRadius: '2px',
                          p: 2
                        }}
                        {...params}
                        required
                      />
                    )}
                  />
                </Grid>
                {/* <DatePicker
                    disableFuture
                    label="Responsive"
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value=""
                    onChange={(newValue) => {
                      // setValue(newValue);
                    }}
                    renderInput={params => <TextField {...params} />}
                  /> */}
                {/* </Stack> */}
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item sx={{justifyContent: 'space-between'}}>
            <FormControl>
              <FormLabel id="step-one-gender" htmlFor="step-one-gender">
                Gender
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="step-one-gender"
                name="row-radio-buttons-group"
                value={formOneState.gender}
                onChange={event => setFormValues('gender', event.target.value)}>
                <FormControlLabel
                  value="female"
                  control={<Radio required />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio required />}
                  label="Male"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio required />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          mb={1}>
          <Grid item sx={{justifyContent: 'space-between'}}>
            <FormControl className="outlined-textfield" variant="standard">
              <FormLabel htmlFor="step-one-email">Email(Optional)</FormLabel>
              <TextField
                id="step-one-email"
                type="email"
                color="secondary"
                size="small"
                sx={{
                  border: '1px solid lightgrey',
                  borderRadius: '2px',
                  p: 2
                }}
                value={formOneState.email}
                onChange={event => setFormValues('email', event.target.value)}
                required
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" alignItems="center">
          <Grid item sx={{justifyContent: 'center', width: '100%'}}>
            <Button
              // [TODO] make it submit??
              fullWidth
              variant="contained"
              disabled={loading || !isValidForm()}
              type="submit"
              // onClick={() =>
              //   dispatch({type: actionType.NEXT_STEP, payload: null})
              // }
            >
              Save Patient Detail
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default StepOne;
