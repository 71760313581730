import React, {useState} from 'react';
import {Grid} from '@material-ui/core';

import CalendarBuild from './CalendarBuild';
import Icons from '@components/system/MyIcons';
import './CalendarNew.scss';

const CalendarNew = (props: any): React.ReactElement => {
  const [calendar, setCalendar] = useState([]);
  const {value, setValue, getTheSelectedDate} = props;

  const startDay = value.clone().startOf('week');
  const endDay = value.clone().endOf('week');

  const init = () => {
    setCalendar(CalendarBuild(value, startDay, endDay));
    getTheSelectedDate(value.format('YYYY-MM-DD'));
  };

  React.useEffect(init, [value]);

  const handleNewDate = () => {
    const nextDate = value.clone().add(7, 'days').startOf('week');
    return nextDate;
  };

  const handlePrevDate = () => {
    const nextDate = value.clone().add(-7, 'days').startOf('week');
    return nextDate;
  };

  const renderArrowIcon = () => {
    return (
      <Grid item className="change-arrow">
        <Icons.BackArrowIconBlue
          onClick={() => setValue(handlePrevDate)}
          id={'back_arrrow'}
        />
        {startDay.format('MMMM DD')} - {endDay.format('DD, YYYY')}
        <Icons.NextArrowIcon
          onClick={() => setValue(handleNewDate)}
          id={'forward_arrow'}
        />
      </Grid>
    );
  };

  const renderCalendarDays = () => {
    return (
      <Grid item xs={12} container direction="column">
        {calendar.map((week: any, index: any) => (
          <Grid
            item
            container
            xs={1}
            key={index}
            justifyContent="space-between">
            {week.map((day: any, indexNew: any) => (
              <Grid
                item
                key={indexNew}
                onClick={() => setValue(day)}
                className="day-format-styles">
                {day.format('ddd')}
                <Grid
                  item
                  id={'date_' + day.format('D')}
                  className={
                    value.isSame(day, 'day') ? 'selected' : 'not-selected'
                  }>
                  {day.format('D')}
                </Grid>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Grid container className="calendar-card-wrapper">
      <Grid item container>
        {renderArrowIcon()}
      </Grid>
      {renderCalendarDays()}
    </Grid>
  );
};

export default CalendarNew;
