/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import {
  verifyOtp,
  sendOtpToUser,
  signUpPerson,
  loginPerson,
  createForumsQuestion,
  temporaryPersonSignUp,
  createForumsReply
} from '@shared/services/PublicService';
import {
  linkPersonToVirtualClinic,
  linkPersonsToBranches
} from '@shared/services/CommonService';
import InitSharedRepo from '../../../../../../shared';
import {_snackbarAcknowledgement} from '../../../../../../shared/src/store/actions/CommonActions';
import {environment} from '@shared/environment/environment';

import Loader from '@components/framework/loader/Loader';
import CommonUtils from 'src/utils/CommonUtils';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import CommonDialogTitleComponent from '../CommonDialogTitle/CommonDialogTitleComponent';
import SecondaryCommonDialogTitleComponent from '../SecondaryCommonDialogTitle/SecondaryCommonDialogTitleComponent';
import ReviewUserAppointmentComponent from '../ReviewUserAppointment/ReviewUserAppointmentComponent';
import AppointmentConfirmationComponent from '../AppointmentConfirmation/AppointmentConfirmationComponent';
import {UserTypes, NotificationTypes} from 'src/constants/SystemConstants';
import './OtpScreenComponent.scss';

const OtpScreenComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = React.useState<boolean>(props?.open);
  const [openReviewPopup, setOpenReviewPopup] = React.useState<boolean>(false);
  const [
    openAppointmentConfirmationPopup,
    setOpenAppointmentConfirmationPopup
  ] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [values, setValues]: any = React.useState({
    otp: '',
    user: ''
  });
  const propValues = props?.values;
  const askQuesData = props?.askQuesData;
  const repliesData = props?.repliesData;

  const handleClose = (event?: any, reason?: any) => {
    setOpen(false);
    props?.closeOtpPopup();
  };

  const closeReviewPopUp = () => {
    setOpenReviewPopup(false);
    handleClose();
  };

  const closeAppointmentConfirmationPopup = () => {
    setOpenAppointmentConfirmationPopup(false);
    handleClose();
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const performSignUpOperations = (): void => {
    props.values.dob = CommonUtils.getDobFormat(props?.values?.dob);
    if (props?.isFromClinicalDashboard) {
      props.values.password =
        props?.values?.first_name.slice(0, 4) +
        '@' +
        CommonUtils.getDDMMFormat(props?.values?.dob);
    }
    signUpPerson(props?.values)
      .then(res => {
        if (
          props?.isFromVirtualClinic ||
          props?.isFromClinicalDashboard ||
          props?.isFromStandAloneDashboard
        ) {
          setOpen(false);
          setOpenAppointmentConfirmationPopup(true);
          setValues({...values, ['user']: res.data.hlpid});
        } else {
          props._snackbarAcknowledgement(
            true,
            res?.message,
            NotificationTypes.success
          );

          if (res?.data) {
            setValues({...values, ['user']: res.data.hlpid});

            InitSharedRepo.phpUrlInit();
            // const dataObject = {
            //   email: props.values?.email,
            //   phone_no: props?.values?.phone_number,
            //   hlpid: res.data.hlpid
            // };
            InitSharedRepo.init();

                const data = {
                  password: props?.values?.password,
                  healpha_id: res.data.hlpid
                };

                loginPerson(data)
                  .then(result => {
                    setIsLoading(false);
                    const user = result?.data?.user;

                    if (result?.data?.token) {
                      CommonUtils.PerformPostLoginActions(
                        result.data.token,
                        user?.name
                      );

                      if (user) {
                        CommonUtils.setUserInfo({
                          email: user?.email,
                          healpha_id: user?.healpha_id,
                          name: user?.name,
                          phone_code: user?.phone_code,
                          phone_no: user?.phone_no
                        });
                      }

                      if (props?.isFromBookAppointment) {
                        setOpen(false);
                        setOpenReviewPopup(true);
                      } else {
                        handleClose();
                      }
                    }
                  })
                  .catch(err => {
                    setIsLoading(false);
                    props._snackbarAcknowledgement(
                      true,
                      err.message ? err.message : t('ERROR_ENCOUNTERED'),
                      NotificationTypes.error
                    );
                  });

            // sendPersonCreateEmail(dataObject)
            //   .then(response => {
            //   })
            //   .catch(err => {
            //     InitSharedRepo.init();
            //     setIsLoading(false);
            //     props._snackbarAcknowledgement(
            //       true,
            //       err.message ? err.message : t('ERROR_ENCOUNTERED'),
            //       NotificationTypes.error
            //     );
            //   });
          }
        }
      })
      .catch(err => {
        setIsLoading(false);
        props._snackbarAcknowledgement(
          true,
          err.message ? err.message : t('ERROR_ENCOUNTERED'),
          NotificationTypes.error
        );
      });
  };

  const performForumOperations = (): void => {
    setIsLoading(true);
    propValues.person_type = 'forums';

    temporaryPersonSignUp(propValues)
      .then(resp => {
        if (resp?.data?.id) {
          CommonUtils.clearTemporaryUserId();
          CommonUtils.setTemporaryUserId(resp.data.id);

          if (props?.isFromQuestion) {
            askQuesData.user_id = resp.data.id;
            askQuesData.user_type = UserTypes.TEMPORARY_PERSON;

            createForumsQuestion(askQuesData)
              .then(res => {
                setIsLoading(false);
                setOpen(false);
                props._snackbarAcknowledgement(
                  true,
                  res?.message,
                  NotificationTypes.success
                );
                history.push(`/portal/home/dashboard`);
              })
              .catch(err => {
                setIsLoading(false);
                props._snackbarAcknowledgement(
                  true,
                  err.message ? err.message : t('ERROR_ENCOUNTERED'),
                  NotificationTypes.error
                );
              });
          } else if (props?.isFromReply) {
            repliesData.user_id = resp.data.id;
            repliesData.user_type = UserTypes.TEMPORARY_PERSON;

            createForumsReply(repliesData)
              .then(res => {
                props._snackbarAcknowledgement(
                  true,
                  res?.message,
                  NotificationTypes.success
                );
                setIsLoading(false);
                setOpen(false);
                history.push(`/portal/home/dashboard`);
              })
              .catch(err => {
                props._snackbarAcknowledgement(
                  true,
                  err.message ? err.message : t('ERROR_ENCOUNTERED'),
                  NotificationTypes.error
                );
              });
          }
        }
      })
      .catch(err => {
        setIsLoading(false);
        if (err.status_code === 400 && err?.message === t('PERSON_EXISTS')) {
          props._snackbarAcknowledgement(
            true,
            t('MOB_ALREADY_REGISTERED_QUESTION_TEXT'),
            NotificationTypes.warning
          );
          handleClose();
        } else {
          props._snackbarAcknowledgement(
            true,
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            NotificationTypes.error
          );
        }
      });
  };

  const handleSubmit = (): void => {
    const data = {
      code: values?.otp,
      phone_code: props?.values?.phone_code || environment.dialCode,
      phone_number: props?.values?.phone_no_full || props?.values?.phone_number
    };

    setIsLoading(true);
    verifyOtp(data)
      .then(res => {
        if (props?.isFromStandaloneBookings || props?.isFromClinicalBookings) {
          linkPersonsToBranches(props?.values)
            .then(result => {
              setOpen(false);
              setOpenAppointmentConfirmationPopup(true);
            })
            .catch(err => {
              setIsLoading(false);
              props._snackbarAcknowledgement(
                true,
                err.message ? err.message : t('ERROR_ENCOUNTERED'),
                NotificationTypes.error
              );
            });
        } else if (props?.isFromVirtualClinicBookings) {
          linkPersonToVirtualClinic(props?.values)
            .then(result => {
              setOpen(false);
              setOpenAppointmentConfirmationPopup(true);
            })
            .catch(err => {
              setIsLoading(false);
              props._snackbarAcknowledgement(
                true,
                err.message ? err.message : t('ERROR_ENCOUNTERED'),
                NotificationTypes.error
              );
            });
        } else if (
          props?.isFromTemporaryBookAppointment &&
          props?.isTemporaryAction
        ) {
          setIsLoading(false);
          setOpen(false);
          props.isTemporaryAction();
        } else if (
          props?.isFromRelation ||
          props?.isFromBookAppointment ||
          props?.isFromVirtualClinic ||
          props?.isFromClinicalDashboard ||
          props?.isFromStandAloneDashboard
        ) {
          performSignUpOperations();
        } else if (props?.isFromPost) {
          performForumOperations();
        }
      })
      .catch(err => {
        setIsLoading(false);
        props._snackbarAcknowledgement(
          true,
          err.message ? err.message : t('ERROR_ENCOUNTERED'),
          NotificationTypes.error
        );
      });
  };

  const resendOtp = (): void => {
    const data = {
      phone_code: props?.values?.phone_code,
      phone_number: props?.values?.phone_no_full || props?.values?.phone_number,
      email: props?.values?.email,
      first_name: props?.values?.first_name,
      last_name: props?.values?.last_name
    };

    setIsLoading(true);
    sendOtpToUser(data)
      .then(res => {
        setIsLoading(false);
        props._snackbarAcknowledgement(
          true,
          res?.message,
          NotificationTypes.success
        );
      })
      .catch(err => {
        setIsLoading(false);
        props._snackbarAcknowledgement(
          true,
          err.message ? err.message : t('ERROR_ENCOUNTERED'),
          NotificationTypes.error
        );
      });
  };

  const renderReviewPopup = (): React.ReactElement => {
    return (
      <ReviewUserAppointmentComponent
        open={openReviewPopup}
        closeReviewPopUp={closeReviewPopUp}
        appointmentValues={props?.appointmentValues}
        values={props?.values}
        healphaId={values?.user}
      />
    );
  };

  const renderAppointmentConfirmationPopup = (): React.ReactElement => {
    return (
      <AppointmentConfirmationComponent
        {...props}
        open={openAppointmentConfirmationPopup}
        closeAppointmentConfirmationPopup={closeAppointmentConfirmationPopup}
        values={props?.values}
        healphaId={values?.user || props?.values?.hlpid}
        isFromClinicalDashboard={
          props?.isFromClinicalDashboard || props?.isFromClinicalBookings
        }
        closePopup={props?.closePopup}
      />
    );
  };

  const renderTextField = (
    name: string,
    placeholder: string
  ): React.ReactElement => {
    return (
      <FormTextField
        placeholder={placeholder}
        name={name}
        value={values[name]}
        callback={handleChange(name)}
      />
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          {!props?.isFromRelation &&
            !props?.isFromVirtualClinic &&
            !props?.isFromClinicalDashboard && (
              <div className="information-text">
                {t('OTP_INFO', {mobile_number: props?.values?.phone_number})}
              </div>
            )}
        </Grid>
        <Grid item xs={12}>
          {renderTextField('otp', t('ENTER_OTP'))}
        </Grid>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Button
            id="submit_otp"
            variant="contained"
            size="medium"
            color="primary"
            className="submit-button"
            onClick={() => handleSubmit()}
            disabled={isLoading}>
            {isLoading ? <Loader size={28} /> : t('CONTINUE')}
          </Button>
        </Grid>

        <Grid
          xs
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <div className="normal-text">
            {t('STILL_NOT_RECEIVED_OTP')}{' '}
            <Link
              id="resend_otp"
              component="button"
              variant="body2"
              className="link-text"
              disabled={isLoading}
              onClick={resendOtp}>
              {t('RESEND_OTP')}
            </Link>
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="otp-wrapper">
      <Dialog
        className="otp-dialog"
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        open={open}
        aria-labelledby="otp-pop-up"
        aria-describedby="user-otp"
        classes={{paper: 'booking-appointment-popup'}}>
        <DialogTitle
          id="user-otp"
          className={
            props?.isFromPost ||
            props?.isFromRelation ||
            props?.isFromVirtualClinic ||
            props?.isFromClinicalDashboard ||
            props?.isFromStandAloneDashboard
              ? 'user-post-popup-title'
              : 'booking-appointment-popup-title'
          }>
          {props?.isFromPost ||
          props?.isFromRelation ||
          props?.isFromVirtualClinic ||
          props?.isFromClinicalDashboard ||
          props?.isFromStandAloneDashboard ? (
            <SecondaryCommonDialogTitleComponent
              title={
                (props?.isFromRelation ||
                  props?.isFromVirtualClinic ||
                  props?.isFromClinicalDashboard ||
                  props?.isFromStandAloneDashboard) &&
                t('OTP')
              }
              subTitle={
                (props?.isFromRelation ||
                  props?.isFromVirtualClinic ||
                  props?.isFromClinicalDashboard) &&
                t('ENTER_OTP_TEXT', {mob: props?.values?.phone_number})
              }
              close={handleClose}
            />
          ) : (
            <CommonDialogTitleComponent
              appointmentValues={props?.appointmentValues}
              close={handleClose}
            />
          )}
        </DialogTitle>

        <DialogContent className="booking-appointment-popup-content">
          {renderDialogContent()}
        </DialogContent>

        <DialogActions
          disableSpacing={true}
          className={'booking-appointment-popup-actions'}>
          {renderDialogActions()}
        </DialogActions>
      </Dialog>
      {openReviewPopup && renderReviewPopup()}
      {openAppointmentConfirmationPopup && renderAppointmentConfirmationPopup()}
    </div>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {_snackbarAcknowledgement})(
  OtpScreenComponent
);
