import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as DoubleForwardArrowIconSvg} from '@assets/images/healpha/DoubleForwardArrow.svg';

const DoubleForwardArrowIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={DoubleForwardArrowIconSvg}
      viewBox="0 0 6 7"
    />
  );
};

export default DoubleForwardArrowIcon;
