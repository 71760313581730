/* eslint-disable camelcase */
import React from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';

import Icons from '@components/system/MyIcons';
import ConfirmIcon from '@assets/images/healpha/ConfirmIcon.svg';

import './VaccineUpdateDialog.scss';

const VaccineUpdatedDialog = (props: any): React.ReactElement => {
  const [open, setOpen] = React.useState(props?.open);

  const handleClose = () => {
    setOpen(false);
    props?.closeVaccineDialog();
  };

  const renderVaccineUpdatedDialogTitle = (): React.ReactElement => {
    return (
      <Grid item container xs={12} className="provide-vaccine-title-wrapper">
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="end"
          className="close-icon">
          <IconButton onClick={handleClose}>
            <Icons.CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };
  const renderVaccineUpdatedDialogContent = (): React.ReactElement => {
    return (
      <Grid item container xs={12} className="vaccine-updated-content-wrapper">
        <Grid item className="vaccine-updated-tick-icon-grid">
          <img src={ConfirmIcon} alt="" className="icon-image" />
        </Grid>
        <Grid item xs={12} className="vaccine-data-updated-text-grid">
          <Typography className="vaccine-data-updated">
            Vaccine Data Updated
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="vaccine-updated-dialog-wrapper">
        <DialogTitle id="alert-dialog-title">
          {renderVaccineUpdatedDialogTitle()}
        </DialogTitle>
        <DialogContent>{renderVaccineUpdatedDialogContent()}</DialogContent>
      </Dialog>
    </div>
  );
};

export default VaccineUpdatedDialog;
