import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as PersonIconSvg} from '@assets/images/healpha/PersonIcon.svg';

const PersonIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={PersonIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default PersonIcon;
