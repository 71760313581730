import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as LogoutIconSvg} from '@assets/images/healpha/Logout.svg';

const LogoutIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={LogoutIconSvg}
      viewBox="0 0 18 18"
    />
  );
};

export default LogoutIcon;
