import React from 'react';
import BaseIcon from './BaseIcon';
import { ReactComponent as VideoVisitIconIconSvg } from '@assets/images/healpha/VideoVisit.svg';

const VideoVisitIcon = (props: any): React.ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest}
      className={className}
      component={VideoVisitIconIconSvg}
      viewBox="0 0 24 24" />
  );
};

export default VideoVisitIcon;