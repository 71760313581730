import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as CancelShareIconSvg} from '@assets/images/healpha/cancelShareBlueIcon.svg';

const CancelShareIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={CancelShareIconSvg}
      viewBox="0 0 35 35"
    />
  );
};

export default CancelShareIcon;
