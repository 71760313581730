export const baseEnvironment = {
  base: true,
  reqTimeout: 50000,
  rsaPublicKey:
    '-----BEGIN RSA PUBLIC KEY-----\n' +
    'MIICCgKCAgEA1bIQNE1HBorG7knKIQN0Spmq2qYW2AcYg1nNPlCZbiqmbh5Z1GYW\n' +
    'JAz3apKdNIQO9xxGQmNAe4WbNyNijR4BURmhsKHGrK/XHvgSFisoJyh80pkZapr1\n' +
    'tA5pSq1lPaMaamiFtRranuax+8X7ao+YzWzI4s073CmPmwJ10e/96TmX5iKnLB8j\n' +
    'Kw2T7YsAF7AkbKQGVNaGeR5UGnK/sTg35gxeLtwaQ4sWsvbLRSAsXP00Ygen4jtU\n' +
    'AmsWo4Lh40d4/09hgu0FYKY7safVW5l6DAp7HsH3MwD98/O5+hRJaCBn6Hnv6gMs\n' +
    '6nN0s5ZdeNkB6H4fgwlBMHlfNdn+M1nUs635cHM3GdD6d282DDdrYZCA/04WZvHa\n' +
    'pTWr8ycZW9X1cSjpYxfrfv8LxuKf+mHua/ccDrRJkGhJf+rnLDSazoj3MDRXyUzO\n' +
    '1C+xpLlySulTWlNe0lSmf3Ix1ISgK/m9H2l8NoMXpLg5mYSFP5DndPUHTZMady+C\n' +
    'WxXTYwZApUQ1tOyD5vi3I7WuJM/PdBMixEB7qtTRup4LTthuneBZZ9Myx/1m+IsU\n' +
    'ToKoWSmnrDsyO01rG4Fmy+YHZ/4lhYGbpQXXOvCZ9hphPi33RPCKg1OpeEBjj6SH\n' +
    'asYefwIuKr8zqujkY1tvXrkO9s5zgtjKcNGNzAxNSrsyOOupu1lLFTMCAwEAAQ==\n' +
    '-----END RSA PUBLIC KEY-----',
  mapsApiKey: 'AIzaSyD4vbV0gLfQe13618c4UY85LJxNCJzqbA0',
  apiEndPoints: {
    login: '/auth/login/person',
    auth: {
      azureSSOLogin: '/auth/azurelogin'
    },
    commonApi: {
      meApi: '/user/me',
      firebaseSaveApi: '/devices/firebase',
      menuDataApi: '/user/menu-items',
      personMenuDataApi: '/user/menu-items/person',
      branchesApi: '/user/non-healpha-user/branches'
    },
    public: {
      base: 'public',
      whiteLabelPrefix: 'white-label-prefix',
      content: 'content',
      byLanguage: 'by-language',
      validate: 'validate',
      getApp: 'get-app-link-message',
      top10Categories: 'top-10-categories',
      practice: {
        base: 'practice',
        branch: 'branch',
        all: 'all',
        search: 'search'
      },
      doctor: {
        base: 'doctor',
        all: 'all',
        search: 'search',
        appointment: 'appointment',
        types: 'types',
        availableTimings: 'available-timings'
      },
      specialization: {
        base: 'specialization',
        search: 'search'
      },
      forums: {
        base: 'forums',
        question: 'question',
        reply: 'reply',
        create: 'create',
        all: 'all'
      }
    },
    payment: {
      base: 'payment',
      capture: 'capture',
      appRedirection: 'app-redirection',
      practiceSubscription: 'practice-subscription',
      create: 'create',
      verify: 'verify',
      order: {
        base: 'order',
        create: 'create'
      }
    },
    multiLanguage: {
      base: 'multi-language',
      content: 'content'
    }
  },
  config: {
    hospitalListLimit: 20,
    doctorListLimit: 20,
    forumQuestionsLimit: 5,
    hospitalForumsQuestionsLimit: 4,
    textAreaFieldTextMinLength: 50,
    textAreaFieldTextMaxLength: 500,
    userListLimit: 20,
    accessControlLimit: 20,
    rolesLimit: 20
  },
  googleApis: {
    autoComplete:
      'https://maps.googleapis.com/maps/api/place/autocomplete/json',
    placeDetails: 'https://maps.googleapis.com/maps/api/place/details/json',
    latLngDetails: 'https://maps.googleapis.com/maps/api/geocode/json'
  },
  mobileAppVersion: '1.1',
  razorpayScriptUrl: 'https://checkout.razorpay.com/v1/checkout.js',
  patientAppLink: 'https://bit.ly/2t2nxZR',
  patientAppAppStoreLink: 'https://apple.co/3L86Uih'
};
