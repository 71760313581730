/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {Done} from '@material-ui/icons';

import {sendOtpToUser, getAllUsers} from '@shared/services/PublicService';
import {environment} from '@shared/environment/environment';

import Icons from '@components/system/MyIcons/index';
import CommonUtils from 'src/utils/CommonUtils';
import Loader from '@components/framework/loader/Loader';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import FormPasswordField from '@components/system/CustomFormField/FormPasswordField';
import FormCombinationField from '@components/system/CustomFormField/FormCombinationField';
import FormPhoneField from '@components/system/CustomFormField/FormPhoneField';
import FormDatePickerField from '@components/system/CustomFormField/FormDatePickerField';
import FormSelectField from '@components/system/CustomFormField/FormSelectField';
import SecondaryCommonDialogTitleComponent from '../SecondaryCommonDialogTitle/SecondaryCommonDialogTitleComponent';
import DialogLeftSideIllustrationsComponent from 'src/modules/shared/DialogLeftSideIllustrations/DialogLeftSideIllustrationsComponent';
import PersonLoginComponent from '../PersonLogin/PersonLoginComponent';
import RelationShipPopupComponent from '../RelationShipPopup/RelationShipPopupComponent';
import OtpScreenComponent from '../OtpScreen/OtpScreenComponent';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {
  genderOptionsList,
  salutationOptionsList
} from 'src/constants/FormFields';
import {
  DEFAULT_WHITE_COLOR,
  BORDER_TERTIARY_COLOR,
  GET_8_PIXEL_BOX
} from 'src/style/variable';
import './PersonRegistrationComponent.scss';
import {connect} from 'react-redux';

const textFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  border: `1px solid ${BORDER_TERTIARY_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.5),
  opacity: 1,
  fontSize: '1.2rem'
};

const dropDownProps = {
  height: '3.9rem',
  width: '100%',
  border: `1px solid ${BORDER_TERTIARY_COLOR}`
};

const PersonRegistrationComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(props?.open);
  const [openLoginPopup, setOpenLoginPopup] = React.useState(false);
  const [openRelationPopup, setOpenRelationPopup] = React.useState(false);
  const [openOtpPopup, setOpenOtpPopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [familyHeadObject, setFamilyHeadObject]: any = React.useState({});
  const [rulesObject, setRulesObject]: any = React.useState({});
  const [values, setValues]: any = React.useState({
    salutation: 'Mr',
    first_name: '',
    last_name: '',
    email: '',
    dob: null,
    gender: '',
    password: '',
    showPassword: false,
    phone_code: environment.dialCode,
    phone_number: '',
    relation: ''
  });

  const handleClose = () => {
    setOpen(false);
    props?.closePersonSignUpPopup();
  };

  const handleEscClose = (event: any, reason: string) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const handleChange = (prop: any) => (event: any) => {
    if (prop === 'first_name' || prop === 'last_name') {
      if (
        /^[a-zA-Z]+$/.test(event?.target?.value) ||
        event?.target?.value === ''
      ) {
        setValues({...values, [prop]: event.target.value});
      }
    } else {
      setValues({...values, [prop]: event.target.value});
    }

    if (prop === 'password') {
      setRulesObject(CommonUtils.getPasswordRules(event?.target?.value));
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleDobChange = (prop: any) => (newValue: any) => {
    setValues({...values, [prop]: newValue});
  };

  const showLoginPopup = (): any => {
    setOpenLoginPopup(true);
    setOpen(false);
  };

  const closeOtpPopup = (): void => {
    setOpenOtpPopup(false);
    handleClose();
  };

  const handleCountryChange = (
    value: any,
    country: any,
    e: any,
    formattedValue: any
  ): void => {
    const countryCode = formattedValue?.split(' ')?.[0];
    const phoneNumber = value?.substr(country?.dialCode?.length);

    if (phoneNumber?.length <= 10) {
      setValues({
        ...values,
        phone_code: countryCode,
        phone_number: phoneNumber
      });
    }
  };

  const handleSubmit = (): void => {
    if (
      !values?.first_name ||
      !values?.last_name ||
      !values?.phone_code ||
      !values?.phone_number ||
      !values?.dob ||
      !values?.gender ||
      !values?.password ||
      !values?.salutation
    ) {
      enqueueSnackbar(t('PERSON_DETAILS_REQ'), SnackBarConfig('w'));
      return;
    }

    if (
      !rulesObject.lengthRule ||
      !rulesObject.upperCaseRule ||
      !rulesObject.specialCharRule ||
      !rulesObject.numberRule
    ) {
      enqueueSnackbar(t('PWD_CRITERIA_TEXT'), SnackBarConfig('w'));
      return;
    }

    if (values?.phone_number?.length != environment.mobileNumberLength) {
      enqueueSnackbar(
        t('MOB_MAX_LENGTH_WARNING', {
          limit: environment.mobileNumberLength
        }),

        SnackBarConfig('w')
      );
      return;
    }

    if (values?.email && !CommonUtils.isEmailValid(values?.email)) {
      enqueueSnackbar(t('INVALID_EMAIL'), SnackBarConfig('w'));
      return;
    }

    const data = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      phone_code: values?.phone_code,
      phone_number: values?.phone_number,
      email: values?.email,
      dob: CommonUtils.getDobFormat(values?.dob),
      gender: values?.gender,
      password: values?.password,
      salutation: values?.salutation
    };

    setIsLoading(true);
    getAllUsers(values?.phone_number)
      .then(res => {
        if (res?.data?.persons?.length > 0) {
          setIsLoading(false);
          setOpen(false);
          setOpenRelationPopup(true);

          res.data.persons?.map((item: any) => {
            if (item?.hlpid == res?.data?.family_head_id) {
              setFamilyHeadObject(item);
            }
          });
        } else {
          sendOtpToUser(data)
            .then(result => {
              setIsLoading(false);
              setOpen(false);
              setOpenOtpPopup(true);
            })
            .catch(err => {
              setIsLoading(false);
              enqueueSnackbar(
                err?.message ? err.message : t('ERROR_ENCOUNTERED'),
                SnackBarConfig('e')
              );
            });
        }
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const renderLoginPopup = (): React.ReactElement => {
    return (
      <PersonLoginComponent
        open={openLoginPopup}
        closePersonLoginPopup={handleClose}
      />
    );
  };

  const renderRelationPopup = (): React.ReactElement => {
    return (
      <RelationShipPopupComponent
        open={openRelationPopup}
        closeRelationPopup={handleClose}
        values={values}
        familyHeadObject={familyHeadObject}
      />
    );
  };

  const renderOtpPopup = (): React.ReactElement => {
    return (
      <OtpScreenComponent
        open={openOtpPopup}
        values={values}
        closeOtpPopup={closeOtpPopup}
        isFromRelation={true}
      />
    );
  };

  const renderFormSection = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormCombinationField
            dropDownName={'salutation'}
            dropdownValue={values['salutation']}
            callback={handleChange('salutation')}
            dropDownOptions={salutationOptionsList}
            textFieldCallBack={handleChange('first_name')}
            textFieldName={'first_name'}
            placeholder={t('FIRST_NAME')}
            textFieldValue={values['first_name']}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            placeholder={t('LAST_NAME')}
            name="last_name"
            value={values['last_name']}
            callback={handleChange('last_name')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormPhoneField
            name="phone_number"
            placeholder={t('MOBILE_NUMBER')}
            callback={handleCountryChange}
            phoneNumber={
              (values?.phone_code || values?.phone_number) &&
              values?.phone_code + ' ' + values?.phone_number
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            placeholder={t('EMAIL')}
            name="email"
            value={values['email']}
            callback={handleChange('email')}
            adornmentPosition="start"
            Icon={Icons.MailIcon}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormDatePickerField
            name="dob"
            value={values['dob']}
            textFieldProps={textFieldProps}
            Icon={Icons.DOBIcon}
            callback={handleDobChange('dob')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormSelectField
            name="gender"
            placeholder={t('GENDER')}
            value={values['gender']}
            dropDownProps={dropDownProps}
            Icon={Icons.DropDownIcon}
            StartAdornmentIcon={Icons.GenderIcon}
            dropDownOptions={genderOptionsList}
            callback={handleChange('gender')}
          />
        </Grid>

        <Grid item xs={12}>
          <FormPasswordField
            name="password"
            placeholder={t('CREATE_PASSWORD')}
            adornmentPosition="start"
            Icon={Icons.PasswordIcon}
            callback={handleChange('password')}
            onClickCallBack={handleClickShowPassword}
            OnMousePDownCallBack={handleMouseDownPassword}
            values={values}
          />
        </Grid>
      </Grid>
    );
  };

  const renderPasswordInfoSection = (
    label: string,
    status: boolean
  ): React.ReactElement => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Done
            fontSize="small"
            classes={{fontSizeSmall: status ? 'success-icon' : 'done-icon'}}
          />
        </Grid>

        <Grid item xs={11}>
          <Typography className="password-info-text">{label}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <Grid>
        <Grid>{renderFormSection()}</Grid>
        <Grid style={{marginTop: '1rem'}}>
          {renderPasswordInfoSection(
            t('PASSWORD_LENGTH'),
            rulesObject.lengthRule
          )}
          {renderPasswordInfoSection(
            t('ONE_UPPERCASE'),
            rulesObject.upperCaseRule
          )}
          {renderPasswordInfoSection(t('ONE_NUMBER'), rulesObject.numberRule)}
          {renderPasswordInfoSection(
            t('ONE_SPECIAL_CHARACTER'),
            rulesObject.specialCharRule
          )}
        </Grid>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={1}>
        <Grid>
          <Typography className="permissions-text">
            {t('ACCOUNT_CREATE_INFO_TEXT')}
            <Link
              component="button"
              variant="body2"
              className="link-text tos-link-text"
              onClick={() =>
                CommonUtils.openNewTab(
                  props?.contactInfo?.termsAndConditionsUrl
                )
              }
              disabled={isLoading}>
              {t('TERMS_OF_SERVICE')}
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            style={{marginTop: '0.5rem'}}
            color="primary"
            className="submit-button"
            onClick={() => handleSubmit()}
            disabled={isLoading}>
            {isLoading ? <Loader size={28} /> : t('CREATE_ACCOUNT')}
          </Button>
        </Grid>

        <Grid
          xs
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Typography className="account-existence-text">
            {t('ACCOUNT_EXIST_TEXT')}
            <Link
              component="button"
              variant="body2"
              className="link-text"
              onClick={showLoginPopup}
              disabled={isLoading}>
              {t('LOGIN_HERE')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className="person-registration-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={(event: any, reason: string) => handleEscClose(event, reason)}
        aria-labelledby="person-registration-pop-up"
        aria-describedby="person-registration"
        classes={{paper: 'person-doctor-popup'}}>
        <Grid container spacing={1}>
          <Grid
            item
            lg={5}
            sx={{display: {xs: 'none', lg: 'block'}}}
            className="popup-left-section">
            <DialogLeftSideIllustrationsComponent
              title={t('PERSON_SIGN_UP_TITLE')}
              footerText={t('LOGIN_SIGN_UP_FOOTER_TEXT', {
                appName: `${t('APP_NAME')}`
              })}
              specialtiesList={CommonUtils.getLoginSignUpSpecialtiesList(t)}
              isFromReg={true}
            />
          </Grid>

          <Grid item lg={7} className="popup-right-section">
            <DialogTitle id="person-registration-title" className="">
              <SecondaryCommonDialogTitleComponent
                title={t('CREATE_ACCOUNT')}
                subTitle={t('APPOINTMENT_DETAILS_TEXT')}
                close={handleClose}
              />
            </DialogTitle>

            <DialogContent className="person-doctor-popup-content">
              {renderDialogContent()}
            </DialogContent>

            <DialogActions
              disableSpacing={true}
              className="person-doctor-popup-actions">
              {renderDialogActions()}
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      {openLoginPopup && renderLoginPopup()}
      {openRelationPopup && renderRelationPopup()}
      {openOtpPopup && renderOtpPopup()}
    </Grid>
  );
};

function mapStateToProps(state: any) {
  // Properties defined here (read from state) will be available as props in the component
  return {
    contactInfo: state.common.contactInfo
  };
}

export default connect(mapStateToProps, {})(PersonRegistrationComponent);
