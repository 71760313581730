/* eslint-disable camelcase */
import {GenderTypes} from 'src/constants/FormFields';

export const GirlBMI: any = {
  '0.0': {
    FIELD1: 11.5,
    FIELD2: 11.8,
    FIELD3: 12.5,
    FIELD4: 13.3,
    FIELD5: 14.2,
    FIELD6: 15,
    FIELD7: 15.5
  },
  0.1: {
    FIELD1: 12.4,
    FIELD2: 12.9,
    FIELD3: 13.6,
    FIELD4: 14.6,
    FIELD5: 15.5,
    FIELD6: 16.4,
    FIELD7: 17
  },
  0.2: {
    FIELD1: 13.5,
    FIELD2: 14,
    FIELD3: 14.8,
    FIELD4: 15.8,
    FIELD5: 16.8,
    FIELD6: 17.8,
    FIELD7: 18.4
  },
  0.3: {
    FIELD1: 14,
    FIELD2: 14.5,
    FIELD3: 15.4,
    FIELD4: 16.4,
    FIELD5: 17.4,
    FIELD6: 18.4,
    FIELD7: 19
  },
  0.4: {
    FIELD1: 14.3,
    FIELD2: 14.8,
    FIELD3: 15.7,
    FIELD4: 16.7,
    FIELD5: 17.7,
    FIELD6: 18.8,
    FIELD7: 19.4
  },
  0.5: {
    FIELD1: 14.5,
    FIELD2: 15,
    FIELD3: 15.8,
    FIELD4: 16.8,
    FIELD5: 17.9,
    FIELD6: 18.9,
    FIELD7: 19.6
  },
  0.6: {
    FIELD1: 14.6,
    FIELD2: 15.1,
    FIELD3: 15.9,
    FIELD4: 16.9,
    FIELD5: 18,
    FIELD6: 19,
    FIELD7: 19.6
  },
  0.7: {
    FIELD1: 14.6,
    FIELD2: 15.1,
    FIELD3: 15.9,
    FIELD4: 16.9,
    FIELD5: 18,
    FIELD6: 19,
    FIELD7: 19.6
  },
  0.8: {
    FIELD1: 14.6,
    FIELD2: 15,
    FIELD3: 15.9,
    FIELD4: 16.8,
    FIELD5: 17.9,
    FIELD6: 18.9,
    FIELD7: 19.6
  },
  0.9: {
    FIELD1: 14.5,
    FIELD2: 15,
    FIELD3: 15.8,
    FIELD4: 16.7,
    FIELD5: 17.8,
    FIELD6: 18.8,
    FIELD7: 19.4
  },
  '0.10': {
    FIELD1: 14.4,
    FIELD2: 14.9,
    FIELD3: 15.7,
    FIELD4: 16.6,
    FIELD5: 17.7,
    FIELD6: 18.7,
    FIELD7: 19.3
  },
  0.11: {
    FIELD1: 14.3,
    FIELD2: 14.8,
    FIELD3: 15.5,
    FIELD4: 16.5,
    FIELD5: 17.5,
    FIELD6: 18.5,
    FIELD7: 19.1
  },
  '1.0': {
    FIELD1: 14.2,
    FIELD2: 14.6,
    FIELD3: 15.4,
    FIELD4: 16.4,
    FIELD5: 17.4,
    FIELD6: 18.4,
    FIELD7: 19
  },
  1.1: {
    FIELD1: 14.1,
    FIELD2: 14.5,
    FIELD3: 15.3,
    FIELD4: 16.2,
    FIELD5: 17.2,
    FIELD6: 18.2,
    FIELD7: 18.8
  },
  1.2: {
    FIELD1: 14,
    FIELD2: 14.4,
    FIELD3: 15.2,
    FIELD4: 16.1,
    FIELD5: 17.1,
    FIELD6: 18.1,
    FIELD7: 18.7
  },
  1.3: {
    FIELD1: 13.9,
    FIELD2: 14.3,
    FIELD3: 15.1,
    FIELD4: 16,
    FIELD5: 17,
    FIELD6: 17.9,
    FIELD7: 18.6
  },
  1.4: {
    FIELD1: 13.8,
    FIELD2: 14.3,
    FIELD3: 15,
    FIELD4: 15.9,
    FIELD5: 16.9,
    FIELD6: 17.8,
    FIELD7: 18.4
  },
  1.5: {
    FIELD1: 13.8,
    FIELD2: 14.2,
    FIELD3: 14.9,
    FIELD4: 15.8,
    FIELD5: 16.8,
    FIELD6: 17.7,
    FIELD7: 18.3
  },
  1.6: {
    FIELD1: 13.7,
    FIELD2: 14.1,
    FIELD3: 14.8,
    FIELD4: 15.7,
    FIELD5: 16.7,
    FIELD6: 17.6,
    FIELD7: 18.2
  },
  1.7: {
    FIELD1: 13.6,
    FIELD2: 14.1,
    FIELD3: 14.8,
    FIELD4: 15.7,
    FIELD5: 16.6,
    FIELD6: 17.5,
    FIELD7: 18.1
  },
  1.8: {
    FIELD1: 13.6,
    FIELD2: 14,
    FIELD3: 14.7,
    FIELD4: 15.6,
    FIELD5: 16.5,
    FIELD6: 17.5,
    FIELD7: 18.1
  },
  1.9: {
    FIELD1: 13.6,
    FIELD2: 14,
    FIELD3: 14.7,
    FIELD4: 15.5,
    FIELD5: 16.5,
    FIELD6: 17.4,
    FIELD7: 18
  },
  '1.10': {
    FIELD1: 13.5,
    FIELD2: 13.9,
    FIELD3: 14.6,
    FIELD4: 15.5,
    FIELD5: 16.4,
    FIELD6: 17.3,
    FIELD7: 17.9
  },
  1.11: {
    FIELD1: 13.5,
    FIELD2: 13.9,
    FIELD3: 14.6,
    FIELD4: 15.4,
    FIELD5: 16.4,
    FIELD6: 17.3,
    FIELD7: 17.9
  },
  '2.0': {
    FIELD1: 13.7,
    FIELD2: 14.1,
    FIELD3: 14.8,
    FIELD4: 15.7,
    FIELD5: 16.6,
    FIELD6: 17.5,
    FIELD7: 18.1
  },
  2.1: {
    FIELD1: 13.7,
    FIELD2: 14.1,
    FIELD3: 14.8,
    FIELD4: 15.7,
    FIELD5: 16.6,
    FIELD6: 17.5,
    FIELD7: 18.1
  },
  2.2: {
    FIELD1: 13.7,
    FIELD2: 14.1,
    FIELD3: 14.8,
    FIELD4: 15.6,
    FIELD5: 16.6,
    FIELD6: 17.5,
    FIELD7: 18.1
  },
  2.3: {
    FIELD1: 13.7,
    FIELD2: 14,
    FIELD3: 14.8,
    FIELD4: 15.6,
    FIELD5: 16.5,
    FIELD6: 17.4,
    FIELD7: 18
  },
  2.4: {
    FIELD1: 13.6,
    FIELD2: 14,
    FIELD3: 14.7,
    FIELD4: 15.6,
    FIELD5: 16.5,
    FIELD6: 17.4,
    FIELD7: 18
  },
  2.5: {
    FIELD1: 13.6,
    FIELD2: 14,
    FIELD3: 14.7,
    FIELD4: 15.6,
    FIELD5: 16.5,
    FIELD6: 17.4,
    FIELD7: 18
  },
  2.6: {
    FIELD1: 13.6,
    FIELD2: 14,
    FIELD3: 14.7,
    FIELD4: 15.5,
    FIELD5: 16.5,
    FIELD6: 17.4,
    FIELD7: 17.9
  },
  2.7: {
    FIELD1: 13.6,
    FIELD2: 14,
    FIELD3: 14.7,
    FIELD4: 15.5,
    FIELD5: 16.4,
    FIELD6: 17.3,
    FIELD7: 17.9
  },
  2.8: {
    FIELD1: 13.5,
    FIELD2: 13.9,
    FIELD3: 14.6,
    FIELD4: 15.5,
    FIELD5: 16.4,
    FIELD6: 17.3,
    FIELD7: 17.9
  },
  2.9: {
    FIELD1: 13.5,
    FIELD2: 13.9,
    FIELD3: 14.6,
    FIELD4: 15.5,
    FIELD5: 16.4,
    FIELD6: 17.3,
    FIELD7: 17.9
  },
  '2.10': {
    FIELD1: 13.5,
    FIELD2: 13.9,
    FIELD3: 14.6,
    FIELD4: 15.4,
    FIELD5: 16.4,
    FIELD6: 17.3,
    FIELD7: 17.9
  },
  2.11: {
    FIELD1: 13.5,
    FIELD2: 13.9,
    FIELD3: 14.6,
    FIELD4: 15.4,
    FIELD5: 16.3,
    FIELD6: 17.3,
    FIELD7: 17.8
  },
  '3.0': {
    FIELD1: 13.5,
    FIELD2: 13.8,
    FIELD3: 14.5,
    FIELD4: 15.4,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  3.1: {
    FIELD1: 13.4,
    FIELD2: 13.8,
    FIELD3: 14.5,
    FIELD4: 15.4,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  3.2: {
    FIELD1: 13.4,
    FIELD2: 13.8,
    FIELD3: 14.5,
    FIELD4: 15.4,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  3.3: {
    FIELD1: 13.4,
    FIELD2: 13.8,
    FIELD3: 14.5,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  3.4: {
    FIELD1: 13.4,
    FIELD2: 13.8,
    FIELD3: 14.5,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  3.5: {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.5,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  3.6: {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  3.7: {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  3.8: {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  3.9: {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  '3.10': {
    FIELD1: 13.2,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  3.11: {
    FIELD1: 13.2,
    FIELD2: 13.6,
    FIELD3: 14.4,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.9
  },
  '4.0': {
    FIELD1: 13.2,
    FIELD2: 13.6,
    FIELD3: 14.4,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.9
  },
  4.1: {
    FIELD1: 13.2,
    FIELD2: 13.6,
    FIELD3: 14.4,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.9
  },
  4.2: {
    FIELD1: 13.2,
    FIELD2: 13.6,
    FIELD3: 14.3,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.3,
    FIELD7: 17.9
  },
  4.3: {
    FIELD1: 13.2,
    FIELD2: 13.6,
    FIELD3: 14.3,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.3,
    FIELD7: 17.9
  },
  4.4: {
    FIELD1: 13.1,
    FIELD2: 13.6,
    FIELD3: 14.3,
    FIELD4: 15.2,
    FIELD5: 16.3,
    FIELD6: 17.3,
    FIELD7: 17.9
  },
  4.5: {
    FIELD1: 13.1,
    FIELD2: 13.6,
    FIELD3: 14.3,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.3,
    FIELD7: 17.9
  },
  4.6: {
    FIELD1: 13.1,
    FIELD2: 13.6,
    FIELD3: 14.3,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.3,
    FIELD7: 18
  },
  4.7: {
    FIELD1: 13.1,
    FIELD2: 13.5,
    FIELD3: 14.3,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.3,
    FIELD7: 18
  },
  4.8: {
    FIELD1: 13.1,
    FIELD2: 13.5,
    FIELD3: 14.3,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.3,
    FIELD7: 18
  },
  4.9: {
    FIELD1: 13.1,
    FIELD2: 13.5,
    FIELD3: 14.3,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.4,
    FIELD7: 18
  },
  '4.10': {
    FIELD1: 13.1,
    FIELD2: 13.5,
    FIELD3: 14.3,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.4,
    FIELD7: 18
  },
  4.11: {
    FIELD1: 13.1,
    FIELD2: 13.5,
    FIELD3: 14.3,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.4,
    FIELD7: 18.1
  },
  '5.0': {
    FIELD1: 13.1,
    FIELD2: 13.5,
    FIELD3: 14.3,
    FIELD4: 15.3,
    FIELD5: 16.3,
    FIELD6: 17.4,
    FIELD7: 18.1
  },
  5.1: {
    FIELD1: 13.133,
    FIELD2: 13.55,
    FIELD3: 14.306,
    FIELD4: 15.244,
    FIELD5: 16.306,
    FIELD6: 17.388,
    FIELD7: 18.103
  },
  5.2: {
    FIELD1: 13.126,
    FIELD2: 13.544,
    FIELD3: 14.301,
    FIELD4: 15.243,
    FIELD5: 16.311,
    FIELD6: 17.402,
    FIELD7: 18.124
  },
  5.3: {
    FIELD1: 13.12,
    FIELD2: 13.538,
    FIELD3: 14.298,
    FIELD4: 15.243,
    FIELD5: 16.317,
    FIELD6: 17.416,
    FIELD7: 18.145
  },
  5.4: {
    FIELD1: 13.114,
    FIELD2: 13.533,
    FIELD3: 14.294,
    FIELD4: 15.244,
    FIELD5: 16.324,
    FIELD6: 17.431,
    FIELD7: 18.167
  },
  5.5: {
    FIELD1: 13.109,
    FIELD2: 13.529,
    FIELD3: 14.292,
    FIELD4: 15.245,
    FIELD5: 16.331,
    FIELD6: 17.447,
    FIELD7: 18.189
  },
  5.6: {
    FIELD1: 13.104,
    FIELD2: 13.525,
    FIELD3: 14.29,
    FIELD4: 15.246,
    FIELD5: 16.339,
    FIELD6: 17.463,
    FIELD7: 18.212
  },
  5.7: {
    FIELD1: 13.1,
    FIELD2: 13.521,
    FIELD3: 14.288,
    FIELD4: 15.249,
    FIELD5: 16.347,
    FIELD6: 17.481,
    FIELD7: 18.237
  },
  5.8: {
    FIELD1: 13.097,
    FIELD2: 13.518,
    FIELD3: 14.287,
    FIELD4: 15.252,
    FIELD5: 16.357,
    FIELD6: 17.499,
    FIELD7: 18.262
  },
  5.9: {
    FIELD1: 13.094,
    FIELD2: 13.516,
    FIELD3: 14.287,
    FIELD4: 15.255,
    FIELD5: 16.367,
    FIELD6: 17.518,
    FIELD7: 18.289
  },
  '5.10': {
    FIELD1: 13.091,
    FIELD2: 13.514,
    FIELD3: 14.287,
    FIELD4: 15.259,
    FIELD5: 16.377,
    FIELD6: 17.537,
    FIELD7: 18.316
  },
  5.11: {
    FIELD1: 13.09,
    FIELD2: 13.513,
    FIELD3: 14.288,
    FIELD4: 15.264,
    FIELD5: 16.388,
    FIELD6: 17.558,
    FIELD7: 18.344
  },
  '6.0': {
    FIELD1: 13.089,
    FIELD2: 13.513,
    FIELD3: 14.29,
    FIELD4: 15.27,
    FIELD5: 16.401,
    FIELD6: 17.579,
    FIELD7: 18.373
  },
  6.1: {
    FIELD1: 13.088,
    FIELD2: 13.513,
    FIELD3: 14.292,
    FIELD4: 15.276,
    FIELD5: 16.414,
    FIELD6: 17.601,
    FIELD7: 18.403
  },
  6.2: {
    FIELD1: 13.088,
    FIELD2: 13.514,
    FIELD3: 14.295,
    FIELD4: 15.283,
    FIELD5: 16.427,
    FIELD6: 17.625,
    FIELD7: 18.434
  },
  6.3: {
    FIELD1: 13.089,
    FIELD2: 13.516,
    FIELD3: 14.299,
    FIELD4: 15.291,
    FIELD5: 16.442,
    FIELD6: 17.649,
    FIELD7: 18.466
  },
  6.4: {
    FIELD1: 13.09,
    FIELD2: 13.518,
    FIELD3: 14.303,
    FIELD4: 15.3,
    FIELD5: 16.458,
    FIELD6: 17.674,
    FIELD7: 18.5
  },
  6.5: {
    FIELD1: 13.093,
    FIELD2: 13.521,
    FIELD3: 14.309,
    FIELD4: 15.31,
    FIELD5: 16.475,
    FIELD6: 17.701,
    FIELD7: 18.534
  },
  6.6: {
    FIELD1: 13.096,
    FIELD2: 13.525,
    FIELD3: 14.315,
    FIELD4: 15.32,
    FIELD5: 16.492,
    FIELD6: 17.728,
    FIELD7: 18.57
  },
  6.7: {
    FIELD1: 13.099,
    FIELD2: 13.53,
    FIELD3: 14.322,
    FIELD4: 15.331,
    FIELD5: 16.511,
    FIELD6: 17.757,
    FIELD7: 18.607
  },
  6.8: {
    FIELD1: 13.104,
    FIELD2: 13.535,
    FIELD3: 14.33,
    FIELD4: 15.344,
    FIELD5: 16.53,
    FIELD6: 17.787,
    FIELD7: 18.645
  },
  6.9: {
    FIELD1: 13.11,
    FIELD2: 13.542,
    FIELD3: 14.339,
    FIELD4: 15.357,
    FIELD5: 16.551,
    FIELD6: 17.818,
    FIELD7: 18.685
  },
  '6.10': {
    FIELD1: 13.116,
    FIELD2: 13.549,
    FIELD3: 14.349,
    FIELD4: 15.372,
    FIELD5: 16.573,
    FIELD6: 17.85,
    FIELD7: 18.726
  },
  6.11: {
    FIELD1: 13.123,
    FIELD2: 13.557,
    FIELD3: 14.36,
    FIELD4: 15.387,
    FIELD5: 16.596,
    FIELD6: 17.883,
    FIELD7: 18.768
  },
  '7.0': {
    FIELD1: 13.131,
    FIELD2: 13.566,
    FIELD3: 14.371,
    FIELD4: 15.404,
    FIELD5: 16.62,
    FIELD6: 17.918,
    FIELD7: 18.812
  },
  7.1: {
    FIELD1: 13.14,
    FIELD2: 13.576,
    FIELD3: 14.384,
    FIELD4: 15.421,
    FIELD5: 16.645,
    FIELD6: 17.954,
    FIELD7: 18.857
  },
  7.2: {
    FIELD1: 13.15,
    FIELD2: 13.587,
    FIELD3: 14.398,
    FIELD4: 15.44,
    FIELD5: 16.671,
    FIELD6: 17.991,
    FIELD7: 18.903
  },
  7.3: {
    FIELD1: 13.16,
    FIELD2: 13.599,
    FIELD3: 14.412,
    FIELD4: 15.459,
    FIELD5: 16.699,
    FIELD6: 18.03,
    FIELD7: 18.951
  },
  7.4: {
    FIELD1: 13.172,
    FIELD2: 13.611,
    FIELD3: 14.428,
    FIELD4: 15.48,
    FIELD5: 16.727,
    FIELD6: 18.069,
    FIELD7: 19
  },
  7.5: {
    FIELD1: 13.184,
    FIELD2: 13.625,
    FIELD3: 14.444,
    FIELD4: 15.501,
    FIELD5: 16.757,
    FIELD6: 18.11,
    FIELD7: 19.05
  },
  7.6: {
    FIELD1: 13.197,
    FIELD2: 13.639,
    FIELD3: 14.462,
    FIELD4: 15.524,
    FIELD5: 16.788,
    FIELD6: 18.152,
    FIELD7: 19.102
  },
  7.7: {
    FIELD1: 13.211,
    FIELD2: 13.655,
    FIELD3: 14.48,
    FIELD4: 15.548,
    FIELD5: 16.819,
    FIELD6: 18.195,
    FIELD7: 19.154
  },
  7.8: {
    FIELD1: 13.226,
    FIELD2: 13.671,
    FIELD3: 14.499,
    FIELD4: 15.572,
    FIELD5: 16.852,
    FIELD6: 18.24,
    FIELD7: 19.208
  },
  7.9: {
    FIELD1: 13.241,
    FIELD2: 13.688,
    FIELD3: 14.52,
    FIELD4: 15.598,
    FIELD5: 16.887,
    FIELD6: 18.286,
    FIELD7: 19.264
  },
  '7.10': {
    FIELD1: 13.257,
    FIELD2: 13.706,
    FIELD3: 14.541,
    FIELD4: 15.625,
    FIELD5: 16.922,
    FIELD6: 18.333,
    FIELD7: 19.321
  },
  7.11: {
    FIELD1: 13.275,
    FIELD2: 13.724,
    FIELD3: 14.563,
    FIELD4: 15.652,
    FIELD5: 16.958,
    FIELD6: 18.381,
    FIELD7: 19.379
  },
  '8.0': {
    FIELD1: 13.293,
    FIELD2: 13.744,
    FIELD3: 14.586,
    FIELD4: 15.681,
    FIELD5: 16.995,
    FIELD6: 18.43,
    FIELD7: 19.438
  },
  8.1: {
    FIELD1: 13.312,
    FIELD2: 13.764,
    FIELD3: 14.61,
    FIELD4: 15.711,
    FIELD5: 17.034,
    FIELD6: 18.48,
    FIELD7: 19.498
  },
  8.2: {
    FIELD1: 13.331,
    FIELD2: 13.786,
    FIELD3: 14.635,
    FIELD4: 15.742,
    FIELD5: 17.073,
    FIELD6: 18.532,
    FIELD7: 19.56
  },
  8.3: {
    FIELD1: 13.352,
    FIELD2: 13.808,
    FIELD3: 14.661,
    FIELD4: 15.773,
    FIELD5: 17.114,
    FIELD6: 18.585,
    FIELD7: 19.623
  },
  8.4: {
    FIELD1: 13.373,
    FIELD2: 13.831,
    FIELD3: 14.687,
    FIELD4: 15.806,
    FIELD5: 17.156,
    FIELD6: 18.639,
    FIELD7: 19.687
  },
  8.5: {
    FIELD1: 13.395,
    FIELD2: 13.854,
    FIELD3: 14.715,
    FIELD4: 15.839,
    FIELD5: 17.198,
    FIELD6: 18.693,
    FIELD7: 19.752
  },
  8.6: {
    FIELD1: 13.418,
    FIELD2: 13.879,
    FIELD3: 14.743,
    FIELD4: 15.874,
    FIELD5: 17.242,
    FIELD6: 18.749,
    FIELD7: 19.818
  },
  8.7: {
    FIELD1: 13.441,
    FIELD2: 13.904,
    FIELD3: 14.772,
    FIELD4: 15.909,
    FIELD5: 17.286,
    FIELD6: 18.806,
    FIELD7: 19.885
  },
  8.8: {
    FIELD1: 13.465,
    FIELD2: 13.93,
    FIELD3: 14.802,
    FIELD4: 15.945,
    FIELD5: 17.331,
    FIELD6: 18.864,
    FIELD7: 19.953
  },
  8.9: {
    FIELD1: 13.489,
    FIELD2: 13.956,
    FIELD3: 14.832,
    FIELD4: 15.982,
    FIELD5: 17.377,
    FIELD6: 18.922,
    FIELD7: 20.022
  },
  '8.10': {
    FIELD1: 13.514,
    FIELD2: 13.983,
    FIELD3: 14.864,
    FIELD4: 16.019,
    FIELD5: 17.424,
    FIELD6: 18.982,
    FIELD7: 20.092
  },
  8.11: {
    FIELD1: 13.54,
    FIELD2: 14.011,
    FIELD3: 14.895,
    FIELD4: 16.058,
    FIELD5: 17.472,
    FIELD6: 19.042,
    FIELD7: 20.162
  },
  '9.0': {
    FIELD1: 13.566,
    FIELD2: 14.039,
    FIELD3: 14.928,
    FIELD4: 16.096,
    FIELD5: 17.52,
    FIELD6: 19.102,
    FIELD7: 20.233
  },
  9.1: {
    FIELD1: 13.592,
    FIELD2: 14.067,
    FIELD3: 14.96,
    FIELD4: 16.136,
    FIELD5: 17.569,
    FIELD6: 19.164,
    FIELD7: 20.305
  },
  9.2: {
    FIELD1: 13.62,
    FIELD2: 14.097,
    FIELD3: 14.994,
    FIELD4: 16.176,
    FIELD5: 17.618,
    FIELD6: 19.226,
    FIELD7: 20.378
  },
  9.3: {
    FIELD1: 13.647,
    FIELD2: 14.126,
    FIELD3: 15.028,
    FIELD4: 16.217,
    FIELD5: 17.669,
    FIELD6: 19.289,
    FIELD7: 20.451
  },
  9.4: {
    FIELD1: 13.675,
    FIELD2: 14.156,
    FIELD3: 15.063,
    FIELD4: 16.258,
    FIELD5: 17.72,
    FIELD6: 19.352,
    FIELD7: 20.524
  },
  9.5: {
    FIELD1: 13.704,
    FIELD2: 14.187,
    FIELD3: 15.098,
    FIELD4: 16.3,
    FIELD5: 17.771,
    FIELD6: 19.416,
    FIELD7: 20.598
  },
  9.6: {
    FIELD1: 13.733,
    FIELD2: 14.218,
    FIELD3: 15.134,
    FIELD4: 16.343,
    FIELD5: 17.823,
    FIELD6: 19.481,
    FIELD7: 20.673
  },
  9.7: {
    FIELD1: 13.762,
    FIELD2: 14.25,
    FIELD3: 15.17,
    FIELD4: 16.386,
    FIELD5: 17.876,
    FIELD6: 19.546,
    FIELD7: 20.749
  },
  9.8: {
    FIELD1: 13.792,
    FIELD2: 14.283,
    FIELD3: 15.207,
    FIELD4: 16.43,
    FIELD5: 17.93,
    FIELD6: 19.612,
    FIELD7: 20.825
  },
  9.9: {
    FIELD1: 13.823,
    FIELD2: 14.316,
    FIELD3: 15.245,
    FIELD4: 16.475,
    FIELD5: 17.984,
    FIELD6: 19.679,
    FIELD7: 20.902
  },
  '9.10': {
    FIELD1: 13.854,
    FIELD2: 14.349,
    FIELD3: 15.283,
    FIELD4: 16.52,
    FIELD5: 18.039,
    FIELD6: 19.746,
    FIELD7: 20.979
  },
  9.11: {
    FIELD1: 13.886,
    FIELD2: 14.384,
    FIELD3: 15.323,
    FIELD4: 16.566,
    FIELD5: 18.096,
    FIELD6: 19.815,
    FIELD7: 21.058
  },
  '10.0': {
    FIELD1: 13.919,
    FIELD2: 14.418,
    FIELD3: 15.362,
    FIELD4: 16.613,
    FIELD5: 18.152,
    FIELD6: 19.884,
    FIELD7: 21.137
  },
  10.1: {
    FIELD1: 13.952,
    FIELD2: 14.454,
    FIELD3: 15.403,
    FIELD4: 16.661,
    FIELD5: 18.21,
    FIELD6: 19.954,
    FIELD7: 21.217
  },
  10.2: {
    FIELD1: 13.986,
    FIELD2: 14.491,
    FIELD3: 15.445,
    FIELD4: 16.71,
    FIELD5: 18.269,
    FIELD6: 20.025,
    FIELD7: 21.298
  },
  10.3: {
    FIELD1: 14.02,
    FIELD2: 14.528,
    FIELD3: 15.487,
    FIELD4: 16.76,
    FIELD5: 18.328,
    FIELD6: 20.097,
    FIELD7: 21.379
  },
  10.4: {
    FIELD1: 14.055,
    FIELD2: 14.566,
    FIELD3: 15.53,
    FIELD4: 16.81,
    FIELD5: 18.389,
    FIELD6: 20.17,
    FIELD7: 21.462
  },
  10.5: {
    FIELD1: 14.092,
    FIELD2: 14.604,
    FIELD3: 15.574,
    FIELD4: 16.861,
    FIELD5: 18.45,
    FIELD6: 20.244,
    FIELD7: 21.545
  },
  10.6: {
    FIELD1: 14.128,
    FIELD2: 14.644,
    FIELD3: 15.619,
    FIELD4: 16.914,
    FIELD5: 18.512,
    FIELD6: 20.318,
    FIELD7: 21.629
  },
  10.7: {
    FIELD1: 14.165,
    FIELD2: 14.684,
    FIELD3: 15.664,
    FIELD4: 16.967,
    FIELD5: 18.575,
    FIELD6: 20.394,
    FIELD7: 21.714
  },
  10.8: {
    FIELD1: 14.204,
    FIELD2: 14.724,
    FIELD3: 15.71,
    FIELD4: 17.021,
    FIELD5: 18.64,
    FIELD6: 20.47,
    FIELD7: 21.8
  },
  10.9: {
    FIELD1: 14.242,
    FIELD2: 14.766,
    FIELD3: 15.758,
    FIELD4: 17.076,
    FIELD5: 18.705,
    FIELD6: 20.547,
    FIELD7: 21.887
  },
  '10.10': {
    FIELD1: 14.282,
    FIELD2: 14.809,
    FIELD3: 15.806,
    FIELD4: 17.132,
    FIELD5: 18.771,
    FIELD6: 20.626,
    FIELD7: 21.974
  },
  10.11: {
    FIELD1: 14.322,
    FIELD2: 14.852,
    FIELD3: 15.854,
    FIELD4: 17.188,
    FIELD5: 18.838,
    FIELD6: 20.705,
    FIELD7: 22.063
  },
  '11.0': {
    FIELD1: 14.363,
    FIELD2: 14.896,
    FIELD3: 15.904,
    FIELD4: 17.246,
    FIELD5: 18.906,
    FIELD6: 20.785,
    FIELD7: 22.152
  },
  11.1: {
    FIELD1: 14.405,
    FIELD2: 14.94,
    FIELD3: 15.955,
    FIELD4: 17.304,
    FIELD5: 18.974,
    FIELD6: 20.866,
    FIELD7: 22.242
  },
  11.2: {
    FIELD1: 14.447,
    FIELD2: 14.986,
    FIELD3: 16.006,
    FIELD4: 17.364,
    FIELD5: 19.044,
    FIELD6: 20.948,
    FIELD7: 22.333
  },
  11.3: {
    FIELD1: 14.49,
    FIELD2: 15.032,
    FIELD3: 16.058,
    FIELD4: 17.424,
    FIELD5: 19.114,
    FIELD6: 21.03,
    FIELD7: 22.424
  },
  11.4: {
    FIELD1: 14.533,
    FIELD2: 15.078,
    FIELD3: 16.11,
    FIELD4: 17.485,
    FIELD5: 19.186,
    FIELD6: 21.113,
    FIELD7: 22.516
  },
  11.5: {
    FIELD1: 14.578,
    FIELD2: 15.126,
    FIELD3: 16.164,
    FIELD4: 17.546,
    FIELD5: 19.258,
    FIELD6: 21.197,
    FIELD7: 22.609
  },
  11.6: {
    FIELD1: 14.623,
    FIELD2: 15.174,
    FIELD3: 16.218,
    FIELD4: 17.609,
    FIELD5: 19.331,
    FIELD6: 21.282,
    FIELD7: 22.703
  },
  11.7: {
    FIELD1: 14.668,
    FIELD2: 15.222,
    FIELD3: 16.273,
    FIELD4: 17.672,
    FIELD5: 19.404,
    FIELD6: 21.368,
    FIELD7: 22.797
  },
  11.8: {
    FIELD1: 14.714,
    FIELD2: 15.271,
    FIELD3: 16.328,
    FIELD4: 17.736,
    FIELD5: 19.478,
    FIELD6: 21.454,
    FIELD7: 22.892
  },
  11.9: {
    FIELD1: 14.76,
    FIELD2: 15.321,
    FIELD3: 16.384,
    FIELD4: 17.8,
    FIELD5: 19.553,
    FIELD6: 21.54,
    FIELD7: 22.987
  },
  '11.10': {
    FIELD1: 14.807,
    FIELD2: 15.371,
    FIELD3: 16.441,
    FIELD4: 17.865,
    FIELD5: 19.629,
    FIELD6: 21.628,
    FIELD7: 23.082
  },
  11.11: {
    FIELD1: 14.855,
    FIELD2: 15.422,
    FIELD3: 16.498,
    FIELD4: 17.931,
    FIELD5: 19.705,
    FIELD6: 21.715,
    FIELD7: 23.178
  },
  '12.0': {
    FIELD1: 14.902,
    FIELD2: 15.473,
    FIELD3: 16.555,
    FIELD4: 17.997,
    FIELD5: 19.781,
    FIELD6: 21.803,
    FIELD7: 23.275
  },
  12.1: {
    FIELD1: 14.95,
    FIELD2: 15.524,
    FIELD3: 16.613,
    FIELD4: 18.063,
    FIELD5: 19.858,
    FIELD6: 21.892,
    FIELD7: 23.371
  },
  12.2: {
    FIELD1: 14.999,
    FIELD2: 15.576,
    FIELD3: 16.671,
    FIELD4: 18.13,
    FIELD5: 19.935,
    FIELD6: 21.98,
    FIELD7: 23.467
  },
  12.3: {
    FIELD1: 15.047,
    FIELD2: 15.628,
    FIELD3: 16.73,
    FIELD4: 18.197,
    FIELD5: 20.012,
    FIELD6: 22.069,
    FIELD7: 23.564
  },
  12.4: {
    FIELD1: 15.096,
    FIELD2: 15.68,
    FIELD3: 16.788,
    FIELD4: 18.264,
    FIELD5: 20.09,
    FIELD6: 22.157,
    FIELD7: 23.66
  },
  12.5: {
    FIELD1: 15.145,
    FIELD2: 15.733,
    FIELD3: 16.847,
    FIELD4: 18.331,
    FIELD5: 20.167,
    FIELD6: 22.246,
    FIELD7: 23.756
  },
  12.6: {
    FIELD1: 15.193,
    FIELD2: 15.785,
    FIELD3: 16.906,
    FIELD4: 18.399,
    FIELD5: 20.245,
    FIELD6: 22.335,
    FIELD7: 23.853
  },
  12.7: {
    FIELD1: 15.242,
    FIELD2: 15.837,
    FIELD3: 16.965,
    FIELD4: 18.466,
    FIELD5: 20.323,
    FIELD6: 22.423,
    FIELD7: 23.948
  },
  12.8: {
    FIELD1: 15.291,
    FIELD2: 15.889,
    FIELD3: 17.024,
    FIELD4: 18.533,
    FIELD5: 20.4,
    FIELD6: 22.511,
    FIELD7: 24.044
  },
  12.9: {
    FIELD1: 15.34,
    FIELD2: 15.942,
    FIELD3: 17.082,
    FIELD4: 18.601,
    FIELD5: 20.477,
    FIELD6: 22.599,
    FIELD7: 24.139
  },
  '12.10': {
    FIELD1: 15.388,
    FIELD2: 15.994,
    FIELD3: 17.141,
    FIELD4: 18.668,
    FIELD5: 20.555,
    FIELD6: 22.687,
    FIELD7: 24.233
  },
  12.11: {
    FIELD1: 15.437,
    FIELD2: 16.046,
    FIELD3: 17.2,
    FIELD4: 18.735,
    FIELD5: 20.631,
    FIELD6: 22.774,
    FIELD7: 24.327
  },
  '13.0': {
    FIELD1: 15.485,
    FIELD2: 16.097,
    FIELD3: 17.258,
    FIELD4: 18.801,
    FIELD5: 20.708,
    FIELD6: 22.86,
    FIELD7: 24.42
  },
  13.1: {
    FIELD1: 15.533,
    FIELD2: 16.149,
    FIELD3: 17.316,
    FIELD4: 18.868,
    FIELD5: 20.784,
    FIELD6: 22.946,
    FIELD7: 24.513
  },
  13.2: {
    FIELD1: 15.581,
    FIELD2: 16.2,
    FIELD3: 17.373,
    FIELD4: 18.934,
    FIELD5: 20.859,
    FIELD6: 23.032,
    FIELD7: 24.605
  },
  13.3: {
    FIELD1: 15.628,
    FIELD2: 16.251,
    FIELD3: 17.431,
    FIELD4: 18.999,
    FIELD5: 20.934,
    FIELD6: 23.116,
    FIELD7: 24.695
  },
  13.4: {
    FIELD1: 15.675,
    FIELD2: 16.301,
    FIELD3: 17.488,
    FIELD4: 19.064,
    FIELD5: 21.009,
    FIELD6: 23.201,
    FIELD7: 24.786
  },
  13.5: {
    FIELD1: 15.722,
    FIELD2: 16.351,
    FIELD3: 17.544,
    FIELD4: 19.129,
    FIELD5: 21.083,
    FIELD6: 23.284,
    FIELD7: 24.875
  },
  13.6: {
    FIELD1: 15.768,
    FIELD2: 16.401,
    FIELD3: 17.6,
    FIELD4: 19.193,
    FIELD5: 21.156,
    FIELD6: 23.367,
    FIELD7: 24.963
  },
  13.7: {
    FIELD1: 15.814,
    FIELD2: 16.451,
    FIELD3: 17.656,
    FIELD4: 19.257,
    FIELD5: 21.229,
    FIELD6: 23.448,
    FIELD7: 25.05
  },
  13.8: {
    FIELD1: 15.859,
    FIELD2: 16.499,
    FIELD3: 17.711,
    FIELD4: 19.32,
    FIELD5: 21.301,
    FIELD6: 23.529,
    FIELD7: 25.137
  },
  13.9: {
    FIELD1: 15.904,
    FIELD2: 16.547,
    FIELD3: 17.765,
    FIELD4: 19.382,
    FIELD5: 21.372,
    FIELD6: 23.609,
    FIELD7: 25.222
  },
  '13.10': {
    FIELD1: 15.948,
    FIELD2: 16.595,
    FIELD3: 17.819,
    FIELD4: 19.444,
    FIELD5: 21.443,
    FIELD6: 23.688,
    FIELD7: 25.306
  },
  13.11: {
    FIELD1: 15.992,
    FIELD2: 16.642,
    FIELD3: 17.872,
    FIELD4: 19.504,
    FIELD5: 21.512,
    FIELD6: 23.765,
    FIELD7: 25.388
  },
  '14.0': {
    FIELD1: 16.035,
    FIELD2: 16.688,
    FIELD3: 17.925,
    FIELD4: 19.565,
    FIELD5: 21.581,
    FIELD6: 23.842,
    FIELD7: 25.47
  },
  14.1: {
    FIELD1: 16.077,
    FIELD2: 16.734,
    FIELD3: 17.977,
    FIELD4: 19.624,
    FIELD5: 21.648,
    FIELD6: 23.918,
    FIELD7: 25.55
  },
  14.2: {
    FIELD1: 16.119,
    FIELD2: 16.779,
    FIELD3: 18.028,
    FIELD4: 19.682,
    FIELD5: 21.715,
    FIELD6: 23.992,
    FIELD7: 25.629
  },
  14.3: {
    FIELD1: 16.16,
    FIELD2: 16.823,
    FIELD3: 18.078,
    FIELD4: 19.74,
    FIELD5: 21.781,
    FIELD6: 24.065,
    FIELD7: 25.707
  },
  14.4: {
    FIELD1: 16.2,
    FIELD2: 16.867,
    FIELD3: 18.127,
    FIELD4: 19.797,
    FIELD5: 21.845,
    FIELD6: 24.137,
    FIELD7: 25.783
  },
  14.5: {
    FIELD1: 16.239,
    FIELD2: 16.909,
    FIELD3: 18.176,
    FIELD4: 19.852,
    FIELD5: 21.909,
    FIELD6: 24.208,
    FIELD7: 25.857
  },
  14.6: {
    FIELD1: 16.278,
    FIELD2: 16.951,
    FIELD3: 18.223,
    FIELD4: 19.907,
    FIELD5: 21.971,
    FIELD6: 24.277,
    FIELD7: 25.93
  },
  14.7: {
    FIELD1: 16.316,
    FIELD2: 16.992,
    FIELD3: 18.27,
    FIELD4: 19.961,
    FIELD5: 22.032,
    FIELD6: 24.345,
    FIELD7: 26.002
  },
  14.8: {
    FIELD1: 16.353,
    FIELD2: 17.033,
    FIELD3: 18.316,
    FIELD4: 20.013,
    FIELD5: 22.092,
    FIELD6: 24.411,
    FIELD7: 26.072
  },
  14.9: {
    FIELD1: 16.389,
    FIELD2: 17.072,
    FIELD3: 18.361,
    FIELD4: 20.065,
    FIELD5: 22.151,
    FIELD6: 24.477,
    FIELD7: 26.141
  },
  '14.10': {
    FIELD1: 16.425,
    FIELD2: 17.11,
    FIELD3: 18.404,
    FIELD4: 20.115,
    FIELD5: 22.208,
    FIELD6: 24.54,
    FIELD7: 26.207
  },
  14.11: {
    FIELD1: 16.459,
    FIELD2: 17.147,
    FIELD3: 18.447,
    FIELD4: 20.164,
    FIELD5: 22.264,
    FIELD6: 24.602,
    FIELD7: 26.273
  },
  '15.0': {
    FIELD1: 16.492,
    FIELD2: 17.184,
    FIELD3: 18.489,
    FIELD4: 20.212,
    FIELD5: 22.319,
    FIELD6: 24.663,
    FIELD7: 26.336
  },
  15.1: {
    FIELD1: 16.525,
    FIELD2: 17.219,
    FIELD3: 18.529,
    FIELD4: 20.26,
    FIELD5: 22.373,
    FIELD6: 24.722,
    FIELD7: 26.398
  },
  15.2: {
    FIELD1: 16.557,
    FIELD2: 17.254,
    FIELD3: 18.569,
    FIELD4: 20.305,
    FIELD5: 22.425,
    FIELD6: 24.779,
    FIELD7: 26.458
  },
  15.3: {
    FIELD1: 16.587,
    FIELD2: 17.287,
    FIELD3: 18.608,
    FIELD4: 20.35,
    FIELD5: 22.476,
    FIELD6: 24.836,
    FIELD7: 26.517
  },
  15.4: {
    FIELD1: 16.617,
    FIELD2: 17.32,
    FIELD3: 18.645,
    FIELD4: 20.393,
    FIELD5: 22.525,
    FIELD6: 24.89,
    FIELD7: 26.574
  },
  15.5: {
    FIELD1: 16.646,
    FIELD2: 17.352,
    FIELD3: 18.682,
    FIELD4: 20.436,
    FIELD5: 22.573,
    FIELD6: 24.943,
    FIELD7: 26.629
  },
  15.6: {
    FIELD1: 16.674,
    FIELD2: 17.382,
    FIELD3: 18.717,
    FIELD4: 20.477,
    FIELD5: 22.62,
    FIELD6: 24.995,
    FIELD7: 26.683
  },
  15.7: {
    FIELD1: 16.701,
    FIELD2: 17.412,
    FIELD3: 18.752,
    FIELD4: 20.517,
    FIELD5: 22.666,
    FIELD6: 25.045,
    FIELD7: 26.735
  },
  15.8: {
    FIELD1: 16.727,
    FIELD2: 17.441,
    FIELD3: 18.785,
    FIELD4: 20.556,
    FIELD5: 22.71,
    FIELD6: 25.094,
    FIELD7: 26.785
  },
  15.9: {
    FIELD1: 16.752,
    FIELD2: 17.469,
    FIELD3: 18.818,
    FIELD4: 20.594,
    FIELD5: 22.754,
    FIELD6: 25.141,
    FIELD7: 26.834
  },
  '15.10': {
    FIELD1: 16.777,
    FIELD2: 17.496,
    FIELD3: 18.849,
    FIELD4: 20.631,
    FIELD5: 22.795,
    FIELD6: 25.187,
    FIELD7: 26.882
  },
  15.11: {
    FIELD1: 16.8,
    FIELD2: 17.522,
    FIELD3: 18.88,
    FIELD4: 20.666,
    FIELD5: 22.836,
    FIELD6: 25.231,
    FIELD7: 26.928
  },
  '16.0': {
    FIELD1: 16.823,
    FIELD2: 17.547,
    FIELD3: 18.909,
    FIELD4: 20.701,
    FIELD5: 22.876,
    FIELD6: 25.274,
    FIELD7: 26.972
  },
  16.1: {
    FIELD1: 16.845,
    FIELD2: 17.571,
    FIELD3: 18.938,
    FIELD4: 20.734,
    FIELD5: 22.914,
    FIELD6: 25.316,
    FIELD7: 27.015
  },
  16.2: {
    FIELD1: 16.866,
    FIELD2: 17.595,
    FIELD3: 18.966,
    FIELD4: 20.767,
    FIELD5: 22.951,
    FIELD6: 25.356,
    FIELD7: 27.056
  },
  16.3: {
    FIELD1: 16.886,
    FIELD2: 17.617,
    FIELD3: 18.992,
    FIELD4: 20.798,
    FIELD5: 22.987,
    FIELD6: 25.395,
    FIELD7: 27.096
  },
  16.4: {
    FIELD1: 16.905,
    FIELD2: 17.639,
    FIELD3: 19.018,
    FIELD4: 20.829,
    FIELD5: 23.021,
    FIELD6: 25.433,
    FIELD7: 27.134
  },
  16.5: {
    FIELD1: 16.924,
    FIELD2: 17.66,
    FIELD3: 19.043,
    FIELD4: 20.858,
    FIELD5: 23.055,
    FIELD6: 25.469,
    FIELD7: 27.171
  },
  16.6: {
    FIELD1: 16.941,
    FIELD2: 17.679,
    FIELD3: 19.067,
    FIELD4: 20.886,
    FIELD5: 23.087,
    FIELD6: 25.504,
    FIELD7: 27.207
  },
  16.7: {
    FIELD1: 16.958,
    FIELD2: 17.699,
    FIELD3: 19.09,
    FIELD4: 20.914,
    FIELD5: 23.119,
    FIELD6: 25.538,
    FIELD7: 27.241
  },
  16.8: {
    FIELD1: 16.974,
    FIELD2: 17.717,
    FIELD3: 19.112,
    FIELD4: 20.94,
    FIELD5: 23.149,
    FIELD6: 25.57,
    FIELD7: 27.273
  },
  16.9: {
    FIELD1: 16.989,
    FIELD2: 17.734,
    FIELD3: 19.133,
    FIELD4: 20.966,
    FIELD5: 23.178,
    FIELD6: 25.601,
    FIELD7: 27.305
  },
  '16.10': {
    FIELD1: 17.004,
    FIELD2: 17.751,
    FIELD3: 19.154,
    FIELD4: 20.99,
    FIELD5: 23.206,
    FIELD6: 25.631,
    FIELD7: 27.335
  },
  16.11: {
    FIELD1: 17.018,
    FIELD2: 17.767,
    FIELD3: 19.173,
    FIELD4: 21.014,
    FIELD5: 23.233,
    FIELD6: 25.66,
    FIELD7: 27.364
  },
  '17.0': {
    FIELD1: 17.031,
    FIELD2: 17.783,
    FIELD3: 19.193,
    FIELD4: 21.037,
    FIELD5: 23.259,
    FIELD6: 25.688,
    FIELD7: 27.392
  },
  17.1: {
    FIELD1: 17.044,
    FIELD2: 17.797,
    FIELD3: 19.211,
    FIELD4: 21.059,
    FIELD5: 23.285,
    FIELD6: 25.715,
    FIELD7: 27.418
  },
  17.2: {
    FIELD1: 17.056,
    FIELD2: 17.811,
    FIELD3: 19.228,
    FIELD4: 21.08,
    FIELD5: 23.309,
    FIELD6: 25.741,
    FIELD7: 27.444
  },
  17.3: {
    FIELD1: 17.067,
    FIELD2: 17.825,
    FIELD3: 19.245,
    FIELD4: 21.101,
    FIELD5: 23.333,
    FIELD6: 25.766,
    FIELD7: 27.469
  },
  17.4: {
    FIELD1: 17.078,
    FIELD2: 17.838,
    FIELD3: 19.262,
    FIELD4: 21.121,
    FIELD5: 23.355,
    FIELD6: 25.79,
    FIELD7: 27.492
  },
  17.5: {
    FIELD1: 17.088,
    FIELD2: 17.85,
    FIELD3: 19.277,
    FIELD4: 21.14,
    FIELD5: 23.378,
    FIELD6: 25.813,
    FIELD7: 27.515
  },
  17.6: {
    FIELD1: 17.098,
    FIELD2: 17.862,
    FIELD3: 19.292,
    FIELD4: 21.159,
    FIELD5: 23.399,
    FIELD6: 25.836,
    FIELD7: 27.537
  },
  17.7: {
    FIELD1: 17.108,
    FIELD2: 17.873,
    FIELD3: 19.307,
    FIELD4: 21.177,
    FIELD5: 23.42,
    FIELD6: 25.857,
    FIELD7: 27.558
  },
  17.8: {
    FIELD1: 17.116,
    FIELD2: 17.884,
    FIELD3: 19.321,
    FIELD4: 21.194,
    FIELD5: 23.44,
    FIELD6: 25.878,
    FIELD7: 27.578
  },
  17.9: {
    FIELD1: 17.125,
    FIELD2: 17.895,
    FIELD3: 19.335,
    FIELD4: 21.212,
    FIELD5: 23.46,
    FIELD6: 25.899,
    FIELD7: 27.598
  },
  '17.10': {
    FIELD1: 17.133,
    FIELD2: 17.905,
    FIELD3: 19.348,
    FIELD4: 21.228,
    FIELD5: 23.479,
    FIELD6: 25.919,
    FIELD7: 27.617
  },
  17.11: {
    FIELD1: 17.141,
    FIELD2: 17.915,
    FIELD3: 19.361,
    FIELD4: 21.244,
    FIELD5: 23.498,
    FIELD6: 25.938,
    FIELD7: 27.635
  },
  '18.0': {
    FIELD1: 17.149,
    FIELD2: 17.924,
    FIELD3: 19.374,
    FIELD4: 21.26,
    FIELD5: 23.516,
    FIELD6: 25.957,
    FIELD7: 27.653
  },
  18.1: {
    FIELD1: 17.156,
    FIELD2: 17.933,
    FIELD3: 19.386,
    FIELD4: 21.276,
    FIELD5: 23.534,
    FIELD6: 25.975,
    FIELD7: 27.671
  },
  18.2: {
    FIELD1: 17.163,
    FIELD2: 17.942,
    FIELD3: 19.398,
    FIELD4: 21.291,
    FIELD5: 23.551,
    FIELD6: 25.993,
    FIELD7: 27.687
  },
  18.3: {
    FIELD1: 17.169,
    FIELD2: 17.95,
    FIELD3: 19.41,
    FIELD4: 21.306,
    FIELD5: 23.568,
    FIELD6: 26.01,
    FIELD7: 27.704
  },
  18.4: {
    FIELD1: 17.176,
    FIELD2: 17.959,
    FIELD3: 19.421,
    FIELD4: 21.32,
    FIELD5: 23.585,
    FIELD6: 26.027,
    FIELD7: 27.719
  },
  18.5: {
    FIELD1: 17.182,
    FIELD2: 17.967,
    FIELD3: 19.432,
    FIELD4: 21.334,
    FIELD5: 23.601,
    FIELD6: 26.043,
    FIELD7: 27.734
  },
  18.6: {
    FIELD1: 17.188,
    FIELD2: 17.975,
    FIELD3: 19.443,
    FIELD4: 21.348,
    FIELD5: 23.617,
    FIELD6: 26.059,
    FIELD7: 27.749
  },
  18.7: {
    FIELD1: 17.193,
    FIELD2: 17.982,
    FIELD3: 19.454,
    FIELD4: 21.362,
    FIELD5: 23.633,
    FIELD6: 26.076,
    FIELD7: 27.764
  },
  18.8: {
    FIELD1: 17.199,
    FIELD2: 17.99,
    FIELD3: 19.464,
    FIELD4: 21.375,
    FIELD5: 23.648,
    FIELD6: 26.091,
    FIELD7: 27.779
  },
  18.9: {
    FIELD1: 17.204,
    FIELD2: 17.997,
    FIELD3: 19.475,
    FIELD4: 21.388,
    FIELD5: 23.663,
    FIELD6: 26.106,
    FIELD7: 27.793
  },
  '18.10': {
    FIELD1: 17.21,
    FIELD2: 18.004,
    FIELD3: 19.485,
    FIELD4: 21.401,
    FIELD5: 23.678,
    FIELD6: 26.121,
    FIELD7: 27.807
  },
  18.11: {
    FIELD1: 17.215,
    FIELD2: 18.011,
    FIELD3: 19.495,
    FIELD4: 21.414,
    FIELD5: 23.693,
    FIELD6: 26.136,
    FIELD7: 27.82
  },
  '19.0': {
    FIELD1: 17.22,
    FIELD2: 18.017,
    FIELD3: 19.504,
    FIELD4: 21.427,
    FIELD5: 23.707,
    FIELD6: 26.151,
    FIELD7: 27.833
  }
};

export const BoyBMI: any = {
  '0.0': {
    FIELD1: 11.5,
    FIELD2: 11.9,
    FIELD3: 12.6,
    FIELD4: 13.4,
    FIELD5: 14.3,
    FIELD6: 15.2,
    FIELD7: 15.8
  },
  0.1: {
    FIELD1: 12.8,
    FIELD2: 13.3,
    FIELD3: 14.1,
    FIELD4: 14.9,
    FIELD5: 15.9,
    FIELD6: 16.7,
    FIELD7: 17.3
  },
  0.2: {
    FIELD1: 14.1,
    FIELD2: 14.6,
    FIELD3: 15.4,
    FIELD4: 16.3,
    FIELD5: 17.3,
    FIELD6: 18.2,
    FIELD7: 18.8
  },
  0.3: {
    FIELD1: 14.7,
    FIELD2: 15.2,
    FIELD3: 16,
    FIELD4: 16.9,
    FIELD5: 17.9,
    FIELD6: 18.8,
    FIELD7: 19.4
  },
  0.4: {
    FIELD1: 15,
    FIELD2: 15.4,
    FIELD3: 16.2,
    FIELD4: 17.2,
    FIELD5: 18.2,
    FIELD6: 19.1,
    FIELD7: 19.7
  },
  0.5: {
    FIELD1: 15.1,
    FIELD2: 15.6,
    FIELD3: 16.4,
    FIELD4: 17.3,
    FIELD5: 18.3,
    FIELD6: 19.2,
    FIELD7: 19.8
  },
  0.6: {
    FIELD1: 15.2,
    FIELD2: 15.6,
    FIELD3: 16.4,
    FIELD4: 17.3,
    FIELD5: 18.3,
    FIELD6: 19.3,
    FIELD7: 19.9
  },
  0.7: {
    FIELD1: 15.2,
    FIELD2: 15.6,
    FIELD3: 16.4,
    FIELD4: 17.3,
    FIELD5: 18.3,
    FIELD6: 19.3,
    FIELD7: 19.9
  },
  0.8: {
    FIELD1: 15.1,
    FIELD2: 15.6,
    FIELD3: 16.3,
    FIELD4: 17.3,
    FIELD5: 18.2,
    FIELD6: 19.2,
    FIELD7: 19.8
  },
  0.9: {
    FIELD1: 15.1,
    FIELD2: 15.5,
    FIELD3: 16.3,
    FIELD4: 17.2,
    FIELD5: 18.1,
    FIELD6: 19.1,
    FIELD7: 19.7
  },
  '0.10': {
    FIELD1: 15,
    FIELD2: 15.4,
    FIELD3: 16.2,
    FIELD4: 17,
    FIELD5: 18,
    FIELD6: 18.9,
    FIELD7: 19.5
  },
  0.11: {
    FIELD1: 14.9,
    FIELD2: 15.3,
    FIELD3: 16,
    FIELD4: 16.9,
    FIELD5: 17.9,
    FIELD6: 18.8,
    FIELD7: 19.4
  },
  '1.0': {
    FIELD1: 14.8,
    FIELD2: 15.2,
    FIELD3: 15.9,
    FIELD4: 16.8,
    FIELD5: 17.7,
    FIELD6: 18.7,
    FIELD7: 19.2
  },
  1.1: {
    FIELD1: 14.7,
    FIELD2: 15.1,
    FIELD3: 15.8,
    FIELD4: 16.7,
    FIELD5: 17.6,
    FIELD6: 18.5,
    FIELD7: 19.1
  },
  1.2: {
    FIELD1: 14.6,
    FIELD2: 15,
    FIELD3: 15.7,
    FIELD4: 16.6,
    FIELD5: 17.5,
    FIELD6: 18.4,
    FIELD7: 18.9
  },
  1.3: {
    FIELD1: 14.5,
    FIELD2: 14.9,
    FIELD3: 15.6,
    FIELD4: 16.4,
    FIELD5: 17.4,
    FIELD6: 18.2,
    FIELD7: 18.8
  },
  1.4: {
    FIELD1: 14.4,
    FIELD2: 14.8,
    FIELD3: 15.5,
    FIELD4: 16.3,
    FIELD5: 17.2,
    FIELD6: 18.1,
    FIELD7: 18.7
  },
  1.5: {
    FIELD1: 14.3,
    FIELD2: 14.7,
    FIELD3: 15.4,
    FIELD4: 16.2,
    FIELD5: 17.1,
    FIELD6: 18,
    FIELD7: 18.6
  },
  1.6: {
    FIELD1: 14.2,
    FIELD2: 14.6,
    FIELD3: 15.3,
    FIELD4: 16.1,
    FIELD5: 17,
    FIELD6: 17.9,
    FIELD7: 18.5
  },
  1.7: {
    FIELD1: 14.2,
    FIELD2: 14.6,
    FIELD3: 15.2,
    FIELD4: 16.1,
    FIELD5: 16.9,
    FIELD6: 17.8,
    FIELD7: 18.4
  },
  1.8: {
    FIELD1: 14.1,
    FIELD2: 14.5,
    FIELD3: 15.2,
    FIELD4: 16,
    FIELD5: 16.9,
    FIELD6: 17.7,
    FIELD7: 18.3
  },
  1.9: {
    FIELD1: 14.1,
    FIELD2: 14.4,
    FIELD3: 15.1,
    FIELD4: 15.9,
    FIELD5: 16.8,
    FIELD6: 17.6,
    FIELD7: 18.2
  },
  '1.10': {
    FIELD1: 14,
    FIELD2: 14.4,
    FIELD3: 15,
    FIELD4: 15.8,
    FIELD5: 16.7,
    FIELD6: 17.6,
    FIELD7: 18.1
  },
  1.11: {
    FIELD1: 14,
    FIELD2: 14.3,
    FIELD3: 15,
    FIELD4: 15.8,
    FIELD5: 16.7,
    FIELD6: 17.5,
    FIELD7: 18
  },
  '2.0': {
    FIELD1: 14.2,
    FIELD2: 14.5,
    FIELD3: 15.2,
    FIELD4: 16,
    FIELD5: 16.9,
    FIELD6: 17.8,
    FIELD7: 18.3
  },
  2.1: {
    FIELD1: 14.1,
    FIELD2: 14.5,
    FIELD3: 15.2,
    FIELD4: 16,
    FIELD5: 16.9,
    FIELD6: 17.7,
    FIELD7: 18.3
  },
  2.2: {
    FIELD1: 14.1,
    FIELD2: 14.5,
    FIELD3: 15.1,
    FIELD4: 15.9,
    FIELD5: 16.8,
    FIELD6: 17.7,
    FIELD7: 18.2
  },
  2.3: {
    FIELD1: 14,
    FIELD2: 14.4,
    FIELD3: 15.1,
    FIELD4: 15.9,
    FIELD5: 16.8,
    FIELD6: 17.6,
    FIELD7: 18.2
  },
  2.4: {
    FIELD1: 14,
    FIELD2: 14.4,
    FIELD3: 15.1,
    FIELD4: 15.9,
    FIELD5: 16.7,
    FIELD6: 17.6,
    FIELD7: 18.1
  },
  2.5: {
    FIELD1: 14,
    FIELD2: 14.4,
    FIELD3: 15,
    FIELD4: 15.8,
    FIELD5: 16.7,
    FIELD6: 17.5,
    FIELD7: 18.1
  },
  2.6: {
    FIELD1: 13.9,
    FIELD2: 14.3,
    FIELD3: 15,
    FIELD4: 15.8,
    FIELD5: 16.7,
    FIELD6: 17.5,
    FIELD7: 18
  },
  2.7: {
    FIELD1: 13.9,
    FIELD2: 14.3,
    FIELD3: 15,
    FIELD4: 15.8,
    FIELD5: 16.6,
    FIELD6: 17.5,
    FIELD7: 18
  },
  2.8: {
    FIELD1: 13.9,
    FIELD2: 14.2,
    FIELD3: 14.9,
    FIELD4: 15.7,
    FIELD5: 16.6,
    FIELD6: 17.4,
    FIELD7: 18
  },
  2.9: {
    FIELD1: 13.8,
    FIELD2: 14.2,
    FIELD3: 14.9,
    FIELD4: 15.7,
    FIELD5: 16.6,
    FIELD6: 17.4,
    FIELD7: 17.9
  },
  '2.10': {
    FIELD1: 13.8,
    FIELD2: 14.2,
    FIELD3: 14.9,
    FIELD4: 15.7,
    FIELD5: 16.5,
    FIELD6: 17.4,
    FIELD7: 17.9
  },
  2.11: {
    FIELD1: 13.8,
    FIELD2: 14.1,
    FIELD3: 14.8,
    FIELD4: 15.6,
    FIELD5: 16.5,
    FIELD6: 17.3,
    FIELD7: 17.9
  },
  '3.0': {
    FIELD1: 13.7,
    FIELD2: 14.1,
    FIELD3: 14.8,
    FIELD4: 15.6,
    FIELD5: 16.5,
    FIELD6: 17.3,
    FIELD7: 17.8
  },
  3.1: {
    FIELD1: 13.7,
    FIELD2: 14.1,
    FIELD3: 14.8,
    FIELD4: 15.6,
    FIELD5: 16.4,
    FIELD6: 17.3,
    FIELD7: 17.8
  },
  3.2: {
    FIELD1: 13.7,
    FIELD2: 14.1,
    FIELD3: 14.7,
    FIELD4: 15.5,
    FIELD5: 16.4,
    FIELD6: 17.2,
    FIELD7: 17.8
  },
  3.3: {
    FIELD1: 13.6,
    FIELD2: 14,
    FIELD3: 14.7,
    FIELD4: 15.5,
    FIELD5: 16.4,
    FIELD6: 17.2,
    FIELD7: 17.7
  },
  3.4: {
    FIELD1: 13.6,
    FIELD2: 14,
    FIELD3: 14.7,
    FIELD4: 15.5,
    FIELD5: 16.4,
    FIELD6: 17.2,
    FIELD7: 17.7
  },
  3.5: {
    FIELD1: 13.6,
    FIELD2: 14,
    FIELD3: 14.7,
    FIELD4: 15.5,
    FIELD5: 16.3,
    FIELD6: 17.2,
    FIELD7: 17.7
  },
  3.6: {
    FIELD1: 13.6,
    FIELD2: 13.9,
    FIELD3: 14.6,
    FIELD4: 15.4,
    FIELD5: 16.3,
    FIELD6: 17.1,
    FIELD7: 17.7
  },
  3.7: {
    FIELD1: 13.5,
    FIELD2: 13.9,
    FIELD3: 14.6,
    FIELD4: 15.4,
    FIELD5: 16.3,
    FIELD6: 17.1,
    FIELD7: 17.7
  },
  3.8: {
    FIELD1: 13.5,
    FIELD2: 13.9,
    FIELD3: 14.6,
    FIELD4: 15.4,
    FIELD5: 16.3,
    FIELD6: 17.1,
    FIELD7: 17.7
  },
  3.9: {
    FIELD1: 13.5,
    FIELD2: 13.9,
    FIELD3: 14.6,
    FIELD4: 15.4,
    FIELD5: 16.3,
    FIELD6: 17.1,
    FIELD7: 17.6
  },
  '3.10': {
    FIELD1: 13.5,
    FIELD2: 13.9,
    FIELD3: 14.5,
    FIELD4: 15.4,
    FIELD5: 16.2,
    FIELD6: 17.1,
    FIELD7: 17.6
  },
  3.11: {
    FIELD1: 13.5,
    FIELD2: 13.8,
    FIELD3: 14.5,
    FIELD4: 15.3,
    FIELD5: 16.2,
    FIELD6: 17.1,
    FIELD7: 17.6
  },
  '4.0': {
    FIELD1: 13.4,
    FIELD2: 13.8,
    FIELD3: 14.5,
    FIELD4: 15.3,
    FIELD5: 16.2,
    FIELD6: 17.1,
    FIELD7: 17.6
  },
  4.1: {
    FIELD1: 13.4,
    FIELD2: 13.8,
    FIELD3: 14.5,
    FIELD4: 15.3,
    FIELD5: 16.2,
    FIELD6: 17.1,
    FIELD7: 17.6
  },
  4.2: {
    FIELD1: 13.4,
    FIELD2: 13.8,
    FIELD3: 14.5,
    FIELD4: 15.3,
    FIELD5: 16.2,
    FIELD6: 17.1,
    FIELD7: 17.6
  },
  4.3: {
    FIELD1: 13.4,
    FIELD2: 13.8,
    FIELD3: 14.5,
    FIELD4: 15.3,
    FIELD5: 16.2,
    FIELD6: 17.1,
    FIELD7: 17.6
  },
  4.4: {
    FIELD1: 13.4,
    FIELD2: 13.8,
    FIELD3: 14.4,
    FIELD4: 15.3,
    FIELD5: 16.2,
    FIELD6: 17.1,
    FIELD7: 17.6
  },
  4.5: {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.3,
    FIELD5: 16.2,
    FIELD6: 17.1,
    FIELD7: 17.6
  },
  4.6: {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.3,
    FIELD5: 16.2,
    FIELD6: 17,
    FIELD7: 17.6
  },
  4.7: {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.2,
    FIELD5: 16.2,
    FIELD6: 17,
    FIELD7: 17.6
  },
  4.8: {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.2,
    FIELD5: 16.1,
    FIELD6: 17,
    FIELD7: 17.6
  },
  4.9: {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.2,
    FIELD5: 16.1,
    FIELD6: 17.1,
    FIELD7: 17.6
  },
  '4.10': {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.2,
    FIELD5: 16.1,
    FIELD6: 17.1,
    FIELD7: 17.6
  },
  4.11: {
    FIELD1: 13.3,
    FIELD2: 13.7,
    FIELD3: 14.4,
    FIELD4: 15.2,
    FIELD5: 16.1,
    FIELD6: 17.1,
    FIELD7: 17.7
  },
  '5.0': {
    FIELD1: 13.3,
    FIELD2: 13.6,
    FIELD3: 14.3,
    FIELD4: 15.2,
    FIELD5: 16.1,
    FIELD6: 17.1,
    FIELD7: 17.7
  },
  5.1: {
    FIELD1: 13.384,
    FIELD2: 13.764,
    FIELD3: 14.441,
    FIELD4: 15.264,
    FIELD5: 16.172,
    FIELD6: 17.074,
    FIELD7: 17.656
  },
  5.2: {
    FIELD1: 13.38,
    FIELD2: 13.759,
    FIELD3: 14.437,
    FIELD4: 15.262,
    FIELD5: 16.173,
    FIELD6: 17.079,
    FIELD7: 17.665
  },
  5.3: {
    FIELD1: 13.377,
    FIELD2: 13.756,
    FIELD3: 14.434,
    FIELD4: 15.26,
    FIELD5: 16.175,
    FIELD6: 17.086,
    FIELD7: 17.677
  },
  5.4: {
    FIELD1: 13.374,
    FIELD2: 13.754,
    FIELD3: 14.432,
    FIELD4: 15.26,
    FIELD5: 16.179,
    FIELD6: 17.095,
    FIELD7: 17.689
  },
  5.5: {
    FIELD1: 13.373,
    FIELD2: 13.752,
    FIELD3: 14.432,
    FIELD4: 15.262,
    FIELD5: 16.184,
    FIELD6: 17.106,
    FIELD7: 17.704
  },
  5.6: {
    FIELD1: 13.373,
    FIELD2: 13.752,
    FIELD3: 14.432,
    FIELD4: 15.264,
    FIELD5: 16.191,
    FIELD6: 17.118,
    FIELD7: 17.721
  },
  5.7: {
    FIELD1: 13.374,
    FIELD2: 13.753,
    FIELD3: 14.434,
    FIELD4: 15.268,
    FIELD5: 16.198,
    FIELD6: 17.131,
    FIELD7: 17.739
  },
  5.8: {
    FIELD1: 13.376,
    FIELD2: 13.755,
    FIELD3: 14.437,
    FIELD4: 15.274,
    FIELD5: 16.208,
    FIELD6: 17.146,
    FIELD7: 17.759
  },
  5.9: {
    FIELD1: 13.379,
    FIELD2: 13.758,
    FIELD3: 14.441,
    FIELD4: 15.28,
    FIELD5: 16.218,
    FIELD6: 17.163,
    FIELD7: 17.78
  },
  '5.10': {
    FIELD1: 13.382,
    FIELD2: 13.762,
    FIELD3: 14.446,
    FIELD4: 15.288,
    FIELD5: 16.23,
    FIELD6: 17.181,
    FIELD7: 17.804
  },
  5.11: {
    FIELD1: 13.387,
    FIELD2: 13.767,
    FIELD3: 14.452,
    FIELD4: 15.296,
    FIELD5: 16.244,
    FIELD6: 17.201,
    FIELD7: 17.828
  },
  '6.0': {
    FIELD1: 13.393,
    FIELD2: 13.773,
    FIELD3: 14.459,
    FIELD4: 15.306,
    FIELD5: 16.258,
    FIELD6: 17.221,
    FIELD7: 17.854
  },
  6.1: {
    FIELD1: 13.399,
    FIELD2: 13.78,
    FIELD3: 14.467,
    FIELD4: 15.317,
    FIELD5: 16.273,
    FIELD6: 17.244,
    FIELD7: 17.882
  },
  6.2: {
    FIELD1: 13.406,
    FIELD2: 13.787,
    FIELD3: 14.476,
    FIELD4: 15.328,
    FIELD5: 16.29,
    FIELD6: 17.267,
    FIELD7: 17.911
  },
  6.3: {
    FIELD1: 13.413,
    FIELD2: 13.795,
    FIELD3: 14.485,
    FIELD4: 15.341,
    FIELD5: 16.307,
    FIELD6: 17.291,
    FIELD7: 17.941
  },
  6.4: {
    FIELD1: 13.422,
    FIELD2: 13.804,
    FIELD3: 14.495,
    FIELD4: 15.354,
    FIELD5: 16.326,
    FIELD6: 17.317,
    FIELD7: 17.972
  },
  6.5: {
    FIELD1: 13.43,
    FIELD2: 13.813,
    FIELD3: 14.506,
    FIELD4: 15.368,
    FIELD5: 16.345,
    FIELD6: 17.343,
    FIELD7: 18.004
  },
  6.6: {
    FIELD1: 13.439,
    FIELD2: 13.823,
    FIELD3: 14.518,
    FIELD4: 15.382,
    FIELD5: 16.365,
    FIELD6: 17.37,
    FIELD7: 18.038
  },
  6.7: {
    FIELD1: 13.449,
    FIELD2: 13.833,
    FIELD3: 14.529,
    FIELD4: 15.398,
    FIELD5: 16.386,
    FIELD6: 17.399,
    FIELD7: 18.073
  },
  6.8: {
    FIELD1: 13.459,
    FIELD2: 13.843,
    FIELD3: 14.542,
    FIELD4: 15.414,
    FIELD5: 16.407,
    FIELD6: 17.428,
    FIELD7: 18.108
  },
  6.9: {
    FIELD1: 13.47,
    FIELD2: 13.855,
    FIELD3: 14.555,
    FIELD4: 15.43,
    FIELD5: 16.429,
    FIELD6: 17.458,
    FIELD7: 18.145
  },
  '6.10': {
    FIELD1: 13.481,
    FIELD2: 13.866,
    FIELD3: 14.569,
    FIELD4: 15.447,
    FIELD5: 16.452,
    FIELD6: 17.489,
    FIELD7: 18.183
  },
  6.11: {
    FIELD1: 13.492,
    FIELD2: 13.878,
    FIELD3: 14.582,
    FIELD4: 15.465,
    FIELD5: 16.476,
    FIELD6: 17.521,
    FIELD7: 18.221
  },
  '7.0': {
    FIELD1: 13.504,
    FIELD2: 13.891,
    FIELD3: 14.597,
    FIELD4: 15.483,
    FIELD5: 16.5,
    FIELD6: 17.554,
    FIELD7: 18.261
  },
  7.1: {
    FIELD1: 13.516,
    FIELD2: 13.904,
    FIELD3: 14.612,
    FIELD4: 15.502,
    FIELD5: 16.525,
    FIELD6: 17.588,
    FIELD7: 18.301
  },
  7.2: {
    FIELD1: 13.528,
    FIELD2: 13.917,
    FIELD3: 14.627,
    FIELD4: 15.521,
    FIELD5: 16.55,
    FIELD6: 17.622,
    FIELD7: 18.343
  },
  7.3: {
    FIELD1: 13.54,
    FIELD2: 13.93,
    FIELD3: 14.643,
    FIELD4: 15.541,
    FIELD5: 16.577,
    FIELD6: 17.657,
    FIELD7: 18.385
  },
  7.4: {
    FIELD1: 13.553,
    FIELD2: 13.944,
    FIELD3: 14.659,
    FIELD4: 15.561,
    FIELD5: 16.603,
    FIELD6: 17.692,
    FIELD7: 18.428
  },
  7.5: {
    FIELD1: 13.567,
    FIELD2: 13.958,
    FIELD3: 14.675,
    FIELD4: 15.581,
    FIELD5: 16.631,
    FIELD6: 17.729,
    FIELD7: 18.472
  },
  7.6: {
    FIELD1: 13.58,
    FIELD2: 13.972,
    FIELD3: 14.692,
    FIELD4: 15.602,
    FIELD5: 16.658,
    FIELD6: 17.766,
    FIELD7: 18.517
  },
  7.7: {
    FIELD1: 13.594,
    FIELD2: 13.987,
    FIELD3: 14.709,
    FIELD4: 15.624,
    FIELD5: 16.686,
    FIELD6: 17.803,
    FIELD7: 18.562
  },
  7.8: {
    FIELD1: 13.608,
    FIELD2: 14.002,
    FIELD3: 14.726,
    FIELD4: 15.646,
    FIELD5: 16.715,
    FIELD6: 17.842,
    FIELD7: 18.609
  },
  7.9: {
    FIELD1: 13.622,
    FIELD2: 14.017,
    FIELD3: 14.744,
    FIELD4: 15.668,
    FIELD5: 16.744,
    FIELD6: 17.881,
    FIELD7: 18.656
  },
  '7.10': {
    FIELD1: 13.636,
    FIELD2: 14.033,
    FIELD3: 14.762,
    FIELD4: 15.69,
    FIELD5: 16.774,
    FIELD6: 17.921,
    FIELD7: 18.704
  },
  7.11: {
    FIELD1: 13.651,
    FIELD2: 14.049,
    FIELD3: 14.781,
    FIELD4: 15.713,
    FIELD5: 16.804,
    FIELD6: 17.961,
    FIELD7: 18.753
  },
  '8.0': {
    FIELD1: 13.666,
    FIELD2: 14.065,
    FIELD3: 14.799,
    FIELD4: 15.737,
    FIELD5: 16.835,
    FIELD6: 18.002,
    FIELD7: 18.802
  },
  8.1: {
    FIELD1: 13.681,
    FIELD2: 14.081,
    FIELD3: 14.819,
    FIELD4: 15.761,
    FIELD5: 16.867,
    FIELD6: 18.044,
    FIELD7: 18.852
  },
  8.2: {
    FIELD1: 13.697,
    FIELD2: 14.098,
    FIELD3: 14.838,
    FIELD4: 15.785,
    FIELD5: 16.898,
    FIELD6: 18.086,
    FIELD7: 18.904
  },
  8.3: {
    FIELD1: 13.712,
    FIELD2: 14.115,
    FIELD3: 14.858,
    FIELD4: 15.809,
    FIELD5: 16.931,
    FIELD6: 18.129,
    FIELD7: 18.956
  },
  8.4: {
    FIELD1: 13.728,
    FIELD2: 14.132,
    FIELD3: 14.878,
    FIELD4: 15.834,
    FIELD5: 16.963,
    FIELD6: 18.172,
    FIELD7: 19.008
  },
  8.5: {
    FIELD1: 13.745,
    FIELD2: 14.149,
    FIELD3: 14.898,
    FIELD4: 15.86,
    FIELD5: 16.996,
    FIELD6: 18.216,
    FIELD7: 19.061
  },
  8.6: {
    FIELD1: 13.761,
    FIELD2: 14.167,
    FIELD3: 14.919,
    FIELD4: 15.886,
    FIELD5: 17.03,
    FIELD6: 18.261,
    FIELD7: 19.115
  },
  8.7: {
    FIELD1: 13.778,
    FIELD2: 14.185,
    FIELD3: 14.94,
    FIELD4: 15.912,
    FIELD5: 17.064,
    FIELD6: 18.306,
    FIELD7: 19.17
  },
  8.8: {
    FIELD1: 13.795,
    FIELD2: 14.203,
    FIELD3: 14.961,
    FIELD4: 15.938,
    FIELD5: 17.099,
    FIELD6: 18.352,
    FIELD7: 19.225
  },
  8.9: {
    FIELD1: 13.812,
    FIELD2: 14.222,
    FIELD3: 14.983,
    FIELD4: 15.965,
    FIELD5: 17.134,
    FIELD6: 18.399,
    FIELD7: 19.281
  },
  '8.10': {
    FIELD1: 13.83,
    FIELD2: 14.241,
    FIELD3: 15.005,
    FIELD4: 15.992,
    FIELD5: 17.17,
    FIELD6: 18.446,
    FIELD7: 19.338
  },
  8.11: {
    FIELD1: 13.848,
    FIELD2: 14.26,
    FIELD3: 15.028,
    FIELD4: 16.02,
    FIELD5: 17.206,
    FIELD6: 18.493,
    FIELD7: 19.395
  },
  '9.0': {
    FIELD1: 13.866,
    FIELD2: 14.28,
    FIELD3: 15.051,
    FIELD4: 16.049,
    FIELD5: 17.243,
    FIELD6: 18.542,
    FIELD7: 19.453
  },
  9.1: {
    FIELD1: 13.885,
    FIELD2: 14.3,
    FIELD3: 15.074,
    FIELD4: 16.078,
    FIELD5: 17.28,
    FIELD6: 18.591,
    FIELD7: 19.513
  },
  9.2: {
    FIELD1: 13.904,
    FIELD2: 14.321,
    FIELD3: 15.098,
    FIELD4: 16.108,
    FIELD5: 17.319,
    FIELD6: 18.641,
    FIELD7: 19.573
  },
  9.3: {
    FIELD1: 13.924,
    FIELD2: 14.343,
    FIELD3: 15.123,
    FIELD4: 16.138,
    FIELD5: 17.357,
    FIELD6: 18.692,
    FIELD7: 19.634
  },
  9.4: {
    FIELD1: 13.944,
    FIELD2: 14.364,
    FIELD3: 15.149,
    FIELD4: 16.169,
    FIELD5: 17.397,
    FIELD6: 18.744,
    FIELD7: 19.696
  },
  9.5: {
    FIELD1: 13.965,
    FIELD2: 14.387,
    FIELD3: 15.175,
    FIELD4: 16.201,
    FIELD5: 17.438,
    FIELD6: 18.797,
    FIELD7: 19.759
  },
  9.6: {
    FIELD1: 13.986,
    FIELD2: 14.41,
    FIELD3: 15.201,
    FIELD4: 16.233,
    FIELD5: 17.479,
    FIELD6: 18.85,
    FIELD7: 19.823
  },
  9.7: {
    FIELD1: 14.008,
    FIELD2: 14.433,
    FIELD3: 15.228,
    FIELD4: 16.266,
    FIELD5: 17.521,
    FIELD6: 18.905,
    FIELD7: 19.887
  },
  9.8: {
    FIELD1: 14.031,
    FIELD2: 14.458,
    FIELD3: 15.256,
    FIELD4: 16.3,
    FIELD5: 17.564,
    FIELD6: 18.96,
    FIELD7: 19.953
  },
  9.9: {
    FIELD1: 14.054,
    FIELD2: 14.483,
    FIELD3: 15.285,
    FIELD4: 16.335,
    FIELD5: 17.608,
    FIELD6: 19.016,
    FIELD7: 20.02
  },
  '9.10': {
    FIELD1: 14.078,
    FIELD2: 14.508,
    FIELD3: 15.314,
    FIELD4: 16.37,
    FIELD5: 17.652,
    FIELD6: 19.073,
    FIELD7: 20.087
  },
  9.11: {
    FIELD1: 14.102,
    FIELD2: 14.534,
    FIELD3: 15.344,
    FIELD4: 16.406,
    FIELD5: 17.697,
    FIELD6: 19.13,
    FIELD7: 20.155
  },
  '10.0': {
    FIELD1: 14.127,
    FIELD2: 14.561,
    FIELD3: 15.375,
    FIELD4: 16.443,
    FIELD5: 17.743,
    FIELD6: 19.189,
    FIELD7: 20.225
  },
  10.1: {
    FIELD1: 14.152,
    FIELD2: 14.588,
    FIELD3: 15.406,
    FIELD4: 16.481,
    FIELD5: 17.79,
    FIELD6: 19.248,
    FIELD7: 20.294
  },
  10.2: {
    FIELD1: 14.178,
    FIELD2: 14.616,
    FIELD3: 15.438,
    FIELD4: 16.519,
    FIELD5: 17.837,
    FIELD6: 19.308,
    FIELD7: 20.365
  },
  10.3: {
    FIELD1: 14.205,
    FIELD2: 14.644,
    FIELD3: 15.471,
    FIELD4: 16.558,
    FIELD5: 17.886,
    FIELD6: 19.369,
    FIELD7: 20.436
  },
  10.4: {
    FIELD1: 14.232,
    FIELD2: 14.673,
    FIELD3: 15.504,
    FIELD4: 16.597,
    FIELD5: 17.935,
    FIELD6: 19.431,
    FIELD7: 20.509
  },
  10.5: {
    FIELD1: 14.259,
    FIELD2: 14.703,
    FIELD3: 15.538,
    FIELD4: 16.638,
    FIELD5: 17.984,
    FIELD6: 19.493,
    FIELD7: 20.581
  },
  10.6: {
    FIELD1: 14.287,
    FIELD2: 14.733,
    FIELD3: 15.572,
    FIELD4: 16.679,
    FIELD5: 18.035,
    FIELD6: 19.556,
    FIELD7: 20.656
  },
  10.7: {
    FIELD1: 14.316,
    FIELD2: 14.764,
    FIELD3: 15.607,
    FIELD4: 16.72,
    FIELD5: 18.086,
    FIELD6: 19.62,
    FIELD7: 20.73
  },
  10.8: {
    FIELD1: 14.346,
    FIELD2: 14.796,
    FIELD3: 15.643,
    FIELD4: 16.763,
    FIELD5: 18.138,
    FIELD6: 19.685,
    FIELD7: 20.805
  },
  10.9: {
    FIELD1: 14.376,
    FIELD2: 14.828,
    FIELD3: 15.679,
    FIELD4: 16.806,
    FIELD5: 18.191,
    FIELD6: 19.75,
    FIELD7: 20.881
  },
  '10.10': {
    FIELD1: 14.406,
    FIELD2: 14.86,
    FIELD3: 15.717,
    FIELD4: 16.85,
    FIELD5: 18.244,
    FIELD6: 19.816,
    FIELD7: 20.958
  },
  10.11: {
    FIELD1: 14.437,
    FIELD2: 14.893,
    FIELD3: 15.754,
    FIELD4: 16.894,
    FIELD5: 18.298,
    FIELD6: 19.883,
    FIELD7: 21.035
  },
  '11.0': {
    FIELD1: 14.469,
    FIELD2: 14.927,
    FIELD3: 15.793,
    FIELD4: 16.939,
    FIELD5: 18.353,
    FIELD6: 19.95,
    FIELD7: 21.113
  },
  11.1: {
    FIELD1: 14.501,
    FIELD2: 14.962,
    FIELD3: 15.832,
    FIELD4: 16.985,
    FIELD5: 18.408,
    FIELD6: 20.018,
    FIELD7: 21.191
  },
  11.2: {
    FIELD1: 14.533,
    FIELD2: 14.996,
    FIELD3: 15.871,
    FIELD4: 17.031,
    FIELD5: 18.464,
    FIELD6: 20.087,
    FIELD7: 21.27
  },
  11.3: {
    FIELD1: 14.566,
    FIELD2: 15.032,
    FIELD3: 15.911,
    FIELD4: 17.078,
    FIELD5: 18.521,
    FIELD6: 20.156,
    FIELD7: 21.35
  },
  11.4: {
    FIELD1: 14.6,
    FIELD2: 15.068,
    FIELD3: 15.952,
    FIELD4: 17.126,
    FIELD5: 18.578,
    FIELD6: 20.226,
    FIELD7: 21.43
  },
  11.5: {
    FIELD1: 14.634,
    FIELD2: 15.105,
    FIELD3: 15.993,
    FIELD4: 17.175,
    FIELD5: 18.636,
    FIELD6: 20.296,
    FIELD7: 21.51
  },
  11.6: {
    FIELD1: 14.669,
    FIELD2: 15.142,
    FIELD3: 16.035,
    FIELD4: 17.224,
    FIELD5: 18.695,
    FIELD6: 20.367,
    FIELD7: 21.591
  },
  11.7: {
    FIELD1: 14.704,
    FIELD2: 15.179,
    FIELD3: 16.078,
    FIELD4: 17.273,
    FIELD5: 18.754,
    FIELD6: 20.439,
    FIELD7: 21.673
  },
  11.8: {
    FIELD1: 14.741,
    FIELD2: 15.218,
    FIELD3: 16.122,
    FIELD4: 17.324,
    FIELD5: 18.815,
    FIELD6: 20.512,
    FIELD7: 21.756
  },
  11.9: {
    FIELD1: 14.777,
    FIELD2: 15.257,
    FIELD3: 16.166,
    FIELD4: 17.375,
    FIELD5: 18.875,
    FIELD6: 20.585,
    FIELD7: 21.838
  },
  '11.10': {
    FIELD1: 14.814,
    FIELD2: 15.297,
    FIELD3: 16.211,
    FIELD4: 17.427,
    FIELD5: 18.937,
    FIELD6: 20.658,
    FIELD7: 21.922
  },
  11.11: {
    FIELD1: 14.852,
    FIELD2: 15.337,
    FIELD3: 16.256,
    FIELD4: 17.48,
    FIELD5: 19,
    FIELD6: 20.733,
    FIELD7: 22.006
  },
  '12.0': {
    FIELD1: 14.891,
    FIELD2: 15.378,
    FIELD3: 16.302,
    FIELD4: 17.533,
    FIELD5: 19.063,
    FIELD6: 20.808,
    FIELD7: 22.09
  },
  12.1: {
    FIELD1: 14.93,
    FIELD2: 15.42,
    FIELD3: 16.349,
    FIELD4: 17.588,
    FIELD5: 19.127,
    FIELD6: 20.884,
    FIELD7: 22.175
  },
  12.2: {
    FIELD1: 14.97,
    FIELD2: 15.463,
    FIELD3: 16.397,
    FIELD4: 17.643,
    FIELD5: 19.191,
    FIELD6: 20.96,
    FIELD7: 22.261
  },
  12.3: {
    FIELD1: 15.01,
    FIELD2: 15.506,
    FIELD3: 16.446,
    FIELD4: 17.698,
    FIELD5: 19.257,
    FIELD6: 21.037,
    FIELD7: 22.347
  },
  12.4: {
    FIELD1: 15.051,
    FIELD2: 15.55,
    FIELD3: 16.495,
    FIELD4: 17.755,
    FIELD5: 19.323,
    FIELD6: 21.115,
    FIELD7: 22.434
  },
  12.5: {
    FIELD1: 15.093,
    FIELD2: 15.594,
    FIELD3: 16.545,
    FIELD4: 17.812,
    FIELD5: 19.39,
    FIELD6: 21.194,
    FIELD7: 22.521
  },
  12.6: {
    FIELD1: 15.136,
    FIELD2: 15.64,
    FIELD3: 16.595,
    FIELD4: 17.87,
    FIELD5: 19.457,
    FIELD6: 21.273,
    FIELD7: 22.609
  },
  12.7: {
    FIELD1: 15.179,
    FIELD2: 15.685,
    FIELD3: 16.646,
    FIELD4: 17.929,
    FIELD5: 19.526,
    FIELD6: 21.352,
    FIELD7: 22.697
  },
  12.8: {
    FIELD1: 15.222,
    FIELD2: 15.732,
    FIELD3: 16.698,
    FIELD4: 17.989,
    FIELD5: 19.595,
    FIELD6: 21.433,
    FIELD7: 22.785
  },
  12.9: {
    FIELD1: 15.266,
    FIELD2: 15.779,
    FIELD3: 16.751,
    FIELD4: 18.049,
    FIELD5: 19.665,
    FIELD6: 21.513,
    FIELD7: 22.874
  },
  '12.10': {
    FIELD1: 15.311,
    FIELD2: 15.827,
    FIELD3: 16.804,
    FIELD4: 18.11,
    FIELD5: 19.735,
    FIELD6: 21.595,
    FIELD7: 22.964
  },
  12.11: {
    FIELD1: 15.357,
    FIELD2: 15.875,
    FIELD3: 16.858,
    FIELD4: 18.171,
    FIELD5: 19.806,
    FIELD6: 21.676,
    FIELD7: 23.053
  },
  '13.0': {
    FIELD1: 15.402,
    FIELD2: 15.923,
    FIELD3: 16.912,
    FIELD4: 18.233,
    FIELD5: 19.877,
    FIELD6: 21.759,
    FIELD7: 23.144
  },
  13.1: {
    FIELD1: 15.448,
    FIELD2: 15.973,
    FIELD3: 16.967,
    FIELD4: 18.296,
    FIELD5: 19.949,
    FIELD6: 21.841,
    FIELD7: 23.234
  },
  13.2: {
    FIELD1: 15.495,
    FIELD2: 16.023,
    FIELD3: 17.023,
    FIELD4: 18.359,
    FIELD5: 20.022,
    FIELD6: 21.924,
    FIELD7: 23.324
  },
  13.3: {
    FIELD1: 15.542,
    FIELD2: 16.073,
    FIELD3: 17.079,
    FIELD4: 18.422,
    FIELD5: 20.095,
    FIELD6: 22.008,
    FIELD7: 23.415
  },
  13.4: {
    FIELD1: 15.59,
    FIELD2: 16.123,
    FIELD3: 17.135,
    FIELD4: 18.486,
    FIELD5: 20.168,
    FIELD6: 22.091,
    FIELD7: 23.505
  },
  13.5: {
    FIELD1: 15.637,
    FIELD2: 16.174,
    FIELD3: 17.192,
    FIELD4: 18.55,
    FIELD5: 20.241,
    FIELD6: 22.174,
    FIELD7: 23.596
  },
  13.6: {
    FIELD1: 15.685,
    FIELD2: 16.225,
    FIELD3: 17.248,
    FIELD4: 18.615,
    FIELD5: 20.315,
    FIELD6: 22.258,
    FIELD7: 23.686
  },
  13.7: {
    FIELD1: 15.733,
    FIELD2: 16.276,
    FIELD3: 17.306,
    FIELD4: 18.68,
    FIELD5: 20.389,
    FIELD6: 22.341,
    FIELD7: 23.776
  },
  13.8: {
    FIELD1: 15.782,
    FIELD2: 16.328,
    FIELD3: 17.363,
    FIELD4: 18.744,
    FIELD5: 20.463,
    FIELD6: 22.425,
    FIELD7: 23.866
  },
  13.9: {
    FIELD1: 15.83,
    FIELD2: 16.379,
    FIELD3: 17.42,
    FIELD4: 18.81,
    FIELD5: 20.537,
    FIELD6: 22.508,
    FIELD7: 23.955
  },
  '13.10': {
    FIELD1: 15.879,
    FIELD2: 16.431,
    FIELD3: 17.478,
    FIELD4: 18.875,
    FIELD5: 20.611,
    FIELD6: 22.591,
    FIELD7: 24.044
  },
  13.11: {
    FIELD1: 15.927,
    FIELD2: 16.483,
    FIELD3: 17.535,
    FIELD4: 18.94,
    FIELD5: 20.685,
    FIELD6: 22.674,
    FIELD7: 24.133
  },
  '14.0': {
    FIELD1: 15.976,
    FIELD2: 16.534,
    FIELD3: 17.593,
    FIELD4: 19.005,
    FIELD5: 20.758,
    FIELD6: 22.757,
    FIELD7: 24.221
  },
  14.1: {
    FIELD1: 16.024,
    FIELD2: 16.586,
    FIELD3: 17.651,
    FIELD4: 19.07,
    FIELD5: 20.832,
    FIELD6: 22.839,
    FIELD7: 24.309
  },
  14.2: {
    FIELD1: 16.073,
    FIELD2: 16.638,
    FIELD3: 17.708,
    FIELD4: 19.135,
    FIELD5: 20.906,
    FIELD6: 22.921,
    FIELD7: 24.396
  },
  14.3: {
    FIELD1: 16.121,
    FIELD2: 16.689,
    FIELD3: 17.766,
    FIELD4: 19.2,
    FIELD5: 20.979,
    FIELD6: 23.003,
    FIELD7: 24.482
  },
  14.4: {
    FIELD1: 16.169,
    FIELD2: 16.741,
    FIELD3: 17.823,
    FIELD4: 19.265,
    FIELD5: 21.052,
    FIELD6: 23.084,
    FIELD7: 24.568
  },
  14.5: {
    FIELD1: 16.217,
    FIELD2: 16.792,
    FIELD3: 17.88,
    FIELD4: 19.329,
    FIELD5: 21.125,
    FIELD6: 23.164,
    FIELD7: 24.653
  },
  14.6: {
    FIELD1: 16.265,
    FIELD2: 16.843,
    FIELD3: 17.937,
    FIELD4: 19.394,
    FIELD5: 21.197,
    FIELD6: 23.244,
    FIELD7: 24.738
  },
  14.7: {
    FIELD1: 16.313,
    FIELD2: 16.894,
    FIELD3: 17.994,
    FIELD4: 19.458,
    FIELD5: 21.269,
    FIELD6: 23.324,
    FIELD7: 24.822
  },
  14.8: {
    FIELD1: 16.36,
    FIELD2: 16.944,
    FIELD3: 18.051,
    FIELD4: 19.522,
    FIELD5: 21.341,
    FIELD6: 23.403,
    FIELD7: 24.905
  },
  14.9: {
    FIELD1: 16.407,
    FIELD2: 16.995,
    FIELD3: 18.107,
    FIELD4: 19.585,
    FIELD5: 21.413,
    FIELD6: 23.482,
    FIELD7: 24.987
  },
  '14.10': {
    FIELD1: 16.454,
    FIELD2: 17.045,
    FIELD3: 18.163,
    FIELD4: 19.649,
    FIELD5: 21.484,
    FIELD6: 23.56,
    FIELD7: 25.069
  },
  14.11: {
    FIELD1: 16.501,
    FIELD2: 17.095,
    FIELD3: 18.219,
    FIELD4: 19.712,
    FIELD5: 21.554,
    FIELD6: 23.637,
    FIELD7: 25.149
  },
  '15.0': {
    FIELD1: 16.547,
    FIELD2: 17.145,
    FIELD3: 18.275,
    FIELD4: 19.774,
    FIELD5: 21.625,
    FIELD6: 23.714,
    FIELD7: 25.229
  },
  15.1: {
    FIELD1: 16.593,
    FIELD2: 17.194,
    FIELD3: 18.33,
    FIELD4: 19.837,
    FIELD5: 21.694,
    FIELD6: 23.79,
    FIELD7: 25.309
  },
  15.2: {
    FIELD1: 16.639,
    FIELD2: 17.243,
    FIELD3: 18.385,
    FIELD4: 19.899,
    FIELD5: 21.764,
    FIELD6: 23.865,
    FIELD7: 25.387
  },
  15.3: {
    FIELD1: 16.685,
    FIELD2: 17.292,
    FIELD3: 18.439,
    FIELD4: 19.96,
    FIELD5: 21.832,
    FIELD6: 23.94,
    FIELD7: 25.465
  },
  15.4: {
    FIELD1: 16.73,
    FIELD2: 17.34,
    FIELD3: 18.494,
    FIELD4: 20.022,
    FIELD5: 21.901,
    FIELD6: 24.014,
    FIELD7: 25.542
  },
  15.5: {
    FIELD1: 16.775,
    FIELD2: 17.389,
    FIELD3: 18.548,
    FIELD4: 20.082,
    FIELD5: 21.969,
    FIELD6: 24.088,
    FIELD7: 25.618
  },
  15.6: {
    FIELD1: 16.819,
    FIELD2: 17.436,
    FIELD3: 18.601,
    FIELD4: 20.143,
    FIELD5: 22.036,
    FIELD6: 24.161,
    FIELD7: 25.693
  },
  15.7: {
    FIELD1: 16.864,
    FIELD2: 17.484,
    FIELD3: 18.654,
    FIELD4: 20.203,
    FIELD5: 22.103,
    FIELD6: 24.233,
    FIELD7: 25.767
  },
  15.8: {
    FIELD1: 16.907,
    FIELD2: 17.531,
    FIELD3: 18.707,
    FIELD4: 20.262,
    FIELD5: 22.169,
    FIELD6: 24.304,
    FIELD7: 25.841
  },
  15.9: {
    FIELD1: 16.95,
    FIELD2: 17.577,
    FIELD3: 18.759,
    FIELD4: 20.321,
    FIELD5: 22.235,
    FIELD6: 24.375,
    FIELD7: 25.913
  },
  '15.10': {
    FIELD1: 16.993,
    FIELD2: 17.623,
    FIELD3: 18.811,
    FIELD4: 20.38,
    FIELD5: 22.3,
    FIELD6: 24.445,
    FIELD7: 25.985
  },
  15.11: {
    FIELD1: 17.035,
    FIELD2: 17.669,
    FIELD3: 18.862,
    FIELD4: 20.438,
    FIELD5: 22.364,
    FIELD6: 24.514,
    FIELD7: 26.056
  },
  '16.0': {
    FIELD1: 17.078,
    FIELD2: 17.714,
    FIELD3: 18.913,
    FIELD4: 20.495,
    FIELD5: 22.428,
    FIELD6: 24.582,
    FIELD7: 26.126
  },
  16.1: {
    FIELD1: 17.119,
    FIELD2: 17.759,
    FIELD3: 18.964,
    FIELD4: 20.552,
    FIELD5: 22.491,
    FIELD6: 24.65,
    FIELD7: 26.195
  },
  16.2: {
    FIELD1: 17.16,
    FIELD2: 17.803,
    FIELD3: 19.014,
    FIELD4: 20.608,
    FIELD5: 22.554,
    FIELD6: 24.717,
    FIELD7: 26.263
  },
  16.3: {
    FIELD1: 17.2,
    FIELD2: 17.847,
    FIELD3: 19.063,
    FIELD4: 20.664,
    FIELD5: 22.616,
    FIELD6: 24.783,
    FIELD7: 26.33
  },
  16.4: {
    FIELD1: 17.24,
    FIELD2: 17.89,
    FIELD3: 19.112,
    FIELD4: 20.72,
    FIELD5: 22.677,
    FIELD6: 24.849,
    FIELD7: 26.397
  },
  16.5: {
    FIELD1: 17.28,
    FIELD2: 17.933,
    FIELD3: 19.161,
    FIELD4: 20.774,
    FIELD5: 22.738,
    FIELD6: 24.913,
    FIELD7: 26.462
  },
  16.6: {
    FIELD1: 17.319,
    FIELD2: 17.975,
    FIELD3: 19.208,
    FIELD4: 20.829,
    FIELD5: 22.798,
    FIELD6: 24.977,
    FIELD7: 26.527
  },
  16.7: {
    FIELD1: 17.357,
    FIELD2: 18.017,
    FIELD3: 19.256,
    FIELD4: 20.882,
    FIELD5: 22.857,
    FIELD6: 25.04,
    FIELD7: 26.591
  },
  16.8: {
    FIELD1: 17.395,
    FIELD2: 18.058,
    FIELD3: 19.303,
    FIELD4: 20.936,
    FIELD5: 22.916,
    FIELD6: 25.102,
    FIELD7: 26.653
  },
  16.9: {
    FIELD1: 17.433,
    FIELD2: 18.099,
    FIELD3: 19.349,
    FIELD4: 20.988,
    FIELD5: 22.974,
    FIELD6: 25.164,
    FIELD7: 26.715
  },
  '16.10': {
    FIELD1: 17.47,
    FIELD2: 18.139,
    FIELD3: 19.395,
    FIELD4: 21.04,
    FIELD5: 23.032,
    FIELD6: 25.225,
    FIELD7: 26.777
  },
  16.11: {
    FIELD1: 17.506,
    FIELD2: 18.179,
    FIELD3: 19.44,
    FIELD4: 21.091,
    FIELD5: 23.088,
    FIELD6: 25.284,
    FIELD7: 26.836
  },
  '17.0': {
    FIELD1: 17.542,
    FIELD2: 18.218,
    FIELD3: 19.485,
    FIELD4: 21.142,
    FIELD5: 23.145,
    FIELD6: 25.344,
    FIELD7: 26.896
  },
  17.1: {
    FIELD1: 17.577,
    FIELD2: 18.256,
    FIELD3: 19.529,
    FIELD4: 21.192,
    FIELD5: 23.2,
    FIELD6: 25.402,
    FIELD7: 26.954
  },
  17.2: {
    FIELD1: 17.613,
    FIELD2: 18.295,
    FIELD3: 19.573,
    FIELD4: 21.242,
    FIELD5: 23.255,
    FIELD6: 25.46,
    FIELD7: 27.012
  },
  17.3: {
    FIELD1: 17.647,
    FIELD2: 18.332,
    FIELD3: 19.616,
    FIELD4: 21.291,
    FIELD5: 23.309,
    FIELD6: 25.516,
    FIELD7: 27.068
  },
  17.4: {
    FIELD1: 17.681,
    FIELD2: 18.369,
    FIELD3: 19.659,
    FIELD4: 21.34,
    FIELD5: 23.363,
    FIELD6: 25.572,
    FIELD7: 27.124
  },
  17.5: {
    FIELD1: 17.714,
    FIELD2: 18.406,
    FIELD3: 19.701,
    FIELD4: 21.388,
    FIELD5: 23.416,
    FIELD6: 25.628,
    FIELD7: 27.179
  },
  17.6: {
    FIELD1: 17.747,
    FIELD2: 18.442,
    FIELD3: 19.742,
    FIELD4: 21.435,
    FIELD5: 23.468,
    FIELD6: 25.683,
    FIELD7: 27.233
  },
  17.7: {
    FIELD1: 17.779,
    FIELD2: 18.477,
    FIELD3: 19.783,
    FIELD4: 21.482,
    FIELD5: 23.52,
    FIELD6: 25.736,
    FIELD7: 27.287
  },
  17.8: {
    FIELD1: 17.81,
    FIELD2: 18.512,
    FIELD3: 19.823,
    FIELD4: 21.528,
    FIELD5: 23.571,
    FIELD6: 25.789,
    FIELD7: 27.339
  },
  17.9: {
    FIELD1: 17.841,
    FIELD2: 18.547,
    FIELD3: 19.863,
    FIELD4: 21.574,
    FIELD5: 23.621,
    FIELD6: 25.842,
    FIELD7: 27.391
  },
  '17.10': {
    FIELD1: 17.872,
    FIELD2: 18.581,
    FIELD3: 19.903,
    FIELD4: 21.619,
    FIELD5: 23.67,
    FIELD6: 25.893,
    FIELD7: 27.441
  },
  17.11: {
    FIELD1: 17.902,
    FIELD2: 18.614,
    FIELD3: 19.942,
    FIELD4: 21.664,
    FIELD5: 23.72,
    FIELD6: 25.943,
    FIELD7: 27.49
  },
  '18.0': {
    FIELD1: 17.931,
    FIELD2: 18.647,
    FIELD3: 19.98,
    FIELD4: 21.708,
    FIELD5: 23.768,
    FIELD6: 25.993,
    FIELD7: 27.539
  },
  18.1: {
    FIELD1: 17.96,
    FIELD2: 18.679,
    FIELD3: 20.018,
    FIELD4: 21.751,
    FIELD5: 23.815,
    FIELD6: 26.042,
    FIELD7: 27.587
  },
  18.2: {
    FIELD1: 17.989,
    FIELD2: 18.71,
    FIELD3: 20.055,
    FIELD4: 21.794,
    FIELD5: 23.862,
    FIELD6: 26.091,
    FIELD7: 27.634
  },
  18.3: {
    FIELD1: 18.017,
    FIELD2: 18.742,
    FIELD3: 20.091,
    FIELD4: 21.836,
    FIELD5: 23.909,
    FIELD6: 26.138,
    FIELD7: 27.68
  },
  18.4: {
    FIELD1: 18.044,
    FIELD2: 18.772,
    FIELD3: 20.127,
    FIELD4: 21.877,
    FIELD5: 23.954,
    FIELD6: 26.185,
    FIELD7: 27.726
  },
  18.5: {
    FIELD1: 18.07,
    FIELD2: 18.802,
    FIELD3: 20.163,
    FIELD4: 21.918,
    FIELD5: 23.999,
    FIELD6: 26.23,
    FIELD7: 27.77
  },
  18.6: {
    FIELD1: 18.096,
    FIELD2: 18.831,
    FIELD3: 20.197,
    FIELD4: 21.958,
    FIELD5: 24.043,
    FIELD6: 26.276,
    FIELD7: 27.814
  },
  18.7: {
    FIELD1: 18.122,
    FIELD2: 18.86,
    FIELD3: 20.232,
    FIELD4: 21.998,
    FIELD5: 24.087,
    FIELD6: 26.32,
    FIELD7: 27.857
  },
  18.8: {
    FIELD1: 18.147,
    FIELD2: 18.889,
    FIELD3: 20.265,
    FIELD4: 22.037,
    FIELD5: 24.13,
    FIELD6: 26.364,
    FIELD7: 27.898
  },
  18.9: {
    FIELD1: 18.171,
    FIELD2: 18.916,
    FIELD3: 20.299,
    FIELD4: 22.076,
    FIELD5: 24.172,
    FIELD6: 26.406,
    FIELD7: 27.939
  },
  '18.10': {
    FIELD1: 18.195,
    FIELD2: 18.943,
    FIELD3: 20.331,
    FIELD4: 22.114,
    FIELD5: 24.214,
    FIELD6: 26.449,
    FIELD7: 27.98
  },
  18.11: {
    FIELD1: 18.218,
    FIELD2: 18.97,
    FIELD3: 20.363,
    FIELD4: 22.151,
    FIELD5: 24.255,
    FIELD6: 26.49,
    FIELD7: 28.019
  },
  '19.0': {
    FIELD1: 18.241,
    FIELD2: 18.996,
    FIELD3: 20.395,
    FIELD4: 22.188,
    FIELD5: 24.295,
    FIELD6: 26.531,
    FIELD7: 28.058
  }
};

export const getBMICalculation = (
  cal_age: any,
  gender: any,
  value: any
): any => {
  try {
    if (cal_age <= 19.0) {
      let calculation = null;
      if (gender === GenderTypes.MALE) {
        calculation = BoyBMI?.[cal_age];
      } else {
        calculation = GirlBMI?.[cal_age];
      }
      if (calculation) {
        if (value >= calculation.FIELD3 && value <= calculation.FIELD5) {
          return 1;
        } else if (
          (value >= calculation.FIELD2 && value < calculation.FIELD3) ||
          (value > calculation.FIELD5 && value <= calculation.FIELD6)
        ) {
          return 0.5;
        } else if (value < calculation.FIELD2 || value > calculation.FIELD6) {
          return 0;
        }
      }
    } else {
      if (value >= 18.5 && value < 25) {
        return 1;
      } else if (value >= 25 && value < 30) {
        return 0.5;
      } else if (value < 18.5 || value >= 30) {
        return 0;
      }
    }
    return null;
  } catch (e) {
    throw new Error((e as any)?.message);
  }
};
