/* eslint-disable camelcase */
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Typography,
  IconButton,
  Button,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import Icons from '@components/system/MyIcons';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {
  DEFAULT_WHITE_COLOR,
  MEDICINE_BORDER_BOX_COLOR
} from 'src/style/variable';
import './UploadAttachmentDialog.scss';

const textFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  border: `1px solid ${MEDICINE_BORDER_BOX_COLOR}`,
  borderRadius: '0.4rem',
  opacity: 1,
  fontSize: '1.2rem'
};

const UploadAttachmentDialog = (props: any): React.ReactElement => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(props?.open);
  const [uploadedFiles, setUploadedFiles] = React.useState<any>([]);
  const [fileName, setFileName]: any = React.useState('');
  const [imageUrl, setImageUrl]: any = React.useState('');

  const handleClose = () => {
    setOpen(false);
    props?.closeShowUploadAttachmentPopUp();
  };

  const handleSubmit = () => {
    if (!imageUrl) {
      enqueueSnackbar('No File Selected', SnackBarConfig('w'));
    }
    const formData = new FormData();
    formData.append('file', uploadedFiles);
    formData.append('file_name', fileName);
    formData.append('image_url', imageUrl);
    props?.handleAddAttachment(formData, fileName, imageUrl);
    handleClose();
  };

  const changeHandler = (event: any) => {
    const documents =
      event?.target?.files?.length > 0 ? event.target.files?.[0] : null;
    setUploadedFiles(documents);
    setFileName(documents?.name?.split('.')?.[0]);
    setImageUrl(URL.createObjectURL(event.target.files?.[0]));
  };

  const onClick = (event: any): void => {
    event.target.value = null;
  };

  const handleTextChange = (event: any) => {
    setFileName(event?.target?.value);
  };

  const renderUploadAttachmentDialogTitle = (): React.ReactElement => {
    return (
      <Grid item container xs={12} spacing={2} alignItems="center">
        <Grid item xs={11} className="upload-attachment-title">
          {t('UPLOAD_FILE')}
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          <IconButton id="close_upload_popup" onClick={handleClose}>
            <Icons.CloseBlackIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const renderTextField = (): React.ReactElement => {
    return (
      <FormTextField
        placeholder={t('SERVICE_REG_ENTER_NAME')}
        name="file-name"
        value={fileName}
        componentTextFieldProps={textFieldProps}
        callback={handleTextChange}
      />
    );
  };

  const renderUploadAttachmentDialogContent = (): React.ReactElement => {
    return (
      <Grid item container xs={12} className="upload-attachment-content-grid">
        <Typography className="name-text">
          {t('UPLOAD_FILE_NAME_OF_THE_FILE')}
        </Typography>

        <Grid item xs={12} className="file-name-text-filed">
          {renderTextField()}
        </Grid>

        <Grid
          item
          container
          xs={12}
          alignItems="center"
          className="files-container">
          <Grid item xs={10}>
            <Typography className="browse-file-name">
              {uploadedFiles?.name}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <label htmlFor="browse-file-button">
              {props?.imageCheck ? (
                <input
                  id="browse-file-button"
                  type="file"
                  className="choose-file-input-box"
                  multiple={false}
                  accept=".jpg, .jpeg, .png"
                  onChange={changeHandler}
                  onClick={onClick}
                />
              ) : (
                <input
                  id="browse-file-button"
                  type="file"
                  className="choose-file-input-box"
                  multiple={false}
                  accept="application/pdf"
                  onChange={changeHandler}
                  onClick={onClick}
                />
              )}
              <Button
                component="span"
                variant="contained"
                size="small"
                className="browse-button">
                {t('EI_BROWSE')}
              </Button>
            </label>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{paper: 'upload-attachment-dialog-wrapper'}}>
      <DialogTitle id="alert-dialog-title">
        {renderUploadAttachmentDialogTitle()}
      </DialogTitle>
      <DialogContent>{renderUploadAttachmentDialogContent()}</DialogContent>
      <DialogActions disableSpacing={true} style={{padding: '1.6rem'}}>
        <Button
          id="submit_upload_file"
          variant="contained"
          size="medium"
          className="submit-button"
          onClick={() => handleSubmit()}>
          {t('UPLOAD_FILE_UPLOAD_SELECTED')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadAttachmentDialog;
