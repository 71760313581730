/* eslint-disable camelcase */
import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {Button, Link, Typography, Grid} from '@material-ui/core';
import Icons from '@components/system/MyIcons/index';
import CommonUtils from 'src/utils/CommonUtils';
import {
  BASE_ROUTE_DOCTOR,
  BASE_ROUTE_PORTAL
} from 'src/constants/route/BaseRoute';
import NavRoutes from 'src/constants/route/NavRoutes';
import MoreClinicsComponent from '../MoreClinics/MoreClinicsComponent';
import AvailabilityButtonsComponent from '../AVailabilityButtons/AvailabilityButtonsComponent';
import AvailableTimings from '../AvailableTimings/AvailableTimings';
import ConsultationFees from '../ConsultationFees/ConsultationFees';
import './DoctorCardComponent.scss';
import Loader from '@components/framework/loader/Loader';
import moment from 'moment';
import {
  getDoctorById,
  getDoctorLanguages
} from '@shared/services/PublicService';
import {connect} from 'react-redux';
import _ from 'lodash';
import DoctorQrPopUp from './DoctorQrCodePopup';

const DoctorCardComponent = (props: any): ReactElement => {
  const {t} = useTranslation();
  const history = useHistory();

  const [doctorInfo, setDoctorInfo]: any = useState({});
  const [appointmentTypesList, setAppointmentTypesList]: any = useState([]);
  const [education, setEducation]: any = useState();
  const [languages, setLanguages]: any = useState();
  const [experience, setexperience]: any = useState();
  const [workTiming, setWorkTiming]: any = useState([]);
  const [showMoreClinics, setShowMoreClinics] = useState<boolean>(false);
  const [isLoading, setIsLoading]: any = useState(true);
  const [openQrPopup, setOpenQrPopup] = useState<boolean>(false);
  const [Load, setLoading]: any = useState(true);

  const buttonsList: any = [
    {id: 1, text: t('IN_PERSON_VISIT'), icon: Icons.InPersonVisitIcon},
    {id: 2, text: t('REMOTE_VISIT'), icon: Icons.VideoVisitIcon}
  ];

  const checkServiceExists = (
    serviceName: string,
    otherServiceName: string
  ): boolean => {
    return appointmentTypesList?.some(function (el: any) {
      return (
        el.appointment_type_value === serviceName ||
        el.appointment_type_value === otherServiceName
      );
    });
  };

  const checkForEducationAndLanguages = (doctor: any) => {
    if (
      doctor?.education &&
      Array.isArray(doctor.education) &&
      doctor.education.length > 0
    ) {
      const locEducation = doctor.education
        .map((item: any) => item.edu_degree)
        .join();
      setEducation(locEducation);
    }
    if (
      doctor?.languages &&
      Array.isArray(doctor.languages) &&
      doctor.languages.length > 0
    ) {
      const locLanguages = doctor.languages.map((item: any) =>
        // item.label?.charAt(0)?.toUpperCase() + item.label?.slice(1)
        CommonUtils.getCapitalizeWord(item?.label)
      );
      const uniqueLocLanguages = _.uniqBy(locLanguages, (e: any) => {
        return e;
      });

      setLanguages(uniqueLocLanguages.join());
    }
  };

  const getEducationLanguagesDetail = async () => {
    // here call api to get education & language details
    const res: any = await getDoctorLanguages(
      props?.doctor?.non_healpha_user_id
    );
    checkForEducationAndLanguages(res?.data);
  };
  const renderWorktime = () => {
    let timing: any;
    getDoctorById(props?.doctor?.non_healpha_user_id, props.branchId).then(
      (res: any) => {
        timing = CommonUtils.getDoctorWorkTimings1(
          res?.data?.weekly_timings,
          t,
          props?.branchId
        );
        const first = timing?.[0];
        const n = timing.length;
        for (let i = 1; i < n; i++) {
          if (timing[i].value.toString() == first.value.toString()) {
            timing.splice(i, 1);
          }
        }
        setWorkTiming(timing);
        setLoading(false);
      }
    );
  };
  useEffect(renderWorktime, []);

  const renderExperience = () => {
    let experienceList: any = [];
    if (
      props.doctor.user_experience &&
      props.doctor.user_experience.length > 0
    ) {
      experienceList = props.doctor.user_experience;
      const temp: any = [];
      const temp1: any = [];
      const fromDate = experienceList?.map((item: any) => {
        const yr: number = moment(item?.from_date).year();
        temp.push(yr);
        return temp;
      });
      let smallest = fromDate?.[0][0];
      for (let i = 1; i < fromDate?.[0].length; i++) {
        if (fromDate?.[0][i] < smallest) {
          smallest = fromDate?.[0][i];
        }
      }
      const tillDate = experienceList?.map((item: any) => {
        temp1.push(moment(item?.till_date).year());
        return temp1;
      });
      let largest = tillDate?.[0][0];
      for (let i = 1; i < tillDate?.[0].length; i++) {
        if (tillDate?.[0][i] > largest) {
          largest = tillDate?.[0][i];
        }
      }
      return largest - smallest;
    } else {
      return 0;
    }
  };
  const renderExperience1 = (docid: any, branchId: any) => {
    let resu: any;
    getDoctorById(docid, branchId).then((res: any) => {
      let experienceList: any = [];
      if (res?.data?.user_experience && res?.data?.user_experience.length > 0) {
        experienceList = res.data.user_experience;
        const temp: any = [];
        const temp1: any = [];
        const fromDate = experienceList?.map((item: any) => {
          const yr: number = moment(item?.from_date).year();
          temp.push(yr);
          return temp;
        });
        let smallest = fromDate?.[0][0];
        for (let i = 1; i < fromDate?.[0].length; i++) {
          if (fromDate?.[0][i] < smallest) {
            smallest = fromDate?.[0][i];
          }
        }
        const tillDate = experienceList?.map((item: any) => {
          temp1.push(moment(item?.till_date).year());
          return temp1;
        });

        let largest = tillDate?.[0][0];
        for (let i = 1; i < tillDate?.[0].length; i++) {
          if (tillDate?.[0][i] > largest) {
            largest = tillDate?.[0][i];
          }
        }
        resu = largest - smallest;
        setexperience(resu);
      } else {
        setexperience(0);
      }
    });
  };
  const init = (): void => {
    setIsLoading(true);
    const doctor: any = props?.doctor;
    renderExperience1(doctor.non_healpha_user_id, props.branchId);
    doctor.appointment_types = CommonUtils.makeAppointmentTypes(
      // eslint-disable-next-line
      doctor?.price_list
    );
    // here filtering the data
    if (doctor && Object.keys(doctor).length > 0) {
      const data: any = {};
      // form the name with salutation
      data.name = `${
        doctor.salutation ? doctor.salutation + '. ' : ''
      }${CommonUtils.getFullName(doctor)}`;

      // check education and form as string to show in UI
      checkForEducationAndLanguages(doctor);

      if (
        doctor?.branches &&
        Array.isArray(doctor.branches) &&
        doctor.branches.length > 0
      ) {
        data.branches = doctor.branches.filter((item: any) => item.branch);
        // get specialization of all branches and form as string
        const specialization = doctor?.branches?.map((item: any) => {
          return props?.specializationsObj && item?.specialization
            ? props?.specializationsObj[item?.specialization]
            : '';
        });
        const uniqueSpecialization = _.uniqBy(specialization, (e: any) => {
          return e;
        });
        data.specialization = uniqueSpecialization.join();

        // check the designations of doctor and form as string
        const designation = doctor?.branches?.map(
          (item: any) => item.designation
        );
        const uniqueDesignation = _.uniqBy(designation, (e: any) => {
          return e;
        });
        data.designation = uniqueDesignation.join();

        if (data.branches[0]?.doctor_id && data.branches[0]?.branch_id) {
          setAppointmentTypesList(
            CommonUtils.formAppointmentTypes(
              data.branches[0]?.branch_id,
              doctor
            )
          );
        }
        data.isTodayAvailable = CommonUtils.findDoctorTodayAvailability(
          data,
          null,
          moment().day()
        );
      }
      if (props?.showRightSideSection) {
        data.workTimings = CommonUtils.getDoctorWorkTimings(
          data?.branches,
          t,
          props?.branchId
        );
      }
      data.profileImage = CommonUtils.getFullImageUrl(doctor?.profile_image);
      data.docQrcode = CommonUtils.getFullImageUrl(doctor?.doc_qrcode);
      data.yearsOfExp = doctor?.experience
        ? doctor?.experience
        : renderExperience();
      data.doctor_id = doctor?.non_healpha_user_id;
      data.is_healpha_verified = doctor?.is_healpha_verified ? true : false;
      setDoctorInfo(data);
      setIsLoading(false);
      if (props?.showRightSideSection) {
        getEducationLanguagesDetail();
      }
    }
  };

  useEffect(init, [props?.doctor]);

  const openDoctorView = () => {
    let url =
      `/${BASE_ROUTE_PORTAL}/home/${BASE_ROUTE_DOCTOR}/${NavRoutes.PUBLIC.DOCTOR.VIEW}` +
      `/${doctorInfo.doctor_id}/${NavRoutes.PUBLIC.DOCTOR.TABS.INFO}`;

    if (props?.branchId) {
      url = `${url}?branch_id=${props.branchId}`;
    }
    history.push(url);
  };

  const handleLinkClick = (): void => {
    setShowMoreClinics(true);
  };

  const checkService = (serviceId: number): boolean => {
    let serviceName = 'general',
      otherServiceName = 'emergency';

    if (serviceId === 2) {
      serviceName = 'homecare';
      otherServiceName = 'telemedicine';
    }

    return checkServiceExists(serviceName, otherServiceName);
  };

  const renderButtons = (): ReactElement => {
    return buttonsList?.map((item: any) => {
      return (
        checkService(item?.id) && (
          <Button
            disabled={true}
            key={item?.id}
            className={[
              'consultation-button',
              item.id === 2 && 'remote-button'
            ].join(' ')}
            size="small"
            startIcon={<item.icon />}>
            {item?.text}
          </Button>
        )
      );
    });
  };

  const renderButtonsSmall = (): ReactElement => {
    return buttonsList?.map((item: any) => {
      return (
        checkService(item?.id) && (
          <Button
            disabled={true}
            key={item?.id}
            className={[
              'consultation-button-small',
              item.id === 2 && 'remote-button-small'
            ].join(' ')}
            size="small"
            startIcon={<item.icon />}>
            {item?.text}
          </Button>
        )
      );
    });
  };
  const renderRemainingBranches = (): ReactElement => {
    return (
      <Grid style={{display: 'flex'}}>
        <Typography style={{marginLeft: 4}}>{'&'}</Typography>
        <Link
          component="button"
          variant="body2"
          className="more-clinics-link"
          onClick={() => handleLinkClick()}>
          {doctorInfo.branches.length - 1} {t('MORE_CLINICS')}
        </Link>
      </Grid>
    );
  };

  const renderBranchesList = (): ReactElement => {
    return (
      <Typography className="title-4" style={{display: 'flex'}}>
        {doctorInfo?.branches[0]?.branch?.branch_name}
        {doctorInfo?.branches?.length > 1 &&
          props?.showRightSideSection &&
          renderRemainingBranches()}
      </Typography>
    );
  };

  const renderProfilePicSection = (): ReactElement => {
    return (
      <Grid
        item
        lg={props?.showRightSideSection ? 5 : 2}
        className="left-section-child-1">
        {doctorInfo?.profileImage ? (
          <img className="img" src={doctorInfo?.profileImage} />
        ) : (
          <Icons.DoctorIcon className="img default-img" />
        )}
        {doctorInfo?.is_healpha_verified && props?.showRightSideSection && (
          <Button
            className="medication-button"
            disabled={true}
            size="small"
            style={{marginTop: '0.25rem'}}
            startIcon={<Icons.MedicationRegistrationVerificationIcon />}>
            {t('APP_VERIFIED')}
          </Button>
        )}
      </Grid>
    );
  };
  const closeQrpagePopup = (): void => {
    setOpenQrPopup(false);
  };
  const displayQr = () => {
    setOpenQrPopup(true);
  };
  const renderQrPopup = () => {
    return (
      <DoctorQrPopUp
        open={openQrPopup}
        closeQrpagePopup={closeQrpagePopup}
        doctorInfo={doctorInfo}
      />
    );
  };
  const renderLeftSection = (): ReactElement => {
    return (
      <Grid className="left-section-child-2" item lg>
        <Grid container alignItems="center">
          {props?.showRightSideSection ? (
            <Link
              component="button"
              variant="body2"
              className="doctor-name-link"
              onClick={() => openDoctorView()}>
              {doctorInfo?.name ? doctorInfo.name : ''}
            </Link>
          ) : (
            <Typography
              className={`title-1 ${
                props?.whitelablingInfo?.isDoctor
                  ? 'whitelabel-name-big-font'
                  : ''
              }`}>
              {doctorInfo?.name ? doctorInfo.name : ''}
            </Typography>
          )}
          {doctorInfo?.is_healpha_verified && !props?.showRightSideSection && (
            <Button
              disabled={true}
              className="medication-button"
              size="medium"
              startIcon={<Icons.MedicationRegistrationVerificationIcon />}>
              {' '}
              {t('APP_VERIFIED')}
            </Button>
          )}
        </Grid>

        <Typography className="title-2">
          {education} - {doctorInfo?.specialization}
        </Typography>

        <Typography className="title-2">{doctorInfo?.designation}</Typography>

        {doctorInfo?.yearsOfExp ? (
          doctorInfo?.yearsOfExp > 0 && (
            <Typography className="title-3">
              {doctorInfo?.yearsOfExp
                ? doctorInfo?.yearsOfExp
                : renderExperience()}{' '}
              {t('YEARS_OF_EXPERIENCE')}
            </Typography>
          )
        ) : (
          <Typography className="title-3">
            {experience + ' '}
            {t('YEARS_OF_EXPERIENCE')}
          </Typography>
        )}
        <Grid className="container-child-2" item xs={12}>
          {languages && (
            <Typography className="title-3">
              {t('SPEAKS')} - {languages}
            </Typography>
          )}
        </Grid>
        {doctorInfo?.branches?.length > 0 &&
          props?.showRightSideSection &&
          renderBranchesList()}

        <Grid
          container={!props?.showRightSideSection}
          className="buttons-section">
          {!props?.showRightSideSection ? (
            <Grid>{renderButtons()}</Grid>
          ) : (
            <Grid className="renderButtonsSmallGrid">
              {renderButtonsSmall()}
            </Grid>
          )}
          {!props?.showRightSideSection && doctorInfo?.docQrcode && (
            <Grid item className="mainQrGrid">
              <Grid className="qrcodeGrid">
                <img
                  className="Qrimg"
                  src={doctorInfo?.docQrcode}
                  onClick={displayQr}
                />
              </Grid>
              <Typography className="scanQrFont">{t('SCAN_QR')}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderRightSection = (): ReactElement => {
    return (
      <Grid item xs={12} lg>
        {Load && <Loader />}
        <AvailableTimings workTimings={workTiming} isLoading={Load} />
      </Grid>
    );
  };

  const renderMiddleSection = (): ReactElement => {
    return (
      <Grid item xs={12} lg>
        <ConsultationFees appointmentTypesList={appointmentTypesList} />
      </Grid>
    );
  };

  const renderAppointmentSection = (): ReactElement => {
    return (
      <AvailabilityButtonsComponent
        branchData={doctorInfo}
        bookAppointmentAction={openDoctorView}
      />
    );
  };

  const renderMoreClinics = (): ReactElement => {
    return (
      <MoreClinicsComponent open={showMoreClinics} doctorData={doctorInfo} />
    );
  };

  return (
    <Grid className="doctor-card-wrapper">
      {isLoading && <Loader />}
      {!isLoading && (
        <Grid className="doctor-card-container">
          <Grid container className="doctor-card-content" spacing={2}>
            <Grid
              container
              item
              lg={props?.showRightSideSection ? 6 : 12}
              className="left-section-child-section"
              spacing={2}>
              {renderProfilePicSection()}
              {renderLeftSection()}
            </Grid>

            {props?.showRightSideSection && renderMiddleSection()}
            {props?.showRightSideSection && renderRightSection()}
          </Grid>
          {props?.showRightSideSection && renderAppointmentSection()}
          {showMoreClinics && renderMoreClinics()}
        </Grid>
      )}
      {openQrPopup && renderQrPopup()}
    </Grid>
  );
};

function mapStateToProps(state: any) {
  // Properties defined here (read from state) will be available as props in the component
  return {
    whitelablingInfo: state.common.whitelablingInfo
  };
}

export default connect(mapStateToProps, {})(DoctorCardComponent);
