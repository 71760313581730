import React, {ReactElement, useState} from 'react';
import {Tab, Tabs, Badge, Grid} from '@material-ui/core';
import {isMobile} from 'react-device-detect';

import './SubTabsComponent.scss';

export interface IRoutesDataType {
  id: string;
  label: string;
  route: string;
  count?: number;
  disabled?: boolean;
}

interface routeProps {
  initialRoute: string;
  routesData: Array<IRoutesDataType>;
  action(name: string): void;
  borderBottom?: boolean;
}

const SubTabsComponent = (props: routeProps): ReactElement => {
  const [value, setValue] = useState<string>(props?.initialRoute);

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
    if (typeof props?.action === 'function') {
      props.action(newValue);
    }
  };

  const checkInitialValue = (): void => {
    setValue(props?.initialRoute);
  };

  React.useEffect(checkInitialValue, [props?.initialRoute]);
  return (
    <Grid
      className={[
        'sub-tabs-wrapper',
        props?.borderBottom === false ? '' : 'tabs-border-bottom'
      ].join(' ')}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        variant={isMobile ? 'scrollable' : 'fullWidth'}
        orientation={isMobile ? 'vertical' : 'horizontal'}
        onChange={handleChange}>
        {props?.routesData?.map(item => {
          return (
            <Tab
              label={
                item?.count ? (
                  <Badge badgeContent={item?.count}>{item?.label}</Badge>
                ) : (
                  item?.label
                )
              }
              value={item?.route}
              key={item?.id}
              disableRipple={true}
              disableFocusRipple={true}
              disabled={item?.disabled || false}
            />
          );
        })}
      </Tabs>
    </Grid>
  );
};
export default SubTabsComponent;
