/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Avatar,
  Divider,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import NewAppointmentBookingsComponent from '../NewAppointmentBookings/NewAppointmentBookingsComponent';
import AppointmentBooking from 'src/modules/user/modules/ClinicDashboard/Appointment/AppointmentBooking';
import Icons from '@components/system/MyIcons/index';
import './AppointmentConfirmationComponent.scss';

const AppointmentConfirmationComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(props?.open);
  const [
    showNewAppointmentBookingUpPopup,
    setShowNewAppointmentBookingUpPopup
  ] = React.useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    if (
      props?.closeAppointmentConfirmationPopup &&
      typeof props.closeAppointmentConfirmationPopup === 'function'
    ) {
      props.closeAppointmentConfirmationPopup();
      props?.closePopup && props?.closePopup();
    }
  };

  const openNewAppointmentBookingPopup = (): void => {
    setOpen(false);
    setShowNewAppointmentBookingUpPopup(true);
  };

  const closeNewAppointmentBookingPopup = (): void => {
    handleClose();
  };

  const renderNewAppointmentBookingPopup = (): React.ReactElement => {
    if (props?.isFromClinicalDashboard) {
      return (
        <AppointmentBooking
          {...props}
          branchId={props?.values?.branch_id}
          open={showNewAppointmentBookingUpPopup}
          closeNewBookingsPopup={closeNewAppointmentBookingPopup}
          healphaId={props?.healphaId}
          is_clinical_dashboard={true}
        />
      );
    }
    return (
      <NewAppointmentBookingsComponent
        branchId={props?.values?.branch_id}
        open={showNewAppointmentBookingUpPopup}
        closeNewBookingsPopup={closeNewAppointmentBookingPopup}
        healphaId={props?.healphaId}
      />
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar alt="" className="appointment-avatar" />
          </Grid>
          <Grid item>
            <Typography className="popup-title">Patient Added </Typography>
            <Typography className="popup-sub-title">
              {`${props?.values?.first_name} ${props?.values?.last_name} has been added successfully`}
            </Typography>
            <IconButton
              id="pop_up_close"
              className="popup-close-icon"
              onClick={handleClose}>
              <Icons.CloseBlackIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Divider className="divider" />

        <Grid container justifyContent="center">
          <Grid item xs={8}>
            <Typography className="message-text">
              Do you want to Book an appointment for this patient?
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            id="book_appointment"
            variant="contained"
            size="medium"
            color="primary"
            className="submit-button"
            style={{height: '3rem'}}
            onClick={openNewAppointmentBookingPopup}>
            {t('Book Appointment')}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            id="cancel_appointmant"
            variant="outlined"
            size="medium"
            className="appointment-cancel-button"
            onClick={() => handleClose()}>
            {t('CANCEL')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className="appointment-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        aria-labelledby="appointment-confirmation-popup"
        aria-describedby="appointment-confirmation"
        classes={{paper: 'appointment-confirmation-popup'}}>
        <DialogContent>{renderDialogContent()}</DialogContent>

        {renderDialogActions()}
      </Dialog>
      {showNewAppointmentBookingUpPopup && renderNewAppointmentBookingPopup()}
    </Grid>
  );
};

export default AppointmentConfirmationComponent;
