import React from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as PositiveUpArrowIconSvg} from '@assets/images/healpha/PositiveUpArrow.svg';

const PositiveUpArrowIcon = (props: any): React.ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      style={{width: 'inherit', height: 'inherit'}}
      className={className}
      component={PositiveUpArrowIconSvg}
      viewBox="0 0 15 15"
    />
  );
};

export default PositiveUpArrowIcon;
