import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as BackIconSvg} from '@assets/images/healpha/back.svg';

const BackIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={BackIconSvg} viewBox="0 0 24 24" />
  );
};

export default BackIcon;