/* eslint-disable camelcase */
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  useMediaQuery,
  Avatar
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {useTheme} from '@material-ui/core/styles';

import AvailableLocationCardComponent from '../AvailableLocationCard/AvailableLocationCardComponent';
import NotFoundComponent from '../NotFound/NotFoundComponent';
import {FONT_PRIMARY_COLOR} from 'src/style/variable';
import './MoreClinicsComponent.scss';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomizedDialogTitle = (props: DialogTitleProps): React.ReactElement => {
  const {children, onClose, ...other} = props;

  return (
    <DialogTitle disableTypography sx={{m: 0, p: 2}} {...other}>
      <Typography variant="h6" component="div">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{marginTop: '1rem'}}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: () => FONT_PRIMARY_COLOR
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const MoreClinicsComponent = (props?: any): React.ReactElement => {
  const {t} = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = React.useState(props?.open);

  const handleClose = () => {
    setOpen(false);
  };

  const renderDialogTitle = (): React.ReactElement => {
    return (
      <CustomizedDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <div style={{display: 'flex', marginTop: '1rem'}}>
          <Avatar
            alt="profile Image"
            src={props?.doctorData?.profileImage}
            sx={{width: 50, height: 50}}
          />
          <span className="more-clinics-header">
            {props?.doctorData?.name} {t('AVAILABLE_BELOW_LOCATIONS')}
          </span>
        </div>
      </CustomizedDialogTitle>
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <DialogContent dividers sx={{p: 2}}>
        <div>
          {props?.doctorData?.branches?.length > 0 ? (
            props?.doctorData?.branches.map((item: any, index: any) => {
              return (
                <div className="branch-item" key={index}>
                  <AvailableLocationCardComponent
                    branch={item}
                    doctorData={props?.doctorData}
                  />
                </div>
              );
            })
          ) : (
            <NotFoundComponent label={t('NO_BRANCHES_FOUND')} />
          )}
        </div>
      </DialogContent>
    );
  };

  return (
    <div className="more-clinics-wrapper">
      <Dialog
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        aria-labelledby="more-clinics-pop-up"
        id={props?.doctorData?.non_healpha_user_id}>
        {renderDialogTitle()}
        {renderDialogContent()}
      </Dialog>
    </div>
  );
};

export default MoreClinicsComponent;
