/* eslint-disable camelcase */
import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {
  BrowserRouter,
  Switch,
  withRouter,
  Route,
  Redirect
} from 'react-router-dom';
import firebase from 'firebase';

import {
  getContactDetails,
  getLanguageContent,
  getValidateWhiteLabelPrefix
} from '@shared/services/PublicService';
import {environment} from '@shared/environment/environment';
import {_initApp} from '@shared/store/actions/AuthActions';
import {
  _setWhitelablingInfo,
  _setContactDetailsInfo
} from '@shared/store/actions/CommonActions';
import {axios} from '@shared/axios';
import InitSharedRepo from '../../shared';

import CommonUtils from './utils/CommonUtils';
import AppLoader from './components/framework/apploader/AppLoader';
import {version} from '../package.json';
import NavRoutes from './constants/route/NavRoutes';
import AuthUtils from './utils/AuthUtils';
import CustomRoute from './constants/route/CustomRoute';
import {ExceptWhitelablingUrls} from './constants/SystemConstants';
import LanguageContent from './i18n';
import Portal from './modules/public/portal/portal';
import {
  BASE_ROUTE_DOCTOR,
  BASE_ROUTE_BRANCH,
  BASE_ROUTE_PORTAL,
  BASE_ROUTE_PRACTICE
} from './constants/route/BaseRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/style.scss';

class App extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      url: ''
    };
    this.getWhiteLabelPrefix = this.getWhiteLabelPrefix.bind(this);
    this.getMultiLanguage = this.getMultiLanguage.bind(this);
    this.getContactDetails = this.getContactDetails.bind(this);
    this.handleAppEntry = this.handleAppEntry.bind(this);
    this.getAuthStatusFromStorage = this.getAuthStatusFromStorage.bind(this);
    this.plugInterceptors = this.plugInterceptors.bind(this);
  }

  async componentDidMount() {
    firebase.initializeApp(environment.firebase);

    navigator.serviceWorker
      .register('/portal/firebase-messaging-sw.js', {scope: '/portal/'})
      .then(registration => {
        const messaging = firebase.messaging();
        messaging.useServiceWorker(registration);
      });
    await this.getMultiLanguage();
    await this.getWhiteLabelPrefix();
    await this.getContactDetails();
    this.handleAppEntry();
    this.plugInterceptors();
  }

  getMultiLanguage = async () => {
    try {
      const languages = environment.languages;
      const selectedLanguage = CommonUtils.getLanguageId()
        ? CommonUtils.getLanguageId()
        : languages[0];
      const response = await getLanguageContent(selectedLanguage.key, 'portal');
      LanguageContent.setLanguage(response?.data);
      InitSharedRepo.initLanguage(selectedLanguage.key);
    } catch (error) {
      this.setState({loading: false});
    }
  };

  getContactDetails = async () => {
    try {
      const res = await getContactDetails();
      // eslint-disable-next-line
      if (res?.data?.contact_details) {
        const details = res.data.contact_details;
        const data = {
          headOfficeAddress: `${details.portal_address1} ${details.portal_address2}`,
          headOfficePhoneNo: `Phone: ${details.portal_phn_code}-${details.portal_phn_no},`,
          headOfficeMobileNo: `${details.portal_alt_phn_code}-${details.portal_alt_phn_no}`,
          twitterUrl: `${details.portal_twitter_link}`,
          quoraUrl: `${details.portal_quora_link}`,
          instagramUrl: `${details.portal_instagram_link}`,
          faceBookUrl: `${details.portal_facebook_link}`,
          linkedInUrl: `${details.portal_linkedin_link}`,
          doctorAppPlayStoreLink: `${details.portal_doc_app_android_link}`,
          doctorAppAppStoreLink: `${details.portal_doc_app_ios_link}`,
          patientAppPlayStoreLink: `${details.portal_person_app_android_link}`,
          carePhoneNo: `${details.portal_care_phn_code}-${details.portal_care_phn_no}`,
          careEmail: `${details.portal_care_email_id}`,
          salesEmail: `Email: ${details.portal_sales_email_id}`,
          salesPhone: `Phone: ${details.portal_sales_phn_code}-${details.portal_sales_phn_no}`,
          salesWhatsapp: `Whatsapp: ${details.portal_sales_whatsapp_no}`,
          termsAndConditionsUrl: `${details.portal_terms_of_service}`
        };
        this.props._setContactDetailsInfo(data);
      }
    } catch (error) {
      this.setState({loading: false});
    }
  };

  getWhiteLabelPrefix = async () => {
    try {
      const whiteLabelPrefix = CommonUtils.checkWhitelabelprefixExistsInUrl();
      const currentUrl: any = window.location.href;
      if (
        whiteLabelPrefix &&
        ExceptWhitelablingUrls.indexOf(whiteLabelPrefix) === -1
      ) {
        const response = await getValidateWhiteLabelPrefix(
          whiteLabelPrefix,
          currentUrl
        );
        const {data} = response;
        if (data && typeof data === 'object') {
          let url = null;
          const whiteLabelData = {
            isDoctor: false,
            isBranch: false,
            isPractice: false,
            branchId: '',
            practiceId: '',
            doctorId: ''
          };
          // eslint-disable-next-line
          if (data?.branch_id) {
            url =
              `/${BASE_ROUTE_PORTAL}/home/${BASE_ROUTE_BRANCH}/${NavRoutes.PUBLIC.HOSPITAL.VIEW}` +
              `/${data.branch_id}/${NavRoutes.PUBLIC.HOSPITAL.TABS.OVERVIEW}`;
            whiteLabelData.isBranch = true;
            whiteLabelData.branchId = data.branch_id;
            // eslint-disable-next-line
          } else if (data?.non_healpha_user_id) {
            url =
              `/${BASE_ROUTE_PORTAL}/home/${BASE_ROUTE_DOCTOR}/${NavRoutes.PUBLIC.DOCTOR.VIEW}` +
              `/${data.non_healpha_user_id}/${NavRoutes.PUBLIC.DOCTOR.TABS.INFO}`;
            whiteLabelData.isDoctor = true;
            whiteLabelData.doctorId = data.non_healpha_user_id;
          } else if (data?.practice_id) {
            url =
              `/${BASE_ROUTE_PORTAL}/home/${BASE_ROUTE_PRACTICE}/${NavRoutes.PUBLIC.HOSPITAL.VIEW}` +
              `/${data.practice_id}/${NavRoutes.PUBLIC.HOSPITAL.TABS.OVERVIEW}`;
            whiteLabelData.isPractice = true;
            whiteLabelData.practiceId = data.practice_id;
            // eslint-disable-next-line
          } else {
            window.location.replace(
              `${environment.portalUrl}/${BASE_ROUTE_PORTAL}`
            );
          }
          this.props._setWhitelablingInfo(whiteLabelData);
          if (url && window.location.pathname !== url) {
            const {history} = this.props;
            history.push(url);
            // window.location.replace(`${environment.portalUrl}${url}`);
            // window.location.reload();
          }
        } else {
          const temp: any = response?.data;
          if (temp != '' && temp != undefined && temp != null) {
            window.location.replace(temp);
          }
        }
      }
    } catch (error) {
      this.setState({loading: false});
    }
  };

  plugInterceptors(): void {
    const {history} = this.props;
    // Request interceptor
    axios.interceptors.request.use(
      function (config: any) {
        // Do something before request is sent
        return config;
      },
      function (error: any) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Response interceptor
    axios.interceptors.response.use(
      function (response: any) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error: any) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        // Logging out for unauthorised access
        if (error?.response?.status === 401) {
          history.push(`/portal/home/dashboard`);
          window.location.reload();
        }
        return Promise.reject(error);
      }
    );
  }

  // Token can be verified here before preparing the return object
  getAuthStatusFromStorage() {
    return AuthUtils.resolvePortal();
  }

  async handleAppEntry() {
    const {initialRoute} = this.props;
    if (!initialRoute) {
      const portal = this.getAuthStatusFromStorage();
      if (portal) {
        this.props._initApp({initialRoute: portal});
        this.setState({loading: false});
      } else {
        setTimeout(() => {
          this.setState({loading: false});
        }, 1000);
      }
    } else {
      setTimeout(() => {
        this.setState({loading: false});
      }, 1000);
    }
  }

  render(): ReactNode {
    if (this.state.loading) {
      return <AppLoader />;
    }
    const commonRoute = CustomRoute.commonRoute;
    const returnRoute = CustomRoute.basicRoute(commonRoute, null);
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Redirect
              from={`/${BASE_ROUTE_PORTAL}/`}
              to={`/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.HOME}`}
              exact
            />
            <Redirect
              from={`/`}
              to={`/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.HOME}`}
              exact
            />
            {returnRoute}
            <Route path={`/${BASE_ROUTE_PORTAL}`} component={Portal} />
          </Switch>
        </BrowserRouter>
        <div className="build-version-number">
          <span>v{version}</span>
        </div>
      </>
    );
  }
}

type StateObj = {
  auth: {initialRoute?: string};
};

function mapStateToProps(state: StateObj) {
  // Properties defined here (read from state) will be available as props in the component
  return {
    initialRoute: state.auth.initialRoute
  };
}

export default connect(mapStateToProps, {
  _initApp,
  _setWhitelablingInfo,
  _setContactDetailsInfo
  // Binding this dispatcher to be available in props in the component
})(withRouter(App));
