/* eslint-disable camelcase */
import React, {useState} from 'react';
import {Button, Grid, Typography} from '@material-ui/core';

import {statusColor} from 'src/constants/statusColors';

import './Status.scss';

const Status = (props: any): React.ReactElement => {
  const [statusStyle, setStatusStyle] = useState(false);
  const {
    keyGiven,
    label,
    count,
    selectedIndex,
    index,
    clickedStatus,
    setClickedStatus
  }: any = props;

  const handleClick = () => {
    props?.handleClickedStatus(keyGiven, count, index);
    setStatusStyle(!statusStyle);
    setClickedStatus(keyGiven);

    if (clickedStatus !== keyGiven) {
      setClickedStatus(keyGiven);
    } else {
      setClickedStatus('');
    }
  };

  const bgColor = statusColor.filter(
    item => item.name === keyGiven || item.name === label
  );

  const newDotColor = bgColor[0]?.dotColor;

  return (
    <Grid item container xs={4} className="status-card-wrapper">
      <Button
        id={'buton_' + index}
        className={[
          'status statusField',
          selectedIndex === index && 'onClickedStatus'
        ].join(' ')}
        style={{backgroundColor: bgColor[0] ? bgColor[0].color : ''}}
        onClick={handleClick}>
        <Grid item container xs={12}>
          <Typography className="status-name">
            {label === 'Appointment Rescheduled' ? 'Rescheduled' : label}
          </Typography>
          <Grid item container className="dot-count-grid" alignItems="center">
            <Grid item xs>
              <Grid
                className="count-bubble-view"
                style={{
                  backgroundColor: newDotColor
                }}
              />
            </Grid>
            <Grid item xs container justifyContent="flex-end">
              <Typography className="status-count"> {count}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
};

export default Status;
