import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as GooglePalyIconSvg} from '@assets/images/healpha/GooglePlay.svg';

const GooglePalyIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={GooglePalyIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default GooglePalyIcon;
