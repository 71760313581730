import axios from 'axios';
import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';
import CommonUtils from 'src/utils/CommonUtils';

import {environment} from './src/environment/environment';

class InitSharedRepo {
  // Call at the very beginning
  static init(): void {
    axios.defaults.timeout = environment.reqTimeout;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['screen-number'] = BASE_ROUTE_PORTAL;
    let url = `${CommonUtils.getWindowUrl()}/api/v1`;
    if (environment.baseHost.includes('4001')) {
      url = url.replace('3000', '4001');
    }

    axios.defaults.baseURL = url;
  }

  static phpUrlInit(): void {
    axios.defaults.baseURL = `${CommonUtils.getWindowUrl()}/api`;
  }

  static phpUrlInitWithOutBaseApi(): void {
    axios.defaults.baseURL = CommonUtils.getWindowUrl();
  }

  // Call after authentication is done and make required changes
  static initAfterAuth(token: any): void {
    axios.defaults.headers.common['authorization'] = `Bearer ${token}` || null;
  }

  static initScreenNumber(id: any): void {
    axios.defaults.headers.common['screen-number'] = id;
  }

  static removeAuthorization(): void {
    axios.defaults.headers.common['authorization'] = null;
  }

  static initLanguage(language: any): void {
    axios.defaults.headers.common['accept-language'] = language;
  }
}
export default InitSharedRepo;
