import React, {Component, ReactNode} from 'react';
import lottie from 'lottie-web';
import * as animationData from '@assets/loader.json';

class AppLoader extends Component<any, any> {
  animBox: HTMLDivElement | null | undefined;
  constructor(props: any) {
    super(props);
  }

  componentDidMount(): void {
    if (this.animBox) {
      const animation = lottie.loadAnimation({
        container: this.animBox,
        loop: true,
        autoplay: true,
        animationData: (animationData as any).default,
        renderer: 'svg'
      });
      animation.play();
    }
  }

  render(): ReactNode {
    return (
      <div className="flex-centering">
        <div
          className="app-loader"
          ref={ref => {this.animBox = ref;}} />
      </div>
    );
  }
}

export default AppLoader;
