import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as StarGreenIconSvg} from '@assets/images/healpha/StarGreen.svg';

const StarGreenIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={StarGreenIconSvg}
      viewBox="0 0 13.258 13.258"
    />
  );
};

export default StarGreenIcon;
