import React from "react";
import { useTranslation } from "react-i18next";
import NotFoundComponent from "../NotFound/NotFoundComponent";
import './MultipleImagesViewComponent.scss';

const MultipleImagesViewComponent = (props: any): React.ReactElement => {
  const images = props?.images;
  const { t } = useTranslation();

  return (
    <div className="multiple-images-view-wrapper">
      <div className="multiple-images-view-container">
        <div className="images-section">
          {Array.isArray(images) && images.length > 0 &&
            images.map((item: any, index: any) => {
              return (
                <img key={index} src={item} />
              );
            })}
          {Array.isArray(images) && images.length === 0 && (
            <NotFoundComponent
              label={t('NO_PHOTOS_UPLOADED')}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MultipleImagesViewComponent;