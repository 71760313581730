/* eslint-disable camelcase */
export const PulseRate = [
  {
    min: 1,
    max: 10,
    pulse_rate: [
      {
        min: 0,
        max: 68,
        value: 0
      },
      {
        min: 69,
        max: 129,
        value: 1
      },
      {
        min: 130,
        max: 'above',
        value: 0
      }
    ]
  },
  {
    min: 0,
    max: 0.11,
    pulse_rate: [
      {
        min: 59,
        max: 99,
        value: 1
      },
      {
        min: 0,
        max: 58,
        value: 0
      },
      {
        min: 100,
        max: 'above',
        value: 0
      }
    ]
  },
  {
    min: 11,
    max: 'above',
    pulse_rate: [
      {
        min: 59,
        max: 99,
        value: 1
      },
      {
        min: 0,
        max: 58,
        value: 0
      },
      {
        min: 100,
        max: 'above',
        value: 0
      }
    ]
  }
];

export const getPulseRateCalculation = (age_year: any, value: any): any => {
  try {
    for (const pr of PulseRate) {
      if (age_year >= pr.min && (age_year <= pr.max || pr.max === 'above')) {
        for (const item of pr.pulse_rate) {
          if (
            value >= item.min &&
            (value <= item.max || item.max === 'above')
          ) {
            return item.value;
          }
        }
      }
    }
    return null;
  } catch (e) {
    throw new Error((e as any)?.message);
  }
};
