/* eslint-disable camelcase */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton
} from '@material-ui/core';
import Icons from '@components/system/MyIcons';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import {useTranslation} from 'react-i18next';
import {
  AB_NORMAL_BMI_COLOR,
  APP_BOOKED_STATUS_DOT_COLOR,
  APP_COMPLETED_STATUS_DOT_COLOR,
  DEFAULT_WHITE_COLOR,
  GET_8_PIXEL_BOX,
  MEDIUM_BMI_COLOR,
  MESSAGE_BORDER_COLOR,
  NORMAL_BMI_COLOR
} from 'src/style/variable';
import {thresholdRangeArray} from 'src/constants/VitalsThresholdValues';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';

import './AddVitals.scss';
import HelperUtils from 'src/utils/HelperUtils';
import {VitalKeys} from 'src/constants/FormFields';

export const inputTextFieldProps = {
  padding: '0rem !important',
  width: '60%',
  marginLeft: '1.625rem',
  border: `0.0625rem solid red`,
  borderRadius: '0.1875rem',
  height: '1.875rem'
};

export const inputFieldProps = {
  padding: '0.5rem, 0.5rem, 0.5rem, 0.5rem',
  border: `0.0625rem solid ${MESSAGE_BORDER_COLOR}`,
  height: '1.875rem',
  borderRadius: '0.1875rem'
};

export const inputFieldPropsPositive = {
  padding: '0.5rem, 0.5rem, 0.5rem, 0.5rem',
  border: `0.0625rem solid ${APP_COMPLETED_STATUS_DOT_COLOR}`,
  height: '1.875rem',
  borderRadius: '0.1875rem'
};

export const inputFieldPropsNegative = {
  padding: '0.5rem, 0.5rem, 0.5rem, 0.5rem',
  border: `0.0625rem solid ${APP_BOOKED_STATUS_DOT_COLOR}`,
  height: '1.875rem',
  borderRadius: '0.1875rem'
};

export const inputFieldPropsLessThan = {
  padding: '0.5rem, 0.5rem, 0.5rem, 0.5rem',
  border: `0.0625rem solid ${MEDIUM_BMI_COLOR}`,
  height: '1.875rem',
  borderRadius: '0.1875rem'
};

export const notesTextFieldProps = {
  width: '100%',
  marginTop: '0.625rem',
  border: `0.0625rem solid ${MESSAGE_BORDER_COLOR}`,
  minHeight: '5.375rem',
  maxHeight: '5.475rem',
  overflowY: 'auto',
  borderRadius: '0.1875rem',
  fontSize: '0.875rem'
};

const textFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  opacity: 1,
  fontSize: '1.2rem',
  color: 'green'
};

const commonProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  borderRadius: GET_8_PIXEL_BOX(0.5),
  opacity: 1,
  fontSize: '1.2rem'
};

const normalTextFieldProps = {
  ...commonProps,
  border: `1px solid ${NORMAL_BMI_COLOR}`
};

const abNormalTextFieldProps = {
  ...commonProps,
  border: `1px solid ${AB_NORMAL_BMI_COLOR}`
};

const mediumTextFieldProps = {
  ...commonProps,
  border: `1px solid ${MEDIUM_BMI_COLOR}`
};

const AddVitals = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [open, setOpen] = React.useState(props?.open);
  const {
    categoryArray,
    bmiVal,
    values,
    setValues,
    isVitalsEdit,
    isNotEditable,
    patientDetails
  } = props;
  const [vitalRangeValues, setVitalRangeValues]: any = React.useState({});
  // const [vitalValues, setVitalValues]: any = React.useState({});

  const handleClose = () => {
    setOpen(false);
    props?.closeAddVitalsPopUp();
  };

  const prePopulateRangeValues = (vitalKey: string, responseData: any) => {
    const gender = patientDetails?.appointment?.person_details?.gender;
    const dob = patientDetails?.appointment?.person_details?.dob;
    const dataObject = HelperUtils.getVitalCalculations(
      gender,
      dob,
      vitalKey,
      Number(responseData?.[vitalKey]?.value),
      Number(responseData?.height?.value),
      Number(responseData?.weight?.value)
    );

    const data = vitalRangeValues;
    data[vitalKey] = dataObject?.vital_value;
    setVitalRangeValues({
      ...vitalRangeValues,
      [vitalKey]: dataObject?.vital_value
    });
  };

  const init = () => {
    isVitalsEdit &&
      Object.values(VitalKeys)?.forEach((item: any) => {
        prePopulateRangeValues(item, values);
      });
  };

  React.useEffect(init, []);

  const handleBlur = (vitalKey: any) => {
    const gender = patientDetails?.appointment?.person_details?.gender;
    const dob = patientDetails?.appointment?.person_details?.dob;
    const dataObject = HelperUtils.getVitalCalculations(
      gender,
      dob,
      vitalKey,
      Number(values?.[vitalKey]?.value),
      Number(values?.height?.value),
      Number(values?.weight?.value)
    );

    if (dataObject?.error_data?.message) {
      enqueueSnackbar(dataObject?.error_data?.message, SnackBarConfig('w'));
    } else {
      setVitalRangeValues({
        ...vitalRangeValues,
        [vitalKey]: dataObject?.vital_value
      });

      const updatedDataObject: any = vitalRangeValues;
      updatedDataObject[vitalKey] = dataObject?.vital_value;

      if (vitalKey === VitalKeys.HEIGHT || vitalKey === VitalKeys.WEIGHT) {
        if (Number(values?.height?.value) && Number(values?.weight?.value)) {
          const bmiDataObject = HelperUtils.getVitalCalculations(
            gender,
            dob,
            VitalKeys.BMI,
            null,
            Number(values?.height?.value),
            Number(values?.weight?.value)
          );

          setValues({...values, [VitalKeys.BMI]: bmiDataObject?.bmi_value});
          updatedDataObject[VitalKeys.BMI] = bmiDataObject?.vital_value;
          setVitalRangeValues(updatedDataObject);
        } else {
          setValues({...values, [VitalKeys.BMI]: null});
          setVitalRangeValues(updatedDataObject);
        }
      }
    }
  };

  const handleChange = (prop: any) => (event: any) => {
    const newValue = event.target.value;
    const newValue1 =
      event.target.value?.length > 1 ? newValue.slice(-1) : event.target.value;

    if (!newValue1.match(/^([^0-9.]*)$/) || newValue == '') {
      setValues({...values, [prop]: {value: event.target.value}});
    }
  };

  const handleChangeNotes = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleSubmit = (): void => {
    const keys = Object.keys(values);

    const data: any = {};

    keys.forEach((key, index) => {
      const color = `${key}_color`;
      if (key == 'bmi') {
        data[key] = bmiVal;
        data[color] = vitalRangeValues[key];
      } else {
        data[key] = values[key]?.value;
        data[color] = vitalRangeValues[key];
      }
    });

    const saveVitals = {vitals: data, comments: values?.notes};

    isVitalsEdit
      ? props?.handleEditVitalsAnswer(saveVitals)
      : props?.handleAddVitalsAnswer(saveVitals);

    handleClose();
  };

  const renderGivenFields = (
    label: any,
    value: any,
    unit: any,
    rangeVal: any,
    first?: any
  ) => {
    return (
      <Grid item container className="inside-vital">
        <Grid item xs={5} className="value-name-unit-grid">
          <span className="value-name">{label}</span>
          {label?.length > 10 && <br />}
          <span className="unit-name">{unit}</span>
        </Grid>
        <Grid item xs={3} className="make-self-align-center ">
          <FormTextField
            disabled={isNotEditable && true}
            name={value}
            value={values[value]?.value}
            autoFocus={label == first && true}
            onBlurCallback={() => handleBlur(value)}
            maxLength="5"
            className={
              values?.[value] &&
              value === VitalKeys.BMI &&
              (vitalRangeValues?.[value] === 1
                ? 'normal-bmi-color'
                : vitalRangeValues?.[value] === 0
                ? 'ab-normal-bmi-color'
                : vitalRangeValues?.[value] === 0.5 && 'medium-bmi-color')
            }
            componentTextFieldProps={
              value === VitalKeys.BMI
                ? textFieldProps
                : values?.[value] && vitalRangeValues?.[value] === 1
                ? normalTextFieldProps
                : values?.[value] && vitalRangeValues?.[value] === 0
                ? abNormalTextFieldProps
                : values?.[value] &&
                  vitalRangeValues?.[value] === 0.5 &&
                  mediumTextFieldProps
            }
            callback={handleChange(value)}
          />
        </Grid>
      </Grid>
    );
  };

  // returns all value
  const renderFieldInfo = (
    label: any,
    value: any,
    unit: any,
    data: any,
    first?: any
  ) => {
    const rangeVal = data
      ? thresholdRangeArray.filter((range: any) =>
          data?.filter((j: any) => j?.label == range?.name)
        )
      : thresholdRangeArray.filter((range: any) => range?.name == label);
    if (data && data.length > 0) {
      return (
        <Grid item container className="inside-vital array-label-name-grid">
          <Grid item className="value-name-unit-grid">
            <span className="array-label-name">{label}</span>
          </Grid>
          {data.map((i: any) => {
            return renderGivenFields(
              i?.label,
              i?.value,
              i?.unit,
              rangeVal,
              first
            );
          })}
        </Grid>
      );
    } else {
      return renderGivenFields(label, value, unit, rangeVal, first);
    }
  };

  // returns bmi value
  const bmiCalculation = () => {
    if (values?.height?.value && values?.weight?.value) {
      props?.handleBMICalculation(values?.height?.value, values?.weight?.value);
    }
  };

  React.useEffect(bmiCalculation, [values?.height, values?.weight]);

  const renderBmiInfo = (label: any, unit: any) => {
    const rangeVal = thresholdRangeArray.filter(
      (range: any) => range?.name == label
    );
    return (
      <Grid container className="vital-information-left-grid">
        <Grid item xs={5} className="value-name-unit-grid bmi-grid">
          <span className="value-name">{label}</span>
          <span className="unit-name">{unit}</span>
        </Grid>
        <Grid
          item
          xs={3}
          className={
            bmiVal
              ? bmiVal <= rangeVal?.[0]?.max && bmiVal >= rangeVal?.[0]?.min
                ? 'make-self-align-center bmi-color-positive'
                : 'make-self-align-center bmi-color-negative'
              : 'make-self-align-center'
          }>
          <span>
            {values?.height && values?.weight && bmiVal ? bmiVal : label}
          </span>
        </Grid>
      </Grid>
    );
  };

  // returns nurse notes
  const renderNurseNotes = () => {
    return (
      <Grid container className="notes-input">
        {t('ADD_VITALS_NURSE_NOTES')}
        <Grid item xs={12}>
          <FormTextField
            disabled={isNotEditable && true}
            name="notes"
            value={values?.notes}
            componentTextFieldProps={notesTextFieldProps}
            multiline={true}
            callback={handleChangeNotes('notes')}
          />
        </Grid>
      </Grid>
    );
  };

  // returns dialog title
  const renderAddVitalsDialogTitle = () => {
    return (
      <Grid container className="title">
        <Grid item xs={11} className="add-vitals">
          {isVitalsEdit ? t('COMMON_EDIT') : t('COMMON_ADD')} {t('VITALS')}
        </Grid>
        <Grid item xs={1} className="icon">
          <IconButton id="close_popup" onClick={handleClose}>
            <Icons.CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };
  // returns dialog content
  const renderAddVitalsDialogContent = () => {
    const divideIn = Math.ceil(categoryArray?.length / 2);
    const modifiedArray = [];
    categoryArray && modifiedArray.push(categoryArray.slice(0, divideIn));
    categoryArray && modifiedArray.push(categoryArray.slice(divideIn));
    const first = categoryArray[0]?.label;
    return (
      <Grid container className="vital-information-wrapper">
        {modifiedArray?.map((categories: any, index1: any) => {
          return (
            <Grid
              item
              key={index1}
              xs={12}
              md={6}
              direction="row"
              className="vital-information-grid">
              {categories?.map((item: any, index: any) => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    md={12}
                    className="vital-information-left-grid-one">
                    {item?.label == 'BMI'
                      ? renderBmiInfo(item?.label, item?.unit)
                      : renderFieldInfo(
                          item?.label,
                          item?.value,
                          item?.unit,
                          (item?.data && item?.data) || '',
                          first
                        )}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
        {renderNurseNotes()}
      </Grid>
    );
  };

  // returns dialog actions
  const renderAddVitalsDialogAction = () => {
    return (
      <Grid container className="dialog-action-button-grid">
        <Button id="cancel_vitals" variant="outlined" onClick={handleClose}>
          {t('COMMON_CANCEL')}
        </Button>
        <Button
          id="submit_vitals"
          disabled={isNotEditable && true}
          className="save-button"
          variant="contained"
          onClick={handleSubmit}>
          {isVitalsEdit ? t('UPDATE') : t('SAVE')} {t('VITALS')}
        </Button>
      </Grid>
    );
  };

  // returns the Dialog component
  return (
    <Dialog open={open} onClose={handleClose} className="add-vitals-wrapper">
      <DialogTitle>{renderAddVitalsDialogTitle()}</DialogTitle>
      <Divider />
      <DialogContent className="title">
        {renderAddVitalsDialogContent()}
      </DialogContent>
      <DialogActions>{renderAddVitalsDialogAction()}</DialogActions>
    </Dialog>
  );
};

export default AddVitals;
