import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as LabServiceIconSvg} from '@assets/images/healpha/LabService.svg';

const LabServiceIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={LabServiceIconSvg}
      viewBox="0 0 22 22"
    />
  );
};

export default LabServiceIcon;
