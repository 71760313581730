import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as AddSubmenuIconSvg} from '@assets/images/healpha/AddSubmenu.svg';

const AddSubmenuIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={AddSubmenuIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default AddSubmenuIcon;
