import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiCssBaseline from './MuiCssBaseline';
import MuiFormControl from './MuiFormControl';

export default {
  MuiFormControl,
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiPopover: {
    paper: {
      boxShadow: 'none'
    }
  },
  MuiCssBaseline
};
