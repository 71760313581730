import React from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as PlayButtonIconSvg} from '@assets/images/healpha/PlayButton.svg';

const PlayButtonIcon = (props: any): React.ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      style={{width: 'inherit', height: 'inherit'}}
      className={className}
      component={PlayButtonIconSvg}
      viewBox="0 0 60 60"
    />
  );
};

export default PlayButtonIcon;
