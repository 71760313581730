/* eslint-disable camelcase */

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {connect} from 'react-redux';
import {Grid, IconButton, Typography} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import HospitalIcon from '@components/system/MyIcons/HospitalIcon';
import {
  BASE_ROUTE_BRANCH,
  BASE_ROUTE_PORTAL
} from 'src/constants/route/BaseRoute';
import NavRoutes from 'src/constants/route/NavRoutes';
import CommonUtils from 'src/utils/CommonUtils';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import './HospitalViewDescriptionComponent.scss';
import HospitalQrPopUp from './HospitalQrPopup';
import GetDirectionsIcon from '@components/system/MyIcons/GetDirectionsIcon';

// it includes hospital description
const HospitalViewDescriptionComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const [info, setInfo]: any = useState({});
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const [openQrPopup, setOpenQrPopup] = useState<boolean>(false);

  // call get hospital details api
  const getHospitalDetails = async () => {
    try {
      const data: any = {};
      if (props?.hospital) {
        const branch = props.hospital;
        data.name = branch?.branch_name || branch?.practice_name;
        data.branch_id = branch?.branch_id;
        data.address =
          branch?.address1 +
          ',' +
          (branch?.address2 == null ? '' : branch?.address2);
        data.city = branch?.city;
        data.pincode = branch?.pincode;
        data.state = branch?.state;
        data.tagline = branch?.tagline;
        data.doctorsCount = branch?.doctors_count;
        data.profileImage = CommonUtils.getBranchImageUrl(branch?.image);
        data.qrcode = CommonUtils.getBranchImageUrl(branch?.qrcode);
        CommonUtils.getBranchSpecialization(branch, data, t);
        data.showTimings = branch.branch_name;
        CommonUtils.getHospitalTimings(branch, data, t);
      }

      if (data?.workTimings?.[0]?.value) {
        data.workTimings[0].value = data.workTimings[0].value.toUpperCase();
      }
      setInfo(data);
    } catch (error) {
      enqueueSnackbar(
        (error as any)?.message || t('ERROR_ENCOUNTERED'),
        SnackBarConfig('e')
      );
    }
  };

  const init = () => {
    getHospitalDetails();
  };

  useEffect(init, [props?.hospital]);

  const moveToHospitalViewRoute = () => {
    if (props?.showRoute)
      history.push(
        `/${BASE_ROUTE_PORTAL}/home/${BASE_ROUTE_BRANCH}/${NavRoutes.PUBLIC.HOSPITAL.VIEW}` +
          `/${info.branch_id}/${NavRoutes.PUBLIC.HOSPITAL.TABS.DOCTORS}`
      );
  };
  const closeQrpagePopup = (): void => {
    setOpenQrPopup(false);
  };
  const displayQr = () => {
    setOpenQrPopup(true);
  };
  const renderQrPopup = () => {
    return (
      <HospitalQrPopUp
        open={openQrPopup}
        closeQrpagePopup={closeQrpagePopup}
        hospitalInfo={props?.hospital}
      />
    );
  };
  const openLocationLink = () => {
    const url = props?.hospital?.map_link;
    window.open(url, '_blank', 'noreferrer');
  };
  return (
    <Grid className="hospital-view-description-wrapper">
      <Grid className="hospital-view-description-container">
        <Grid
          container
          className="hospital-view-description-content"
          spacing={2}>
          <Grid
            item
            xs={12}
            md={props?.showRoute ? 2 : 3}
            className="container-child-1">
            {info?.profileImage ? (
              <img className="img" src={info?.profileImage} />
            ) : (
              <HospitalIcon className="img default-img" />
            )}
          </Grid>

          <Grid
            className="container-child-2"
            item
            xs={12}
            md={props?.showRoute ? 10 : 9}>
            <Typography
              className={`title-1 ${
                props?.showRoute && 'hospital-name-link-text'
              } ${
                props?.whitelablingInfo?.isBranch
                  ? 'whitelabel-name-big-font'
                  : ''
              }`}
              onClick={moveToHospitalViewRoute}>
              {info?.name ? info.name : '-'}
            </Typography>

            {props?.hospital?.practice_module?.[0].access_module_id == 2 ? (
              <Typography className="title-2">
                {info?.speciality
                  ? `${info?.speciality} ${t('HOSPITAL')} . `
                  : ''}
                {info?.doctorsCount ? info?.doctorsCount : 0} {t('DOCTORS')}
              </Typography>
            ) : (
              <Typography className="taglineFont">{info?.tagline}</Typography>
            )}

            <Typography className="title-2">
              {info?.address ? `${info.address}, ` : ''}
              <br />
              {info?.city ? `${info.city},${info.state}-${info.pincode}.` : ''}
              <IconButton onClick={openLocationLink}>
                <GetDirectionsIcon />
              </IconButton>
            </Typography>
            {props?.hospital?.practice_module?.[0]?.access_module_id == 2
              ? info?.showTimings &&
                info?.workTimings?.length > 0 && (
                  <Grid
                    className="bottom-section"
                    container
                    // alignItems="center"
                    // spacing={2}
                  >
                    <Grid container xs={9}>
                      <Typography className="left-title">
                        {t('TIMINGS')}:
                      </Typography>

                      <Typography className="right-title">
                        {`${info?.workTimings?.[0]?.label}, ${info?.workTimings?.[0]?.value}`}
                      </Typography>
                    </Grid>
                    <Grid item className="mainQrGrid">
                      <Grid className="qrcodeGrid">
                        {info?.qrcode && (
                          <img
                            className="Qrimg"
                            src={info?.qrcode}
                            onClick={displayQr}
                          />
                        )}
                      </Grid>
                      <Typography className="scanQrFont">
                        {t('SCAN_QR')}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              : ''}
          </Grid>
        </Grid>
      </Grid>
      {openQrPopup && renderQrPopup()}
    </Grid>
  );
};

function mapStateToProps(state: any) {
  return {
    branchId: state.common.branchId,
    practiceId: state.common.practiceId,
    whitelablingInfo: state.common.whitelablingInfo
  };
}

export default connect(mapStateToProps, {})(HospitalViewDescriptionComponent);
