import React from 'react';
import PhoneInput from 'react-phone-input-2';

import {environment} from '@shared/environment/environment';

import 'react-phone-input-2/lib/style.css';

interface FormPhoneFieldProps {
  name: string;
  placeholder?: string;
  callback?: any;
  countryCode?: any;
  phoneNumber?: any;
  disabled?: boolean;
  className?: any;
}

const FormPhoneField = (props: FormPhoneFieldProps): React.ReactElement => {
  const {
    placeholder,
    name,
    callback,
    phoneNumber,
    countryCode,
    disabled,
    className
  } = props;

  return (
    <PhoneInput
      containerClass="phone-wrapper-container"
      inputClass={`phone-input-container ${className && className}`}
      dropdownClass="phone-drop-down-container"
      value={phoneNumber}
      country={countryCode}
      onlyCountries={[environment.countryCode]}
      inputProps={{name: name, id: name, value: phoneNumber}}
      placeholder={placeholder}
      countryCodeEditable={false}
      disabled={disabled}
      disableDropdown={true}
      onChange={(value: any, country: any, e: any, formattedValue: any) => {
        if (callback && typeof callback === 'function') {
          callback(value, country, e, formattedValue, name);
        }
      }}
    />
  );
};

export default FormPhoneField;
