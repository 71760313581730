import React from 'react';
import {
  LocalizationProvider,
  MobileDatePicker,
  DatePicker
} from '@material-ui/lab';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import {Grid, TextField} from '@material-ui/core';

interface FormDatePickerFieldProps {
  id?: string;
  name: string;
  callback?: any;
  value: any;
  textFieldProps: any;
  Icon: any;
  readOnly?: boolean;
  disabled?: boolean;
  minDate?: any;
  maxDate?: any;
  InputAdornmentPosition?: any;
  className?: any;
  views?: any;
}

const renderTextField = (
  params: any,
  name: string,
  textFieldProps: any,
  className: any
): React.ReactElement => {
  return (
    <TextField
      {...params}
      fullWidth
      placeholder="Enter DOB"
      sx={textFieldProps}
      helperText=""
      variant="outlined"
      id={name}
      name={name}
      size="medium"
      className={className}
    />
  );
};

const FormDatePickerField = (
  props: FormDatePickerFieldProps
): React.ReactElement => {
  const {
    id,
    name,
    value,
    textFieldProps,
    Icon,
    callback,
    readOnly,
    disabled,
    minDate,
    maxDate,
    InputAdornmentPosition,
    className,
    views
  } = props;

  return (
    <LocalizationProvider dateAdapter={MomentAdapter}>
      <Grid sx={{display: {xs: 'block', lg: 'none'}}}>
        <MobileDatePicker
          value={value}
          views={views}
          clearable={true}
          readOnly={readOnly}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          openPickerIcon={<Icon id={id} />}
          InputAdornmentProps={{position: InputAdornmentPosition || 'start'}}
          onChange={newValue => {
            if (callback && typeof callback === 'function') {
              callback(newValue);
            }
          }}
          renderInput={(params: any) =>
            renderTextField(params, name, textFieldProps, className)
          }
        />
      </Grid>

      <Grid sx={{display: {xs: 'none', lg: 'block'}}}>
        <DatePicker
          value={value}
          views={views}
          clearable={true}
          readOnly={readOnly}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          openPickerIcon={<Icon id={id} />}
          InputAdornmentProps={{position: InputAdornmentPosition || 'start'}}
          onChange={newValue => {
            if (callback && typeof callback === 'function') {
              callback(newValue);
            }
          }}
          renderInput={(params: any) =>
            renderTextField(params, name, textFieldProps, className)
          }
        />
      </Grid>
    </LocalizationProvider>
  );
};

export default FormDatePickerField;
