/* eslint-disable camelcase */
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {useHistory} from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} from '@material-ui/core';

import {
  bookDoctorAppointment,
  // getConsultationItem,
  getPersonDetails,
  sendBookAppointmentEmail,
  setPhpSession
} from '@shared/services/PublicService';
import {environment} from '@shared/environment/environment';
import InitSharedRepo from '../../../../../../shared';

import CommonDialogTitleComponent from '../CommonDialogTitle/CommonDialogTitleComponent';
import CommonUtils from 'src/utils/CommonUtils';
import AuthUtils from 'src/utils/AuthUtils';
import TemplateUtils from 'src/utils/TemplateUtils';
import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';
import NavRoutes from 'src/constants/route/NavRoutes';
import EmailUpdateComponent from '../EmailUpdate/EmailUpdateComponent';
import {CommunicationService} from 'src/service/CommunicationService';
import {STANDALONE_APPOINTMENT_UPDATE} from 'src/constants/CommunicationServiceConstants';
import ConfirmationPopupComponent from '../ConfirmationPopUp/ConfirmationPopupComponent';
import ConfirmationBoxComponent from '../ConfirmationBox/ConfirmationBoxComponent';
import Loader from '@components/framework/loader/Loader';
import AddPatientComponent from '../AddPatient/AddPatientComponent';
import Icons from '@components/system/MyIcons/index';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import FormCombinationField from '@components/system/CustomFormField/FormCombinationField';
import FormDatePickerField from '@components/system/CustomFormField/FormDatePickerField';
import FormPhoneField from '@components/system/CustomFormField/FormPhoneField';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {salutationOptionsList} from 'src/constants/FormFields';
import {
  CountryCodes,
  PaymentProviders,
  PaymentTypes,
  TokensObject,
  AppointmentStatusTypes
} from 'src/constants/SystemConstants';
import {
  BORDER_TERTIARY_COLOR,
  DEFAULT_WHITE_COLOR,
  GET_8_PIXEL_BOX
} from 'src/style/variable';
import './ReviewUserAppointmentComponent.scss';

const textFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  border: `1px solid ${BORDER_TERTIARY_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.5),
  opacity: 1,
  fontSize: '1.2rem'
};

const ReviewUserAppointmentComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();

  const [open, setOpen] = React.useState(props?.open);
  const [openPatientDetails, setOpenPatientDetails] = React.useState(false);
  const [openEmailUpdate, setOpenEmailUpdate] = React.useState(false);
  const [openConfirmationBox, setOpenConfirmationBox] = React.useState(false);
  const [openInfoPopup, setOpenInfoPopup] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [values, setValues]: any = React.useState(props?.values);
  const [paymentTypes, setPaymentTypes]: any = React.useState([]);
  const [isOnlineAppointment, setIsOnlineAppointment]: any = useState();
  const [isPaymentZero, setIsPaymentZero]: any = useState();
  const [appointmentId, setAppointmentId]: any = useState();
  const {paymentProvider} = environment;

  const init = () => {
    if ((!values || Object.keys(values).length == 0) && props?.healphaId) {
      getPersonDetails(props?.healphaId)
        .then(res => {
          if (res?.data) {
            setValues({
              first_name: res?.data?.first_name,
              last_name: res?.data?.last_name,
              phone_code: res?.data?.phone_code,
              phone_number: res?.data?.phone_no,
              email: res?.data?.email,
              date_of_birth: res?.data?.dob,
              gender: res?.data?.gender,
              salutation: res?.data?.salutation
            });
          }
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
      setValues();
    }
  };

  React.useEffect(init, []);

  const initAppointmentType = () => {
    let locPaymentTypes: any = [];
    // if payment provider exists, check for online or offline and payment types both
    if (paymentProvider) {
      locPaymentTypes = [
        {id: 1, label: t('PAY_NOW'), value: 'pay_now'},
        {id: 2, label: t('PAY_AT_HOSPITAL'), value: 'agent'}
      ];
      setPaymentTypes(locPaymentTypes);
      setIsOnlineAppointment(
        props?.appointmentValues?.appointment?.appointment_type === 'online'
      );
      setIsPaymentZero(
        props?.appointmentValues?.appointment?.price &&
          !parseInt(props?.appointmentValues?.appointment?.price)
      );
    } else {
      // if payment provider does not exist then only show pay at hospital option
      locPaymentTypes = [{id: 2, label: t('PAY_AT_HOSPITAL'), value: 'agent'}];
      setPaymentTypes(locPaymentTypes);
    }
  };

  React.useEffect(initAppointmentType, [
    props?.appointmentValues?.appointment?.appointment_type
  ]);

  const handleClose = (event?: any, reason?: any) => {
    setOpen(false);
    props?.closeReviewPopUp();
  };

  const closeConfirmationBox = (): void => {
    handleClose();
  };

  const closeInfoBox = (action: boolean): void => {
    handleClose();

    if (
      action &&
      (props?.isFromVirtualClinic || props?.isStandAloneDoctor) &&
      !props?.isFromPersonWeb
    ) {
      TemplateUtils.setSelectedPatientId(props?.healphaId);
      TemplateUtils.clearPaymentDetails();
      TemplateUtils.clearPaymentCriteria();
      TemplateUtils.clearServices();
      TemplateUtils.clearCorporatePersonDetails();

      AuthUtils.setBillingAppointmentDetails(
        JSON.stringify({
          appointment_id: appointmentId,
          appointment_type:
            props?.appointmentValues?.appointment?.appointment_type_value,
          appointment_status: AppointmentStatusTypes.BOOKED,
          doc_id: props?.appointmentValues?.doctor?.non_healpha_user_id,
          enc_id: props?.appointmentValues?.encounterId,
          appointment_date: props?.appointmentValues?.calendarValue,
          cloud_clinic_branch_id: props?.appointmentValues?.branch?.branch_id,
          is_virtual: props?.isFromVirtualClinic ? true : false,
          is_online_appointment:
            isOnlineAppointment ||
            props?.appointmentValues?.is_online_appointment
        })
      );

      let url = NavRoutes.PUBLIC.BILLING.BILLING_PATIENT_SCREEN;
      if (props?.isStandAloneDoctor) {
        url += `?isFromStandAlone=${true}`;
      }

      history.push(url);
    } else if (props?.isFromPersonWeb) {
      history.push(`/portal/user/${NavRoutes.PUBLIC.PERSON_WEB_TIMELINE}`);
      CommunicationService.sendMessage({
        type: STANDALONE_APPOINTMENT_UPDATE,
        value: true
      });
    } else if (props?.isFromVirtualClinic) {
      history.push(`/portal/user/${NavRoutes.PUBLIC.VIRTUAL_CLINIC_DASHBOARD}`);
      CommunicationService.sendMessage({
        type: STANDALONE_APPOINTMENT_UPDATE,
        value: true
      });
    } else {
      history.push(`/portal/user/${NavRoutes.PUBLIC.APPOINTMENTS}`);

      CommunicationService.sendMessage({
        type: STANDALONE_APPOINTMENT_UPDATE,
        value: true
      });
    }
  };

  // const openAddPatientPopup = (): void => {
  //   setOpen(false);
  //   setOpenPatientDetails(true);
  // };

  const closePatientPopup = (closeBothPopups?: boolean) => {
    setOpenPatientDetails(false);

    if (closeBothPopups) {
      handleClose();
    } else {
      setOpen(true);
    }
  };

  const continueTheBookAppointment = async (
    payment_id?: any,
    order_id?: any
  ) => {
    try {
      if (props?.healphaId) {
        setIsLoading(true);
        const apiProps = CommonUtils.formBookAppointmentProps(
          props,
          props?.isPaymentZeroForReview
        );
        if (payment_id) {
          apiProps.payment_id = payment_id;
        }
        if (order_id) {
          apiProps.order_id = order_id;
        }

        if (props?.isFromVirtualClinic) {
          apiProps.virtual_clinic_branch = props?.virtualClinicBranchId;
        }

        const res: any = await bookDoctorAppointment(
          apiProps,
          props?.healphaId
        );

        if (res?.data?.id) {
          InitSharedRepo.phpUrlInit();

          const data = {
            appointment_id: res.data.id,
            doc_name: CommonUtils.getFullName(props?.appointmentValues?.doctor),
            branch_name: props?.appointmentValues?.branch?.branch_name
          };

          await sendBookAppointmentEmail(data);

          const dataObject = {
            tk: AuthUtils.getAuthToken(),
            type: CommonUtils.PhpPortalParamTypes.PERSON_LOGIN,
            jwt_token: TokensObject.REACT_PHP
          };

          await setPhpSession(dataObject);

          InitSharedRepo.init();
          setIsLoading(false);

          if (
            (props?.isFromVirtualClinic || props?.isStandAloneDoctor) &&
            !props?.isFromPersonWeb
          ) {
            setAppointmentId(res.data.id);
            setOpenInfoPopup(true);
          } else {
            setOpenConfirmationBox(true);
          }
          setOpen(false);
        }
      } else {
        enqueueSnackbar(t('USER_ID_MISSING_WARNING'), SnackBarConfig('w')); // TODO BACK-END MESSAGE
      }
    } catch (err) {
      InitSharedRepo.init();
      setIsLoading(false);
      enqueueSnackbar(
        (err as any)?.message || t('ERROR_ENCOUNTERED'),
        SnackBarConfig('e')
      );
    }
  };

  const handleSubmit = async (email: any): Promise<any> => {
    try {
      if (
        !isOnlineAppointment &&
        !props?.appointmentValues?.is_online_appointment &&
        !values?.payment_type &&
        !isPaymentZero &&
        // !props?.isFromVirtualClinic &&
        !props?.isStandAloneDoctor
      ) {
        enqueueSnackbar(t('PAYMENT_METHOD_REQUIRED'), SnackBarConfig('w'));
        return;
      }
      // first validate user can book the appointment or not
      setIsLoading(true);
      const apiProps = CommonUtils.formBookAppointmentProps(
        props,
        props?.isPaymentZeroForReview
      );
      apiProps.validate_fields = true;

      // apiProps?.is_online &&
      //   props?.isFromPersonWeb &&
      //   getConsultationItem(
      //     props?.healphaId,
      //     apiProps.branch_id,
      //     apiProps.doctor_id,
      //     apiProps.appointment_type,
      //     apiProps.appointment_date,
      //     apiProps?.encounter_id,
      //     'General',
      //     false,
      //     '',
      //     apiProps?.is_online || isOnlineAppointment
      //   ).then(res => {
      //     // eslint-disable-next-line no-console
      //     console.log('res of consultaiton line item is ', res);
      //     setIsPaymentZero(res?.data?.consultation?.service_amount == 0);
      //   });
      if (props?.isFromVirtualClinic && !props?.isFromPersonWeb) {
        apiProps.virtual_clinic_branch = props?.virtualClinicBranchId;
      }
      !props?.isFromPersonWeb &&
        (await bookDoctorAppointment(apiProps, props?.healphaId));
      setIsLoading(false);

      const payNow =
        isOnlineAppointment ||
        props?.appointmentValues?.is_online_appointment ||
        values?.payment_type === 'pay_now';
      if (
        payNow &&
        (!props?.isFromVirtualClinic || props?.isFromPersonWeb) &&
        !props?.isStandAloneDoctor
      ) {
        if (isPaymentZero || props?.isPaymentZeroForReview) {
          continueTheBookAppointment(
            CommonUtils.PAYMENT_ZERO,
            CommonUtils.PAYMENT_ZERO
          );
        } else {
          if (
            paymentProvider === PaymentProviders.RAZORPAY ||
            paymentProvider === PaymentProviders.WEBXPAY
          ) {
            if (email) {
              // go to payment page
              history.push(
                `/${BASE_ROUTE_PORTAL}/landing/${NavRoutes.PUBLIC.PAYMENT_SUCCESS}` +
                  `?doctor_id=${apiProps.doctor_id}&branch_id=${apiProps.branch_id}&appointment_date=${apiProps.appointment_date}` +
                  `&appointment_time=${apiProps.appointment_time}&appointment_type=${apiProps.appointment_type}` +
                  `&description=${apiProps.description}&amount=${apiProps.amount}&type=${PaymentTypes.PORTAL_APPOINTMENT}&hlp_id=${props?.healphaId}` +
                  `&isStandAloneDoctor=${
                    props?.isStandAloneDoctor ? true : ''
                  }&is_person_web=${
                    props?.isFromPersonWeb
                  }&is_online_appointment=${
                    apiProps?.is_online_appointment || isOnlineAppointment
                  }&encounter_id=${apiProps?.encounter_id}`
              );
            } else {
              setOpenEmailUpdate(true);
            }
          } else {
            enqueueSnackbar(t('INVALID_PAYMENT_PROVIDER'), SnackBarConfig('e'));
          }
        }
      } else {
        continueTheBookAppointment();
      }
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar(
        (err as any)?.message || t('ERROR_ENCOUNTERED'),
        SnackBarConfig('e')
      );
    }
  };

  const callbackEmailUpdate = (email: any) => {
    setValues({...values, ['email']: email});
    setOpenEmailUpdate(false);
    setIsLoading(true);
    setTimeout(() => {
      handleSubmit(true);
    }, 1000);
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const renderConfirmationBox = (): React.ReactElement => {
    return (
      <ConfirmationBoxComponent
        open={openConfirmationBox}
        closeConfirmationBox={closeConfirmationBox}
        closeInfoBox={closeInfoBox}
        isStandAloneDoctor={props?.isStandAloneDoctor}
        isAppointmentFlow={!props?.isFromVirtualClinic ? true : false}
        isFromPersonWeb={props?.isFromPersonWeb}
        message={t('APPOINTMENT_SUCCESS_MSG')}
      />
    );
  };

  const renderInformationPopup = (): React.ReactElement => {
    return (
      <ConfirmationPopupComponent
        open={openInfoPopup}
        action={closeInfoBox}
        message={t(
          'Appointment is Booked Successfully. Do you want to redirect Billing'
        )}
        yesLabel={t('Yes')}
        noLabel={t('No')}
      />
    );
  };

  const renderEmailUpdatePopup = (): React.ReactElement => {
    return (
      <EmailUpdateComponent
        open={openEmailUpdate}
        hlpId={props?.healphaId}
        callback={callbackEmailUpdate}
      />
    );
  };

  const renderPatientDetailsPopup = (): React.ReactElement => {
    return (
      <AddPatientComponent
        open={openPatientDetails}
        appointmentValues={props?.appointmentValues}
        values={values}
        healphaId={props?.healphaId}
        closePatientPopup={closePatientPopup}
      />
    );
  };

  const renderGridHelperInformation = (text: string): React.ReactElement => {
    return (
      <Grid item xs={12}>
        <div className="information-text">{text}</div>
      </Grid>
    );
  };

  const renderRadioButtons = (inputList: any): React.ReactElement => {
    return (
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="payment-type"
          id="payment-type"
          name="payment-type"
          onChange={handleChange('payment_type')}
          value={values?.userType}>
          {inputList?.map((item: any) => {
            return (
              <FormControlLabel
                key={item?.id}
                value={item?.value}
                sx={{marginBottom: 0}}
                control={<Radio size="medium" />}
                label={item?.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        {renderGridHelperInformation(t('APPOINTMENT_QUESTION'))}

        <Grid item xs={12}>
          <FormTextField
            placeholder={t('FAMILY_HEAD_PLACEHOLDER')}
            name="family_head"
            value={CommonUtils.convertTitleCase(
              values?.first_name + ' ' + values?.last_name
            )}
            adornmentPosition="start"
            Icon={Icons.RadioBoxSelectedIcon}
            readOnly={true}
          />
        </Grid>

        {/* {props?.showAddPatientLabel && (
          <Grid item xs={12}>
            <div className="permissions-text">
              {t('SOMEONE_ELSE')}
              <Link
                component="button"
                variant="body2"
                underline="always"
                onClick={() => openAddPatientPopup()}>
                {t('ADD_PATIENT_DETAILS')}
              </Link>
            </div>
          </Grid>
        )} */}

        {renderGridHelperInformation(
          t('CHECK_HEAD_INFO', {
            head_name: CommonUtils.convertTitleCase(
              values?.first_name + ' ' + values?.last_name
            )
          })
        )}

        <Grid item xs={12}>
          <FormCombinationField
            dropDownName={'salutation'}
            dropdownValue={values?.salutation}
            dropDownOptions={salutationOptionsList}
            textFieldName={'family_head'}
            id="peron_name"
            placeholder={t('FAMILY_HEAD_PLACEHOLDER')}
            textFieldValue={CommonUtils.convertTitleCase(
              values?.first_name + ' ' + values?.last_name
            )}
            readOnly={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {values?.phone_number && (
            <FormPhoneField
              name="phone_number"
              placeholder={t('MOBILE_NUMBER')}
              phoneNumber={
                (values.phone_code || CountryCodes?.INDIA) +
                ' ' +
                values.phone_number
              }
              // countryCode={values.phone_code}
              disabled={true}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            placeholder={t('EMAIL')}
            name="email"
            value={values?.email}
            adornmentPosition="start"
            Icon={Icons.MailIcon}
            readOnly={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormDatePickerField
            name="date_of_birth"
            value={values?.date_of_birth || values?.dob}
            textFieldProps={textFieldProps}
            Icon={Icons.DOBIcon}
            readOnly={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            placeholder={t('GENDER')}
            name="gender"
            value={values?.gender}
            adornmentPosition="start"
            Icon={Icons.GenderIcon}
            readOnly={true}
          />
        </Grid>
        {!isOnlineAppointment &&
          !props?.appointmentValues?.is_online_appointment &&
          !isPaymentZero &&
          !props?.isStandAloneDoctor &&
          // props?.isFromPersonWeb &&
          renderGridHelperInformation(t('SELECT_BELOW_PAYMENT_OPTION'))}
        {!isOnlineAppointment &&
          !props?.appointmentValues?.is_online_appointment &&
          !isPaymentZero &&
          !props?.isStandAloneDoctor && (
            <Grid item xs={12} md={6}>
              {renderRadioButtons(paymentTypes)}
            </Grid>
          )}
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Button
            id="review_and_book_appointment"
            variant="contained"
            size="medium"
            color="primary"
            className="submit-button"
            onClick={() => handleSubmit(values?.email)}
            disabled={isLoading}>
            {isLoading ? (
              <Loader size={28} />
            ) : (
              t('REVIEW_AND_BOOK_APPOINTMENT')
            )}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="review-appointment-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        open={open}
        aria-labelledby="review-appointment-pop-up"
        aria-describedby="review-appointment-otp"
        classes={{paper: 'booking-appointment-popup'}}>
        <DialogTitle
          id="review-appointment"
          className="booking-appointment-popup-title">
          <CommonDialogTitleComponent
            close={handleClose}
            appointmentValues={props?.appointmentValues}
          />
        </DialogTitle>

        <DialogContent className="booking-appointment-popup-content">
          {renderDialogContent()}
        </DialogContent>

        <DialogActions
          disableSpacing={true}
          className="booking-appointment-popup-actions">
          {renderDialogActions()}
        </DialogActions>
      </Dialog>
      {openPatientDetails && renderPatientDetailsPopup()}
      {openEmailUpdate && renderEmailUpdatePopup()}
      {openConfirmationBox && renderConfirmationBox()}
      {openInfoPopup && renderInformationPopup()}
    </div>
  );
};

export default ReviewUserAppointmentComponent;
