import React from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as ViewPatientHistoryIconIconSvg} from '@assets/images/healpha/viewPatientHistoryIcon.svg';

const ViewPatientHistoryIcon = (props: any): React.ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={ViewPatientHistoryIconIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default ViewPatientHistoryIcon;
