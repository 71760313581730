import React from 'react';
import {Worker} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';

import {Grid} from '@material-ui/core';
import {Viewer} from '@react-pdf-viewer/core';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';

import './PdfViewerComponent.scss';

const PdfViewerComponentNew: any = (props: any) => {
  const {samplePdf} = props;
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="App">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
        <Grid
          style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '750px'
          }}>
          <Viewer fileUrl={samplePdf} plugins={[defaultLayoutPluginInstance]} />
          ;
        </Grid>
      </Worker>
    </div>
  );
};

export default PdfViewerComponentNew;
