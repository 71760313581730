import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as DoctorIconSvg} from '@assets/images/healpha/doctor_avatar.svg';

const DoctorIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={DoctorIconSvg} viewBox="0 0 145 145" />
  );
};

export default DoctorIcon;