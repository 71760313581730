import Storage from './Storage';

class TemplateUtils {
  static getTemplateId(): any {
    return Storage.getItem(Storage.KEYS.TEMPLATE_ID);
  }

  static clearTemplateId(): void {
    Storage.removeItem(Storage.KEYS.TEMPLATE_ID);
  }

  static setTemplateId(templateId: any): void {
    this.clearTemplateId();
    Storage.setItem(Storage.KEYS.TEMPLATE_ID, templateId);
  }

  static getTemplateCategoryId(): any {
    return Storage.getItem(Storage.KEYS.TEMPLATE_CATEGORY_ID);
  }

  static clearTemplateCategoryId(): void {
    Storage.removeItem(Storage.KEYS.TEMPLATE_CATEGORY_ID);
  }

  static setTemplateCategoryId(categoryId: any): void {
    this.clearTemplateCategoryId();
    Storage.setItem(Storage.KEYS.TEMPLATE_CATEGORY_ID, categoryId);
  }
  static getTemplateMicroId(): any {
    return Storage.getItem(Storage.KEYS.TEMPLATE_MICRO_ID);
  }

  static clearTemplateMicroId(): void {
    Storage.removeItem(Storage.KEYS.TEMPLATE_MICRO_ID);
  }

  static setTemplateMicroId(microId: any): void {
    this.clearTemplateMicroId();
    Storage.setItem(Storage.KEYS.TEMPLATE_MICRO_ID, microId);
  }

  static getService(): any {
    return Storage.getItem(Storage.KEYS.SERVICE_ID, true);
  }

  static clearServices(): void {
    Storage.removeItem(Storage.KEYS.SERVICE_ID);
  }
  static setService(serviceId: any): void {
    this.clearServices();
    Storage.setItem(Storage.KEYS.SERVICE_ID, serviceId);
  }

  static getTemplatePracticeId(): any {
    return Storage.getItem(Storage.KEYS.TEMPLATE_PRACTICE_ID);
  }

  static clearTemplatePracticeId(): void {
    Storage.removeItem(Storage.KEYS.TEMPLATE_PRACTICE_ID);
  }
  static setTemplatePracticeId(practiceId: any): void {
    this.clearTemplatePracticeId();
    Storage.setItem(Storage.KEYS.TEMPLATE_PRACTICE_ID, practiceId);
  }

  static getTemplatePlanId(): any {
    return Storage.getItem(Storage.KEYS.PLAN_ID);
  }

  static clearTemplatePlanId(): void {
    Storage.removeItem(Storage.KEYS.PLAN_ID);
  }
  static setTemplatePlanId(planId: any): void {
    this.clearTemplatePlanId();
    Storage.setItem(Storage.KEYS.PLAN_ID, planId);
  }
  static getTemplateBranchId(): any {
    return Storage.getItem(Storage.KEYS.TEMPLATE_BRANCH_ID);
  }

  static clearTemplateBranchId(): void {
    Storage.removeItem(Storage.KEYS.TEMPLATE_BRANCH_ID);
  }
  static setTemplateBranchId(branchId: any): void {
    this.clearTemplateBranchId();
    Storage.setItem(Storage.KEYS.TEMPLATE_BRANCH_ID, branchId);
  }

  static getTemplateDoctorId(): any {
    return Storage.getItem(Storage.KEYS.TEMPLATE_DOCTOR_ID);
  }

  static clearTemplateDoctorId(): void {
    Storage.removeItem(Storage.KEYS.TEMPLATE_DOCTOR_ID);
  }
  static setTemplateDoctorId(doctorId: any): void {
    this.clearTemplateBranchId();
    Storage.setItem(Storage.KEYS.TEMPLATE_DOCTOR_ID, doctorId);
  }

  static clearTemplateData(): void {
    this.clearTemplateBranchId();
    this.clearTemplateCategoryId();
    this.clearTemplatePracticeId();
    this.clearTemplateId();
    this.clearTemplateDoctorId();
  }

  static getCategoryVisibility(): any {
    return Storage.getItem(Storage.KEYS.CATEGORY_VISIBILITY);
  }

  static clearCategoryVisibility(): void {
    Storage.removeItem(Storage.KEYS.CATEGORY_VISIBILITY);
  }
  static setCategoryVisibility(data: any): void {
    this.clearCategoryVisibility();
    Storage.setItem(Storage.KEYS.CATEGORY_VISIBILITY, data);
  }

  static getSelectedPatientId(): any {
    return Storage.getItem(Storage.KEYS.SELECTED_PATIENT_ID);
  }

  static clearSelectedPatientId(): void {
    Storage.removeItem(Storage.KEYS.SELECTED_PATIENT_ID);
  }
  static setSelectedPatientId(hlpId: any): void {
    this.clearSelectedPatientId();
    Storage.setItem(Storage.KEYS.SELECTED_PATIENT_ID, hlpId);
  }

  static getPaymentCriteria(): any {
    return Storage.getItem(Storage.KEYS.PAYMENT_CRITERIA, true);
  }

  static clearPaymentCriteria(): void {
    Storage.removeItem(Storage.KEYS.PAYMENT_CRITERIA);
  }
  static setPaymentCriteria(data: any): void {
    this.clearPaymentCriteria();
    Storage.setItem(Storage.KEYS.PAYMENT_CRITERIA, data);
  }

  static getPaymentDetails(): any {
    return Storage.getItem(Storage.KEYS.PAYMENT_DETAILS, true);
  }

  static clearPaymentDetails(): void {
    Storage.removeItem(Storage.KEYS.PAYMENT_DETAILS);
  }
  static setPaymentDetails(data: any): void {
    this.clearPaymentDetails();
    Storage.setItem(Storage.KEYS.PAYMENT_DETAILS, data);
  }

  static getSubscriptionData(): any {
    return Storage.getItem(Storage.KEYS.SUBSCRIPTION_DETAILS, true);
  }

  static clearSubscriptionData(): void {
    Storage.removeItem(Storage.KEYS.SUBSCRIPTION_DETAILS);
  }
  static setSubscriptionData(data: any): void {
    this.clearSubscriptionData();
    Storage.setItem(Storage.KEYS.SUBSCRIPTION_DETAILS, data);
  }

  static getVisitId(): any {
    return Storage.getItem(Storage.KEYS.VISIT_ID);
  }
  static clearVisitId(): void {
    Storage.removeItem(Storage.KEYS.VISIT_ID);
  }
  static setVisitId(data: any): void {
    this.clearVisitId();
    Storage.setItem(Storage.KEYS.VISIT_ID, data);
  }

  static getCorporatePersonDetails(): any {
    return Storage.getItem(Storage.KEYS.CORPORATE_PERSON_DETAILS, true);
  }
  static clearCorporatePersonDetails(): void {
    Storage.removeItem(Storage.KEYS.CORPORATE_PERSON_DETAILS);
  }
  static setCorporatePersonDetails(data: any): void {
    this.clearCorporatePersonDetails();
    Storage.setItem(Storage.KEYS.CORPORATE_PERSON_DETAILS, data);
  }
  static getModuleId(): any {
    return Storage.getItem(Storage.KEYS.MODULE_ID);
  }
  static clearModuleId(): void {
    Storage.removeItem(Storage.KEYS.MODULE_ID);
  }
  static setModuleId(moduleId: any): void {
    this.clearModuleId();
    Storage.setItem(Storage.KEYS.TEMPLATE_ID, moduleId);
  }
}

export default TemplateUtils;
