/* eslint-disable camelcase */
import React from 'react';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import {sendOtp} from '@shared/services/PublicService';
import {environment} from '@shared/environment/environment';

import SecondaryCommonDialogTitleComponent from '../SecondaryCommonDialogTitle/SecondaryCommonDialogTitleComponent';
import OtpScreenComponent from '../OtpScreen/OtpScreenComponent';
import Icons from '@components/system/MyIcons/index';
import FormCombinationField from '@components/system/CustomFormField/FormCombinationField';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import FormPhoneField from '@components/system/CustomFormField/FormPhoneField';
import FormSelectField from '@components/system/CustomFormField/FormSelectField';
import Loader from '@components/framework/loader/Loader';
import {
  genderOptionsList,
  salutationOptionsList
} from 'src/constants/FormFields';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {BORDER_TERTIARY_COLOR} from 'src/style/variable';
import './PostUserDetailsComponent.scss';

const dropDownProps = {
  height: '3.9rem',
  width: '100%',
  border: `1px solid ${BORDER_TERTIARY_COLOR}`
};

const PostUserDetailsComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = React.useState(props?.open);
  const [showOtpPopup, setShowOtpPopup] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [values, setValues]: any = React.useState({
    salutation: 'Mr',
    first_name: '',
    last_name: '',
    gender: '',
    phone_code: environment.dialCode,
    phone_number: ''
  });
  const askQuesData = props?.values;
  const repliesData = props?.repliesData;

  const handleClose = (event?: any, reason?: any) => {
    setOpen(false);
    props?.closePopup();
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleCheckBoxChange = (event: any): any => {
    setChecked(event?.target?.checked);
  };

  const openOtpPopup = (): void => {
    setOpen(false);
    setShowOtpPopup(true);
  };

  const closeOtpPopup = (): void => {
    setShowOtpPopup(false);
    handleClose();
  };

  const handleCountryChange = (
    value: any,
    country: any,
    e: any,
    formattedValue: any,
    name: any
  ): void => {
    const countryCode = formattedValue?.split(' ')?.[0];
    const phoneNumber = value?.substr(country?.dialCode?.length);

    if (phoneNumber?.length <= 10) {
      setValues({
        ...values,
        phone_code: countryCode,
        phone_number: phoneNumber
      });
    }
  };

  const handleSubmit = (): void => {
    const data = {
      phone_code: values?.phone_code,
      phone_number: values?.phone_number,
      email: values?.email,
      first_name: values?.first_name,
      last_name: values?.last_name
    };

    setIsLoading(true);
    sendOtp(data)
      .then(res => {
        setIsLoading(false);
        openOtpPopup();
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const renderOtpPopup = (): React.ReactElement => {
    return (
      <OtpScreenComponent
        open={showOtpPopup}
        closeOtpPopup={closeOtpPopup}
        isFromPost={true}
        values={values}
        askQuesData={askQuesData}
        repliesData={repliesData}
        isFromReply={props?.isFromReply}
        isFromQuestion={props?.isFromQuestion}
      />
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormCombinationField
            dropDownName={'salutation'}
            dropdownValue={values['salutation']}
            callback={handleChange('salutation')}
            dropDownOptions={salutationOptionsList}
            textFieldCallBack={handleChange('first_name')}
            textFieldName={'first_name'}
            placeholder={t('FIRST_NAME')}
            textFieldValue={values['first_name']}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            placeholder={t('LAST_NAME')}
            name="last_name"
            value={values['last_name']}
            callback={handleChange('last_name')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormPhoneField
            name="mobile_number"
            placeholder={t('MOBILE_NUMBER')}
            callback={handleCountryChange}
            phoneNumber={
              (values?.phone_code || values?.phone_number) &&
              values?.phone_code + ' ' + values?.phone_number
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormSelectField
            name="gender"
            placeholder={t('GENDER')}
            value={values['gender']}
            dropDownProps={dropDownProps}
            Icon={Icons.DropDownIcon}
            StartAdornmentIcon={Icons.GenderIcon}
            dropDownOptions={genderOptionsList}
            callback={handleChange('gender')}
          />
        </Grid>

        <Grid item xs={12}>
          <FormGroup id="post-permission">
            <FormControlLabel
              sx={{marginBottom: 0}}
              control={
                <Checkbox checked={checked} onChange={handleCheckBoxChange} />
              }
              label={t('ACCEPTANCE_TEXT')}
            />
          </FormGroup>
        </Grid>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="submit-button"
            disabled={isLoading || !checked}
            onClick={handleSubmit}>
            {isLoading ? <Loader size={28} /> : t('REVIEW_POST')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className="post-user-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        open={open}
        aria-labelledby="post-user-pop-up"
        aria-describedby="post-user-otp"
        classes={{paper: 'booking-appointment-popup'}}>
        <DialogTitle id="post-user" className="user-post-popup-title">
          <SecondaryCommonDialogTitleComponent close={handleClose} />
        </DialogTitle>

        <DialogContent className="booking-appointment-popup-content">
          {renderDialogContent()}
        </DialogContent>

        <DialogActions
          disableSpacing={true}
          className="booking-appointment-popup-actions">
          {renderDialogActions()}
        </DialogActions>
      </Dialog>
      {showOtpPopup && renderOtpPopup()}
    </Grid>
  );
};

export default PostUserDetailsComponent;
