import {
  PrivateRoute,
  PublicRoute
} from '../../components/framework/route/AuthRoute';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import React from 'react';
import { CommonRoute } from './CommonRoute';
import PortalRoute from './PortalRoute';

// TODO : interface
export interface ChildRouteInterface {
  /*
  @path
  The path for route
   */
  path: string;
  /*
  @component
  The component to load when the router matches the path
   */
  component: any;
  /*
  @exact
  router should match the exact path
   */
  exact?: boolean;
  /*
  @private
  is the route used is private
   */
  private?: boolean;
  /*
  @public
  is the route used is public
   */
  public?: boolean;
  /*
  @exactFrom
  for redirection: matches from route path, you need to give path that should be matched for redirection
  e.g. /home/ exactFrom and redirectTo /home/dashboard
   */
  exactFrom?: string;
  /*
  @redirectTo
  for redirection: if matches from route is true, then Redirection on this path
  e.g. /home/ exactFrom and redirectTo /home/dashboard
   */
  redirectTo?: string;
  /*
  if hasNoAccessPath or user logon then fallbacks to hasNoAccessPath
   */
  hasNoAccessPath?: string;
  /*
  Child routes
   */
  routes?: Array<ChildRouteInterface>;
}

export interface CustomRouteInterface {
  baseName: string;
  params: any;
  exactFrom: string;
  redirectTo: string;
  routes?: Array<ChildRouteInterface>;
}

export interface PrivateRouteComponentProps extends RouteComponentProps {
  exactFrom?: string;
  redirectTo?: string;
  routes?: Array<ChildRouteInterface>;
  clientId: string;
  orgId: string;
  clientInfo: any;
}

class CustomRoute {
  // Defining any routes can consist of these parameters
  static commonRoute = CommonRoute;
  static portal: CustomRouteInterface = PortalRoute;
  /*
  Basic Route is used only for authenticated routes in UI.
  For e.g. Login Register routes or for Home based routes
   */
  static basicRoute = (
    routeObj: any,
    token: any,
    initialRoute?: string
  ): any => {
    return routeObj.routes.map((r: any, i: any) => {
      if (r.public) {
        return (
          <PublicRoute
            key={i}
            routes={r.routes}
            params={routeObj.params}
            path={r.path}
            exact={r.exact}
            exactFrom={r.exactFrom}
            redirectTo={r.redirectTo}
            user={token}
            hasAccessPath={r.hasAccessPath || initialRoute}
            component={r.component}
          />
        );
      } else if (r.private) {
        return (
          <PrivateRoute
            key={i}
            routes={r.routes}
            params={routeObj.params}
            path={r.path}
            exact={r.exact}
            exactFrom={r.exactFrom}
            redirectTo={r.redirectTo}
            user={token}
            hasNoAccessPath={r.hasNoAccessPath}
            component={r.component}
          />
        );
      } else {
        return <Route key={i} path={r.path} component={r.component} />;
      }
    });
  };

  // These are for defining routing and passing all those routing props to the component
  // perm and permFallbackRoute:
  // This is a special key, if you want your route only to be active on permission of certain condition
  // you specify the condition in perm which holds the value (string || boolean)
  // if the perm does not hold any value, it will fallback to the route specified in permFallbackRoute
  static privateRouting({
    key,
    exact,
    path,
    Component,
    exactFrom,
    redirectTo,
    perm,
    permFallbackRoute,
    routes
  }: {
    key: any;
    exact?: boolean;
    path: string;
    Component: any;
    exactFrom?: any;
    redirectTo?: string;
    perm?: string | boolean;
    permFallbackRoute?: string;
    routes?: Array<any>;
  }): any {
    if (permFallbackRoute) {
      return (
        <Route
          key={key}
          exact={exact}
          path={path}
          render={props1 => {
            if (perm) {
              return (
                <Component
                  {...props1}
                  exactFrom={exactFrom}
                  redirectTo={redirectTo}
                  routes={routes}
                />
              );
            } else {
              return <Redirect to={{ pathname: permFallbackRoute }} />;
            }
          }}
        />
      );
    } else {
      return (
        <Route
          key={key}
          exact={exact}
          path={path}
          render={props1 => {
            return (
              <Component
                {...props1}
                exactFrom={exactFrom}
                redirectTo={redirectTo}
                routes={routes}
              />
            );
          }}
        />
      );
    }
  }
}

export default CustomRoute;
