import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as CallIconSvg} from '@assets/images/healpha/call.svg';

const CallIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={CallIconSvg} viewBox="0 0 24 24" />
  );
};

export default CallIcon;