import * as React from 'react';
import {TextField} from '@material-ui/core';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import {LocalizationProvider} from '@material-ui/lab';
import TimePicker from '@material-ui/lab/TimePicker';

const TimeSelector = (props: any): React.ReactElement => {
  const {time, setTime} = props;

  const handleChange = (newVal: any) => {
    setTime(newVal);
  };

  return (
    <LocalizationProvider dateAdapter={MomentAdapter}>
      <TimePicker
        value={time}
        onChange={(item: any) => handleChange(item)}
        renderInput={params => <TextField {...params} />}
        showToolbar={true}
        ampm={true}
      />
    </LocalizationProvider>
  );
};

export default TimeSelector;
