import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as CloneIconSvg} from '@assets/images/healpha/clone.svg';

const CloneIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={CloneIconSvg}
      viewBox="0 0 19 19"
    />
  );
};

export default CloneIcon;
