/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import {getVitals, saveVitals} from '@shared/services/CommonService';

import HelperUtils from 'src/utils/HelperUtils';
import Loader from '@components/framework/loader/Loader';
import {patientVitalsList, VitalKeys} from 'src/constants/FormFields';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import Icons from '@components/system/MyIcons/index';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {
  DEFAULT_WHITE_COLOR,
  NORMAL_BMI_COLOR,
  AB_NORMAL_BMI_COLOR,
  MEDIUM_BMI_COLOR,
  GET_8_PIXEL_BOX
} from 'src/style/variable';
import './NewPersonVitalsComponent.scss';

const textFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  opacity: 1,
  fontSize: '1.2rem',
  color: 'green'
};

const commonProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  borderRadius: GET_8_PIXEL_BOX(0.5),
  opacity: 1,
  fontSize: '1.2rem'
};

const normalTextFieldProps = {
  ...commonProps,
  border: `1px solid ${NORMAL_BMI_COLOR}`
};

const abNormalTextFieldProps = {
  ...commonProps,
  border: `1px solid ${AB_NORMAL_BMI_COLOR}`
};

const mediumTextFieldProps = {
  ...commonProps,
  border: `1px solid ${MEDIUM_BMI_COLOR}`
};

const NewPersonVitalsComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(props?.open);
  const [values, setValues]: any = React.useState({});
  const [vitalRangeValues, setVitalRangeValues]: any = React.useState({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const prePopulateRangeValues = (vitalKey: string, responseData: any) => {
    const dataObject = HelperUtils.getVitalCalculations(
      props?.person_details?.gender,
      props?.person_details?.dob,
      vitalKey,
      Number(responseData?.[vitalKey]),
      Number(responseData?.height),
      Number(responseData?.weight)
    );

    const data = vitalRangeValues;
    data[vitalKey] = dataObject?.vital_value;
    setVitalRangeValues({
      ...vitalRangeValues,
      [vitalKey]: dataObject?.vital_value
    });
  };

  const init = () => {
    setIsLoading(true);

    const data = {
      hlp_id: props?.person_details?.hlpid,
      enc_id: props?.encounterId,
      doc_id: props?.doc_id
    };
    getVitals(data)
      .then(response => {
        setIsLoading(false);

        const vital = response?.data?.vitals?.[0];
        const responseData = {
          height: vital?.height?.value,
          weight: vital?.weight?.value,
          bmi: vital?.bmi?.value,
          systolic: vital?.systolic?.value,
          diastolic: vital?.diastolic?.value,
          temperature: vital?.temperature?.value,
          pulse_rate: vital?.pulse_rate?.value,
          pefr: vital?.pefr?.value,
          oxygen_saturation: vital?.oxygen_saturation?.value,
          respiratory_rate: vital?.respiratory_rate?.value,
          total: response?.data?.length ? response?.data?.length + 1 : 1
        };
        setValues(responseData);

        Object.values(VitalKeys)?.forEach((item: any) => {
          prePopulateRangeValues(item, responseData);
        });
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  React.useEffect(init, []);

  const handleClose = () => {
    setOpen(false);
    if (
      props?.closeAppointmentConfirmationPopup &&
      typeof props.closeAppointmentConfirmationPopup === 'function'
    ) {
      props.closeAppointmentConfirmationPopup();
    }
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleBlur = (vitalKey: any) => {
    const dataObject = HelperUtils.getVitalCalculations(
      props?.person_details?.gender,
      props?.person_details?.dob,
      vitalKey,
      Number(values?.[vitalKey]),
      Number(values?.height),
      Number(values?.weight)
    );

    if (dataObject?.error_data?.message) {
      enqueueSnackbar(dataObject?.error_data?.message, SnackBarConfig('w'));
    } else {
      setVitalRangeValues({
        ...vitalRangeValues,
        [vitalKey]: dataObject?.vital_value
      });

      const updatedDataObject: any = vitalRangeValues;
      updatedDataObject[vitalKey] = dataObject?.vital_value;

      if (vitalKey === VitalKeys.HEIGHT || vitalKey === VitalKeys.WEIGHT) {
        if (Number(values?.height) && Number(values?.weight)) {
          const bmiDataObject = HelperUtils.getVitalCalculations(
            props?.person_details?.gender,
            props?.person_details?.dob,
            VitalKeys.BMI,
            null,
            Number(values?.height),
            Number(values?.weight)
          );

          setValues({...values, [VitalKeys.BMI]: bmiDataObject?.bmi_value});
          updatedDataObject[VitalKeys.BMI] = bmiDataObject?.vital_value;
          setVitalRangeValues(updatedDataObject);
        } else {
          setValues({...values, [VitalKeys.BMI]: null});
          setVitalRangeValues(updatedDataObject);
        }
      }
    }
  };

  const handleSubmit = (): void => {
    setIsLoading(true);

    const vitals = {
      height: values?.height,
      weight: values?.weight,
      bmi: values?.bmi,
      systolic: values?.systolic,
      diastolic: values?.diastolic,
      temperature: values?.temperature,
      pulse_rate: values?.pulse_rate,
      pefr: values?.pefr,
      oxygen_saturation: values?.oxygen_saturation,
      respiratory_rate: values?.respiratory_rate,

      height_color: vitalRangeValues?.height,
      weight_color: vitalRangeValues?.weight,
      bmi_color: vitalRangeValues?.bmi,
      systolic_color: vitalRangeValues?.systolic,
      diastolic_color: vitalRangeValues?.diastolic,
      temperature_color: vitalRangeValues?.temperature,
      pulse_rate_color: vitalRangeValues?.pulse_rate,
      pefr_color: vitalRangeValues?.pefr,
      oxygen_saturation_color: vitalRangeValues?.oxygen_saturation,
      respiratory_rate_color: vitalRangeValues?.respiratory_rate
    };

    const data = {
      template_id: props?.template_id,
      hlp_id: props?.person_details?.hlpid,
      enc_id: props?.encounterId,
      doc_id: props?.doc_id,
      vitals: vitals,
      comments: ''
    };

    saveVitals(data)
      .then(response => {
        enqueueSnackbar(response.message, SnackBarConfig('s'));
        setIsLoading(false);
        handleClose();
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const renderTitle = (): React.ReactElement => {
    return (
      <Grid container item xs={12} alignItems="center" style={{paddingTop: 0}}>
        <Grid item xs={6}>
          <Typography className="popup-title">
            {t('TIMELINE_ENTER_VITALS')}
          </Typography>
        </Grid>

        <Grid item xs={6} container justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <Icons.CloseBlackIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const renderHeader = (): React.ReactElement => {
    return (
      <Grid item xs={12} style={{paddingTop: 0}}>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          className="header-container">
          <Grid item xs={4} className="header-text">
            {t('ADD_VITALS_VITAL_SIGNS')}
          </Grid>
          <Grid item xs className="header-text header-text-values">
            {t('VALUES')}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderContent = (): React.ReactElement => {
    return (
      <Grid item xs={12} style={{paddingTop: 0}}>
        <Grid
          container
          item
          xs={12}
          className="vitals-container"
          alignItems="center">
          {patientVitalsList?.map?.((item: any, index: number) => (
            <>
              <Grid item xs={4} key={index}>
                <Typography className="vital-name">{t(item?.label)}</Typography>
              </Grid>

              <Grid
                container
                item
                xs={8}
                className="vital-text-field-common-grid"
                key={item?.id}
                style={{marginBottom: '0.5rem'}}>
                <Grid item xs={6}>
                  <FormTextField
                    name={item?.value}
                    value={values?.[item?.value]}
                    callback={handleChange(item?.value)}
                    onBlurCallback={() => handleBlur(item?.value)}
                    type="number"
                    size="small"
                    className={
                      values?.[item?.value] &&
                      item?.value === VitalKeys.BMI &&
                      (vitalRangeValues?.[item?.value] === 1
                        ? 'normal-bmi-color'
                        : vitalRangeValues?.[item?.value] === 0
                        ? 'ab-normal-bmi-color'
                        : vitalRangeValues?.[item?.value] === 0.5 &&
                          'medium-bmi-color')
                    }
                    componentTextFieldProps={
                      item?.value === VitalKeys.BMI
                        ? textFieldProps
                        : values?.[item?.value] &&
                          vitalRangeValues?.[item?.value] === 1
                        ? normalTextFieldProps
                        : values?.[item?.value] &&
                          vitalRangeValues?.[item?.value] === 0
                        ? abNormalTextFieldProps
                        : values?.[item?.value] &&
                          vitalRangeValues?.[item?.value] === 0.5 &&
                          mediumTextFieldProps
                    }
                    readOnly={item?.value === 'bmi' ? true : false}
                  />
                </Grid>
              </Grid>

              {item?.isBorderNeeded && (
                <Grid item xs={12}>
                  <Divider className="divider" />
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          className="submit-button"
          style={{height: '2.65rem'}}
          disabled={isLoading}
          onClick={() => handleSubmit()}>
          {isLoading ? <Loader size={28} /> : t('Save Data')}
        </Button>
      </Grid>
    );
  };

  return (
    <Grid className="new-person-vitals-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        aria-labelledby="new-person-vitals-popup"
        aria-describedby="new-person-vitals"
        classes={{paper: 'new-person-vitals-popup'}}>
        <DialogContent>
          <Grid container spacing={2}>
            {renderTitle()}
            {renderHeader()}
            {renderContent()}
            {renderDialogActions()}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default NewPersonVitalsComponent;
