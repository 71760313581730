import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as HospitalIconSvg} from '@assets/images/healpha/hospital_avatar.svg';

const HospitalIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={HospitalIconSvg} viewBox="0 0 145 145" />
  );
};

export default HospitalIcon;