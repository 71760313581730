/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import {Box, Grid, IconButton, Typography} from '@material-ui/core';
import CloseBlackIcon from '@components/system/MyIcons/CloseBlackIcon';
import HospitalIconDark from '@components/system/MyIcons/HospitalIconDark';
import {actionType, StepsCommonProps} from '../Appointment';
import './AppointmentSteps.scss';
import {PersonSharp} from '@material-ui/icons';
import {getPatientCard} from '@shared/services/PublicService';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {useTranslation} from 'react-i18next';
import Loader from '@components/framework/loader/Loader';
import moment from 'moment';
import {toUpper} from 'lodash';
import CommonUtils from 'src/utils/CommonUtils';
import ConfirmIcon from '@assets/images/healpha/ConfirmIcon.svg';
import './StepSix.scss';
import {isMobile} from 'react-device-detect';

const getDateInFormat = (selectedDate: moment.Moment): any => {
  return selectedDate.format('DD|MMM|YYYY').split('|');
};

function StepSix({
  data,
  dispatch,
  branchId
}: // doctorId
StepsCommonProps): React.ReactElement {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [appointmentData, setAppointmentData] = useState<any>({});
  const [date, month, year] = getDateInFormat(data?.formFour?.selectedDate);

  const {doctor_details, appointment_status} =
    appointmentData?.appointment || {};

  const initGetPatientCard = () => {
    data?.formFour?.id &&
      getPatientCard(branchId, data?.formFour?.id)
        .then((res: any) => {
          if (res.status_code !== 200) throw new Error(res?.message);
          setAppointmentData(res?.data?.person_appointment_details);
        })
        .catch((err: any) => {
          enqueueSnackbar(
            err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        })
        .finally(() => {
          setLoading(false);
        });
  };
  useEffect(initGetPatientCard, []);
  return loading ? (
    <Grid container p={5} className="confirm-container">
      <Loader color="inherit" size={20} />
    </Grid>
  ) : (
    <Grid
      container
      p={2.5}
      className="confirm-container"
      style={{
        width: isMobile ? '100%' : '30rem'
      }}>
      <Grid container item className="header">
        <Grid item>
          <IconButton
            id="close_pop"
            className="p-0"
            onClick={() => {
              dispatch({type: actionType.CLOSE_MODAL, payload: null});
            }}>
            <CloseBlackIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={12} className="confirm-grid">
        <Grid className="confirm" item>
          <img src={ConfirmIcon} alt="confirm" />
        </Grid>
        <Grid className="confirmed-text pt-3" item>
          <Typography fontWeight="bold" variant="h4">
            {t('CLINIC_APPOINTMENT_CONFIRMED')}.
          </Typography>
        </Grid>
        <Grid item mb={2}>
          <Typography variant="h6">{t('CLINIC_DETAILS')}</Typography>
        </Grid>
        <Grid container item className="confirmedCard" flexWrap="nowrap">
          <Grid item className="date-grid">
            <Box className="date-box">
              <Typography className="date-text">{date}</Typography>
              <Typography className="date-month-text">
                {toUpper(month)}
              </Typography>
            </Box>
            <Box>{year}</Box>
          </Grid>
          <Grid item xs={8} md={12} className="doctor-grid">
            <Box className="doctor-box">
              <Typography fontWeight="bold" variant="h4">
                {`${doctor_details?.salutation}.${doctor_details?.first_name} ${doctor_details?.last_name}`}
              </Typography>
              <Grid item className="confirmedTag">
                {appointment_status}
              </Grid>
            </Box>

            <Typography variant="h3" className="subTitle">
              {CommonUtils.getCapitalizeWord(
                doctor_details?.branches?.[0]?.specialization
              )}
              {/* {startCase(
                get(
                  find(doctorDetails.user_experience, {current_work: '1'}),
                  'designation',
                  '-'
                )
              )} */}
            </Typography>
            <Box className="d-flex">
              <HospitalIconDark />
              <Typography>
                {doctor_details?.branches?.find(
                  (i: any) => i.branch_id === branchId
                )?.branch_name || ''}
                {appointmentData?.encounter_id ? '-' : ''}
                {appointmentData?.encounter_id?.split('-')?.[2]}
              </Typography>
            </Box>
            <Box className="d-flex">
              <PersonSharp />
              <Typography className="d-flex align-items-center">
                {t('OUTPATIENT_REGULAR')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StepSix;
