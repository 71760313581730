// Auth actions
export const SET_LOGIN_AUTH = 'SET_LOGIN_AUTH';
export const SET_LOGIN_ADMIN_AUTH = 'SET_LOGIN_ADMIN_AUTH';
export const SET_LOGIN_ADVISOR_AUTH = 'SET_LOGIN_ADVISOR_AUTH';
export const SET_LOGOUT_AUTH = 'SET_LOGOUT_AUTH';
export const SET_LOGOUT_ADMIN_AUTH = 'SET_LOGOUT_ADMIN_AUTH';
export const SET_LOGOUT_ADVISOR_AUTH = 'SET_LOGOUT_ADVISOR_AUTH';
export const INITIALIZE_APP = 'INITIALIZE_APP';

// Common Actions
export const SET_USER_INFO = 'SET_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const SET_CLIENT_INFO = 'SET_CLIENT_INFO';
export const SET_BRANCH_INFO = 'SET_BRANCH_INFO';
export const SET_WHITELABLING_INFO = 'SET_WHITELABLING_INFO';
export const SET_CONTACTDETAILS_INFO = 'SET_CONTACTDETAILS_INFO';
export const SET_BRANCH_ID = 'SET_BRANCH_ID';
export const SET_ORG_ID = 'SET_ORG_ID';
export const SET_DOCTOR_ID = 'SET_DOCTOR_ID';
export const SET_ADVISOR_INFO = 'SET_ADVISOR_INFO';
export const SET_CLIENT_FEATURES = 'SET_CLIENT_FEATURES';
export const SET_DOCTOR_INFO = 'SET_DOCTOR_INFO';
export const SNACKBAR_ACKNOWLEDGEMENT = 'SNACKBAR_ACKNOWLEDGEMENT';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';

// Admin Actions
export enum ADMIN_SETTERS {
  SET_COMPANY_ONBOARD_ORG = 'SET_COMPANY_ONBOARD_ORG'
}

export const HIDE_LEFT_NAV = 'HIDE_LEFT_NAV';

// Advisor Actions
export enum ADVISOR_SETTERS {
  SET_CLIENT_ONBOARD_DATA = 'SET_CLIENT_ONBOARD_DATA'
}

// Self Service Actions
export const SET_SELF_SERVICE_STATUS = 'SET_SELF_SERVICE_STATUS';
