/* eslint-disable camelcase */
import React, {ReactElement, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {Link, Grid, Typography} from '@material-ui/core';

import MultipleImagesViewComponent from '../MultipleImagesViewComponent/MultipleImagesViewComponent';
import CommonUtils from 'src/utils/CommonUtils';
import {
  BASE_ROUTE_BRANCH,
  BASE_ROUTE_DOCTOR,
  BASE_ROUTE_PORTAL
} from 'src/constants/route/BaseRoute';
import NavRoutes from 'src/constants/route/NavRoutes';
import AvailableTimings from '../AvailableTimings/AvailableTimings';
import {CommunicationService} from 'src/service/CommunicationService';
import {AVAILABLE_LOCATION_BOOK_APPOINTMENT} from 'src/constants/CommunicationServiceConstants';
import AvailabilityButtonsComponent from '../AVailabilityButtons/AvailabilityButtonsComponent';
import ConsultationFees from '../ConsultationFees/ConsultationFees';
import './AvailableLocationCardComponent.scss';
import moment from 'moment';

const AvailableLocationCardComponent = (props: any): ReactElement => {
  const {t} = useTranslation();
  const [branchData, setBranchData]: any = useState({});
  const history = useHistory();
  const [appointmentTypesList, setAppointmentTypesList]: any = useState([]);
  const init = () => {
    const branch = props?.branch;
    const data: any = {};
    data.images = [];
    data.branch_name = branch?.branch?.branch_name;
    data.branch_id = branch?.branch_id;
    data.isTodayAvailable = CommonUtils.findDoctorTodayAvailability(
      props?.doctorData,
      branch?.branch_id,
      moment().day()
    );
    data.address1 = branch?.branch?.address1;
    data.address2 = branch?.branch?.address2;
    data.city = branch?.branch?.city;

    if (branch?.branch?.practice_image) {
      const result: any = CommonUtils.getFullImageUrl(
        branch.branch.practice_image
      );
      if (result) {
        if (typeof result === 'string') {
          data.images.push(result);
        } else {
          data.images = result;
        }
      }
    }

    data.workTimings = CommonUtils.getDoctorWorkTimings2(
      props?.weeklytimings,
      t,
      data.branch_id
    );

    if (
      props?.branch?.branch_id &&
      (props?.doctorData?.non_healpha_user_id || props?.doctorData?.doctor_id)
    ) {
      setAppointmentTypesList(
        CommonUtils.formAppointmentTypes(
          props.branch.branch_id,
          props?.doctorData
        )
      );
    }
    setBranchData(data);
  };

  useEffect(init, [props?.branch]);

  const goToHospitalView = () => {
    if (branchData?.branch_id) {
      history.push(
        `/${BASE_ROUTE_PORTAL}/home/${BASE_ROUTE_BRANCH}/${NavRoutes.PUBLIC.HOSPITAL.VIEW}` +
          `/${branchData.branch_id}/${NavRoutes.PUBLIC.HOSPITAL.TABS.DOCTORS}`
      );
    }
  };

  // const bookAppointment = () => {
  //   if (branchData?.branch_id) {
  //     CommunicationService.sendMessage({
  //       type: AVAILABLE_LOCATION_BOOK_APPOINTMENT,
  //       branch_id: branchData.branch_id
  //     });
  //   }
  // };
  const bookAppointmentNew = () => {
    if (branchData?.branch_id) {
      CommunicationService.sendMessage({
        type: AVAILABLE_LOCATION_BOOK_APPOINTMENT,
        branch_id: branchData.branch_id
      });
    }
    let url =
      `/${BASE_ROUTE_PORTAL}/home/${BASE_ROUTE_DOCTOR}/${NavRoutes.PUBLIC.DOCTOR.VIEW}` +
      `/${
        props?.doctorData?.non_healpha_user_id || props?.doctorData?.doctor_id
      }/${NavRoutes.PUBLIC.DOCTOR.TABS.INFO}`;

    if (props?.branchId) {
      url = `${url}?branch_id=${props.branchId}`;
    }
    history.push(url);
  };
  const renderBookingSection = (): ReactElement => {
    return (
      <AvailabilityButtonsComponent
        branchData={branchData}
        bookAppointmentAction={bookAppointmentNew}
      />
    );
  };

  const renderLeftSection = (): ReactElement => {
    return (
      <Grid className="left-section" item xs={12} sm={6} lg={4}>
        <Grid>
          <Link
            component="button"
            variant="body2"
            className="hospital-name"
            onClick={goToHospitalView}>
            {branchData?.branch_name || '-'}
          </Link>

          <Typography className="address">
            {branchData?.address1 || '-'}
          </Typography>

          <Typography className="address">
            {' '}
            {branchData?.address2}, {branchData?.city}.
          </Typography>

          <Grid className="bottom-section">
            <Grid className="images-section">
              <MultipleImagesViewComponent images={branchData.images} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderMiddleSection = (): ReactElement => {
    return (
      <Grid className="middle-section" item xs={12} sm={6} lg={4}>
        <ConsultationFees appointmentTypesList={appointmentTypesList} />
      </Grid>
    );
  };

  const renderRightSection = (): ReactElement => {
    return (
      <Grid className="right-section" item xs={12} sm={6} lg={4}>
        <AvailableTimings workTimings={branchData?.workTimings} />
      </Grid>
    );
  };

  return (
    <Grid className="doctor-location-wrapper">
      <Grid className="doctor-location-container">
        <Grid className="detailed-card-wrapper">
          <Grid container className="detailed-card-container" spacing={2}>
            {renderLeftSection()}
            {renderMiddleSection()}
            {renderRightSection()}
          </Grid>
          {renderBookingSection()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AvailableLocationCardComponent;
