/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import Icons from '@components/system/MyIcons/index';
import './CommonConfirmationBoxComponent.scss';

const CommonConfirmationBoxComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(props?.open);

  const handleClose = (callbackAction: boolean) => {
    if (
      props?.closeCommonConfirmationPopup &&
      typeof props.closeCommonConfirmationPopup === 'function'
    ) {
      props.closeCommonConfirmationPopup(callbackAction);
    }
  };

  const closePopup = (): void => {
    setOpen(false);
    handleClose(false);
  };

  const handleClick = (): void => {
    setOpen(false);
    handleClose(true);
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography className="popup-sub-title">{props?.title}</Typography>
            <IconButton
              id="close_popup"
              className="popup-close-icon"
              onClick={closePopup}>
              <Icons.CloseBlackIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item>
            <Typography className="message-text">
              {t(props?.message)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            id="yes_id"
            variant="contained"
            size="medium"
            color="primary"
            className="submit-button"
            onClick={handleClick}
            style={{height: '3rem'}}>
            {props?.yesLabel}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            id="no_id"
            variant="contained"
            size="medium"
            className="appointment-cancel-button"
            onClick={closePopup}>
            {props?.noLabel}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className="common-confirmation-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        aria-labelledby="common-confirmation-popup"
        aria-describedby="common-confirmation"
        classes={{paper: 'common-confirmation-popup'}}>
        <DialogContent>{renderDialogContent()}</DialogContent>

        {renderDialogActions()}
      </Dialog>
    </Grid>
  );
};

export default CommonConfirmationBoxComponent;
