import {AxiosCall} from '../axios';

export const getMaterTemplates = async (
  branchId?: any,
  practice_id?: any,
  searchText?: any,
  offset?: any,
  language?: any
): Promise<any> => {
  const url = `/template/master-templates?branch_id=${branchId}&practice_id=${practice_id}&search_text=${searchText}&offset=${
    offset || ''
  }&language=${language}`;
  return AxiosCall.get(url);
};

export const getTemplateVitals = async (
  encounterId: any,
  docId: any,
  hlpId: any,
  template_id?: any,
  is_default_template?: any,
  is_all_vitals_required?: any
): Promise<any> => {
  const url =
    `/appointment/encounter/${encounterId}/doctor/${docId}/person` +
    `/${hlpId}/vital?template_id=${template_id}&is_default_template=${is_default_template}&is_all_vitals_req=${is_all_vitals_required}`;
  return AxiosCall.get(url);
};

export const getSubjectiveAnswers = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/template/answers`;
  return AxiosCall.get(url);
};

export const addSubjectiveAnswers = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  data: any,
  template_id: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/template/answers?template_id=${template_id}`;
  return AxiosCall.post(url, data);
};

export const editSubjectiveAnswers = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  id: any,
  data: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/template/answers/${id}`;
  return AxiosCall.put(url, data);
};

export const addVitalsAnswers = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  data: any,
  template_id?: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/vital?template_id=${template_id}`;
  return AxiosCall.post(url, data);
};

export const getBMIValue = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  height: any,
  weight: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/vital/bmi/calculate?height=${height}&weight=${weight})`;
  return AxiosCall.get(url);
};

export const editVitalsAnswers = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  id: any,
  data: any,
  template_id?: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/vital/${id}?template_id=${template_id}`;
  return AxiosCall.put(url, data);
};

export const previewPrescription = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  is_Preview: any,
  templateId: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/generate-prescription?template_id=${templateId}`;
  return AxiosCall.post(url, {is_preview: is_Preview});
};

export const getMedicinesFromTemplate = async (
  category: any,
  template_id: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/prescription/${category}`;
  return AxiosCall.get(url);
};

export const getServicesFromTemplate = async (
  type: any,
  template_id: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/service/${type}`;
  return AxiosCall.get(url);
};

export const getMedicineReminders = async (
  hlp_id: any,
  date: any,
  encId: any
): Promise<any> => {
  const url = `/appointment/person/${hlp_id}/medicine-reminders?date=${date}&encounter_id=${encId}`;
  return AxiosCall.get(url);
};
