import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as BackArrowBlackIconSvg} from '@assets/images/healpha/back_arrow_search_black.svg';

const BackArrowBlackIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={BackArrowBlackIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default BackArrowBlackIcon;
