/* eslint-disable camelcase */
import React from 'react';
import {Grid, Typography, Link} from '@material-ui/core';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './CaptureVitalsCommonComponent.scss';
import moment from 'moment';
import CommonUtils from 'src/utils/CommonUtils';
import {useTranslation} from 'react-i18next';

const CaptureVitalsCommonComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {encId, categoryArray, vitalsAnswerArray} = props;

  const handleLabels = () => {
    return (
      <Grid xs={12} item className="labels-main-grid">
        <Grid item className="labels-empty-grid">
          {}
        </Grid>
        {categoryArray?.map((categories: any, index1: any) => {
          return (
            <Grid
              container
              spacing={0}
              xs={12}
              key={index1}
              direction="column"
              className={
                Array?.isArray(categories?.data)
                  ? 'capture-vital-info-grid is-array-grid'
                  : 'capture-vital-info-grid '
              }>
              <Grid
                item
                xs={6}
                className={
                  Array?.isArray(categories?.data)
                    ? 'capture-vital-label-grid is-array-heading-label'
                    : 'capture-vital-label-grid'
                }>
                {categories?.label} {categories?.unit}
              </Grid>
              {Array?.isArray(categories?.data) &&
                categories?.data?.length > 0 &&
                categories?.data?.map((item: any, index: any) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={index}
                      className="inside-blood-pressure-label">
                      {item?.label} {item?.unit}
                    </Grid>
                  );
                })}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const getFormatedDate = (date: any) => {
    const updatedDate = moment(date).format('YYYY-MM-DD HH:mm a').toString();
    return updatedDate;
  };

  const handleValues = (data: any) => {
    const updatedDate = getFormatedDate(data?.updated_date);
    const date = updatedDate?.split(' ')?.[0];
    const time = CommonUtils.convertTo12Hours(updatedDate?.split(' ')?.[1]);
    return (
      <Grid xs={12} item className="values-main-grid">
        <Grid item className="labels-empty-grid">
          <Grid item className="labels-date-grid">
            <Grid xs={12} className="capture-vital-date">
              {date}
            </Grid>
            <Grid xs={12} className="capture-vital-time">
              {time}
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="labels-answers-grid">
          {categoryArray?.map((item: any, index: any) => {
            const j: any = (item?.value).toLowerCase();
            return (
              <Grid
                item
                key={index}
                className={
                  j == 'blood_pressure'
                    ? 'labels-single-label give-height-to-blood-pressure'
                    : 'labels-single-label'
                }>
                {j == 'blood_pressure'
                  ? (
                      <Grid className={'blood-pressure-text-empty-field'}>
                        {}
                      </Grid>
                    ) && (
                      <div className={'blood-pressure-main-div'}>
                        {item?.data?.map((i: any, index2: any) => {
                          return (
                            <p
                              className={
                                'capture-vital-answer-text blood-pressure-text'
                              }
                              key={index2}>
                              {data?.[i?.value]?.value}
                            </p>
                          );
                        })}
                      </div>
                    )
                  : data?.[j]?.value}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  const handleComments = (vital: any) => {
    const updatedDate = getFormatedDate(vital?.updated_date);
    return (
      vital?.comments && (
        <Grid item className="capture-vital-single-comment-grid">
          <Grid xs={9} item className="vital-comment-text-grid">
            {vital?.comments}
          </Grid>
          <Grid xs={3} item className="vital-comment-date-grid">
            {updatedDate}
          </Grid>
        </Grid>
      )
    );
  };

  const renderAddVitalsDialogAction = () => {
    return (
      <Grid container className="dialog-action-button-grid-capture-vitals">
        <Link
          className="capture-vitals-add-vitals"
          onClick={props?.handleOpenAddVitals}>
          + {t('TEMPLATE_ADD_VITALS')}
        </Link>
      </Grid>
    );
  };

  return (
    <Grid xs={12} className="capture-vitals-main-wrapper">
      <Grid xs={12} item className="capture-vitals-grid">
        <Grid xs={12} item className="capture-vitals-title-grid">
          <Grid xs={4} item className="capture-vitals-title-text-grid">
            <Typography className="capture-vitals-title">
              {t('VITALS')}
            </Typography>
          </Grid>

          <Grid item className="capture-vitals-visit-id-grid">
            <Typography className="capture-vitals-visit-id">
              <div className="capture-vitals-visit-id-text">
                {t('VISIT_ID')}:
              </div>{' '}
              {encId}
            </Typography>
          </Grid>
        </Grid>
        {vitalsAnswerArray?.length > 0 && (
          <Grid xs={12} item className="label-values-main-grid">
            <Grid xs={6} sm={5} item className="capture-vitals-label-grid">
              {handleLabels()}
            </Grid>

            <Grid
              xs={6}
              sm={6}
              item
              container
              className="capture-vitals-values-main-grid">
              {vitalsAnswerArray?.map((item: any, index: any) => {
                return (
                  <Grid item className="capture-vitals-values-grid" key={index}>
                    {handleValues(item)}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        <Grid item className="capture-vitals-comments-main-grid">
          {vitalsAnswerArray?.map((item: any, index: any) => {
            return (
              <Grid
                xs={12}
                sm={12}
                item
                className="capture-vitals-comments-grid"
                key={index}>
                {handleComments(item)}
              </Grid>
            );
          })}
        </Grid>

        <Grid item className="capture-vitals-add-new-vitals">
          {renderAddVitalsDialogAction()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CaptureVitalsCommonComponent;
