import React from 'react';
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

const DeleteAttachmentConfirmationPopUp = (props: any): React.ReactElement => {
  const [open, setOpen] = React.useState(props?.open);
  const {type} = props;

  const handleClose = () => {
    setOpen(false);
    props?.closeDeleteAttachmentConfirmationPopUp();
  };

  const handleConfirmation = () => {
    props?.deleteAttachment(type);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" />
        <DialogContent>
          <Grid item>Do You Want To Delete The Attachment ? </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="cancel" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            id="yes_remove"
            onClick={handleConfirmation}
            color="primary"
            autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteAttachmentConfirmationPopUp;
