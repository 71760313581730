import React from 'react';
import {InputAdornment, TextField, IconButton, CircularProgress} from '@material-ui/core';

import {
  DEFAULT_WHITE_COLOR,
  BORDER_TERTIARY_COLOR,
  GET_8_PIXEL_BOX
} from 'src/style/variable';

const textFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  border: `1px solid ${BORDER_TERTIARY_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.5),
  opacity: 1,
  fontSize: '1.2rem'
};

interface FormTextFieldProps {
  name: string;
  placeholder?: string;
  value: any;
  Icon?: any;
  EndIcon?: any;
  adornmentPosition?: any;
  callback?: any;
  search?: any;
  onBlurCallback?: any;
  readOnly?: boolean;
  multiline?: true;
  minRows?: any;
  type?: string;
  componentTextFieldProps?: any;
  minLength?: any;
  maxLength?: any;
  keyPressCallBack?: any;
  autoFocus?: boolean;
  disabled?: boolean;
  iconDisabled?: boolean;
  onClick?: any;
  size?: any;
  className?: any;
  loading?: boolean;
}

const FormTextField = (props: FormTextFieldProps): React.ReactElement => {
  const {
    placeholder,
    name,
    value,
    adornmentPosition,
    Icon,
    EndIcon,
    callback,
    search,
    onBlurCallback,
    readOnly,
    multiline,
    type,
    componentTextFieldProps,
    minRows,
    minLength,
    maxLength,
    keyPressCallBack,
    autoFocus,
    disabled,
    iconDisabled,
    onClick,
    size,
    className,
    loading
  } = props;

  return (
    <TextField
      fullWidth
      sx={componentTextFieldProps || textFieldProps}
      className={className}
      placeholder={placeholder}
      variant="outlined"
      id={name}
      name={name}
      type={type}
      autoFocus={autoFocus}
      size={size || 'medium'}
      multiline={multiline}
      minRows={minRows}
      onChange={callback}
      disabled={disabled}
      onKeyUp={search}
      onBlur={onBlurCallback}
      onClick={onClick}
      value={value || ''}
      inputProps={{
        minLength: minLength ? String(minLength) : null,
        maxLength: maxLength ? String(maxLength) : null
      }}
      InputProps={{
        readOnly: readOnly || false,
        disabled: disabled || false,
        startAdornment: adornmentPosition === 'start' && (
          <InputAdornment position="start">
            <Icon />
          </InputAdornment>
        ),
        endAdornment: EndIcon && (
          <InputAdornment position="end">
            <IconButton
              disabled={iconDisabled}
              onClick={keyPressCallBack}
              edge="end">
                { loading && <CircularProgress size={'1rem'} /> }
              <EndIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default FormTextField;
