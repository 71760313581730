export const Temperature = [
  {
    min: 0,
    max: 93,
    value: 0
  },
  {
    min: 94,
    max: 98,
    value: 1
  },
  {
    min: 99,
    max: 100,
    value: 0.5
  },
  {
    min: 101,
    max: 'above',
    value: 0
  }
];

export const getTemperatureCalculation = (value: any): any => {
  try {
    for (const temp of Temperature) {
      if (value >= temp.min && (value <= temp.max || temp.max === 'above')) {
        return temp.value;
      }
    }
    return null;
  } catch (e) {
    throw new Error((e as any)?.message);
  }
};
