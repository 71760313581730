import React from 'react';
import {Avatar, Typography, Box} from '@material-ui/core';
import {
  PersonSharp as PersonSharpIcon,
  MailSharp as MailSharpIcon,
  PhoneSharp as PhoneSharpIcon
} from '@material-ui/icons';
import {capitalize} from 'lodash';
import {SxProps} from '@material-ui/system';
const cardStyle = {
  padding: '10px',
  border: '1px solid lightgrey',
  borderRadius: '4px',
  backgroundColor: 'rgb(255, 255, 255)',
  color: 'rgba(0, 0, 0, 0.87)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  boxShadow:
    'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px'
};

interface CardViewProps {
  avatarUrl?: string;
  name: string;
  gender: string;
  age: number;
  email: string;
  phoneNumber: string | number;
  containerStyle?: SxProps;
}

export function CardView(props: CardViewProps): React.ReactElement {
  const {avatarUrl, name, gender, age, email, phoneNumber, containerStyle} =
    props;
  return (
    <Box component="div" display="flex" sx={{...cardStyle, ...containerStyle}}>
      <Box display="flex" flexDirection="column" mr={1}>
        <Avatar src={avatarUrl} alt={name}>
          {name[0]}
        </Avatar>
      </Box>
      <Box display="flex" flexDirection="column" flex={1}>
        <Typography variant="h4">{name}</Typography>
        <Box display="flex" alignItems="center">
          <PersonSharpIcon />
          <Typography variant="caption">
            {`${capitalize(gender)}, ${age}`}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <MailSharpIcon />
          <Typography variant="caption">{email}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <PhoneSharpIcon />
          <Typography variant="caption">{phoneNumber}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
