import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as PatientCheckedIconSvg} from '@assets/images/healpha/PatientChecked.svg';

const PatientCheckedIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={PatientCheckedIconSvg}
      viewBox="0 0 34 34"
    />
  );
};

export default PatientCheckedIcon;
