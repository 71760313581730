// @ts-ignore
import NunitoSans from '../../assets/fonts/NunitoSans-Regular.ttf';
// @ts-ignore
import NunitoSansSemiBold from '../../assets/fonts/NunitoSans-SemiBold.ttf';
// @ts-ignore
import NunitoSansBold from '../../assets/fonts/NunitoSans-Bold.ttf';

const nunitoSansPro = {
  fontFamily: 'NunitoSans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${NunitoSans})
  `
};
const nunitoSansSemiBold = {
  fontFamily: 'NunitoSansSemiBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${NunitoSansSemiBold})
  `
};
const nunitoSansBold = {
  fontFamily: 'NunitoSansBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${NunitoSansBold})
  `
};

export default {
  '@global': {
    '@font-face': [nunitoSansPro, nunitoSansSemiBold, nunitoSansBold]
  }
};
