import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as MorningIconSvg} from '@assets/images/healpha/morning.svg';

const MorningIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={MorningIconSvg} viewBox="0 0 24 24" />
  );
};

export default MorningIcon;