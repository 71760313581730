/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {Grid, Typography, Button} from '@material-ui/core';

import {
  getForumCategories,
  getAllForumsList,
  sendAppLink,
  createForumsQuestion
} from '@shared/services/PublicService';
import {environment} from '@shared/environment/environment';

import ForumsHeaderCardComponent from '../ForumsHeaderCard/ForumsHeaderCardComponent';
import FormSelectField from '@components/system/CustomFormField/FormSelectField';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import ForumsQuestionCardComponent from '../ForumsQuestionCard/ForumsQuestionCardComponent';
import PostUserDetailsComponent from '@components/system/Dialogs/PostUserDetails/PostUserDetailsComponent';
import NotFoundComponent from '../NotFound/NotFoundComponent';
import FormPhoneField from '@components/system/CustomFormField/FormPhoneField';
import {topicOptionsList} from 'src/constants/FormFields';
import Loader from '@components/framework/loader/Loader';
import Icons from '@components/system/MyIcons/index';
import {UserTypes} from 'src/constants/SystemConstants';
import CommonUtils from 'src/utils/CommonUtils';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {
  DEFAULT_WHITE_COLOR,
  PLACEHOLDER_TEXT_COLOR,
  SEARCH_BAR_BACKGROUND_COLOR,
  SECONDARY_BACKGROUND_COLOR,
  APP_PRIMARY_FONT_REGULAR,
  FONT_PRIMARY_COLOR,
  GET_8_PIXEL_BOX
} from 'src/style/variable';
import './HospitalHealthForumsComponent.scss';

const dropDownProps = {
  height: '3rem',
  width: '100%',
  border: `1px solid ${PLACEHOLDER_TEXT_COLOR}`,
  background: `${SECONDARY_BACKGROUND_COLOR} 0% 0% no-repeat padding-box`
};

const inputProps = {
  font: `normal normal 600 1.3rem/1.4rem ${APP_PRIMARY_FONT_REGULAR}`,
  letterSpacing: 0,
  color: FONT_PRIMARY_COLOR,
  paddingTop: '0.5rem',
  opacity: 1
};

const componentTextFieldProps2 = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  border: `1px solid ${SEARCH_BAR_BACKGROUND_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.75)
};

const HospitalHealthForumsComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const moduleId = props?.moduleId;
  const [categoryOptionsList, setCategoryOptionsList]: any = React.useState([]);
  const [questions, setQuestions]: any = React.useState([]);
  const [showPostUserDetailsPopup, setShowPostUserDetailsPopup] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLinkLoading, setIsLinkLoading] = React.useState<boolean>(false);
  const category: any = React.useRef('');
  const topic: any = React.useRef('');
  const [values, setValues]: any = React.useState({
    phone_code: environment.dialCode,
    mobile_number: '',
    question: ''
  });

  const getCategories = (): void => {
    getForumCategories()
      .then(res => {
        if (res?.data?.categories?.length > 0) {
          setCategoryOptionsList(res.data.categories);
        }
      })
      .catch(err => {
        enqueueSnackbar(
          err.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const getQuestions = (): void => {
    setQuestions([]);
    setIsLoading(true);
    getAllForumsList(
      category?.current,
      topic?.current,
      '',
      0,
      environment.config.hospitalForumsQuestionsLimit
    )
      .then(res => {
        setIsLoading(false);
        if (res?.data?.questions?.length > 0) {
          setQuestions(res.data.questions);
        }
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const init = (): void => {
    getCategories();
    getQuestions();
  };

  React.useEffect(init, []);

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleCountryChange = (
    value: any,
    country: any,
    e: any,
    formattedValue: any
  ): void => {
    const countryCode = formattedValue?.split(' ')?.[0];
    const phoneNumber = value?.substr(country?.dialCode?.length);

    if (phoneNumber?.length <= 10) {
      setValues({
        ...values,
        phone_code: countryCode,
        mobile_number: phoneNumber
      });
    }
  };

  const handleSubmit = () => {
    if (!topic.current || !category.current) {
      enqueueSnackbar(t('CATEGORY_TOPIC_REQ'), SnackBarConfig('w'));
      return;
    } else if (
      values?.question?.length <
        environment.config.textAreaFieldTextMinLength ||
      values?.question?.length > environment.config.textAreaFieldTextMaxLength
    ) {
      enqueueSnackbar(
        t('TEXT_LIMIT_WARNING', {
          MinLength: environment.config.textAreaFieldTextMinLength,
          MaxLength: environment.config.textAreaFieldTextMaxLength
        }),
        SnackBarConfig('w')
      );
      return;
    }

    const forumsUser: any = CommonUtils.getTemporaryUserId();
    if (forumsUser) {
      const data = {
        topic: topic?.current,
        category: category?.current,
        question: values?.question,
        user_id: forumsUser,
        user_type: UserTypes.TEMPORARY_PERSON
      };

      createForumsQuestion(data)
        .then(res => {
          enqueueSnackbar(res?.message, SnackBarConfig('s'));
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    } else {
      setShowPostUserDetailsPopup(true);
    }
  };

  const handleDropDownChange = (name: any) => (event: any) => {
    if (name === 'topic') {
      topic.current = event?.target?.value;
    } else {
      category.current = event?.target?.value;
    }

    getQuestions();
  };

  const sendLink = async () => {
    setIsLinkLoading(true);
    sendAppLink({phone_number: values.mobile_number})
      .then(res => {
        enqueueSnackbar(res?.message, SnackBarConfig('s'));
        setIsLinkLoading(false);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
        setIsLinkLoading(false);
      });
  };

  const closePopup = (): void => {
    setShowPostUserDetailsPopup(false);
  };

  const renderPopUp = (): React.ReactElement => {
    return (
      <PostUserDetailsComponent
        open={showPostUserDetailsPopup}
        closePopup={closePopup}
        values={{
          topic: topic?.current,
          category: category?.current,
          question: values?.question
        }}
        isFromQuestion={true}
      />
    );
  };

  return (
    <Grid className="hospital-health-forums-wrapper">
      <Grid className="hospital-health-forums-container">
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <Typography className="get-link-label">
              {t('GET_APP_LINK')}
            </Typography>
          </Grid>

          <Grid container item xs={12} style={{paddingTop: '0.25rem'}}>
            <Grid item xs={9}>
              <FormPhoneField
                name="mobile_number"
                placeholder={t('MOBILE_PLACEHOLDER')}
                callback={handleCountryChange}
                phoneNumber={
                  (values?.phone_code || values?.mobile_number) &&
                  values?.phone_code + ' ' + values?.mobile_number
                }
              />
            </Grid>

            <Grid item xs={3}>
              <Button
                variant="contained"
                size="medium"
                onClick={sendLink}
                className="send-link-button"
                color="primary"
                disabled={isLinkLoading}>
                {isLinkLoading ? <Loader size={28} /> : t('SEND_LINK')}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ForumsHeaderCardComponent
              subTitle={t('DISCUSS_ASK_QUESTIONS')}
              showButton={false}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid className="hospital-forum-categories-component">
              <Grid className="questions-dropdown-container">
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={6}>
                    <FormSelectField
                      name="category"
                      placeholder={t('CATEGORY')}
                      value={category?.current}
                      inputProps={inputProps}
                      dropDownProps={dropDownProps}
                      dropDownOptions={categoryOptionsList}
                      callback={handleDropDownChange('category')}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormSelectField
                      name="topic"
                      placeholder={t('TOPIC')}
                      value={topic?.current}
                      inputProps={inputProps}
                      dropDownProps={dropDownProps}
                      dropDownOptions={topicOptionsList}
                      callback={handleDropDownChange('topic')}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {isLoading ? (
                <Loader className="loader-parent" />
              ) : questions?.length > 0 ? (
                <Grid
                  className={`${
                    moduleId == 2
                      ? 'questions-card-container'
                      : 'questions-card-container1'
                  }`}>
                  {questions?.map((item: any, index: any) => {
                    return (
                      <Grid
                        className="forums-ques-item"
                        item
                        xs={12}
                        key={index}>
                        <ForumsQuestionCardComponent
                          question={item}
                          fullWidth={false}
                          isFromHospital={true}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <div style={{margin: '2rem'}}>
                  <NotFoundComponent label={t('NO_QUESTIONS')} />
                </div>
              )}

              <Grid item xs={12}>
                <FormTextField
                  placeholder={t('ASK_QUESTION')}
                  name="question"
                  value={values['question']}
                  componentTextFieldProps={componentTextFieldProps2}
                  callback={handleChange('question')}
                  keyPressCallBack={handleSubmit}
                  adornmentPosition="start"
                  Icon={Icons.ForumsReplyIcon}
                  iconDisabled={values?.question?.length > 0 ? false : true}
                  EndIcon={
                    values?.question?.length > 0
                      ? Icons.SendActiveIcon
                      : Icons.SendInActiveIcon
                  }
                  minLength={environment.config.textAreaFieldTextMinLength}
                  maxLength={environment.config.textAreaFieldTextMaxLength}
                />
              </Grid>
              {/* </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showPostUserDetailsPopup && renderPopUp()}
    </Grid>
  );
};

export default HospitalHealthForumsComponent;
