import _moment, {Moment} from 'moment';
const moment = _moment;

export const DEFAULT_DATE_FORMAT = 'MM/DD/yyyy';
export const DATE_FORMAT = 'yyyy-MM-DD';
const format = (
  date: Date | string | undefined | Moment,
  formatting?: string
): string => {
  try {
    if (!date) {
      return '';
    }
    const m = moment(date);
    let formatDate = '';
    if (formatting) {
      formatDate = m.format(formatting);
    } else {
      formatDate = m.format(DEFAULT_DATE_FORMAT);
    }
    return formatDate;
  } catch {
    return '';
  }
};

export default {format, DEFAULT_DATE_FORMAT};
