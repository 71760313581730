import React, {ReactElement} from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';

interface BaseRouteComponentProps extends RouteProps {
  user: boolean;
  component: React.ComponentType<any>;
  params?: any;
  routes?: any;
  exactFrom?: any;
  redirectTo?: any;
}
interface PublicRouteComponentProps extends BaseRouteComponentProps {
  hasAccessPath: string;
}
interface PrivateRouteComponentProps extends BaseRouteComponentProps {
  hasNoAccessPath: string;
}

const PrivateRoute = ({
  component: Component,
  user,
  params,
  hasNoAccessPath,
  ...rest
}: PrivateRouteComponentProps): ReactElement => {
  return (
    <Route
      {...rest}
      render={props => {
        if (user) {
          return <Component {...rest} {...props} params={params} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: `${hasNoAccessPath}`,
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

const PublicRoute = ({
  component: Component,
  user,
  params,
  hasAccessPath,
  ...rest
}: PublicRouteComponentProps): ReactElement => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          return <Component {...rest} {...props} params={params} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: `${hasAccessPath}`,
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

export {PrivateRoute, PublicRoute};
