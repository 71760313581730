/* eslint-disable camelcase */
import React from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
  Button,
  Typography,
  Divider
} from '@material-ui/core';
import Icons from '@components/system/MyIcons';

import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';

import FormDatePickerField from '@components/system/CustomFormField/FormDatePickerField';
import VaccineUpdatedDialog from './VaccineUpdatedDialog';
import {BORDER_BOX_COLOR} from 'src/style/variable';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import CommonUtils from 'src/utils/CommonUtils';
import {
  addImagingAttachments,
  updateVaccinesInformation
} from '@shared/services/PublicService';
import AuthUtils from 'src/utils/AuthUtils';
import UploadAttachmentDialog from 'src/modules/shared/UploadAttachment/UploadAttachmentDialog';
import PdfViewerDialog from 'src/modules/shared/PdfViewerComponent/PdfViewerDialog';
import moment from 'moment';
import './ProvideVaccineDialog.scss';

const datePickerProps = {
  border: `0.063rem solid ${BORDER_BOX_COLOR}`,
  height: '2.25rem',
  justifyContent: 'center',
  borderRadius: '0.375rem',
  width: '100%'
};

export const inputTextFieldProps = {
  height: '2.25rem',
  borderRadius: '0.4375rem',
  padding: '1rem, 0.625rem, 0.5rem, 0.625rem',
  width: '100%',
  justifyContent: 'center',
  border: `0.0625rem solid ${BORDER_BOX_COLOR}`
};

const ProvideVaccineDialog = (props: any): React.ReactElement => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  // const wrapperRef : any = useRef<any>();
  let indexData = 0;
  const [open, setOpen] = React.useState(props?.open);
  const {doctorID, selectedDate, provideVaccinePassData} = props;
  const {
    vaccineData,
    isFromDashboard,
    setVaccineDataMainArray,

    vaccineDataMainArray,
    isAdminNurse
  } = props;

  const [vaccineUpdatedData, setVaccineUpdatedData] = React.useState({
    id: '',
    batchNo: '',
    date: ''
  });

  const isNurse = CommonUtils.checkIsNurse();

  const docId = isNurse || isAdminNurse ? doctorID : AuthUtils.getDoctorId();
  const personId = AuthUtils.getHealphaId();
  const encId = AuthUtils.getEncId();
  const type = 'vaccine';
  const [uploadId, setUploadId] = React.useState('');
  const [showUploadAttachmentPopUp, setShowUploadAttachmentPopUp] =
    React.useState(false);
  const [testData, setTestdata]: any = React.useState([]);
  const [openVaccineUpdatedDialog, setOpenVaccineUpdatedDialog] =
    React.useState(false);
  const [prescription, setPrescription] = React.useState('');
  const [showPdfFileDialog, setShowPdfFileDialog] = React.useState(false);
  const [uploadPdfData, setUploadPdfData] = React.useState<any>([]);
  const [fileName, setFileName] = React.useState([]);
  const handleClose = () => {
    setOpen(false);
    props?.closeProvideVaccineDialog();
  };

  const renderVaccineUpdatedDialog = (): React.ReactElement => {
    return (
      <VaccineUpdatedDialog
        open={openVaccineUpdatedDialog}
        closeVaccineDialog={handleClose}
      />
    );
  };

  const updateData = () => {
    const remainingVaccines = vaccineData?.filter((vaccineDone: any) => {
      return (
        vaccineDone?.attachments?.length <= 0 ||
        (vaccineDone?.batch_no && vaccineDone?.batch_no?.length <= 0) ||
        (vaccineDone?.expiry_date && vaccineDone?.expiry_date?.length <= 0)
      );
    });
    setTestdata(remainingVaccines);
  };

  React.useEffect(updateData, []);

  const closeShowPdfFileDialog = () => {
    setShowPdfFileDialog(false);
  };
  const removeFile = (index: any) => {
    const data = [...testData];
    data[index].attachments = [];
    setTestdata(data);
    // setdataOf(data);
  };

  const handlePrescription = (url: any) => {
    setPrescription(CommonUtils.getFullImageUrl(url));
    setShowPdfFileDialog(true);
  };

  const renderPdfViewerDialog = (): React.ReactElement => {
    return (
      <PdfViewerDialog
        open={showPdfFileDialog}
        closeShowPdfFileDialog={closeShowPdfFileDialog}
        pdfURL={prescription}
      />
    );
  };

  const handleOpenVaccineUpdatedDialog = () => {
    setOpenVaccineUpdatedDialog(true);
    setOpen(false);
  };

  const handleEvent = () => {
    const date = selectedDate;
    const evt = new CustomEvent('handleStatusCount', {detail: {date: date}});
    window.dispatchEvent(evt);
    window.dispatchEvent(new Event('handlingDisplay'));
  };

  const handleSubmit = () => {
    vaccineDataMainArray?.map((i: any) => {
      if (!i?.date || !i?.batchNo) {
        enqueueSnackbar(
          'Batch number and Expiry Date is required',
          SnackBarConfig('w')
        );
        return;
      }

      const data = {
        description: '',
        expiry_date: i?.date,
        batch_no: i?.batchNo
      };

      const vaccine_id = i?.id;
      updateVaccinesInformation(encId, docId, personId, vaccine_id, data)
        .then(res => {
          addImagingAttachments(
            provideVaccinePassData?.encId,
            provideVaccinePassData?.docId,
            provideVaccinePassData?.personId,
            fileName,
            type,
            uploadId
          )
            .then(response => {
              enqueueSnackbar(response?.message, SnackBarConfig('s'));
              // setFileName(file_name);
              // !isFromDashboard && props?.handleVaccineData();
              // const response_data =props?.handleGetVaccine(
              // provideVaccinePassData?.encId,
              //   provideVaccinePassData?.docId,
              //   provideVaccinePassData?.personId);
            })
            .catch(err => {
              enqueueSnackbar(
                err?.message ? err.message : t('ERROR_ENCOUNTERED'),
                SnackBarConfig('e')
              );
            });
          enqueueSnackbar(res?.message, SnackBarConfig('s'));
          !isFromDashboard && props?.handleVaccineData();
          isFromDashboard && handleEvent();
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
      handleOpenVaccineUpdatedDialog();
    });
  };

  const handleDobChange = (prop: any, id: any) => (newValue: any) => {
    setVaccineUpdatedData({
      ...vaccineUpdatedData,
      [prop]: CommonUtils.getDobFormat(newValue),
      id: id
    });
    const temp: any = [];
    Object.keys(vaccineUpdatedData).length > 0 && temp.push(vaccineUpdatedData);
    temp?.length > 0 && setVaccineDataMainArray(temp);

    const found = vaccineDataMainArray?.filter((i: any) => {
      return i?.id === id;
    });
    const index = vaccineDataMainArray.indexOf(found[0]);

    if (index !== -1) {
      const temp1 = {
        id: found?.[0]?.id,
        batchNo: found?.[0]?.batchNo,
        date: newValue
      };
      const tempDelete = [...vaccineDataMainArray];
      const findAT = tempDelete?.findIndex((i: any) => i?.id == id);
      tempDelete[findAT] = temp1;
      setVaccineDataMainArray(tempDelete);
    } else {
      const temp2 = {
        id: found?.[0]?.id,
        batchNo: found?.[0]?.batchNo,
        date: newValue
      };
      const tempArr: any = [...vaccineDataMainArray];
      tempArr.push(temp2);
      setVaccineDataMainArray(tempArr);
    }
  };

  const handleChange = (prop: any, id: any) => (event: any) => {
    setVaccineUpdatedData({
      ...vaccineUpdatedData,
      [prop]: event.target.value,
      id: id
    });

    const found = vaccineDataMainArray?.filter((i: any) => {
      return i?.id === id;
    });
    const index = vaccineDataMainArray.indexOf(found[0]);

    if (index !== -1) {
      const temp = {
        id: id,
        batchNo: event.target.value
      };
      const tempDelete = [...vaccineDataMainArray];
      const findAT = tempDelete?.findIndex((i: any) => i?.id == id);
      tempDelete[findAT] = temp;
      setVaccineDataMainArray(tempDelete);
    } else {
      const temp = {
        id: id,
        batchNo: event.target.value
      };
      const tempArr: any = [...vaccineDataMainArray];
      tempArr.push(temp);
      setVaccineDataMainArray(tempArr);
    }
  };

  const handleAddAttachment = (file: any, file_name?: any) => {
    const temp: any = [...uploadPdfData];
    const prevFile: any = [...fileName];
    uploadPdfData?.length <= 0
      ? temp.push(file)
      : enqueueSnackbar('Only One File Can be Attached', SnackBarConfig('w'));
    setUploadPdfData(temp);
    fileName?.length <= 0
      ? prevFile.push(file)
      : enqueueSnackbar('Only One File Can be Attached', SnackBarConfig('w'));
    setFileName(prevFile);

    const store_data = [...testData];
    store_data[indexData].attachments = [
      {
        file: file_name,
        pdf: file
      }
    ];
    setTestdata(store_data);
  };

  const closeShowUploadAttachmentPopUp = (): void => {
    setShowUploadAttachmentPopUp(false);
  };

  const renderShowUploadAttachmentPopUp = (): React.ReactElement => {
    return (
      <UploadAttachmentDialog
        open={showUploadAttachmentPopUp}
        closeShowUploadAttachmentPopUp={closeShowUploadAttachmentPopUp}
        handleAddAttachment={handleAddAttachment}
      />
    );
  };

  const handleUpload = (id: any) => {
    setUploadId(id);
    setShowUploadAttachmentPopUp(true);
  };

  const renderProvideVaccineDialogTitle = (): React.ReactElement => {
    return (
      <Grid item container xs={12} className="provide-vaccine-title-wrapper">
        <Grid item xs={11} className="provide-vaccine-title">
          Provide Vaccine
        </Grid>
        <Grid item xs={1} className="close-icon">
          <IconButton
            id="close_popup"
            onClick={handleClose}
            className="close-icon">
            <Icons.CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const renderProvideVaccineDialogContent = (): React.ReactElement => {
    return testData?.map((item: any, index: any) => {
      const data = vaccineDataMainArray?.filter((i: any) => i?.id === item?.id);
      return (
        <Grid
          item
          container
          key={index}
          xs={12}
          className="provide-vaccine-content-wrapper">
          <Grid item xs={12} className="provide-vaccine-one">
            <Grid item xs={1} className="provide-vaccine-icon-grid">
              <Icons.VaccineIcon />
            </Grid>
            <Grid item xs={10} className="provide-vaccine-data-grid">
              <Typography className="provide-vaccine-data-title">
                {' '}
                {item?.vaccine_details?.vaccine_brand_name}
              </Typography>
              <Typography className="provide-vaccine-data-text">
                {' '}
                {item?.description}
              </Typography>
              {item?.attachments?.length > 0 && (
                <Divider className="provide-vaccine-divider" />
              )}

              <Grid item xs={12} className="provide-vaccine-input-main-grid">
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    className="provide-vaccine-input-grid">
                    <FormTextField
                      placeholder="Batch No"
                      value={data?.[0]?.['batchNo'] || item?.['batch_no']}
                      componentTextFieldProps={inputTextFieldProps}
                      name="batchNo"
                      callback={handleChange('batchNo', item?.id)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    className="provide-vaccine-input-grid">
                    <FormDatePickerField
                      id={'calender_' + index}
                      name="date"
                      value={data?.[0]?.['date'] || item?.['expiry_date']}
                      textFieldProps={datePickerProps}
                      Icon={Icons.DOBIcon}
                      callback={handleDobChange('date', item?.id)}
                      minDate={moment()}
                    />
                  </Grid>
                </Grid>

                <Divider />
                {item?.attachments?.length > 0 ? (
                  item?.attachments?.map((item2: any, index2: any) => {
                    return item?.attachments ? (
                      <Grid
                        item
                        container
                        key={index}
                        className="single-pdf-attachment">
                        <p
                          className="single-pdf-icon-and-name"
                          onClick={() => handlePrescription(item2?.file)}>
                          <p className="single-pdf-icon">
                            <Icons.PDFIcon />
                          </p>
                          <p className="single-pdf-file-name">
                            {item2?.file ? item2?.file : null}
                          </p>{' '}
                        </p>
                        <IconButton
                          onClick={() => {
                            removeFile(index2),
                              props?.handleDeleteAttachment(
                                'vaccine',
                                item?.id
                              );
                          }}>
                          {' '}
                          <Icons.VaccineCloseIcon />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item xs={12} className="upload-button-grid">
                        <Button
                          id="upload_button_id"
                          className="upload-button"
                          onClick={() => {
                            (indexData = index), handleUpload(item?.id);
                          }}>
                          <Icons.CameraIcon /> Upload Button
                        </Button>
                      </Grid>
                    );
                  })
                ) : (
                  //   );
                  // })
                  //   );
                  // })
                  <Grid item xs={12} className="upload-button-grid">
                    <Button
                      id="upload_button_id"
                      className="upload-button"
                      onClick={() => {
                        (indexData = index), handleUpload(item?.id);
                      }}>
                      <Icons.CameraIcon /> Upload Button
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const renderProvideVaccineDialogAction = (): React.ReactElement => {
    return (
      <Button
        id="complete_vaccination"
        autoFocus
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        style={{width: '100%'}}>
        Complete Vaccination
      </Button>
    );
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="provide-vaccine-dialog-wrapper">
        <DialogTitle id="alert-dialog-title">
          {renderProvideVaccineDialogTitle()}
        </DialogTitle>
        <DialogContent>{renderProvideVaccineDialogContent()}</DialogContent>
        <DialogActions>{renderProvideVaccineDialogAction()}</DialogActions>
      </Dialog>
      {openVaccineUpdatedDialog && renderVaccineUpdatedDialog()}
      {showUploadAttachmentPopUp && renderShowUploadAttachmentPopUp()}
      {showPdfFileDialog && renderPdfViewerDialog()}
    </div>
  );
};

export default ProvideVaccineDialog;
