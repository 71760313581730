import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as WhitePlusIconSvg} from '@assets/images/healpha/WhitePlus.svg';

const WhitePlusIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={WhitePlusIconSvg}
      viewBox="0 0 16 16"
    />
  );
};

export default WhitePlusIcon;
