import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as TimeIconSvg} from '@assets/images/healpha/time.svg';

const TimeIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={TimeIconSvg} viewBox="0 0 24 24" />
  );
};

export default TimeIcon;