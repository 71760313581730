export const AVAILABLE_LOCATION_BOOK_APPOINTMENT =
  'AVAILABLE_LOCATION_BOOK_APPOINTMENT';

export const BACK_NAVIGATION = 'BACK_NAVIGATION';

export const MENU_SETTINGS_CLICK = 'MENU_SETTINGS_CLICK';

export const START_CONSULTATION_BUTTON_CLICK =
  'START_CONSULTATION_BUTTON_CLICK';

export const PIP_DISABLED = 'PIP_DISABLED';

export const FCM_TRIGGER = 'FCM_TRIGGER';

export const FCM_TRIGGER_VIDEO_PRESENCE = 'FCM_TRIGGER_VIDEO_PRESENCE';

export const FCM_EXIT_ROOM = 'FCM_EXIT_ROOM';

export const FCM_PATIENT_EXIT_ROOM = 'FCM_PATIENT_EXIT_ROOM';

export const PRESCRIPTION_EXIT_ROOM = 'PRESCRIPTION_EXIT_ROOM';

export const STANDALONE_APPOINTMENT_UPDATE = 'STANDALONE_APPOINTMENT_UPDATE';

export const SAVE_EXIT_BUTTON_CLICK = 'SAVE_EXIT_BUTTON_CLICK';

export const NEXT_BUTTON_CLICK = 'NEXT_BUTTON_CLICK';

export const SELF_SERVICE_IS_LOADING = 'SELF_SERVICE_IS_LOADING';

export const BANK_DETAILS_ENABLED = 'BANK_DETAILS_ENABLED';

export const WORK_FLOW_UPDATED = 'WORK_FLOW_UPDATED';

export const NEW_BRANCH_CLICK = 'NEW_BRANCH_CLICK';

export const USERS_ROLES = 'USERS_ROLES';

export const SS_FLOW = 'SS_FLOW';
