/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
  Avatar,
  TextField,
  Divider,
  Select,
  OutlinedInput,
  MenuItem,
  Menu,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
  // Checkbox
} from '@material-ui/core';
import moment from 'moment';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import {LocalizationProvider, StaticDatePicker} from '@material-ui/lab';
import _ from 'lodash';

import {
  getPersonAppointmentListQr,
  getVirtualDoctorsList,
  getVirtualPatientsList
} from '@shared/services/CommonService';
import {
  getDoctorById,
  getAppointmentTimings,
  sendOtpToUser,
  // searchPerson,
  getAppointmentTypes,
  searchPatient,
  getPaymentCriteria,
  getAllDoctorsList,
  getPersonDetails,
  getSpecializationSearch,
  getPracticeSearch,
  getConsultationItem,
  getCorporateDoctor
} from '@shared/services/PublicService';
import {environment} from '@shared/environment/environment';

import CommonUtils from 'src/utils/CommonUtils';
import AuthUtils from 'src/utils/AuthUtils';
import NewPersonRegistrationComponent from '@components/system/Dialogs/NewPersonRegistration/NewPersonRegistrationComponent';
import ReviewUserAppointmentComponent from '../ReviewUserAppointment/ReviewUserAppointmentComponent';
import OtpScreenComponent from '../OtpScreen/OtpScreenComponent';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import CustomAutoCompleteField from '@components/system/CustomAutoCompleteField/CustomAutoCompleteField';
import {CommunicationService} from 'src/service/CommunicationService';
import {STANDALONE_APPOINTMENT_UPDATE} from 'src/constants/CommunicationServiceConstants';
import Icons from '@components/system/MyIcons/index';
import CheckupImage from '@assets/images/healpha/Checkup.png';
import {
  genderOptionsList,
  Relations,
  DoctorSpecializations,
  onlineOffline
} from 'src/constants/FormFields';
import {
  GET_8_PIXEL_BOX,
  DEFAULT_WHITE_COLOR,
  MESSAGE_BORDER_COLOR,
  FONT_OTHER_COLOR,
  APP_PRIMARY_FONT_REGULAR,
  APPOINTMENT_BORDER_COLOR
} from 'src/style/variable';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {
  appointmentsTypes,
  AppointmentTypelist,
  personDropDownOptions
} from 'src/constants/SystemConstants';
import './NewAppointmentBookingsComponent.scss';
import FormSelectField from '@components/system/CustomFormField/FormSelectField';
import ChangeConfirmPopUp from './ChangeConfirmPopUp';
import PersonPaymentRedirection from 'src/modules/user/modules/Dashboard/AppointmentScreen/AppointmentDetailsComponent/AppointmentComponent/PersonPaymentRedirection';
import PersonCheckedInDialog from 'src/modules/user/modules/Dashboard/AppointmentScreen/AppointmentDetailsComponent/AppointmentComponent/PersonCheckedInDialog';
import PersonAllDialogs from 'src/modules/user/modules/Dashboard/AppointmentScreen/AppointmentDetailsComponent/AppointmentComponent/PersonAllDialogs';
import UserStore from 'src/modules/user/modules/Temp/UserStore';

const textFieldProps = {
  border: `1px solid ${MESSAGE_BORDER_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.75),
  background: `${DEFAULT_WHITE_COLOR} 0% 0% no-repeat padding-box`
};

const dropDownProps = {
  height: '2.85rem',
  width: '100%',
  border: `1px solid ${APPOINTMENT_BORDER_COLOR}`,
  background: `${DEFAULT_WHITE_COLOR} 0% 0% no-repeat padding-box`,
  marginBottom: '0.5rem'
};

const dropDownPropsForPersonWeb = {
  height: '2.85rem',
  width: '40%',
  border: `1px solid ${APPOINTMENT_BORDER_COLOR}`,
  background: `${DEFAULT_WHITE_COLOR} 0% 0% no-repeat padding-box`
};

const inputProps = {
  font: `normal normal 400 1.1rem/1.5rem ${APP_PRIMARY_FONT_REGULAR}`,
  color: FONT_OTHER_COLOR,
  paddingTop: '0.5rem'
};

const NewAppointmentBookingsComponent = (props: any): React.ReactElement => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const subscription: any = React.useRef(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState<boolean>(props?.open);
  const [patientSelected, setPatientSelected] = React.useState<boolean>(
    props?.isFromPersonWeb ? true : false
  );
  const [doctorSelected, setDoctorSelected] = React.useState<boolean>(false);
  const [branchSelected, setBranchSelected] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>(props?.personName);
  const [doctorSearchText, setDoctorSearchText] = React.useState<string>('');
  const [searchFieldOptions, setSearchFieldOptions]: any = React.useState([]);
  const [doctorSearchFieldOptions, setDoctorSearchFieldOptions]: any =
    React.useState([]);
  const [branch_id_coporate, set_branch_id_coporate]: any =
    React.useState<string>('');
  const [openOtpPopup, setOpenOtpPopup] = React.useState(false);
  const [searchFields, setSearchFields] = React.useState(false);
  const [personValues, setPersonValues]: any = React.useState({});
  const [calendarValue, setCalendarValue]: any = React.useState(moment());
  const [selectedBranch, setSelectedBranch]: any = React.useState(null);
  const [selectedPatientDetails, setSelectedPatientDetails]: any =
    React.useState({});
  const [selectedDoctorDetails, setSelectedDoctorDetails]: any =
    React.useState(null);
  const [doctorData, setDoctorData]: any = React.useState({});
  const [selectedAppointmentTypes, setSelectedAppointmentTypes]: any =
    React.useState([]);
  const [selectedDate, setSelectedDate]: any = React.useState();
  const [slotTimings, setSlotTimings]: any = React.useState([]);
  const [selectedDoctorId, setSelectedDoctorId]: any = React.useState([]);
  const [selectedAppointmentsList, setSelectedAppointmentsList]: any =
    React.useState([]);
  const [selectedSlotButton, setSelectedSlotButton] =
    React.useState<string>('');
  const [slotTime, setSlotTime] = React.useState<string>('');
  const [showPersonSignUpPopup, setShowPersonSignUpPopup] =
    React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [showPersonRedirectionDialog, setShowPersonRedirectionDialog] =
    React.useState<boolean>(false);

  const [showPersonNoDialog, setShowPersonNoDialog] =
    React.useState<boolean>(false);
  const [personAllDialogs, setPersonAllDialogs] =
    React.useState<boolean>(false);
  const [appointId, setAppointId] = React.useState('');

  const [qrAppointId, setQrAppointId] = React.useState('');

  const [appointmentObject, setAppointmentObject]: any = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const patientId = 1;
  const doctorId = 2;
  const virtualClinicBranchId = props?.branchId || AuthUtils.getMenuBranchId();
  const nh_id = AuthUtils.getDoctorId();
  const isStandAloneDoctor = CommonUtils.checkIsStandAloneDoctor();
  const [values, setValues]: any = React.useState({
    appointmentType: '',
    compliant: '',
    encounter_id: '',
    onlineOffline: 'offline'
  });
  const [changeConfirmed, setChangeConfirmed] = React.useState(false);

  const [encounters, setEncounters] = React.useState([]);
  const [isPaymentZeroForReview, setIsPaymentZeroForReview] =
    React.useState(false);
  const [showChangeConfirmPop, setShowChangeConfirmPop] = React.useState(false);

  const [doctorListSpecialization, setDoctorListSpecialization] =
    React.useState([]);

  const [showEncounterDropDown, setShowEncounterDropDown] =
    React.useState<boolean>(false);

  const [selectedType, setSelectedType] = React.useState({
    selected: 'Doctor'
  });

  const [slotsDetails, setSlotsDetails]: any = React.useState([
    {
      label: 'MORNING_SLOTS_AVAILABLE',
      label1: 'MORNING',
      key: 'morning',
      itemsToShow: 12,
      expanded: false
    },
    {
      label: 'AFTERNOON_SLOTS_AVAILABLE',
      label1: 'AFTERNOON',
      key: 'afternoon',
      itemsToShow: 12,
      expanded: false
    },
    {
      label: 'EVENING_SLOTS_AVAILABLE',
      label1: 'EVENING',
      key: 'evening',
      itemsToShow: 12,
      expanded: false
    }
  ]);

  const closePopup = (id?: any): void => {
    setOpen(false);

    if (
      props?.closeNewBookingsPopup &&
      typeof props.closeNewBookingsPopup === 'function'
    ) {
      props.closeNewBookingsPopup(id);
    }
  };

  const closeReviewPopup = (): any => {
    setShowDialog(false);
  };

  const closePersonRedirectionPopUp = (): any => {
    setShowPersonRedirectionDialog(false);
  };

  const closePersonCheckedInPopup = (): any => {
    setShowPersonNoDialog(false);
  };

  const closePersonAllPopup = (): any => {
    setPersonAllDialogs(false);
  };

  const getDoctorDetails = (nonHealphaUserId: string, branchId?: any): void => {
    const virtualBranch = isStandAloneDoctor ? null : virtualClinicBranchId;
    getDoctorById(
      nonHealphaUserId,
      branchId ? branchId : branch_id_coporate,
      virtualBranch,
      props?.type == 'corporate' ? 'corporate' : ''
    )
      .then(response => {
        const doctor = response?.data;
        doctor.branches = response?.data?.branches.filter(
          (item: any) => item.branch
        );
        doctor.appointment_types = CommonUtils.makeAppointmentTypes(
          doctor?.price_list
        );
        setDoctorData(doctor);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const getAppointmentSlotTimes = (
    docId: any,
    branchId: any,
    date: any,
    VirtualBranchId: any
  ) => {
    getAppointmentTimings(docId, branchId, date, VirtualBranchId)
      .then(res => {
        setSlotTimings(res?.data?.free_slots);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const getAppointmentSlots = (date: any, doctor_branch_id: any) => {
    const dateFormat = moment(date).format('YYYY-MM-DD');
    setSelectedDate(dateFormat);
    const virtual_branch_id = isStandAloneDoctor ? null : virtualClinicBranchId;
    const docId = isStandAloneDoctor ? nh_id : selectedDoctorId;
    getAppointmentSlotTimes(
      docId,
      doctor_branch_id,
      dateFormat,
      virtual_branch_id
    );
  };

  // const formatAge = (personAge: string): string => {
  //   let age = personAge?.replace('Y', 'Years');
  //   age = age?.replace('M', 'Months');
  //   age = age?.replace('D', 'Days');
  //   return age;
  // };

  const formatElement = (option: any): string => {
    let element = `${option?.hlpid} | ${CommonUtils.getFullName(option)}`;

    if (option?.is_clinic_associated || option?.is_branch_associated) {
      element = `${element}, ${option?.age} ${option?.gender} | ${option?.dob} | ${option?.phone_no} `;

      if (option?.father_name) {
        element = `${element} | ${option?.father_name}`;
      }
    }

    return element;
  };
  const getEncounter = (data: any, id?: any, branch?: any) => {
    getPaymentCriteria(
      data?.hlpid || id,
      virtualClinicBranchId || branch,
      doctorId,
      '',
      '',
      false,
      '',
      '',
      '',
      '',
      true
    )
      .then(res => {
        setEncounters(res?.data?.criteria?.visit_ids || []);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const getDoctorAppointmentTypes = (): void => {
    getAppointmentTypes(nh_id, virtualClinicBranchId)
      .then(res => {
        setSelectedAppointmentTypes(res?.data?.types || []);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const getPatients = (
    text: string,
    isFromVirtualOperation?: boolean
  ): void => {
    setSearchFieldOptions([]);
    setPatientSelected(false);

    getVirtualPatientsList(virtualClinicBranchId, text)
      .then(res => {
        if (res?.data?.patients?.length > 0) {
          const formattedOptions = res.data.patients.map((element: any) => ({
            ...element,
            formattedDetails: formatElement(element)
          }));

          setSearchFieldOptions(formattedOptions);

          if (isFromVirtualOperation && res?.data?.patients?.length === 1) {
            const newValue = formattedOptions?.[0];
            setPatientSelected(true);
            setSearchText(newValue);
            setSelectedPatientDetails(newValue);
            setPersonValues({
              first_name: newValue?.first_name,
              last_name: newValue?.last_name,
              phone_code: newValue?.phone_code,
              phone_number: newValue?.phone_no,
              email: newValue?.email,
              date_of_birth: newValue?.dob,
              gender: newValue?.gender,
              salutation: newValue?.salutation,
              hlpid: newValue?.hlpid
            });
          }
        }
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const getStandAlonePatients = (text: string, isFromReg?: boolean): void => {
    setSearchFieldOptions([]);
    setPatientSelected(false);

    searchPatient(text, virtualClinicBranchId)
      .then(res => {
        if (res?.data?.patients?.length > 0) {
          const formattedOptions = res?.data?.patients.map((element: any) => ({
            ...element,
            formattedDetails: formatElement(element)
          }));

          setSearchFieldOptions(formattedOptions);

          if (isFromReg && res?.data?.patients.length === 1) {
            const newValue = formattedOptions?.[0];
            setPatientSelected(true);
            setSearchText(newValue);
            setSelectedPatientDetails(newValue);
            setPersonValues({
              first_name: newValue?.first_name,
              last_name: newValue?.last_name,
              phone_code: newValue?.phone_code,
              phone_number: newValue?.phone_no,
              email: newValue?.email,
              date_of_birth: newValue?.dob,
              gender: newValue?.gender,
              salutation: newValue?.salutation,
              hlpid: newValue?.hlpid.includes('*')
                ? newValue?.hlpid_full
                : newValue?.hlpid
            });

            getDoctorAppointmentTypes();
            getAppointmentSlots(
              selectedDate || moment().format('YYYY-MM-DD'),
              virtualClinicBranchId
            );
            getDoctorDetails(nh_id, virtualClinicBranchId);
          }
        }
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleGetDoctorsFromType = async (text: any, type: any, flag: any) => {
    if (
      flag
        ? flag == appointmentsTypes?.SPECIALIZATION
        : selectedType?.selected == appointmentsTypes?.SPECIALIZATION
    ) {
      const response = await getAllDoctorsList(text, '', '', type, '', '', '');
      setDoctorListSpecialization(response?.data?.doctors);
    } else if (
      flag
        ? flag == appointmentsTypes?.HOSPITAL
        : selectedType?.selected == appointmentsTypes?.HOSPITAL
    ) {
      const response = await getAllDoctorsList(text, type, '', '', '', '', '');
      setDoctorListSpecialization(response?.data?.doctors);
    } else if (
      flag == appointmentsTypes?.TELEMEDICINE ||
      flag == appointmentsTypes?.HOMECARE
    ) {
      const response = await getAllDoctorsList(text, '', '', '', type, '', '');
      setDoctorListSpecialization(response?.data?.doctors);
    }
  };

  const getDoctors = async (text: string) => {
    setDoctorSearchFieldOptions([]);
    !props?.isFromPersonWeb &&
      getVirtualDoctorsList(virtualClinicBranchId, text)
        .then(res => {
          if (res?.data?.doctors?.length > 0) {
            const formattedOptions = res.data.doctors.map((element: any) => ({
              ...element,
              formattedDetails: CommonUtils.getFullName(element)
            }));

            setDoctorSearchFieldOptions(formattedOptions);
          }
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    try {
      if (props?.isFromPersonWeb && selectedType?.selected == 'Doctor') {
        if (
          AuthUtils.getPersonCorporateDetails() &&
          props?.type == 'corporate'
        ) {
          const response_corporate = await getCorporateDoctor(
            props?.healphaId,
            text,
            ''
          );
          if (response_corporate?.data?.doctors?.length > 0) {
            set_branch_id_coporate(
              response_corporate?.data?.doctors[0]?.branches[0]?.branch_id
            );
            const formattedOptions = response_corporate.data.doctors.map(
              (element: any) => ({
                ...element,
                full_name: CommonUtils.getFullName(element)
              })
            );
            setDoctorSearchFieldOptions(formattedOptions);
          }
        } else {
          const response = await getAllDoctorsList(
            text,
            '',
            '',
            '',
            '',
            '',
            ''
          );
          if (response?.data?.doctors?.length > 0) {
            const formattedOptions = response.data.doctors.map(
              (element: any) => ({
                ...element,
                full_name: CommonUtils.getFullName(element)
              })
            );
            setDoctorSearchFieldOptions(formattedOptions);
          }
        }
      } else if (selectedType?.selected == 'Specialization') {
        const res = await getSpecializationSearch(text, '', '');
        const specializations = res?.data?.specializations;
        const data: any = [];
        if (specializations && Array.isArray(specializations)) {
          specializations.forEach((item: any) => {
            data.push({
              full_name: item.specialization_type_name,
              id: encodeURIComponent(item.specialization_type_value)
            });
          });
        }
        setDoctorSearchFieldOptions(data);
      } else if (selectedType?.selected == 'Hospital') {
        const res = await getPracticeSearch(text);
        if (res?.data?.practices?.length > 0) {
          const data = res.data.practices;
          setDoctorSearchFieldOptions(data);
        }
      }
    } catch (err) {
      enqueueSnackbar(
        (err as any)?.message || t('ERROR_ENCOUNTERED'),
        SnackBarConfig('e')
      );
    }
  };

  const selectedPatientsDetails = () => {
    getPersonDetails(props?.healphaId).then(res => {
      const newValue = res?.data;
      setPatientSelected(true);
      setSearchText(newValue);
      setSelectedPatientDetails(newValue);
      setPersonValues({
        first_name: newValue?.first_name,
        last_name: newValue?.last_name,
        phone_code: newValue?.phone_code,
        phone_number: newValue?.phone_no,
        email: newValue?.email,
        date_of_birth: newValue?.dob,
        gender: newValue?.gender,
        salutation: newValue?.salutation,
        hlpid: newValue?.hlpid.includes('*')
          ? newValue?.hlpid_full
          : newValue?.hlpid
      });
    });
  };

  const rescheduleInit = () => {
    if (props?.appointmentType) {
      setValues({...values, appointmentType: props?.appointmentType});
    }
    if (props?.enc_id) {
      setValues({...values, encounter_id: props?.enc_id});
    }
  };
  React.useEffect(rescheduleInit, []);

  React.useEffect(() => {
    subscription.current = CommunicationService.getMessage().subscribe(
      function (message: any) {
        if (
          message?.data?.type === STANDALONE_APPOINTMENT_UPDATE &&
          message?.data?.value === true
        ) {
          closePopup();
        }
      }
    );

    return () => {
      return subscription?.current?.unsubscribe();
    };
  });

  const onChange = async (newValue: any, id: any, flag: any) => {
    if (id == patientId) {
      setPatientSelected(true);
      setSearchText(newValue);
      setSelectedPatientDetails(newValue);
      setPersonValues({
        first_name: newValue?.first_name,
        last_name: newValue?.last_name,
        phone_code: newValue?.phone_code,
        phone_number: newValue?.phone_no,
        email: newValue?.email,
        date_of_birth: newValue?.dob,
        gender: newValue?.gender,
        salutation: newValue?.salutation,
        hlpid: newValue?.hlpid.includes('*')
          ? newValue?.hlpid_full
          : newValue?.hlpid
      });

      if (isStandAloneDoctor) {
        getDoctorAppointmentTypes();
        newValue?.is_branch_associated && getEncounter(newValue);

        getAppointmentSlots(
          selectedDate || moment().format('YYYY-MM-DD'),
          virtualClinicBranchId
        );
        getDoctorDetails(nh_id, virtualClinicBranchId);
      }
    } else if (id == doctorId && !props?.isFromPersonWeb) {
      setDoctorSelected(true);
      setDoctorSearchText(newValue);
      setSelectedDoctorDetails(newValue);
      setSelectedDoctorId(newValue?.non_healpha_user_id);
      getDoctorDetails(
        newValue?.non_healpha_user_id,
        newValue?.branches[0]?.branch_id || ''
      );
    } else if (
      flag
        ? flag == appointmentsTypes?.DOCTOR
        : selectedType?.selected == appointmentsTypes?.DOCTOR
    ) {
      setDoctorSelected(true);
      setDoctorSearchText(newValue);
      setSelectedDoctorDetails(newValue);
      setSelectedDoctorId(newValue?.non_healpha_user_id);
      getDoctorDetails(newValue?.non_healpha_user_id);
      props?.isFromPersonWeb &&
        getEncounter(
          newValue,
          props?.healphaId,
          newValue?.branches?.[0]?.branch_id
        );
    } else if (
      flag
        ? flag == appointmentsTypes?.SPECIALIZATION
        : selectedType?.selected == appointmentsTypes?.SPECIALIZATION
    ) {
      handleGetDoctorsFromType('', newValue?.id, flag || '');
    } else if (
      flag
        ? flag == appointmentsTypes?.HOSPITAL
        : selectedType?.selected == appointmentsTypes?.HOSPITAL
    ) {
      handleGetDoctorsFromType('', newValue?.branch_id, flag || '');
    } else if (
      flag == appointmentsTypes?.TELEMEDICINE ||
      flag == appointmentsTypes?.HOMECARE
    ) {
      setSearchFields(true);
      handleGetDoctorsFromType('', newValue?.id, flag || '');
    }
  };

  const init = () => {
    if (props?.healphaId && props?.isFromDashBoard) {
      setDoctorSearchText(
        UserStore.getAppointmentValues()?.full_name ||
          UserStore.getAppointmentValues()?.branch_name
      );
      setSelectedType({selected: props?.isType});
      selectedPatientsDetails();
      onChange(UserStore.getAppointmentValues(), 2, props?.isType);
    }
    if (props?.healphaId && props?.isFromDashBoard == undefined) {
      if (isStandAloneDoctor) {
        getStandAlonePatients(props.healphaId, true);
      } else {
        props?.isFromPersonWeb
          ? selectedPatientsDetails()
          : getPatients(props.healphaId, true);
      }
    }
    if (props?.isFromQrScan && props?.doctorId) {
      getDoctorById(
        props.doctorId,
        props?.branchId,
        props?.type == 'corporate' ? 'corporate' : ''
      )
        .then(response => {
          const doctor = response?.data;
          doctor.branches = response?.data?.branches.filter(
            (item: any) => item.branch
          );
          doctor.appointment_types = CommonUtils.makeAppointmentTypes(
            doctor?.price_list
          );
          setDoctorData(doctor);
          setSelectedDoctorDetails(doctor);
          setDoctorSelected(true);
          setSelectedDoctorId(doctor?.non_healpha_user_id);
          const dateFormat = moment().format('YYYY-MM-DD');
          getPersonAppointmentListQr(
            props?.healphaId,
            doctor?.non_healpha_user_id,
            '',
            dateFormat,
            dateFormat,
            '',
            ''
          ).then((res: any) => {
            response = res?.data?.appointments;
            response &&
              response?.map((list: any) => {
                if (list?.appointment?.appointment_status == 'Booked') {
                  setAppointId(list?.appointment);
                  setShowPersonRedirectionDialog(true);
                }
                if (
                  list?.appointment?.appointment_status == 'Checkedin' ||
                  list?.appointment?.appointment_status == 'Confirm'
                ) {
                  setQrAppointId(list?.appointment?.id);
                  setShowPersonNoDialog(true);
                  // closePopup(list?.appointment?.id);
                }
                if (
                  list?.appointment?.appointment_status == 'Consulting' ||
                  list?.appointment?.appointment_status == 'Completed' ||
                  list?.appointment?.appointment_status == 'Closed' ||
                  list?.appointment?.appointment_status == 'Triage'
                ) {
                  setQrAppointId(list?.appointment?.id);
                  setPersonAllDialogs(true);
                }
              });
          });
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    }
    if (props?.isFromQrScan && props?.QrBranchId) {
      getAllDoctorsList('', props?.QrBranchId, '', '', '', '', '').then(
        (res: any) => {
          setDoctorListSpecialization(res?.data?.doctors);
          setSelectedType({selected: 'Hospital'});
        }
      );
    }
  };

  React.useEffect(init, []);

  // search input field on text change callback
  const onInputChange = (text: string, id: any) => {
    if (id == doctorId) {
      setDoctorSearchText(text);
    }
  };

  const apiCallback = (text: string, id: any) => {
    if (id == patientId) {
      setSearchText(text);
    } else if (id == doctorId) {
      setDoctorSearchText(text);
    }

    if (text?.length > 2) {
      if (id == patientId) {
        if (isStandAloneDoctor) {
          getStandAlonePatients(text);
        } else {
          getPatients(text);
        }
      } else if (id == doctorId) {
        getDoctors(text);
      }
    }
  };

  const closePatientCard = (): void => {
    setPatientSelected(false);
    setSearchFieldOptions([]);
    setSearchText('');
    setPatientSelected(false);
    setSelectedSlotButton('');
    setSlotTime('');
    setValues({
      appointmentType: '',
      compliant: '',
      encounter_id: ''
    });
  };

  const closeDoctorCard = (): void => {
    setDoctorSelected(false);
    setDoctorSearchFieldOptions([]);
    setDoctorSearchText('');
    setDoctorSelected(false);
    setBranchSelected(false);
  };

  const openRightSection = (item: any, appointmentTypes: any): void => {
    setBranchSelected(true);
    setSelectedBranch(item);
    setSelectedAppointmentTypes(appointmentTypes);
    getAppointmentSlots(
      selectedDate || moment().format('YYYY-MM-DD'),
      item?.branch_id
    );
  };

  const handleChangeConfirmed = (answer: any) => {
    if (answer == 'yes') {
      setChangeConfirmed(true);
    } else {
      setChangeConfirmed(false);
    }
  };

  const handleChange = (name: any) => (event: any) => {
    const onlineApp: any =
      name == 'encounter_id' &&
      encounters?.filter((i: any) => i?.id == event.target.value);
    if (name == 'encounter_id' && onlineApp) {
      setValues({
        ...values,
        onlineOffline:
          onlineApp?.[0]?.is_online_appointment == true ? 'online' : 'offline'
      });
    }
    if (name == 'onlineOffline') {
      // event.target.value == 'online' && setShowChangeConfirmPop(true);
      // changeConfirmed &&
      setValues({
        ...values,
        onlineOffline: event.target.value
      });
    } else {
      setValues({...values, [name]: event.target.value});
    }
  };

  const handleSlotTimeButtonClick = (timeObject: any) => {
    setSelectedSlotButton(timeObject?.value);
    setSlotTime(timeObject?.label);
  };

  const showMore = (index: number, length: any): any => {
    const data = [...slotsDetails];
    data[index].expanded = data?.[index]?.itemsToShow === 12 ? true : false;
    data[index].itemsToShow = data?.[index]?.itemsToShow === 12 ? length : 12;
    setSlotsDetails(data);
  };

  const handleSubmit = (): void => {
    props?.isFromPersonWeb &&
      values?.encounter_id &&
      getConsultationItem(
        personValues?.hlpid,
        selectedBranch?.branch?.branch_id ||
          doctorData?.branches?.[0]?.branch?.branch_id,
        selectedDoctorDetails?.non_healpha_user_id ||
          doctorData?.non_healpha_user_id,
        appointmentObject?.appointment?.appointment_type || 'review',
        moment(calendarValue).format('YYYY-MM-DD'),
        values?.encounter_id,
        'General',
        false,
        '',
        values?.onlineOffline
          ? values?.onlineOffline?.toLowerCase() == 'online'
            ? true
            : false
          : false
      ).then(res => {
        // eslint-disable-next-line no-console
        setIsPaymentZeroForReview(res?.data?.consultation?.service_amount == 0);
      });
    if (
      slotTime &&
      moment(calendarValue).date() === moment().date() &&
      moment(slotTime, 'HH:mm a').isSameOrBefore(moment())
    ) {
      enqueueSnackbar(t('PAST_TIME_SLOT_WARNING'), SnackBarConfig('w'));
    } else if (values?.appointmentType && values?.compliant && slotTime) {
      setShowDialog(true);
    } else {
      enqueueSnackbar(t('APPOINTMENT_WARNING_TEXT'), SnackBarConfig('w'));
    }
  };

  const closePersonSignUpPopup = (): void => {
    setShowPersonSignUpPopup(false);
  };

  const closeOtpPopup = (): void => {
    setOpenOtpPopup(false);
  };

  const openPersonRegPopup = (): void => {
    setShowPersonSignUpPopup(true);
  };

  const openOtpScreen = (): void => {
    const data = {
      phone_code: selectedPatientDetails?.phone_code || environment.dialCode,
      phone_number:
        selectedPatientDetails?.phone_no_full ||
        selectedPatientDetails?.phone_no
    };

    sendOtpToUser(data)
      .then(res => {
        setOpenOtpPopup(true);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleAppointmentClick = (item: any) => {
    item?.appointment_type_name === AppointmentTypelist.review
      ? setShowEncounterDropDown(true)
      : setShowEncounterDropDown(false);
    setAppointmentObject(item);
  };

  const openAppointmentsList = (appointmentTypes: any) => (event: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedAppointmentsList(appointmentTypes);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRelation = (): any => {
    if (
      selectedPatientDetails?.gender === genderOptionsList?.[0]?.value ||
      selectedPatientDetails?.gender === genderOptionsList?.[0]?.key
    ) {
      return Relations.SON;
    }

    if (
      selectedPatientDetails?.gender === genderOptionsList?.[1]?.value ||
      selectedPatientDetails?.gender === genderOptionsList?.[1]?.key
    ) {
      return Relations.DAUGHTER;
    }
  };

  const renderAppointmentList = (): React.ReactElement => {
    return (
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        {selectedAppointmentsList?.map((item: any, index: number) => (
          <MenuItem key={index} value={item?.appointment_type_value}>
            {`${item?.appointment_type_name} - Rs.${item.price}`}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  const renderCloseIcon = (): React.ReactElement => {
    return (
      <IconButton
        id="close_new_appointmnet"
        onClick={closePopup}
        className="close-icon">
        <Icons.CloseBlackIcon />
      </IconButton>
    );
  };

  const renderTitle = (): React.ReactElement => {
    return <Typography className="popup-title">New Appointment</Typography>;
  };

  const handlePersonDropDown = (prop: any) => (event: any) => {
    setSelectedType({...selectedType, [prop]: event.target.value});
    setDoctorListSpecialization([]);
  };

  const renderSearchField = (
    options: any,
    field: any,
    id: any,
    placeholderText: string,
    noOptionsText: string,
    loading: boolean,
    disabled: boolean,
    fieldSearchText: any,
    className?: string
  ): React.ReactElement => {
    return (
      <Grid
        className={props?.isFromPersonWeb ? 'search-and-drop-down-grid' : ''}>
        <Grid
          className={
            props?.isFromPersonWeb ? 'search-and-drop-down-grid-search' : ''
          }>
          <CustomAutoCompleteField
            options={options}
            field={field}
            infoField={''}
            id={id}
            disabled={disabled}
            required={false}
            placeHolder={placeholderText}
            onChange={onChange}
            onInputChange={onInputChange}
            apiCallback={apiCallback}
            defaultValue={fieldSearchText || null}
            loading={loading}
            debounce={true}
            noOptionsText={noOptionsText}
            customTextFieldProps={textFieldProps}
            size="small"
            className={className}
          />
        </Grid>
        {props?.isFromPersonWeb && (
          <FormSelectField
            name="selectedType"
            placeholder="Select Event Type"
            value={selectedType['selected']}
            dropDownProps={dropDownPropsForPersonWeb}
            dropDownOptions={personDropDownOptions}
            callback={handlePersonDropDown('selected')}
          />
        )}
      </Grid>
    );
  };

  const renderImage = (): React.ReactElement => {
    return (
      <img src={CheckupImage} style={{width: '40rem'}} alt="check up image" />
    );
  };

  const renderBranchCard = (item: any): React.ReactElement => {
    const workTimings = CommonUtils.getDoctorWorkTimings(
      doctorData.branches,
      t,
      item.branch_id
    );
    const appointmentTypes = CommonUtils.formAppointmentTypes(
      item.branch_id,
      doctorData,
      true
    );

    appointmentTypes &&
      appointmentTypes?.sort(function (a: any, b: any) {
        return a.price - b.price;
      });

    return (
      <Grid
        key={item?.id}
        style={{marginTop: '1rem'}}
        className={[
          'patient-card-container',
          selectedBranch?.branch_id === item?.branch_id &&
            'selected-card-container'
        ].join(' ')}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography className="branch-name">{`${item?.branch?.branch_name}, ${item?.branch?.city}`}</Typography>

            {workTimings?.map?.((ele: any, index: any) => {
              return (
                <Grid key={index}>
                  <Typography className="days-timings">
                    {`${ele?.label}  ${ele?.value}`}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              className="book-button"
              onClick={() => openRightSection(item, appointmentTypes)}>
              Book
            </Button>
            <Button
              variant="outlined"
              className="appointment-button"
              onClick={openAppointmentsList(appointmentTypes)}
              endIcon={<Icons.DoubleForwardArrowIcon />}>
              {`${appointmentTypes?.[0]?.appointment_type_name} Rs.${appointmentTypes?.[0]?.price}`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderBranchesCard = (): React.ReactElement => {
    return doctorData.branches.map((item: any) => renderBranchCard(item));
  };

  const renderKeyValueObject = (value: string, Icon?: any): any => {
    return (
      value && (
        <Grid
          item
          container
          spacing={2}
          alignItems="center"
          style={{paddingTop: '0.75rem'}}>
          {Icon && (
            <Grid item xs={2}>
              <Icon />
            </Grid>
          )}
          <Grid item xs={10}>
            <Typography className="card-value">{value}</Typography>
          </Grid>
        </Grid>
      )
    );
  };

  const renderPatientCard = (): React.ReactElement => {
    return (
      <Grid className="patient-card-container">
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Avatar
              alt=""
              className="vc-patient-icon"
              src={CommonUtils.getFullImageUrl(
                selectedPatientDetails?.person_image
              )}
            />
          </Grid>

          <Grid item xs={8} container>
            <Grid item xs={12}>
              <Typography className="patient-name">
                {`${
                  selectedPatientDetails?.salutation
                } ${CommonUtils.getFullName(selectedPatientDetails)}`}
              </Typography>
            </Grid>
            {selectedPatientDetails?.father_name &&
              selectedPatientDetails?.gender && (
                <Typography className="father-name">
                  {`${getRelation()} ${selectedPatientDetails?.father_name}`}
                </Typography>
              )}

            {selectedPatientDetails?.gender &&
              selectedPatientDetails?.age &&
              renderKeyValueObject(
                `${selectedPatientDetails?.gender},${selectedPatientDetails?.age}`,
                Icons.GenderDarkIcon
              )}

            {selectedPatientDetails?.email &&
              renderKeyValueObject(
                selectedPatientDetails.email,
                Icons.EmailIcon
              )}
            {selectedPatientDetails?.phone_no &&
              renderKeyValueObject(
                selectedPatientDetails?.phone_no,
                Icons.CallDarkIcon
              )}
            {selectedPatientDetails?.hlpid &&
              renderKeyValueObject(
                selectedPatientDetails?.hlpid,
                Icons.PatientIcon
              )}
          </Grid>

          <Grid
            item
            xs={2}
            container
            justifyContent="flex-end"
            style={{cursor: 'pointer'}}>
            <Icons.CloseBlackIcon
              onClick={closePatientCard}
              id="close_patient_card"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDoctorCard = (): React.ReactElement => {
    const specializationList = selectedDoctorDetails?.branches?.map(
      (item: any) => {
        return DoctorSpecializations?.[item?.specialization];
      }
    );
    const uniqueSpecialization = _.uniqBy(specializationList, (e: any) => {
      return e;
    });
    const specialization = uniqueSpecialization.join();

    const educationList = selectedDoctorDetails?.education?.map((item: any) => {
      return item?.edu_college;
    });
    const uniqueEducation = _.uniqBy(educationList, (e: any) => {
      return e;
    });
    const education = uniqueEducation.join();
    return (
      <Grid className="patient-card-container">
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Avatar
              alt=""
              className="vc-patient-icon"
              src={CommonUtils.getFullImageUrl(
                selectedDoctorDetails?.profile_image
              )}
            />
          </Grid>

          <Grid item xs={8} container>
            <Grid item xs={12}>
              <Typography className="patient-name">
                {`${
                  selectedDoctorDetails?.salutation
                } ${CommonUtils.getFullName(selectedDoctorDetails)}`}
              </Typography>
            </Grid>
            {renderKeyValueObject(specialization)}
            {renderKeyValueObject(education)}
          </Grid>

          <Grid
            item
            xs={2}
            container
            justifyContent="flex-end"
            style={{cursor: 'pointer'}}>
            <Icons.CloseBlackIcon onClick={closeDoctorCard} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const selectedDoctor = (item: any) => {
    const newValue = item;
    setDoctorSelected(true);
    setDoctorSearchText(newValue);
    setSelectedDoctorDetails(newValue);
    setSelectedDoctorId(newValue?.non_healpha_user_id);
    getDoctorDetails(
      newValue?.non_healpha_user_id,
      selectedType?.selected == appointmentsTypes?.HOSPITAL
        ? item?.branches[0]?.branch_id
        : ''
    );
  };

  const renderDoctorCardList = (item: any) => {
    return (
      <Grid
        className="doctor-list-for-specialization-single-card"
        onClick={() => selectedDoctor(item)}>
        <Grid item xs={2}>
          <Avatar
            alt=""
            className="vc-patient-icon"
            src={CommonUtils.getFullImageUrl(item?.person_image)}
          />
        </Grid>
        <Grid item xs={8} className="doctor-details-info">
          {item?.salutation} {item?.first_name} {item?.last_name}
          <Grid item xs={12} className="doctor-details-info-specialization">
            {item?.branches?.[0]?.specialization}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDoctorPatientInfo = (): React.ReactElement => {
    return (
      <Grid className="doctor-patient-container">
        {!props?.isFromPersonWeb && (
          <Typography className="doctor-patient-title">
            {patientSelected ? 'Patient' : 'Select Patient'}
          </Typography>
        )}
        {patientSelected &&
        !props?.isFromPersonWeb &&
        (isStandAloneDoctor ||
          selectedPatientDetails?.is_clinic_associated ||
          selectedPatientDetails?.is_branch_associated)
          ? renderPatientCard()
          : !props?.isFromPersonWeb &&
            renderSearchField(
              searchFieldOptions,
              'formattedDetails',
              patientId,
              'Search Patient',
              '',
              false,
              false,
              searchText,
              'new-patient-appointment-search-option listbox-scroll-style'
            )}

        {searchFieldOptions?.length === 0 && searchText?.length > 2 ? (
          <Typography className="patient-info-text">
            {t('DO_YOU_WANT_TO_ADD_THIS_USER_AS_NEW_PATIENT')}
            <span className="patient-link-text" onClick={openPersonRegPopup}>
              {t('NEW_APPOINTMENT_ADD_NEW_PATIENT')}
            </span>
          </Typography>
        ) : (
          patientSelected &&
          !props?.isFromPersonWeb &&
          ((!isStandAloneDoctor &&
            !selectedPatientDetails?.is_clinic_associated &&
            !selectedPatientDetails?.is_branch_associated) ||
            (isStandAloneDoctor &&
              !selectedPatientDetails?.is_branch_associated)) && (
            <Typography className="patient-info-text">
              {t('DO_YOU_WANT_TO_ADD_THIS_USER_TO_THE_CLINIC')}
              <span className="patient-link-text" onClick={openOtpScreen}>
                {t('COMMON_ADD')}
              </span>
            </Typography>
          )
        )}

        {patientSelected &&
          !isStandAloneDoctor &&
          (selectedPatientDetails?.is_clinic_associated ||
            props?.isFromPersonWeb ||
            selectedPatientDetails?.is_branch_associated) && (
            <Grid>
              <Typography className="doctor-patient-title">
                {doctorSelected
                  ? 'Doctor'
                  : `Select ${
                      props?.isFromPersonWeb ? selectedType?.selected : 'Doctor'
                    }`}
              </Typography>

              {doctorSelected
                ? renderDoctorCard()
                : !searchFields &&
                  renderSearchField(
                    doctorSearchFieldOptions,
                    props?.isFromPersonWeb
                      ? selectedType?.selected == 'Hospital'
                        ? 'branch_name'
                        : 'full_name'
                      : 'formattedDetails',
                    doctorId,
                    `Search ${
                      props?.isFromPersonWeb ? selectedType?.selected : 'Doctor'
                    }`,
                    `${selectedType?.selected} not found`,
                    false,
                    !patientSelected,
                    doctorSearchText,
                    props?.isFromPersonWeb
                      ? 'listbox-scroll-style width-reduced'
                      : 'listbox-scroll-style'
                  )}

              {doctorSelected &&
                doctorData?.branches?.length > 0 &&
                !isStandAloneDoctor &&
                renderBranchesCard()}
            </Grid>
          )}

        {selectedType?.selected != 'Doctor' &&
          doctorListSpecialization?.length > 0 &&
          !doctorSelected && (
            <Grid className="doctor-list-for-specialization-main-grid">
              {doctorListSpecialization?.map((item: any, index: any) => {
                return (
                  <Grid
                    className="doctor-list-for-specialization-grid"
                    key={index}>
                    {renderDoctorCardList(item)}
                  </Grid>
                );
              })}
            </Grid>
          )}
      </Grid>
    );
  };

  const closeChangeConfirmPopUp = (): void => {
    setShowChangeConfirmPop(false);
  };
  const renderChangeConfirmPopUp = () => {
    return (
      <ChangeConfirmPopUp
        open={showChangeConfirmPop}
        closeDeleteConfirmationDialog={closeChangeConfirmPopUp}
        fromWebFlow={true}
        handleChangeConfirmed={handleChangeConfirmed}
        setChangeConfirmed={setChangeConfirmed}
      />
    );
  };

  const renderRadioButtons = (): React.ReactElement => {
    return (
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="person-online"
          name="onlineOffline"
          onChange={handleChange('onlineOffline')}
          value={values?.onlineOffline}>
          {onlineOffline?.map((item: any) => {
            return (
              <FormControlLabel
                key={item?.value}
                value={item?.value}
                sx={{marginBottom: 0}}
                className="new-person-radio-button"
                control={
                  <Radio size="medium" id={'gender-radio_' + item?.label} />
                }
                label={t(item?.label)}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  // const renderCheckBoxForReview = (): React.ReactElement => {
  //   return (
  //     <Grid container>
  //       <FormControl component="fieldset">
  //         <Checkbox
  //           onChange={handleChange('online')}
  //           checked={values?.online}
  //           name={'online'}
  //         />
  //         Online
  //         <Checkbox
  //           onChange={handleChange('offline')}
  //           checked={values?.offline}
  //           name={'offline'}
  //         />
  //         Offline
  //       </FormControl>
  //     </Grid>
  //   );
  // };

  const renderNormalDropDown = (): React.ReactElement => {
    return (
      <Grid>
        <Select
          sx={dropDownProps}
          name="appointmentType"
          displayEmpty
          value={values?.appointmentType}
          input={<OutlinedInput style={inputProps} />}
          inputProps={inputProps}
          onChange={handleChange('appointmentType')}>
          <MenuItem disabled value="">
            <em>{t('SELECT_DOCTOR_APPOINTMENT_TYPE')}</em>
          </MenuItem>

          {selectedAppointmentTypes?.map((item: any, index: number) => (
            <MenuItem
              key={index}
              value={item?.appointment_type_value}
              onClick={() => handleAppointmentClick(item)}>
              {`${item?.appointment_type_name} -Rs.${item?.price}`}
            </MenuItem>
          ))}
        </Select>
        {showEncounterDropDown && (
          <Grid>
            <Select
              sx={dropDownProps}
              name="appointmentType"
              displayEmpty
              value={values?.encounter_id}
              input={<OutlinedInput style={inputProps} />}
              inputProps={inputProps}
              onChange={handleChange('encounter_id')}>
              <MenuItem disabled value="">
                <em>{t('SELECT_ENCOUNTER_ID')}</em>
              </MenuItem>

              {encounters?.map((item: any, index: number) => (
                <MenuItem key={index} value={item?.id}>
                  {item.id}
                </MenuItem>
              ))}
            </Select>

            {values?.encounter_id && renderRadioButtons()}
            {/* {values?.encounter_id && renderCheckBoxForReview()} */}
          </Grid>
        )}
      </Grid>
    );
  };

  const renderComplaintFiled = (): React.ReactElement => {
    return (
      <Grid>
        <Typography className="field-label-text">
          {t('COMPLIANCE_FOR_YOUR_VISIT')}
        </Typography>

        <FormTextField
          name="compliant"
          value={values['compliant']}
          callback={handleChange('compliant')}
          className="custom-text-filed-height"
        />
      </Grid>
    );
  };

  const renderDateContainer = (): React.ReactElement => {
    return (
      <Grid className="date-appointment-container">
        {renderNormalDropDown()}
        {renderComplaintFiled()}

        <Grid className="date-picker-container">
          <Typography className="date-picker-label">
            {t('CLINIC_PICK_DATE')}
          </Typography>
          <LocalizationProvider dateAdapter={MomentAdapter}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              openTo="day"
              views={['day']}
              defaultCalendarMonth={moment()}
              value={calendarValue}
              maxDate={moment().add(30, 'days')}
              minDate={moment()}
              onChange={newValue => {
                setCalendarValue(newValue);
                getAppointmentSlots(
                  newValue,
                  selectedBranch?.branch_id || virtualClinicBranchId
                );
              }}
              renderInput={params => <TextField {...params} />}
              inputFormat="ddd-MM-YYYY"
              toolbarFormat="ddd"
            />
          </LocalizationProvider>
        </Grid>

        <Button
          variant="outlined"
          id="continue_button"
          className={`continue-button ${
            (!values?.appointmentType ||
              !values?.compliant ||
              !slotTime ||
              (appointmentObject.appointment_type_name ===
                AppointmentTypelist.review &&
                !values?.encounter_id)) &&
            'disabled-button'
          }`}
          disabled={
            !values?.appointmentType ||
            !values?.compliant ||
            !slotTime ||
            (appointmentObject.appointment_type_name ===
              AppointmentTypelist.review &&
              !values?.encounter_id)
          }
          onClick={handleSubmit}>
          {t('CONTINUE')}
        </Button>
      </Grid>
    );
  };

  const renderSlots = (): React.ReactElement => {
    return slotsDetails.map((item: any, index: any) => {
      return (
        <Grid key={index} className="sessions-container">
          <Grid container spacing={2}>
            <Grid item>
              {item?.key === 'morning' && <Icons.MorningIcon />}
              {item?.key === 'afternoon' && <Icons.AfternoonIcon />}
              {item?.key === 'evening' && <Icons.EveningIcon />}
            </Grid>

            <Grid item>
              <Typography className="session-name-text">
                {slotTimings?.[item?.key] && slotTimings?.[item.key]?.length > 0
                  ? t(item?.label, {
                      count: slotTimings?.[item.key]?.length
                    })
                  : t(item?.label1)}
              </Typography>

              {(!slotTimings?.[item?.key] ||
                (slotTimings?.[item?.key] &&
                  slotTimings?.[item?.key]?.length === 0)) && (
                <Typography className="not-available-text">
                  {t('NO_SLOTS_AVAILABLE')}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{paddingTop: '1rem'}}>
            {slotTimings?.[item?.key] &&
              slotTimings?.[item?.key]?.length > 0 &&
              slotTimings?.[item?.key]
                .slice(0, item?.itemsToShow)
                .map((time: any, index1: any) => {
                  return (
                    <Grid key={index1} item xs={4} spacing={2}>
                      <Button
                        variant="outlined"
                        size="small"
                        id={'slot_button_' + time?.label}
                        className={[
                          'slot-button',
                          selectedSlotButton === time?.value && 'border-button'
                        ].join(' ')}
                        onClick={() => handleSlotTimeButtonClick(time)}
                        startIcon={<Icons.TimeIcon />}>
                        {time?.label}
                      </Button>
                    </Grid>
                  );
                })}
          </Grid>
          {slotTimings?.[item.key]?.length > 12 && (
            <a
              onClick={() => showMore(index, slotTimings?.[item?.key]?.length)}>
              {item?.expanded ? (
                <Typography id={'compress' + index} className="expanded-button">
                  {t('VIEW_LESS')}
                </Typography>
              ) : (
                <Typography id={'expand_' + index} className="expanded-button">
                  {t('VIEW_MORE')}
                </Typography>
              )}
            </a>
          )}
        </Grid>
      );
    });
  };

  const renderSlotsContainer = (): React.ReactElement => {
    return (
      <Grid className="slots-container">
        <Typography className="slots-text">Slots</Typography>
        <Divider className="divider" />

        {renderSlots()}
      </Grid>
    );
  };

  const renderContent = (): React.ReactElement => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          {renderDoctorPatientInfo()}
        </Grid>
        {((!branchSelected && !isStandAloneDoctor) ||
          (isStandAloneDoctor &&
            (!patientSelected ||
              !selectedPatientDetails?.is_branch_associated))) && (
          <Grid item xs={8}>
            {renderImage()}
          </Grid>
        )}
        {((branchSelected && !isStandAloneDoctor) ||
          (isStandAloneDoctor &&
            patientSelected &&
            selectedPatientDetails?.is_branch_associated)) && (
          <Grid item xs>
            {renderDateContainer()}
          </Grid>
        )}
        {((branchSelected && !isStandAloneDoctor) ||
          (isStandAloneDoctor &&
            patientSelected &&
            selectedPatientDetails?.is_branch_associated)) && (
          <Grid item xs>
            {renderSlotsContainer()}
          </Grid>
        )}
      </Grid>
    );
  };

  const renderPopUp = (): React.ReactElement => {
    return (
      <ReviewUserAppointmentComponent
        open={showDialog}
        closeReviewPopUp={closeReviewPopup}
        values={personValues}
        isStandAloneDoctor={isStandAloneDoctor}
        isFromVirtualClinic={isStandAloneDoctor ? false : true}
        isFromPersonWeb={props?.isFromPersonWeb}
        virtualClinicBranchId={
          isStandAloneDoctor ? null : virtualClinicBranchId
        }
        healphaId={personValues?.hlpid}
        appointmentValues={{
          appointment: appointmentObject,
          branch: selectedBranch || doctorData?.branches?.[0],
          compliant: values?.compliant,
          calendarValue: calendarValue,
          slotTime: slotTime,
          selectedTime: selectedSlotButton,
          doctor: selectedDoctorDetails || doctorData,
          encounterId: values?.encounter_id,
          is_online_appointment:
            values?.onlineOffline &&
            values?.onlineOffline?.toLowerCase() == 'online'
              ? true
              : false
        }}
        isPaymentZeroForReview={isPaymentZeroForReview}
      />
    );
  };

  const renderPersonRedirectionPopUp = (): any => {
    return (
      <PersonPaymentRedirection
        open={showPersonRedirectionDialog}
        closePersonRedirectionPopUp={closePersonRedirectionPopUp}
        appointId={appointId}
        closePopup={closePopup}
      />
    );
  };

  const renderPersonCheckedInPopUp = (): any => {
    return (
      <PersonCheckedInDialog
        open={showPersonNoDialog}
        closePersonCheckedInPopup={closePersonCheckedInPopup}
        closePopup={closePopup}
        selectedQrAppointId={qrAppointId}
      />
    );
  };

  const renderPersonAllPopUp = (): any => {
    return (
      <PersonAllDialogs
        open={personAllDialogs}
        closePersonAllPopup={closePersonAllPopup}
        closePopup={closePopup}
        selectedQrAppointId={qrAppointId}
      />
    );
  };
  const rerender = () => {
    renderRadioButtons();
  };

  React.useEffect(rerender, [changeConfirmed]);
  const renderPersonRegPopup = (): React.ReactElement => {
    return (
      <NewPersonRegistrationComponent
        branchId={virtualClinicBranchId}
        open={showPersonSignUpPopup}
        closePersonSignUpPopup={closePersonSignUpPopup}
        is_virtual_clinic={props?.is_virtual_clinic}
      />
    );
  };

  const renderOtpPopup = (): React.ReactElement => {
    return (
      <OtpScreenComponent
        open={openOtpPopup}
        values={{
          phone_code: selectedPatientDetails?.phone_code,
          phone_number: selectedPatientDetails?.phone_no,
          phone_no_full: selectedPatientDetails?.phone_no_full,
          first_name: selectedPatientDetails?.first_name,
          last_name: selectedPatientDetails?.last_name,
          hlpid: selectedPatientDetails?.hlpid.includes('*')
            ? selectedPatientDetails?.hlpid_full
            : selectedPatientDetails?.hlpid,
          branchId: virtualClinicBranchId
        }}
        closeOtpPopup={closeOtpPopup}
        isFromVirtualClinicBookings={isStandAloneDoctor ? false : true}
        isFromStandaloneBookings={isStandAloneDoctor ? true : false}
        isFromRelation={true}
      />
    );
  };

  return (
    <Grid className="new-appointment-bookings-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        aria-labelledby="new-appointment-bookings-pop-up"
        aria-describedby="new-appointment-bookings"
        classes={{paper: 'new-appointment-bookings-popup'}}>
        <DialogContent className="new-appointment-bookings-content">
          {renderTitle()}
          {renderCloseIcon()}
          {renderContent()}
        </DialogContent>
      </Dialog>
      {showPersonSignUpPopup && renderPersonRegPopup()}
      {openOtpPopup && renderOtpPopup()}
      {showDialog && renderPopUp()}
      {showPersonRedirectionDialog && renderPersonRedirectionPopUp()}
      {showPersonNoDialog && renderPersonCheckedInPopUp()}
      {personAllDialogs && renderPersonAllPopUp()}
      {showChangeConfirmPop && renderChangeConfirmPopUp()}
      {Boolean(anchorEl) && renderAppointmentList()}
    </Grid>
  );
};

export default NewAppointmentBookingsComponent;
