import {environment} from '../environment/environment';
import {AxiosCall} from '../axios';

const apiEndPoints = environment.apiEndPoints;
const paymentRouter = apiEndPoints.payment;
const orderRouter = paymentRouter.order;

export const createOrder = async (data: any): Promise<any> => {
  const url = `${paymentRouter.base}/${orderRouter.base}/${orderRouter.create}`;
  return AxiosCall.post(url, data);
};

export const verifyPayment = async (data: any): Promise<any> => {
  const url = `${paymentRouter.base}/${paymentRouter.appRedirection}/${paymentRouter.verify}`;
  return AxiosCall.post(url, data);
};

export const createPracticeSubscription = async (data: any): Promise<any> => {
  const url = `${paymentRouter.base}/${paymentRouter.practiceSubscription}/${paymentRouter.create}`;
  return AxiosCall.post(url, data);
};
