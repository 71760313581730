import axios from 'axios';

// wrapper between our services and axios - on how to handle the request!
export class AxiosCall {
  static async get(url: string, source?: any): Promise<any> {
    try {
      let response;
      if (source) {
        response = await axios.get(url, {
          cancelToken: source.token
        });
      } else {
        response = await axios.get(url);
      }
      return response.data;
    } catch (err: any) {
      if (err && err.response && err.response.data) {
        throw err.response.data;
      } else {
        throw err;
      }
    }
  }

  static async getImage(url: string, config: any): Promise<any> {
    try {
      const response = await axios.get(url, config);
      return response.data;
    } catch (err: any) {
      if (err && err.response && err.response.data) {
        throw err.response.data;
      } else {
        throw err;
      }
    }
  }

  static async post(url: string, payload: any, config?: any): Promise<any> {
    try {
      let response;
      if (config) {
        response = await axios.post(url, payload, config);
      } else {
        response = await axios.post(url, payload);
      }
      return response.data;
    } catch (err: any) {
      if (err && err.response && err.response.data) {
        throw err.response.data;
      } else {
        throw err;
      }
    }
  }

  static async put(url: string, payload: any): Promise<any> {
    try {
      const response = await axios.put(url, payload);
      return response.data;
    } catch (err: any) {
      if (err && err.response && err.response.data) {
        throw err.response.data;
      } else {
        throw err;
      }
    }
  }

  static async delete(url: string): Promise<any> {
    try {
      const response = await axios.delete(url);
      return response.data;
    } catch (err: any) {
      if (err && err.response && err.response.data) {
        throw err.response.data;
      } else {
        throw err;
      }
    }
  }

  static async deleteBody(url: string, body: string): Promise<any> {
    try {
      const response = await axios.delete(url, {data: body});
      return response.data;
    } catch (err: any) {
      if (err && err.response && err.response.data) {
        throw err.response.data;
      } else {
        throw err;
      }
    }
  }

  static async getWithOptions(url: string, options: any): Promise<any> {
    try {
      const response = await axios.get(url, options);
      return response.data;
    } catch (err: any) {
      if (err && err.response && err.response.data) {
        throw err.response.data;
      } else {
        throw err;
      }
    }
  }
}
export {axios};
