/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  OutlinedInput,
  Select,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import {
  getAllUsers,
  getUserForgotPassword
} from '@shared/services/PublicService';
// import InitSharedRepo from '../../../../../../shared';

import CommonUtils from 'src/utils/CommonUtils';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import ConfirmationBoxComponent from '../ConfirmationBox/ConfirmationBoxComponent';
import SecondaryCommonDialogTitleComponent from '../SecondaryCommonDialogTitle/SecondaryCommonDialogTitleComponent';
import DialogLeftSideIllustrationsComponent from 'src/modules/shared/DialogLeftSideIllustrations/DialogLeftSideIllustrationsComponent';
import Loader from '@components/framework/loader/Loader';
import {BORDER_TERTIARY_COLOR} from 'src/style/variable';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import './ForgotPasswordComponent.scss';
import {useHistory} from 'react-router';
import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';
import {UserTypes} from 'src/constants/SystemConstants';

const dropDownProps = {
  height: '3.9rem',
  width: '100%',
  border: `1px solid ${BORDER_TERTIARY_COLOR}`
};

const ForgotPasswordComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const [open, setOpen] = React.useState(props?.open);
  const [openConfirmationBox, setOpenConfirmationBox] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [dropDownOptions, setDropDownOptions]: any = React.useState([]);
  const [values, setValues]: any = React.useState({
    email_mobile_number: '',
    user: '',
    code: '',
    disabled: false
  });
  const [showCodeField, setShowCodeField]: any = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    props?.closeForgotPasswordPopup();
  };

  const handleEscClose = (event: any, reason: string) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const handleBlur = () => {
    if (!props?.isFromDoctor) {
      setDropDownOptions([]);
      getAllUsers(values?.email_mobile_number)
        .then(res => {
          if (res?.data?.persons?.length === 1) {
            setValues({
              ...values,
              ['disabled']: true,
              ['user']: res?.data?.persons?.[0]?.hlpid
            });
            setDropDownOptions(res.data.persons);
          } else if (res?.data?.persons?.length > 0) {
            setDropDownOptions(res.data.persons);
          }
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    }
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      let input: any = {};
      if (props?.isFromDoctor) {
        input = {
          email: values?.email_mobile_number,
          user_type: UserTypes.DOCTOR
        };
      } else {
        input = {hlpid: values?.user, user_type: UserTypes.PERSON};
      }
      if (showCodeField) {
        if (!values?.code) {
          enqueueSnackbar(t('PLEASE_ENTER_OTP'), SnackBarConfig('e'));
          return;
        }
        input.otp = values?.code;
      }
      setIsLoading(true);
      const resp: any = await getUserForgotPassword(input);
      setIsLoading(false);
      if (resp?.data?.token) {
        handleClose();
        // here move user to forgot password page
        const url = props?.isFromDoctor
          ? `/${BASE_ROUTE_PORTAL}/home/forgotPassword?token=${resp.data.token}`
          : `/${BASE_ROUTE_PORTAL}/home/forgotPasswordPerson?token=${resp.data.token}`;
        history.push(url);
      } else {
        setOpenConfirmationBox(true);
        setShowCodeField(true);
      }
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar(
        (err as any)?.message || t('ERROR_ENCOUNTERED'),
        SnackBarConfig('e')
      );
    }
  };

  const renderConfirmationBox = (): React.ReactElement => {
    return (
      <ConfirmationBoxComponent
        open={openConfirmationBox}
        closeConfirmationBox={() => {
          setOpenConfirmationBox(false);
        }}
        message={t('OTP_EMAIL_SMS_CONFIRMATION', {
          mobile: values?.email_mobile_number
        })}
      />
    );
  };

  const renderUsersDropDown = (): React.ReactElement => {
    return (
      <Select
        sx={dropDownProps}
        displayEmpty
        name="user"
        id="user"
        value={values?.user}
        disabled={values?.disabled || showCodeField}
        onChange={handleChange('user')}
        input={<OutlinedInput />}>
        <MenuItem disabled value="">
          <em style={{color: '#646467'}}>{t('SELECT_USER_LABEL')}</em>
        </MenuItem>

        {dropDownOptions?.map((item: any, index: number) => (
          <MenuItem key={index} value={item?.hlpid}>
            {`${item?.salutation}. ${item?.first_name} ${
              item?.middle_name ? item?.middle_name : ''
            } ${item?.last_name}`}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderFormSection = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormTextField
            placeholder={t('EMAIL_MOB_LABEL')}
            name="email_mobile_number"
            value={values['email_mobile_number']}
            callback={handleChange('email_mobile_number')}
            onBlurCallback={handleBlur}
            autoFocus={true}
            maxLength={50}
            disabled={showCodeField}
          />
        </Grid>

        {!props?.isFromDoctor && (
          <Grid item xs={12}>
            {renderUsersDropDown()}
          </Grid>
        )}
        {showCodeField && (
          <Grid item xs={12}>
            <FormTextField
              placeholder={t('ENTER_OTP')}
              name={'code'}
              value={values['code']}
              callback={handleChange('code')}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return <Grid style={{marginTop: '1rem'}}>{renderFormSection()}</Grid>;
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            style={{marginTop: '10rem'}}
            disabled={isLoading}
            className="submit-button"
            onClick={handleSubmit}>
            {isLoading ? <Loader size={28} /> : t('SUBMIT_LABEL')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className="forgot-password-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={(event: any, reason: string) => handleEscClose(event, reason)}
        aria-labelledby="forgot-password-login-pop-up"
        aria-describedby="forgot-password"
        classes={{paper: 'person-doctor-popup'}}>
        <Grid container spacing={1}>
          <Grid
            item
            lg={5}
            sx={{display: {xs: 'none', lg: 'block'}}}
            className="popup-left-section">
            <DialogLeftSideIllustrationsComponent
              title={t('FORGOT_PASSWORD_TILE')}
              footerText={t('LOGIN_SIGN_UP_FOOTER_TEXT', {
                appName: `${t('APP_NAME')}`
              })}
              specialtiesList={
                props?.isFromDoctor
                  ? CommonUtils.getDoctorSpecialtiesList(t)
                  : CommonUtils.getLoginSignUpSpecialtiesList(t)
              }
            />
          </Grid>

          <Grid item lg={7} className="popup-right-section">
            <DialogTitle id="forgot-password-title">
              <SecondaryCommonDialogTitleComponent
                title={t('FORGOT_PASSWORD')}
                subTitle={t('RESET_PWD_TITLE')}
                close={handleClose}
              />
            </DialogTitle>

            <DialogContent className="booking-appointment-popup-content">
              {renderDialogContent()}
            </DialogContent>

            <DialogActions
              disableSpacing={true}
              className="person-doctor-popup-actions">
              {renderDialogActions()}
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      {openConfirmationBox && renderConfirmationBox()}
    </Grid>
  );
};

export default ForgotPasswordComponent;
