import React from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import './ChangeConfirmPopUp.scss';

const ChangeConfirmPopUp = (props: any): React.ReactElement => {
  const [open, setOpen] = React.useState(props?.open);

  const {setChangeConfirmed} = props;
  const handleClose = () => {
    setOpen(false);
    setChangeConfirmed(false);

    props?.closeDeleteConfirmationDialog();
  };

  const handleConfirmation = () => {
    // deleteFunc(id);
    setChangeConfirmed(true);
    // handleClose();
    setOpen(false);
    setChangeConfirmed(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" />
        <DialogContent className="change-confirm-content-grid">
          <Typography>
            {' '}
            {props?.message
              ? props?.message
              : 'Are You Sure you want to change appointment type?'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props?.validityExtend ? 'No' : 'Cancel'}
          </Button>
          <Button onClick={handleConfirmation} color="primary" autoFocus>
            {props?.payLater ? 'OK' : 'YES'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangeConfirmPopUp;
