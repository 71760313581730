import React from 'react';
import {
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

interface FormSelectFieldProps {
  name: string;
  callback?: any;
  value: any;
  inputProps?: any;
  dropDownProps: any;
  readOnly?: boolean;
  disabled?: boolean;
  Icon?: any;
  StartAdornmentIcon?: any;
  placeholder?: any;
  dropDownOptions: any;
  dropDownOptionsProps?: any;
  dropDownMenuProps?: any;
  keyLabel?: any;
  keyValue?: any;
}

const FormSelectField = (props: FormSelectFieldProps): React.ReactElement => {
  const {
    name,
    value,
    dropDownProps,
    Icon,
    callback,
    StartAdornmentIcon,
    placeholder,
    readOnly,
    disabled,
    inputProps,
    dropDownOptions,
    dropDownOptionsProps,
    dropDownMenuProps,
    keyLabel,
    keyValue
  } = props;

  const {t} = useTranslation();

  return (
    <Select
      sx={dropDownProps}
      MenuProps={dropDownMenuProps || {}}
      displayEmpty
      name={name}
      id={name}
      readOnly={readOnly}
      disabled={disabled}
      value={value}
      onChange={callback}
      input={<OutlinedInput style={inputProps} />}
      IconComponent={Icon}
      startAdornment={
        StartAdornmentIcon && (
          <InputAdornment position="start">
            <StartAdornmentIcon />
          </InputAdornment>
        )
      }>
      <MenuItem disabled value="">
        <em style={{color: '#646467', fontSize: '14px'}}>{placeholder}</em>
      </MenuItem>

      {dropDownOptions &&
        dropDownOptions?.map((item: any, index: number) => (
          <MenuItem
            style={dropDownOptionsProps || {}}
            key={index}
            value={item.value || item?.[keyValue]}>
            {t(item?.label || item?.[keyLabel])}
          </MenuItem>
        ))}
    </Select>
  );
};

export default FormSelectField;
