/* eslint-disable max-len */

import NavRoutes from 'src/constants/route/NavRoutes';
import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';

export const NotificationTypes: any = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info'
};

export const ModuleTypes = {
  SCHOOL: 'School',
  OP: 'Op',
  COVID: 'Covid',
  MASS_GATHERING: 'Mass Gathering',
  SETTINGS: 'Settings'
};

export const MenuScreenTypes = {
  MODULE: 'module',
  MENU: 'menu',
  PAGE: 'page'
};

export const MenuSectionTypes = {
  MENU: 'menu',
  MASTER: 'master',
  SETTINGS: 'settings'
};

export const MenuModuleObject = {
  GENERAL: '997',
  OPERATIONAL: '998',
  TECHNICAL: '999'
};

export const TokensObject = {
  REACT_PHP: 1,
  PHP: 2
};

export const MenuDashboardPages = {
  DOCTOR: 'doctor_home',
  PERSON: 'healpha_dashboard',
  TIMELINE: 'person_web_timeline'
};
export const CreditTabs = {
  PERSON: 'Person',
  ENCOUNTER: 'Encounter'
};
export const CountryCodes = {
  INDIA: '+91'
};

export const UserTypes = {
  DOCTOR: 'doctor',
  PERSON: 'person',
  TEMPORARY_PERSON: 'temporary_person',
  PATIENT: 'patient',
  OP_BILLER: 'op biller',
  BILLER: 'biller',
  PRACTICE_ADMIN: 'practice admin',
  HEALPHA_ADMIN: 'healpha admin',
  BRANCH_ADMIN: 'branch admin',
  NURSE: 'op nurse',
  NURSE_SIMPLE: 'nurse',
  OP_DOCTOR: 'op doctor',
  EXECUTION_TEAM: 'execution team',
  VIRTUAL_ADMIN: 'virtual admin'
};

export const PaymentProviders = {
  RAZORPAY: 'razorpay',
  WEBXPAY: 'webxpay'
};

export const CurrencyTypes = {
  RUPEE: {
    label: 'Rs',
    symbol: '₹'
  }
};

export const PaymentStatus = {
  PAID: 'Paid',
  DUE: 'Due',
  PAY_LATER: 'Pay Later'
};

export const PaymentTypes = {
  APP_APPOINTMENT: 'app_appointment',
  PHP_APPOINTMENT: 'php_appointment',
  PORTAL_APPOINTMENT: 'portal_appointment',
  APP_PRACTICE_SUBSCRIPTION: 'app_practice_subscription',
  PHP_PRACTICE_SUBSCRIPTION: 'php_practice_subscription',
  PORTAL_PRACTICE_SUBSCRIPTION: 'portal_practice_subscription',
  APP_HEALPHA_SETTLEMENT: 'app_healpha_settlement',
  PHP_HEALPHA_SETTLEMENT: 'php_healpha_settlement',
  PORTAL_HEALPHA_SETTLEMENT: 'portal_healpha_settlement',
  PLAN_SUBSCRIPTION: 'plan_subscription',
  RENEWAL_SUBSCRIPTION: 'renewal_subscription'
};

export const PatientCategory = {
  TPA: 'tpa',
  GENERAL: 'general',
  GENERAL_CAPS: 'General',
  CORPORATE: 'corporate',
  COMPANY: 'company',
  VIRTUAL_CLINIC: 'virtual_clinic'
};

export const paymentOptions = [
  'Cash',
  'Cards',
  'DD/Cheque',
  'Net Banking',
  'UPI',
  'Wallet'
];

export const filterDefaultOptions = {
  ALL: 'All'
};

export const discountType = {
  VALUE: 'value',
  VALUES: 'values',
  PERCENTAGE: 'percentage'
};

export const discountBy = {
  ME: 'me',
  EMPLOYER: 'employer',
  EMPLOYEE: 'employee'
};

export const BillingConstants = {
  CONSULTATION: 'Consultation',
  VALUE: 'Value',
  UPDATED: 'updated',
  INSERTED: 'inserted',
  NORMAL: 'normal'
};

export const TemplateConstants = {
  BLOOD_PRESSURE: 'Blood Pressure',
  SYSTOLIC: 'systolic',
  DIASTOLIC: 'diastolic',
  VITALS: 'vitals',
  MOBILE: 'isMobile',
  DATE: 'isDate'
};

export const PlanConstants = {
  FIXED: 'fixed',
  VOLUME: 'volume'
};
export const PracticeSubscriptionTable = [
  {label: '#', value: 'id', width: '4rem'},
  {
    label: 'PLAN_NAME',
    value: 'planName',
    width: '16rem',
    sortable: true
  },
  {
    label: 'MODULE_NAME',
    value: 'moduleName',
    width: '13rem',
    sortable: true
  },
  // {
  //   label: 'NO OF BRANCHES',
  //   value: 'noofbranches',
  //   width: '15rem',
  //   isFilter: true,
  //   filterValue: 'name',
  //   sortable: true
  // },
  {
    label: 'BILLING_RATE',
    value: 'rate',
    width: '10rem',
    sortable: true
  },
  {
    label: 'VALID_TILL',
    value: 'validity',
    width: '10rem'
  },
  {
    label: 'COMMON_ACTION',
    value: 'action',
    width: '7rem'
  },
  {
    label: '',
    value: 'cart',
    width: '8rem'
  }
];

export const ArchivePlanPracticeTable = [
  {label: '#', value: 'id', width: '4rem'},
  {
    label: 'PLAN_NAME',
    value: 'planName',
    width: '16rem',
    sortable: true
  },
  {
    label: 'MODULE_NAME',
    value: 'moduleName',
    width: '13rem',
    sortable: true
  },
  {
    label: 'BILLING_RATE',
    value: 'rate',
    width: '10rem',
    sortable: true
  },
  {
    label: 'VALID_TILL',
    value: 'validity',
    width: '10rem'
  }
];

export const PersonSubscriptionTable = [
  {label: '#', value: 'id', width: '4rem'},
  {
    label: 'PLAN_NAME',
    value: 'planName',
    width: '12rem',
    sortable: true
  },
  {
    label: 'MODULE_NAME',
    value: 'moduleName',
    width: '12rem',
    sortable: true
  },
  {
    label: 'BILLING_RATE',
    value: 'rate',
    width: '8rem',
    sortable: true
  },
  {
    label: 'VALID_TILL',
    value: 'validity',
    width: '10rem'
  },
  {
    label: 'CREDIT',
    value: 'credits',
    width: '8rem',
    isFilter: true,
    filterValue: 'name',
    sortable: true
  },
  {
    label: 'COMMON_ACTION',
    value: 'action',
    width: '8rem'
  },
  {
    label: '',
    value: 'cart',
    width: '6rem'
  }
];

export const ArchivePlanPersonTable = [
  {label: '#', value: 'id', width: '4rem'},
  {
    label: 'PLAN_NAME',
    value: 'planName',
    width: '12rem',
    sortable: true
  },
  {
    label: 'MODULE_NAME',
    value: 'moduleName',
    width: '12rem',
    sortable: true
  },
  {
    label: 'BILLING_RATE',
    value: 'rate',
    width: '8rem',
    sortable: true
  },
  {
    label: 'VALID_TILL',
    value: 'validity',
    width: '10rem'
  },
  {
    label: 'CREDIT',
    value: 'credits',
    width: '8rem',
    isFilter: true,
    filterValue: 'name',
    sortable: true
  }
];
export const CreditSubscriptionTable = [
  {label: '#', value: 'id', width: '4rem'},
  {
    label: 'CATEGORY',
    value: 'type',
    width: '12rem',
    sortable: true
  },
  {
    label: 'PLAN_NAME',
    value: 'name',
    width: '12rem',
    sortable: true
  },
  {
    label: 'BILLING_RATE',
    value: 'rate',
    width: '10rem',
    sortable: true
  },
  {
    label: 'VALID_TILL',
    value: 'validity',
    width: '10rem'
  },
  {
    label: 'CREDIT',
    value: 'credits',
    width: '8rem',
    isFilter: true,
    filterValue: 'name',
    sortable: true
  },
  {
    label: 'COMMON_ACTION',
    value: 'action',
    width: '8rem'
  },
  {
    label: '',
    value: 'cart',
    width: '6rem'
  }
];

export const ArchivePlanCreditTable = [
  {label: '#', value: 'id', width: '4rem'},
  {
    label: 'CATEGORY',
    value: 'type',
    width: '12rem',
    sortable: true
  },
  {
    label: 'PLAN_NAME',
    value: 'name',
    width: '12rem',
    sortable: true
  },
  {
    label: 'BILLING_RATE',
    value: 'rate',
    width: '10rem',
    sortable: true
  },
  {
    label: 'VALID_TILL',
    value: 'validity',
    width: '10rem'
  },
  {
    label: 'CREDIT',
    value: 'credits',
    width: '8rem',
    isFilter: true,
    filterValue: 'name',
    sortable: true
  }
];

export const PracticePlanTable = [
  {label: '#', value: 'series', width: '4rem'},
  {
    label: 'PLAN_NAME',
    value: 'planName',
    width: '10rem',
    isFilter: true,
    filterValue: 'name',
    sortable: true
  },
  {
    label: 'SALES_NAME',
    value: 'salesName',
    width: '11rem',
    isFilter: true,
    filterValue: 'sales_name',
    sortable: true
  },
  {
    label: 'PLAN_DURATION',
    value: 'validity',
    width: '7rem',
    isFilter: true,
    filterValue: 'validity',
    sortable: true
  },
  {
    label: 'PLAN_RATE',
    value: 'rate',
    width: '5rem',
    isFilter: true,
    filterValue: 'rate'
  },
  {label: 'START_DATE', value: 'from_date', width: '7rem'},
  {label: 'END_DATE', value: 'to_date', width: '7rem'},
  {label: 'STATUS', value: 'status', width: '5.2rem'},
  {label: 'DEFAULT', value: 'default_plan', width: '5.5rem'},
  {label: 'TEMPLATE_ACTION', value: 'active', width: '6.0rem'},
  {label: 'COMMON_DATE', onlyFilter: true, isFilter: true, filterValue: 'date'}
];
export const CreditPlanTable = [
  {label: '#', value: 'id', width: '3.5rem'},
  {
    label: 'MODULE_NAME',
    value: 'module_name',
    width: '9.3rem',
    isFilter: true,
    sortable: true
  },
  {
    label: 'PLAN_NAME',
    value: 'planName',
    width: '8.3rem',
    isFilter: true,
    sortable: true
  },
  {
    label: 'SALES_NAME',
    value: 'name_id',
    width: '8.5rem',
    isFilter: true,
    sortable: true
  },
  {
    label: 'DURATION',
    value: 'validity',
    width: '6.8rem',
    isFilter: true,
    sortable: true
  },
  {label: 'RATE', value: 'rate', width: '4.5rem', isFilter: true},
  {label: 'VALIDITY', value: 'Validity', width: '18%', isFilter: true},
  {label: 'STATUS', value: 'status', width: '4.9rem', isFilter: true},
  {label: 'Default', value: 'default_plan', width: '5.3rem', isFilter: true},
  {label: 'COMMON_ACTION', value: 'active', width: '5.6rem'}
  //  {label:"",value:"Delete",width: "5rem"}
];

export const TemplateComponentsList = [
  {
    label: 'TEMPLATE_SINGLE_CHOICE',
    value: 'singleChoice'
  },
  {
    label: 'TEMPLATE_MULTI_CHOICE',
    value: 'multiChoice'
  },
  {
    label: 'TEMPLATE_SIMPLE_ANSWER',
    value: 'simpleQuestion'
  },
  {
    label: 'TEMPLATE_YES_NO',
    value: 'yesNo'
  },
  {
    label: 'TEMPLATE_HEADING',
    value: 'heading'
  },
  {
    label: 'TEMPLATE_NOTES',
    value: 'notes'
  },
  {
    label: 'TEMPLATE_TABLE',
    value: 'table'
  },
  {
    label: 'TEMPLATE_SLIDER',
    value: 'slider'
  }
];

export const ExceptWhitelablingUrls = [
  // 'core',
  // 'practice',
  'app',
  'op',
  'school',
  'corporate',
  'virtual',
  'haj',
  'pharmacy',
  'lab'
];
export const ExceptWhitelablingWithSession = [
  'core',
  'practice',
  'app',
  'op',
  'school',
  'corporate',
  'virtual',
  'haj',
  'pharmacy',
  'lab'
];
export const InvoicePhpRoutes = {
  PRACTICE_SUB_URL: 'practice_sub',
  INVOICE_HISTORY_LIST_URL: 'invoice_history_list'
};

export const DeviceString = 'Device';

export const FCMNotificationTypes = {
  NORMAL: 'Rxconcent Call',
  DISCONNECTED: 'Rxconcent Call Disconnected',
  DECLINED: 'Rxconcent Call Declined'
};

export const DefaultAppointmentStatusCount = 6;

export const lankanDomain = 'lk';

export const NewScreenUrls = [
  NavRoutes.PUBLIC.MULTI_LANGUAGE,
  NavRoutes.PUBLIC.APPOINTMENTS,
  NavRoutes.PUBLIC.APPOINTMENT,
  NavRoutes.PUBLIC.VIEW_PATIENT_HISTORY,
  NavRoutes.PUBLIC.OBSERVATIONS,
  NavRoutes.PUBLIC.ALL_TEMPLATES,
  NavRoutes.PUBLIC.SYMPTOMPS_TEMPLATE,
  NavRoutes.PUBLIC.CUSTOM_TEMPLATES,
  NavRoutes.PUBLIC.PREVIEW_TEMPLATE,
  NavRoutes.PUBLIC.PLAN_TEMPLATES,
  NavRoutes.PUBLIC.OBSERVATIONAL_TEMPLATE,
  NavRoutes.PUBLIC.NEW_TEMPLATE,
  NavRoutes.PUBLIC.ALL_MICRO_TEMPLATE,
  NavRoutes.PUBLIC.APPOINTMENT,
  NavRoutes.PUBLIC.BILLING.BILLINGHOMEPAGE,
  NavRoutes.PUBLIC.BILLING.BILLING_PATIENT_SCREEN,
  NavRoutes.PUBLIC.VIRTUAL_CLINIC_DASHBOARD,
  NavRoutes.PUBLIC.NURSE_DASHBOARD,
  NavRoutes.PUBLIC.ACCESS_CONTROL.LIST_OF_MODULES,
  NavRoutes.PUBLIC.ACCESS_CONTROL.AGREEMENT,
  NavRoutes.PUBLIC.ACCESS_CONTROL.VIEW_AGREEMENTS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.ADD_AGREEMENT,
  NavRoutes.PUBLIC.ACCESS_CONTROL.ASSIGN_SCREEN_TO_MODULE,
  NavRoutes.PUBLIC.ACCESS_CONTROL.USER_ROLES,
  NavRoutes.PUBLIC.ACCESS_CONTROL.PRACTICE_PLANS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.BRANCH_PLANS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.BRANCH_SELF_PLANS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.CART_COMPONENT,
  NavRoutes.PUBLIC.ACCESS_CONTROL.PRACTICE_SELECTION,
  NavRoutes.PUBLIC.ACCESS_CONTROL.LIST_OF_SCREENS_WITH_MODULES,
  NavRoutes.PUBLIC.ACCESS_CONTROL.NEW_USER,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MENU_STRUCTURE,
  NavRoutes.PUBLIC.ACCESS_CONTROL.ADD_NEW_ROLES,
  NavRoutes.PUBLIC.ACCESS_CONTROL.EDIT_NEW_ROLES,
  NavRoutes.PUBLIC.ACCESS_CONTROL.NEW_SMS_PLAN_COMPONENT,
  NavRoutes.PUBLIC.ACCESS_CONTROL.SMS_CREDIT_COMPONENT,
  NavRoutes.PUBLIC.ACCESS_CONTROL.SMS_TABS_COMPONENT,
  NavRoutes.PUBLIC.ACCESS_CONTROL.CREDIT_PLAN_BY_BRANCH,
  NavRoutes.PUBLIC.ACCESS_CONTROL.ALL_INVOICES_COMPONENT,
  NavRoutes.PUBLIC.ACCESS_CONTROL.ONBOARD_TABS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.CREDIT_PLAN_BY_BRANCH,
  NavRoutes.PUBLIC.ACCESS_CONTROL.HEALPHA_CHARGES,
  NavRoutes.PUBLIC.MANAGE.EDIT_USER,
  NavRoutes.PUBLIC.PERSON_WEB_TIMELINE,
  NavRoutes.PUBLIC.ACCESS_CONTROL.PRACTICE_LIST,
  NavRoutes.PUBLIC.ACCESS_CONTROL.ADD_PRACTICE,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.BASE,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.ADD_DETAILS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.ADD_CHILD_HEALTH,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.ADD_CORPORATE_DETAILS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS
    .ADD_VIRTUAL_CLINIC_DETAILS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.ADD_LAB_DETAILS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE.TABS.ADD_PHARMACY_DETAILS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.BASE,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.ADD_DETAIL,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.CHILD_HEALTH,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.CORPORATE_DETAILS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.VIRTUAL_CLINIC_DETAILS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_PRACTICE_PARAMETER,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_CHILDHEALTH_PARAMETER,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_CORPORATE_PARAMETER,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_VIRTUAL_CLINIC_PARAMETER,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.LAB_DETAILS,
  NavRoutes.PUBLIC.ACCESS_CONTROL.MANAGE_BRANCH.PHARMACY_DETAILS
];

export const AppointmentTypes = {
  online: 'online',
  offline: 'offline'
};

export const AppointmentTypelist = {
  review: 'Review'
};

export const PlanType = {
  practice: 'practice',
  branch: 'branch'
};

export const patientUnavailableType = 'patient_unavailable';
export const patientUnavailableCancelled = 'is_appointment_cancelled';

export const ObservationList = [
  {
    id: 1,
    name: 'Vitals'
  },
  {
    id: 2,
    name: 'Subjective'
  },
  {
    id: 3,
    name: 'Objective'
  },
  {
    id: 4,
    name: 'Assessment'
  }
];

export const ConnectButtonAppointmentTypes = [
  'confirm',
  'checkedin',
  'consulting',
  'triage',
  'rescheduled'
];
export const VitalButtonAppointmentTypes = [
  'confirm',
  'checkedin',
  'triage',
  'rescheduled'
];
export const AppointmentStatusTypes: any = {
  BOOKED: 'Booked',
  IN_PROCESS: 'In Process',
  CLOSED: 'Closed',
  CANCELLED: 'Cancelled',
  CONFIRM: 'Confirm',
  RESCHEDULED: 'Rescheduled',
  PAID: 'Paid',
  CONSULTING: 'Consulting',
  TRIAGE: 'Triage',
  CHECKED_IN: 'Checkedin'
};

export const DeviceTypes = {
  WEB: 'web',
  APP: 'app'
};

export const ConsultNowButtonShowWhen = [
  'confirm',
  'checkedin',
  'consulting',
  're consulting',
  'triage',
  'rescheduled'
];

export const ConsultNowButtonForBooked = [
  'booked',
  'confirm',
  'checkedin',
  'consulting',
  're consulting',
  'triage',
  'rescheduled'
];
export const nurseDashboardServices = ['completed', 'triage', 'checkedin'];
export const EnterVitalsButton = [
  'confirm',
  'checkedin',
  'consulting',
  'rescheduled'
];

export const HealphaAdminBranchDetails = {
  ID: 'Healpha-1',
  NAME: 'Healpha Admin',
  PRACTICE_ID: 'Healpha',
  IMAGE: '\ndata/static/upload/images/Screenshotfrom2022-06-0115-37-03.png'
};

export const DashboardStepsList = [
  {
    label: 'Registration',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.DASHBOARD} `
  },
  {
    label: 'Subscription',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.BASE}/${NavRoutes.PUBLIC.USER.SUBSCRIPTION.MY_SUBSCRIPTIONS}`
  },
  {
    label: 'Setup',
    url: ''
  }
];

export const RegistrationStepsList = [
  {
    label: 'Profile',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PROFILE}`
  },
  {
    label: 'Practice',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}`
  },
  {
    label: 'Branches',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}`
  },
  {
    label: 'Doctor',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.DOCTOR}`
  },
  {
    label: 'Review',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.DASHBOARD}`
  }
];

export const RegistrationStepsListOp = [
  {
    label: 'Profile',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PROFILE}`
  },
  {
    label: 'Clinic',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}`
  },
  {
    label: 'Branches',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}`
  },
  {
    label: 'Doctor',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.DOCTOR}`
  },
  {
    label: 'Review',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.DASHBOARD}`
  }
];

export const RegistrationStepsListSchool = [
  {
    label: 'Profile',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PROFILE}`
  },
  {
    label: 'School',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}`
  },
  {
    label: 'Branches',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}`
  },
  {
    label: 'Review',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.DASHBOARD}`
  }
];

export const RegistrationStepsListCorporate = [
  {
    label: 'Profile',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PROFILE}`
  },
  {
    label: 'company',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}`
  },
  {
    label: 'Branches',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}`
  },
  {
    label: 'Review',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.DASHBOARD}`
  }
];

export const RegistrationStepsListVirtualClinic = [
  {
    label: 'Profile',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PROFILE}`
  },
  {
    label: 'Virtual Clinic',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}`
  },
  {
    label: 'Branches',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}`
  },
  {
    label: 'Review',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.DASHBOARD}`
  }
];

export const RegistrationStepsListLab = [
  {
    label: 'Profile',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PROFILE}`
  },
  {
    label: 'Lab',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}`
  },
  {
    label: 'Branches',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}`
  },
  {
    label: 'Review',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.DASHBOARD}`
  }
];
export const RegistrationStepsListPharmacy = [
  {
    label: 'Profile',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PROFILE}`
  },
  {
    label: 'Pharmacy',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE.BASE}`
  },
  {
    label: 'Branches',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.BRANCHES}`
  },
  {
    label: 'Review',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.DASHBOARD}`
  }
];
export const PaymentStepsList = [
  {
    label: 'Review',
    url: ''
  },
  {
    label: 'Invoice',
    url: ''
  },
  {
    label: 'Payment',
    url: ''
  }
];

export const PracticeStepsList = [
  {
    label: 'Practice Parameters',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.PARAMETERS}`
  },
  {
    label: 'Service Group Master',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.SERVICE_GROUP}`
  },
  {
    label: 'Service Master',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.SERVICE_MASTER}`
  },
  {
    label: 'Users',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.USERS}`
  },
  {
    label: 'Patients',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_SETUP.PATIENT}`
  }
];

export const ChildHealthStepsList = [
  {
    label: 'Parameters',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.SCHOOL_PARAMETERS}`
  },
  {
    label: 'ChildHealth Users',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.USERS}`
  },
  {
    label: 'Classes',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.ADD_CLASSES_DETAILS}`
  },
  {
    label: 'Student',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CHILD_HEALTH.ADD_STUDENTS_DETAILS}`
  }
];

export const CorporateStepsList = [
  {
    label: 'Corporate Parameters',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.CORPORATE_PARAMETERS}`
  },
  {
    label: 'Corporate Users',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.USERS}`
  },
  {
    label: 'Department',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.DEPARTMENT}`
  },
  {
    label: 'Employees',
    url: `/${BASE_ROUTE_PORTAL}/${NavRoutes.PUBLIC.SELF_SERVICE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.BASE}/${NavRoutes.PUBLIC.USER.PRACTICE_CORPORATE.ADD_EMPLOYEE_DETAILS}`
  }
];

export const personDropDownOptions = [
  {
    label: 'Doctor',
    value: 'Doctor'
  },
  {
    label: 'Specialization',
    value: 'Specialization'
  },
  {
    label: 'Hospital',
    value: 'Hospital'
  }
];

export const ImageUploadTypes = {
  PROFILE_PIC: 'profile_pic',
  USER_SIGN: 'user_sign',
  MEDICAL_CERTIFICATE: 'medical_certificate',
  PRACTICE_LOGO: 'practice_logo',
  BRANCH_LOGO: 'branch_logo',
  PRACTICE_IMAGES: 'practice_images'
};

export const AddressTypes = {
  STREET_NUMBER: 'street_number',
  STREET_ADDRESS: 'street_address',
  ROUTE: 'route',
  ADMINISTRATIVE_AREA_LEVEL_1: 'administrative_area_level_1',
  LOCALITY: 'locality',
  POSTAL_CODE: 'postal_code',
  COUNTRY: 'country'
};

export const SubscriptionPlanTypes = {
  PRACTICE: 'practice',
  PERSON: 'person',
  ENCOUNTER: 'encounter',
  SMS: 'sms',
  WHATSAPP: 'whatsapp',
  EMAIL: 'email',
  PERSON_CREDITS: 'person_credits',
  ENCOUNTER_CREDITS: 'encounter_credits',
  SMS_CREDITS: 'sms_credits',
  EMAIL_CREDITS: 'email_credits',
  WHATSAPP_CREDITS: 'whatsapp_credits'
};

export const moduleNameIds = {
  VIRTUAL_CLINIC: 'virtual_clinic',
  CLOUD_CLINIC: 'cloud_clinic'
};

export const SelfServiceSingleUrlFlow = {
  OP: 'op',
  ALL: 'all',
  LAB: 'lab',
  PHARMACY: 'pharmacy',
  SCHOOL: 'school',
  CORPORATE: 'corporate',
  VIRTUAL_CLINIC: 'virtual_clinic',
  HAJ: 'haj',
  CHILD_HEALTH: 'child_health'
};

export const appointmentsTypes = {
  SPECIALIZATION: 'Specialization',
  HOSPITAL: 'Hospital',
  TELEMEDICINE: 'telemedicine',
  HOMECARE: 'homecare',
  DOCTOR: 'Doctor'
};

export const AccessControlTabs = {
  USERS: 'users',
  ACCESS_CONTROL: 'access control',
  ROLES: 'roles'
};

export const SelfserviceWorkFlowData = {
  PROFILE: 'profile',
  PRACTICE_ABOUT: 'practice_about',
  BRANCH_ABOUT: 'branch_about',
  PRACTICE_FEATURE: 'practice_feature',
  bRANCH_FEATURE: 'branch_feature',
  DOCTOR: 'doctor',
  PRACTICE_PAYMENT: 'practice_payment',
  PRACTICE_SUBSCRIPTION: 'practice_subscription',
  PARAMETERS: 'parameters',
  SERVICE_GROUP: 'servicegroup',
  SERVICE_MASTER: 'servicemaster',
  USERS: 'users',
  ADD_PATIENT_DETAILS: 'add_patient_details',
  CORPORATE_PARAMETERS: 'corporate_parameters',
  CORPORATE_USER: 'corporate_user',
  ADD_CORPORATE_DEPARTMENT: 'add_corporate_department',
  ADD_EMPLOYEE_DETAILS: 'add_employee_details',
  SCHOOL_PARAMETERS: 'school_parameters',
  USERS_DETAILS: 'users_details ',
  ADD_CLASSES_DETAILS: 'add_classes_details',
  ADD_STUDENTS_DETAILS: 'add_students_details',
  COMPLETED: 'completed',
  PARTIALLY_COMPLETED: 'partially completed',
  PENDING: 'pending'
};

export const plans = {
  PERSON_CREDIT_PLAN: 'Person Credit Plan',
  ENCOUNTER_CREDITS_PLAN: 'Encounter Credit Plan'
};

export const timeInterval = [
  {
    label: '7 Days',
    value: '7-days'
  },
  {
    label: '14 Days',
    value: '14-days'
  },
  {
    label: '1 Month',
    value: '30-days'
  },
  {
    label: '2 Months',
    value: '60-days'
  },
  {
    label: '3 Months',
    value: '90-days'
  },
  {
    label: '4 Months',
    value: '120-days'
  },
  {
    label: '5 Months',
    value: '150-days'
  },
  {
    label: '6 Months',
    value: '180-days'
  }
];

export const moduleNames = {
  CLOUD_CLINIC: 'Cloud Clinic',
  CHILD_HEALTH: 'Child Health',
  CORPORATE: 'Corporate'
};

export const SELF_SERVICE_DEFAULT_SCREEN_NUMBER = 'ss';
