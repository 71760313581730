import React from 'react';
import {MenuItem, OutlinedInput, Select, TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import {
  DEFAULT_WHITE_COLOR,
  BORDER_TERTIARY_COLOR,
  GET_8_PIXEL_BOX
} from 'src/style/variable';

interface FormCombinationFieldProps {
  dropDownName: string;
  textFieldName: any;
  callback?: any;
  textFieldCallBack?: any;
  dropdownValue: any;
  textFieldValue: any;
  placeholder?: any;
  dropDownOptions: any;
  readOnly?: boolean;
  disabled?: boolean;
  id?: string;
  componentSalutationDropDownProps?: any;
  componentTextFieldProps?: any;
  className?: any;
}

const combinedTextFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  border: `1px solid ${BORDER_TERTIARY_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.5),
  opacity: 1,
  fontSize: '1.2rem',
  borderLeftWidth: 0,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0
};

const FormCombinationField = (
  props: FormCombinationFieldProps
): React.ReactElement => {
  const {
    dropDownName,
    dropdownValue,
    callback,
    textFieldCallBack,
    dropDownOptions,
    textFieldName,
    placeholder,
    textFieldValue,
    readOnly,
    disabled,
    id,
    componentSalutationDropDownProps,
    componentTextFieldProps,
    className
  } = props;

  const {t} = useTranslation();

  const salutationDropDownProps = {
    height: className ? '2.7rem' : '3.9rem',
    border: `1px solid ${BORDER_TERTIARY_COLOR}`,
    width: 'auto',
    borderRightWidth: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  };

  return (
    <div style={{display: 'flex'}}>
      <Select
        sx={componentSalutationDropDownProps || salutationDropDownProps}
        name={dropDownName}
        id={dropDownName}
        value={dropdownValue || ''}
        onChange={callback}
        input={<OutlinedInput />}
        inputProps={{
          readOnly: readOnly || false,
          disabled: disabled || false
        }}>
        {dropDownOptions.map((item: any, index: number) => (
          <MenuItem key={index} value={item.value}>
            {t(item?.label)}
          </MenuItem>
        ))}
      </Select>

      <TextField
        autoFocus
        fullWidth
        sx={componentTextFieldProps || combinedTextFieldProps}
        className={`form-combination-field-textfield-input ${
          className && className
        }`}
        placeholder={placeholder}
        variant="outlined"
        id={id}
        name={textFieldName}
        size="medium"
        onChange={textFieldCallBack}
        value={textFieldValue || ''}
        InputProps={{
          readOnly: readOnly || false,
          disabled: disabled || false
        }}
      />
    </div>
  );
};

export default FormCombinationField;
