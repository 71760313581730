/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Grid, Typography, Link, Chip, makeStyles} from '@material-ui/core';
import ClampLines from 'react-clamp-lines';

import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';
import NavRoutes from 'src/constants/route/NavRoutes';
import CommonUtils from 'src/utils/CommonUtils';
import DoctorForumsIcon from '@components/system/MyIcons/DoctorForumsIcon';
import ForumsReplyIcon from '@components/system/MyIcons/ForumsReplyIcon';
import './ForumsQuestionCardComponent.scss';
import {
  deleteForumQuestionAndReply,
  getQuestionRepliesCount
} from '@shared/services/PublicService';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles({
  notDeleted: {
    backgroundColor: '#dc4141',
    color: 'white',
    width: '70%',
    fontSize: '.625rem'
  },
  Deleted: {
    backgroundColor: '#4141dc',
    color: 'white',
    width: '80%',
    fontSize: '.625rem'
  }
});

const ForumsQuestionCardComponent = (props: any): React.ReactElement => {
  const styles = useStyles();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const [replyCount, setReplyCount]: any = useState();

  const getRepliesCount = async () => {
    if (props?.question) {
      const response = await getQuestionRepliesCount(props?.question?.id);
      if (response?.data) {
        setReplyCount(response.data.replies_count);
      }
    }
  };

  const init = () => {
    if (props?.question) {
      getRepliesCount();
    }
  };

  useEffect(init, []);

  const gotoForumReplies = () => {
    if (props?.isFrom == 'dashboard')
      history.push(
        `/${BASE_ROUTE_PORTAL}/user/${
          NavRoutes.PUBLIC.REPLIES_QUESTION_SHARED
        }/${props?.question?.id}?from=${
          props?.spam == 'admin' && props?.spam
        }&to=${props?.spam == 'admin' && props?.to}`
      );
    else
      history.push(
        `/${BASE_ROUTE_PORTAL}/home/${NavRoutes.PUBLIC.REPLIES}/${props?.question?.id}`
      );
  };

  const handleChipsSelect = async (
    id: any,
    type: any,
    count: any,
    isDeleted: any
  ) => {
    deleteForumQuestionAndReply(type, id, {
      status: isDeleted ? 0 : 1
    })
      .then(res => {
        if (res?.status_type == 'Success') props?.getAllForums('', '');
      })
      .catch(err => {
        enqueueSnackbar(
          err.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  return (
    <Grid className="forums-question-component-wrapper">
      {props?.question && (
        <Grid
          className={[
            'forums-question-component-container',
            props?.isFromHospital && 'secondary-color'
          ].join(' ')}>
          <Grid className="section-1">
            <Link
              component="button"
              variant="body2"
              className={[
                'ques-title',
                !props?.fullWidth && 'ques-title-2'
              ].join(' ')}
              underline="hover"
              onClick={gotoForumReplies}>
              {props?.question?.question}
            </Link>
          </Grid>
          <Grid container xs={12} className="section-2" alignItems="center">
            <Grid container item xs={10}>
              <Grid item className="ques-by">
                {t('BY')}
              </Grid>
              <Grid item className="ques-name">
                {`${props?.question?.user?.first_name} ${props?.question?.user?.last_name}, `}
              </Grid>
              <Grid item className="ques-date">
                {CommonUtils.getDateFormat(props?.question?.created_at)}
              </Grid>
            </Grid>
            {props?.spam == 'admin' && (
              <Grid container item xs={2} className="spam-card">
                <Chip
                  className={
                    props?.question?.deleted
                      ? styles.notDeleted
                      : styles.Deleted
                  }
                  label={
                    props?.question?.deleted
                      ? t('MARKED_AS_SPAM')
                      : t('MARK_AS_SPAM')
                  }
                  size="small"
                  variant="filled"
                  onClick={() =>
                    handleChipsSelect(
                      props?.question?.id,
                      'question',
                      replyCount,
                      props?.question?.deleted
                    )
                  }
                />
              </Grid>
            )}
          </Grid>
          {props?.question?.replies?.[0]?.reply && (
            <Grid className="section-3">
              <Typography
                className={[
                  'ques-description',
                  !props?.fullWidth && 'ques-description-2'
                ].join(' ')}>
                <ClampLines
                  text={props?.question?.replies?.[0]?.reply}
                  id={props?.question?.replies[0]?.id}
                  lines={props?.fullWidth ? 3 : 2}
                  moreText={t('VIEW_MORE')}
                  lessText={t('VIEW_LESS')}
                  buttons={true}
                />
              </Typography>
            </Grid>
          )}
          <Grid container className="section-2" spacing={1} alignItems="center">
            <Grid item xs={props?.fullWidth ? 6 : 12}>
              {props?.question?.replies?.length > 0 ? (
                <Grid container xs={12} alignItems="center">
                  {props?.question?.replies[0]?.user?.role === 'Doctor' && (
                    <Grid item>
                      <DoctorForumsIcon className="left-img-sec" />
                    </Grid>
                  )}
                  <Grid container item xs={9}>
                    <Grid
                      item
                      className={[
                        'ques-name',
                        !props?.fullWidth && 'ques-name-2'
                      ].join(' ')}>
                      {`${props?.question?.replies[0]?.user?.first_name} ${props?.question?.replies[0]?.user?.last_name},`}
                    </Grid>
                    <Grid
                      item
                      className={[
                        'ques-date',
                        !props?.fullWidth && 'ques-date-2'
                      ].join(' ')}>
                      {CommonUtils.getDateFormat(
                        props?.question?.replies[0]?.created_at
                      )}
                    </Grid>
                  </Grid>
                  {props?.spam == 'admin' && (
                    <Grid container item xs={3} className="spam-card">
                      <Chip
                        className={
                          props?.question?.replies[0]?.deleted
                            ? styles.notDeleted
                            : styles.Deleted
                        }
                        label={
                          props?.question?.replies[0]?.deleted
                            ? t('MARKED_AS_SPAM')
                            : t('MARK_AS_SPAM')
                        }
                        size="small"
                        variant="filled"
                        onClick={() =>
                          handleChipsSelect(
                            props?.question?.replies[0]?.id,
                            'reply',
                            replyCount,
                            props?.question?.replies[0]?.deleted
                          )
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <p>{t('NO_REPLY_YET')}</p>
              )}
            </Grid>
            {props?.fullWidth && (
              <Grid item xs={6}>
                <Grid
                  container
                  className="reply-section"
                  onClick={gotoForumReplies}
                  alignItems="center">
                  <Grid item>
                    <ForumsReplyIcon className="reply-img-sec" />
                  </Grid>
                  <Grid item className="reply-txt">
                    {replyCount ? replyCount : 0}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default ForumsQuestionCardComponent;
