/* eslint-disable camelcase */
import moment from 'moment';

export const getRespiratoryRateCalculation = (
  dob: any,
  created_date: any,
  value: any
): any => {
  try {
    // const dob_date = moment(moment(dob).format('YYYY-MM-DD'));
    // const months = dob_date.diff(created_date, 'months');
    // const month_diff = months <= 0 ? 0 : months;

    const createDate = moment(moment(created_date).format('YYYY-MM-DD'));
    const dob_date = moment(moment(dob).format('YYYY-MM-DD'));
    const months = createDate.diff(dob_date, 'months');
    const month_diff = months <= 0 ? 0 : months;

    if (month_diff < 1) {
      if (value >= 30 && value <= 40) {
        return 1;
      } else {
        return 0;
      }
    } else if (month_diff <= 6) {
      if (value >= 25 && value <= 40) {
        return 1;
      } else {
        return 0;
      }
    } else if (month_diff <= 36) {
      if (value >= 20 && value <= 30) {
        return 1;
      } else {
        return 0;
      }
    } else if (month_diff <= 72) {
      if (value >= 18 && value <= 25) {
        return 1;
      } else {
        return 0;
      }
    } else if (month_diff <= 120) {
      if (value >= 17 && value <= 23) {
        return 1;
      } else {
        return 0;
      }
    } else if (month_diff <= 780) {
      if (value >= 12 && value <= 18) {
        return 1;
      } else {
        return 0;
      }
    } else if (month_diff <= 960) {
      if (value >= 12 && value <= 28) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (value >= 10 && value <= 30) {
        return 1;
      } else {
        return 0;
      }
    }
  } catch (e) {
    throw new Error((e as any)?.message);
  }
};
