import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {useSnackbar} from 'notistack';
import {Redirect, Switch, useHistory, useParams} from 'react-router-dom';
import {LinearProgress, Grid} from '@material-ui/core';

import {getDoctorById} from '@shared/services/PublicService';
import {
  _setDoctorId,
  _setDoctorInfo
} from '@shared/store/actions/CommonActions';

import SubTabsComponent from '@components/system/SubTabs/SubTabsComponent';
import NavRoutes from 'src/constants/route/NavRoutes';
import DoctorCardComponent from 'src/modules/shared/DoctorCard/DoctorCardComponent';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import CustomRoute from 'src/constants/route/CustomRoute';
import AppointmentComponent from 'src/modules/shared/Appointment/AppointmentComponent';
import Loader from '@components/framework/loader/Loader';
import './DoctorViewComponent.scss';
import CommonUtils from 'src/utils/CommonUtils';

const DoctorViewComponent = (props: any): React.ReactElement => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const history = useHistory();
  const params: any = useParams();
  const [doctorInfo, setDoctorInfo]: any = useState({});
  const [currentRoute, setCurrentRoute] = useState<string>('');
  const [tabs, setTabs]: any = useState([]);
  const [isLoading, setIsLoading]: any = useState(false);
  const [specializationsObj, setSpecializationsObj]: any = useState({});
  const doctorRoutes = {
    info: {
      id: NavRoutes.PUBLIC.DOCTOR.TABS.INFO,
      label: t('INFO'),
      route: NavRoutes.PUBLIC.DOCTOR.TABS.INFO
    },
    experience: {
      id: NavRoutes.PUBLIC.DOCTOR.TABS.EDUCATION_EXPERIENCE,
      label: t('EDUCATION_EXPERIENCE'),
      route: NavRoutes.PUBLIC.DOCTOR.TABS.EDUCATION_EXPERIENCE
    },
    awards: {
      id: NavRoutes.PUBLIC.DOCTOR.TABS.AWARDS_RECOGNITIONS,
      label: t('AWARDS_RECOGNITIONS'),
      route: NavRoutes.PUBLIC.DOCTOR.TABS.AWARDS_RECOGNITIONS
    },
    publications: {
      id: NavRoutes.PUBLIC.DOCTOR.TABS.PUBLICATIONS,
      label: t('PUBLICATIONS') + ' & ' + t('RESEARCH'),
      route: NavRoutes.PUBLIC.DOCTOR.TABS.PUBLICATIONS
    },
    membership: {
      id: NavRoutes.PUBLIC.DOCTOR.TABS.MEMBERSHIP,
      label: t('MEMBERSHIP'),
      route: NavRoutes.PUBLIC.DOCTOR.TABS.MEMBERSHIP
    },
    feedback: {
      id: NavRoutes.PUBLIC.DOCTOR.TABS.FEEDBACK,
      label: t('FEEDBACK'),
      route: NavRoutes.PUBLIC.DOCTOR.TABS.FEEDBACK
    }
  };

  const getDoctor = async () => {
    try {
      setIsLoading(true);
      const localTabs = [];
      // eslint-disable-next-line
      const doctorId = params?.doctor_id;
      const branchId = new URLSearchParams(props.location.search).get(
        'branch_id'
      );
      const response = await getDoctorById(doctorId, branchId);
      const doctor = response?.data;
      doctor.branches = response?.data?.branches.filter(
        (item: any) => item.branch
      );
      setSpecializationsObj(doctor?.specializations);
      // eslint-disable-next-line
      doctor.appointment_types = CommonUtils.makeAppointmentTypes(
        // eslint-disable-next-line
        doctor?.price_list
      );
      localTabs.push(doctorRoutes.info);
      localTabs.push(doctorRoutes.experience);
      if (
        doctor?.awards &&
        Array.isArray(doctor.awards) &&
        doctor.awards.length > 0
      ) {
        localTabs.push(doctorRoutes.awards);
      }
      if (
        doctor?.publications &&
        Array.isArray(doctor.publications) &&
        doctor.publications.length > 0
      ) {
        localTabs.push(doctorRoutes.publications);
      }
      if (
        doctor?.memberships &&
        Array.isArray(doctor.memberships) &&
        doctor.memberships.length > 0
      ) {
        localTabs.push(doctorRoutes.membership);
      }
      if (doctor?.feedback) {
        localTabs.push(doctorRoutes.feedback);
      }
      setTabs(localTabs);
      setDoctorInfo(doctor);
      props._setDoctorInfo(doctor);
      props._setDoctorId({
        doctorId: doctorId
      });
      setIsLoading(false);
    } catch (err) {
      enqueueSnackbar(
        (err as any)?.message || t('ERROR_ENCOUNTERED'),
        SnackBarConfig('e')
      );
      setIsLoading(false);
    }
  };

  const init = () => {
    getDoctor();
  };

  // eslint-disable-next-line
  useEffect(init, [props?.doctorId, params?.doctor_id]);

  // Listener for route change
  const updateStepperBasedOnLocation = () => {
    const currentRouteArray = location.pathname.split('/');
    const currentRoutePath = currentRouteArray[currentRouteArray.length - 1];
    setCurrentRoute(currentRoutePath);
  };

  useEffect(updateStepperBasedOnLocation, [location]);

  const getCurrentRoute = (route: string): void => {
    const branchId = new URLSearchParams(props.location.search).get(
      'branch_id'
    );
    let url = route;

    if (branchId) {
      url = `${url}?branch_id=${branchId}`;
    }
    history.push(url);
  };

  const renderSubTabComponent = (): ReactElement => {
    return (
      <SubTabsComponent
        initialRoute={currentRoute || tabs?.[0]?.route}
        routesData={tabs}
        action={getCurrentRoute}
      />
    );
  };

  const renderRoutes = (): React.ReactElement => {
    const {routes, redirectTo, exactFrom} = props;
    const route = routes?.map((r: any, i: any) => {
      return CustomRoute.privateRouting({
        key: i,
        path: r.path,
        exact: r.exact,
        Component: r.component,
        exactFrom: r.exactFrom,
        redirectTo: r.redirectTo,
        routes: r.routes
      });
    });

    return (
      <React.Suspense fallback={<LinearProgress />}>
        <Switch>
          <Redirect from={exactFrom} to={redirectTo} exact />
          {route}
        </Switch>
      </React.Suspense>
    );
  };

  return (
    <Grid className="doctor-view-component-wrapper">
      {isLoading && <Loader />}
      <Grid container spacing={2} className="doctor-view-component-container">
        <Grid item xs={12} md={8} direction="column">
          <Grid className="description-section">
            <DoctorCardComponent
              doctor={doctorInfo}
              specializationsObj={specializationsObj}
              showRightSideSection={false}
            />
          </Grid>

          <Grid className="tabs-section">
            {renderSubTabComponent()}
            <Grid className="body-section">{renderRoutes()}</Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          {<AppointmentComponent doctor={doctorInfo} />}
        </Grid>
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state: any) {
  return {
    doctorId: state.common.doctorId
  };
}

export default connect(mapStateToProps, {
  _setDoctorId,
  _setDoctorInfo
})(DoctorViewComponent);
