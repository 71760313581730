/* eslint-disable camelcase */
import React from 'react';
import {
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {useHistory} from 'react-router-dom';

import {getMaterTemplates} from '@shared/services/TemplateService';

import Icons from '@components/system/MyIcons/index';
import Loader from '@components/framework/loader/Loader';
import NavRoutes from 'src/constants/route/NavRoutes';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import './MasterTemplatesComponent.scss';
import AuthUtils from 'src/utils/AuthUtils';
import CommonUtils from 'src/utils/CommonUtils';

const MasterTemplatesComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [openConnect, setOpenConnect] = React.useState(props?.open);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [offSet, setOffSet]: any = React.useState(0);
  const [countSize, setCountSize]: any = React.useState('');
  const [templateData, setTemplateData]: any = React.useState([]);
  const practiceId = `${props?.branch_id?.split?.('-')[0]},Healpha`;
  const language: any = CommonUtils.getLanguageId().key || 'en';

  const getTemplatesData = (searchText: string, type: any, offset: any) => {
    if (type != 'append') setIsLoading(true);
    getMaterTemplates(
      props?.branch_id,
      practiceId,
      searchText,
      offset,
      language
    )
      .then(res => {
        if (type != 'append') setIsLoading(false);
        setCountSize(res?.data?.count);
        if (type != 'append') setTemplateData(res?.data?.templates);
        else if (type == 'append')
          if (res?.data?.templates?.length != 0)
            setTemplateData([...templateData?.concat(res?.data?.templates)]);
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleScroll = (e: any) => {
    if (offSet < countSize) {
      if (
        Math.ceil(e.target.scrollTop + e.target.clientHeight) >=
        e.target.scrollHeight
      ) {
        setOffSet(offSet + 20);
        getTemplatesData('', 'append', offSet + 20);
      }
    }
  };

  const init = () => {
    getTemplatesData('', '', 0);
  };

  React.useEffect(init, []);

  const handleCloseConnect = () => {
    setOpenConnect(false);

    if (
      props?.closeMasterTemplates &&
      typeof props.closeMasterTemplates === 'function'
    ) {
      props?.closeMasterTemplates();
    }
  };

  const goToObservations = (templateId: any) => {
    AuthUtils.setTemplateNewId(templateId);

    if (props?.isFromHistory) {
      history.push(NavRoutes.PUBLIC.VIEW_PATIENT_HISTORY);
    } else {
      history.push(NavRoutes.PUBLIC.OBSERVATIONS);
    }

    handleCloseConnect();
  };

  const onInputChange = (event: any) => {
    setOffSet(0);
    if (event?.target?.value?.length > 2) {
      getTemplatesData(event.target.value, '', 0);
    } else if (event?.target?.value?.length == 0) {
      getTemplatesData('', '', 0);
    }
  };

  const renderMasterTemplateTitle = (): React.ReactElement => {
    return (
      <Grid item container xs={12} className="template-title-wrapper">
        <Grid item xs={11} className="template-title">
          {t('DOC_DASH_SELECT_TEMPLATE')}
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="end">
          <Icons.CloseIcon
            cursor="pointer"
            onClick={() => handleCloseConnect()}
            className="close-icon"
          />
        </Grid>
      </Grid>
    );
  };

  const renderTemplateNameGrid = (): React.ReactElement => {
    return templateData?.map((item: any) => (
      <Grid key={item?.id} id={'item_' + item?.id}>
        <Grid
          item
          key={item?.id}
          container
          xs={12}
          justifyContent="space-between"
          className="one-template">
          <Grid item xs={8}>
            <Typography
              className="template-name"
              onClick={() => goToObservations(item?.id)}>
              {item?.name}
            </Typography>
          </Grid>
          {/* <Grid item xs={3} className="preview-button-grid">
            <Button className="preview-button">Preview</Button>
          </Grid> */}
        </Grid>
        <Divider />
      </Grid>
    ));
  };

  const renderMasterTemplateContent = (): React.ReactElement => {
    return (
      <Grid item container xs={12} className="template-main-grid">
        <Grid item xs={12} className="search-bar-grid">
          <TextField
            variant="outlined"
            onChange={onInputChange}
            className="search-bar-style"
            placeholder={t('CONSULT_NOW_SEARCH_TEMPLATE_NAME')}
            InputProps={{
              startAdornment: (
                <div>
                  <Icons.SearchIcon
                    color="disabled"
                    className="search-bar-icon"
                  />
                </div>
              ),
              endAdornment: <div>{isLoading && <Loader size={20} />}</div>
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="one-template-grid"
          onScroll={handleScroll}>
          {isLoading ? <Loader size={28} /> : renderTemplateNameGrid()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openConnect}
      className="master-template-dialog-wrapper "
      maxWidth="xs">
      <DialogTitle>{renderMasterTemplateTitle()}</DialogTitle>
      <DialogContent className="content">
        {renderMasterTemplateContent()}
      </DialogContent>
    </Dialog>
  );
};

export default MasterTemplatesComponent;
