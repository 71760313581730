import {useEffect, useRef} from 'react';

export const useDidUpdateEffect = (fn: () => void, deps: Array<any>): void => {
  const initialRef = useRef(false);

  useEffect(() => {
    if (initialRef.current) fn();
    else initialRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
