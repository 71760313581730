import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as TableIconSvg} from '@assets/images/healpha/table.svg';

const TableIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={TableIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default TableIcon;
