import React from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, IconButton, Typography} from '@material-ui/core';

import Icons from '@components/system/MyIcons';
import './SecondaryCommonDialogTitleComponent.scss';

const SecondaryCommonDialogTitleComponent = (
  props: any
): React.ReactElement => {
  const {t} = useTranslation();

  const closePopup = (): any => {
    props?.close();
  };

  return (
    <Grid
      container
      spacing={2}
      className="secondary-common-dialog-title-wrapper">
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Typography
            className={props?.title ? 'person-doctor-header' : 'header-text'}>
            {props?.title ? props?.title : t('POST_DETAILS_TEXT')}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <IconButton
            id="close_popup_id"
            style={{marginTop: '-1rem', marginLeft: '2rem'}}
            onClick={closePopup}>
            <Icons.CloseBlackIcon />
          </IconButton>
        </Grid>
      </Grid>

      {props?.subTitle && (
        <Grid>
          <Typography className="header-text person-doctor-sub-title">
            {props?.subTitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SecondaryCommonDialogTitleComponent;
