import React from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as NegativeDownArrowIconSvg} from '@assets/images/healpha/negativeDownArrow.svg';

const NegativeDownArrowIcon = (props: any): React.ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      style={{width: 'inherit', height: 'inherit'}}
      className={className}
      component={NegativeDownArrowIconSvg}
      viewBox="0 0 15 15"
    />
  );
};

export default NegativeDownArrowIcon;
