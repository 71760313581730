/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react';
import ClosePrimaryIcon from '@assets/images/healpha/ci_close-big.svg';
import PhoneIcon from '@assets/images/healpha/Phone.svg';
import Refresh from '@assets/images/healpha/Refresh.svg';
import Next from '@assets/images/healpha/next.svg';
import Pdf from '@assets/images/healpha/pdf.svg';
import PatientIcon from '@assets/images/healpha/Patient.svg';
import Close from '@assets/images/healpha/Close.svg';
import Email from '@assets/images/healpha/emailIcon.svg';
import {Avatar, Typography, Grid, Button} from '@material-ui/core';
import './AppointmentCard.scss';
import {useTranslation} from 'react-i18next';
import {statusColor} from 'src/constants/statusColors';
import {
  cancelAppointment,
  getPatientCard,
  updateEncStatus
} from '@shared/services/PublicService';
import AuthUtils from 'src/utils/AuthUtils';
import CommonUtils from 'src/utils/CommonUtils';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {useSnackbar} from 'notistack';
import TemplateUtils from 'src/utils/TemplateUtils';
import NavRoutes from 'src/constants/route/NavRoutes';
import {useHistory} from 'react-router-dom';
import PdfViewerDialog from '../../../../shared/PdfViewerComponent/PdfViewerDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import NewAppointmentBookingsComponent from './AppointmentBooking';
import moment from 'moment';
import {
  AppointmentStatusTypes,
  AppointmentTypes
} from 'src/constants/SystemConstants';

const AppointmentCard = ({
  appointmentData,
  selectedDate,
  setVisible
}: any): React.ReactElement => {
  const history = useHistory();
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [data, setData]: any = useState({});
  const [appointmentStatus, setappointmentStatus] = useState<any>('');
  const [prescription, setPrescription] = React.useState('');
  const [showPdfFileDialog, setShowPdfFileDialog] = useState(false);
  const branchId = AuthUtils.getMenuBranchId();
  const [cancel, setcancel] = useState<boolean>(false);
  const [refund, setRefund] = useState<boolean>(false);
  const [cancelReason, setcancelReason] = useState<any>('');
  const [processOpen, setProcessOpen] = useState<any>(false);
  const [appId, setappId] = useState('');
  const [
    showNewAppointmentBookingUpPopup,
    setShowNewAppointmentBookingUpPopup
  ] = useState<boolean>(false);
  const [branch_id, setbranch_id] = useState<any>('');
  const [SelectedDate, setselectedDate] = useState<any>('');
  const [doc_id, setdoc_id] = useState<any>('');
  const [healpha_id, sethealpha_id] = useState<any>('');
  const [pdfUrl, setpdfUrl] = useState<any>('');
  const online = data?.appointment_type_status === AppointmentTypes.online;
  const isStandAloneDoctor = CommonUtils.checkIsStandAloneDoctor();

  const goToBilling = (): void => {
    TemplateUtils.setSelectedPatientId(data?.appointment?.healpha_id);
    TemplateUtils.clearPaymentDetails();
    TemplateUtils.clearPaymentCriteria();
    TemplateUtils.clearServices();
    TemplateUtils.clearCorporatePersonDetails();
    AuthUtils.setBillingAppointmentDetails(
      JSON.stringify({
        appointment_id: data?.appointment?.id,
        appointment_type: data?.appointment?.appointment_type,
        doc_id: data?.appointment?.doc_id,
        enc_id: data?.encounter_id,
        appointment_status: data?.appointment?.appointment_status,
        appointment_date: data?.appointment?.date_start
      })
    );
    let url = NavRoutes.PUBLIC.BILLING.BILLING_PATIENT_SCREEN;
    if (isStandAloneDoctor) {
      url += `?isFromStandAlone=${true}`;
    }
    history.push(url);
  };
  const handlePdfViewer = (e: any) => {
    e.stopPropagation();
    setShowPdfFileDialog(true);
    setpdfUrl(CommonUtils.getFullImageUrl(prescription));
  };
  const init = () => {
    appointmentData?.id &&
      getPatientCard(branchId, appointmentData?.id)
        .then((res: any) => {
          setappointmentStatus(
            res?.data?.person_appointment_details?.appointment
              ?.appointment_status
          );
          setappId(res?.data?.person_appointment_details?.appointment?.id);
          setPrescription(
            res?.data?.person_appointment_details?.prescription_url || []
          );
          setbranch_id(
            res?.data?.person_appointment_details?.appointment?.branch_id
          );
          const date = moment(
            res?.data?.person_appointment_details?.appointment?.date_start
          ).format('YYYY-MM-DD');
          setselectedDate(date);
          setdoc_id(res?.data?.person_appointment_details?.appointment?.doc_id);
          sethealpha_id(
            res?.data?.person_appointment_details?.appointment?.healpha_id
          );
          setData(res.data?.person_appointment_details);
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
  };
  const closeModal = (): void => {
    const evt = new CustomEvent('closeModal', {});
    window.dispatchEvent(evt);
  };
  const handleEvent = () => {
    const date = selectedDate;
    const evt = new CustomEvent('handleStatusCount', {detail: {date: date}});
    window.dispatchEvent(evt);
    window.dispatchEvent(new Event('handlingDisplay'));
    // closeModal();
  };

  useEffect(init, []);
  const CancelAppointment = () => {
    if (
      appointmentStatus !== AppointmentStatusTypes.CONFIRM &&
      appointmentStatus !== AppointmentStatusTypes.PAID
    ) {
      closeModal();
    }
    cancelAppointment(cancelReason, appId)
      .then(res => {
        setcancelReason('');
        setcancel(false);
        if (res?.data?.receipt) {
          setRefund(true);
          setShowPdfFileDialog(true);
          setpdfUrl(CommonUtils.getFullImageUrl(res?.data?.receipt));
        } else {
          handleEvent();
        }
        enqueueSnackbar(
          res?.message ? t(res.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('s')
        );
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const onProcess = (): void => {
    updateEncStatus(appointmentData?.id)
      .then(res => {
        setProcessOpen(false);
        handleEvent();
        enqueueSnackbar(t(res.message), SnackBarConfig('s'));
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleCloseProcess = () => {
    setProcessOpen(false);
  };

  const ProcessModal = () => {
    return (
      <Dialog
        className="cancel-appointment-wrapper p-2"
        onClose={handleCloseProcess}
        aria-labelledby="simple-dialog-title"
        open={processOpen}>
        <div className="dialog">
          <DialogTitle id="simple-dialog-title">
            <Grid container xs={6} md={12} sm={12} className="addservice">
              <Grid item xs={8} md={10} sm={10} className="add fontback ml-0">
                <h5>
                  <b>{t('HAS_THE_PATIENT_ARRIVED_FOR_CONSULTATION?')}</b>
                </h5>
              </Grid>
              <Grid item xs={2} className="clos">
                <img
                  src={Close}
                  alt="close"
                  width={20}
                  height={20}
                  onClick={handleCloseProcess}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <Grid xs={8} md={10} sm={12} className="add-clik">
            <Typography>
              {t(
                'CLICKING_ON_YES_WILL_GENERATE_AN_ENCOUNTER_TO_CONTINUE_THE_CONSULTATION'
              )}
              .
            </Typography>
          </Grid>
        </div>
        <div className="add-bt ">
          <Button
            onClick={onProcess}
            variant="contained"
            className="text-nowrap mx-4 my-2 fontback btn-primary add-wt">
            {t('COMMON_YES')}
          </Button>
        </div>
        <div className="add-bt ">
          <Button
            onClick={handleCloseProcess}
            size="medium"
            color="primary"
            variant="outlined"
            className="text-nowrap my-2 mx-4 fontback add-wt">
            {t('TEMPLATE_CANCEL')}
          </Button>
        </div>
      </Dialog>
    );
  };

  const CancelAppointmentModal = () => {
    return (
      <Dialog
        className="cancel-appointment-wrapper p-2"
        // onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={cancel}>
        <div className="dialog">
          <DialogTitle id="simple-dialog-title">
            <div className="row d-flex addservice">
              <div className="col-sm-10 add fontback">
                <h6>
                  <b>Reason</b>
                </h6>
              </div>
              <div className="col-sm-2 clos">
                <img
                  src={Close}
                  alt="close"
                  width={20}
                  height={20}
                  onClick={() => {
                    setcancel(false);
                    setcancelReason('');
                  }}
                />
              </div>
            </div>
          </DialogTitle>
          <div className="px-4 py-2 conform conformText">
            {/* <span>{t('ARE_YOU_SURE_WANT_TO_DELETE_SERVICES')}</span> */}
            <FormTextField
              name="reason"
              value={cancelReason}
              callback={(e: any) => {
                setcancelReason(e.target.value);
              }}
            />
          </div>
          <div className="px-4 py-2 conform">
            <Button
              onClick={CancelAppointment}
              variant="contained"
              className="text-nowrap mx-2 my-2 fontback btn-primary">
              {/* {t('delete')} */}
              Submit
            </Button>
            <Button
              onClick={() => {
                setcancel(false);
                setcancelReason('');
              }}
              color="primary"
              variant="outlined"
              className="text-nowrap my-2 mr-0 fontback">
              {t('TEMPLATE_CANCEL')}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };
  const closeShowPdfFileDialog = () => {
    refund && closeModal();
    setShowPdfFileDialog(false);
    setRefund(false);
    refund && handleEvent();
  };
  const renderPdfViewerDialog = (): React.ReactElement => {
    return (
      <PdfViewerDialog
        open={showPdfFileDialog}
        closeShowPdfFileDialog={closeShowPdfFileDialog}
        pdfURL={pdfUrl}
      />
    );
  };
  const closeNewAppointmentBookingPopup = () => {
    setShowNewAppointmentBookingUpPopup(false);
  };

  const handleNewAppointmentBookingPopup = () => {
    setShowNewAppointmentBookingUpPopup(true);
  };

  const renderNewAppointmentBookingPopup = (): React.ReactElement => {
    return (
      <NewAppointmentBookingsComponent
        branchId={branch_id}
        open={showNewAppointmentBookingUpPopup}
        closeNewBookingsPopup={closeNewAppointmentBookingPopup}
        is_clinical_dashboard={true}
        selectedDate={SelectedDate}
        docId={doc_id}
        healphaId={healpha_id}
        reschedule={true}
        appointment_id={data?.appointment?.id}
        enc_id={data?.encounter_id ? data?.encounter_id : ''}
        appointmentType={data?.appointment?.appointment_type}
        appointmentTypeStatus={data?.appointment_type_status}
      />
    );
  };

  const renderStatusAndDot = (): React.ReactElement => {
    const {appointment_status} = data?.appointment || {};
    const bgColor = statusColor.filter(
      item => item.name === appointment_status
    );
    const newDotColor = bgColor[0]?.dotColor;
    return (
      <Grid
        container
        spacing={0.25}
        alignItems="center"
        className="status-dot-grid">
        <Grid item>
          <Typography>{appointment_status}</Typography>
        </Grid>

        <Grid
          item
          className="status-dot"
          style={{backgroundColor: newDotColor}}
        />
      </Grid>
    );
  };

  return (
    <div className="patient-details-card-wrapper">
      <div className="d-flex justify-content-between ml-3">
        <div className="d-flex">
          <div className="ml-3 px-3 mt-2">
            <Avatar sx={{width: 70, height: 70}} className="letter">
              {data?.appointment?.person_details?.full_name
                ?.slice(0, 1)
                .toUpperCase()}
            </Avatar>
          </div>
          <div style={{alignItems: 'center'}} className="my-3">
            <Grid className="d-flex">
              <Typography className="custom text-capitalize">
                {data?.appointment?.person_details?.full_name}
              </Typography>
              <Grid>
                <Typography
                  className={`${online ? 'tag-online' : 'tag-offline'} mx-2`}>
                  {data?.appointment_type_status}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" className="my-2 type-appoint">
              {data?.appointment?.appointment_type}
            </Typography>
            <Typography variant="h4" className="custom1 my-2">
              {renderStatusAndDot()}
            </Typography>
          </div>
        </div>
        <div className="my-3">
          <Button
            className="m-0 p-0"
            onClick={() => {
              setVisible(false);
            }}>
            <img src={Close} alt="close" width={20} height={20} />
          </Button>
        </div>
      </div>
      <div>
        <div className="d-flex px-3">
          <div className="d-flex justify-content-around">
            {data?.appointment?.person_details?.email ? (
              <div className="d-flex">
                <img src={Email} width={20} height={20} className="mx-2" />
                <Typography className="mx-1 email-details">
                  {data?.appointment?.person_details?.email}
                </Typography>
              </div>
            ) : null}
            <img src={PatientIcon} width={20} height={20} className="mx-2" />
            <Typography className="mx-1 gender-detail">
              {CommonUtils.getCapitalizeWord(
                data?.appointment?.person_details?.gender
              )}
              {', '}
              {data?.appointment?.person_details?.age
                ?.split(' ')?.[0]
                ?.replace('Y', ' Years')
                ?.replace('D', ' Days')
                ?.replace('M', ' Months')}
            </Typography>
          </div>
        </div>
        {data?.appointment?.person_details?.phone_no ? (
          <div className="d-flex mt-3 px-3">
            <img src={PhoneIcon} width={20} height={20} className="mx-2" />
            <Typography className="mx-1 phone-details">
              {data?.appointment?.person_details?.phone_no}
            </Typography>
          </div>
        ) : null}
      </div>
      <hr className="mx-3" />
      <Typography variant="body2" className="m-3 px-3 complain-head">
        {t('CHIEF_COMPLAINT')}
      </Typography>
      <Typography className="custom-description m-3 px-3">
        {data?.appointment?.description}
      </Typography>
      <div className="d-flex">
        {appointmentStatus === AppointmentStatusTypes.BOOKED ||
        appointmentStatus === AppointmentStatusTypes.CONFIRM ||
        appointmentStatus === AppointmentStatusTypes.RESCHEDULED ||
        appointmentStatus === AppointmentStatusTypes.PAID ? (
          <Button
            onClick={() => {
              setcancel(true);
            }}>
            <img
              src={ClosePrimaryIcon}
              width={50}
              height={50}
              className="mx-3 back-color"
            />
          </Button>
        ) : null}
        {appointmentStatus === AppointmentStatusTypes.BOOKED ||
        appointmentStatus === AppointmentStatusTypes.CONFIRM ||
        appointmentStatus === AppointmentStatusTypes.RESCHEDULED ||
        appointmentStatus === AppointmentStatusTypes.PAID ? (
          <Button
            onClick={() => {
              handleNewAppointmentBookingPopup();
            }}>
            <img
              src={Refresh}
              width={50}
              height={50}
              className="mx-3 back-color"
            />
          </Button>
        ) : null}
        {prescription?.length > 1 ? (
          <Button
            onClick={e => {
              handlePdfViewer(e);
            }}>
            <img src={Pdf} width={50} height={50} className="mx-3 back-color" />
          </Button>
        ) : null}
        {appointmentStatus !== AppointmentStatusTypes.CANCELLED &&
        appointmentStatus !== AppointmentStatusTypes.CLOSED ? (
          <Button
            onClick={() => {
              appointmentStatus !== AppointmentStatusTypes.PAID
                ? goToBilling()
                : setProcessOpen(true);
            }}>
            <img
              src={Next}
              width={50}
              height={50}
              className="mx-3 back-color"
            />
          </Button>
        ) : null}
      </div>
      <ProcessModal />
      {showPdfFileDialog && renderPdfViewerDialog()}
      {cancel && CancelAppointmentModal()}
      {showNewAppointmentBookingUpPopup && renderNewAppointmentBookingPopup()}
    </div>
  );
};

export default AppointmentCard;
