import {environment} from '../environment/environment';
import {AxiosCall} from '../axios';
// import Axios from 'axios';

const apiEndPoints = environment.apiEndPoints;
const publicRoute = apiEndPoints.public;
const multiLanguageRoute = apiEndPoints.multiLanguage;
const loginUrl = apiEndPoints.login;
const sendOtpUrl = '/auth/send-otp';
const verifyOtpUrl = '/auth/verify-otp';
const signUpUrl = '/auth/sign-up/person';
const doctorSignUpUrl = '/auth/sign-up/doctor/web';
const tempPersonSignUpUrl = '/auth/sign-up/temp-person';
const temporaryLoginUrl = '/auth/login/temp-person';
const doctorVerifyUrl = '/auth/verify-doctor';
const nonHealphaUserLoginUrl = '/auth/login/non-healpha-user';

export const getSpecializationSearch = async (
  searchText: string,
  practiceId: any,
  branchId: any
): Promise<any> => {
  const url =
    `/${publicRoute.base}/${publicRoute.specialization.base}/${publicRoute.specialization.search}?search_text=${searchText}` +
    `&branch_id=${branchId}&practice_id=${practiceId}`;
  return AxiosCall.get(url);
};

export const createForumsQuestion = async (values: any): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.forums.base}/${publicRoute.forums.question}/${publicRoute.forums.create}`;
  return await AxiosCall.post(url, values);
};

export const createForumsReply = async (values: any): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.forums.base}/${publicRoute.forums.reply}/${publicRoute.forums.create}`;
  return await AxiosCall.post(url, values);
};

export const sendOtp = async (values: any): Promise<any> => {
  return await AxiosCall.post(sendOtpUrl, values);
};

export const getAllForumsList = async (
  category: any,
  topic: any,
  question: any,
  offset: any,
  limit?: any
): Promise<any> => {
  let url =
    `/${publicRoute.base}/${publicRoute.forums.base}` +
    `/${publicRoute.forums.question}/${publicRoute.forums.all}?category=${category}` +
    `&topic=${topic}&question=${question}&offset=${offset}`;

  if (limit) {
    url = `${url}&limit=${limit}`;
  }
  return AxiosCall.get(url);
};

export const getAllReplies = async (id: any): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.forums.base}/${publicRoute.forums.question}/${id}`;
  return AxiosCall.get(url);
};

export const getPracticeSearch = async (searchText: string): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.practice.base}/${publicRoute.doctor.search}?search_text=${searchText}`;
  return AxiosCall.get(url);
};

export const getAllPracticesList = async (
  search_text: any,
  offset: any
): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.practice.base}/${publicRoute.practice.search}/${publicRoute.practice.all}?search_text=${search_text}&offset=${offset}`;
  return AxiosCall.get(url);
};

export const getPracticeById = async (practice_id: string): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.practice.base}/${practice_id}`;
  return AxiosCall.get(url);
};

export const getBranchById = async (branch_id: string): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.practice.branch}/${branch_id}`;
  return AxiosCall.get(url);
};

export const getDoctorSearch = async (
  searchText: string,
  specializationText: string,
  branchId: string
): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.doctor.base}/${publicRoute.doctor.search}?search_text=${searchText}&specialization=${specializationText}&branch_id=${branchId}`;
  return AxiosCall.get(url);
};

export const getAllDoctorsList = async (
  search_text: any,
  branch_id: any,
  practice_id: any,
  specializationTxt: any,
  appointmentTypeTxt: any,
  offset: any,
  filtersObject: any
): Promise<any> => {
  const specialization = filtersObject?.specialization || specializationTxt;
  let url =
    `/${publicRoute.base}/${publicRoute.doctor.base}/${publicRoute.doctor.search}` +
    `/${publicRoute.doctor.all}?search_text=${search_text}` +
    `&branch_id=${branch_id}&practice_id=${practice_id}&specialization=${specialization}` +
    `&appointment_type=${appointmentTypeTxt}&offset=${offset}`;
  if (filtersObject) {
    const {
      amount,
      appointment_type,
      availability_date,
      gender,
      is_greater_than,
      languages
    } = filtersObject;
    url = `${url}&is_greater_than=${is_greater_than}`;
    if (amount) {
      url = `${url}&amount=${amount}`;
    }
    if (appointment_type) {
      url = `${url}&appointment_type=${appointment_type}`;
    }
    if (availability_date) {
      url = `${url}&availability_date=${availability_date}`;
    }
    if (gender) {
      url = `${url}&gender=${gender}`;
    }
    if (languages) {
      url = `${url}&languages=${languages}`;
    }
  }
  return AxiosCall.get(url);
};
export const getDoctorsBySearch = async (
  search_text: any,
  branch_id: any,
  practice_id: any,
  specializationTxt: any,
  appointmentTypeTxt: any,
  offset: any,
  filtersObject: any
): Promise<any> => {
  const specialization = filtersObject?.specialization || specializationTxt;
  let url =
    `/${publicRoute.base}/${publicRoute.doctor.base}/${publicRoute.doctor.search}` +
    `/${publicRoute.doctor.all}?search_text=${search_text}` +
    `&branch_id=${branch_id}&practice_id=${practice_id}&specialization=${specialization}` +
    `&appointment_type=${appointmentTypeTxt}&offset=${offset}`;
  if (filtersObject) {
    const {
      amount,
      appointment_type,
      availability_date,
      gender,
      is_greater_than,
      languages
    } = filtersObject;
    url = `${url}&consultation_price_operator=${
      is_greater_than ? (is_greater_than == true ? 'gte' : 'lt') : ''
    }`;
    if (amount) {
      url = `${url}&consultation_price_filter=${amount}`;
    }
    if (appointment_type) {
      url = `${url}&appointment_type=${appointment_type}`;
    }
    if (availability_date) {
      url = `${url}&availability_date=${availability_date}`;
    }
    if (gender) {
      url = `${url}&gender_filter=${gender}`;
    }
    if (languages) {
      url = `${url}&languages_filter=${languages}`;
    }
  }
  return AxiosCall.get(url);
};

export const getCorporateDoctor = async (
  person_id: any,
  search_text: any,
  branch_id: any
): Promise<any> => {
  const url = `/person/corporate/doctor/all?person_id=${person_id}&branch_id=${branch_id}&search_text=${search_text}`;
  return AxiosCall.get(url);
};

export const getDoctorLanguages = async (doctor_id: any): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.doctor.base}/${doctor_id}/language-speaks-education`;
  return AxiosCall.get(url);
};

export const getDoctorById = async (
  doctor_id: any,
  branchId?: any,
  virtualClinicBranchId?: any,
  category?: any
): Promise<any> => {
  let url = `/${publicRoute.base}/${publicRoute.doctor.base}/${doctor_id}`;
  if (branchId) {
    url = `${url}?branch_id=${branchId}`;
  }
  if (category) {
    url = `${url}&category=${category}`;
  }
  if (branchId && virtualClinicBranchId) {
    url = `${url}&virtual_clinic_branch=${virtualClinicBranchId}`;
  } else if (virtualClinicBranchId) {
    url = `${url}?virtual_clinic_branch=${virtualClinicBranchId}`;
  }
  return AxiosCall.get(url);
};

export const getReviewsOfHospital = (): any => {
  const feedback = [
    {
      description: `Recently visited health check up department of Apollo Hospital for a whole
      body check up.
    The entire process from check in to the end was seamless. The staff at the health check
    department was courteous, helpful and very attentive.
    I would highly recommend this hospital to anyone
    looking for something similar services.`,
      profileImage:
        `https://test.healpha.com/data/static/upload/images` +
        `/stock-vector-building-high-school-of-the-american-or-european-with-trees-and-bushes-a-city-landscape-with-a-1017174409.jpg`,
      profileName: 'Prakash',
      date: '7 days ago',
      rateValue: 2
    },
    {
      description: `Recently visited health check up department of Apollo Hospital for a
      whole body check up.
    The entire process from check in to the end was seamless. The staff at the health check
    department was courteous, helpful and very attentive. I would highly recommend this hospital
    to anyone
    looking for something similar services.`,
      profileImage:
        `https://test.healpha.com/data/static/upload` +
        `/images/stock-vector-building-high-school-of-the-american-or-european-with-trees-and-bushes-a-city-landscape-with-a-1017174409.jpg`,
      profileName: 'Prakash',
      date: '7 days ago',
      rateValue: 2
    },
    {
      description: `Recently visited health check up department of Apollo Hospital for a whole body check up.
    The entire process from check in to the end was seamless. The staff at the health check
    department was courteous, helpful and very attentive. I would highly recommend this hospital to anyone
    looking for something similar services.`,
      profileImage:
        `https://test.healpha.com/data/static/upload/images` +
        `/stock-vector-building-high-school-of-the-american-or-european-with-trees-and-bushes-a-city-landscape-with-a-1017174409.jpg`,
      profileName: 'Prakash',
      date: '7 days ago',
      rateValue: 2
    }
  ];
  return {data: {reviews: feedback}};
};

export const getDoctorAppointments = async (
  doctor_id: any,
  branch_id: any
): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.doctor.base}/${doctor_id}/${publicRoute.practice.branch}/${branch_id}`;
  const totalUrl = `${url}/${publicRoute.doctor.appointment}/${publicRoute.doctor.types}`;
  return AxiosCall.get(totalUrl);
};

export const getValidateWhiteLabelPrefix = async (
  prefix: any,
  url: any
): Promise<any> => {
  const urlFinal = `/${publicRoute.base}/${publicRoute.whiteLabelPrefix}/${publicRoute.validate}/${prefix}?url=${url}`;
  return AxiosCall.get(urlFinal);
};

export const getLanguageContent = async (
  language: any,
  module: any
): Promise<any> => {
  const url = `/${multiLanguageRoute.base}/${multiLanguageRoute.content}?language=${language}&module=${module}`;
  return AxiosCall.get(url);
};

export const getAppointmentTimings = async (
  doctor_id: any,
  branch_id: any,
  date: any,
  virtualClinicBranchId?: any
): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.doctor.base}/${doctor_id}/${publicRoute.practice.branch}/${branch_id}`;
  let totalUrl = `${url}/${publicRoute.doctor.appointment}/${publicRoute.doctor.availableTimings}?date=${date}`;

  if (virtualClinicBranchId) {
    totalUrl = `${totalUrl}&virtual_clinic_branch=${virtualClinicBranchId}`;
  }
  return AxiosCall.get(totalUrl);
};

export const sendAppLink = async (data: any): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.getApp}`;
  return AxiosCall.post(url, data);
};

export const sendOtpToUser = async (data: any): Promise<any> => {
  return AxiosCall.post(sendOtpUrl, data);
};

export const verifyOtp = async (data: any): Promise<any> => {
  return AxiosCall.post(verifyOtpUrl, data);
};

export const signUpPerson = async (data: any): Promise<any> => {
  return AxiosCall.post(signUpUrl, data);
};

export const loginPerson = async (data: any): Promise<any> => {
  return AxiosCall.post(loginUrl, data);
};

export const temporaryPersonSignUp = async (data: any): Promise<any> => {
  return AxiosCall.post(tempPersonSignUpUrl, data);
};

export const temporaryPersonLogin = async (data: any): Promise<any> => {
  return AxiosCall.post(temporaryLoginUrl, data);
};

export const createFamilyMember = async (
  data: any,
  personId: any
): Promise<any> => {
  const url = `/person/${personId}/family-member/create`;
  return AxiosCall.post(url, data);
};

export const bookDoctorAppointment = async (
  data: any,
  personId: any
): Promise<any> => {
  const url = `/person/${personId}/book-appointment`;
  return AxiosCall.post(url, data);
};

export const sendBookAppointmentEmail = async (data: any): Promise<any> => {
  const url = `/send_appointment_mail`;
  return AxiosCall.post(url, data);
};

// export const sendPersonCreateEmail = async (data: any): Promise<any> => {
//   const url = `/send_person_registration_mail`;
//   return AxiosCall.post(url, data);
// };

export const logoutPhpSession = async (): Promise<any> => {
  const url = `/welcome/logout`;
  return AxiosCall.post(url, {});
};

export const getForgotPassword = async (data: any): Promise<any> => {
  const url = `/set_forgot_mail_react`;
  return AxiosCall.post(url, data);
};

export const getAllUsers = async (phoneNumber: any): Promise<any> => {
  const url = `/public/person/all-users?phone_number=${phoneNumber}`;
  return AxiosCall.get(url);
};

export const getPersonDetails = async (personId: any): Promise<any> => {
  const url = `/person/${personId}`;
  return AxiosCall.get(url);
};

export const getTopCategories = async (): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.top10Categories}`;
  return AxiosCall.get(url);
};

export const getForumCategories = async (): Promise<any> => {
  const url = `/${publicRoute.base}/forum-categories/${publicRoute.specialization.search}`;
  return AxiosCall.get(url);
};

export const getQuestionRepliesCount = async (
  question_id: any
): Promise<any> => {
  const url = `/${publicRoute.base}/${publicRoute.forums.base}/${publicRoute.forums.question}/${question_id}/replies/count`;
  return AxiosCall.get(url);
};

export const doctorSignUp = async (data: any): Promise<any> => {
  return AxiosCall.post(doctorSignUpUrl, data);
};

export const verifyDoctorToken = async (data: any): Promise<any> => {
  return AxiosCall.post(doctorVerifyUrl, data);
};

export const updateDoctorData = async (data: any): Promise<any> => {
  const url = `/auth/create-password`;
  return AxiosCall.put(url, data);
};

export const nonHealphaUserLogin = async (data: any): Promise<any> => {
  return AxiosCall.post(nonHealphaUserLoginUrl, data);
};

export const getUserForgotPassword = async (data: any): Promise<any> => {
  const url = `/auth/forgot-password`;
  return AxiosCall.post(url, data);
};

export const resetPersonPassword = async (data: any): Promise<any> => {
  const url = '/auth/reset-password';
  return AxiosCall.put(url, data);
};

export const setPhpSession = async (data: any): Promise<any> => {
  const url = `/set_session_iframe`;
  return AxiosCall.post(url, data);
};

export const getLanguages = async (): Promise<any> => {
  const url = '/multi-language/content';
  return AxiosCall.get(url);
};

export const updateLanguages = async (data: any): Promise<any> => {
  const url = '/multi-language/content';
  return AxiosCall.put(url, data);
};

export const addLanguages = async (data: any): Promise<any> => {
  const url = '/multi-language/content';
  return AxiosCall.post(url, data);
};

export const getContactDetails = async (): Promise<any> => {
  const url = '/public/country-contact-details';
  return AxiosCall.get(url);
};

export const getAllMasterTemplateDetails = async (
  variables: any
): Promise<any> => {
  const url = `/template/master-templates?practice_id=${
    variables.practice_id || ''
  }&branch_id=${variables.branch_id || ''}&search_text=${
    variables.search_text || ''
  }&offset=${variables.offset || ''}&status=${
    variables.status || ''
  }&doctor_id=${variables.doctor || ''}&specialization=${
    variables.specialization || ''
  }&language=${variables.language}`;
  return AxiosCall.get(url);
};

export const updatePersonProfile = async (
  hlpId: any,
  data: any
): Promise<any> => {
  const url = `/person/${hlpId}`;
  return AxiosCall.put(url, data);
};

export const getPhpContentByLanguage = async (
  language_key: any
): Promise<any> => {
  const url = `/switch_language`;
  return AxiosCall.post(url, {language: language_key});
};

export const getStatusCount = async (
  doctor_id: any,
  branch_id: any,
  date: any
): Promise<any> => {
  const url = `/appointment/status/all?doctor_id=${doctor_id}&branch_id=${branch_id}&appointment-details&date=${date}`;
  return AxiosCall.get(url, {
    date: date,
    doctor_id: doctor_id,
    branch_id: branch_id
  });
};

export const getAppointmentDetails = async ({
  doctor_id,
  branch_id,
  date,
  appointment_checked,
  search_text,
  end_date,
  total_appts_req
}: any): Promise<any> => {
  const url = `/appointment/list/all?doctor_id=${doctor_id || ''}&branch_id=${
    branch_id || ''
  }&date=${date || ''}&appointment_checked=${
    appointment_checked || ''
  }&search_text=${search_text || ''}&end_date=${
    end_date || ''
  }&total_appts_req=${total_appts_req || false}`;
  return AxiosCall.get(url);
};

export const getAppointmentById = async (
  id: any,
  branch_id: any
): Promise<any> => {
  const url = `/appointment/${id}?branch_id=${branch_id}`;
  return AxiosCall.get(url);
};

export const getAppointmentLog = async (
  doctor_id: any,
  hlp_id: any,
  id: any
): Promise<any> => {
  const url = `/appointment/doctor/${doctor_id}/person/${hlp_id}/logs/${id}`;
  return AxiosCall.get(url);
};

export const getPatientUnavailableOptions = async (): Promise<any> => {
  const url = `/reference-data/patient-unavailable-options`;
  return AxiosCall.get(url);
};

export const addPatientUnavailableMessage = async (
  enc_id: any,
  doc_id: any,
  hlp_id: any,
  type: any,
  data: any,
  app_id: any,
  is_appointment_cancelled: any
): Promise<any> => {
  const url = `/appointment/encounter/${enc_id}/doctor/${doc_id}/person/${hlp_id}/comment/${type}`;
  return AxiosCall.post(url, {
    comment: data,
    is_appointment_cancelled: is_appointment_cancelled,
    appointment_id: app_id
  });
};

export const cancelAppointment = async (data: any, id: any): Promise<any> => {
  const url = `/appointment/cancel/${id}`;
  return AxiosCall.post(url, {
    description: data
  });
};

export const getFamilyHistory = async (person_id: any): Promise<any> => {
  const url = `/person/family-history-by/${person_id}`;
  return AxiosCall.get(url);
};

export const getFamilyHistoryById = async (
  familyHistoryId: any
): Promise<any> => {
  const url = `/person/family-history/${familyHistoryId}`;
  return AxiosCall.get(url);
};

export const getPatientList = async (
  doctor_id: any,
  branch_id: any,
  date: any,
  data: any
): Promise<any> => {
  const url = `/appointment/doctor/${doctor_id}/branch/${branch_id}/appointment-details/${date}`;
  return AxiosCall.get(url, data);
};

export const getHealthEvents = async (person_id: any): Promise<any> => {
  const url = `/person/health-event-by/${person_id}`;
  return AxiosCall.get(url);
};

export const getHealthEventsById = async (healthEventId: any): Promise<any> => {
  const url = `/person/health-event/${healthEventId}`;
  return AxiosCall.get(url);
};
export const getImmunizationData = async (healpha_id: any): Promise<any> => {
  const url = `/person/${healpha_id}/immunization/immunization `;
  return AxiosCall.get(url);
};
export const getImmunizationOtherChart = async (
  healpha_id: any
): Promise<any> => {
  const url = `/person/${healpha_id}/immunization/other `;
  return AxiosCall.get(url);
};

export const getImmunizationDataReference = async (
  branch_id: any,
  search_text: any
): Promise<any> => {
  const url = `reference-data/immunization/vaccines/${branch_id}?search_text=${search_text} `;
  return AxiosCall.get(url);
};
export const getImmunizationAddVaccineList = async (
  hlp_id: any
): Promise<any> => {
  const url = `reference-data/immunization/vaccines/${hlp_id}`;
  return AxiosCall.get(url);
};
export const setVaccineDate = async (
  hlp_id: any,
  id: any,
  date: any
): Promise<any> => {
  const url = `/person/${hlp_id}/immunization/${id}`;
  return AxiosCall.put(url, {actual_date: date});
};

export const addVaccineData = async (
  hlp_id: any,
  id: any,
  date: any
): Promise<any> => {
  const url = `/person/${hlp_id}/immunization/add-vaccine`;
  return AxiosCall.post(url, {id: id, vaccine_taken_date: date});
};

export const getAllergies = async (personId: any): Promise<any> => {
  const url = `/person/allergies-by/${personId}`;
  return AxiosCall.get(url);
};

export const getAllergiesById = async (allergyId: any): Promise<any> => {
  const url = `/person/allergies/${allergyId}`;
  return AxiosCall.get(url);
};

export const getMedicines = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  category: any,
  template_id: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/prescription/${category}?template_id=${template_id}`;
  return AxiosCall.get(url);
};

export const getBranchPlan = async ({
  practice_id,
  branch_id,
  module_id,
  type,
  search_text,
  active,
  is_practice_level
}: any): Promise<any> => {
  const url = `/plan/branches?practice_id=${practice_id || ''}&branch_id=${
    branch_id || ''
  }&module_id=${module_id || ''}&search_text=${
    search_text || ''
  }&type=${type}&active=${active || ''}&is_practice_level=${is_practice_level}`;
  return AxiosCall.get(url);
};

export const getPatientCard = async (branch_id: any, id: any): Promise<any> => {
  const url = `/appointment/${id}?branch_id=${branch_id}`;
  return AxiosCall.get(url);
};

export const getTimelineData = async (
  healpha_id: any,
  search_text: any,
  encounter_id?: any,
  virtual_clinic_branch?: any
): Promise<any> => {
  const url = `/appointment/person-timeline/${healpha_id}?search_text=${search_text}&encounter_id=${encounter_id}&virtual_clinic_branch=${virtual_clinic_branch} `;
  return AxiosCall.get(url);
};

export const addFamilyHistory = async (data: any): Promise<any> => {
  const url = `/person/family-history`;
  return AxiosCall.post(url, data);
};

export const addAllergies = async (data: any): Promise<any> => {
  const url = '/person/allergies ';
  return AxiosCall.post(url, data);
};

export const addHealthEvents = async (
  healpha_id: any,
  data: any
): Promise<any> => {
  const url = `/person/${healpha_id}/health-event/ `;
  return AxiosCall.post(url, data);
};

export const uploadHealthEventsAttachment = async (
  healpha_id: any,
  id: any,
  data: any
): Promise<any> => {
  const url = `/person/${healpha_id}/health-event/${id}/attachment?mime_type=pdf`;
  return AxiosCall.post(url, data);
};

export const deleteHealthEventsAttachment = async (
  healpha_id: any,
  id: any,
  attachment_id: any
): Promise<any> => {
  const url = `/person/${healpha_id}/health-event/${id}/attachment/${attachment_id}`;
  return AxiosCall.delete(url);
};

// delete api
export const deleteAllergyById = async (allergy_id: any): Promise<any> => {
  const url = `/person/allergies/${allergy_id}  `;
  return AxiosCall.delete(url);
};

export const deleteEventById = async (event_id: any): Promise<any> => {
  const url = `/person/health-event/${event_id}  `;
  return AxiosCall.delete(url);
};

export const deleteFamilyById = async (family_id: any): Promise<any> => {
  const url = `/person/family-history/${family_id}  `;
  return AxiosCall.delete(url);
};

export const sendPushNotification = async (data: any): Promise<any> => {
  const url = `/notify_healpha_call`;
  return AxiosCall.post(url, data);
};

export const storeDeviceToken = async (data: any): Promise<any> => {
  const url = `/user/device-token`;
  return AxiosCall.post(url, data);
};

export const deleteNotificationDevice = async (deviceId: any): Promise<any> => {
  const url = `/user/device-token/${deviceId}`;
  return AxiosCall.delete(url);
};

export const createMicroTemplate = async (data: any): Promise<any> => {
  const url = `/template/micro`;
  return AxiosCall.post(url, data);
};

export const createMasterTemplate = async (data: any): Promise<any> => {
  const url = `/template/master-template`;
  return AxiosCall.post(url, data);
};

export const createCustomVital = async (
  template_id: any,
  data: any
): Promise<any> => {
  const url = `/template/${template_id}/custom-vital`;
  return AxiosCall.post(url, data);
};

export const cloneMasterTemplate = async (
  template_id: any,
  data: any
): Promise<any> => {
  const url = `/template/${template_id}/clone`;
  return AxiosCall.post(url, data);
};

export const createCustomTemplateGroup = async (
  template_id: any,
  data: any
): Promise<any> => {
  const url = `/template/${template_id}/micro-template/custom/group`;
  return AxiosCall.post(url, data);
};

export const createCustomTemplateCategory = async (
  template_id: any,
  data: any
): Promise<any> => {
  const url = `/template/${template_id}/micro-template/custom/category`;
  return AxiosCall.post(url, data);
};

export const editAllergy = async (id: any, data: any): Promise<any> => {
  const url = `/person/allergies/${id}`;
  return AxiosCall.put(url, data);
};
export const editFamilyHistory = async (id: any, data: any): Promise<any> => {
  const url = `/person/family-history/${id}`;
  return AxiosCall.put(url, data);
};
export const editHealthEvent = async (
  id: any,
  healpha_id: any,
  data: any
): Promise<any> => {
  const url = `/person/${healpha_id}/health-event/${id}`;
  return AxiosCall.put(url, data);
};

export const updateMasterTemplate = async (
  template_id: any,
  data: any
): Promise<any> => {
  const url = `/template/${template_id}`;
  return AxiosCall.put(url, data);
};

export const updateMicroTemplate = async (
  template_id: any,
  data: any
): Promise<any> => {
  const url = `/template/micro/${template_id}`;
  return AxiosCall.put(url, data);
};

export const updateCustomVitals = async (
  template_id: any,
  id: any,
  data: any
): Promise<any> => {
  const url = `/template/${template_id}/custom-vital/${id}`;
  return AxiosCall.put(url, data);
};

export const deleteCustomVitals = async (
  template_id: any,
  id: any
): Promise<any> => {
  const url = `/template/${template_id}/custom-vital/${id}`;
  return AxiosCall.delete(url);
};

export const getAllData = async (data: any): Promise<any> => {
  const url = `/reference-data/list/practice-branch-doctor-spec?practice_id=${
    data.practice_id || ''
  }&branch_id=${data.branch_id || ''}`;
  return AxiosCall.get(url);
};

export const getMeasurements = async (data: any): Promise<any> => {
  const url = `/reference-data/custom-vitals/definitions`;
  return AxiosCall.get(url);
};

export const getAllDefultVitals = async (data: any): Promise<any> => {
  const url = `/reference-data/vital/definitions`;
  return AxiosCall.get(url);
};

export const getAllMicroTemplates = async (variables: any): Promise<any> => {
  const url = `/template/${variables.template_id}/micro-templates?category_id=${
    variables.category_id || ''
  }&search_text=${variables.search_text || ''}&is_custom=${
    variables.is_custom || ''
  }&language=${variables.language}`;
  return AxiosCall.get(url);
};
export const getMicroTemplate = async (id: any): Promise<any> => {
  const url = `/template/micro/${id}`;
  return AxiosCall.get(url);
};

export const getTemplate = async (template_id: any): Promise<any> => {
  const url = `/template/${template_id}/create-format`;
  return AxiosCall.get(url);
};

export const getSearchText = async (
  branch_id: any,
  category: any
): Promise<any> => {
  const url = `/appointment/plan/search-list/${category}?branch_id=${branch_id}`;
  return AxiosCall.get(url);
};

export const deleteMasterTemplateById = async (
  template_id: any
): Promise<any> => {
  const url = `/template/${template_id}  `;
  return AxiosCall.delete(url);
};

export const getMedicineType = async (type: any): Promise<any> => {
  const url = `/appointment/plan/drug-types?drug_type=${type}`;
  return AxiosCall.get(url);
};

export const addMedicines = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  category: any,
  template_id: any,
  data: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/prescription/${category}?template_id=${template_id}`;
  return AxiosCall.post(url, data);
};
export const deleteMedicines = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  category: any,
  id: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/prescription/${category}/${id}`;
  return AxiosCall.delete(url);
};

export const deleteMedicinesFromTemplate = async (
  template_id: any,
  category: any,
  id: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/prescription/${category}/${id}`;
  return AxiosCall.delete(url);
};

export const updateMedicines = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  category: any,
  id: any,
  data: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/prescription/${category}/${id}`;
  return AxiosCall.put(url, data);
};

export const getMedicinesSearchList = async (
  category: any,
  text: any,
  branch_id: any,
  hlp_id: any
): Promise<any> => {
  const url = `/appointment/plan/search-list/${category}?search_text=${text}&branch_id=${branch_id}&hlp_id=${hlp_id}`;
  return AxiosCall.get(url);
};

export const getMedicinesDataWithTemplateId = async (
  template_id: any,
  category: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/prescription/${category}`;
  return AxiosCall.get(url);
};

export const addMedicinesDataWithTemplateId = async (
  template_id: any,
  category: any,
  data: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/prescription/${category}`;
  return AxiosCall.post(url, data);
};

export const getSuppliments = async (
  branch_id: any,
  text: any
): Promise<any> => {
  const url = `/appointment/branch/${branch_id}/fetch-medical-data?suppliment=true&medicine_type=1&search_text=${text}`;
  return AxiosCall.get(url);
};

export const getLabData = async (branch_id: any, text: any): Promise<any> => {
  const url = `/appointment/branch/${branch_id}/fetch-medical-data?search_text=${text}&lab=true`;
  return AxiosCall.get(url);
};

export const getImagingData = async (
  category: any,
  text: any,
  branch_id: any,
  hlp_id: any
): Promise<any> => {
  const url = `/appointment/plan/search-list/${category}?search_text=${text}&branch_id=${branch_id}&hlp_id=${hlp_id}`;
  return AxiosCall.get(url);
};

export const addImagingData = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  category: any,
  template_id: any,
  data: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/service/${category}?template_id=${template_id}`;
  return AxiosCall.post(url, data);
};

export const deleteImagingData = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  category: any,
  id: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/service/${category}/${id}`;
  return AxiosCall.delete(url);
};

export const deleteImagingDataTemplateId = async (
  template_id: any,
  category: any,
  id: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/service/${category}/${id}`;
  return AxiosCall.delete(url);
};

export const updateImagingData = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  category: any,
  id: any,
  data: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/service/${category}/${id}`;
  return AxiosCall.put(url, data);
};

export const getNursingData = async (
  branch_id: any,
  text: any
): Promise<any> => {
  const url = `/appointment/branch/${branch_id}/fetch-medical-data?search_text=${text}&nursing=true`;
  return AxiosCall.get(url);
};

export const getVaccineData = async (
  branch_id: any,
  text: any,
  healpha_id: any
): Promise<any> => {
  const url = `/appointment/branch/${branch_id}/fetch-medical-data?vaccine=true&search_text=${text}&healpha_id=${healpha_id}`;
  return AxiosCall.get(url);
};

export const deleteMicroTemplateById = async (id: any): Promise<any> => {
  const url = `/template/micro/${id}  `;
  return AxiosCall.delete(url);
};

export const searchforplan = async (variables: any): Promise<any> => {
  const url = `/appointment/plan/search-list/${variables.category}?branch_id=${
    variables.branch_id || ''
  }&search_text=${variables.search_text || ''}`;
  return AxiosCall.get(url);
};
export const createPlanType = async (
  template_id: any,
  variables: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/service/${variables.type}`;
  return AxiosCall.post(url, variables.data);
};

export const saveMedicineTemplate = async (
  template_id: any,
  variables: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/prescription/${variables.type}`;
  return AxiosCall.post(url, variables.data);
};

export const updateMedicineTemplate = async (
  template_id: any,
  variables: any,
  id: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/prescription/${variables.type}/${id}`;
  return AxiosCall.put(url, variables.data);
};

export const getMedicineTemplate = async (
  template_id: any,
  variables: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/prescription/${variables.category}`;
  return AxiosCall.get(url);
};

export const deleteMedicineTemplate = async (
  template_id: any,
  category: any,
  id: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/prescription/${category}/${id}`;
  return AxiosCall.delete(url);
};

export const deletePlanType = async (
  template_id: any,
  type: any,
  id: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/service/${type}/${id}`;
  return AxiosCall.delete(url);
};

export const getPlanType = async (
  template_id: any,
  variables: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/service/${variables.type}`;
  return AxiosCall.get(url);
};

export const getMasterPlanType = async ({
  type,
  module_id,
  search_key,
  search_text,
  is_active,
  offset,
  is_default,
  is_trial,
  from_date,
  to_date
}: any): Promise<any> => {
  const url = `plan/masters?module_id=${module_id || ''}&search_key=${
    search_key || ''
  }&search_text=${search_text || ''}&type=${type || ''}&is_active=${
    is_active || ''
  }&offset=${offset || ''}&is_default=${is_default || ''}&is_trial=${
    is_trial || ''
  }&from_date=${from_date || ''}
  &to_date=${to_date || ''}`;
  return AxiosCall.get(url);
};

export const deletePracticePlanById = async (id: any): Promise<any> => {
  const url = `/plan/master/${id}`;
  return AxiosCall.delete(url);
};

export const deleteBranchPlanById = async (id: any): Promise<any> => {
  const url = `/plan/branch/${id}`;
  return AxiosCall.delete(url);
};

export const searchforplanTemplate = async (
  template_id: any,
  variables: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/search-list/${
    variables.category
  }?search_text=${variables.search_text || ''}`;
  return AxiosCall.get(url);
};

export const updateplanTemplate = async (
  template_id: any,
  variables: any,
  id: any
): Promise<any> => {
  const url = `/template/${template_id}/plan/service/${variables.type}/${id}`;
  return AxiosCall.put(url, variables);
};

// billing api's

export const getServices = async (
  hlp_id: any,
  branch_id: any,
  group_id: any,
  tarrif?: any
): Promise<any> => {
  const url = `/billing/patient/${hlp_id}/branch/${branch_id}/service-groups/?group_id=${
    group_id || ''
  }&tarrif=${tarrif || ''}`;
  return AxiosCall.get(url);
};

export const getAllPersonsList = async (search_text: string): Promise<any> => {
  const url = `/reference-data/patients/all?search_text=${search_text}`;
  return AxiosCall.get(url);
};

export const getReceipts = async (
  hlp_id: any,
  encounter_id: any
): Promise<any> => {
  const url = `/billing/patient/${hlp_id}/encounter/${encounter_id}/receipts`;
  return AxiosCall.get(url);
};

export const getserviceAuthorization = async (branch_id: any): Promise<any> => {
  const url = `/billing/branch/${branch_id}/service-authorization`;
  return AxiosCall.get(url);
};

export const AddCancelselected = async (
  hlp_id: any,
  encounter_id: any,
  variables: any
): Promise<any> => {
  const url = `/billing/patient/${hlp_id}/encounter/${encounter_id}/cancel-services`;
  return AxiosCall.post(url, variables);
};

export const getPaymentCriteria = async (
  hlp_id: any,
  branch_id: any,
  doctor_id?: any,
  is_appointment?: any,
  appointment_type?: any,
  is_consult?: boolean,
  appointment_date?: any,
  enc_id?: any,
  is_virtual?: any,
  cloud_clinic_branch_id?: any,
  is_review?: any
): Promise<any> => {
  const url = `/billing/patient/${hlp_id}/branch/${branch_id}/payment-criteria/?doctor_id=${
    doctor_id || ''
  }&is_appointment=${is_appointment || ''}&appointment_type=${
    appointment_type || ''
  }&is_consult=${is_consult}&appointment_date=${
    appointment_date || ''
  }&encounter_id=${enc_id || ''}&is_virtual=${
    is_virtual || ''
  }&cloud_clinic_branch_id=${cloud_clinic_branch_id || ''}&is_review=${
    is_review || ''
  }`;
  return AxiosCall.get(url);
};

export const getServiceByEncounter = async ({
  hlp_id,
  branch_id,
  encounter_id,
  is_adjust_amount
}: any): Promise<any> => {
  const url = `/billing/patient/${hlp_id}/branch/${branch_id}/encounter/${encounter_id}/services${
    is_adjust_amount ? '?is_adjust_amount=true' : ''
  }`;
  return AxiosCall.get(url);
};

export const getAllBanksList = async (search_text: string): Promise<any> => {
  // const url = `/reference-data/patients/all?search_text=${search_text}`;
  const url = `/reference-data/bank-names/all?search_text=${search_text}`;
  return AxiosCall.get(url);
};

export const AddServices = async (
  hlp_id: any,
  branch_id: any,
  variables: any
): Promise<any> => {
  const url = `/billing/patient/${hlp_id}/branch/${branch_id}/add-services`;
  return AxiosCall.post(url, variables);
};

export const RefundServices = async (
  hlp_id: any,
  encounter_id: any,
  variables: any
): Promise<any> => {
  const url = `/billing/patient/${hlp_id}/encounter/${encounter_id}/refund-amount`;
  return AxiosCall.post(url, variables);
};

export const searchPerson = async (searchText: any): Promise<any> => {
  const url = `/reference-data/patients/all?search_text=${searchText}`;
  return AxiosCall.get(url);
};

export const searchPatient = async (
  searchText: any,
  branch_id: any
): Promise<any> => {
  const url = `/user/person/all?branch_id=${branch_id}&search_text=${searchText}`;
  return AxiosCall.get(url);
};

export const getAvailableTimeSlots = async (
  doctorId: string,
  branchId: string,
  date: string
): Promise<any> => {
  const url = `/public/doctor/${doctorId}/branch/${branchId}/appointment/available-timings?date=${date}`;
  return AxiosCall.get(url);
};

export const getAppointmentTypes = async (
  doctorId: string,
  branchId: string
): Promise<any> => {
  const url = `public/doctor/${doctorId}/branch/${branchId}/appointment/types`;
  return AxiosCall.get(url);
};

export const checkAppointmentExists = async (data: any): Promise<any> => {
  const url = '/check_duplicte_app_fromdoc';
  return AxiosCall.post(url, data, {withCredentials: true, crossdomain: true});
};

export const CreatebranchPlans = async (data: any): Promise<any> => {
  const url = `/plan/branch/create`;
  return AxiosCall.post(url, data);
};

export const bookAppointment = async (
  hlpid: string,
  data: any
): Promise<any> => {
  const url = `/person/${hlpid}/book-appointment`;
  return AxiosCall.post(url, data);
};
export const rescheduleAppointment = async (
  id: string,
  data: any
): Promise<any> => {
  const url = `/appointment/reschedule/${id}`;
  return AxiosCall.post(url, data);
};
export const getPatientById = async (
  hlp_id: any,
  op_branch_id?: any
): Promise<any> => {
  const url = `/person/${hlp_id}?op_branch_id=${op_branch_id}`;
  return AxiosCall.get(url);
};

export const addPlanNotes = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  data: any,
  template_id: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/notes?template_id=${template_id}`;
  return AxiosCall.post(url, data);
};

export const getPlanNotes = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  template_id: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/notes?template_id=${template_id}`;
  return AxiosCall.get(url);
};

export const getMasterTemplateById = async (template_id: any): Promise<any> => {
  const url = `/template/master-template/${template_id}  `;
  return AxiosCall.get(url);
};

export const addPlanAttachments = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  data: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/attachment/encounter`;
  return AxiosCall.post(url, data);
};

export const deletePlanAttachments = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  type: any,
  id: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/attachment/${type}/${id}`;
  return AxiosCall.delete(url);
};

export const getDoctorsByBranch = async (
  branch_id: any,
  search_text: any
): Promise<any> => {
  const url = `/public/doctor/search?branch_id=${branch_id}&search_text=${
    search_text || ''
  }`;
  return AxiosCall.get(url);
};
export const getVaccinesInformation = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  template_id: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/vaccine?template_id=${template_id}`;
  return AxiosCall.get(url);
};

export const addVaccinesInformation = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  template_id: any,
  data: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/vaccine?template_id=${template_id}`;
  return AxiosCall.post(url, data);
};

export const deleteVaccinesInformation = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  id: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/vaccine/${id}`;
  return AxiosCall.delete(url);
};

export const updateVaccinesInformation = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  vaccine_id: any,
  data: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/vaccine/${vaccine_id}`;
  return AxiosCall.put(url, data);
};

export const getImagingAllData = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  type: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/service/${type}`;
  return AxiosCall.get(url);
};

export const addImagingAttachments = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  data: any,
  type: any,
  service_id: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/plan/attachment/${type}?service_id=${service_id}`;
  return AxiosCall.post(url, data);
};

export const provideVaccine = async (
  encounter_id: any,
  doc_id: any,
  hlp_id: any,
  data: any
): Promise<any> => {
  const url = `/appointment/encounter/${encounter_id}/doctor/${doc_id}/person/${hlp_id}/vaccine/provide`;
  return AxiosCall.post(url, data);
};

export const addImmunizationAttachments = async (
  id: any,
  hlp_id: any,
  data: any
): Promise<any> => {
  const url = `/person/${hlp_id}/immunization/${id}/attachment`;
  return AxiosCall.post(url, data);
};

export const deleteImmunizationAttachments = async (
  id: any,
  hlp_id: any
): Promise<any> => {
  const url = `/person/${hlp_id}/immunization/${id}/attachment`;
  return AxiosCall.delete(url);
};

export const activateModules = async (
  search_text: any,
  practice_id: any,
  branch_id: any
): Promise<any> => {
  const url = `/access-control/modules?search_text=${search_text}&practice_id=${practice_id}&branch_id=${branch_id}`;
  return AxiosCall.get(url);
};

export const agreementsData = async (category: any): Promise<any> => {
  const url = `/agreement/master-variables/${category}`;
  return AxiosCall.get(url);
};

export const agreementsInformation = async (search_text: any): Promise<any> => {
  const url = `/agreement/all?search_text=${search_text}`;
  return AxiosCall.get(url);
};

export const agreementsDataWithId = async (id: any): Promise<any> => {
  const url = `/agreement/${id}`;
  return AxiosCall.get(url);
};

export const createNewAgreement = async (data: any): Promise<any> => {
  const url = `agreement/create`;
  return await AxiosCall.post(url, data);
};

export const updateAgreement = async (id: any, data: any): Promise<any> => {
  const url = `agreement/${id}`;
  return await AxiosCall.put(url, data);
};

export const deleteAgreementById = async (id: any): Promise<any> => {
  const url = `agreement/${id}`;
  return await AxiosCall.delete(url);
};

export const createNewModule = async (data: any): Promise<any> => {
  const url = `/access-control/module`;
  return await AxiosCall.post(url, data);
};

export const getAllScreens = async (
  search_text: any,
  limit: any,
  offset: any
): Promise<any> => {
  const url = `/access-control/screens-by-modules?search_text=${search_text}&limit=${limit}&offset=${offset}`;
  return AxiosCall.get(url);
};

export const assignModules = async (
  id: any,
  menu_section: any,
  search_text: any,
  limit: any,
  offset: any
): Promise<any> => {
  const url = `/access-control/module/${id}?menu_section=${menu_section}&search_text=${search_text}&limit=${limit}&offset=${offset} `;
  return AxiosCall.get(url);
};

export const addNewModuleScreen = async (data: any): Promise<any> => {
  const url = `access-control/screen`;
  return AxiosCall.post(url, data);
};

export const getUsersList = async (
  branch_id: any,
  limit: any,
  offset: any,
  practice_id: any,
  search: any,
  data: any
): Promise<any> => {
  const url = `/user/non-healpha-list/all?branch_id=${branch_id}&limit=${limit}&offset=${offset}&practice_id=${practice_id}&search_text=${search}&role_id=${data}`;
  return AxiosCall.get(url);
};

export const updateAccessControl = async (values: any): Promise<any> => {
  const url = `/access-control/user/authorization`;
  return AxiosCall.put(url, values);
};

export const updateModuleDetails = async (id: any, data: any): Promise<any> => {
  const url = `/access-control/module/${id}`;
  return AxiosCall.put(url, data);
};

export const createNewUser = async (data: any): Promise<any> => {
  const url = `/auth/sign-up/non-healpha-user`;
  return AxiosCall.post(url, data);
};

export const updateScreenDetails = async (id: any, data: any): Promise<any> => {
  const url = `/access-control/screen/${id}`;
  return AxiosCall.put(url, data);
};

export const getScreenByID = async (id: any): Promise<any> => {
  const url = `/access-control/screen/${id} `;
  return AxiosCall.get(url);
};
export const getAccessControlList = async (
  branch_id: any,
  search_text: any,
  limit: any,
  offset: any,
  schedule_id: any
): Promise<any> => {
  const url = `/access-control/user/authorization?branch_id=${branch_id}&search_text=${search_text}&limit=${limit}&offset=${offset}&schedule_id=${schedule_id}`;
  return AxiosCall.get(url);
};

export const getUsers = async (
  branch_id: any,
  limit: any,
  offset: any,
  practice_id: any,
  search: any
): Promise<any> => {
  const url = `/user/non-healpha-user/search?branch_id=${branch_id}&limit=${limit}&offset=${offset}&practice_id=${practice_id}&search_text=${
    search || ''
  }`;
  return AxiosCall.get(url);
};

export const getUserRoles = async (
  limit: any,
  offset: any,
  search: any,
  active: any,
  practiceId: any,
  branchId: any
): Promise<any> => {
  const url = `/reference-data/roles/all?limit=${limit}&offset=${offset}&search_text=${search}&is_active=${active}&practice_id=${practiceId}&branch_id=${branchId}`;
  return AxiosCall.get(url);
};

export const assignUserRoles = async (
  id: any,
  role_id: any,
  data: any
): Promise<any> => {
  const url = `user/${id}/role/${role_id}/assign`;
  return AxiosCall.post(url, data);
};

export const getAllRoles = async (
  branch_id: any,
  search: any,
  limit: any,
  offset: any
): Promise<any> => {
  const url = `/role/list/all?branch_id=${branch_id}&search_text=${search}&limit=${limit}&offset=${offset}`;
  return AxiosCall.get(url);
};

export const updateEncStatus = async (appointment_id: any): Promise<any> => {
  const url = `/appointment/${appointment_id}/encounter-status`;
  return AxiosCall.put(url, {});
};

export const getConsultationItem = async (
  hlp_id: any,
  branch_id: any,
  doctor_id: any,
  appointment_type: any,
  appointment_date: any,
  encounter_id: any,
  patient_category: any,
  is_virtual?: any,
  cloud_clinic_branch_id?: any,
  is_online_review?: any
): Promise<any> => {
  const url = `/billing/patient/${hlp_id}/branch/${branch_id}/consultation-line-item?appointment_type=${
    appointment_type || ''
  }&doctor_id=${doctor_id || ''}&encounter_id=${
    encounter_id || ''
  }&appointment_date=${appointment_date || ''}&is_virtual=${
    is_virtual || ''
  }&cloud_clinic_branch_id=${cloud_clinic_branch_id || ''}&patient_category=${
    patient_category || ''
  }&is_online_review=${is_online_review}`;
  return AxiosCall.get(url);
};

export const qrPaymentCapture = async (
  appointmentId: any,
  data: any
): Promise<any> => {
  const url = `/appointment/${appointmentId}/payment-capture`;
  return AxiosCall.post(url, data);
};

export const updateNewUserDetails = async (
  id: any,
  data: any
): Promise<any> => {
  const url = `/user/profile/${id}`;
  return AxiosCall.put(url, data);
};

export const updateMedicineTakenTiming = async (
  hlp_id: any,
  id: any,
  data: any
): Promise<any> => {
  const url = `/appointment/person/${hlp_id}/medicine-reminder/${id}`;
  return AxiosCall.put(url, data);
};

export const getSessionTimings = async (
  doctor_id: any,
  branch_id: any
): Promise<any> => {
  const url = `/doctor/${doctor_id}/session-timing?branch_id=${branch_id}`;
  return AxiosCall.get(url);
};

export const saveSessionTimings = async (
  doctor_id: any,
  branch_id: any,
  dataValue: any
): Promise<any> => {
  const url = `/doctor/${doctor_id}/branch/${branch_id}/session-timing`;
  return AxiosCall.post(url, dataValue);
};

export const getUserRolesById = async (
  roleId: any,
  moduleId: any
): Promise<any> => {
  const url = `/role/${roleId}?module_id=${moduleId}`;
  return AxiosCall.get(url);
};

export const newRolesCreation = async (data: any): Promise<any> => {
  const url = `/role/create`;
  return AxiosCall.post(url, data);
};

export const updateRoleById = async (id: any, data: any): Promise<any> => {
  const url = `/role/${id}`;
  return AxiosCall.put(url, data);
};

export const SaveMenu = async (data: any): Promise<any> => {
  const url = '/access-control/screen';
  return AxiosCall.post(url, data);
};

export const getUserProfile = async (id: any): Promise<any> => {
  const url = `/user/profile/${id}`;
  return AxiosCall.get(url);
};

export const uploadProfileImage = async (
  id: any,
  data: any,
  type: string
): Promise<any> => {
  const url = `/user/profile-pic/${id}/upload/${type}`;
  return AxiosCall.put(url, data);
};

export const UpdateMenuStructure = async (id: any, data: any): Promise<any> => {
  const url = `/access-control/module/${id}/menu-structure`;
  return AxiosCall.put(url, data);
};

export const getModuleAccess = async (module_id: any): Promise<any> => {
  const url = `/access-control/module-access/${module_id}`;
  return AxiosCall.get(url);
};

// plans

export const createNewPlan = async (data: any): Promise<any> => {
  const url = `/plan/master/create`;
  return AxiosCall.post(url, data);
};
export const getModules = async ({
  branch_id,
  practice_id
}: any): Promise<any> => {
  const url = `/access-control/modules?branch_id=${
    branch_id || ''
  }&practice_id=${practice_id || ''}`;
  return AxiosCall.get(url);
};

export const updateNewPlan = async (
  id: any,
  data: any,
  from_date?: any,
  to_date?: any
): Promise<any> => {
  const url = `plan/master/${id}?from_date=${from_date || ''}&to_date=${
    to_date || ''
  }`;
  return AxiosCall.put(url, data);
};
export const updateBranchPlan = async (id: any, data: any): Promise<any> => {
  const url = `/plan/branch/${id}`;
  return AxiosCall.put(url, data);
};
export const getScheduleIds = async (
  type: any,
  branch_id: any
): Promise<any> => {
  const url = `access-control/schedule-ids?type=${type}&branch_id=${branch_id}`;
  return AxiosCall.get(url);
};

export const getAppointTypes = async (): Promise<any> => {
  const url = `/reference-data/master-appointment-types`;
  return AxiosCall.get(url);
};

export const UpdateSessionTimings = async (
  doctor_id: any,
  work_timing_id: any,
  data: any
): Promise<any> => {
  const url = `/doctor/${doctor_id}/session-timing/${work_timing_id}`;
  return AxiosCall.put(url, data);
};

export const getCountryState = async (countryId: any): Promise<any> => {
  const url = `/reference-data/country-state/all?country_id:${countryId}`;
  return AxiosCall.get(url);
};

export const getSubscription = async (
  branch_id: any,
  practice_id: any
): Promise<any> => {
  const url = `/plan/branch-subscription?branch_id=${
    branch_id || ''
  }&practice_id=${practice_id || ''}`;
  return AxiosCall.get(url);
};

export const getSubscriptionArchive = async (
  branch_id: any,
  practice_id: any
): Promise<any> => {
  const url = `/plan/branch-subscription?branch_id=${
    branch_id || ''
  }&practice_id=${practice_id || ''}&archive_plan=${true}`;
  return AxiosCall.get(url);
};

export const createSubscription = async (data: any): Promise<any> => {
  const url = `/plan/subscription/create`;
  return AxiosCall.post(url, data);
};

export const getCartSubscription = async (data: any): Promise<any> => {
  const url = `/plan/branch-subscription-cart`;
  return AxiosCall.get(url);
};

export const resetPassword = async (data: any): Promise<any> => {
  const url = `/user/reset-password`;
  return AxiosCall.put(url, data);
};

export const getParameterList = async (type: any): Promise<any> => {
  const url = `/master/parameterlists/${type}`;
  return AxiosCall.get(url);
};

export const getSchoolParameterList = async (): Promise<any> => {
  const url = `/master/parameterlists/school`;
  return AxiosCall.get(url);
};

export const getCorporateParameterList = async (): Promise<any> => {
  const url = `/master/parameterlists/corporate`;
  return AxiosCall.get(url);
};

export const getRoles = async (
  branch_id: any,
  practice_id: any,
  id: any
): Promise<any> => {
  const url = `/user/${id}/roles`;
  return AxiosCall.get(url);
};

export const updateRole = async (role_id: any, data: any): Promise<any> => {
  const url = `/user/access-role/${role_id}`;
  return AxiosCall.put(url, data);
};

export const createParameterLists = async (
  data: any,
  type: any
): Promise<any> => {
  const url = `/master/parameter/${type}`;
  return AxiosCall.post(url, data);
};

export const getBranchParameterList = async (
  branch_id: any,
  type: any
): Promise<any> => {
  const url = `/master/branch/${branch_id}/parameters/${type}`;
  return AxiosCall.get(url);
};

export const updateParameterListById = async (
  branch_id: any,
  data: any,
  type: any
): Promise<any> => {
  const url = `/master/parameter/${type}/${branch_id}`;
  return AxiosCall.put(url, data);
};

export const getDoctorAppointmentPrices = async (
  doctor_id: any,
  branch_id: any
): Promise<any> => {
  const url = `/doctor/${doctor_id}/branch/${branch_id}/consultation-fees`;
  return AxiosCall.get(url);
};

export const getDoctorAppointmentPricesList = async (
  doctor_id: any,
  practice_id: any,
  branch_id: any
): Promise<any> => {
  const url = `/doctor/${doctor_id}/practice/${practice_id}/branch/${branch_id}/appointment-prices`;
  return AxiosCall.get(url);
};

export const updateDoctorAppointmentPrices = async (
  doctor_id: any,
  branch_id: any,
  data: any
): Promise<any> => {
  const url = `doctor/${doctor_id}/branch/${branch_id}/prices`;
  return AxiosCall.put(url, data);
};

export const getSchedularData = async ({
  branch_id,
  date
}: any): Promise<any> => {
  const url = `/appointment/schedular/all?branch_id=${branch_id || ''}&date=${
    date || ''
  }`;
  return AxiosCall.get(url);
};

export const getBranchTimings = async (
  practice_id: any,
  branch_id: any
): Promise<any> => {
  const url = `practice/${practice_id}/branch/${branch_id}`;
  return AxiosCall.get(url);
};

export const getVirtualClinicBranches = async (
  searchText: any
): Promise<any> => {
  const url = `/public/all-branches?is_virtual_branches=${true}&is_self_service=${true}&search_text=${searchText}`;
  return AxiosCall.get(url);
};

export const getAllMicroTemplate = async (
  searchText: any,
  limit: any,
  offset: any
): Promise<any> => {
  const url = `template/micro-master/all?search_text=${
    searchText || ''
  }&limit=${limit || ''}&offset=${offset || ''}`;
  return AxiosCall.get(url);
};

export const getQRCode = async (path: any): Promise<any> => {
  const url = `public/validate-qrcode?path=${path}`;
  return AxiosCall.get(url);
};

export const getAllLabPharmacyOrders = async (
  type: any,
  hlp_id: any,
  vc_id: any
): Promise<any> => {
  const url = `/pharmacy/all-orders?type=${type}&hlp_id=${hlp_id}&virtual_clinic_branch_id=${vc_id}`;
  return AxiosCall.get(url);
};

export const getLabPharmacyOrderById = async (order_id: any): Promise<any> => {
  const url = `pharmacy/order/${order_id}`;
  return AxiosCall.get(url);
};

export const getDoctorAppointmentCounts = async (): Promise<any> => {
  const url = `/appointment/today-count/all`;
  return AxiosCall.get(url);
};

export const getDoctorLists = async (text: any): Promise<any> => {
  const url = `/doctor/list/all?search_text=${text}`;
  return AxiosCall.get(url);
};

export const getBranchLists = async (doc_id: any): Promise<any> => {
  const url = `/doctor/${doc_id}/branch/all`;
  return AxiosCall.get(url);
};

export const getPersonTimelineLists = async (
  healpha_id: any,
  search_text: any
): Promise<any> => {
  const url = `/appointment/person-timeline/${healpha_id}?is_not_owner=${true}&search_text=${search_text}`;
  return AxiosCall.get(url);
};

export const updateEncounter = async (data: any): Promise<any> => {
  const url = `/appointment/user-encounter-access`;
  return AxiosCall.put(url, data);
};

export const getDoctorPatients = async (
  doc_id: any,
  search: any
): Promise<any> => {
  const url = `/doctor/${doc_id}/patient/all?search_text=${search}`;
  return AxiosCall.get(url);
};

export const getPersonTimelineData = async (healpha_id: any): Promise<any> => {
  const url = `/appointment/person-timeline/${healpha_id}`;
  return AxiosCall.get(url);
};

export const deleteForumQuestionAndReply = async (
  type: any,
  id: any,
  data: any
): Promise<any> => {
  const url = `/public/forums/${type}/${id}`;
  return AxiosCall.deleteBody(url, data);
};

export const getTabAccessData = async (
  branch_id: any,
  module_id: any
): Promise<any> => {
  const url = `/users/tab/access?branch_id=${branch_id}&module_id=${module_id}`;
  return AxiosCall.get(url);
};

export const getGraphData = async (
  branch_id: any,
  doctor_id: any,
  filter: any
): Promise<any> => {
  const url = `/bi/appointment/dashboard/counts-revenue?branch_id=${branch_id}&doctor_id=${doctor_id}&filter=${filter}`;
  return AxiosCall.get(url);
};
