import { LinearProgress } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Switch } from 'react-router-dom';
import CustomRoute from 'src/constants/route/CustomRoute';

const Portal = (props: any): ReactElement => {
  const PortalRoute = CustomRoute.portal;

  const renderPortalRoutes = () => {
    const returnRoute = CustomRoute.basicRoute(PortalRoute, null);
    return (
      <React.Suspense fallback={<LinearProgress />}>
        <Switch>
          {returnRoute}
        </Switch>
      </React.Suspense>
    );
  };
  return renderPortalRoutes();
};

export default Portal;
