import {ADMIN_SETTERS, HIDE_LEFT_NAV} from '../actions/Index';
import {AdminCompanyOnBoardType} from '../ReducerTypes/ReducerTypes';

const initAdmin: {hideNav: boolean; companyOnboard: AdminCompanyOnBoardType} = {
  hideNav: false,
  companyOnboard: {
    orgUuid: '',
    onboardStatus: '',
    status: '',
    mode: '',
    unsaved: false
  }
};

export default function (state = initAdmin, action: any = {}): any {
  const {type, payload} = action;
  if (type === HIDE_LEFT_NAV) {
    return {
      ...state,
      hideNav: payload
    };
  } else if (type === ADMIN_SETTERS.SET_COMPANY_ONBOARD_ORG) {
    return {
      ...state,
      companyOnboard: {...state.companyOnboard, ...payload}
    };
  } else {
    return state;
  }
}
