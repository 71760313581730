import React from "react";
import './NotFoundComponent.scss';

const NotFoundComponent = (props: any): React.ReactElement => {
  return (
    <div className="not-found-component-wrapper">
      {props?.label}
    </div>
  );
}

export default NotFoundComponent;