import palette from './palette';

export default {
  fontFamily: 'Nunito Sans',
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '2.25rem'
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.875rem'
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.5rem'
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.25rem'
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1rem'
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '0.875rem'
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '1rem',
    fontFamily: 'Nunito Sans',
    fontWeight: 600
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '0.875rem'
  },
  body1: {
    color: palette.text.primary,
    fontSize: '1rem',
    lineHeight: 1.125,
    letterSpacing: '0.00938rem'
  },
  body2: {
    color: palette.text.secondary,
    fontSize: '1rem',
    lineHeight: 1.125,
    letterSpacing: '0.00938rem'
  },
  button: {
    color: palette.text.primary,
    fontSize: '0.875rem'
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '0.6875rem',
    letterSpacing: '0.33px'
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '0.6875rem',
    fontWeight: 500,
    letterSpacing: '0.33px'
  }
};
