/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react';
import {Paper} from '@material-ui/core';
import {ViewState} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  MonthView,
  Appointments,
  AppointmentTooltip
} from '@devexpress/dx-react-scheduler-material-ui';
import {useTranslation} from 'react-i18next';
import Icons from '@components/system/MyIcons';
// import {appointmentsData} from './demo-data/month-appointments';
import {getAppointmentDetails} from '@shared/services/PublicService';
import AuthUtils from 'src/utils/AuthUtils';
import moment from 'moment';
import AppointmentCard from '../Appointment/AppointmentCard';
import './CalendarDayWise.scss';
import NewAppointmentBookingsComponent from '../Appointment/AppointmentBooking';
import {
  Dialog,
  DialogContent,
  Grid,
  useTheme,
  useMediaQuery,
  IconButton,
  Typography
} from '@material-ui/core';
import AppointmentDetails from '../../Dashboard/AppointmentScreen/AppointmentDetailsComponent/AppointmentDetails/AppointmentDetails';

const layoutStyling = {
  width: '25rem',
  top: {
    xs: '0',
    lg: '15.625rem !important'
  },
  right: {
    xs: '0',
    lg: '15rem !important'
  },
  left: 'auto !important'
};
const schedularStyles = {width: '100%', boxShadow: 'none', height: '75vh'};

const currentDate = moment();
const dateToday = currentDate.date();

const CalendarMonthWise = ({
  doctors,
  selectedDate
}: any): React.ReactElement => {
  const theme = useTheme();
  const {t} = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [appointments, setAppointments] = useState<any>([]);
  const [SelectedDate, setSelectedDate] = useState<any>(null);
  const [
    showNewAppointmentBookingUpPopup,
    setShowNewAppointmentBookingUpPopup
  ] = useState<boolean>(false);
  const [showViewMore, setShowViewMore] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [appointmentDetails, setAppointmentDetails] = useState<any>({});
  const [clickedStatus, setClickedStatus] = useState('');
  const [appointmentId, setAppointmentId] = useState<any>('');
  const [prescription, setPrescription] = React.useState('');
  const handlePatientDetails = (data: any) => {
    setAppointmentId(data.id);
  };

  const doctor_id = doctors[0]?.non_healpha_user_id;
  const branch_id = AuthUtils.getMenuBranchId();
  const dateCurrent = `${currentDate.year()}-${
    currentDate.month() + 1
  }-${dateToday}`;

  const init = (doc_id: any) => {
    getAppointmentDetails({
      doctor_id: doc_id ? doc_id : doctor_id,
      branch_id: branch_id,
      date: new Date(currentDate.year(), currentDate.month(), 1),
      appointment_checked: '',
      search_text: '',
      end_date: new Date(currentDate.year(), currentDate.month() + 1, 0),
      total_appts_req: true
    }).then(res => {
      const dup = new Set();
      const filtered = res?.data?.appointments.filter((el: any) => {
        const duplicate = dup.has(
          moment(el?.appointment?.appointment_date_start).format('YYYY-MM-DD')
        );
        dup.add(
          moment(el?.appointment?.appointment_date_start).format('YYYY-MM-DD')
        );
        return !duplicate;
      });
      let arr: any = [];
      filtered.map((i: any) => {
        const todayFilter = res?.data?.appointments.filter((x: any) => {
          return moment(x?.appointment?.appointment_date_start).isSame(
            i?.appointment?.appointment_date_start,
            'day'
          );
        });
        const todayFilterTo3 = todayFilter
          .map((fil: any) => {
            return {
              title: fil?.appointment?.person_details?.full_name,
              startDate: new Date(fil?.appointment?.appointment_date_start),
              endDate: new Date(fil?.appointment?.appointment_date_end),
              id: fil?.appointment?.id,
              location: 'Appointments',
              ...fil
            };
          })
          .slice(0, 3);
        if (todayFilter.length > 3) {
          arr = [
            ...arr,
            ...todayFilterTo3?.slice(0, 2),
            {
              title: `${t('VIEW_MORE')}(${todayFilter.length - 2})`,
              startDate: new Date(
                todayFilterTo3[0]?.appointment?.appointment_date_start
              ),
              endDate: new Date(
                todayFilterTo3[0]?.appointment?.appointment_date_end
              ),
              id: 3,
              location: 'Appointments',
              isViewMore: true
            }
          ];
        } else {
          arr = [...arr, ...todayFilterTo3];
        }
        return i;
      });
      setAppointments(arr || []);
    });
  };
  const initListener = () => {
    window.addEventListener('initListener', (e: any) => {
      const doc_id = e.detail.doc_id;
      init(doc_id);
    });
    return () => {
      window.removeEventListener('initListener', () => {
        init('');
      });
    };
  };
  useEffect(initListener, []);
  const initLoad = () => {
    init('');
  };
  useEffect(initLoad, [doctor_id]);

  const closeModal = () => {
    window.addEventListener('closeModal', () => {
      setVisible(false);
    });
    return () => {
      window.removeEventListener('closeModal', () => {
        setVisible(false);
      });
    };
  };
  useEffect(closeModal, []);

  const openNewAppointmentBookingPopup = (): void => {
    setShowNewAppointmentBookingUpPopup(true);
  };

  const closeNewAppointmentBookingPopup = (): void => {
    setShowNewAppointmentBookingUpPopup(false);
  };

  const openShowViewMore = (): void => {
    setShowViewMore(true);
  };

  const closeShowViewMore = (): void => {
    setShowViewMore(false);
  };

  const renderNewAppointmentBookingPopup = (): React.ReactElement => {
    return (
      <NewAppointmentBookingsComponent
        branchId={branch_id}
        open={showNewAppointmentBookingUpPopup}
        closeNewBookingsPopup={closeNewAppointmentBookingPopup}
        is_clinical_dashboard={true}
        selectedDate={SelectedDate}
        docId={doctor_id}
      />
    );
  };

  const Appointment = ({
    children,
    style,
    onClick,
    onDoubleClick,
    ...restProps
  }: any) => {
    const onPress = (e: any) => {
      if (restProps?.data?.isViewMore) {
        setAppointmentDetails(restProps?.data);
        openShowViewMore();
      } else {
        onClick(e);
      }
    };
    return (
      <Appointments.Appointment
        {...restProps}
        onClick={onPress}
        // onDoubleClick={onPress}
        style={{
          ...style,
          backgroundColor: doctors?.[0]?.color || ''
        }}>
        {children}
      </Appointments.Appointment>
    );
  };

  const TimeTableCell = ({...restProps}: any) => {
    const isBefore = moment(restProps.startDate).isBefore();
    const isSameDay = moment(restProps.startDate).isSame(moment(), 'day');
    return (
      <MonthView.TimeTableCell
        className={isBefore && !isSameDay ? 'cell cell-disable' : 'cell'}
        onClick={() => {
          if (isBefore && !isSameDay) return;
          setSelectedDate(restProps.startDate);
          openNewAppointmentBookingPopup();
        }}
        {...restProps}
      />
    );
  };

  const Layout = ({children, appointmentData, ...restProps}: any) => {
    return (
      <AppointmentTooltip.Layout
        PaperProps={{sx: layoutStyling}}
        className="w-100"
        {...restProps}
      />
    );
  };

  const renderCloseIcon = (): React.ReactElement => {
    return (
      <IconButton onClick={closeShowViewMore} className="close-icon">
        <Icons.CloseBlackIcon />
      </IconButton>
    );
  };

  const renderTitle = (): React.ReactElement => {
    return (
      <Typography className="popup-title">
        {moment(appointmentDetails.startDate).format('dddd, MMMM Do')}
      </Typography>
    );
  };

  const renderContent = (): React.ReactElement => {
    return (
      <Grid className="view-more-grid">
        <AppointmentDetails
          clickedStatus={clickedStatus}
          setClickedStatus={setClickedStatus}
          handlePatientDetails={handlePatientDetails}
          selectedDate={moment(appointmentDetails?.startDate)?.format(
            'YYYY-MM-DD'
          )}
          branchId={branch_id}
          docId={doctor_id}
          isFromClinicalDashboard={true}
          isFromViewMore={true}
          handleAppointmentDetails={() => null}
          setAppointmentId={setAppointmentId}
          appointmentId={appointmentId}
          setShowPdfFileDialog={() => null}
          setPrescription={setPrescription}
          prescription={prescription}
          setShowBackground={() => null}
          setShowProvideVaccinePopUp={() => null}
          setVaccineData={() => null}
        />
      </Grid>
    );
  };

  const renderViewMoreCard = () => {
    return (
      <Grid className="view-more-wrapper">
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth="sm"
          open={showViewMore}
          onClose={closeShowViewMore}
          aria-labelledby="view-more-pop-up"
          aria-describedby="view-more-bookings"
          classes={{paper: 'view-more-popup'}}>
          <DialogContent className="view-more-content">
            {renderTitle()}
            {renderCloseIcon()}
            {renderContent()}
          </DialogContent>
        </Dialog>
      </Grid>
    );
  };

  const Content = ({children, appointmentData, ...restProps}: any) => {
    return (
      <div className="appointment-view-card">
        <AppointmentCard
          appointmentData={appointmentData}
          selectedDate={selectedDate}
        />
      </div>
    );
  };

  return (
    <Paper sx={schedularStyles} className="monthly-scheduler-wrapper">
      {showNewAppointmentBookingUpPopup && renderNewAppointmentBookingPopup()}
      <Scheduler data={appointments}>
        <ViewState currentDate={dateCurrent} />
        <MonthView timeTableCellComponent={TimeTableCell} />
        <Appointments appointmentComponent={Appointment} />
        <AppointmentTooltip
          contentComponent={Content}
          layoutComponent={Layout}
          visible={visible}
          onVisibilityChange={setVisible}
        />
        {showViewMore && renderViewMoreCard()}
        {/* <ViewMoreCard appointmentData={appointmentDetails} /> */}
      </Scheduler>
    </Paper>
  );
};

export default CalendarMonthWise;
