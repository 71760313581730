import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as VideoIconSvg} from '@assets/images/healpha/VideoCall.svg';

const VideoIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={VideoIconSvg}
      viewBox="0 0 21 19.683"
    />
  );
};

export default VideoIcon;
