import React from 'react';
import {IconButton, InputAdornment, TextField} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';

import {
  DEFAULT_WHITE_COLOR,
  BORDER_TERTIARY_COLOR,
  GET_8_PIXEL_BOX
} from 'src/style/variable';

const textFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  border: `1px solid ${BORDER_TERTIARY_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.5),
  opacity: 1,
  fontSize: '1.2rem'
};

interface FormPasswordFieldProps {
  name: string;
  callback?: any;
  onClickCallBack: any;
  OnMousePDownCallBack: any;
  values: any;
  Icon?: any;
  StartAdornmentIcon?: any;
  placeholder?: any;
  adornmentPosition?: string;
  idB?: string;
  textFieldProps1?: any;
}

const FormPasswordField = (
  props: FormPasswordFieldProps
): React.ReactElement => {
  const {
    idB,
    name,
    values,
    Icon,
    callback,
    placeholder,
    adornmentPosition,
    onClickCallBack,
    OnMousePDownCallBack,
    textFieldProps1
  } = props;

  return (
    <TextField
      fullWidth
      sx={textFieldProps1 ? textFieldProps1 : textFieldProps}
      placeholder={placeholder}
      variant="outlined"
      id={name}
      name={name}
      size="medium"
      onChange={callback}
      value={values[name] || ''}
      type={
        values.showPassword
          ? 'text'
          : name === 'password' || name === 'confirmPassword'
          ? 'password'
          : 'text'
      }
      InputProps={{
        startAdornment: adornmentPosition === 'start' && (
          <InputAdornment position={adornmentPosition}>
            <Icon />
          </InputAdornment>
        ),
        endAdornment: (name === 'password' || name === 'confirmPassword') && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onClickCallBack}
              id={idB}
              onMouseDown={OnMousePDownCallBack}
              edge="end">
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default FormPasswordField;
