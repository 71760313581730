import {combineReducers} from 'redux';
import AuthReducer from './AuthReducer';
import {reducer as reduxForm} from 'redux-form';
import CommonReducers from './CommonReducers';
import AdminReducers from './AdminReducers';
import AdvisorReducers from './AdvisorReducers';
import SelfServiceReducers from './SelfServiceReducers';

const appReducer = combineReducers({
  form: reduxForm,
  auth: AuthReducer,
  common: CommonReducers,
  admin: AdminReducers,
  advisor: AdvisorReducers,
  selfServiceData: SelfServiceReducers
});

// Meant for any global state changes (if any)
const rootReducer = (state: any, action: any): any => {
  return appReducer(state, action);
};

export default rootReducer;
