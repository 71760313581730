import {
  CLEAR_USER_INFO,
  SET_BRANCH_ID,
  SET_USER_INFO,
  SET_DOCTOR_ID,
  SET_CLIENT_INFO,
  SET_ADVISOR_INFO,
  SET_ORG_ID,
  SET_CLIENT_FEATURES,
  SET_BRANCH_INFO,
  SET_DOCTOR_INFO,
  SNACKBAR_ACKNOWLEDGEMENT,
  SET_WHITELABLING_INFO,
  SET_CONTACTDETAILS_INFO,
  SET_USER_DETAILS
} from '../actions/Index';

const initCommon = {
  userData: {},
  branchId: '',
  practiceId: '',
  orgId: '',
  onboardingStatus: '',
  clientUserId: '',
  doctorId: '',
  clientInfo: '',
  branchInfo: '',
  whitelablingInfo: '',
  contactInfo: '',
  adminId: 0,
  advisorId: '',
  advisorUserId: '',
  advisorInfo: '',
  primaryColor: '',
  clientFeatures: [],
  doctorData: {},
  snackBarEnabled: false,
  snackBarMessage: '',
  snackBarType: '',
  userDetails: {}
};

export default function (
  state = initCommon,
  action: any = {}
): typeof initCommon {
  const {type, payload, primaryColor} = action;
  // change it to switch if more than 3 state
  switch (type) {
    case SET_USER_INFO:
      return {
        ...state,
        userData: payload,
        primaryColor
      };
    case SET_BRANCH_ID:
      return {
        ...state,
        branchId: payload.branchId,
        practiceId: payload.practiceId
      };
    case SET_ORG_ID:
      return {
        ...state,
        orgId: payload.orgId
      };
    case SET_CLIENT_INFO:
      return {
        ...state,
        clientInfo: payload
      };
    case SET_BRANCH_INFO:
      return {
        ...state,
        branchInfo: payload
      };
    case SET_WHITELABLING_INFO:
      return {
        ...state,
        whitelablingInfo: payload
      };
    case SET_CONTACTDETAILS_INFO:
      return {
        ...state,
        contactInfo: payload
      };
    case SET_DOCTOR_ID:
      return {
        ...state,
        doctorId: payload.doctorId
      };
    case SET_ADVISOR_INFO:
      return {
        ...state,
        advisorInfo: payload
      };
    case CLEAR_USER_INFO:
      return {
        ...initCommon
      };
    case SET_CLIENT_FEATURES:
      return {
        ...state,
        clientFeatures: payload
      };
    case SET_DOCTOR_INFO:
      return {
        ...state,
        doctorData: payload
      };
    case SNACKBAR_ACKNOWLEDGEMENT:
      return {
        ...state,
        snackBarEnabled: payload.snackBarEnabled,
        snackBarMessage: payload.snackBarMessage,
        snackBarType: payload.snackBarType
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: payload
      };
    default:
      return state;
  }
}
