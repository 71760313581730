/* eslint-disable camelcase */
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Avatar, Button, Divider, Grid, Typography} from '@material-ui/core';
import _ from 'lodash';

import CommonUtils from 'src/utils/CommonUtils';
import {
  ConnectButtonAppointmentTypes,
  VitalButtonAppointmentTypes,
  AppointmentTypes,
  ConsultNowButtonShowWhen,
  ConsultNowButtonForBooked,
  EnterVitalsButton
} from 'src/constants/SystemConstants';
import {DoctorSpecializations} from 'src/constants/FormFields';
import {statusColor} from 'src/constants/statusColors';
import ConnectNowNew from './ConnectNow/ConnectNowNew';
import NewPersonVitalsComponent from '@components/system/Dialogs/NewPersonVitals/NewPersonVitalsComponent';
import MasterTemplatesComponent from '../../Standalone/PatientDetailsStandalone/MasterTemplates/MasterTemplatesComponent';
import CommonConfirmationBoxComponent from '@components/system/Dialogs/CommonConfirmationBox/CommonConfirmationBoxComponent';
import NavRoutes from 'src/constants/route/NavRoutes';
import Icons from '@components/system/MyIcons';
import ViewPatientHistoryIcon from '@assets/images/healpha/viewPatientHistoryIcon.png';

import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {createEncounter} from '@shared/services/CommonService';

import {addVitalsAnswers, getBMIValue} from '@shared/services/TemplateService';
import AddVitals from '../../PatientObservations/Observations/AddVitals/AddVitals';
import AuthUtils from 'src/utils/AuthUtils';
import './PatientDetails.scss';
import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';

const PatientDetails = ({
  person_details,
  doctor_details,
  branch_id,
  appointment_type,
  appointment_status,
  doc_id,
  encounterId,
  description,
  appointmentTypeStatus,
  docName,
  version,
  runOnClick,
  appointmentId,
  templateId,
  isFromVirtualClinic,
  isFromPersonWeb,
  specialization,
  isVirtualAppointment,
  virtualAppointmentBranchId,
  isFromNurseDashboard
}: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const [openConnectNow, setOpenConnectNow] = React.useState(false);
  const [openMasterTemplates, setOpenMasterTemplates] =
    React.useState<boolean>(false);
  const [showVitals, setShowVitals] = React.useState<boolean>(false);
  const [isFromHistory, setIsFromHistory] = React.useState<boolean>(false);

  const bgColor = statusColor.filter(item => item.name == appointment_status);
  const newDotColor = bgColor[0]?.dotColor;
  const BASE_ROUTE_USER = `${BASE_ROUTE_PORTAL}/user`;

  const isStandAloneDoctor = CommonUtils.checkIsStandAloneDoctor();
  const isNurse = CommonUtils.checkIsNurse();
  const isAdminNurseDashboard = CommonUtils.checkIsAdminNurseDashboard();

  const [bmiVal, setBMIVal] = React.useState('');

  const [showVitalsPopUp, setShowVitalsPopUp] = React.useState<boolean>(false);
  const [values, setValues]: any = React.useState({
    height: '',
    weight: '',
    bmi: '',
    pulse_rate: '',
    oxygen_saturation: '',
    pefr: '',
    respiratory_rate: '',
    temperature: '',
    systolic: '',
    diastolic: '',
    notes: ''
  });
  const [openCommonConfirmationBox, setOpenCommonConfirmationBox] =
    React.useState<boolean>(false);

  const closeConnectNow = (openVideoCall?: boolean): void => {
    setOpenConnectNow(false);

    if (openVideoCall && typeof openVideoCall === 'boolean') {
      let data: any = {};
      if (isFromVirtualClinic) {
        data = {
          appointment_id: `${encounterId}_${version}`,
          id: person_details?.hlpid,
          name: person_details?.full_name,
          opponent_id: doc_id,
          opponent_name: doctor_details?.full_name,
          templateId: templateId,
          branchId: branch_id
        };
      } else {
        data = {
          appointment_id: `${encounterId}_${version}`,
          id: doc_id,
          name: docName,
          opponent_id: person_details.hlpid,
          opponent_name: person_details?.full_name,
          templateId: templateId,
          branchId: branch_id
        };

        if (isVirtualAppointment) {
          data.virtual_clinci_branch = virtualAppointmentBranchId;
        }
      }
      window.parent.postMessage(data, '*');
    }
  };

  const openVitals = (): void => {
    setShowVitals(true);
  };

  const handlestatus = () => {
    const handlingDisplay = new CustomEvent('handlingDisplay', {});
    window.dispatchEvent(handlingDisplay);
  };
  const closeVitals = (): void => {
    setShowVitals(false);
    (isNurse || isAdminNurseDashboard) && handlestatus();
  };

  const handleOpenConnectNow = () => {
    if (appointmentTypeStatus === AppointmentTypes.online) {
      setOpenConnectNow(true);
    } else {
      if (encounterId) {
        if (templateId) {
          history.push(NavRoutes.PUBLIC.OBSERVATIONS);
        } else {
          setOpenMasterTemplates(true);
        }
      } else {
        setOpenCommonConfirmationBox(true);
      }
    }
  };

  const goToPatientHistory = () => {
    history.push({
      pathname: `/${BASE_ROUTE_USER}/${NavRoutes.PUBLIC.VIEW_PATIENT_HISTORY}`,
      state: {
        isFromVirtualClinic: isFromVirtualClinic
      }
    });
  };

  const closeCallBackAction = (): void => {
    setOpenMasterTemplates(false);
    setIsFromHistory(false);
  };

  const renderConnectNow = (): React.ReactElement => {
    return (
      <ConnectNowNew
        open={openConnectNow}
        closeConnectNow={closeConnectNow}
        runOnClick={runOnClick}
        appointmentId={appointmentId}
      />
    );
  };

  const renderMasterTemplatesPopup = (): React.ReactElement => {
    return (
      <MasterTemplatesComponent
        open={openMasterTemplates}
        closeMasterTemplates={closeCallBackAction}
        branch_id={branch_id}
        isFromHistory={isFromHistory}
      />
    );
  };

  const renderVitals = (): React.ReactElement => {
    return (
      <NewPersonVitalsComponent
        open={showVitals}
        person_details={person_details}
        doc_id={doc_id}
        template_id={templateId}
        encounterId={encounterId}
        closeAppointmentConfirmationPopup={closeVitals}
      />
    );
  };

  const renderAvatar = (): React.ReactElement => {
    return (
      <Grid item xs={12} md={1} className="profile-icon-grid">
        <Avatar
          alt=""
          src={
            isFromPersonWeb
              ? CommonUtils.getFullImageUrl(doctor_details?.person_image)
              : CommonUtils.getFullImageUrl(person_details?.person_image)
          }
          className="profile-icon"
        />
      </Grid>
    );
  };

  const renderMobileNumber = (phoneNumber: string): React.ReactElement => {
    return (
      <Grid item className="phone-number">
        <Icons.CallDarkIcon /> {phoneNumber}
      </Grid>
    );
  };

  const renderPatientDetailedInfo = (): React.ReactElement => {
    let age = person_details?.age.replace('Y', 'Years');
    age = age?.replace('M', 'Months');
    age = age?.replace('D', 'Days');
    return (
      <Grid item xs={10} md={10} className="name">
        <Grid display="flex" justifyContent="space-between">
          <Grid item display="flex">
            <Typography className="text">
              {person_details?.full_name}
            </Typography>
            <Typography className="status-type">
              {appointmentTypeStatus ? appointmentTypeStatus : 'online'}
            </Typography>
          </Grid>
          <Grid item textAlign="end" className="appointment_status">
            {appointment_status}{' '}
            <Icons.EllipseIcon style={{color: newDotColor}} fontSize="small" />
          </Grid>
        </Grid>
        <Grid item xs={4} className="category">
          {appointment_type}
        </Grid>
        <Grid
          item
          container
          xs={12}
          className="email-gender-phone"
          alignItems="center">
          {person_details?.email && (
            <Grid
              item
              alignItems="center"
              justifyContent="center"
              className="person-email">
              <Icons.EmailIcon /> {person_details?.email}
              <span style={{padding: '0 1rem'}}>|</span>
            </Grid>
          )}
          <Grid item className="age">
            <Icons.GenderDarkIcon /> {person_details?.gender},
            <span style={{padding: '0 0.2rem'}}>
              {person_details?.age || age}
            </span>
            <span style={{padding: '0 1rem'}}>|</span>
          </Grid>
          {person_details?.phone_no &&
            renderMobileNumber(person_details?.phone_no)}
        </Grid>
      </Grid>
    );
  };

  const renderDoctorDetailedInfo = (): React.ReactElement => {
    const branchDetails = doctor_details?.branches?.filter(
      (i: any) => i?.branch_id === branch_id
    );
    return (
      <Grid item xs={10} md={10} className="name">
        <Grid display="flex" justifyContent="space-between">
          <Grid item display="flex">
            <Typography className="text">
              {doctor_details?.salutation} {doctor_details?.full_name}
            </Typography>
            <Typography className="status-type">
              {appointmentTypeStatus ? appointmentTypeStatus : 'online'}
            </Typography>
          </Grid>
          <Grid item textAlign="end" className="appointment_status">
            {appointment_status}{' '}
            <Icons.EllipseIcon style={{color: newDotColor}} fontSize="small" />
          </Grid>
        </Grid>
        <Grid item xs={isFromPersonWeb ? 12 : 4} className="category">
          <p> {specialization}</p>
          {isFromPersonWeb && (
            <p className="doctor-phone-no">
              {' '}
              {renderMobileNumber(doctor_details?.phone_no)}
            </p>
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          className="email-gender-phone"
          alignItems="center">
          {
            <Grid
              item
              alignItems="center"
              justifyContent="center"
              className="branch-name-grid">
              {branchDetails?.[0]?.branch_name}
            </Grid>
          }
        </Grid>
      </Grid>
    );
  };

  // handleVitals from Observations

  const handleBMICalculation = (height: any, weight: any) => {
    getBMIValue(encounterId, doc_id, person_details?.hlpid, height, weight)
      .then(res => {
        setBMIVal(res?.data?.bmi_value);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };
  const closeAddVitalsPopUp = (): void => {
    setValues({
      height: '',
      weight: '',
      bmi: '',
      pulse_rate: '',
      oxygen_saturation: '',
      pefr: '',
      respiratory_rate: '',
      temperature: '',
      systolic: '',
      diastolic: '',
      notes: ''
    });
    setShowVitalsPopUp(false);
  };

  const closeConfirmationPopup = (callBackAction: boolean): void => {
    setOpenCommonConfirmationBox(false);

    if (callBackAction) {
      const payload = {
        appointment_type: appointment_type
      };
      createEncounter(appointmentId, payload)
        .then(res => {
          AuthUtils.setEncId(res?.data?.enc_id);
          if (templateId) {
            history.push(NavRoutes.PUBLIC.OBSERVATIONS);
          } else {
            setOpenMasterTemplates(true);
          }
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    }
  };

  const handleAddVitalsAnswer = (data: any) => {
    const temp = templateId ? templateId : true;
    addVitalsAnswers(encounterId, doc_id, person_details?.hlpid, data, temp)
      .then(res => {
        enqueueSnackbar(res?.message, SnackBarConfig('s'));
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const renderAddVitalsPopUp = (): React.ReactElement => {
    return (
      <AddVitals
        open={showVitalsPopUp}
        closeAddVitalsPopUp={closeAddVitalsPopUp}
        handleBMICalculation={handleBMICalculation}
        bmiVal={bmiVal}
        setValues={setValues}
        values={values}
        handleAddVitalsAnswer={handleAddVitalsAnswer}
      />
    );
  };

  const renderCommonConfirmationBox = (): React.ReactElement => {
    return (
      <CommonConfirmationBoxComponent
        open={openCommonConfirmationBox}
        closeCommonConfirmationPopup={closeConfirmationPopup}
        title={t('Has the patient arrived for consultation ? ')}
        message={t(
          `Clicking on "Yes" will generate an encounter to continue the consultation.`
        )}
        yesLabel={t('Yes')}
        noLabel={t('Cancel')}
      />
    );
  };

  const renderHistoryConnectButtons = (): React.ReactElement => {
    const enterVitals = EnterVitalsButton?.includes(
      appointment_status?.toLowerCase()
    );
    const consultNowButton = ConsultNowButtonShowWhen?.includes(
      appointment_status?.toLowerCase()
    );

    const consultNowForBookedStatus = ConsultNowButtonForBooked?.includes(
      appointment_status?.toLowerCase()
    );

    const conditionNotForNormalDoc = isStandAloneDoctor || isFromVirtualClinic;

    const ifNotNormalShowBookedFor =
      conditionNotForNormalDoc && consultNowForBookedStatus;

    const finalCondition = conditionNotForNormalDoc
      ? ifNotNormalShowBookedFor
      : consultNowButton;
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        className={
          encounterId && enterVitals && consultNowButton && isFromVirtualClinic
            ? 'patient-history-connect-now-grid'
            : 'align-button-center patient-history-connect-now-grid'
        }>
        <Grid
          container
          item
          xs={12}
          md={6}
          paddingTop={isFromVirtualClinic && '0rem !important'}>
          <Button
            id={'prescription_history'}
            variant="outlined"
            className={'patientHistory history-button'}
            onClick={goToPatientHistory}
            startIcon={
              <p className="icon">
                <img
                  src={ViewPatientHistoryIcon}
                  alt=""
                  className="icon-image"
                />
              </p>
            }>
            {t('DOCTOR_DASHBORD_VIEW_PATIENT_HISTORY')}
          </Button>
        </Grid>
        {finalCondition && !isFromVirtualClinic && (
          <Grid item xs={12} md={6}>
            <Button
              id={'connect_now_button'}
              variant="contained"
              color="primary"
              onClick={handleOpenConnectNow}
              className="patientHistory">
              {appointmentTypeStatus === AppointmentTypes.online
                ? t('DOCTOR_DASHBORD_CONNECT_NOW')
                : t('DOCTOR_DASHBORD_CONSULT_NOW')}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderDoctorButtons = (): React.ReactElement => {
    const statusLowerCase =
      appointment_status && appointment_status?.toLowerCase();
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="doctor-buttons-container">
        {VitalButtonAppointmentTypes?.includes(statusLowerCase) && (
          <Grid item>
            <Button
              variant="outlined"
              className="vitals-button"
              onClick={openVitals}>
              {t('TIMELINE_ENTER_VITALS')}
            </Button>
          </Grid>
        )}

        {appointmentTypeStatus === AppointmentTypes.online &&
          ConnectButtonAppointmentTypes?.includes(statusLowerCase) && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenConnectNow}
                className="vc-button">
                {t('DOCTOR_DASHBORD_CONNECT_NOW')}
              </Button>
            </Grid>
          )}
      </Grid>
    );
  };

  const renderDoctorInfo = (): React.ReactElement => {
    const specializationList = doctor_details?.branches?.map((item: any) => {
      return DoctorSpecializations?.[item?.specialization];
    });
    const uniqueSpecialization = _.uniqBy(specializationList, (e: any) => {
      return e;
    });
    const doctorSpecialization = uniqueSpecialization.join();

    const educationList = doctor_details?.education?.map((item: any) => {
      return item?.edu_college;
    });
    const uniqueEducation = _.uniqBy(educationList, (e: any) => {
      return e;
    });
    const education = uniqueEducation.join();

    return (
      <Grid container spacing={2} style={{paddingTop: '1rem'}}>
        <Grid item xs={1}>
          <Avatar
            alt=""
            src={CommonUtils.getFullImageUrl(doctor_details?.profile_image)}
            className="vc-profile-icon"
          />
        </Grid>
        <Grid item xs>
          <Typography className="doctor-name">{`${doctor_details?.salutation}. ${doctor_details?.full_name}`}</Typography>
          <Typography className="sub-title">{doctorSpecialization}</Typography>
          <Typography className="sub-title">{education}</Typography>
        </Grid>
        <Grid container item xs justifyContent="flex-end">
          {renderMobileNumber(doctor_details?.phone_no)}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className="patient-details-wrapper">
      <Grid container style={{padding: '0.9375rem'}}>
        <Grid item container xs={12} className="name-details">
          {renderAvatar()}
          {!isFromPersonWeb && renderPatientDetailedInfo()}
          {isFromPersonWeb && renderDoctorDetailedInfo()}
        </Grid>
        <Divider className="divider" />
        <Grid item container xs={12} className="chief">
          <Grid item xs={12} className="complaint">
            {t('DOCTOR_DASHBORD_CHIEF_COMPLAINT')}
          </Grid>
          <Grid item className="complaintText">
            {description}
          </Grid>
          {isFromNurseDashboard == 'FromAdminDashboard'
            ? false
            : !isNurse && !isFromPersonWeb && renderHistoryConnectButtons()}
        </Grid>
        <Divider className="divider" />
        {isFromVirtualClinic && !isFromPersonWeb && renderDoctorInfo()}
      </Grid>

      {isFromVirtualClinic && renderDoctorButtons()}
      {openConnectNow && renderConnectNow()}
      {openMasterTemplates && renderMasterTemplatesPopup()}
      {showVitals && renderVitals()}
      {showVitalsPopUp && renderAddVitalsPopUp()}
      {openCommonConfirmationBox && renderCommonConfirmationBox()}
    </Grid>
  );
};

export default PatientDetails;
