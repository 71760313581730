/* eslint-disable camelcase */
import moment from 'moment';

import {environment} from '@shared/environment/environment';

import Storage from './Storage';
import {lankanDomain, UserTypes} from 'src/constants/SystemConstants';
import {VitalKeys} from 'src/constants/FormFields';
import Icons from '@components/system/MyIcons/index';
import AuthUtils from './AuthUtils';
import InitSharedRepo from '../../../shared';
import {createOrder} from '@shared/services/PaymentService';
import {APP_PRIMARY_COLOR} from 'src/style/variable';
import _ from 'lodash';

import {getBMICalculation} from './vital-standards/bmi';
import {getDiastolicCalculation} from './vital-standards/diastolic';
import {getHeightCalculation} from './vital-standards/height';
import {getOxygenSaturationCalculation} from './vital-standards/oxygen_saturation';
import {getPefrCalculation} from './vital-standards/pefr';
import {getPulseRateCalculation} from './vital-standards/pulse_rate';
import {getRespiratoryRateCalculation} from './vital-standards/respiratory_rate';
import {getSystolicCalculation} from './vital-standards/systolic';
import {getTemperatureCalculation} from './vital-standards/temperature';
import {getWeightCalculation} from './vital-standards/weight';
import NavRoutes from 'src/constants/route/NavRoutes';
import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';

// interface for payload
interface PhpPortalParams {
  token: string;
  type: string;
  doctor_id?: string;
  branch_id?: string;
  person_healpha_id?: string;
  appointment_date?: string;
  appointment_type?: string;
  appointment_time?: string;
  nh_id?: string;
  jwt_token?: number;
}

class CommonUtils {
  static KEYS = {
    SEARCH_FILTERS: 'SEARCH_FILTERS',
    DROP_DOWN_ID: 'DROP_DOWN_ID',
    DROP_DOWN_TEXT: 'DROP_DOWN_TEXT',
    FORUMS_USER: 'FORUMS_USER',
    LANGUAGE_OBJ: 'LANGUAGE_OBJ',
    USER_INFO: 'USER_INFO'
  };

  static PAYMENT_ZERO = 'Pay_zero';

  static clearUserInfo(): void {
    Storage.removeItem(CommonUtils.KEYS.USER_INFO);
  }

  static setUserInfo(data: any): void {
    this.clearUserInfo();
    Storage.setItem(CommonUtils.KEYS.USER_INFO, data);
  }

  static getUserInfo(): any {
    return Storage.getItem(CommonUtils.KEYS.USER_INFO, true);
  }

  static PhpPortalParamTypes = {
    PERSON_LOGIN: 'person_login',
    PERSON_APPOINTMENT: 'person_appointment',
    PERSON_APPOINTMENT_BOOKED: 'appointment_booked',
    DOCTOR_LOGIN: 'nh_login',
    DOCTOR_REG: 'nh_create'
  };

  static DoctorWeekDays: any = {
    0: {
      chk_key: 'sun_chk',
      time_key: 'sunday_working_time',
      break_key: 'sunday_break_time'
    }, // SUNDAY
    1: {
      chk_key: 'mon_chk',
      time_key: 'monday_working_time',
      break_key: 'monday_break_time'
    }, // MONDAY
    2: {
      chk_key: 'tue_chk',
      time_key: 'tuesday_working_time',
      break_key: 'tuesday_break_time'
    }, // TUESDAY
    3: {
      chk_key: 'wed_chk',
      time_key: 'wednesday_working_time',
      break_key: 'wednesday_break_time'
    }, // WEDNESDAY
    4: {
      chk_key: 'thu_chk',
      time_key: 'thursday_working_time',
      break_key: 'thursday_break_time'
    }, // THURSDAY
    5: {
      chk_key: 'fri_chk',
      time_key: 'friday_working_time',
      break_key: 'friday_break_time'
    }, // FRIDAY
    6: {
      chk_key: 'sat_chk',
      time_key: 'saturday_working_time',
      break_key: 'saturday_break_time'
    } // SATURDAY
  };

  static AppointmentTypeKeys: any = {
    general: 'general_price',
    review: 'review',
    emergency: 'emergency_price',
    vip: 'vip',
    telemedicine: 'telemedicine',
    'tele medicine': 'telemedicine',
    test: 'test',
    night: 'night_price',
    online: 'online',
    'online call': 'online',
    covid: 'covid',
    homecare: 'homecare',
    normal: 'normal'
  };

  static workTimingsMapping = {
    0: {
      chk_key: 'mon_chk',
      time_key: 'monday_working_time',
      start_time: 'mon_start_time',
      end_time: 'mon_end_time',
      day: 'MON'
    },
    1: {
      chk_key: 'tue_chk',
      time_key: 'tuesday_working_time',
      start_time: 'tue_start_time',
      end_time: 'tue_end_time',
      day: 'TUE'
    },
    2: {
      chk_key: 'wed_chk',
      time_key: 'wednesday_working_time',
      start_time: 'wed_start_time',
      end_time: 'wed_end_time',
      day: 'WED'
    },
    3: {
      chk_key: 'thu_chk',
      time_key: 'thursday_working_time',
      start_time: 'thu_start_time',
      end_time: 'thu_end_time',
      day: 'THU'
    },
    4: {
      chk_key: 'fri_chk',
      time_key: 'friday_working_time',
      start_time: 'fri_start_time',
      end_time: 'fri_end_time',
      day: 'FRI'
    },
    5: {
      chk_key: 'sat_chk',
      time_key: 'saturday_working_time',
      start_time: 'sat_start_time',
      end_time: 'sat_end_time',
      day: 'SAT'
    },
    6: {
      chk_key: 'sun_chk',
      time_key: 'sunday_working_time',
      start_time: 'sun_start_time',
      end_time: 'sun_end_time',
      day: 'SUN'
    }
  };
  static workTimingsMapping1 = {
    0: {
      chk_key: 'monday_chk',
      time_key: 'monday_working_time',
      start_time: 'mon_start_time',
      end_time: 'mon_end_time',
      day: 'MON'
    },
    1: {
      chk_key: 'tuesday_chk',
      time_key: 'tuesday_working_time',
      start_time: 'tue_start_time',
      end_time: 'tue_end_time',
      day: 'TUE'
    },
    2: {
      chk_key: 'wednesday_chk',
      time_key: 'wednesday_working_time',
      start_time: 'wed_start_time',
      end_time: 'wed_end_time',
      day: 'WED'
    },
    3: {
      chk_key: 'thursday_chk',
      time_key: 'thursday_working_time',
      start_time: 'thu_start_time',
      end_time: 'thu_end_time',
      day: 'THU'
    },
    4: {
      chk_key: 'friday_chk',
      time_key: 'friday_working_time',
      start_time: 'fri_start_time',
      end_time: 'fri_end_time',
      day: 'FRI'
    },
    5: {
      chk_key: 'saturday_chk',
      time_key: 'saturday_working_time',
      start_time: 'sat_start_time',
      end_time: 'sat_end_time',
      day: 'SAT'
    },
    6: {
      chk_key: 'sunday_chk',
      time_key: 'sunday_working_time',
      start_time: 'sun_start_time',
      end_time: 'sun_end_time',
      day: 'SUN'
    }
  };
  // get full name of object
  static getFullName = (element: any): any => {
    return (
      element?.first_name +
      (element?.middle_name ? ' ' + element.middle_name : '') +
      (element?.last_name ? ' ' + element.last_name : '')
    );
  };

  static getShortName = (fullName: any): any => {
    return fullName
      ?.split(' ')
      .map((name: any) => name[0])
      .join('')
      .toUpperCase();
  };

  static getBranchName = (fullName: any): any => {
    return fullName
      ?.split(' ')
      .map((name: any) => name[0])
      .join('')
      .slice(0, 1)
      .toUpperCase();
  };

  static getCapitalizeWord = (str: string): any => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  static getFullImageUrl(url: any): any {
    const portalUrl = environment.portalUrl;
    if (url && typeof url === 'string') {
      const urls = url.split(',');
      if (urls && Array.isArray(urls) && urls.length > 0) {
        if (urls.length === 1) {
          return `${portalUrl}/${url.trim()}`;
        } else {
          const urls_data: any = [];
          urls.forEach((item: any) => {
            urls_data.push(`${portalUrl}/${item.trim()}`);
          });
          return urls_data;
        }
      }
    }
    return null;
  }

  static getFullImageUrlForCorporate(url: any): any {
    const portalUrl = environment.portalUrl;
    if (url && typeof url === 'string') {
      return `${portalUrl}/${url.trim()}`;
    }
  }

  static getBranchImageUrl(url: any): any {
    if (url && typeof url === 'string') {
      return `${environment.portalUrl}/${url.trim()}`;
    }
    return null;
  }

  static clearKeys(): void {
    AuthUtils.clearAuthToken();
    AuthUtils.clearPHPToken();
    AuthUtils.clearDoctorId();
    AuthUtils.clearHealphaId();
    AuthUtils.clearPersonName();
    AuthUtils.clearMenuModuleData();
    AuthUtils.clearMenuBranchId();
    AuthUtils.clearMenuPageUrl();
    AuthUtils.clearStatus();
    this.clearUserInfo();
    localStorage.clear();
  }

  static PerformPostLoginActions(
    token: any,
    name?: any,
    isFromLoginFlow?: boolean
  ): void {
    this.clearKeys();
    InitSharedRepo.initAfterAuth(token);
    AuthUtils.setAuthToken(token);

    if (isFromLoginFlow) {
      InitSharedRepo.phpUrlInit();
    }
    if (name) {
      AuthUtils.setPersonName(name);
    }
  }

  static calculateOffset(params: any, limit: any): any {
    let pageNumber = 1;
    if (params?.page) {
      pageNumber = parseInt(params.page);
    }
    let offset = (pageNumber - 1) * limit;
    if (Math.sign(offset) === -1) {
      offset = 0;
    }
    return {pageNo: pageNumber, offSetNo: offset};
  }

  // method to get whitelabelprefix
  static checkWhitelabelprefixExistsInUrl(): any {
    let prefix = '';
    let {host} = window.location,
      {baseHost}: any = environment;

    if (!host || !baseHost) {
      return;
    }

    if (baseHost.includes('4001')) {
      baseHost = baseHost.split('4001').join('3000');
    }

    host = host.split('.' + baseHost)[0];

    if (host !== baseHost) {
      prefix = host;
    }

    return prefix;
  }

  static checkWhitelabelOfUrl(host: string): any {
    let prefix = '';
    let {baseHost}: any = environment;

    if (!host || !baseHost) {
      return;
    }

    if (baseHost.includes('4001')) {
      baseHost = baseHost.split('4001').join('3000');
    }

    host = host.split('.' + baseHost)[0];

    if (host !== baseHost) {
      prefix = host;
    }

    prefix = host.split('https://')[1];
    return prefix;
  }

  static getWindowUrl(): any {
    return `${window.location.protocol}//${window.location.host}`;
    // return 'https://core.dev3.rxconcent.com';
  }
  static splitTime(time: string): any {
    const splitTime = time?.split('to');
    let stTime = '',
      endTime = '';
    if (splitTime?.length > 0 && splitTime[0]) {
      stTime = splitTime[0]?.trim();
      stTime = moment(stTime, 'H:mm')
        .format('LT')
        .replace(' ', '')
        .toLowerCase();
    }
    if (splitTime?.length > 1 && splitTime[1]) {
      endTime = splitTime[1]?.trim();
      endTime = moment(endTime, 'H:mm')
        .format('LT')
        .replace(' ', '')
        .toLowerCase();
    }
    return {
      start_time: stTime,
      end_time: endTime
    };
  }
  static allAreEqual(obj: any): boolean {
    return Object.values(obj).every((value, _index, arr) => {
      if (value == arr[0]) {
        return true;
      }

      return false;
    });
  }
  static getHospitalTimings(branch: any, data: any, t: any): any {
    // eslint-disable-next-line
    if (branch?.op_branch?.visit_hours_chk != null) {
      const obj = CommonUtils.splitTime(branch.op_branch.visit_hours_time);
      const dataObj: any = {};
      // eslint-disable-next-line
      if (branch?.op_branch?.sun_time) {
        dataObj.label = t('MON_TO_SUN');
      } else {
        dataObj.label = t('MON_TO_SAT');
      }
      dataObj.value = `${obj.start_time} - ${obj.end_time}`;
      data.workTimings = [dataObj];
    } else {
      const tempObj: any = {};
      if (
        branch?.op_branch?.sun_time != null ||
        branch?.op_branch?.sun_time != undefined
      ) {
        tempObj.sun = branch?.op_branch?.sun_time;
      }
      tempObj.mon = branch?.op_branch?.mon_time;
      tempObj.tue = branch?.op_branch?.tue_time;
      tempObj.wed = branch?.op_branch?.wed_time;
      tempObj.thu = branch?.op_branch?.thu_time;
      tempObj.fri = branch?.op_branch?.fri_time;
      tempObj.sat = branch?.op_branch?.sat_time;
      const allEqual = CommonUtils.allAreEqual(tempObj);
      if (allEqual === true) {
        const tmp: any = branch?.op_branch?.wed_time;
        const obj = CommonUtils.splitTime(tmp);
        const dataObj: any = {};
        // eslint-disable-next-line
        if (branch?.op_branch?.sun_time != null) {
          dataObj.label = t('MON_TO_SUN');
        } else {
          dataObj.label = t('MON_TO_SAT');
        }
        dataObj.value = `${obj.start_time} - ${obj.end_time}`;
        data.workTimings = [dataObj];
      }
    }
  }

  static isValidSequence(data: any): any {
    let isValid = true;
    for (let i = 0; i < data.length; i++) {
      if (data[i + 1] && data[i + 1].index - data[i].index !== 1) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }

  static getDoctorWorkTimings(input: any, t: any, branch_id: any): any {
    let data = null;
    if (input && Array.isArray(input) && input.length > 0) {
      if (branch_id) {
        const [filterItem] = input.filter(
          (item: any) => item.branch_id === branch_id
        );
        data = filterItem;
      } else {
        data = input[0];
      }
    }
    const results = [];
    if (data && typeof data === 'object' && Object.keys(data).length > 0) {
      const mapData: any = this.workTimingsMapping;
      const workTimingData: any = {};
      for (let i = 0; i <= 6; i++) {
        if (
          data[mapData[i].chk_key] &&
          data[mapData[i].start_time] &&
          data[mapData[i].end_time]
        ) {
          const workingTime = `${data[mapData[i].start_time]} to ${
            data[mapData[i].end_time]
          }`;
          const pushObj = {
            day: t(`${mapData[i].day}`),
            index: i
          };
          if (workTimingData[workingTime]) {
            workTimingData[workingTime].push(pushObj);
          } else {
            workTimingData[workingTime] = [pushObj];
          }
        }
      }
      for (const workingTime in workTimingData) {
        if (
          workingTime &&
          workTimingData[workingTime] &&
          Array.isArray(workTimingData[workingTime]) &&
          workTimingData[workingTime].length > 0
        ) {
          const length = workTimingData[workingTime].length;
          const timeObj = CommonUtils.splitTime(workingTime);
          const obj: any = {
            value: `${timeObj.start_time} - ${timeObj.end_time}`
          };
          if (
            length > 2 &&
            CommonUtils.isValidSequence(workTimingData[workingTime])
          ) {
            obj.label = `${workTimingData[workingTime][0].day} - ${
              workTimingData[workingTime][length - 1].day
            }`;
          } else {
            obj.label = workTimingData[workingTime]
              .map((item: any) => item.day)
              .join();
          }
          results.push(obj);
        }
      }
    }
    return results;
  }

  static getDoctorWorkTimings1(input: any, t: any, branch_id: any): any {
    let data: any = null;
    const results: any = [];
    if (input && Array.isArray(input) && input.length > 0) {
      {
        input &&
          input?.map((element: any) => {
            if (branch_id) {
              data = element;
            } else {
              data = input[0];
            }
            if (
              data &&
              typeof data === 'object' &&
              Object.keys(data).length > 0
            ) {
              const mapData: any = this.workTimingsMapping1;
              const workTimingData: any = {};
              for (let i = 0; i <= 6; i++) {
                if (
                  data[mapData[i].chk_key] &&
                  data[mapData[i].start_time] &&
                  data[mapData[i].end_time]
                ) {
                  const workingTime = `${data[mapData[i].start_time]} to ${
                    data[mapData[i].end_time]
                  }`;
                  const pushObj = {
                    day: t(`${mapData[i].day}`),
                    index: i
                  };
                  if (workTimingData[workingTime]) {
                    workTimingData[workingTime].push(pushObj);
                  } else {
                    workTimingData[workingTime] = [pushObj];
                  }
                }
              }
              for (const workingTime in workTimingData) {
                if (
                  workingTime &&
                  workTimingData[workingTime] &&
                  Array.isArray(workTimingData[workingTime]) &&
                  workTimingData[workingTime].length > 0
                ) {
                  const length = workTimingData[workingTime].length;
                  const timeObj = CommonUtils.splitTime(workingTime);
                  const obj: any = {
                    value: `${timeObj.start_time} - ${timeObj.end_time}`
                  };
                  if (
                    length > 2 &&
                    CommonUtils.isValidSequence(workTimingData[workingTime])
                  ) {
                    obj.label = `${workTimingData[workingTime][0].day} - ${
                      workTimingData[workingTime][length - 1].day
                    }`;
                  } else {
                    obj.label = workTimingData[workingTime]
                      .map((item: any) => item.day)
                      .join();
                  }
                  results.push(obj);
                }
              }
            }
          });
      }
    }
    return results;
  }
  static getDoctorWorkTimings2(input: any, t: any, branch_id: any): any {
    const data: any = input;
    const results: any = [];
    if (data && typeof data === 'object' && Object.keys(data).length > 0) {
      const mapData: any = this.workTimingsMapping;
      const workTimingData: any = {};
      for (let i = 0; i <= 6; i++) {
        if (
          data[mapData[i].chk_key] &&
          data[mapData[i].start_time] &&
          data[mapData[i].end_time]
        ) {
          const workingTime = `${data[mapData[i].start_time]} to ${
            data[mapData[i].end_time]
          }`;
          const pushObj = {
            day: t(`${mapData[i].day}`),
            index: i
          };
          if (workTimingData[workingTime]) {
            workTimingData[workingTime].push(pushObj);
          } else {
            workTimingData[workingTime] = [pushObj];
          }
        }
      }
      for (const workingTime in workTimingData) {
        if (
          workingTime &&
          workTimingData[workingTime] &&
          Array.isArray(workTimingData[workingTime]) &&
          workTimingData[workingTime].length > 0
        ) {
          const length = workTimingData[workingTime].length;
          const timeObj = CommonUtils.splitTime(workingTime);
          const obj: any = {
            value: `${timeObj.start_time} - ${timeObj.end_time}`
          };
          if (
            length > 2 &&
            CommonUtils.isValidSequence(workTimingData[workingTime])
          ) {
            obj.label = `${workTimingData[workingTime][0].day} - ${
              workTimingData[workingTime][length - 1].day
            }`;
          } else {
            obj.label = workTimingData[workingTime]
              .map((item: any) => item.day)
              .join();
          }
          results.push(obj);
        }
      }
    }
    return results;
  }
  static getBranchSpecialization(branch: any, data: any, t: any): any {
    if (branch?.specialization) {
      const splitArr = branch.specialization.split(',');
      if (splitArr.length !== 0) {
        if (splitArr.length === 1) {
          data.speciality = t('Single-Speciality'); // TODO BACK-END MESSAGE
        } else {
          data.speciality = t('MULTI_SPECIALITY');
        }
      }
    }
  }

  static getHeaderLinks(t: any, domain: string): any {
    return [
      {
        id: 1,
        label: t('HOME'),
        value: 'home',
        isSubList: true,
        isSubListOpen: false,
        subList: [
          {
            id: 9,
            label: t('ABOUT'),
            value: 'about',
            isSubList: false,
            url: 'about-us',
            isValidUrl: true
          },
          {
            id: 10,
            label: t('TEAM'),
            value: 'teams',
            isSubList: false,
            url: 'team',
            isValidUrl: true
          }
        ]
      },
      {
        id: 2,
        label: t('SERVICES'),
        value: 'services',
        isSubList: true,
        isSubListOpen: false,
        subList: [
          {
            id: 13,
            label: t('PREVENTIVE_HEALTH'),
            value: 'preventive_health',
            isSubList: true,
            isSubListOpen: false,
            subSubList: [
              {
                id: 15,
                label: t('APP_NAME_SCHOOL', {appName: `${t('APP_NAME')}`}),
                value: 'healpha_school',
                isSubList: false,
                url: 'healpha-school',
                isValidUrl: true
              },
              {
                id: 16,
                label: t('APP_NAME_CORPORATE', {appName: `${t('APP_NAME')}`}),
                value: 'healpha_corporate',
                isSubList: false,
                url: 'healpha-corporate',
                isValidUrl: true
              },
              {
                id: 17,
                label: t('COMMUNITY_HEALTH'),
                value: 'community_health',
                isSubList: false,
                url: 'community-health',
                isValidUrl: true
              },
              {
                id: 18,
                label: t('RESIDENTIAL_WELFARE_ASSOCIATION'),
                value: 'residential_welfare_association',
                isSubList: false,
                url: 'residential-welfare-association',
                isValidUrl: true
              }
            ]
          },
          {
            id: 14,
            label: t('CURATIVE_HEALTH'),
            value: 'curative_health',
            isSubList: true,
            isSubListOpen: false,
            subSubList: [
              {
                id: 19,
                label: t('DIGITAL_SMART_CLINICS'),
                value: 'healpha_school',
                isSubList: false,
                url: 'digital-smart-clinics',
                isValidUrl: true
              },
              {
                id: 20,
                label: t('TELECONSULTATION'),
                value: 'healpha_corporate',
                isSubList: false,
                url: 'teleconsultation',
                isValidUrl: true
              },
              {
                id: 21,
                label: t('COVID_HOMECARE'),
                value: 'community_health',
                isSubList: false,
                url: 'covid-homecare',
                isValidUrl: true
              },
              {
                id: 22,
                label: t('VIRTUAL_CLINICS'),
                value: 'residential_welfare_association',
                isSubList: false,
                url: 'virtual-clinics-for-corporates',
                isValidUrl: domain === lankanDomain ? false : true
              }
            ]
          }
        ]
      },
      {
        id: 3,
        label: t('DOCTORS'),
        value: 'doctors',
        isSubList: false,
        url: 'doctors',
        isValidUrl: true
      },
      {
        id: 4,
        label: t('COLLABORATION'),
        value: 'collaboration',
        isSubList: false,
        url: 'partners',
        isValidUrl: domain === lankanDomain ? false : true
      },
      {
        id: 5,
        label: t('INVESTORS'),
        value: 'investors',
        isSubList: false,
        url: 'investors',
        isValidUrl: true
      },
      {
        id: 6,
        label: t('Media'),
        value: 'news_gallery',
        isSubList: true,
        isSubListOpen: false,
        subList: [
          {
            id: 11,
            label: 'News',
            value: 'news',
            isSubList: false,
            url: 'news',
            isValidUrl: domain === lankanDomain ? false : true
          },
          {
            id: 12,
            label: t('GALLERY'),
            value: 'gallery',
            isSubList: false,
            url: 'gallery',
            isValidUrl: true
          },
          {
            id: 23,
            label: t('Blog'),
            value: 'blog',
            isSubList: false,
            url: 'blog',
            isValidUrl: true
          }
        ]
      },
      {
        id: 7,
        label: t('FAQ'),
        value: 'faq',
        isSubList: false,
        url: 'frequently-asked-questions',
        isValidUrl: domain === lankanDomain ? false : true
      },
      {
        id: 8,
        label: t('CONTACT'),
        value: 'contact',
        isSubList: false,
        url: 'contact-us',
        isValidUrl: true
      }
    ];
  }

  static getFooterLinks(t: any, domain: string): any {
    return [
      {
        id: 1,
        label: t('HOME'),
        value: 'home',
        url: '',
        isValidUrl: true
      },
      {
        id: 2,
        label: t('DOCTORS'),
        value: 'doctors',
        url: 'doctors',
        isValidUrl: true
      },
      {
        id: 3,
        label: t('COLLABORATION'),
        value: 'collaboration',
        url: 'partners',
        isValidUrl: domain === lankanDomain ? false : true
      },
      {
        id: 4,
        label: t('INVESTORS'),
        value: 'investors',
        url: 'investors',
        isValidUrl: true
      },
      {
        id: 5,
        label: t('NEWS_GALLERY'),
        value: 'news_gallery',
        url: 'news',
        isValidUrl: domain === lankanDomain ? false : true
      },
      {
        id: 6,
        label: t('PRIVACY_POLICY_LABEL'),
        value: 'privacy-policy',
        url: 'privacy-policy',
        isValidUrl: domain === lankanDomain ? false : true
      },
      {
        id: 7,
        label: t('TERMS_OF_SERVICE'),
        value: 'terms_of_service',
        url: 'terms-of-service',
        isValidUrl: true
      },
      {
        id: 8,
        label: t('CONTACT_US_LABEL'),
        value: 'contact',
        url: 'contact-us',
        isValidUrl: true
      }
    ];
  }

  static getLoginSignUpSpecialtiesList(t: any): any {
    return [
      {
        id: 1,
        text: t('CONNECT_WITH_DOCTORS_TEXT'),
        icon: Icons.ConnectWithDoctorsIcon
      },
      {
        id: 2,
        text: t('TRACK_RECEIPTS_TEXT'),
        icon: Icons.ReceiptsIcon
      },
      {
        id: 3,
        text: t('REMINDERS_TEXT'),
        icon: Icons.RemaindersIcon
      },
      {
        id: 4,
        text: t('FORUMS_QUERIES_TEXT'),
        icon: Icons.ForumsIcon
      }
    ];
  }

  static getDoctorSpecialtiesList(t: any): any {
    return [
      {
        id: 1,
        text: t('DOCTOR_SPECIALITY_TEXT1'),
        icon: Icons.VideoIcon
      },
      {
        id: 2,
        text: t('DOCTOR_SPECIALITY_TEXT2'),
        icon: Icons.ReceiptsIcon
      },
      {
        id: 3,
        text: t('DOCTOR_SPECIALITY_TEXT3'),
        icon: Icons.SecurePlatformIcon
      },
      {
        id: 4,
        text: t('DOCTOR_SPECIALITY_TEXT4'),
        icon: Icons.PracticeIcon
      }
    ];
  }

  static SchoolCorpDescription(t: any): any {
    return [
      {
        id: 1,
        text: t('COMP_HEALTH_CHECKUP'),
        icon: Icons.VideoIcon
      },
      {
        id: 2,
        text: t('HEALTH_MANAGEMENT'),
        icon: Icons.ReceiptsIcon
      },
      {
        id: 3,
        text: t('DOCTOR_SPECIALITY_TEXT3'),
        icon: Icons.SecurePlatformIcon
      },
      {
        id: 4,
        text: t('CLINIC_MANAGEMENT'),
        icon: Icons.PracticeIcon
      }
    ];
  }

  static formBookAppointmentProps(props: any, payzero?: any): any {
    return {
      doctor_id: props?.appointmentValues?.doctor?.non_healpha_user_id,
      branch_id: props?.appointmentValues?.branch?.branch_id,
      appointment_date: moment(props?.appointmentValues?.calendarValue).format(
        'YYYY-MM-DD'
      ),
      appointment_time: props?.appointmentValues?.selectedTime,
      appointment_type:
        props?.appointmentValues?.appointment?.appointment_type_value,
      description: props?.appointmentValues?.compliant,
      amount: payzero ? 0 : props?.appointmentValues?.appointment?.price,
      encounter_id: props?.appointmentValues?.encounterId
        ? props?.appointmentValues?.encounterId
        : '',
      is_online_appointment:
        props?.appointmentValues?.appointment?.appointment_type === 'online' ||
        props?.appointmentValues?.is_online_appointment
    };
  }

  static convertTitleCase(str: string): string {
    return str
      .split(' ')
      .map(w => w?.[0]?.toUpperCase() + w?.substr(1)?.toLowerCase())
      .join(' ');
  }

  static convertTo12Hours(time: any): string {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  static openNewTab(page: string): void {
    window.open(page, '_blank');
  }

  static makeAppointmentTypes(price_list: any): any {
    const results: any = [];
    if (price_list && Array.isArray(price_list)) {
      price_list.forEach((price_item: any) => {
        let result: any = [];
        if (price_item.master_appointment_types) {
          price_item.master_appointment_types.forEach((item: any) => {
            if (
              item.appointment_types &&
              price_item[
                this.AppointmentTypeKeys[
                  item.appointment_types.appointment_type_value
                ]
              ]
            ) {
              result.push({
                appointment_type_name:
                  item.appointment_types.appointment_type_name,
                appointment_type_value:
                  item.appointment_types.appointment_type_value,
                appointment_type: item.appointment_types.appointment_type,
                price:
                  price_item[
                    this.AppointmentTypeKeys[
                      item.appointment_types.appointment_type_value
                    ]
                  ]
              });
            }
          });
        }
        result = _.uniqBy(result, 'appointment_type_value');
        results.push({
          practice_id: price_item.practice_id,
          branch_id: price_item.branch_id,
          types: result
        });
      });
    }
    return results;
  }

  static formAppointmentTypes(
    branch_id: any,
    doctor: any,
    isClinicDashboard?: boolean
  ): any {
    if (
      doctor?.appointment_types &&
      Array.isArray(doctor.appointment_types) &&
      doctor.appointment_types.length > 0
    ) {
      const [appointment_types] = doctor?.appointment_types?.filter(
        (item: any) => item.branch_id === branch_id
      );
      if (appointment_types?.types.length != 0) {
        const appointments = appointment_types?.types.filter((item: any) =>
          isClinicDashboard ? item : item.appointment_type_value !== 'review'
        );
        return appointments;
      } else if (appointment_types?.types.length == 0) {
        const tempArr: any = doctor?.appointment_types.splice(1, 1);
        const [appointment_types1] = tempArr?.filter(
          (item: any) => item.branch_id === branch_id
        );
        const appointments = appointment_types1?.types.filter((item: any) =>
          isClinicDashboard ? item : item.appointment_type_value !== 'review'
        );
        return appointments;
      }
    }
    return [];
  }

  // a method to launch php portal
  static launchPhpPortal(data: PhpPortalParams): void {
    let url = `${environment.portalUrl}/landing?tk=${data.token}`;
    if (data?.type) {
      url = `${url}&type=${data.type}`;
    }
    if (data?.doctor_id) {
      url = `${url}&doctor_id=${data.doctor_id}`;
    }
    if (data?.branch_id) {
      url = `${url}&branch_id=${data.branch_id}`;
    }
    if (data?.person_healpha_id) {
      url = `${url}&person_healpha_id=${data.person_healpha_id}`;
    }
    if (data?.appointment_date) {
      url = `${url}&appointment_date=${data.appointment_date}`;
    }
    if (data?.appointment_time) {
      url = `${url}&slot=${data.appointment_time}`;
    }
    if (data?.appointment_type) {
      url = `${url}&appointment_type=${data.appointment_type}`;
    }
    if (data?.nh_id) {
      url = `${url}&nh_id=${data.nh_id}&login_from=''`;
    }
    window.location.replace(url);
  }

  static loadScript(src: any): any {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // launch razor pay UI
  static async launchRazorpayUI(
    amount: any,
    currency: any,
    name: any,
    email: any,
    contact: any,
    appointment_type: any,
    callback: any
  ): Promise<any> {
    // first load the script
    this.loadScript(environment.razorpayScriptUrl).then(async () => {
      try {
        const orderResponse = await createOrder({amount, currency});
        const order_id = orderResponse.data.id;
        // make options object
        const options = {
          key: environment.paymentProviderKeyId,
          amount,
          currency,
          name,
          description: appointment_type,
          order_id,
          handler: async (response: any) => {
            callback(
              {payment_id: response.razorpay_payment_id, order_id},
              null
            );
          },
          prefill: {
            name,
            email,
            contact
          },
          theme: {
            color: APP_PRIMARY_COLOR
          },
          config: {
            display: {
              language: 'en'
            }
          }
        };
        const paymentObject = (window as any).Razorpay(options);
        paymentObject.open();
      } catch (err) {
        callback(null, err);
      }
    });
  }

  static findDoctorTodayAvailability(
    doctor: any,
    branch_id: any,
    day: any
  ): boolean {
    let is_today_available = false;
    const {branches} = doctor;
    if (branches && Array.isArray(branches)) {
      for (const item of branches) {
        if (
          (!branch_id ||
            (branch_id && item.branch_id && item.branch_id === branch_id)) &&
          item[this.DoctorWeekDays[day].chk_key]
        ) {
          is_today_available = true;
          break;
        }
      }
    }
    return is_today_available;
  }

  static rupeesIntoPaisa(rupees: any): any {
    return rupees * 100;
  }

  static getDateFormat(question: any): any {
    return moment(question).format('DD-MMM-YYYY, hh:MMA');
  }

  static getDobFormat(question: any): any {
    return moment(question).format('DD-MMM-YYYY');
  }

  static getDDMMFormat(question: any): any {
    return moment(question).format('DDMM');
  }

  static getPasswordRules(value: any): any {
    const rulesObject: any = {};

    if (value?.length >= 8 && value?.length <= 16) {
      rulesObject.lengthRule = true;
    } else {
      rulesObject.lengthRule = false;
    }

    if (value?.search(/[A-Z]/) < 0) {
      rulesObject.upperCaseRule = false;
    } else {
      rulesObject.upperCaseRule = true;
    }

    if (value?.search(/[0-9]/) < 0) {
      rulesObject.numberRule = false;
    } else {
      rulesObject.numberRule = true;
    }

    if (value?.search(/[!@#$%^&*]/) < 0) {
      rulesObject.specialCharRule = false;
    } else {
      rulesObject.specialCharRule = true;
    }

    return rulesObject;
  }

  static setSearchFilters(data: any): void {
    Storage.setItem(CommonUtils.KEYS.SEARCH_FILTERS, data);
  }

  static getSearchFilters(): any {
    return Storage.getItem(CommonUtils.KEYS.SEARCH_FILTERS);
  }

  static clearSearchFilters(): void {
    Storage.removeItem(CommonUtils.KEYS.SEARCH_FILTERS);
  }

  static isEmailValid(email: string): boolean {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(email.toLowerCase());
  }

  // static setDropDownId(data: any): void {
  //   Storage.setItem(CommonUtils.KEYS.DROP_DOWN_ID, data);
  // }

  // static getDropDownId(): any {
  //   return Storage.getItem(CommonUtils.KEYS.DROP_DOWN_ID);
  // }

  // static clearDropDownId(): void {
  //   Storage.removeItem(CommonUtils.KEYS.DROP_DOWN_ID);
  // }

  // static setDropDownText(data: any): void {
  //   Storage.setItem(CommonUtils.KEYS.DROP_DOWN_TEXT, data);
  // }

  // static getDropDownText(): any {
  //   return Storage.getItem(CommonUtils.KEYS.DROP_DOWN_TEXT);
  // }

  // static clearDropDownText(): void {
  //   Storage.removeItem(CommonUtils.KEYS.DROP_DOWN_TEXT);
  // }

  static setTemporaryUserId(data: any): void {
    Storage.setItem(CommonUtils.KEYS.FORUMS_USER, data);
  }

  static getTemporaryUserId(): any {
    return Storage.getItem(CommonUtils.KEYS.FORUMS_USER);
  }

  static clearTemporaryUserId(): void {
    Storage.removeItem(CommonUtils.KEYS.FORUMS_USER);
  }

  static setLanguageId(data: any): void {
    this.clearLanguageId();
    Storage.setItem(CommonUtils.KEYS.LANGUAGE_OBJ, data);
  }

  static getLanguageId(): any {
    return Storage.getItem(CommonUtils.KEYS.LANGUAGE_OBJ, true);
  }

  static clearLanguageId(): void {
    Storage.removeItem(CommonUtils.KEYS.LANGUAGE_OBJ);
  }

  static checkIsStandAloneDoctor(): boolean {
    return (
      (AuthUtils.getRoleType() === UserTypes.DOCTOR ||
        AuthUtils.getRoleType() === UserTypes.OP_DOCTOR) &&
      AuthUtils.getIsStandalone()
    );
  }

  static checkIsNurse(): boolean {
    return (
      AuthUtils.getRoleType() === UserTypes.NURSE ||
      AuthUtils.getRoleTypeForNurseInClinic() === UserTypes.NURSE ||
      AuthUtils.getRoleType() == UserTypes.NURSE_SIMPLE
    );
  }

  static checkIsAdminDoctorDashboard(): boolean {
    return (
      AuthUtils.getOperationalFlag() == 'true' ||
      AuthUtils.getRoleType() == UserTypes?.PRACTICE_ADMIN ||
      AuthUtils.getRoleType() == UserTypes?.BRANCH_ADMIN
    );
  }

  static checkIsAdminNurseDashboard(): boolean {
    return (
      (AuthUtils.getOperationalFlag() == 'true' ||
        AuthUtils.getRoleType() == UserTypes?.PRACTICE_ADMIN ||
        AuthUtils.getRoleType() == UserTypes?.BRANCH_ADMIN) &&
      AuthUtils.getIsAdminNurse() == 'true'
    );
  }

  static checkIsFrontOfficeAndAdmin(): boolean {
    return (
      AuthUtils.getRoleType() === UserTypes.OP_BILLER ||
      AuthUtils.getRoleType() === UserTypes.BILLER ||
      AuthUtils.getRoleType() === UserTypes.PRACTICE_ADMIN ||
      AuthUtils.getRoleType() === UserTypes.HEALPHA_ADMIN
    );
  }

  static checkIsFrontOfficeForDoctorDashboard(): boolean {
    return AuthUtils.getRoleType().toLowerCase() === UserTypes.EXECUTION_TEAM;
  }

  static getVitalCalc(
    vital_key: string,
    value: any,
    height: any,
    weight: any,
    cal_age: any,
    gender: any,
    dob: any,
    created_date: any,
    age_year: any
  ): any {
    try {
      let output = null,
        bmi_value: any = '',
        bmi_val: any = '';

      const error: any = {};
      switch (vital_key) {
        case VitalKeys.HEIGHT:
          output = getHeightCalculation(cal_age, gender, value);
          break;
        case VitalKeys.WEIGHT:
          output = getWeightCalculation(cal_age, gender, value);
          break;
        case VitalKeys.BMI:
          if (!height) {
            error.code = 400;
            error.message = 'HEIGHT_REQUIRED';
            return {error_data: error};
          }
          if (!weight) {
            error.code = 400;
            error.message = 'WEIGHT_REQUIRED';
            return {error_data: error};
          }
          bmi_val =
            (parseFloat(weight) / parseFloat(height) / parseFloat(height)) *
            10000;
          bmi_value = bmi_val.toFixed(1);
          output = getBMICalculation(cal_age, gender, bmi_value);
          break;
        case VitalKeys.PULSE_RATE:
          output = getPulseRateCalculation(age_year, value);
          break;
        case VitalKeys.OXYGEN_SATURATION:
          output = getOxygenSaturationCalculation(value);
          break;
        case VitalKeys.PEFR:
          if (!height) {
            error.code = 400;
            error.message = 'HEIGHT_REQUIRED';
            return {error_data: error};
          }
          output = getPefrCalculation(height, value);
          break;
        case VitalKeys.TEMPERATURE:
          output = getTemperatureCalculation(value);
          break;
        case VitalKeys.SYSTOLIC:
          if (!height) {
            error.code = 400;
            error.message = 'HEIGHT_REQUIRED';
            return {error_data: error};
          }
          output = getSystolicCalculation(
            height,
            value,
            dob,
            created_date,
            age_year,
            gender
          );
          break;
        case VitalKeys.DIASTOLIC:
          if (!height) {
            error.code = 400;
            error.message = 'HEIGHT_REQUIRED';
            return {error_data: error};
          }
          output = getDiastolicCalculation(
            height,
            value,
            dob,
            created_date,
            age_year,
            gender
          );
          break;
        case VitalKeys.RESPIRATORY_RATE:
          output = getRespiratoryRateCalculation(dob, created_date, value);
          break;
      }
      return {error_data: {}, vital_value: output, bmi_value};
    } catch (e) {
      throw new Error((e as any)?.message);
    }
  }
  static joinWithDelimiter(list: any[], delimiter: string): string {
    return list
      .filter(v => v === 0 || (v && !!v.toString().trim()))
      .map(v => v.toString().trim())
      .join(delimiter);
  }

  static workFlowArray(flowData: any, name: any): any {
    const workFlowData: any = flowData.data ? flowData.data : flowData;
    const dummy: any = [...workFlowData];
    workFlowData.map((item: any, index: any) => {
      if (item?.name == name) {
        dummy[index].status = 'completed';
      }
    });
    return dummy;
  }

  static workFlowStatus(flowData: any, name: any): any {
    const workFlowData: any = flowData.data ? flowData.data : flowData;
    const dummy: any = [...workFlowData];
    workFlowData.map((item: any, index: any) => {
      if (item?.name == name) {
        dummy[index].status = 'partially completed';
      }
    });
    return dummy;
  }

  static branchWorkFlowArray(flowData1: any, name: any, id: any): any {
    const data: any = flowData1;
    const branchId: any = Object.keys(flowData1);
    branchId.map((ids: any, index: any) => {
      if (id == ids) {
        const workFlowData: any = Object.values(flowData1)[index];
        const dummy: any = [...workFlowData];
        workFlowData.map((item: any, index1: any) => {
          if (item?.name == name) {
            dummy[index1].status = 'completed';
          }
        });
        data[id] = dummy;
      }
    });
    return data;
  }

  static moduleNavigation(getLoginModule: any, history: any): void {
    const BASE_ROUTE_HOME = `${BASE_ROUTE_PORTAL}/home`;
    if (getLoginModule == 'corporate') {
      history.push(
        `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_CORPORATE_USER}`
      );
    } else if (getLoginModule == 'op') {
      history.push(
        `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_OP_USER}`
      );
    } else if (getLoginModule == 'lab') {
      history.push(
        `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_LAB_USER}`
      );
    } else if (getLoginModule == 'pharmacy') {
      history.push(
        `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_PHARMA_USER}`
      );
    } else if (getLoginModule == 'school') {
      history.push(
        `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_SCHOOL_USER}`
      );
    } else if (getLoginModule == 'virtual_clinic') {
      history.push(
        `/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_VIRTUAL_USER}`
      );
    } else {
      history.push(`/${BASE_ROUTE_HOME}/${NavRoutes.PUBLIC.NON_HEALPHA_USER}`);
    }
  }
}

export default CommonUtils;
