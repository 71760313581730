import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as ImagingIconSvg} from '@assets/images/healpha/imagingIcon.svg';

const ImagingIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={ImagingIconSvg}
      viewBox="0 0 22 24"
    />
  );
};

export default ImagingIcon;
