/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';

import {
  createFamilyMember,
  bookDoctorAppointment
} from '@shared/services/PublicService';

import CommonDialogTitleComponent from '../CommonDialogTitle/CommonDialogTitleComponent';
import Icons from '@components/system/MyIcons/index';
import Loader from '@components/framework/loader/Loader';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import FormSelectField from '@components/system/CustomFormField/FormSelectField';
import FormCombinationField from '@components/system/CustomFormField/FormCombinationField';
import FormDatePickerField from '@components/system/CustomFormField/FormDatePickerField';
import FormPasswordField from '@components/system/CustomFormField/FormPasswordField';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {
  genderOptionsList,
  salutationOptionsList,
  familyRelationsList
} from 'src/constants/FormFields';
import {
  BORDER_TERTIARY_COLOR,
  DEFAULT_WHITE_COLOR,
  GET_8_PIXEL_BOX
} from 'src/style/variable';
import './AddPatientComponent.scss';
import CommonUtils from 'src/utils/CommonUtils';
import AuthUtils from 'src/utils/AuthUtils';

const textFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  border: `1px solid ${BORDER_TERTIARY_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.5),
  height: '3.5rem',
  opacity: 1
};

const dropDownProps = {
  height: '3.9rem',
  width: '100%',
  border: `1px solid ${BORDER_TERTIARY_COLOR}`
};

const AddPatientComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = React.useState(props?.open);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [values, setValues]: any = React.useState({
    salutation: 'Mr',
    dob: '',
    gender: '',
    family_head: '',
    relation: '',
    first_name: '',
    last_name: '',
    showPassword: false
  });

  const handleClose = (event?: any, reason?: any) => {
    setOpen(false);
    props?.closePatientPopup(true);
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleDobChange = (prop: any) => (newValue: any) => {
    setValues({...values, [prop]: newValue});
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleSubmit = (): void => {
    const data = {
      relation: values.relation,
      salutation: values.salutation,
      first_name: values.first_name,
      last_name: values.last_name,
      dob: CommonUtils.getDobFormat(values?.dob),
      gender: values.gender,
      password: values.password
    };

    if (props?.healphaId) {
      setIsLoading(true);
      createFamilyMember(data, props.healphaId)
        .then(result => {
          if (result?.data?.id) {
            bookDoctorAppointment(
              CommonUtils.formBookAppointmentProps(props),
              result.data.id
            )
              .then(res => {
                setIsLoading(false);
                enqueueSnackbar(res?.message, SnackBarConfig('s'));
                if (!result.data.id) {
                  CommonUtils.launchPhpPortal({
                    token: AuthUtils.getAuthToken(),
                    type: CommonUtils.PhpPortalParamTypes
                      .PERSON_APPOINTMENT_BOOKED
                  });
                }
              })
              .catch(err => {
                setIsLoading(false);
                enqueueSnackbar(
                  err?.message ? err.message : t('ERROR_ENCOUNTERED'),
                  SnackBarConfig('e')
                );
              });
          }
        })
        .catch(err => {
          setIsLoading(false);
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    } else {
      enqueueSnackbar(t('USER_ID_MISSING_WARNING'), SnackBarConfig('w'));
    }
  };

  const renderGridHelperInformation = (text: string): React.ReactElement => {
    return (
      <Grid item xs={12}>
        <div className="information-text">{text}</div>
      </Grid>
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        {renderGridHelperInformation(t('APPOINTMENT_QUESTION'))}

        <Grid item xs={12}>
          <FormTextField
            placeholder={t('FAMILY_HEAD_PLACEHOLDER')}
            name="family_head"
            value={props?.values?.first_name + ' ' + props?.values?.last_name}
            callback={handleChange('family_head')}
            adornmentPosition="start"
            Icon={RadioButtonUncheckedOutlinedIcon}
            readOnly={true}
          />
        </Grid>

        {renderGridHelperInformation(t('PROVIDE_PATIENT_DETAILS'))}

        <Grid item xs={12}>
          <FormSelectField
            name="relation"
            placeholder={t('RELATIONSHIP_PLACEHOLDER', {
              head_name: values?.first_name + ' ' + values?.last_name
            })}
            value={values['relation']}
            dropDownProps={dropDownProps}
            Icon={Icons.DropDownIcon}
            StartAdornmentIcon={Icons.GenderIcon}
            dropDownOptions={familyRelationsList}
            callback={handleChange('relation')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormCombinationField
            dropDownName={'salutation'}
            dropdownValue={values['salutation']}
            callback={handleChange('salutation')}
            dropDownOptions={salutationOptionsList}
            textFieldCallBack={handleChange('first_name')}
            textFieldName="first_name"
            placeholder={t('FIRST_NAME')}
            textFieldValue={values['first_name']}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            placeholder={t('LAST_NAME')}
            name="last_name"
            value={values['last_name']}
            callback={handleChange('last_name')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormDatePickerField
            name="dob"
            value={values?.dob}
            textFieldProps={textFieldProps}
            Icon={Icons.DOBIcon}
            callback={handleDobChange('dob')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormSelectField
            name="gender"
            placeholder={t('GENDER')}
            value={values['gender']}
            dropDownProps={dropDownProps}
            Icon={Icons.DropDownIcon}
            StartAdornmentIcon={Icons.GenderIcon}
            dropDownOptions={genderOptionsList}
            callback={handleChange('gender')}
          />
        </Grid>

        <Grid item xs={12}>
          <FormPasswordField
            name="password"
            placeholder={t('CREATE_PASSWORD')}
            adornmentPosition="start"
            Icon={Icons.PasswordIcon}
            callback={handleChange('password')}
            onClickCallBack={handleClickShowPassword}
            OnMousePDownCallBack={handleMouseDownPassword}
            values={values}
          />
        </Grid>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="submit-button"
            disabled={isLoading}
            onClick={() => handleSubmit()}>
            {isLoading ? (
              <Loader size={28} />
            ) : (
              t('REVIEW_AND_BOOK_APPOINTMENT')
            )}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="add-patient-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        open={open}
        aria-labelledby="add-patient-pop-up"
        aria-describedby="add-patient-otp"
        classes={{paper: 'booking-appointment-popup'}}>
        <DialogTitle
          id="add-patient"
          className="booking-appointment-popup-title">
          <CommonDialogTitleComponent
            appointmentValues={props?.appointmentValues}
            close={handleClose}
          />
        </DialogTitle>

        <DialogContent className="booking-appointment-popup-content">
          {renderDialogContent()}
        </DialogContent>

        <DialogActions
          disableSpacing={true}
          className="booking-appointment-popup-actions">
          {renderDialogActions()}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddPatientComponent;
