/* eslint-disable camelcase */
import React, {useState} from 'react';
import {
  Box,
  Grid,
  FormControl,
  TextField,
  IconButton,
  FormLabel,
  Button,
  Typography,
  Avatar
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import CloseBlackIcon from '@components/system/MyIcons/CloseBlackIcon';
import {actionType, StepsCommonProps} from '../Appointment';
import './AppointmentSteps.scss';
import {bookAppointment} from '@shared/services/PublicService';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {useTranslation} from 'react-i18next';
import {clone, get, isEmpty, startCase, upperCase} from 'lodash';
import StethoscopeIcon from '@components/system/MyIcons/StethoscopeIcon';

const useStyles = makeStyles({
  container: {
    flexWrap: 'nowrap',
    flexDirection: 'column'
  },
  header: {},
  headerTitle: {
    display: 'flex',
    flexDirection: 'column'
  },
  subTitle: {
    color: 'grey'
  }
});

type FormFiveState = {
  compliant: string;
};

function StepFive({
  data,
  doctorId,
  branchId,
  doctorName,
  dispatch
}: StepsCommonProps): React.ReactElement {
  const styles = useStyles();
  const [formFive, setFormFive] = useState<FormFiveState>({
    compliant: ''
  });
  const {
    formFour: {
      selectedPatient = {},
      appointmentType = {},
      selectedDate = null,
      selectedTimeSlot = ''
    } = {}
  } = data;
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const validData = (): boolean => {
    const copyRequiredData = {
      ...data?.formFour,
      doctorId,
      branchId,
      compliant: formFive.compliant
    };
    const fieldsToCheck = [
      'doctorId',
      'branchId',
      'selectedPatient.hlpid',
      'appointmentType.appointment_type_value',
      'appointmentType.price',
      'selectedDate',
      'selectedTimeSlot',
      'compliant'
    ];
    return fieldsToCheck.every(fieldPath => {
      const fieldValue = get(copyRequiredData, fieldPath);
      switch (typeof fieldValue) {
        case 'string':
          return !isEmpty(fieldValue);
        case 'object':
          if (fieldValue instanceof moment) return moment(fieldValue).isValid();
          return !isEmpty(fieldValue);
        default:
          return false;
      }
    });
  };
  const onSubmitHandler = (e: any): void => {
    e.preventDefault();
    // [TODO] Check valid form
    const payload = {
      doctor_id: doctorId,
      branch_id: branchId,
      appointment_date: moment(selectedDate).isValid()
        ? moment(selectedDate).format('YYYY-MM-DD')
        : '',
      appointment_time: selectedTimeSlot,
      appointment_type: appointmentType.appointment_type_value,
      description: formFive.compliant,
      amount: appointmentType.price,
      type: appointmentType.appointment_type_value
    };
    if (validData()) {
      setLoading(true);
      bookAppointment(selectedPatient.hlpid, payload)
        .then(res => {
          if (res?.status_code === 200) {
            dispatch({
              type: actionType.NEXT_STEP,
              payload: {...data, formFive, ...res.data}
            });
          } else {
            throw new Error(res?.message);
          }
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const constructDateTime = () => {
    const copyDate = clone(selectedDate);
    const [hrs, mins] = selectedTimeSlot.split(':');
    return moment(copyDate).set({h: hrs, m: mins}).format('DD/MM/YYYY, hh:mmA');
  };
  return (
    <Grid container p={2.5} className={styles.container}>
      <form id="step-five" onSubmit={onSubmitHandler}>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          className={styles.header}>
          <Grid item>
            <Box className={styles.headerTitle}>
              <Box>{t('CLINIC_CONFIRM_APPOINTMENT')}</Box>
            </Box>
          </Grid>
          <Grid item>
            <IconButton
              sx={{padding: 0}}
              onClick={() =>
                dispatch({type: actionType.CLOSE_MODAL, payload: null})
              }>
              <CloseBlackIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          item
          mt={1}
          justifyContent="space-between"
          alignItems="flex-start"
          flexWrap="nowrap">
          <Grid
            container
            item
            direction="column"
            sx={{justifyContent: 'space-between', marginRight: '3.125rem'}}>
            <Grid item display="flex" flexDirection="column">
              <Typography fontWeight="bold" variant="h4">
                {startCase(selectedPatient.fullName)}
              </Typography>
              <Typography
                sx={{fontSize: '0.9rem', marginTop: '0.125rem'}}
                className="text-capitalize">
                {`${selectedPatient.gender}, ${
                  selectedPatient.age.split(' ')[0]
                }`}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="column" mt={3}>
              <StethoscopeIcon />
              <Typography variant="h5" className={styles.subTitle}>
                {t('CLINIC_APPOINTMENT_TYPE')}
              </Typography>
              <Typography variant="h5">
                {appointmentType.appointment_type_name}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="column" mt={2}>
              <Typography variant="h5" className={styles.subTitle}>
                Appointment Date &amp; Time
              </Typography>
              <Typography variant="h5">{constructDateTime()}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Avatar src={undefined} alt="Daniel" sx={{height: 56, width: 56}}>
              {upperCase(selectedPatient.fullName[0])}
            </Avatar>
          </Grid>
        </Grid>
        <Grid item mt={2} mb={1}>
          <FormControl
            fullWidth
            className="outlined-textfield"
            variant="standard">
            <FormLabel htmlFor="step-five-compliant">Compliants</FormLabel>
            <TextField
              id="step-five-compliant"
              aria-label="maximum height"
              multiline
              rows={3}
              placeholder={t('CLINIC_WRITE_HERE')}
              defaultValue=""
              value={formFive.compliant}
              onChange={event =>
                setFormFive(state => ({
                  ...state,
                  compliant: event.target.value
                }))
              }
              style={{
                border: '0.0625rem solid lightgrey',
                borderRadius: '0.125rem',
                padding: 2,
                width: '100%'
              }}
              required
            />
          </FormControl>
        </Grid>
        <Grid container item justifyContent="center" alignItems="center">
          <Grid item sx={{justifyContent: 'center', width: '100%'}}>
            <Button
              fullWidth
              variant="contained"
              disabled={loading || !validData()}
              type="submit">
              {t('CLINIC_CONFIRM_APPOINTMENT')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default StepFive;
