/* eslint-disable camelcase */
import React, {useState} from 'react';
import {
  Box,
  Grid,
  // FormControl,
  // InputLabel,
  // OutlinedInput,
  // TextField,
  IconButton,
  // FormLabel,
  Button,
  Divider,
  // Icon,
  Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import CommonUtils from 'src/utils/CommonUtils';
// import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import MyIcon from '@components/system/MyIcons';
import CloseBlackIcon from '@components/system/MyIcons/CloseBlackIcon';
import {actionType, StepsCommonProps} from '../Appointment';
import './AppointmentSteps.scss';
import {signUpPerson} from '@shared/services/PublicService';
// import {NotificationTypes} from 'src/constants/SystemConstants';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';

const useStyles = makeStyles({
  container: {
    flexWrap: 'nowrap',
    flexDirection: 'column'
  },
  header: {
    justifyContent: 'end'
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'column'
  }
});

function StepThree({data, dispatch}: StepsCommonProps): React.ReactElement {
  const styles = useStyles();
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      const patientPayload = {
        first_name: data?.formOne?.firstName,
        last_name: data?.formOne?.lastName,
        phone_code: data?.formOne?.phoneCode,
        phone_number: data?.formOne?.mobileNumber,
        email: data?.formOne?.email,
        dob: CommonUtils.getDobFormat(data?.formOne?.dateOfBirth),
        gender: data?.formOne?.gender,
        password: data?.formOne?.password || 'test123@123',
        salutation: data?.formOne?.salutation || 'Mr'
      };
      signUpPerson(patientPayload)
        .then(res => {
          if (res?.status_code !== 200) {
            throw new Error(res?.message);
          }
          dispatch({
            type: actionType.NEXT_STEP,
            payload: {
              ...data,
              formThree: {...patientPayload, hlpid: res?.data?.hlpid}
            }
          });
          setLoading(true);
        })
        .catch(err => {
          setLoading(false);
          enqueueSnackbar(
            err.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    }
  };
  return (
    <form id="step-three" onSubmit={onSubmitHandler}>
      <Grid container p={2} className={styles.container}>
        {/* <Box className={clsx()}> */}
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          className={styles.header}>
          <Grid item>
            <IconButton
              sx={{padding: 0}}
              onClick={() =>
                dispatch({type: actionType.CLOSE_MODAL, payload: null})
              }>
              <CloseBlackIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item alignItems="center" mb={1.5}>
          <Grid item>
            <MyIcon.UserIcon className="step-three-user-icon" />
          </Grid>
          <Grid item sx={{paddingLeft: '10px'}}>
            <Box fontWeight="bold">Patient Added</Box>
            <Typography fontSize="0.8rem" variant="caption">
              {`${data.formOne.firstName} ${data.formOne.lastName} has been added successfuly`}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{marginBottom: '20px'}} />
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item width="400px" mb={1}>
            <Typography
              variant="h3"
              sx={{margin: 'auto', textAlign: 'center', width: '310px'}}>
              Do you want to Book an appointment for this patient
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          direction="column">
          <Grid item width="100%" mb={1}>
            <Button
              disabled={loading}
              fullWidth
              variant="contained"
              type="submit">
              Book Appointment
            </Button>
          </Grid>
          <Grid item width="100%">
            <Button
              fullWidth
              variant="contained"
              sx={{
                color: '#04A6D6',
                background: 'lightgrey',
                ':hover': {
                  background: '#eee'
                }
              }}
              onClick={() =>
                dispatch({type: actionType.CLOSE_MODAL, payload: null})
              }>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default StepThree;
