/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import NavRoutes from 'src/constants/route/NavRoutes';
import './ConfirmationBoxComponent.scss';

const ConfirmationBoxComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(props?.open);

  const handleClose = () => {
    setOpen(false);
    if (props?.clearStates && typeof props.clearStates === 'function') {
      props?.clearStates();
    }
    if (
      props?.closeConfirmationBox &&
      typeof props.closeConfirmationBox === 'function' &&
      !props?.isFromPersonWeb
    ) {
      props.closeConfirmationBox();
      props?.isFromPersonWeb &&
        history.push(`/portal/user/${NavRoutes.PUBLIC.PERSON_WEB_TIMELINE}`);
    } else if (props?.isAppointmentFlow && !props?.isFromPersonWeb) {
      history.push(`/portal/user/dashboard`);
    } else if (props?.isFromPersonWeb) {
      if (
        props?.closeConfirmationBox &&
        typeof props.closeConfirmationBox === 'function'
      ) {
        props.closeConfirmationBox();
      }

      if (props?.closeInfoBox && typeof props.closeInfoBox === 'function') {
        props.closeInfoBox();
      }

      history.push(`/portal/user/${NavRoutes.PUBLIC.PERSON_WEB_TIMELINE}`);
    }
  };

  const renderFormSection = (): React.ReactElement => {
    return (
      <Grid>
        <Grid item xs={12}>
          <Typography>{props?.message}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return <Grid style={{marginTop: '0.5rem'}}>{renderFormSection()}</Grid>;
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container item xs={4} justifyContent="flex-end">
        <Button
          id="confirm"
          variant="contained"
          size="medium"
          color="primary"
          className="submit-button"
          style={{width: 'auto', height: '3rem'}}
          onClick={() => handleClose()}>
          {props?.ButtonText ? t(props?.ButtonText) : t('OK')}
        </Button>
      </Grid>
    );
  };

  return (
    <Grid className="confirmation-box-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        aria-labelledby="confirmation-pop-up"
        aria-describedby="confirmation"
        classes={{paper: 'confirmation-box-popup'}}>
        <Grid container spacing={1}>
          <Grid item xs={12} className="popup-right-section">
            <DialogContent className="popup-content">
              {renderDialogContent()}
            </DialogContent>

            <DialogActions disableSpacing={true}>
              {renderDialogActions()}
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default ConfirmationBoxComponent;
