import {
  SET_LOGIN_AUTH,
  SET_LOGOUT_AUTH,
  INITIALIZE_APP,
  SET_LOGIN_ADMIN_AUTH,
  SET_LOGOUT_ADMIN_AUTH,
  SET_LOGIN_ADVISOR_AUTH,
  SET_LOGOUT_ADVISOR_AUTH
} from '../actions/Index';

// Defining the initial state for the reducer
const initState = {
  authToken: null,
  adminAuthToken: null,
  advisorAuthToken: null,
  initialRoute: '',
  isEnrolledForBioAuth: false,
  deviceHasTouchId: false,
  deviceHasFaceId: false,
  oauth: null
};

export default function (state = initState, action: any = {}): any {
  const {type, payload} = action;
  switch (type) {
    case SET_LOGIN_AUTH:
      return {
        ...state,
        authToken: payload.authToken,
        initialRoute: payload.initialRoute || '',
        oauth: payload.oauth
      };
    case SET_LOGIN_ADMIN_AUTH:
      return {
        ...state,
        adminAuthToken: payload.authToken,
        initialRoute: payload.initialRoute || '',
        oauth: payload.oauth
      };
    case SET_LOGIN_ADVISOR_AUTH:
      return {
        ...state,
        advisorAuthToken: payload.authToken,
        initialRoute: payload.initialRoute || '',
        oauth: payload.oauth
      };
    case SET_LOGOUT_AUTH:
      return {
        ...state,
        authToken: null,
        initialRoute: '',
        oauth: null
      };
    case SET_LOGOUT_ADMIN_AUTH:
      return {
        ...state,
        adminAuthToken: null,
        initialRoute: '',
        oauth: null
      };
    case SET_LOGOUT_ADVISOR_AUTH:
      return {
        ...state,
        advisorAuthToken: null,
        initialRoute: '',
        oauth: null
      };
    case INITIALIZE_APP:
      return {...state, ...payload.config};
    default:
      return state;
  }
}
