import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import { ReactComponent as ReportIconSvg } from '@assets/images/healpha/report.svg';

const ReportIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={ReportIconSvg} viewBox="0 0 24 24" />
  );
};

export default ReportIcon;