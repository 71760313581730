import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Button, Grid, Typography} from '@material-ui/core';

import CovidCareIconSvg from '@assets/images/healpha/forums_illustration.svg';
import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';
import NavRoutes from 'src/constants/route/NavRoutes';
import './ForumsHeaderCardComponent.scss';

const ForumsHeaderCardComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const history = useHistory();

  const goToAskQuestion = () => {
    if (props?.isFrom == 'dashboard')
      history.push(
        `/${BASE_ROUTE_PORTAL}/user/${
          NavRoutes.PUBLIC.ASK_QUESTION_SHARED
        }?from=${props?.spam == 'admin' && props?.spam}&to=${
          props?.spam == 'admin' && props?.to
        }`
      );
    else
      history.push(
        `/${BASE_ROUTE_PORTAL}/home/${NavRoutes.PUBLIC.ASK_QUESTION}`
      );
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent={props?.showButton ? 'center' : 'flex-start'}
      alignItems={props?.showButton ? 'center' : 'flex-start'}
      className={[
        'forums-description-section',
        !props?.showButton && 'forums-description-section-2'
      ].join(' ')}>
      <Grid item xs={props?.showButton ? 9 : 8} className="section-1">
        <Typography
          className={['title', !props?.showButton && 'title-2'].join(' ')}>
          {t('APP_HEALTH_FORUMS', {appName: 'Healpha'})}
        </Typography>

        <Typography
          className={['subtitle', !props?.showButton && 'subtitle-2'].join(
            ' '
          )}>
          {props?.subTitle}
        </Typography>

        {props?.showButton && (
          <Button
            variant="contained"
            color="primary"
            className="btn-sec"
            size="medium"
            onClick={goToAskQuestion}>
            {t('ASK_A_QUESTION')}
          </Button>
        )}
      </Grid>
      <Grid item xs={props?.showButton ? 3 : 4} className="section-2">
        <img src={CovidCareIconSvg} className="forums-icon" alt="React Logo" />
      </Grid>
    </Grid>
  );
};
export default ForumsHeaderCardComponent;
