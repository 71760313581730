/* eslint-disable camelcase */
import moment from 'moment';
import _ from 'lodash';

import {GenderTypes} from 'src/constants/FormFields';

export const Diastolic = [
  {
    min: 0,
    max: 59,
    value: 0
  },
  {
    min: 60,
    max: 69,
    value: 0.5
  },
  {
    min: 70,
    max: 80,
    value: 1
  },
  {
    min: 81,
    max: 89,
    value: 0.5
  },
  {
    min: 90,
    max: 'above',
    value: 0
  }
];

export const BoyHyperBP: any = {
  0: {
    5: 46.8,
    10: 47.5,
    25: 48.6,
    50: 49.9,
    75: 51.2,
    90: 52.3,
    95: 53
  },
  1: {
    5: 51.5,
    10: 52.2,
    25: 53.4,
    50: 54.7,
    75: 56,
    90: 57.2,
    95: 57.9
  },
  2: {
    5: 55.1,
    10: 55.9,
    25: 57.1,
    50: 58.4,
    75: 59.8,
    90: 61,
    95: 61.7
  },
  3: {
    5: 58.1,
    10: 58.8,
    25: 60.1,
    50: 61.4,
    75: 62.8,
    90: 64,
    95: 64.8
  },
  4: {
    5: 60.5,
    10: 61.2,
    25: 62.5,
    50: 63.9,
    75: 65.3,
    90: 66.6,
    95: 67.3
  },
  5: {
    5: 62.4,
    10: 63.2,
    25: 64.5,
    50: 65.9,
    75: 67.3,
    90: 68.6,
    95: 69.4
  },
  6: {
    5: 64.1,
    10: 64.9,
    25: 66.2,
    50: 67.6,
    75: 69.1,
    90: 70.4,
    95: 71.1
  },
  7: {
    5: 65.6,
    10: 66.4,
    25: 67.7,
    50: 69.2,
    75: 70.6,
    90: 71.9,
    95: 72.7
  },
  8: {
    5: 67,
    10: 67.8,
    25: 69.1,
    50: 70.6,
    75: 72.1,
    90: 73.4,
    95: 74.2
  },
  9: {
    5: 68.3,
    10: 69.1,
    25: 70.5,
    50: 72,
    75: 73.5,
    90: 74.8,
    95: 75.7
  },
  10: {
    5: 69.5,
    10: 70.4,
    25: 71.7,
    50: 73.3,
    75: 74.8,
    90: 76.2,
    95: 77
  },
  11: {
    5: 70.7,
    10: 71.6,
    25: 73,
    50: 74.5,
    75: 76.1,
    90: 77.5,
    95: 78.4
  },
  12: {
    5: 71.8,
    10: 72.7,
    25: 74.1,
    50: 75.7,
    75: 77.4,
    90: 78.8,
    95: 79.7
  },
  13: {
    5: 72.9,
    10: 73.8,
    25: 75.3,
    50: 76.9,
    75: 78.6,
    90: 80,
    95: 80.9
  },
  14: {
    5: 74,
    10: 74.9,
    25: 76.4,
    50: 78,
    75: 79.7,
    90: 81.2,
    95: 82.1
  },
  15: {
    5: 75,
    10: 75.9,
    25: 77.4,
    50: 79.1,
    75: 80.9,
    90: 82.4,
    95: 83.3
  },
  16: {
    5: 76,
    10: 76.9,
    25: 78.5,
    50: 80.2,
    75: 82,
    90: 83.5,
    95: 84.5
  },
  17: {
    5: 76.9,
    10: 77.9,
    25: 79.5,
    50: 81.2,
    75: 83,
    90: 84.6,
    95: 85.6
  },
  18: {
    5: 77.8,
    10: 78.8,
    25: 80.4,
    50: 82.3,
    75: 84.1,
    90: 85.7,
    95: 86.7
  },
  19: {
    5: 78.7,
    10: 79.7,
    25: 81.4,
    50: 83.2,
    75: 85.1,
    90: 86.8,
    95: 87.8
  },
  20: {
    5: 79.6,
    10: 80.6,
    25: 82.3,
    50: 84.2,
    75: 86.1,
    90: 87.8,
    95: 88.8
  },
  21: {
    5: 80.4,
    10: 81.5,
    25: 83.2,
    50: 85.1,
    75: 87.1,
    90: 88.8,
    95: 89.9
  },
  22: {
    5: 81.2,
    10: 82.3,
    25: 84.1,
    50: 86,
    75: 88,
    90: 89.8,
    95: 90.9
  },
  23: {
    5: 82,
    10: 83.1,
    25: 84.9,
    50: 86.9,
    75: 89,
    90: 90.8,
    95: 91.9
  },
  24: {
    5: 82.1,
    10: 83.2,
    25: 85.1,
    50: 87.1,
    75: 89.2,
    90: 91,
    95: 92.1
  },
  25: {
    5: 82.8,
    10: 84,
    25: 85.9,
    50: 88,
    75: 90.1,
    90: 92,
    95: 93.1
  },
  26: {
    5: 83.6,
    10: 84.7,
    25: 86.7,
    50: 88.8,
    75: 90.9,
    90: 92.9,
    95: 94
  },
  27: {
    5: 84.3,
    10: 85.5,
    25: 87.4,
    50: 89.6,
    75: 91.8,
    90: 93.8,
    95: 94.9
  },
  28: {
    5: 85,
    10: 86.2,
    25: 88.2,
    50: 90.4,
    75: 92.6,
    90: 94.6,
    95: 95.8
  },
  29: {
    5: 85.7,
    10: 86.9,
    25: 88.9,
    50: 91.2,
    75: 93.4,
    90: 95.5,
    95: 96.7
  },
  30: {
    5: 86.3,
    10: 87.6,
    25: 89.6,
    50: 91.9,
    75: 94.2,
    90: 96.3,
    95: 97.5
  },
  31: {
    5: 87,
    10: 88.2,
    25: 90.3,
    50: 92.7,
    75: 95,
    90: 97.1,
    95: 98.4
  },
  32: {
    5: 87.6,
    10: 88.9,
    25: 91,
    50: 93.4,
    75: 95.7,
    90: 97.9,
    95: 99.2
  },
  33: {
    5: 88.2,
    10: 89.5,
    25: 91.7,
    50: 94.1,
    75: 96.5,
    90: 98.6,
    95: 99.9
  },
  34: {
    5: 88.8,
    10: 90.1,
    25: 92.3,
    50: 94.8,
    75: 97.2,
    90: 99.4,
    95: 100.7
  },
  35: {
    5: 89.4,
    10: 90.7,
    25: 93,
    50: 95.4,
    75: 97.9,
    90: 100.1,
    95: 101.4
  },
  36: {
    5: 90,
    10: 91.3,
    25: 93.6,
    50: 96.1,
    75: 98.6,
    90: 100.8,
    95: 102.2
  },
  37: {
    5: 90.6,
    10: 91.9,
    25: 94.2,
    50: 96.7,
    75: 99.3,
    90: 101.5,
    95: 102.9
  },
  38: {
    5: 91.1,
    10: 92.5,
    25: 94.8,
    50: 97.4,
    75: 99.9,
    90: 102.2,
    95: 103.6
  },
  39: {
    5: 91.7,
    10: 93.1,
    25: 95.4,
    50: 98,
    75: 100.6,
    90: 102.9,
    95: 104.3
  },
  40: {
    5: 92.2,
    10: 93.7,
    25: 96,
    50: 98.6,
    75: 101.3,
    90: 103.6,
    95: 105
  },
  41: {
    5: 92.8,
    10: 94.2,
    25: 96.6,
    50: 99.2,
    75: 101.9,
    90: 104.3,
    95: 105.7
  },
  42: {
    5: 93.3,
    10: 94.8,
    25: 97.2,
    50: 99.9,
    75: 102.5,
    90: 104.9,
    95: 106.4
  },
  43: {
    5: 93.9,
    10: 95.3,
    25: 97.7,
    50: 100.4,
    75: 103.1,
    90: 105.6,
    95: 107
  },
  44: {
    5: 94.4,
    10: 95.9,
    25: 98.3,
    50: 101,
    75: 103.8,
    90: 106.2,
    95: 107.7
  },
  45: {
    5: 94.9,
    10: 96.4,
    25: 98.9,
    50: 101.6,
    75: 104.4,
    90: 106.8,
    95: 108.3
  },
  46: {
    5: 95.4,
    10: 96.9,
    25: 99.4,
    50: 102.2,
    75: 105,
    90: 107.5,
    95: 109
  },
  47: {
    5: 95.9,
    10: 97.4,
    25: 100,
    50: 102.8,
    75: 105.6,
    90: 108.1,
    95: 109.6
  },
  48: {
    5: 96.4,
    10: 98,
    25: 100.5,
    50: 103.3,
    75: 106.2,
    90: 108.7,
    95: 110.2
  },
  49: {
    5: 96.9,
    10: 98.5,
    25: 101,
    50: 103.9,
    75: 106.7,
    90: 109.3,
    95: 110.8
  },
  50: {
    5: 97.4,
    10: 99,
    25: 101.6,
    50: 104.4,
    75: 107.3,
    90: 109.9,
    95: 111.5
  },
  51: {
    5: 97.9,
    10: 99.5,
    25: 102.1,
    50: 105,
    75: 107.9,
    90: 110.5,
    95: 112.1
  },
  52: {
    5: 98.4,
    10: 100,
    25: 102.6,
    50: 105.6,
    75: 108.5,
    90: 111.1,
    95: 112.7
  },
  53: {
    5: 98.9,
    10: 100.5,
    25: 103.2,
    50: 106.1,
    75: 109.1,
    90: 111.7,
    95: 113.3
  },
  54: {
    5: 99.4,
    10: 101,
    25: 103.7,
    50: 106.7,
    75: 109.6,
    90: 112.3,
    95: 113.9
  },
  55: {
    5: 99.9,
    10: 101.5,
    25: 104.2,
    50: 107.2,
    75: 110.2,
    90: 112.9,
    95: 114.5
  },
  56: {
    5: 100.4,
    10: 102,
    25: 104.7,
    50: 107.8,
    75: 110.8,
    90: 113.5,
    95: 115.2
  },
  57: {
    5: 100.9,
    10: 102.5,
    25: 105.3,
    50: 108.3,
    75: 111.4,
    90: 114.1,
    95: 115.8
  },
  58: {
    5: 101.4,
    10: 103,
    25: 105.8,
    50: 108.9,
    75: 111.9,
    90: 114.7,
    95: 116.4
  },
  59: {
    5: 101.9,
    10: 103.5,
    25: 106.3,
    50: 109.4,
    75: 112.5,
    90: 115.3,
    95: 117
  },
  60: {
    5: 102.3,
    10: 104,
    25: 106.8,
    50: 110,
    75: 113.1,
    90: 115.9,
    95: 117.6
  },
  61: {
    5: 102.7,
    10: 104.4,
    25: 107.2,
    50: 110.3,
    75: 113.4,
    90: 116.1,
    95: 117.8
  },
  62: {
    5: 103.2,
    10: 104.9,
    25: 107.7,
    50: 110.8,
    75: 113.9,
    90: 116.7,
    95: 118.4
  },
  63: {
    5: 103.7,
    10: 105.4,
    25: 108.2,
    50: 111.3,
    75: 114.5,
    90: 117.3,
    95: 119
  },
  64: {
    5: 104.2,
    10: 105.9,
    25: 108.7,
    50: 111.9,
    75: 115,
    90: 117.9,
    95: 119.6
  },
  65: {
    5: 104.6,
    10: 106.3,
    25: 109.2,
    50: 112.4,
    75: 115.6,
    90: 118.4,
    95: 120.1
  },
  66: {
    5: 105.1,
    10: 106.8,
    25: 109.7,
    50: 112.9,
    75: 116.1,
    90: 119,
    95: 120.7
  },
  67: {
    5: 105.6,
    10: 107.3,
    25: 110.2,
    50: 113.4,
    75: 116.7,
    90: 119.5,
    95: 121.3
  },
  68: {
    5: 106,
    10: 107.8,
    25: 110.7,
    50: 113.9,
    75: 117.2,
    90: 120.1,
    95: 121.8
  },
  69: {
    5: 106.5,
    10: 108.3,
    25: 111.2,
    50: 114.5,
    75: 117.7,
    90: 120.6,
    95: 122.4
  },
  70: {
    5: 106.9,
    10: 108.7,
    25: 111.7,
    50: 115,
    75: 118.2,
    90: 121.2,
    95: 123
  },
  71: {
    5: 107.4,
    10: 109.2,
    25: 112.2,
    50: 115.5,
    75: 118.8,
    90: 121.7,
    95: 123.5
  },
  72: {
    5: 107.8,
    10: 109.6,
    25: 112.6,
    50: 116,
    75: 119.3,
    90: 122.3,
    95: 124.1
  },
  73: {
    5: 108.3,
    10: 110.1,
    25: 113.1,
    50: 116.4,
    75: 119.8,
    90: 122.8,
    95: 124.6
  },
  74: {
    5: 108.7,
    10: 110.5,
    25: 113.6,
    50: 116.9,
    75: 120.3,
    90: 123.3,
    95: 125.1
  },
  75: {
    5: 109.2,
    10: 111,
    25: 114,
    50: 117.4,
    75: 120.8,
    90: 123.8,
    95: 125.7
  },
  76: {
    5: 109.6,
    10: 111.4,
    25: 114.5,
    50: 117.9,
    75: 121.3,
    90: 124.4,
    95: 126.2
  },
  77: {
    5: 110,
    10: 111.9,
    25: 115,
    50: 118.4,
    75: 121.8,
    90: 124.9,
    95: 126.7
  },
  78: {
    5: 110.5,
    10: 112.3,
    25: 115.4,
    50: 118.9,
    75: 122.3,
    90: 125.4,
    95: 127.3
  },
  79: {
    5: 110.9,
    10: 112.8,
    25: 115.9,
    50: 119.4,
    75: 122.8,
    90: 125.9,
    95: 127.8
  },
  80: {
    5: 111.3,
    10: 113.2,
    25: 116.3,
    50: 119.8,
    75: 123.3,
    90: 126.5,
    95: 128.3
  },
  81: {
    5: 111.8,
    10: 113.7,
    25: 116.8,
    50: 120.3,
    75: 123.8,
    90: 127,
    95: 128.9
  },
  82: {
    5: 112.2,
    10: 114.1,
    25: 117.3,
    50: 120.8,
    75: 124.3,
    90: 127.5,
    95: 129.4
  },
  83: {
    5: 112.6,
    10: 114.5,
    25: 117.7,
    50: 121.3,
    75: 124.8,
    90: 128,
    95: 129.9
  },
  84: {
    5: 113,
    10: 115,
    25: 118.2,
    50: 121.7,
    75: 125.3,
    90: 128.5,
    95: 130.4
  },
  85: {
    5: 113.5,
    10: 115.4,
    25: 118.6,
    50: 122.2,
    75: 125.8,
    90: 129,
    95: 130.9
  },
  86: {
    5: 113.9,
    10: 115.8,
    25: 119.1,
    50: 122.7,
    75: 126.3,
    90: 129.5,
    95: 131.5
  },
  87: {
    5: 114.3,
    10: 116.3,
    25: 119.5,
    50: 123.1,
    75: 126.8,
    90: 130,
    95: 132
  },
  88: {
    5: 114.7,
    10: 116.7,
    25: 120,
    50: 123.6,
    75: 127.3,
    90: 130.5,
    95: 132.5
  },
  89: {
    5: 115.1,
    10: 117.1,
    25: 120.4,
    50: 124.1,
    75: 127.7,
    90: 131,
    95: 133
  },
  90: {
    5: 115.5,
    10: 117.5,
    25: 120.8,
    50: 124.5,
    75: 128.2,
    90: 131.5,
    95: 133.5
  },
  91: {
    5: 116,
    10: 118,
    25: 121.3,
    50: 125,
    75: 128.7,
    90: 132,
    95: 134
  },
  92: {
    5: 116.4,
    10: 118.4,
    25: 121.7,
    50: 125.5,
    75: 129.2,
    90: 132.5,
    95: 134.5
  },
  93: {
    5: 116.8,
    10: 118.8,
    25: 122.2,
    50: 125.9,
    75: 129.7,
    90: 133,
    95: 135.1
  },
  94: {
    5: 117.2,
    10: 119.2,
    25: 122.6,
    50: 126.4,
    75: 130.1,
    90: 133.5,
    95: 135.6
  },
  95: {
    5: 117.6,
    10: 119.6,
    25: 123,
    50: 126.8,
    75: 130.6,
    90: 134,
    95: 136.1
  },
  96: {
    5: 118,
    10: 120,
    25: 123.5,
    50: 127.3,
    75: 131.1,
    90: 134.5,
    95: 136.6
  },
  97: {
    5: 118.4,
    10: 120.4,
    25: 123.9,
    50: 127.7,
    75: 131.5,
    90: 135,
    95: 137.1
  },
  98: {
    5: 118.8,
    10: 120.8,
    25: 124.3,
    50: 128.2,
    75: 132,
    90: 135.5,
    95: 137.5
  },
  99: {
    5: 119.2,
    10: 121.2,
    25: 124.7,
    50: 128.6,
    75: 132.5,
    90: 136,
    95: 138
  },
  100: {
    5: 119.6,
    10: 121.7,
    25: 125.2,
    50: 129,
    75: 132.9,
    90: 136.4,
    95: 138.5
  },
  101: {
    5: 120,
    10: 122.1,
    25: 125.6,
    50: 129.5,
    75: 133.4,
    90: 136.9,
    95: 139
  },
  102: {
    5: 120.3,
    10: 122.5,
    25: 126,
    50: 129.9,
    75: 133.9,
    90: 137.4,
    95: 139.5
  },
  103: {
    5: 120.7,
    10: 122.9,
    25: 126.4,
    50: 130.4,
    75: 134.3,
    90: 137.9,
    95: 140
  },
  104: {
    5: 121.1,
    10: 123.3,
    25: 126.8,
    50: 130.8,
    75: 134.8,
    90: 138.4,
    95: 140.5
  },
  105: {
    5: 121.5,
    10: 123.7,
    25: 127.3,
    50: 131.3,
    75: 135.2,
    90: 138.8,
    95: 141
  },
  106: {
    5: 121.9,
    10: 124.1,
    25: 127.7,
    50: 131.7,
    75: 135.7,
    90: 139.3,
    95: 141.5
  },
  107: {
    5: 122.3,
    10: 124.5,
    25: 128.1,
    50: 132.1,
    75: 136.2,
    90: 139.8,
    95: 142
  },
  108: {
    5: 122.7,
    10: 124.9,
    25: 128.5,
    50: 132.6,
    75: 136.6,
    90: 140.3,
    95: 142.5
  },
  109: {
    5: 123.1,
    10: 125.3,
    25: 128.9,
    50: 133,
    75: 137.1,
    90: 140.7,
    95: 142.9
  },
  110: {
    5: 123.5,
    10: 125.7,
    25: 129.3,
    50: 133.4,
    75: 137.5,
    90: 141.2,
    95: 143.4
  },
  111: {
    5: 123.8,
    10: 126.1,
    25: 129.8,
    50: 133.9,
    75: 138,
    90: 141.7,
    95: 143.9
  },
  112: {
    5: 124.2,
    10: 126.5,
    25: 130.2,
    50: 134.3,
    75: 138.4,
    90: 142.2,
    95: 144.4
  },
  113: {
    5: 124.6,
    10: 126.9,
    25: 130.6,
    50: 134.7,
    75: 138.9,
    90: 142.6,
    95: 144.9
  },
  114: {
    5: 125,
    10: 127.2,
    25: 131,
    50: 135.2,
    75: 139.4,
    90: 143.1,
    95: 145.4
  },
  115: {
    5: 125.4,
    10: 127.6,
    25: 131.4,
    50: 135.6,
    75: 139.8,
    90: 143.6,
    95: 145.9
  },
  116: {
    5: 125.8,
    10: 128,
    25: 131.8,
    50: 136.1,
    75: 140.3,
    90: 144.1,
    95: 146.3
  },
  117: {
    5: 126.1,
    10: 128.4,
    25: 132.2,
    50: 136.5,
    75: 140.7,
    90: 144.5,
    95: 146.8
  },
  118: {
    5: 126.5,
    10: 128.8,
    25: 132.7,
    50: 136.9,
    75: 141.2,
    90: 145,
    95: 147.3
  },
  119: {
    5: 126.9,
    10: 129.2,
    25: 133.1,
    50: 137.3,
    75: 141.6,
    90: 145.5,
    95: 147.8
  },
  120: {
    5: 127.3,
    10: 129.6,
    25: 133.5,
    50: 137.8,
    75: 142.1,
    90: 145.9,
    95: 148.3
  },
  121: {
    5: 127.7,
    10: 130,
    25: 133.9,
    50: 138.2,
    75: 142.5,
    90: 146.4,
    95: 148.7
  },
  122: {
    5: 128.1,
    10: 130.4,
    25: 134.3,
    50: 138.6,
    75: 143,
    90: 146.9,
    95: 149.2
  },
  123: {
    5: 128.4,
    10: 130.8,
    25: 134.7,
    50: 139.1,
    75: 143.4,
    90: 147.4,
    95: 149.7
  },
  124: {
    5: 128.8,
    10: 131.2,
    25: 135.1,
    50: 139.5,
    75: 143.9,
    90: 147.8,
    95: 150.2
  },
  125: {
    5: 129.2,
    10: 131.6,
    25: 135.6,
    50: 140,
    75: 144.4,
    90: 148.3,
    95: 150.7
  },
  126: {
    5: 129.6,
    10: 132,
    25: 136,
    50: 140.4,
    75: 144.8,
    90: 148.8,
    95: 151.2
  },
  127: {
    5: 130,
    10: 132.4,
    25: 136.4,
    50: 140.8,
    75: 145.3,
    90: 149.3,
    95: 151.7
  },
  128: {
    5: 130.4,
    10: 132.8,
    25: 136.8,
    50: 141.3,
    75: 145.7,
    90: 149.8,
    95: 152.2
  },
  129: {
    5: 130.8,
    10: 133.2,
    25: 137.3,
    50: 141.7,
    75: 146.2,
    90: 150.2,
    95: 152.7
  },
  130: {
    5: 131.2,
    10: 133.6,
    25: 137.7,
    50: 142.2,
    75: 146.7,
    90: 150.7,
    95: 153.2
  },
  131: {
    5: 131.6,
    10: 134.1,
    25: 138.1,
    50: 142.7,
    75: 147.2,
    90: 151.2,
    95: 153.7
  },
  132: {
    5: 132,
    10: 134.5,
    25: 138.6,
    50: 143.1,
    75: 147.7,
    90: 151.7,
    95: 154.2
  },
  133: {
    5: 132.5,
    10: 134.9,
    25: 139,
    50: 143.6,
    75: 148.1,
    90: 152.2,
    95: 154.7
  },
  134: {
    5: 132.9,
    10: 135.3,
    25: 139.5,
    50: 144.1,
    75: 148.6,
    90: 152.8,
    95: 155.2
  },
  135: {
    5: 133.3,
    10: 135.8,
    25: 139.9,
    50: 144.5,
    75: 149.1,
    90: 153.3,
    95: 155.7
  },
  136: {
    5: 133.7,
    10: 136.2,
    25: 140.4,
    50: 145,
    75: 149.6,
    90: 153.8,
    95: 156.3
  },
  137: {
    5: 134.2,
    10: 136.7,
    25: 140.9,
    50: 145.5,
    75: 150.1,
    90: 154.3,
    95: 156.8
  },
  138: {
    5: 134.6,
    10: 137.1,
    25: 141.3,
    50: 146,
    75: 150.6,
    90: 154.8,
    95: 157.4
  },
  139: {
    5: 135.1,
    10: 137.6,
    25: 141.8,
    50: 146.5,
    75: 151.2,
    90: 155.4,
    95: 157.9
  },
  140: {
    5: 135.5,
    10: 138.1,
    25: 142.3,
    50: 147,
    75: 151.7,
    90: 155.9,
    95: 158.5
  },
  141: {
    5: 136,
    10: 138.5,
    25: 142.8,
    50: 147.5,
    75: 152.2,
    90: 156.5,
    95: 159
  },
  142: {
    5: 136.5,
    10: 139,
    25: 143.3,
    50: 148,
    75: 152.8,
    90: 157,
    95: 159.6
  },
  143: {
    5: 136.9,
    10: 139.5,
    25: 143.8,
    50: 148.5,
    75: 153.3,
    90: 157.6,
    95: 160.2
  },
  144: {
    5: 137.4,
    10: 140,
    25: 144.3,
    50: 149.1,
    75: 153.9,
    90: 158.2,
    95: 160.7
  },
  145: {
    5: 137.9,
    10: 140.5,
    25: 144.8,
    50: 149.6,
    75: 154.4,
    90: 158.7,
    95: 161.3
  },
  146: {
    5: 138.4,
    10: 141,
    25: 145.4,
    50: 150.2,
    75: 155,
    90: 159.3,
    95: 161.9
  },
  147: {
    5: 138.9,
    10: 141.5,
    25: 145.9,
    50: 150.7,
    75: 155.6,
    90: 159.9,
    95: 162.5
  },
  148: {
    5: 139.4,
    10: 142.1,
    25: 146.4,
    50: 151.3,
    75: 156.1,
    90: 160.5,
    95: 163.1
  },
  149: {
    5: 140,
    10: 142.6,
    25: 147,
    50: 151.9,
    75: 156.7,
    90: 161.1,
    95: 163.8
  },
  150: {
    5: 140.5,
    10: 143.1,
    25: 147.5,
    50: 152.4,
    75: 157.3,
    90: 161.7,
    95: 164.4
  },
  151: {
    5: 141,
    10: 143.7,
    25: 148.1,
    50: 153,
    75: 157.9,
    90: 162.4,
    95: 165
  },
  152: {
    5: 141.6,
    10: 144.2,
    25: 148.7,
    50: 153.6,
    75: 158.6,
    90: 163,
    95: 165.7
  },
  153: {
    5: 142.1,
    10: 144.8,
    25: 149.3,
    50: 154.2,
    75: 159.2,
    90: 163.6,
    95: 166.3
  },
  154: {
    5: 142.7,
    10: 145.4,
    25: 149.9,
    50: 154.8,
    75: 159.8,
    90: 164.3,
    95: 167
  },
  155: {
    5: 143.3,
    10: 145.9,
    25: 150.4,
    50: 155.4,
    75: 160.4,
    90: 164.9,
    95: 167.6
  },
  156: {
    5: 143.8,
    10: 146.5,
    25: 151,
    50: 156,
    75: 161.1,
    90: 165.6,
    95: 168.3
  },
  157: {
    5: 144.4,
    10: 147.1,
    25: 151.6,
    50: 156.7,
    75: 161.7,
    90: 166.2,
    95: 168.9
  },
  158: {
    5: 145,
    10: 147.7,
    25: 152.2,
    50: 157.3,
    75: 162.3,
    90: 166.9,
    95: 169.6
  },
  159: {
    5: 145.5,
    10: 148.3,
    25: 152.8,
    50: 157.9,
    75: 162.9,
    90: 167.5,
    95: 170.2
  },
  160: {
    5: 146.1,
    10: 148.8,
    25: 153.4,
    50: 158.5,
    75: 163.6,
    90: 168.1,
    95: 170.9
  },
  161: {
    5: 146.7,
    10: 149.4,
    25: 154,
    50: 159.1,
    75: 164.2,
    90: 168.8,
    95: 171.5
  },
  162: {
    5: 147.2,
    10: 150,
    25: 154.6,
    50: 159.7,
    75: 164.8,
    90: 169.4,
    95: 172.2
  },
  163: {
    5: 147.8,
    10: 150.6,
    25: 155.2,
    50: 160.3,
    75: 165.4,
    90: 170,
    95: 172.8
  },
  164: {
    5: 148.4,
    10: 151.1,
    25: 155.7,
    50: 160.9,
    75: 166,
    90: 170.6,
    95: 173.4
  },
  165: {
    5: 148.9,
    10: 151.7,
    25: 156.3,
    50: 161.5,
    75: 166.6,
    90: 171.3,
    95: 174
  },
  166: {
    5: 149.5,
    10: 152.2,
    25: 156.9,
    50: 162.1,
    75: 167.2,
    90: 171.9,
    95: 174.6
  },
  167: {
    5: 150,
    10: 152.8,
    25: 157.4,
    50: 162.6,
    75: 167.8,
    90: 172.5,
    95: 175.2
  },
  168: {
    5: 150.5,
    10: 153.3,
    25: 158,
    50: 163.2,
    75: 168.4,
    90: 173,
    95: 175.8
  },
  169: {
    5: 151.1,
    10: 153.9,
    25: 158.5,
    50: 163.7,
    75: 168.9,
    90: 173.6,
    95: 176.4
  },
  170: {
    5: 151.6,
    10: 154.4,
    25: 159.1,
    50: 164.3,
    75: 169.5,
    90: 174.2,
    95: 177
  },
  171: {
    5: 152.1,
    10: 154.9,
    25: 159.6,
    50: 164.8,
    75: 170,
    90: 174.7,
    95: 177.5
  },
  172: {
    5: 152.6,
    10: 155.4,
    25: 160.1,
    50: 165.3,
    75: 170.5,
    90: 175.2,
    95: 178.1
  },
  173: {
    5: 153.1,
    10: 155.9,
    25: 160.6,
    50: 165.8,
    75: 171.1,
    90: 175.8,
    95: 178.6
  },
  174: {
    5: 153.5,
    10: 156.4,
    25: 161.1,
    50: 166.3,
    75: 171.5,
    90: 176.3,
    95: 179.1
  },
  175: {
    5: 154,
    10: 156.8,
    25: 161.5,
    50: 166.8,
    75: 172,
    90: 176.7,
    95: 179.6
  },
  176: {
    5: 154.4,
    10: 157.3,
    25: 162,
    50: 167.2,
    75: 172.5,
    90: 177.2,
    95: 180
  },
  177: {
    5: 154.9,
    10: 157.7,
    25: 162.4,
    50: 167.7,
    75: 172.9,
    90: 177.7,
    95: 180.5
  },
  178: {
    5: 155.3,
    10: 158.1,
    25: 162.9,
    50: 168.1,
    75: 173.4,
    90: 178.1,
    95: 180.9
  },
  179: {
    5: 155.7,
    10: 158.6,
    25: 163.3,
    50: 168.5,
    75: 173.8,
    90: 178.5,
    95: 181.4
  },
  180: {
    5: 156.1,
    10: 159,
    25: 163.7,
    50: 169,
    75: 174.2,
    90: 179,
    95: 181.8
  },
  181: {
    5: 156.5,
    10: 159.4,
    25: 164.1,
    50: 169.4,
    75: 174.6,
    90: 179.4,
    95: 182.2
  },
  182: {
    5: 156.9,
    10: 159.7,
    25: 164.5,
    50: 169.7,
    75: 175,
    90: 179.7,
    95: 182.6
  },
  183: {
    5: 157.3,
    10: 160.1,
    25: 164.8,
    50: 170.1,
    75: 175.4,
    90: 180.1,
    95: 183
  },
  184: {
    5: 157.6,
    10: 160.5,
    25: 165.2,
    50: 170.5,
    75: 175.7,
    90: 180.5,
    95: 183.3
  },
  185: {
    5: 158,
    10: 160.8,
    25: 165.6,
    50: 170.8,
    75: 176.1,
    90: 180.8,
    95: 183.7
  },
  186: {
    5: 158.3,
    10: 161.1,
    25: 165.9,
    50: 171.1,
    75: 176.4,
    90: 181.1,
    95: 184
  },
  187: {
    5: 158.6,
    10: 161.5,
    25: 166.2,
    50: 171.5,
    75: 176.7,
    90: 181.5,
    95: 184.3
  },
  188: {
    5: 159,
    10: 161.8,
    25: 166.5,
    50: 171.8,
    75: 177,
    90: 181.8,
    95: 184.6
  },
  189: {
    5: 159.3,
    10: 162.1,
    25: 166.8,
    50: 172.1,
    75: 177.3,
    90: 182.1,
    95: 184.9
  },
  190: {
    5: 159.6,
    10: 162.4,
    25: 167.1,
    50: 172.4,
    75: 177.6,
    90: 182.3,
    95: 185.2
  },
  191: {
    5: 159.8,
    10: 162.7,
    25: 167.4,
    50: 172.6,
    75: 177.9,
    90: 182.6,
    95: 185.4
  },
  192: {
    5: 160.1,
    10: 162.9,
    25: 167.7,
    50: 172.9,
    75: 178.1,
    90: 182.9,
    95: 185.7
  },
  193: {
    5: 160.4,
    10: 163.2,
    25: 167.9,
    50: 173.1,
    75: 178.4,
    90: 183.1,
    95: 185.9
  },
  194: {
    5: 160.6,
    10: 163.4,
    25: 168.2,
    50: 173.4,
    75: 178.6,
    90: 183.3,
    95: 186.1
  },
  195: {
    5: 160.9,
    10: 163.7,
    25: 168.4,
    50: 173.6,
    75: 178.8,
    90: 183.5,
    95: 186.4
  },
  196: {
    5: 161.1,
    10: 163.9,
    25: 168.6,
    50: 173.8,
    75: 179,
    90: 183.7,
    95: 186.6
  },
  197: {
    5: 161.3,
    10: 164.1,
    25: 168.8,
    50: 174,
    75: 179.2,
    90: 183.9,
    95: 186.7
  },
  198: {
    5: 161.5,
    10: 164.3,
    25: 169,
    50: 174.2,
    75: 179.4,
    90: 184.1,
    95: 186.9
  },
  199: {
    5: 161.7,
    10: 164.5,
    25: 169.2,
    50: 174.4,
    75: 179.6,
    90: 184.3,
    95: 187.1
  },
  200: {
    5: 161.9,
    10: 164.7,
    25: 169.4,
    50: 174.6,
    75: 179.8,
    90: 184.4,
    95: 187.2
  },
  201: {
    5: 162.1,
    10: 164.9,
    25: 169.6,
    50: 174.7,
    75: 179.9,
    90: 184.6,
    95: 187.4
  },
  202: {
    5: 162.3,
    10: 165.1,
    25: 169.7,
    50: 174.9,
    75: 180.1,
    90: 184.7,
    95: 187.5
  },
  203: {
    5: 162.4,
    10: 165.2,
    25: 169.9,
    50: 175,
    75: 180.2,
    90: 184.8,
    95: 187.6
  },
  204: {
    5: 162.6,
    10: 165.4,
    25: 170,
    50: 175.2,
    75: 180.3,
    90: 185,
    95: 187.7
  },
  205: {
    5: 162.7,
    10: 165.5,
    25: 170.1,
    50: 175.3,
    75: 180.4,
    90: 185.1,
    95: 187.8
  },
  206: {
    5: 162.9,
    10: 165.6,
    25: 170.3,
    50: 175.4,
    75: 180.5,
    90: 185.2,
    95: 187.9
  },
  207: {
    5: 163,
    10: 165.8,
    25: 170.4,
    50: 175.5,
    75: 180.6,
    90: 185.2,
    95: 188
  },
  208: {
    5: 163.1,
    10: 165.9,
    25: 170.5,
    50: 175.6,
    75: 180.7,
    90: 185.3,
    95: 188.1
  },
  209: {
    5: 163.2,
    10: 166,
    25: 170.6,
    50: 175.7,
    75: 180.8,
    90: 185.4,
    95: 188.1
  },
  210: {
    5: 163.3,
    10: 166.1,
    25: 170.7,
    50: 175.8,
    75: 180.9,
    90: 185.5,
    95: 188.2
  },
  211: {
    5: 163.4,
    10: 166.2,
    25: 170.8,
    50: 175.8,
    75: 180.9,
    90: 185.5,
    95: 188.3
  },
  212: {
    5: 163.5,
    10: 166.3,
    25: 170.8,
    50: 175.9,
    75: 181,
    90: 185.6,
    95: 188.3
  },
  213: {
    5: 163.6,
    10: 166.3,
    25: 170.9,
    50: 176,
    75: 181,
    90: 185.6,
    95: 188.3
  },
  214: {
    5: 163.7,
    10: 166.4,
    25: 171,
    50: 176,
    75: 181.1,
    90: 185.7,
    95: 188.4
  },
  215: {
    5: 163.8,
    10: 166.5,
    25: 171,
    50: 176.1,
    75: 181.1,
    90: 185.7,
    95: 188.4
  },
  216: {
    5: 163.9,
    10: 166.6,
    25: 171.1,
    50: 176.1,
    75: 181.2,
    90: 185.7,
    95: 188.4
  },
  217: {
    5: 163.9,
    10: 166.6,
    25: 171.2,
    50: 176.2,
    75: 181.2,
    90: 185.7,
    95: 188.5
  },
  218: {
    5: 164,
    10: 166.7,
    25: 171.2,
    50: 176.2,
    75: 181.3,
    90: 185.8,
    95: 188.5
  },
  219: {
    5: 164.1,
    10: 166.8,
    25: 171.3,
    50: 176.3,
    75: 181.3,
    90: 185.8,
    95: 188.5
  },
  220: {
    5: 164.1,
    10: 166.8,
    25: 171.3,
    50: 176.3,
    75: 181.3,
    90: 185.8,
    95: 188.5
  },
  221: {
    5: 164.2,
    10: 166.9,
    25: 171.4,
    50: 176.4,
    75: 181.3,
    90: 185.8,
    95: 188.5
  },
  222: {
    5: 164.2,
    10: 166.9,
    25: 171.4,
    50: 176.4,
    75: 181.4,
    90: 185.8,
    95: 188.5
  },
  223: {
    5: 164.3,
    10: 167,
    25: 171.4,
    50: 176.4,
    75: 181.4,
    90: 185.9,
    95: 188.5
  },
  224: {
    5: 164.3,
    10: 167,
    25: 171.5,
    50: 176.4,
    75: 181.4,
    90: 185.9,
    95: 188.5
  },
  225: {
    5: 164.4,
    10: 167.1,
    25: 171.5,
    50: 176.5,
    75: 181.4,
    90: 185.9,
    95: 188.5
  },
  226: {
    5: 164.5,
    10: 167.1,
    25: 171.6,
    50: 176.5,
    75: 181.4,
    90: 185.9,
    95: 188.5
  },
  227: {
    5: 164.5,
    10: 167.2,
    25: 171.6,
    50: 176.5,
    75: 181.5,
    90: 185.9,
    95: 188.5
  },
  228: {
    5: 164.5,
    10: 167.2,
    25: 171.6,
    50: 176.5,
    75: 181.5,
    90: 185.9,
    95: 188.5
  }
};

export const GirlHyperBP: any = {
  0: {
    5: 46.1,
    10: 46.8,
    25: 47.9,
    50: 49.1,
    75: 50.4,
    90: 51.5,
    95: 52.2
  },
  1: {
    5: 50.5,
    10: 51.2,
    25: 52.4,
    50: 53.7,
    75: 55,
    90: 56.2,
    95: 56.9
  },
  2: {
    5: 53.7,
    10: 54.5,
    25: 55.7,
    50: 57.1,
    75: 58.4,
    90: 59.7,
    95: 60.4
  },
  3: {
    5: 56.3,
    10: 57.1,
    25: 58.4,
    50: 59.8,
    75: 61.2,
    90: 62.5,
    95: 63.3
  },
  4: {
    5: 58.5,
    10: 59.3,
    25: 60.6,
    50: 62.1,
    75: 63.5,
    90: 64.9,
    95: 65.7
  },
  5: {
    5: 60.4,
    10: 61.2,
    25: 62.5,
    50: 64,
    75: 65.5,
    90: 66.9,
    95: 67.7
  },
  6: {
    5: 62,
    10: 62.8,
    25: 64.2,
    50: 65.7,
    75: 67.3,
    90: 68.6,
    95: 69.5
  },
  7: {
    5: 63.5,
    10: 64.3,
    25: 65.7,
    50: 67.3,
    75: 68.8,
    90: 70.3,
    95: 71.1
  },
  8: {
    5: 64.9,
    10: 65.7,
    25: 67.2,
    50: 68.7,
    75: 70.3,
    90: 71.8,
    95: 72.6
  },
  9: {
    5: 66.2,
    10: 67,
    25: 68.5,
    50: 70.1,
    75: 71.8,
    90: 73.2,
    95: 74.1
  },
  10: {
    5: 67.4,
    10: 68.3,
    25: 69.8,
    50: 71.5,
    75: 73.1,
    90: 74.6,
    95: 75.5
  },
  11: {
    5: 68.6,
    10: 69.5,
    25: 71.1,
    50: 72.8,
    75: 74.5,
    90: 76,
    95: 76.9
  },
  12: {
    5: 69.8,
    10: 70.7,
    25: 72.3,
    50: 74,
    75: 75.8,
    90: 77.3,
    95: 78.3
  },
  13: {
    5: 70.9,
    10: 71.8,
    25: 73.4,
    50: 75.2,
    75: 77,
    90: 78.6,
    95: 79.5
  },
  14: {
    5: 72,
    10: 72.9,
    25: 74.6,
    50: 76.4,
    75: 78.2,
    90: 79.8,
    95: 80.8
  },
  15: {
    5: 73,
    10: 74,
    25: 75.7,
    50: 77.5,
    75: 79.4,
    90: 81,
    95: 82
  },
  16: {
    5: 74,
    10: 75,
    25: 76.7,
    50: 78.6,
    75: 80.5,
    90: 82.2,
    95: 83.2
  },
  17: {
    5: 75,
    10: 76,
    25: 77.7,
    50: 79.7,
    75: 81.6,
    90: 83.3,
    95: 84.4
  },
  18: {
    5: 75.9,
    10: 77,
    25: 78.7,
    50: 80.7,
    75: 82.7,
    90: 84.4,
    95: 85.5
  },
  19: {
    5: 76.9,
    10: 77.9,
    25: 79.7,
    50: 81.7,
    75: 83.7,
    90: 85.5,
    95: 86.6
  },
  20: {
    5: 77.7,
    10: 78.8,
    25: 80.7,
    50: 82.7,
    75: 84.7,
    90: 86.6,
    95: 87.7
  },
  21: {
    5: 78.6,
    10: 79.7,
    25: 81.6,
    50: 83.7,
    75: 85.7,
    90: 87.6,
    95: 88.7
  },
  22: {
    5: 79.5,
    10: 80.6,
    25: 82.5,
    50: 84.6,
    75: 86.7,
    90: 88.6,
    95: 89.7
  },
  23: {
    5: 80.3,
    10: 81.5,
    25: 83.4,
    50: 85.5,
    75: 87.7,
    90: 89.6,
    95: 90.7
  },
  24: {
    5: 80.4,
    10: 81.6,
    25: 83.5,
    50: 85.7,
    75: 87.9,
    90: 89.9,
    95: 91
  },
  25: {
    5: 81.2,
    10: 82.4,
    25: 84.4,
    50: 86.6,
    75: 88.8,
    90: 90.8,
    95: 92
  },
  26: {
    5: 82,
    10: 83.2,
    25: 85.2,
    50: 87.4,
    75: 89.7,
    90: 91.7,
    95: 92.9
  },
  27: {
    5: 82.7,
    10: 83.9,
    25: 86,
    50: 88.3,
    75: 90.6,
    90: 92.6,
    95: 93.8
  },
  28: {
    5: 83.5,
    10: 84.7,
    25: 86.8,
    50: 89.1,
    75: 91.4,
    90: 93.5,
    95: 94.7
  },
  29: {
    5: 84.2,
    10: 85.4,
    25: 87.6,
    50: 89.9,
    75: 92.2,
    90: 94.4,
    95: 95.6
  },
  30: {
    5: 84.9,
    10: 86.2,
    25: 88.3,
    50: 90.7,
    75: 93.1,
    90: 95.2,
    95: 96.5
  },
  31: {
    5: 85.6,
    10: 86.9,
    25: 89,
    50: 91.4,
    75: 93.9,
    90: 96,
    95: 97.3
  },
  32: {
    5: 86.2,
    10: 87.5,
    25: 89.7,
    50: 92.2,
    75: 94.6,
    90: 96.8,
    95: 98.2
  },
  33: {
    5: 86.9,
    10: 88.2,
    25: 90.4,
    50: 92.9,
    75: 95.4,
    90: 97.6,
    95: 99
  },
  34: {
    5: 87.5,
    10: 88.9,
    25: 91.1,
    50: 93.6,
    75: 96.2,
    90: 98.4,
    95: 99.8
  },
  35: {
    5: 88.2,
    10: 89.5,
    25: 91.8,
    50: 94.4,
    75: 96.9,
    90: 99.2,
    95: 100.5
  },
  36: {
    5: 88.8,
    10: 90.2,
    25: 92.5,
    50: 95.1,
    75: 97.6,
    90: 99.9,
    95: 101.3
  },
  37: {
    5: 89.4,
    10: 90.8,
    25: 93.1,
    50: 95.7,
    75: 98.3,
    90: 100.7,
    95: 102.1
  },
  38: {
    5: 90,
    10: 91.4,
    25: 93.8,
    50: 96.4,
    75: 99,
    90: 101.4,
    95: 102.8
  },
  39: {
    5: 90.6,
    10: 92,
    25: 94.4,
    50: 97.1,
    75: 99.7,
    90: 102.1,
    95: 103.6
  },
  40: {
    5: 91.2,
    10: 92.6,
    25: 95.1,
    50: 97.7,
    75: 100.4,
    90: 102.9,
    95: 104.3
  },
  41: {
    5: 91.8,
    10: 93.2,
    25: 95.7,
    50: 98.4,
    75: 101.1,
    90: 103.6,
    95: 105
  },
  42: {
    5: 92.4,
    10: 93.8,
    25: 96.3,
    50: 99,
    75: 101.8,
    90: 104.3,
    95: 105.7
  },
  43: {
    5: 92.9,
    10: 94.4,
    25: 96.9,
    50: 99.7,
    75: 102.4,
    90: 104.9,
    95: 106.4
  },
  44: {
    5: 93.5,
    10: 95,
    25: 97.5,
    50: 100.3,
    75: 103.1,
    90: 105.6,
    95: 107.1
  },
  45: {
    5: 94,
    10: 95.6,
    25: 98.1,
    50: 100.9,
    75: 103.7,
    90: 106.3,
    95: 107.8
  },
  46: {
    5: 94.6,
    10: 96.1,
    25: 98.7,
    50: 101.5,
    75: 104.4,
    90: 107,
    95: 108.5
  },
  47: {
    5: 95.1,
    10: 96.7,
    25: 99.3,
    50: 102.1,
    75: 105,
    90: 107.6,
    95: 109.2
  },
  48: {
    5: 95.6,
    10: 97.2,
    25: 99.8,
    50: 102.7,
    75: 105.6,
    90: 108.3,
    95: 109.8
  },
  49: {
    5: 96.2,
    10: 97.8,
    25: 100.4,
    50: 103.3,
    75: 106.3,
    90: 108.9,
    95: 110.5
  },
  50: {
    5: 96.7,
    10: 98.3,
    25: 100.9,
    50: 103.9,
    75: 106.9,
    90: 109.5,
    95: 111.1
  },
  51: {
    5: 97.2,
    10: 98.8,
    25: 101.5,
    50: 104.5,
    75: 107.5,
    90: 110.1,
    95: 111.8
  },
  52: {
    5: 97.7,
    10: 99.3,
    25: 102,
    50: 105,
    75: 108.1,
    90: 110.8,
    95: 112.4
  },
  53: {
    5: 98.2,
    10: 99.9,
    25: 102.6,
    50: 105.6,
    75: 108.6,
    90: 111.4,
    95: 113
  },
  54: {
    5: 98.7,
    10: 100.4,
    25: 103.1,
    50: 106.2,
    75: 109.2,
    90: 112,
    95: 113.6
  },
  55: {
    5: 99.2,
    10: 100.9,
    25: 103.6,
    50: 106.7,
    75: 109.8,
    90: 112.6,
    95: 114.3
  },
  56: {
    5: 99.7,
    10: 101.4,
    25: 104.2,
    50: 107.3,
    75: 110.4,
    90: 113.2,
    95: 114.9
  },
  57: {
    5: 100.2,
    10: 101.9,
    25: 104.7,
    50: 107.8,
    75: 111,
    90: 113.8,
    95: 115.5
  },
  58: {
    5: 100.7,
    10: 102.4,
    25: 105.2,
    50: 108.4,
    75: 111.5,
    90: 114.4,
    95: 116.1
  },
  59: {
    5: 101.1,
    10: 102.8,
    25: 105.7,
    50: 108.9,
    75: 112.1,
    90: 114.9,
    95: 116.7
  },
  60: {
    5: 101.6,
    10: 103.3,
    25: 106.2,
    50: 109.4,
    75: 112.6,
    90: 115.5,
    95: 117.2
  },
  61: {
    5: 101.75,
    10: 103.485,
    25: 106.382,
    50: 109.602,
    75: 112.821,
    90: 115.719,
    95: 117.453
  },
  62: {
    5: 102.221,
    10: 103.967,
    25: 106.884,
    50: 110.126,
    75: 113.367,
    90: 116.285,
    95: 118.031
  },
  63: {
    5: 102.686,
    10: 104.444,
    25: 107.382,
    50: 110.645,
    75: 113.909,
    90: 116.846,
    95: 118.604
  },
  64: {
    5: 103.147,
    10: 104.917,
    25: 107.874,
    50: 111.16,
    75: 114.445,
    90: 117.402,
    95: 119.172
  },
  65: {
    5: 103.606,
    10: 105.387,
    25: 108.363,
    50: 111.67,
    75: 114.976,
    90: 117.952,
    95: 119.733
  },
  66: {
    5: 104.059,
    10: 105.851,
    25: 108.847,
    50: 112.175,
    75: 115.504,
    90: 118.499,
    95: 120.292
  },
  67: {
    5: 104.509,
    10: 106.313,
    25: 109.327,
    50: 112.677,
    75: 116.026,
    90: 119.04,
    95: 120.844
  },
  68: {
    5: 104.955,
    10: 106.771,
    25: 109.804,
    50: 113.174,
    75: 116.544,
    90: 119.577,
    95: 121.393
  },
  69: {
    5: 105.398,
    10: 107.224,
    25: 110.276,
    50: 113.667,
    75: 117.058,
    90: 120.11,
    95: 121.937
  },
  70: {
    5: 105.836,
    10: 107.674,
    25: 110.745,
    50: 114.156,
    75: 117.568,
    90: 120.639,
    95: 122.477
  },
  71: {
    5: 106.272,
    10: 108.12,
    25: 111.21,
    50: 114.642,
    75: 118.075,
    90: 121.164,
    95: 123.013
  },
  72: {
    5: 106.703,
    10: 108.563,
    25: 111.671,
    50: 115.124,
    75: 118.578,
    90: 121.685,
    95: 123.545
  },
  73: {
    5: 107.135,
    10: 109.005,
    25: 112.131,
    50: 115.604,
    75: 119.077,
    90: 122.203,
    95: 124.073
  },
  74: {
    5: 107.564,
    10: 109.445,
    25: 112.588,
    50: 116.081,
    75: 119.574,
    90: 122.718,
    95: 124.599
  },
  75: {
    5: 107.989,
    10: 109.881,
    25: 113.043,
    50: 116.557,
    75: 120.07,
    90: 123.232,
    95: 125.125
  },
  76: {
    5: 108.417,
    10: 110.319,
    25: 113.499,
    50: 117.031,
    75: 120.563,
    90: 123.743,
    95: 125.645
  },
  77: {
    5: 108.842,
    10: 110.755,
    25: 113.952,
    50: 117.504,
    75: 121.057,
    90: 124.254,
    95: 126.167
  },
  78: {
    5: 109.266,
    10: 111.19,
    25: 114.405,
    50: 117.977,
    75: 121.549,
    90: 124.764,
    95: 126.688
  },
  79: {
    5: 109.691,
    10: 111.626,
    25: 114.858,
    50: 118.449,
    75: 122.04,
    90: 125.272,
    95: 127.207
  },
  80: {
    5: 110.115,
    10: 112.06,
    25: 115.31,
    50: 118.921,
    75: 122.532,
    90: 125.782,
    95: 127.727
  },
  81: {
    5: 110.54,
    10: 112.495,
    25: 115.762,
    50: 119.393,
    75: 123.023,
    90: 126.29,
    95: 128.246
  },
  82: {
    5: 110.965,
    10: 112.931,
    25: 116.215,
    50: 119.865,
    75: 123.514,
    90: 126.799,
    95: 128.765
  },
  83: {
    5: 111.391,
    10: 113.367,
    25: 116.669,
    50: 120.337,
    75: 124.006,
    90: 127.308,
    95: 129.284
  },
  84: {
    5: 111.819,
    10: 113.805,
    25: 117.123,
    50: 120.81,
    75: 124.498,
    90: 127.816,
    95: 129.802
  },
  85: {
    5: 112.245,
    10: 114.242,
    25: 117.578,
    50: 121.284,
    75: 124.991,
    90: 128.327,
    95: 130.323
  },
  86: {
    5: 112.674,
    10: 114.681,
    25: 118.034,
    50: 121.759,
    75: 125.484,
    90: 128.837,
    95: 130.843
  },
  87: {
    5: 113.102,
    10: 115.119,
    25: 118.489,
    50: 122.234,
    75: 125.978,
    90: 129.349,
    95: 131.366
  },
  88: {
    5: 113.532,
    10: 115.559,
    25: 118.946,
    50: 122.71,
    75: 126.473,
    90: 129.86,
    95: 131.887
  },
  89: {
    5: 113.965,
    10: 116.002,
    25: 119.405,
    50: 123.187,
    75: 126.968,
    90: 130.371,
    95: 132.408
  },
  90: {
    5: 114.397,
    10: 116.444,
    25: 119.864,
    50: 123.665,
    75: 127.465,
    90: 130.885,
    95: 132.932
  },
  91: {
    5: 114.83,
    10: 116.887,
    25: 120.324,
    50: 124.144,
    75: 127.963,
    90: 131.4,
    95: 133.457
  },
  92: {
    5: 115.266,
    10: 117.333,
    25: 120.786,
    50: 124.623,
    75: 128.461,
    90: 131.914,
    95: 133.981
  },
  93: {
    5: 115.702,
    10: 117.779,
    25: 121.249,
    50: 125.104,
    75: 128.96,
    90: 132.43,
    95: 134.507
  },
  94: {
    5: 116.14,
    10: 118.227,
    25: 121.713,
    50: 125.587,
    75: 129.461,
    90: 132.947,
    95: 135.033
  },
  95: {
    5: 116.579,
    10: 118.676,
    25: 122.179,
    50: 126.071,
    75: 129.963,
    90: 133.465,
    95: 135.562
  },
  96: {
    5: 117.02,
    10: 119.126,
    25: 122.645,
    50: 126.556,
    75: 130.466,
    90: 133.986,
    95: 136.092
  },
  97: {
    5: 117.461,
    10: 119.577,
    25: 123.114,
    50: 127.042,
    75: 130.971,
    90: 134.507,
    95: 136.623
  },
  98: {
    5: 117.906,
    10: 120.032,
    25: 123.584,
    50: 127.53,
    75: 131.477,
    90: 135.029,
    95: 137.155
  },
  99: {
    5: 118.352,
    10: 120.488,
    25: 124.056,
    50: 128.02,
    75: 131.984,
    90: 135.552,
    95: 137.687
  },
  100: {
    5: 118.8,
    10: 120.945,
    25: 124.529,
    50: 128.511,
    75: 132.493,
    90: 136.077,
    95: 138.222
  },
  101: {
    5: 119.249,
    10: 121.404,
    25: 125.004,
    50: 129.004,
    75: 133.003,
    90: 136.603,
    95: 138.758
  },
  102: {
    5: 119.699,
    10: 121.863,
    25: 125.48,
    50: 129.498,
    75: 133.515,
    90: 137.132,
    95: 139.296
  },
  103: {
    5: 120.153,
    10: 122.327,
    25: 125.958,
    50: 129.993,
    75: 134.028,
    90: 137.66,
    95: 139.833
  },
  104: {
    5: 120.608,
    10: 122.791,
    25: 126.438,
    50: 130.49,
    75: 134.543,
    90: 138.19,
    95: 140.372
  },
  105: {
    5: 121.063,
    10: 123.255,
    25: 126.919,
    50: 130.989,
    75: 135.059,
    90: 138.723,
    95: 140.915
  },
  106: {
    5: 121.523,
    10: 123.725,
    25: 127.403,
    50: 131.49,
    75: 135.576,
    90: 139.254,
    95: 141.456
  },
  107: {
    5: 121.983,
    10: 124.193,
    25: 127.887,
    50: 131.991,
    75: 136.095,
    90: 139.789,
    95: 142
  },
  108: {
    5: 122.443,
    10: 124.663,
    25: 128.373,
    50: 132.494,
    75: 136.616,
    90: 140.326,
    95: 142.546
  },
  109: {
    5: 122.907,
    10: 125.136,
    25: 128.861,
    50: 132.999,
    75: 137.137,
    90: 140.862,
    95: 143.09
  },
  110: {
    5: 123.372,
    10: 125.61,
    25: 129.35,
    50: 133.505,
    75: 137.659,
    90: 141.399,
    95: 143.637
  },
  111: {
    5: 123.839,
    10: 126.086,
    25: 129.84,
    50: 134.012,
    75: 138.183,
    90: 141.938,
    95: 144.185
  },
  112: {
    5: 124.307,
    10: 126.562,
    25: 130.332,
    50: 134.52,
    75: 138.708,
    90: 142.478,
    95: 144.734
  },
  113: {
    5: 124.778,
    10: 127.042,
    25: 130.826,
    50: 135.03,
    75: 139.234,
    90: 143.018,
    95: 145.282
  },
  114: {
    5: 125.248,
    10: 127.521,
    25: 131.32,
    50: 135.541,
    75: 139.762,
    90: 143.561,
    95: 145.834
  },
  115: {
    5: 125.721,
    10: 128.003,
    25: 131.816,
    50: 136.053,
    75: 140.29,
    90: 144.103,
    95: 146.386
  },
  116: {
    5: 126.198,
    10: 128.488,
    25: 132.315,
    50: 136.567,
    75: 140.819,
    90: 144.646,
    95: 146.936
  },
  117: {
    5: 126.674,
    10: 128.973,
    25: 132.814,
    50: 137.082,
    75: 141.35,
    90: 145.191,
    95: 147.49
  },
  118: {
    5: 127.151,
    10: 129.459,
    25: 133.315,
    50: 137.599,
    75: 141.883,
    90: 145.739,
    95: 148.046
  },
  119: {
    5: 127.632,
    10: 129.948,
    25: 133.817,
    50: 138.117,
    75: 142.416,
    90: 146.285,
    95: 148.601
  },
  120: {
    5: 128.115,
    10: 130.439,
    25: 134.322,
    50: 138.636,
    75: 142.951,
    90: 146.834,
    95: 149.158
  },
  121: {
    5: 128.601,
    10: 130.933,
    25: 134.829,
    50: 139.158,
    75: 143.486,
    90: 147.382,
    95: 149.714
  },
  122: {
    5: 129.086,
    10: 131.426,
    25: 135.336,
    50: 139.68,
    75: 144.024,
    90: 147.934,
    95: 150.274
  },
  123: {
    5: 129.576,
    10: 131.923,
    25: 135.846,
    50: 140.205,
    75: 144.563,
    90: 148.486,
    95: 150.834
  },
  124: {
    5: 130.067,
    10: 132.422,
    25: 136.358,
    50: 140.731,
    75: 145.104,
    90: 149.04,
    95: 151.396
  },
  125: {
    5: 130.56,
    10: 132.923,
    25: 136.872,
    50: 141.259,
    75: 145.647,
    90: 149.596,
    95: 151.959
  },
  126: {
    5: 131.054,
    10: 133.425,
    25: 137.387,
    50: 141.789,
    75: 146.191,
    90: 150.153,
    95: 152.524
  },
  127: {
    5: 131.552,
    10: 133.931,
    25: 137.905,
    50: 142.321,
    75: 146.736,
    90: 150.711,
    95: 153.089
  },
  128: {
    5: 132.052,
    10: 134.437,
    25: 138.424,
    50: 142.853,
    75: 147.283,
    90: 151.269,
    95: 153.655
  },
  129: {
    5: 132.552,
    10: 134.946,
    25: 138.944,
    50: 143.387,
    75: 147.83,
    90: 151.829,
    95: 154.222
  },
  130: {
    5: 133.054,
    10: 135.454,
    25: 139.466,
    50: 143.922,
    75: 148.379,
    90: 152.39,
    95: 154.791
  },
  131: {
    5: 133.556,
    10: 135.964,
    25: 139.987,
    50: 144.458,
    75: 148.928,
    90: 152.951,
    95: 155.359
  },
  132: {
    5: 134.06,
    10: 136.475,
    25: 140.51,
    50: 144.993,
    75: 149.476,
    90: 153.511,
    95: 155.925
  },
  133: {
    5: 134.565,
    10: 136.986,
    25: 141.032,
    50: 145.528,
    75: 150.024,
    90: 154.07,
    95: 156.491
  },
  134: {
    5: 135.068,
    10: 137.497,
    25: 141.554,
    50: 146.062,
    75: 150.57,
    90: 154.628,
    95: 157.056
  },
  135: {
    5: 135.573,
    10: 138.008,
    25: 142.075,
    50: 146.595,
    75: 151.115,
    90: 155.183,
    95: 157.617
  },
  136: {
    5: 136.074,
    10: 138.515,
    25: 142.594,
    50: 147.126,
    75: 151.658,
    90: 155.737,
    95: 158.178
  },
  137: {
    5: 136.575,
    10: 139.022,
    25: 143.111,
    50: 147.655,
    75: 152.198,
    90: 156.287,
    95: 158.735
  },
  138: {
    5: 137.073,
    10: 139.527,
    25: 143.626,
    50: 148.18,
    75: 152.735,
    90: 156.834,
    95: 159.287
  },
  139: {
    5: 137.568,
    10: 140.028,
    25: 144.137,
    50: 148.702,
    75: 153.268,
    90: 157.377,
    95: 159.836
  },
  140: {
    5: 138.062,
    10: 140.526,
    25: 144.644,
    50: 149.22,
    75: 153.795,
    90: 157.913,
    95: 160.378
  },
  141: {
    5: 138.548,
    10: 141.018,
    25: 145.146,
    50: 149.732,
    75: 154.318,
    90: 158.446,
    95: 160.916
  },
  142: {
    5: 139.032,
    10: 141.507,
    25: 145.643,
    50: 150.239,
    75: 154.835,
    90: 158.971,
    95: 161.446
  },
  143: {
    5: 139.51,
    10: 141.99,
    25: 146.135,
    50: 150.739,
    75: 155.344,
    90: 159.489,
    95: 161.969
  },
  144: {
    5: 139.981,
    10: 142.467,
    25: 146.619,
    50: 151.233,
    75: 155.846,
    90: 159.999,
    95: 162.484
  },
  145: {
    5: 140.448,
    10: 142.938,
    25: 147.097,
    50: 151.718,
    75: 156.34,
    90: 160.499,
    95: 162.988
  },
  146: {
    5: 140.905,
    10: 143.399,
    25: 147.565,
    50: 152.195,
    75: 156.825,
    90: 160.992,
    95: 163.485
  },
  147: {
    5: 141.355,
    10: 143.853,
    25: 148.026,
    50: 152.663,
    75: 157.3,
    90: 161.473,
    95: 163.97
  },
  148: {
    5: 141.794,
    10: 144.296,
    25: 148.476,
    50: 153.121,
    75: 157.765,
    90: 161.945,
    95: 164.447
  },
  149: {
    5: 142.226,
    10: 144.731,
    25: 148.917,
    50: 153.568,
    75: 158.219,
    90: 162.404,
    95: 164.909
  },
  150: {
    5: 142.648,
    10: 145.156,
    25: 149.347,
    50: 154.004,
    75: 158.661,
    90: 162.852,
    95: 165.36
  },
  151: {
    5: 143.059,
    10: 145.571,
    25: 149.767,
    50: 154.429,
    75: 159.091,
    90: 163.287,
    95: 165.799
  },
  152: {
    5: 143.463,
    10: 145.976,
    25: 150.176,
    50: 154.842,
    75: 159.509,
    90: 163.709,
    95: 166.222
  },
  153: {
    5: 143.852,
    10: 146.368,
    25: 150.573,
    50: 155.244,
    75: 159.915,
    90: 164.119,
    95: 166.635
  },
  154: {
    5: 144.231,
    10: 146.749,
    25: 150.958,
    50: 155.633,
    75: 160.308,
    90: 164.517,
    95: 167.035
  },
  155: {
    5: 144.601,
    10: 147.121,
    25: 151.332,
    50: 156.01,
    75: 160.689,
    90: 164.899,
    95: 167.419
  },
  156: {
    5: 144.957,
    10: 147.479,
    25: 151.693,
    50: 156.375,
    75: 161.057,
    90: 165.271,
    95: 167.793
  },
  157: {
    5: 145.304,
    10: 147.827,
    25: 152.043,
    50: 156.727,
    75: 161.411,
    90: 165.627,
    95: 168.15
  },
  158: {
    5: 145.64,
    10: 148.164,
    25: 152.381,
    50: 157.067,
    75: 161.752,
    90: 165.97,
    95: 168.493
  },
  159: {
    5: 145.964,
    10: 148.488,
    25: 152.707,
    50: 157.394,
    75: 162.081,
    90: 166.299,
    95: 168.824
  },
  160: {
    5: 146.274,
    10: 148.799,
    25: 153.019,
    50: 157.708,
    75: 162.397,
    90: 166.617,
    95: 169.143
  },
  161: {
    5: 146.574,
    10: 149.1,
    25: 153.321,
    50: 158.01,
    75: 162.7,
    90: 166.92,
    95: 169.446
  },
  162: {
    5: 146.864,
    10: 149.39,
    25: 153.61,
    50: 158.3,
    75: 162.989,
    90: 167.21,
    95: 169.736
  },
  163: {
    5: 147.142,
    10: 149.668,
    25: 153.888,
    50: 158.577,
    75: 163.266,
    90: 167.486,
    95: 170.012
  },
  164: {
    5: 147.409,
    10: 149.935,
    25: 154.154,
    50: 158.842,
    75: 163.531,
    90: 167.75,
    95: 170.276
  },
  165: {
    5: 147.663,
    10: 150.188,
    25: 154.408,
    50: 159.096,
    75: 163.784,
    90: 168.004,
    95: 170.529
  },
  166: {
    5: 147.909,
    10: 150.433,
    25: 154.651,
    50: 159.338,
    75: 164.025,
    90: 168.243,
    95: 170.768
  },
  167: {
    5: 148.144,
    10: 150.667,
    25: 154.884,
    50: 159.569,
    75: 164.254,
    90: 168.471,
    95: 170.994
  },
  168: {
    5: 148.369,
    10: 150.891,
    25: 155.106,
    50: 159.789,
    75: 164.472,
    90: 168.687,
    95: 171.209
  },
  169: {
    5: 148.584,
    10: 151.105,
    25: 155.318,
    50: 159.998,
    75: 164.679,
    90: 168.891,
    95: 171.412
  },
  170: {
    5: 148.788,
    10: 151.308,
    25: 155.518,
    50: 160.197,
    75: 164.876,
    90: 169.087,
    95: 171.607
  },
  171: {
    5: 148.984,
    10: 151.502,
    25: 155.71,
    50: 160.386,
    75: 165.061,
    90: 169.269,
    95: 171.788
  },
  172: {
    5: 149.171,
    10: 151.687,
    25: 155.892,
    50: 160.564,
    75: 165.236,
    90: 169.441,
    95: 171.958
  },
  173: {
    5: 149.346,
    10: 151.861,
    25: 156.064,
    50: 160.733,
    75: 165.403,
    90: 169.605,
    95: 172.12
  },
  174: {
    5: 149.516,
    10: 152.028,
    25: 156.227,
    50: 160.893,
    75: 165.558,
    90: 169.757,
    95: 172.27
  },
  175: {
    5: 149.674,
    10: 152.185,
    25: 156.381,
    50: 161.043,
    75: 165.705,
    90: 169.901,
    95: 172.412
  },
  176: {
    5: 149.827,
    10: 152.335,
    25: 156.527,
    50: 161.184,
    75: 165.842,
    90: 170.034,
    95: 172.542
  },
  177: {
    5: 149.969,
    10: 152.475,
    25: 156.664,
    50: 161.318,
    75: 165.971,
    90: 170.16,
    95: 172.666
  },
  178: {
    5: 150.104,
    10: 152.608,
    25: 156.793,
    50: 161.442,
    75: 166.092,
    90: 170.277,
    95: 172.781
  },
  179: {
    5: 150.231,
    10: 152.733,
    25: 156.914,
    50: 161.56,
    75: 166.205,
    90: 170.386,
    95: 172.888
  },
  180: {
    5: 150.354,
    10: 152.853,
    25: 157.029,
    50: 161.669,
    75: 166.309,
    90: 170.485,
    95: 172.984
  },
  181: {
    5: 150.468,
    10: 152.965,
    25: 157.137,
    50: 161.772,
    75: 166.407,
    90: 170.579,
    95: 173.075
  },
  182: {
    5: 150.576,
    10: 153.07,
    25: 157.237,
    50: 161.867,
    75: 166.498,
    90: 170.665,
    95: 173.159
  },
  183: {
    5: 150.675,
    10: 153.166,
    25: 157.33,
    50: 161.956,
    75: 166.583,
    90: 170.746,
    95: 173.238
  },
  184: {
    5: 150.77,
    10: 153.259,
    25: 157.418,
    50: 162.039,
    75: 166.66,
    90: 170.819,
    95: 173.308
  },
  185: {
    5: 150.861,
    10: 153.347,
    25: 157.501,
    50: 162.116,
    75: 166.732,
    90: 170.886,
    95: 173.372
  },
  186: {
    5: 150.946,
    10: 153.429,
    25: 157.578,
    50: 162.188,
    75: 166.798,
    90: 170.947,
    95: 173.43
  },
  187: {
    5: 151.024,
    10: 153.504,
    25: 157.649,
    50: 162.254,
    75: 166.859,
    90: 171.004,
    95: 173.485
  },
  188: {
    5: 151.099,
    10: 153.577,
    25: 157.716,
    50: 162.315,
    75: 166.915,
    90: 171.054,
    95: 173.531
  },
  189: {
    5: 151.168,
    10: 153.643,
    25: 157.778,
    50: 162.372,
    75: 166.966,
    90: 171.101,
    95: 173.576
  },
  190: {
    5: 151.232,
    10: 153.704,
    25: 157.835,
    50: 162.424,
    75: 167.013,
    90: 171.143,
    95: 173.615
  },
  191: {
    5: 151.296,
    10: 153.764,
    25: 157.889,
    50: 162.472,
    75: 167.055,
    90: 171.179,
    95: 173.648
  },
  192: {
    5: 151.353,
    10: 153.818,
    25: 157.938,
    50: 162.516,
    75: 167.093,
    90: 171.213,
    95: 173.679
  },
  193: {
    5: 151.406,
    10: 153.869,
    25: 157.984,
    50: 162.556,
    75: 167.128,
    90: 171.243,
    95: 173.706
  },
  194: {
    5: 151.457,
    10: 153.917,
    25: 158.027,
    50: 162.593,
    75: 167.16,
    90: 171.27,
    95: 173.73
  },
  195: {
    5: 151.505,
    10: 153.962,
    25: 158.067,
    50: 162.628,
    75: 167.189,
    90: 171.294,
    95: 173.75
  },
  196: {
    5: 151.551,
    10: 154.004,
    25: 158.104,
    50: 162.659,
    75: 167.215,
    90: 171.315,
    95: 173.768
  },
  197: {
    5: 151.592,
    10: 154.043,
    25: 158.138,
    50: 162.689,
    75: 167.24,
    90: 171.335,
    95: 173.786
  },
  198: {
    5: 151.633,
    10: 154.081,
    25: 158.172,
    50: 162.716,
    75: 167.261,
    90: 171.352,
    95: 173.8
  },
  199: {
    5: 151.671,
    10: 154.116,
    25: 158.202,
    50: 162.742,
    75: 167.283,
    90: 171.369,
    95: 173.814
  },
  200: {
    5: 151.71,
    10: 154.152,
    25: 158.233,
    50: 162.767,
    75: 167.301,
    90: 171.382,
    95: 173.824
  },
  201: {
    5: 151.745,
    10: 154.185,
    25: 158.261,
    50: 162.79,
    75: 167.32,
    90: 171.396,
    95: 173.836
  },
  202: {
    5: 151.782,
    10: 154.218,
    25: 158.289,
    50: 162.813,
    75: 167.336,
    90: 171.407,
    95: 173.843
  },
  203: {
    5: 151.815,
    10: 154.249,
    25: 158.316,
    50: 162.834,
    75: 167.352,
    90: 171.419,
    95: 173.853
  },
  204: {
    5: 151.848,
    10: 154.279,
    25: 158.341,
    50: 162.854,
    75: 167.368,
    90: 171.43,
    95: 173.861
  },
  205: {
    5: 151.88,
    10: 154.308,
    25: 158.366,
    50: 162.874,
    75: 167.383,
    90: 171.441,
    95: 173.869
  },
  206: {
    5: 151.911,
    10: 154.337,
    25: 158.39,
    50: 162.894,
    75: 167.397,
    90: 171.45,
    95: 173.876
  },
  207: {
    5: 151.941,
    10: 154.365,
    25: 158.413,
    50: 162.912,
    75: 167.411,
    90: 171.459,
    95: 173.883
  },
  208: {
    5: 151.972,
    10: 154.392,
    25: 158.436,
    50: 162.93,
    75: 167.424,
    90: 171.468,
    95: 173.888
  },
  209: {
    5: 152.001,
    10: 154.419,
    25: 158.459,
    50: 162.948,
    75: 167.436,
    90: 171.476,
    95: 173.894
  },
  210: {
    5: 152.028,
    10: 154.444,
    25: 158.48,
    50: 162.965,
    75: 167.45,
    90: 171.486,
    95: 173.901
  },
  211: {
    5: 152.057,
    10: 154.47,
    25: 158.502,
    50: 162.982,
    75: 167.461,
    90: 171.493,
    95: 173.906
  },
  212: {
    5: 152.084,
    10: 154.494,
    25: 158.523,
    50: 162.998,
    75: 167.474,
    90: 171.502,
    95: 173.913
  },
  213: {
    5: 152.112,
    10: 154.52,
    25: 158.544,
    50: 163.014,
    75: 167.485,
    90: 171.509,
    95: 173.917
  },
  214: {
    5: 152.137,
    10: 154.543,
    25: 158.563,
    50: 163.03,
    75: 167.497,
    90: 171.517,
    95: 173.923
  },
  215: {
    5: 152.162,
    10: 154.566,
    25: 158.582,
    50: 163.045,
    75: 167.508,
    90: 171.524,
    95: 173.928
  },
  216: {
    5: 152.189,
    10: 154.59,
    25: 158.602,
    50: 163.06,
    75: 167.517,
    90: 171.529,
    95: 173.93
  },
  217: {
    5: 152.213,
    10: 154.611,
    25: 158.62,
    50: 163.073,
    75: 167.527,
    90: 171.535,
    95: 173.934
  },
  218: {
    5: 152.235,
    10: 154.632,
    25: 158.637,
    50: 163.086,
    75: 167.536,
    90: 171.54,
    95: 173.937
  },
  219: {
    5: 152.257,
    10: 154.652,
    25: 158.653,
    50: 163.098,
    75: 167.544,
    90: 171.545,
    95: 173.939
  },
  220: {
    5: 152.278,
    10: 154.671,
    25: 158.668,
    50: 163.109,
    75: 167.551,
    90: 171.548,
    95: 173.94
  },
  221: {
    5: 152.296,
    10: 154.686,
    25: 158.681,
    50: 163.119,
    75: 167.557,
    90: 171.552,
    95: 173.943
  },
  222: {
    5: 152.315,
    10: 154.703,
    25: 158.694,
    50: 163.128,
    75: 167.562,
    90: 171.553,
    95: 173.941
  },
  223: {
    5: 152.332,
    10: 154.718,
    25: 158.706,
    50: 163.136,
    75: 167.565,
    90: 171.553,
    95: 173.939
  },
  224: {
    5: 152.346,
    10: 154.731,
    25: 158.715,
    50: 163.142,
    75: 167.569,
    90: 171.553,
    95: 173.937
  },
  225: {
    5: 152.362,
    10: 154.744,
    25: 158.724,
    50: 163.147,
    75: 167.569,
    90: 171.55,
    95: 173.932
  },
  226: {
    5: 152.373,
    10: 154.754,
    25: 158.731,
    50: 163.151,
    75: 167.57,
    90: 171.548,
    95: 173.928
  },
  227: {
    5: 152.387,
    10: 154.765,
    25: 158.738,
    50: 163.153,
    75: 167.568,
    90: 171.542,
    95: 173.92
  },
  228: {
    5: 152.396,
    10: 154.772,
    25: 158.743,
    50: 163.155,
    75: 167.567,
    90: 171.537,
    95: 173.914
  }
};

export const BoyDiaBP: any = {
  1: {
    5: {
      50: '34',
      90: '49',
      95: '54',
      99: '61'
    },
    10: {
      50: '35',
      90: '50',
      95: '54',
      99: '62'
    },
    25: {
      50: '36',
      90: '51',
      95: '55',
      99: '63'
    },
    50: {
      50: '37',
      90: '52',
      95: '56',
      99: '64'
    },
    75: {
      50: '38',
      90: '53',
      95: '57',
      99: '65'
    },
    90: {
      50: '39',
      90: '53',
      95: '58',
      99: '66'
    },
    95: {
      50: '39',
      90: '54',
      95: '58',
      99: '66'
    }
  },
  2: {
    5: {
      50: '39',
      90: '54',
      95: '59',
      99: '66'
    },
    10: {
      50: '40',
      90: '55',
      95: '59',
      99: '67'
    },
    25: {
      50: '41',
      90: '56',
      95: '60',
      99: '68'
    },
    50: {
      50: '42',
      90: '57',
      95: '61',
      99: '69'
    },
    75: {
      50: '43',
      90: '58',
      95: '62',
      99: '70'
    },
    90: {
      50: '44',
      90: '58',
      95: '63',
      99: '71'
    },
    95: {
      50: '44',
      90: '59',
      95: '63',
      99: '71'
    }
  },
  3: {
    5: {
      50: '44',
      90: '59',
      95: '63',
      99: '71'
    },
    10: {
      50: '44',
      90: '59',
      95: '63',
      99: '71'
    },
    25: {
      50: '45',
      90: '60',
      95: '64',
      99: '72'
    },
    50: {
      50: '46',
      90: '61',
      95: '65',
      99: '73'
    },
    75: {
      50: '47',
      90: '62',
      95: '66',
      99: '74'
    },
    90: {
      50: '48',
      90: '63',
      95: '67',
      99: '75'
    },
    95: {
      50: '48',
      90: '63',
      95: '67',
      99: '75'
    }
  },
  4: {
    5: {
      50: '47',
      90: '62',
      95: '66',
      99: '74'
    },
    10: {
      50: '48',
      90: '63',
      95: '67',
      99: '75'
    },
    25: {
      50: '49',
      90: '64',
      95: '68',
      99: '76'
    },
    50: {
      50: '50',
      90: '65',
      95: '69',
      99: '77'
    },
    75: {
      50: '51',
      90: '66',
      95: '70',
      99: '78'
    },
    90: {
      50: '51',
      90: '66',
      95: '71',
      99: '78'
    },
    95: {
      50: '52',
      90: '67',
      95: '71',
      99: '79'
    }
  },
  5: {
    5: {
      50: '50',
      90: '65',
      95: '69',
      99: '77'
    },
    10: {
      50: '51',
      90: '66',
      95: '70',
      99: '78'
    },
    25: {
      50: '52',
      90: '67',
      95: '71',
      99: '79'
    },
    50: {
      50: '53',
      90: '68',
      95: '72',
      99: '80'
    },
    75: {
      50: '54',
      90: '69',
      95: '73',
      99: '81'
    },
    90: {
      50: '55',
      90: '69',
      95: '74',
      99: '81'
    },
    95: {
      50: '55',
      90: '70',
      95: '74',
      99: '82'
    }
  },
  6: {
    5: {
      50: '53',
      90: '68',
      95: '72',
      99: '80'
    },
    10: {
      50: '53',
      90: '68',
      95: '72',
      99: '80'
    },
    25: {
      50: '54',
      90: '69',
      95: '73',
      99: '81'
    },
    50: {
      50: '55',
      90: '70',
      95: '74',
      99: '82'
    },
    75: {
      50: '56',
      90: '71',
      95: '75',
      99: '83'
    },
    90: {
      50: '57',
      90: '72',
      95: '76',
      99: '84'
    },
    95: {
      50: '57',
      90: '72',
      95: '76',
      99: '84'
    }
  },
  7: {
    5: {
      50: '55',
      90: '70',
      95: '74',
      99: '82'
    },
    10: {
      50: '55',
      90: '70',
      95: '74',
      99: '82'
    },
    25: {
      50: '56',
      90: '71',
      95: '75',
      99: '83'
    },
    50: {
      50: '57',
      90: '72',
      95: '76',
      99: '84'
    },
    75: {
      50: '58',
      90: '73',
      95: '77',
      99: '85'
    },
    90: {
      50: '59',
      90: '74',
      95: '78',
      99: '86'
    },
    95: {
      50: '59',
      90: '74',
      95: '78',
      99: '86'
    }
  },
  8: {
    5: {
      50: '56',
      90: '71',
      95: '75',
      99: '83'
    },
    10: {
      50: '57',
      90: '72',
      95: '76',
      99: '84'
    },
    25: {
      50: '58',
      90: '72',
      95: '77',
      99: '85'
    },
    50: {
      50: '59',
      90: '73',
      95: '78',
      99: '86'
    },
    75: {
      50: '60',
      90: '74',
      95: '79',
      99: '87'
    },
    90: {
      50: '60',
      90: '75',
      95: '79',
      99: '87'
    },
    95: {
      50: '61',
      90: '76',
      95: '80',
      99: '88'
    }
  },
  9: {
    5: {
      50: '57',
      90: '72',
      95: '76',
      99: '84'
    },
    10: {
      50: '58',
      90: '73',
      95: '77',
      99: '85'
    },
    25: {
      50: '59',
      90: '74',
      95: '78',
      99: '86'
    },
    50: {
      50: '60',
      90: '75',
      95: '79',
      99: '87'
    },
    75: {
      50: '61',
      90: '76',
      95: '80',
      99: '88'
    },
    90: {
      50: '61',
      90: '76',
      95: '81',
      99: '88'
    },
    95: {
      50: '62',
      90: '77',
      95: '81',
      99: '89'
    }
  },
  10: {
    5: {
      50: '58',
      90: '73',
      95: '77',
      99: '85'
    },
    10: {
      50: '59',
      90: '73',
      95: '78',
      99: '86'
    },
    25: {
      50: '60',
      90: '74',
      95: '79',
      99: '86'
    },
    50: {
      50: '61',
      90: '75',
      95: '80',
      99: '88'
    },
    75: {
      50: '61',
      90: '76',
      95: '81',
      99: '88'
    },
    90: {
      50: '62',
      90: '77',
      95: '81',
      99: '89'
    },
    95: {
      50: '63',
      90: '78',
      95: '82',
      99: '90'
    }
  },
  11: {
    5: {
      50: '59',
      90: '74',
      95: '78',
      99: '86'
    },
    10: {
      50: '59',
      90: '74',
      95: '78',
      99: '86'
    },
    25: {
      50: '60',
      90: '75',
      95: '79',
      99: '87'
    },
    50: {
      50: '61',
      90: '76',
      95: '80',
      99: '88'
    },
    75: {
      50: '62',
      90: '77',
      95: '81',
      99: '89'
    },
    90: {
      50: '63',
      90: '78',
      95: '82',
      99: '90'
    },
    95: {
      50: '63',
      90: '78',
      95: '82',
      99: '90'
    }
  },
  12: {
    5: {
      50: '59',
      90: '74',
      95: '78',
      99: '86'
    },
    10: {
      50: '60',
      90: '75',
      95: '79',
      99: '87'
    },
    25: {
      50: '61',
      90: '75',
      95: '80',
      99: '88'
    },
    50: {
      50: '62',
      90: '76',
      95: '81',
      99: '89'
    },
    75: {
      50: '63',
      90: '77',
      95: '82',
      99: '90'
    },
    90: {
      50: '63',
      90: '78',
      95: '82',
      99: '90'
    },
    95: {
      50: '64',
      90: '79',
      95: '83',
      99: '91'
    }
  },
  13: {
    5: {
      50: '60',
      90: '75',
      95: '79',
      99: '87'
    },
    10: {
      50: '60',
      90: '75',
      95: '79',
      99: '87'
    },
    25: {
      50: '61',
      90: '76',
      95: '80',
      99: '88'
    },
    50: {
      50: '62',
      90: '77',
      95: '81',
      99: '89'
    },
    75: {
      50: '63',
      90: '78',
      95: '82',
      99: '90'
    },
    90: {
      50: '64',
      90: '79',
      95: '83',
      99: '91'
    },
    95: {
      50: '64',
      90: '79',
      95: '83',
      99: '91'
    }
  },
  14: {
    5: {
      50: '60',
      90: '75',
      95: '80',
      99: '87'
    },
    10: {
      50: '61',
      90: '76',
      95: '80',
      99: '88'
    },
    25: {
      50: '62',
      90: '77',
      95: '81',
      99: '89'
    },
    50: {
      50: '63',
      90: '78',
      95: '82',
      99: '90'
    },
    75: {
      50: '64',
      90: '79',
      95: '83',
      99: '91'
    },
    90: {
      50: '65',
      90: '79',
      95: '84',
      99: '92'
    },
    95: {
      50: '65',
      90: '80',
      95: '84',
      99: '92'
    }
  },
  15: {
    5: {
      50: '61',
      90: '76',
      95: '81',
      99: '88'
    },
    10: {
      50: '62',
      90: '77',
      95: '81',
      99: '89'
    },
    25: {
      50: '63',
      90: '78',
      95: '82',
      99: '90'
    },
    50: {
      50: '64',
      90: '79',
      95: '83',
      99: '91'
    },
    75: {
      50: '65',
      90: '80',
      95: '84',
      99: '92'
    },
    90: {
      50: '66',
      90: '80',
      95: '85',
      99: '93'
    },
    95: {
      50: '66',
      90: '81',
      95: '85',
      99: '93'
    }
  },
  16: {
    5: {
      50: '63',
      90: '78',
      95: '82',
      99: '90'
    },
    10: {
      50: '63',
      90: '78',
      95: '83',
      99: '90'
    },
    25: {
      50: '64',
      90: '79',
      95: '83',
      99: '91'
    },
    50: {
      50: '65',
      90: '80',
      95: '84',
      99: '92'
    },
    75: {
      50: '66',
      90: '81',
      95: '85',
      99: '93'
    },
    90: {
      50: '67',
      90: '82',
      95: '86',
      99: '94'
    },
    95: {
      50: '67',
      90: '82',
      95: '87',
      99: '94'
    }
  },
  17: {
    5: {
      50: '65',
      90: '80',
      95: '84',
      99: '92'
    },
    10: {
      50: '66',
      90: '80',
      95: '85',
      99: '93'
    },
    25: {
      50: '66',
      90: '81',
      95: '86',
      99: '93'
    },
    50: {
      50: '67',
      90: '82',
      95: '87',
      99: '94'
    },
    75: {
      50: '68',
      90: '83',
      95: '87',
      99: '95'
    },
    90: {
      50: '69',
      90: '84',
      95: '88',
      99: '96'
    },
    95: {
      50: '70',
      90: '84',
      95: '89',
      99: '97'
    }
  }
};

export const GirlDiaBP: any = {
  1: {
    5: {
      50: '38',
      90: '52',
      95: '56',
      99: '64'
    },
    10: {
      50: '39',
      90: '53',
      95: '57',
      99: '64'
    },
    25: {
      50: '39',
      90: '53',
      95: '57',
      99: '65'
    },
    50: {
      50: '40',
      90: '54',
      95: '58',
      99: '65'
    },
    75: {
      50: '41',
      90: '55',
      95: '59',
      99: '66'
    },
    90: {
      50: '41',
      90: '55',
      95: '59',
      99: '67'
    },
    95: {
      50: '42',
      90: '56',
      95: '60',
      99: '67'
    }
  },
  2: {
    5: {
      50: '43',
      90: '57',
      95: '61',
      99: '69'
    },
    10: {
      50: '44',
      90: '58',
      95: '62',
      99: '69'
    },
    25: {
      50: '44',
      90: '58',
      95: '62',
      99: '70'
    },
    50: {
      50: '45',
      90: '59',
      95: '63',
      99: '70'
    },
    75: {
      50: '46',
      90: '60',
      95: '64',
      99: '71'
    },
    90: {
      50: '46',
      90: '61',
      95: '65',
      99: '72'
    },
    95: {
      50: '47',
      90: '61',
      95: '65',
      99: '72'
    }
  },
  3: {
    5: {
      50: '47',
      90: '61',
      95: '65',
      99: '73'
    },
    10: {
      50: '48',
      90: '62',
      95: '66',
      99: '73'
    },
    25: {
      50: '48',
      90: '62',
      95: '66',
      99: '74'
    },
    50: {
      50: '49',
      90: '63',
      95: '67',
      99: '74'
    },
    75: {
      50: '50',
      90: '64',
      95: '68',
      99: '75'
    },
    90: {
      50: '50',
      90: '64',
      95: '68',
      99: '76'
    },
    95: {
      50: '51',
      90: '65',
      95: '69',
      99: '76'
    }
  },
  4: {
    5: {
      50: '50',
      90: '64',
      95: '68',
      99: '76'
    },
    10: {
      50: '50',
      90: '64',
      95: '68',
      99: '76'
    },
    25: {
      50: '51',
      90: '65',
      95: '69',
      99: '76'
    },
    50: {
      50: '52',
      90: '66',
      95: '70',
      99: '77'
    },
    75: {
      50: '52',
      90: '67',
      95: '71',
      99: '78'
    },
    90: {
      50: '53',
      90: '67',
      95: '71',
      99: '79'
    },
    95: {
      50: '54',
      90: '68',
      95: '72',
      99: '79'
    }
  },
  5: {
    5: {
      50: '52',
      90: '66',
      95: '70',
      99: '78'
    },
    10: {
      50: '53',
      90: '67',
      95: '71',
      99: '78'
    },
    25: {
      50: '53',
      90: '67',
      95: '71',
      99: '79'
    },
    50: {
      50: '54',
      90: '68',
      95: '72',
      99: '79'
    },
    75: {
      50: '55',
      90: '69',
      95: '73',
      99: '80'
    },
    90: {
      50: '55',
      90: '69',
      95: '73',
      99: '81'
    },
    95: {
      50: '56',
      90: '70',
      95: '74',
      99: '81'
    }
  },
  6: {
    5: {
      50: '54',
      90: '68',
      95: '72',
      99: '80'
    },
    10: {
      50: '54',
      90: '68',
      95: '72',
      99: '80'
    },
    25: {
      50: '55',
      90: '69',
      95: '73',
      99: '80'
    },
    50: {
      50: '56',
      90: '70',
      95: '74',
      99: '81'
    },
    75: {
      50: '56',
      90: '70',
      95: '74',
      99: '82'
    },
    90: {
      50: '57',
      90: '71',
      95: '75',
      99: '83'
    },
    95: {
      50: '58',
      90: '72',
      95: '76',
      99: '83'
    }
  },
  7: {
    5: {
      50: '55',
      90: '69',
      95: '73',
      99: '81'
    },
    10: {
      50: '56',
      90: '70',
      95: '74',
      99: '81'
    },
    25: {
      50: '56',
      90: '70',
      95: '74',
      99: '82'
    },
    50: {
      50: '57',
      90: '71',
      95: '75',
      99: '82'
    },
    75: {
      50: '58',
      90: '72',
      95: '76',
      99: '83'
    },
    90: {
      50: '58',
      90: '72',
      95: '76',
      99: '84'
    },
    95: {
      50: '59',
      90: '73',
      95: '77',
      99: '84'
    }
  },
  8: {
    5: {
      50: '57',
      90: '71',
      95: '75',
      99: '82'
    },
    10: {
      50: '57',
      90: '71',
      95: '75',
      99: '82'
    },
    25: {
      50: '57',
      90: '71',
      95: '75',
      99: '83'
    },
    50: {
      50: '58',
      90: '72',
      95: '76',
      99: '83'
    },
    75: {
      50: '59',
      90: '73',
      95: '77',
      99: '84'
    },
    90: {
      50: '60',
      90: '74',
      95: '78',
      99: '85'
    },
    95: {
      50: '60',
      90: '74',
      95: '78',
      99: '86'
    }
  },
  9: {
    5: {
      50: '58',
      90: '72',
      95: '76',
      99: '83'
    },
    10: {
      50: '58',
      90: '72',
      95: '76',
      99: '83'
    },
    25: {
      50: '58',
      90: '72',
      95: '76',
      99: '84'
    },
    50: {
      50: '59',
      90: '73',
      95: '77',
      99: '84'
    },
    75: {
      50: '60',
      90: '74',
      95: '78',
      99: '85'
    },
    90: {
      50: '61',
      90: '75',
      95: '79',
      99: '86'
    },
    95: {
      50: '61',
      90: '75',
      95: '79',
      99: '87'
    }
  },
  10: {
    5: {
      50: '59',
      90: '73',
      95: '77',
      99: '84'
    },
    10: {
      50: '59',
      90: '73',
      95: '77',
      99: '84'
    },
    25: {
      50: '59',
      90: '73',
      95: '77',
      99: '85'
    },
    50: {
      50: '60',
      90: '74',
      95: '78',
      99: '86'
    },
    75: {
      50: '61',
      90: '75',
      95: '79',
      99: '86'
    },
    90: {
      50: '62',
      90: '76',
      95: '80',
      99: '87'
    },
    95: {
      50: '62',
      90: '76',
      95: '80',
      99: '88'
    }
  },
  11: {
    5: {
      50: '60',
      90: '74',
      95: '78',
      99: '85'
    },
    10: {
      50: '60',
      90: '74',
      95: '78',
      99: '85'
    },
    25: {
      50: '60',
      90: '74',
      95: '78',
      99: '86'
    },
    50: {
      50: '61',
      90: '75',
      95: '79',
      99: '87'
    },
    75: {
      50: '62',
      90: '76',
      95: '80',
      99: '87'
    },
    90: {
      50: '63',
      90: '77',
      95: '81',
      99: '88'
    },
    95: {
      50: '63',
      90: '77',
      95: '81',
      99: '89'
    }
  },
  12: {
    5: {
      50: '61',
      90: '75',
      95: '79',
      99: '86'
    },
    10: {
      50: '61',
      90: '75',
      95: '79',
      99: '86'
    },
    25: {
      50: '61',
      90: '75',
      95: '79',
      99: '87'
    },
    50: {
      50: '62',
      90: '76',
      95: '80',
      99: '88'
    },
    75: {
      50: '63',
      90: '77',
      95: '81',
      99: '88'
    },
    90: {
      50: '64',
      90: '78',
      95: '82',
      99: '89'
    },
    95: {
      50: '64',
      90: '78',
      95: '82',
      99: '90'
    }
  },
  13: {
    5: {
      50: '62',
      90: '76',
      95: '80',
      99: '87'
    },
    10: {
      50: '62',
      90: '76',
      95: '80',
      99: '87'
    },
    25: {
      50: '62',
      90: '76',
      95: '80',
      99: '88'
    },
    50: {
      50: '63',
      90: '77',
      95: '81',
      99: '89'
    },
    75: {
      50: '64',
      90: '78',
      95: '82',
      99: '89'
    },
    90: {
      50: '65',
      90: '79',
      95: '83',
      99: '90'
    },
    95: {
      50: '65',
      90: '79',
      95: '83',
      99: '91'
    }
  },
  14: {
    5: {
      50: '63',
      90: '77',
      95: '81',
      99: '88'
    },
    10: {
      50: '63',
      90: '77',
      95: '81',
      99: '88'
    },
    25: {
      50: '63',
      90: '77',
      95: '81',
      99: '89'
    },
    50: {
      50: '64',
      90: '78',
      95: '82',
      99: '90'
    },
    75: {
      50: '65',
      90: '79',
      95: '83',
      99: '90'
    },
    90: {
      50: '66',
      90: '80',
      95: '84',
      99: '91'
    },
    95: {
      50: '66',
      90: '80',
      95: '84',
      99: '92'
    }
  },
  15: {
    5: {
      50: '64',
      90: '78',
      95: '82',
      99: '89'
    },
    10: {
      50: '64',
      90: '78',
      95: '82',
      99: '89'
    },
    25: {
      50: '64',
      90: '78',
      95: '82',
      99: '90'
    },
    50: {
      50: '65',
      90: '79',
      95: '83',
      99: '91'
    },
    75: {
      50: '66',
      90: '80',
      95: '84',
      99: '91'
    },
    90: {
      50: '67',
      90: '81',
      95: '85',
      99: '92'
    },
    95: {
      50: '67',
      90: '81',
      95: '85',
      99: '93'
    }
  },
  16: {
    5: {
      50: '64',
      90: '78',
      95: '82',
      99: '90'
    },
    10: {
      50: '64',
      90: '78',
      95: '82',
      99: '90'
    },
    25: {
      50: '65',
      90: '79',
      95: '83',
      99: '90'
    },
    50: {
      50: '66',
      90: '80',
      95: '84',
      99: '91'
    },
    75: {
      50: '66',
      90: '81',
      95: '85',
      99: '92'
    },
    90: {
      50: '67',
      90: '81',
      95: '85',
      99: '93'
    },
    95: {
      50: '68',
      90: '82',
      95: '86',
      99: '93'
    }
  },
  17: {
    5: {
      50: '64',
      90: '78',
      95: '82',
      99: '90'
    },
    10: {
      50: '65',
      90: '79',
      95: '83',
      99: '90'
    },
    25: {
      50: '65',
      90: '79',
      95: '83',
      99: '91'
    },
    50: {
      50: '66',
      90: '80',
      95: '84',
      99: '91'
    },
    75: {
      50: '67',
      90: '81',
      95: '85',
      99: '92'
    },
    90: {
      50: '67',
      90: '81',
      95: '85',
      99: '93'
    },
    95: {
      50: '68',
      90: '82',
      95: '86',
      99: '93'
    }
  }
};

export const getDiastolicCalculation = (
  height: any,
  value: any,
  dob: any,
  created_date: any,
  age_year: any,
  gender: any
): any => {
  try {
    if (height) {
      // const dob_date1 = moment(moment(dob).format('YYYY-MM-DD'));
      // const months = dob_date1.diff(created_date, 'months');
      // const month_diff = months <= 0 ? 0 : months;

      const createDate = moment(moment(created_date).format('YYYY-MM-DD'));
      const dob_date = moment(moment(dob).format('YYYY-MM-DD'));
      const months = createDate.diff(dob_date, 'months');
      const month_diff = months <= 0 ? 0 : months;

      let age = age_year;
      if (age === 0) {
        age = 1;
      }
      if (month_diff < 204) {
        let dia_per: any = '';
        let diastolic_data: any = '';
        if (gender === GenderTypes.MALE) {
          dia_per = BoyHyperBP?.[month_diff];
          diastolic_data = BoyDiaBP?.[age];
        } else {
          dia_per = GirlHyperBP?.[month_diff];
          diastolic_data = GirlDiaBP?.[age];
        }
        let dic: any = {};
        dic = _.invert(dia_per);
        const key_list: any = [];
        // eslint-disable-next-line guard-for-in
        for (const key in dic) {
          key_list.push(key);
        }
        const sort_val = key_list.sort();
        const len = sort_val.length;
        let perc = '';
        let pval: any = 0;
        for (let i = 0; i < len; i++) {
          if (i == len - 2) {
            const avg_dia = (
              (parseFloat(sort_val[i]) + parseFloat(sort_val[i + 1])) /
              2
            ).toFixed(1);
            if (height <= avg_dia && height > pval) {
              perc = dic[sort_val[i]];
            } else if (height > avg_dia) {
              perc = dic[sort_val[i + 1]];
            }
          } else if (i == len - 1) {
            const avg1_dia = (
              (parseFloat(sort_val[i]) + parseFloat(sort_val[i + 1])) /
              2
            ).toFixed(1);
            const avg2_dia = (
              (parseFloat(sort_val[i + 1]) + parseFloat(sort_val[i + 2])) /
              2
            ).toFixed(1);
            if (height <= avg2_dia && height > avg1_dia) {
              perc = dic[sort_val[i]];
            } else if (height > avg2_dia && height <= sort_val[i + 1]) {
              perc = dic[sort_val[i + 1]];
            }
          } else {
            const avg3_dia = (
              (parseFloat(sort_val[i]) + parseFloat(sort_val[i + 1])) /
              2
            ).toFixed(1);
            if (height <= avg3_dia && height > pval) {
              perc = dic[sort_val[i]];
            }
            pval = avg3_dia;
          }
        }
        for (const item in diastolic_data) {
          if (item === perc) {
            const fieldh50 = parseInt(diastolic_data[item]['50']); // green
            const fieldh90 = parseInt(diastolic_data[item]['90']); // green
            const fieldh95 = parseInt(diastolic_data[item]['95']); // amber
            const fieldh99 = parseInt(diastolic_data[item]['99']); // red
            if (value >= fieldh50 && value <= fieldh90) {
              return 1;
            } else if (value >= fieldh90 && value < fieldh95) {
              return 0.5;
            } else if (value >= fieldh95 || value < fieldh50) {
              return 0;
            } else if (value > fieldh99 || value < fieldh50) {
              return 0;
            }
          }
        }
      } else {
        for (const dia of Diastolic) {
          if (value >= dia.min && (value <= dia.max || dia.max === 'above')) {
            return dia.value;
          }
        }
      }
    }
    return null;
  } catch (e) {
    throw new Error((e as any)?.message);
  }
};
