import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as UploadIconSvg} from '@assets/images/healpha/UploadIcon.svg';

const UploadIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={UploadIconSvg}
      viewBox="0 0 16 30"
    />
  );
};

export default UploadIcon;
