import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import { ReactComponent as ForumsIllustrationIconSvg } from '@assets/images/healpha/forums_illustration.svg';

const ForumsIllustrationIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={ForumsIllustrationIconSvg} viewBox="0 0 369.639 222.09" />
  );
};

export default ForumsIllustrationIcon;