import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as WhiteAddIconSvg} from '@assets/images/healpha/WhiteAdd.svg';

const WhiteAddIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={WhiteAddIconSvg}
      viewBox="0 0 12 12"
    />
  );
};

export default WhiteAddIcon;
