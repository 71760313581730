/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Avatar, Grid, IconButton, Button, Typography} from '@material-ui/core';
import moment from 'moment';

import Icons from '@components/system/MyIcons';
import CommonUtils from 'src/utils/CommonUtils';
import './CommonDialogTitleComponent.scss';

const CommonDialogTitleComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();

  const closePopup = (): any => {
    props?.close();
  };

  return (
    <Grid container spacing={2} className="common-dialog-title-wrapper">
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={10}>
          <Typography className="appointment-header-text">
            {t('APPOINTMENT_DETAILS')}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <IconButton
            id="close_popup"
            style={{marginTop: '-1rem', marginLeft: '2rem'}}
            onClick={closePopup}>
            <Icons.CloseWhiteIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item sm={2}>
        <Avatar
          alt="profile-image"
          src={CommonUtils.getFullImageUrl(
            props?.appointmentValues?.doctor?.profile_image
          )}
          sx={{width: '5.5rem', height: '5.5rem'}}
        />
      </Grid>

      <Grid item sm={10} container direction="column" spacing={2}>
        <Grid item sm={12} container direction="column" spacing={2}>
          <Grid item sm={12}>
            <Typography className="doctor-name">
              {t(
                `Dr. ${CommonUtils.getFullName(
                  props?.appointmentValues?.doctor
                )}`
              )}
            </Typography>

            <Typography className="appointment-details">
              {`${moment(props?.appointmentValues?.calendarValue).format(
                'dddd'
              )}, 
              ${moment(props?.appointmentValues?.calendarValue).format('MMM')} 
              ${moment(props?.appointmentValues?.calendarValue).format(
                'D'
              )}  - ${props?.appointmentValues?.slotTime}, Consultation - Rs.${
                props?.appointmentValues?.appointment?.price
              }(${
                props?.appointmentValues?.appointment?.appointment_type_name
              })`}
            </Typography>

            <Typography className="address-details">
              {t(
                `${props?.appointmentValues?.branch?.branch_name}, ${
                  props?.appointmentValues?.branch?.address1
                    ? props.appointmentValues.branch.address1
                    : ''
                }`
              )}
            </Typography>

            {props?.showChangeIcon && (
              <Button
                disableElevation={true}
                variant="contained"
                size="small"
                style={{marginLeft: '-0.6rem'}}
                className="address-details"
                startIcon={<Icons.ChangeIcon />}
                onClick={closePopup}>
                {t('CHANGE')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommonDialogTitleComponent;
