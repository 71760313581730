import React, {useState} from 'react';

import {
  Button,
  Dialog,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';

import Loader from '@components/framework/loader/Loader';

import {useTranslation} from 'react-i18next';

import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import Icons from '@components/system/MyIcons';
import {useSnackbar} from 'notistack';

import {
  addPatientUnavailableMessage,
  getPatientUnavailableOptions
} from '@shared/services/PublicService';
import AuthUtils from 'src/utils/AuthUtils';
import {patientUnavailableType} from 'src/constants/SystemConstants';
import './WriteMessageNew.scss';

const WriteMessageNew = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [open, setOpen] = useState(props?.open);
  const [showOtherMessageBox, setShowOtherMessageBox] = useState(false);
  const [messageList, setMessageList] = useState<any>([]);
  const encId = AuthUtils.getEncId();
  const healphaId = AuthUtils.getHealphaId();
  const docId = AuthUtils.getDoctorId();
  const [loading, setLoading] = React.useState(false);
  const [loadMessageList, setLoadMessageList] = React.useState(false);
  const [values, setValues]: any = useState({
    label: ''
  });
  const {appointmentId} = props;
  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
    if (event.target.value === 'Other') {
      setShowOtherMessageBox(true);
    } else {
      setShowOtherMessageBox(false);
    }
  };

  const handleChangeMessage = (prop: any) => (event: any) => {
    setValues({...values, message: event.target.value});
  };

  const handleCloseWriteMessage = () => {
    setOpen(false);
    props?.closeWriteMessage();
  };

  const handlePatientUnavailableOptions = () => {
    setLoadMessageList(true);

    getPatientUnavailableOptions()
      .then(res => {
        setLoadMessageList(false);

        const data = res?.data?.values;
        setMessageList(data);
      })
      .catch(err => {
        setLoadMessageList(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const init = () => {
    handlePatientUnavailableOptions();
  };

  React.useEffect(init, []);

  const handleSubmit = (data: any, isCancelled: any): void => {
    setLoading(true);
    const isAppointmentCancelled = isCancelled;

    addPatientUnavailableMessage(
      encId,
      docId,
      healphaId,
      patientUnavailableType,
      data,
      appointmentId,
      isAppointmentCancelled
    )
      .then(res => {
        setLoading(false);
        props?.runOnClick();

        enqueueSnackbar(res?.message, SnackBarConfig('s'));
        handleCloseWriteMessage();
        setOpen(false);
      })
      .catch(err => {
        setLoading(false);

        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
        handleCloseWriteMessage();
      });
  };

  const renderRadioButtons = (inputList: any): React.ReactElement => {
    return (
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="doctor-type"
          id="doctor-type"
          name="doctor-type"
          onChange={handleChange('label')}
          className="message-radio-group"
          value={values?.label}>
          {inputList?.map((item: any, index: any) => {
            return (
              <FormControlLabel
                id={'radio_' + index}
                key={index}
                value={item?.label}
                className="one-message-radio-button"
                sx={{marginBottom: 0}}
                control={<Radio size="small" />}
                label={
                  <Typography className="one-message-radio-text">
                    {' '}
                    {item?.label}{' '}
                  </Typography>
                }
              />
            );
          })}
        </RadioGroup>
        {showOtherMessageBox && (
          <Typography>
            <TextField
              id="text_field"
              className="other-message-text-box"
              onChange={handleChangeMessage('message')}
            />
          </Typography>
        )}
      </FormControl>
    );
  };

  return (
    <div>
      <Dialog
        className="write-message-wrapper"
        onClose={handleCloseWriteMessage}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <DialogTitle>
          <Grid container>
            <Grid item xs={11} className="write-message-title">
              {t('CONNECT_NOW_WRITE_MESSAGE')}
            </Grid>
            <Grid item xs={1}>
              <Icons.CloseIcon
                cursor="pointer"
                onClick={handleCloseWriteMessage}
                className="close-icon"
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="title">
          <Grid item xs={12} className="write-message-content">
            {loadMessageList ? (
              <Loader color="inherit" size={20} />
            ) : (
              messageList && renderRadioButtons(messageList)
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container className="send-message-button-grid">
            <Grid item xs={12}>
              <Button
                id="Send_go_back"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => handleSubmit(values?.label, false)}
                className="send-go-back-button">
                {loading ? (
                  <Loader color="inherit" size={20} />
                ) : (
                  t('CONNECT_NOW_SEND_MESSAGE_AND_GO_BACK')
                )}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                id="send_cancel"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => handleSubmit(values?.label, true)}
                className="send-cancel-button">
                {loading ? (
                  <Loader color="inherit" size={20} />
                ) : (
                  t('CONNECT_NOW_SEND_MESSAGE_AND_CANCEL')
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default WriteMessageNew;
