import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as CorporateIconSvg} from '@assets/images/healpha/Corporate.svg';

const CorporateIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={CorporateIconSvg}
      viewBox="0 0 201 167"
    />
  );
};

export default CorporateIcon;
