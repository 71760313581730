import {decrypt, encrypt} from '@shared/utils/LocalStorage';

class Storage {
  static KEYS = {
    PORTAL: 'PORTAL',
    AUTH_TOKEN: 'AUTH_TOKEN',
    PHP_TOKEN: 'PHP_TOKEN',
    CLIENT_ID: 'CLIENT_ID',
    ADMIN_AUTH_TOKEN: 'ADMIN_AUTH_TOKEN',
    ADMIN_ID: 'ADMIN_ID',
    ADVISOR_AUTH_TOKEN: 'ADVISOR_AUTH_TOKEN',
    ADVISOR_ID: 'ADVISOR_ID',
    NEW_CLIENT: 'NEW_CLIENT',
    CLIENT_STATUS: 'CLIENT_STATUS',
    CLICKED_COMPANY_NAME: 'CLICKED_COMPANY_NAME',
    CLIENT_ACCOUNT_INFO: 'CLIENT_ACCOUNT_INFO',
    COMPANY_ON_BOARD_INFO: 'COMPANY_ON_BOARD_INFO',
    NH_ID: 'NH_ID',
    HEALPHA_ID: 'HEALPHA_ID',
    ENC_ID: 'ENC_ID',
    TEMPLATE_ID_NEW: 'TEMPLATE_ID_NEW',
    BRANCH_ID: 'BRANCH_ID',
    PERSON_DETAILS: 'PERSON_DETAILS',
    PERSON_CORPORATE_DETAILS: 'PERSON_CORPORATE_DETAILS',
    PERSON_NAME: 'PERSON_NAME',
    MENU_MODULE_DATA: 'MENU_MODULE_DATA',
    MENU_SECTION_DATA: 'MENU_SECTION_DATA',
    MENU_BRANCH_ID: 'MENU_BRANCH_ID',
    MENU_PRACTICE_ID: 'MENU_PRACTICE_ID',
    MENU_PAGE_URL: 'MENU_PAGE_URL',
    TEMPLATE_ID: 'TEMPLATE_ID',
    TEMPLATE_CATEGORY_ID: 'TEMPLATE_CATEGORY_ID',
    TEMPLATE_MICRO_ID: 'TEMPLATE_MICRO_ID',
    SERVICE_ID: 'SERVICE_ID',
    VIRTUAL_CLINIC_ID: 'VIRTUAL_CLINIC_ID',
    ADMIN_DOC_DASHBOARD_ID: 'ADMIN_DOC_DASHBOARD_ID',
    ADMIN_NURSE: 'ADMIN_NURSE',
    APPOINTMENT_PERSON_NAME: 'APPOINTMENT_PERSON_NAME',
    TEMPLATE_PRACTICE_ID: 'TEMPLATE_PRACTICE_ID',
    TEMPLATE_BRANCH_ID: 'TEMPLATE_BRANCH_ID',
    TEMPLATE_DOCTOR_ID: 'TEMPLATE_DOCTOR_ID',
    SELECTED_PATIENT_ID: 'SELECTED_PATIENT_ID',
    PAYMENT_CRITERIA: 'PAYMENT_CRITERIA',
    PAYMENT_DETAILS: 'PAYMENT_DETAILS',
    SUBSCRIPTION_DETAILS: 'SUBSCRIPTION_DETAILS',
    CATEGORY_VISIBILITY: 'CATEGORY_VISIBILITY',
    VISIT_ID: 'VISIT_ID',
    IS_STANDALONE: 'IS_STANDALONE',
    CORPORATE_PERSON_DETAILS: 'CORPORATE_PERSON_DETAILS',
    IS_ROLETYPE: 'IS_ROLETYPE',
    IS_PRESCRIBED: 'IS_PRESCRIBED',
    APPOINTMENT_NOTIFY: 'APPOINTMENT_NOTIFY',
    APPOINTMENT_DETAILS: 'APPOINTMENT_DETAILS',
    BILLING_APPOINTMENT_DETAILS: 'BILLING_APPOINTMENT_DETAILS',
    TEMPLATE_ATTACHED_ID: 'TEMPLATE_ATTACHED_ID',
    ENC_ID_BILLING: 'ENC_ID_BILLING',
    MODULE_ID: 'MODULE_ID',
    NEWUSER_ID: 'NEWUSER_ID',
    IS_FROM_TIMELINE: 'IS_FROM_TIMELINE',
    ROLE_ID: 'ROLE_ID',
    ROLE_NAME: 'ROLE_NAME',
    ROLE_DESCRIPTION: 'ROLE_DESCRIPTION',
    ROLE_HEADING: 'ROLE_HEADING',
    ROLE_DETAIL: 'ROLE_DETAIL',
    HEALPHA_ID_FOR_PERSON_WEB: 'HEALPHA_ID_FOR_PERSON_WEB',
    BRANCH_NAME: 'BRANCH_NAME',
    IS_FROM_VIRTUAL_CLINIC: 'IS_FROM_VIRTUAL_CLINIC',
    FROM_PERSON_WEB: 'FROM_PERSON_WEB',
    IS_ROLETYPE_NURSE: 'IS_ROLETYPE_NURSE',
    MID: 'MID',
    PLAN_ID: 'PLAN_ID',
    SELF_SERVICE_PRACTICE_ID: 'SELF_SERVICE_PRACTICE_ID',
    SELF_SERVICE_Branch_ID: 'SELF_SERVICE_Branch_ID',
    MENU_ID: 'MENU_ID',
    NON_HEALPHA_USER_ID: 'NON_HEALPHA_USER_ID',
    NON_HEALPHA_ACCESS_USER_ID: 'NON_HEALPHA_ACCESS_USER_ID',
    NON_HEALPHA_USER_DOCTOR_FLAG: 'NON_HEALPHA_USER_DOCTOR_FLAG',
    IS_MODULE_SET_UP: 'IS_MODULE_SET_UP',
    IS_SELF_SERVICE: 'IS_SELF_SERVICE',
    DOCTOR_SLOT_TIME: 'DOCTOR_SLOT_TIME',
    DOCTOR_TYPE: 'DOCTOR_TYPE',
    PRACTICE_ADMIN_NAME: 'PRACTICE_ADMIN_NAME',
    PRACTICE_ADMIN_EMAIL: 'PRACTICE_ADMIN_EMAIL',
    BRANCH_ADMIN_NAME: 'BRANCH_ADMIN_NAME',
    BRANCH_ADMIN_EMAIL: 'BRANCH_ADMIN_EMAIL',
    WORK_FLOW_INFO: 'WORK_FLOW_INFO',
    BRANCH_WORK_FLOW_INFO: 'BRANCH_WORK_FLOW_INFO',
    SELF_SERVICE_NEW_PRACTICE_ID: 'SELF_SERVICE_NEW_PRACTICE_ID',
    MODULE_SETUP_TYPE: 'MODULE_SETUP_TYPE',
    CORPORATE_MODULE_BRANCHID: 'CORPORATE_MODULE_BRANCHID',
    MODULE_BRANCHID: 'MODULE_BRANCHID',
    DOCTOR_SPECIALIZATION: 'DOCTOR_SPECIALIZATION',
    ENABLING: 'ENABLING',
    ENABLING2: 'ENABLING2',
    ENABLING3: 'ENABLING3',
    ENABLING4: 'ENABLING4',
    PRACTICE_MODULES_FEATURE: 'PRACTICE_MODULES_FEATURE',
    DOCTOR_ROLE: 'DOCTOR_ROLE',
    CORPORATE_ENABLING: 'CORPORATE_ENABLING',
    CORPORATE_ENABLING2: 'CORPORATE_ENABLING2',
    CORPORATE_ENABLING3: 'CORPORATE_ENABLING3',
    OP_ENABLING: 'OP_ENABLING',
    OP_ENABLING2: 'OP_ENABLING2',
    OP_ENABLING3: 'OP_ENABLING3',
    REG_COMPLETED: 'REG_COMPLETED',
    REG_COMPLETED2: 'REG_COMPLETED2',
    REG_COMPLETED3: 'REG_COMPLETED3',
    REG_COMPLETED4: 'REG_COMPLETED4',
    HEALPHA_ADMIN: 'HEALPHA_ADMIN',
    HEALPHA_DOCTOR: 'HEALPHA_DOCTOR',
    VIRTUAL_BOOL: 'VIRTUAL_BOOL',
    EDIT_USER_FLAG: 'EDIT_USER_FLAG',
    CLOUD_CLINIC_FLAG: 'CLOUD_CLINIC_FLAG',
    EDIT_PROFILE_FLAG: 'EDIT_PROFILE_FLAG',
    MANAGE_BANK_FLAG: 'MANAGE_BANK_FLAG',
    SELF_SERVICE_STATUS: 'SELF_SERVICE_STATUS',
    ROLE_Module_id: 'ROLE_Module_id',
    OPERATIONAL_FLAG: 'OPERATIONAL_FLAG',
    IS_OPERATIONAL_BRANCH: 'IS_OPERATIONAL_BRANCH',
    IS_PERSON_CORPORATE: 'IS_PERSON_CORPORATE',
    MODULE_DETAILS: 'MODULE_DETAILS',
    TECHNICAL_FLAG: 'TECHNICAL_FLAG',
    SELF_SERVICE_MODULE_ID_: 'SELF_SERVICE_MODULE_ID_'
  };

  static setItem = (key: string, value: any): void => {
    if (!value) {
      value = '';
    }
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(encrypt(key), encrypt(value));
  };

  static getItem = (key: string, shouldParse?: boolean): string | null => {
    const keyVal = decrypt(localStorage.getItem(Storage.searchKey(key)));
    if (shouldParse && keyVal) {
      return JSON.parse(keyVal);
    }
    return keyVal;
  };

  static searchKey(key: string): string {
    const keys = Object.keys(localStorage);
    let i = keys.length;
    while (i--) {
      try {
        if (decrypt(keys[i]) === key) {
          return keys[i];
        }
      } catch (e) {
        localStorage.removeItem(keys[i]);
      }
    }
    return '';
  }

  static removeItem = (key: string): void => {
    localStorage.removeItem(Storage.searchKey(key));
  };

  static clear = (): void => {
    localStorage.clear();
  };
}

export default Storage;
