import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import { ReactComponent as DropDownIconSvg } from '@assets/images/healpha/DownLine.svg';

const DropDownIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={DropDownIconSvg} viewBox="0 0 24 24" />
  );
};

export default DropDownIcon;