/* eslint-disable camelcase */
import React from 'react';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle
} from '@material-ui/core';

import {
  cancelAppointment,
  updateEncStatus
} from '@shared/services/PublicService';

import Icons from '@components/system/MyIcons';
import {statusColor} from 'src/constants/statusColors';
import {AppointmentStatusTypes} from 'src/constants/SystemConstants';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import Close from '../../../../../../../assets/images/healpha/Close.svg';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import PdfViewerDialog from 'src/modules/shared/PdfViewerComponent/PdfViewerDialog';

import moment from 'moment';
import CommonUtils from 'src/utils/CommonUtils';
import './PatientCard.scss';
import NewAppointmentBookingsComponent from 'src/modules/user/modules/ClinicDashboard/Appointment/AppointmentBooking';

const DoctorCard = (props: any): React.ReactElement => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [cancel, setcancel] = React.useState<boolean>(false);
  const [refund, setRefund] = React.useState<boolean>(false);
  const [click, setClick] = React.useState<boolean>(false);
  const [cancelReason, setcancelReason] = React.useState<any>('');
  const [showPdfFileDialog, setShowPdfFileDialog] =
    React.useState<boolean>(false);
  const [pdfUrl, setpdfUrl] = React.useState<any>('');
  const [processOpen, setProcessOpen] = React.useState<any>(false);
  const [
    showNewAppointmentBookingUpPopup,
    setShowNewAppointmentBookingUpPopup
  ] = React.useState<boolean>(false);
  const {
    currPatient,
    selectedPatient,
    setSelectedPatient,
    index,
    appointmentTypeStatus,
    prescription,
    keyGiven,
    clickedStatus,
    selectedDate,
    healphaIdForPersonWeb
  } = props;
  const ITEM_HEIGHT = 48;
  const {
    appointment_type,
    date_start,
    appointment_status,
    id,
    // healpha_id,
    doc_id,
    branch_id,
    appointment_date_start,
    doctor_details,
    branch_details
  } = currPatient;

  const timeOfApp: any = CommonUtils.convertTo12Hours(date_start);
  const timeOfAppAMPM: any =
    CommonUtils.convertTo12Hours(date_start)?.slice(-2);
  const dateMonthYear: any = moment(
    appointment_date_start.split(' ')?.[0]
  ).format('MMM YYYY');
  const dateDay = moment(appointment_date_start.split(' ')?.[0]).format('DD');

  const handleEvent = () => {
    const date = selectedDate;
    const evt = new CustomEvent('handleStatusCount', {detail: {date: date}});
    window.dispatchEvent(evt);
    window.dispatchEvent(new Event('handlingDisplay'));
  };

  const onProcess = (): void => {
    updateEncStatus(id)
      .then(res => {
        setProcessOpen(false);
        handleEvent();
        enqueueSnackbar(t(res.message), SnackBarConfig('s'));
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const init = () => {
    if (props?.qrAppointmentId === props?.compareAppointmentId) {
      setClick(true);
    }
  };
  React.useEffect(init, [props?.qrAppointmentId]);

  const handlePersonCard = () => {
    props?.handlePatientDetails(currPatient);
    props?.handleAppointmentDetails(
      currPatient?.id,
      props?.isVirtualAppointment,
      currPatient?.virtual_clinic_branch
    );
    setSelectedPatient(index);
    setClick(false);
  };

  const handlePdfViewer = (e: any) => {
    e.stopPropagation();
    setShowPdfFileDialog(true);
    setpdfUrl(CommonUtils.getFullImageUrl(prescription));
  };

  const closeShowPdfFileDialog = () => {
    setShowPdfFileDialog(false);
    setRefund(false);
    refund && handleEvent();
  };
  const renderPdfViewerDialog = (): React.ReactElement => {
    return (
      <PdfViewerDialog
        open={showPdfFileDialog}
        closeShowPdfFileDialog={closeShowPdfFileDialog}
        pdfURL={pdfUrl}
        title={refund ? 'Refund Receipt' : 'View Prescription'}
      />
    );
  };

  const renderStatusAndDot = (): React.ReactElement => {
    const bgColor = statusColor.filter(
      item => item.name === keyGiven || item.name === clickedStatus
    );
    const newDotColor = bgColor[0]?.dotColor;
    return (
      <Grid
        container
        spacing={0.25}
        alignItems="center"
        className="status-dot-grid">
        <Grid item>
          <Typography className="status-name">{appointment_status}</Typography>
        </Grid>

        <Grid
          item
          className="status-dot"
          style={{backgroundColor: newDotColor}}
        />
      </Grid>
    );
  };

  const handleClick = (event: any) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e?: any) => {
    e && e.stopPropagation();

    setAnchorEl(null);
  };
  const renderKebabMenu = () => {
    return (
      <Grid item xs={2}>
        <Icons.KebabmenuIcon
          onClick={handleClick}
          className="kebab-icon"
          id={'id_' + index}
        />
      </Grid>
    );
  };

  const CancelAppointment = (e: any) => {
    cancelAppointment(cancelReason, id)
      .then(res => {
        setcancelReason('');
        setcancel(false);
        if (res?.data?.receipt) {
          setRefund(true);
          setShowPdfFileDialog(true);
          setpdfUrl(CommonUtils.getFullImageUrl(res?.data?.receipt));
        } else {
          handleEvent();
        }

        enqueueSnackbar(
          res?.message ? t(res.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('s')
        );
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleCloseProcess = () => {
    setProcessOpen(false);
  };

  const ProcessModal = () => {
    return (
      <Dialog
        className="cancel-appointment-wrapper p-2"
        onClose={handleCloseProcess}
        aria-labelledby="simple-dialog-title"
        open={processOpen}>
        <div className="dialog">
          <DialogTitle id="simple-dialog-title">
            <Grid container xs={6} md={12} sm={12} className="addservice">
              <Grid item xs={8} md={10} sm={10} className="add fontback ml-0">
                <h5>
                  <b>{t('HAS_THE_PATIENT_ARRIVED_FOR_CONSULTATION?')}</b>
                </h5>
              </Grid>
              <Grid item xs={2} className="clos">
                <img
                  src={Close}
                  alt="close"
                  width={20}
                  height={20}
                  onClick={handleCloseProcess}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <Grid xs={8} md={10} sm={12} className="add-clik">
            <Typography>
              {t(
                'CLICKING_ON_YES_WILL_GENERATE_AN_ENCOUNTER_TO_CONTINUE_THE_CONSULTATION'
              )}
              .
            </Typography>
          </Grid>
        </div>
        <div className="add-bt ">
          <Button
            onClick={onProcess}
            variant="contained"
            className="text-nowrap mx-4 my-2 fontback btn-primary add-wt">
            {t('COMMON_YES')}
          </Button>
        </div>
        <div className="add-bt ">
          <Button
            onClick={handleCloseProcess}
            size="medium"
            color="primary"
            variant="outlined"
            className="text-nowrap my-2 mx-4 fontback add-wt">
            {t('TEMPLATE_CANCEL')}
          </Button>
        </div>
      </Dialog>
    );
  };

  const CancelAppointmentModal = () => {
    return (
      <Dialog
        className="cancel-appointment-wrapper p-2"
        // onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={cancel}>
        <div className="dialog">
          <DialogTitle id="simple-dialog-title">
            <div className="row d-flex addservice">
              <div className="col-sm-10 add fontback">
                <h6>
                  <b>{t('REASON')}</b>
                </h6>
              </div>
              <div className="col-sm-2 clos">
                <img
                  src={Close}
                  alt="close"
                  width={20}
                  height={20}
                  onClick={() => {
                    setcancel(false);
                    setcancelReason('');
                  }}
                />
              </div>
            </div>
          </DialogTitle>
          <div className="px-4 py-2 conform conformText">
            {/* <span>{t('ARE_YOU_SURE_WANT_TO_DELETE_SERVICES')}</span> */}
            <FormTextField
              name="reason"
              value={cancelReason}
              callback={(e: any) => {
                setcancelReason(e.target.value);
              }}
            />
          </div>
          <div className="px-4 py-2 conform">
            <Button
              onClick={CancelAppointment}
              variant="contained"
              className="text-nowrap mx-2 my-2 fontback btn-primary">
              {/* {t('delete')} */}
              {t('SUBMIT')}
            </Button>
            <Button
              onClick={() => {
                setcancel(false);
                setcancelReason('');
              }}
              color="primary"
              variant="outlined"
              className="text-nowrap my-2 mr-0 fontback">
              {t('TEMPLATE_CANCEL')}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const closeNewAppointmentBookingPopup = () => {
    setShowNewAppointmentBookingUpPopup(false);
  };

  const handleNewAppointmentBookingPopup = () => {
    setShowNewAppointmentBookingUpPopup(true);
  };

  const renderNewAppointmentBookingPopup = (): React.ReactElement => {
    return (
      <NewAppointmentBookingsComponent
        branchId={branch_id}
        open={showNewAppointmentBookingUpPopup}
        closeNewBookingsPopup={closeNewAppointmentBookingPopup}
        is_clinical_dashboard={true}
        selectedDate={selectedDate}
        docId={doc_id}
        // healphaId={healpha_id}
        healphaId={healphaIdForPersonWeb}
        // healphaId={isFromPersonWeb ? doc_id : healpha_id}
        isFromPersonWeb={true}
        reschedule={true}
        appointment_id={id}
        enc_id={
          currPatient?.enc_version?.enc_id
            ? currPatient?.enc_version?.enc_id
            : ''
        }
        appointmentType={appointment_type}
        appointmentTypeStatus={appointmentTypeStatus}
      />
    );
  };

  const renderDayDate = () => {
    return (
      <Grid className="day-date-main-grid">
        <Grid className="month-date-grid">{dateMonthYear}</Grid>
        <Grid className="day-date-grid">{dateDay}</Grid>
        <Grid className="time-grid">
          {timeOfApp.slice(0, -6)} {timeOfAppAMPM}
        </Grid>
      </Grid>
    );
  };

  const renderDoctorDetails = () => {
    return (
      <Grid className="doctor-details-main-grid">
        <Grid className="doctor-name-grid">
          {doctor_details?.salutation} {doctor_details?.full_name}
        </Grid>
        <Grid className="doctor-specialization-grid">{appointment_type}</Grid>
        <Grid className="doctor-hospital-grid">
          {branch_details?.branch_name}
        </Grid>
      </Grid>
    );
  };

  const renderAppointmentStatusDetails = () => {
    return (
      <Grid className="doctor-appointment-status-main-grid">
        <Grid xs={12} className="doctor-appointment-status-name-grid">
          <p className="appointment-status-dot">{renderStatusAndDot()} </p>{' '}
          {appointment_status !== AppointmentStatusTypes.CANCELLED &&
            appointment_status !== AppointmentStatusTypes.CLOSED &&
            appointment_status !== AppointmentStatusTypes.CHECKED_IN &&
            appointment_status !== AppointmentStatusTypes.CONSULTING &&
            appointment_status !== AppointmentStatusTypes.TRIAGE && (
              <p className="appointment-status-three-dot">
                {renderKebabMenu()}
              </p>
            )}
          <Grid>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={(e: any) => handleClose(e)}
              // className="pop-menu"
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.9,
                  width: '21ch'
                }
              }}>
              {appointment_status === AppointmentStatusTypes.BOOKED ||
              appointment_status === AppointmentStatusTypes.CONFIRM ||
              appointment_status === AppointmentStatusTypes.PAID ||
              appointment_status === AppointmentStatusTypes.RESCHEDULED ? (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setcancel(true);
                  }}>
                  {t('TEMPLATE_CANCEL')}
                </MenuItem>
              ) : null}
              {appointment_status === AppointmentStatusTypes.BOOKED ||
              appointment_status === AppointmentStatusTypes.CONFIRM ||
              appointment_status === AppointmentStatusTypes.PAID ? (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleNewAppointmentBookingPopup();
                  }}>
                  {t('RESCHEDULE')}
                </MenuItem>
              ) : null}
              {prescription?.length > 1 ? (
                <MenuItem
                  onClick={e => {
                    handlePdfViewer(e);
                    handleClose();
                  }}>
                  {t('VIEW_PRESCRIPTION')}
                </MenuItem>
              ) : null}
              {/* {appointment_status !== AppointmentStatusTypes.CANCELLED &&
              appointment_status !== AppointmentStatusTypes.CLOSED ? (
                <MenuItem
                  onClick={() => {
                    appointment_status !== AppointmentStatusTypes.PAID
                      ? goToBilling()
                      : setProcessOpen(true);
                    handleClose();
                  }}>
                  {t('PROCESS')}
                </MenuItem>
              ) : null} */}
            </Menu>
          </Grid>
        </Grid>
        <Grid className="doctor-appointment-status-grid">
          {appointmentTypeStatus}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid spacing={3} id={'patient_card_' + index}>
      <Grid
        container
        spacing={0.25}
        alignItems="center"
        className={[
          'patient-card-wrapper doctor-card-wrapper',
          selectedPatient === index && 'border-class'
        ].join(' ')}
        onClick={handlePersonCard}>
        <Grid xs={3} className="render-day-date-grid">
          {renderDayDate()}
        </Grid>
        <Grid xs={5} className="render-doctor-details-main-grid">
          {renderDoctorDetails()}
        </Grid>{' '}
        <Grid xs={4} className="render-appointment-status-main-grid">
          {renderAppointmentStatusDetails()}
        </Grid>
        {click && handlePersonCard()}
      </Grid>

      {processOpen && ProcessModal()}
      {cancel && CancelAppointmentModal()}
      {showNewAppointmentBookingUpPopup && renderNewAppointmentBookingPopup()}
      {showPdfFileDialog && renderPdfViewerDialog()}
    </Grid>
  );
};

export default DoctorCard;
