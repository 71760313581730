import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as CamWhiteIconSvg} from '@assets/images/healpha/CamWhite.svg';

const CamWhiteIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={CamWhiteIconSvg}
      viewBox="0 0 20 14"
    />
  );
};

export default CamWhiteIcon;
