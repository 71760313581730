import * as React from 'react';
import {Snackbar, Alert} from '@material-ui/core';
import {connect} from 'react-redux';

import {_snackbarAcknowledgement} from '../../../../../shared/src/store/actions/CommonActions';

const SnackBar = (props: any): React.ReactElement => {
  const {snackBarEnabled, snackBarMessage, snackBarType} = props;

  const handleClose = (event?: any, reason?: any) => {
    if (reason === 'clickaway') {
      return;
    }
    props._snackbarAcknowledgement(false, '', '');
  };

  return (
    <Snackbar
      open={snackBarEnabled}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      sx={{top: '50% !important'}}
      autoHideDuration={600000}
      onClose={handleClose}>
      <Alert onClose={handleClose} severity={snackBarType}>
        {snackBarMessage}
      </Alert>
    </Snackbar>
  );
};

function mapStateToProps(state: any) {
  return {
    snackBarEnabled: state.common.snackBarEnabled,
    snackBarMessage: state.common.snackBarMessage,
    snackBarType: state.common.snackBarType
  };
}

export default connect(mapStateToProps, {_snackbarAcknowledgement})(SnackBar);
