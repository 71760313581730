import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import { ReactComponent as GetDirectionsIconSvg } from '@assets/images/healpha/get_directions.svg';

const GetDirectionsIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={GetDirectionsIconSvg} viewBox="0 0 24 24" />
  );
};

export default GetDirectionsIcon;