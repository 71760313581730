import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as LanguageIconSvg} from '@assets/images/healpha/Language.svg';

const LanguageIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={LanguageIconSvg}
      viewBox="0 0 18 18"
    />
  );
};

export default LanguageIcon;
