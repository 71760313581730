import Storage from './Storage';

class AuthUtils {
  static initiatePortal(portal: string): void {
    Storage.setItem(Storage.KEYS.PORTAL, portal);
  }

  static resolvePortal(): string | null {
    return Storage.getItem(Storage.KEYS.PORTAL);
  }

  static resetPortal(): void {
    Storage.removeItem(Storage.KEYS.PORTAL);
    Storage.clear();
  }

  static getAuthToken(): any {
    return Storage.getItem(Storage.KEYS.AUTH_TOKEN);
  }

  static setAuthToken(token: any): void {
    Storage.setItem(Storage.KEYS.AUTH_TOKEN, token);
  }

  static clearAuthToken(): void {
    Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
  }

  static getPHPToken(): any {
    return Storage.getItem(Storage.KEYS.PHP_TOKEN);
  }

  static clearPHPToken(): void {
    Storage.removeItem(Storage.KEYS.PHP_TOKEN);
  }

  static setPHPToken(token: any): void {
    this.clearPHPToken();
    Storage.setItem(Storage.KEYS.PHP_TOKEN, token);
  }

  static clearDoctorId(): void {
    Storage.removeItem(Storage.KEYS.NH_ID);
  }

  static getDoctorId(): any {
    return Storage.getItem(Storage.KEYS.NH_ID);
  }

  static setDoctorId(id: any): void {
    this.clearDoctorId();
    Storage.setItem(Storage.KEYS.NH_ID, id);
  }

  static getHealphaId(): any {
    return Storage.getItem(Storage.KEYS.HEALPHA_ID);
  }

  static clearHealphaId(): void {
    Storage.removeItem(Storage.KEYS.HEALPHA_ID);
  }

  static setHealphaId(id: any): void {
    this.clearHealphaId();
    Storage.setItem(Storage.KEYS.HEALPHA_ID, id);
  }

  static getIsPrescription(): any {
    return Storage.getItem(Storage.KEYS.IS_PRESCRIBED);
  }

  static clearIsPrescription(): void {
    Storage.removeItem(Storage.KEYS.IS_PRESCRIBED);
  }

  static setIsPrescription(data: any): void {
    this.clearIsPrescription();
    Storage.setItem(Storage.KEYS.IS_PRESCRIBED, data);
  }

  static clearEncId(): void {
    Storage.removeItem(Storage.KEYS.ENC_ID);
  }

  static setEncId(id: any): void {
    this.clearEncId();
    Storage.setItem(Storage.KEYS.ENC_ID, id);
  }

  static getEncId(): any {
    return Storage.getItem(Storage.KEYS.ENC_ID);
  }

  static clearIsFromTimeline(): void {
    Storage.removeItem(Storage.KEYS.IS_FROM_TIMELINE);
  }

  static setIsFromTimeline(id: any): void {
    this.clearIsFromTimeline();
    Storage.setItem(Storage.KEYS.IS_FROM_TIMELINE, id);
  }

  static getIsFromTimeline(): any {
    return Storage.getItem(Storage.KEYS.IS_FROM_TIMELINE);
  }

  static clearEncIdBilling(): void {
    Storage.removeItem(Storage.KEYS.ENC_ID_BILLING);
  }

  static setEncIdBilling(id: any): void {
    this.clearEncIdBilling();
    Storage.setItem(Storage.KEYS.ENC_ID_BILLING, id);
  }

  static getEncIdBilling(): any {
    return Storage.getItem(Storage.KEYS.ENC_ID_BILLING);
  }

  static clearTemplateAttachedId(): void {
    Storage.removeItem(Storage.KEYS.TEMPLATE_ATTACHED_ID);
  }

  static setTemplateAttachedId(id: any): void {
    this.clearTemplateAttachedId();
    Storage.setItem(Storage.KEYS.TEMPLATE_ATTACHED_ID, id);
  }

  static getTemplateAttachedId(): any {
    return Storage.getItem(Storage.KEYS.TEMPLATE_ATTACHED_ID);
  }

  static clearTemplateNewId(): void {
    Storage.removeItem(Storage.KEYS.TEMPLATE_ID_NEW);
  }

  static setTemplateNewId(id: any): void {
    this.clearTemplateNewId();
    Storage.setItem(Storage.KEYS.TEMPLATE_ID_NEW, id);
  }

  static getTemplateNewId(): any {
    return Storage.getItem(Storage.KEYS.TEMPLATE_ID_NEW);
  }

  static clearPersonDetails(): void {
    Storage.removeItem(Storage.KEYS.PERSON_DETAILS);
  }

  static setPersonDetails(data: any): void {
    this.clearPersonDetails();
    Storage.setItem(Storage.KEYS.PERSON_DETAILS, data);
  }

  static getPersonDetails(): any {
    return Storage.getItem(Storage.KEYS.PERSON_DETAILS, true);
  }
  static clearPersonCorporateDetails(): void {
    Storage.removeItem(Storage.KEYS.PERSON_CORPORATE_DETAILS);
  }

  static setPersonCorporateDetails(data: any): void {
    this.clearPersonDetails();
    Storage.setItem(Storage.KEYS.PERSON_CORPORATE_DETAILS, data);
  }

  static getPersonCorporateDetails(): any {
    return Storage.getItem(Storage.KEYS.PERSON_CORPORATE_DETAILS);
  }

  static clearAppointmentDetails(): void {
    Storage.removeItem(Storage.KEYS.APPOINTMENT_DETAILS);
  }

  static setAppointmentDetails(data: any): void {
    this.clearAppointmentDetails();
    Storage.setItem(Storage.KEYS.APPOINTMENT_DETAILS, data);
  }

  static getAppointmentDetails(): any {
    return Storage.getItem(Storage.KEYS.APPOINTMENT_DETAILS, true);
  }

  static clearBillingAppointmentDetails(): void {
    Storage.removeItem(Storage.KEYS.BILLING_APPOINTMENT_DETAILS);
  }

  static setBillingAppointmentDetails(data: any): void {
    this.clearBillingAppointmentDetails();
    Storage.setItem(Storage.KEYS.BILLING_APPOINTMENT_DETAILS, data);
  }

  static getBillingAppointmentDetails(): any {
    return Storage.getItem(Storage.KEYS.BILLING_APPOINTMENT_DETAILS, true);
  }

  static getPersonName(): any {
    return Storage.getItem(Storage.KEYS.PERSON_NAME);
  }

  static setPersonName(name: string): void {
    this.clearPersonName();
    Storage.setItem(Storage.KEYS.PERSON_NAME, name);
  }

  static clearPersonName(): void {
    Storage.removeItem(Storage.KEYS.PERSON_NAME);
  }

  static getAppointmentPersonName(): any {
    return Storage.getItem(Storage.KEYS.APPOINTMENT_PERSON_NAME);
  }

  static clearAppointmentPersonName(): void {
    Storage.removeItem(Storage.KEYS.APPOINTMENT_PERSON_NAME);
  }

  static setAppointmentPersonName(name: string): void {
    this.clearAppointmentPersonName();
    Storage.setItem(Storage.KEYS.APPOINTMENT_PERSON_NAME, name);
  }

  static getBranchId(): any {
    return Storage.getItem(Storage.KEYS.BRANCH_ID);
  }

  static setBranchId(id: string): void {
    this.clearBranchId();
    Storage.setItem(Storage.KEYS.BRANCH_ID, id);
  }

  static clearBranchId(): void {
    Storage.removeItem(Storage.KEYS.BRANCH_ID);
  }

  static getMenuModuleData(): any {
    return Storage.getItem(Storage.KEYS.MENU_MODULE_DATA, true);
  }

  static clearMenuModuleData(): void {
    Storage.removeItem(Storage.KEYS.MENU_MODULE_DATA);
  }

  static setMenuModuleData(name: any): void {
    this.clearMenuModuleData();
    Storage.setItem(Storage.KEYS.MENU_MODULE_DATA, name);
  }

  static getMenuSectionData(): any {
    return Storage.getItem(Storage.KEYS.MENU_SECTION_DATA);
  }

  static clearMenuSectionData(): void {
    Storage.removeItem(Storage.KEYS.MENU_SECTION_DATA);
  }

  static setMenuSectionData(menuSection: any): void {
    this.clearMenuSectionData();
    Storage.setItem(Storage.KEYS.MENU_SECTION_DATA, menuSection);
  }

  static getMenuBranchId(): any {
    return Storage.getItem(Storage.KEYS.MENU_BRANCH_ID);
  }

  static clearMenuBranchId(): void {
    Storage.removeItem(Storage.KEYS.MENU_BRANCH_ID);
  }

  static setMenuBranchId(branchId: any): void {
    this.clearMenuBranchId();
    Storage.setItem(Storage.KEYS.MENU_BRANCH_ID, branchId);
  }

  static getMenuPracticeId(): any {
    return Storage.getItem(Storage.KEYS.MENU_PRACTICE_ID);
  }

  static clearMenuPracticeId(): void {
    Storage.removeItem(Storage.KEYS.MENU_PRACTICE_ID);
  }

  static setMenuPracticeId(practiceId: any): void {
    this.clearMenuPracticeId();
    Storage.setItem(Storage.KEYS.MENU_PRACTICE_ID, practiceId);
  }

  static getMenuPageUrl(): any {
    return Storage.getItem(Storage.KEYS.MENU_PAGE_URL);
  }

  static clearMenuPageUrl(): void {
    Storage.removeItem(Storage.KEYS.MENU_PAGE_URL);
  }

  static setMenuPageUrl(pageUrl: any): void {
    this.clearMenuPageUrl();
    Storage.setItem(Storage.KEYS.MENU_PAGE_URL, pageUrl);
  }

  static getVirtualClinicId(): any {
    return Storage.getItem(Storage.KEYS.VIRTUAL_CLINIC_ID);
  }

  static clearVirtualClinicId(): void {
    Storage.removeItem(Storage.KEYS.VIRTUAL_CLINIC_ID);
  }

  static setVirtualClinicId(id: any): void {
    this.clearVirtualClinicId();
    Storage.setItem(Storage.KEYS.VIRTUAL_CLINIC_ID, id);
  }

  static getAdminDocId(): any {
    return Storage.getItem(Storage.KEYS.ADMIN_DOC_DASHBOARD_ID);
  }

  static clearAdminDocId(): void {
    Storage.removeItem(Storage.KEYS.ADMIN_DOC_DASHBOARD_ID);
  }

  static setAdminDocId(id: any): void {
    this.clearAdminDocId();
    Storage.setItem(Storage.KEYS.ADMIN_DOC_DASHBOARD_ID, id);
  }

  static getIsAdminNurse(): any {
    return Storage.getItem(Storage.KEYS.ADMIN_NURSE);
  }

  static clearIsAdminNurse(): void {
    Storage.removeItem(Storage.KEYS.ADMIN_NURSE);
  }

  static setIsAdminNurse(id: any): void {
    this.clearIsAdminNurse();
    Storage.setItem(Storage.KEYS.ADMIN_NURSE, id);
  }

  static getIsStandalone(): any {
    return Storage.getItem(Storage.KEYS.IS_STANDALONE);
  }

  static clearIsStandalone(): void {
    Storage.removeItem(Storage.KEYS.IS_STANDALONE);
  }

  static setIsStandalone(isStandalone: any): void {
    this.clearIsStandalone();
    Storage.setItem(Storage.KEYS.IS_STANDALONE, isStandalone);
  }

  static getRoleType(): any {
    return Storage.getItem(Storage.KEYS.IS_ROLETYPE);
  }

  static clearIsRoleType(): void {
    Storage.removeItem(Storage.KEYS.IS_ROLETYPE);
  }

  static setIsRoleType(RoleType: any): void {
    this.clearIsRoleType();
    Storage.setItem(Storage.KEYS.IS_ROLETYPE, RoleType);
  }

  static getRoleTypeForNurseInClinic(): any {
    return Storage.getItem(Storage.KEYS.IS_ROLETYPE_NURSE);
  }

  static clearIsRoleTypeForNurseInClinic(): void {
    Storage.removeItem(Storage.KEYS.IS_ROLETYPE_NURSE);
  }

  static setIsRoleTypeForNurseInClinic(RoleType: any): void {
    this.clearIsRoleTypeForNurseInClinic();
    Storage.setItem(Storage.KEYS.IS_ROLETYPE_NURSE, RoleType);
  }

  static getAppointmentNotify(): any {
    return Storage.getItem(Storage.KEYS.APPOINTMENT_NOTIFY);
  }

  static clearIsAppointmentNotify(): void {
    Storage.removeItem(Storage.KEYS.APPOINTMENT_NOTIFY);
  }

  static setIsAppointmentNotify(Appointment: any): void {
    this.clearIsAppointmentNotify();
    Storage.setItem(Storage.KEYS.APPOINTMENT_NOTIFY, Appointment);
  }

  static clearModuleId(): void {
    Storage.removeItem(Storage.KEYS.MODULE_ID);
  }

  static setModuleId(id: any): void {
    this.clearModuleId();
    Storage.setItem(Storage.KEYS.MODULE_ID, id);
  }

  static getModuleId(): any {
    return Storage.getItem(Storage.KEYS.MODULE_ID);
  }

  static clearNewUserId(): void {
    Storage.removeItem(Storage.KEYS.NEWUSER_ID);
  }

  static setNewUserId(id: any): void {
    this.clearNewUserId();
    Storage.setItem(Storage.KEYS.NEWUSER_ID, id);
  }

  static getNewUserId(): any {
    return Storage.getItem(Storage.KEYS.NEWUSER_ID);
  }

  static clearRoleId(): void {
    Storage.removeItem(Storage.KEYS.ROLE_ID);
  }

  static setRoleId(id: any): void {
    this.clearRoleId();
    Storage.setItem(Storage.KEYS.ROLE_ID, id);
  }

  static getRoleId(): any {
    return Storage.getItem(Storage.KEYS.ROLE_ID);
  }

  static clearRoleName(): void {
    Storage.removeItem(Storage.KEYS.ROLE_NAME);
  }

  static setRoleName(name: any): void {
    this.clearRoleName();
    Storage.setItem(Storage.KEYS.ROLE_NAME, name);
  }

  static getRoleName(): any {
    return Storage.getItem(Storage.KEYS.ROLE_NAME);
  }

  static clearRoleModuleId(): void {
    Storage.removeItem(Storage.KEYS.ROLE_Module_id);
  }

  static setRoleModuleId(mids: any): void {
    this.clearRoleModuleId();
    Storage.setItem(Storage.KEYS.ROLE_Module_id, mids);
  }

  static getRoleModuleId(): any {
    return Storage.getItem(Storage.KEYS.ROLE_Module_id);
  }
  static clearRoleDescription(): void {
    Storage.removeItem(Storage.KEYS.ROLE_DESCRIPTION);
  }

  static setRoleDescription(description: any): void {
    this.clearRoleDescription();
    Storage.setItem(Storage.KEYS.ROLE_DESCRIPTION, description);
  }

  static getRoleDescription(): any {
    return Storage.getItem(Storage.KEYS.ROLE_DESCRIPTION);
  }

  static clearRoleHeading(): void {
    Storage.removeItem(Storage.KEYS.ROLE_HEADING);
  }

  static setRoleHeading(heading: any): void {
    this.clearRoleDescription();
    Storage.setItem(Storage.KEYS.ROLE_HEADING, heading);
  }

  static getRoleHeading(): any {
    return Storage.getItem(Storage.KEYS.ROLE_HEADING);
  }
  static getHealphaIdForPersonWeb(): any {
    return Storage.getItem(Storage.KEYS.HEALPHA_ID_FOR_PERSON_WEB);
  }

  static clearHealphaIdForPersonWeb(): void {
    Storage.removeItem(Storage.KEYS.HEALPHA_ID_FOR_PERSON_WEB);
  }

  static setHealphaIdForPersonWeb(id: any): void {
    this.clearHealphaIdForPersonWeb();
    Storage.setItem(Storage.KEYS.HEALPHA_ID_FOR_PERSON_WEB, id);
  }

  static getMenuBranchName(): any {
    return Storage.getItem(Storage.KEYS.BRANCH_NAME);
  }

  static setMenuBranchName(name: string): void {
    this.clearMenuBranchName();
    Storage.setItem(Storage.KEYS.BRANCH_NAME, name);
  }

  static clearMenuBranchName(): void {
    Storage.removeItem(Storage.KEYS.BRANCH_NAME);
  }

  static getIsFromVirtualClinic(): any {
    return Storage.getItem(Storage.KEYS.IS_FROM_VIRTUAL_CLINIC);
  }

  static clearIsFromVirtualClinic(): void {
    Storage.removeItem(Storage.KEYS.IS_FROM_VIRTUAL_CLINIC);
  }

  static setIsFromVirtualClinic(id: any): void {
    this.clearIsFromVirtualClinic();
    Storage.setItem(Storage.KEYS.IS_FROM_VIRTUAL_CLINIC, id);
  }

  static getFromPersonWeb(): any {
    return Storage.getItem(Storage.KEYS.FROM_PERSON_WEB);
  }

  static clearFromPersonWeb(): void {
    Storage.removeItem(Storage.KEYS.FROM_PERSON_WEB);
  }

  static setFromPersonWeb(id: any): void {
    this.clearFromPersonWeb();
    Storage.setItem(Storage.KEYS.FROM_PERSON_WEB, id);
  }

  static clearDetail(): void {
    Storage.removeItem(Storage.KEYS.ROLE_DETAIL);
  }

  static setDetail(detail: any): void {
    this.clearDetail();
    Storage.setItem(Storage.KEYS.ROLE_DETAIL, detail);
  }

  static getDetail(): any {
    return Storage.getItem(Storage.KEYS.ROLE_DETAIL);
  }

  static clearMid(): void {
    Storage.removeItem(Storage.KEYS.MID);
  }

  static setMid(mid: any): void {
    this.clearDetail();
    Storage.setItem(Storage.KEYS.MID, mid);
  }

  static getMid(): any {
    return Storage.getItem(Storage.KEYS.MID);
  }

  static async clearPracticeId(): Promise<any> {
    Storage.removeItem(Storage.KEYS.SELF_SERVICE_PRACTICE_ID);
  }

  static setPracticeId(practiceId: any): void {
    this.clearPracticeId().then(() => {
      Storage.setItem(Storage.KEYS.SELF_SERVICE_PRACTICE_ID, practiceId);
    });
  }

  static async setNewPracticeId(practiceId: any): Promise<any> {
    await this.clearPracticeId();
    await Storage.setItem(Storage.KEYS.SELF_SERVICE_PRACTICE_ID, practiceId);
  }

  static getPracticeId(): any {
    return Storage.getItem(Storage.KEYS.SELF_SERVICE_PRACTICE_ID);
  }

  static clearNewPracticeId(): void {
    Storage.removeItem(Storage.KEYS.SELF_SERVICE_NEW_PRACTICE_ID);
  }

  static getNewPracticeId(): any {
    return Storage.getItem(Storage.KEYS.SELF_SERVICE_NEW_PRACTICE_ID);
  }

  static clearMenuId(): void {
    Storage.removeItem(Storage.KEYS.MENU_ID);
  }

  static setMenuId(menuId: any): void {
    this.clearMenuId();
    Storage.setItem(Storage.KEYS.MENU_ID, menuId);
  }

  static getMenuId(): any {
    return Storage.getItem(Storage.KEYS.MENU_ID);
  }

  static clearNonHealphaUserId(): void {
    Storage.removeItem(Storage.KEYS.NON_HEALPHA_USER_ID);
  }

  static setNonHealphaUserId(nonHealphaUserId: any): void {
    this.clearNonHealphaUserId();
    Storage.setItem(Storage.KEYS.NON_HEALPHA_USER_ID, nonHealphaUserId);
  }

  static getNonHealphaUserId(): any {
    return Storage.getItem(Storage.KEYS.NON_HEALPHA_USER_ID);
  }

  static clearAccessNonHealphaUserId(): void {
    Storage.removeItem(Storage.KEYS.NON_HEALPHA_ACCESS_USER_ID);
  }

  static setAccessNonHealphaUserId(nonHealphaAccessUserId: any): void {
    this.clearAccessNonHealphaUserId();
    Storage.setItem(
      Storage.KEYS.NON_HEALPHA_ACCESS_USER_ID,
      nonHealphaAccessUserId
    );
  }

  static getAccessNonHealphaUserId(): any {
    return Storage.getItem(Storage.KEYS.NON_HEALPHA_ACCESS_USER_ID);
  }

  static clearIsModuleFlag(): void {
    Storage.removeItem(Storage.KEYS.IS_MODULE_SET_UP);
  }

  static setIsModuleFlag(isModuleSetUP: any): void {
    this.clearIsModuleFlag();
    Storage.setItem(Storage.KEYS.IS_MODULE_SET_UP, isModuleSetUP);
  }

  static getIsModuleFlag(): any {
    return Storage.getItem(Storage.KEYS.IS_MODULE_SET_UP);
  }

  static clearIsSelfService(): void {
    Storage.removeItem(Storage.KEYS.IS_SELF_SERVICE);
  }

  static setIsSelfService(isSelfService: any): void {
    this.clearIsSelfService();
    Storage.setItem(Storage.KEYS.IS_SELF_SERVICE, isSelfService);
  }

  static getIsSelfService(): any {
    return Storage.getItem(Storage.KEYS.IS_SELF_SERVICE);
  }

  static clearNonHealphaUserDoctorFlag(): void {
    Storage.removeItem(Storage.KEYS.NON_HEALPHA_USER_DOCTOR_FLAG);
  }

  static setNonHealphaUserDoctorFlag(nonHealphaUserDoctorFlag: any): void {
    this.clearNonHealphaUserDoctorFlag();
    Storage.setItem(
      Storage.KEYS.NON_HEALPHA_USER_DOCTOR_FLAG,
      nonHealphaUserDoctorFlag
    );
  }

  static getNonHealphaUserDoctorFlag(): any {
    return Storage.getItem(Storage.KEYS.NON_HEALPHA_USER_DOCTOR_FLAG);
  }

  static clearSelfServiceBranchId(): void {
    Storage.removeItem(Storage.KEYS.SELF_SERVICE_Branch_ID);
  }

  static setSelfServiceBranchId(selfServiceBranchId: any): void {
    this.clearSelfServiceBranchId();
    Storage.setItem(Storage.KEYS.SELF_SERVICE_Branch_ID, selfServiceBranchId);
  }

  static getSelfServiceBranchId(): any {
    return Storage.getItem(Storage.KEYS.SELF_SERVICE_Branch_ID);
  }

  static clearDoctorSlotTime(): void {
    Storage.removeItem(Storage.KEYS.DOCTOR_SLOT_TIME);
  }

  static setDoctorSlotTime(doctorSlotTime: any): void {
    this.clearDoctorSlotTime();
    Storage.setItem(Storage.KEYS.DOCTOR_SLOT_TIME, doctorSlotTime);
  }

  static getDoctorSlotTime(): any {
    return Storage.getItem(Storage.KEYS.DOCTOR_SLOT_TIME);
  }

  static clearDoctorType(): void {
    Storage.removeItem(Storage.KEYS.DOCTOR_TYPE);
  }

  static setDoctorType(doctorType: any): void {
    this.clearDoctorType();
    Storage.setItem(Storage.KEYS.DOCTOR_TYPE, doctorType);
  }

  static getDoctorType(): any {
    return Storage.getItem(Storage.KEYS.DOCTOR_TYPE);
  }

  static clearDoctorSpecializations(): void {
    Storage.removeItem(Storage.KEYS.DOCTOR_SPECIALIZATION);
  }

  static setDoctorSpecializations(doctorSpecialization: any): void {
    this.clearDoctorSpecializations();
    Storage.setItem(Storage.KEYS.DOCTOR_SPECIALIZATION, doctorSpecialization);
  }

  static getDoctorSpecializations(): any {
    return Storage.getItem(Storage.KEYS.DOCTOR_SPECIALIZATION);
  }
  static clearPracticeAdminName(): void {
    Storage.removeItem(Storage.KEYS.PRACTICE_ADMIN_NAME);
  }

  static setPracticeAdminName(practiceAdminName: any): void {
    this.clearPracticeAdminName();
    Storage.setItem(Storage.KEYS.PRACTICE_ADMIN_NAME, practiceAdminName);
  }

  static getPracticeAdminName(): any {
    return Storage.getItem(Storage.KEYS.PRACTICE_ADMIN_NAME);
  }

  static clearPracticeAdminEmail(): void {
    Storage.removeItem(Storage.KEYS.PRACTICE_ADMIN_EMAIL);
  }

  static setPracticeAdminEmail(practiceAdminEmail: any): void {
    this.clearPracticeAdminEmail();
    Storage.setItem(Storage.KEYS.PRACTICE_ADMIN_EMAIL, practiceAdminEmail);
  }

  static getPracticeAdminEmail(): any {
    return Storage.getItem(Storage.KEYS.PRACTICE_ADMIN_EMAIL);
  }

  static clearBranchAdminName(): void {
    Storage.removeItem(Storage.KEYS.BRANCH_ADMIN_NAME);
  }

  static setBranchAdminName(branchAdminName: any): void {
    this.clearBranchAdminName();
    Storage.setItem(Storage.KEYS.BRANCH_ADMIN_NAME, branchAdminName);
  }

  static getBranchAdminName(): any {
    return Storage.getItem(Storage.KEYS.BRANCH_ADMIN_NAME);
  }

  static clearBranchAdminEmail(): void {
    Storage.removeItem(Storage.KEYS.BRANCH_ADMIN_EMAIL);
  }

  static setBranchAdminEmail(branchAdminEmail: any): void {
    this.clearBranchAdminEmail();
    Storage.setItem(Storage.KEYS.BRANCH_ADMIN_EMAIL, branchAdminEmail);
  }

  static getBranchAdminEmail(): any {
    return Storage.getItem(Storage.KEYS.BRANCH_ADMIN_EMAIL);
  }

  static clearWorkFlowInfo(): void {
    Storage.removeItem(Storage.KEYS.WORK_FLOW_INFO);
  }

  static setWorkFlowInfo(info: any): void {
    this.clearWorkFlowInfo();
    Storage.setItem(Storage.KEYS.WORK_FLOW_INFO, info);
  }

  static getWorkFlowInfo(): any {
    return Storage.getItem(Storage.KEYS.WORK_FLOW_INFO);
  }

  static clearBranchesWorkFlowInfo(): void {
    Storage.removeItem(Storage.KEYS.BRANCH_WORK_FLOW_INFO);
  }

  static setBranchesWorkFlowInfo(info: any): void {
    this.clearBranchesWorkFlowInfo();
    Storage.setItem(Storage.KEYS.BRANCH_WORK_FLOW_INFO, info);
  }

  static getBranchesWorkFlowInfo(): any {
    return Storage.getItem(Storage.KEYS.BRANCH_WORK_FLOW_INFO);
  }

  static clearModuleSetupType(): void {
    Storage.removeItem(Storage.KEYS.MODULE_SETUP_TYPE);
  }

  static setModuleSetupType(moduleSetupType: any): void {
    this.clearModuleSetupType();
    Storage.setItem(Storage.KEYS.MODULE_SETUP_TYPE, moduleSetupType);
  }

  static getModuleSetupType(): any {
    return Storage.getItem(Storage.KEYS.MODULE_SETUP_TYPE);
  }

  static clearCorporateModuleBranchId(): void {
    Storage.removeItem(Storage.KEYS.CORPORATE_MODULE_BRANCHID);
  }

  static setCorporateModuleBranchId(moduleBranchid: any): void {
    this.clearCorporateModuleBranchId();
    Storage.setItem(Storage.KEYS.CORPORATE_MODULE_BRANCHID, moduleBranchid);
  }

  static getCorporateModuleBranchId(): any {
    return Storage.getItem(Storage.KEYS.CORPORATE_MODULE_BRANCHID);
  }

  static clearModuleBranchId(): void {
    Storage.removeItem(Storage.KEYS.MODULE_BRANCHID);
  }

  static setModuleBranchId(moduleBranchid: any): void {
    this.clearModuleBranchId();
    Storage.setItem(Storage.KEYS.MODULE_BRANCHID, moduleBranchid);
  }

  static getModuleBranchId(): any {
    return Storage.getItem(Storage.KEYS.MODULE_BRANCHID);
  }

  static clearEnabling(): void {
    Storage.removeItem(Storage.KEYS.ENABLING);
  }

  static setEnabling(enabling: any): void {
    this.clearEnabling();
    Storage.setItem(Storage.KEYS.ENABLING, enabling);
  }
  static getEnabling(): any {
    return Storage.getItem(Storage.KEYS.ENABLING);
  }

  static clearCorporateEnabling(): void {
    Storage.removeItem(Storage.KEYS.CORPORATE_ENABLING);
  }

  static setCorporateEnabling(Corporateenabling: any): void {
    this.clearCorporateEnabling();
    Storage.setItem(Storage.KEYS.CORPORATE_ENABLING, Corporateenabling);
  }
  static getCorporateEnabling(): any {
    return Storage.getItem(Storage.KEYS.CORPORATE_ENABLING);
  }

  static clearOpEnabling(): void {
    Storage.removeItem(Storage.KEYS.OP_ENABLING);
  }
  static setOpEnabling(Openabling: any): void {
    this.clearOpEnabling();
    Storage.setItem(Storage.KEYS.OP_ENABLING, Openabling);
  }
  static getOpEnabling(): any {
    return Storage.getItem(Storage.KEYS.OP_ENABLING);
  }

  static getEnabling2(): any {
    return Storage.getItem(Storage.KEYS.ENABLING2);
  }
  static clearEnabling2(): void {
    Storage.removeItem(Storage.KEYS.ENABLING2);
  }

  static setEnabling2(enabling2: any): void {
    this.clearEnabling2();
    Storage.setItem(Storage.KEYS.ENABLING2, enabling2);
  }
  static getCorporateEnabling2(): any {
    return Storage.getItem(Storage.KEYS.CORPORATE_ENABLING2);
  }
  static clearCorporateEnabling2(): void {
    Storage.removeItem(Storage.KEYS.CORPORATE_ENABLING2);
  }

  static setCorporateEnabling2(Corporateenabling2: any): void {
    this.clearCorporateEnabling2();
    Storage.setItem(Storage.KEYS.CORPORATE_ENABLING2, Corporateenabling2);
  }

  static clearOpEnabling2(): void {
    Storage.removeItem(Storage.KEYS.OP_ENABLING2);
  }
  static setOpEnabling2(Openabling2: any): void {
    this.clearOpEnabling2();
    Storage.setItem(Storage.KEYS.OP_ENABLING2, Openabling2);
  }
  static getOpEnabling2(): any {
    return Storage.getItem(Storage.KEYS.OP_ENABLING2);
  }

  static getEnabling3(): any {
    return Storage.getItem(Storage.KEYS.ENABLING3);
  }
  static clearEnabling3(): void {
    Storage.removeItem(Storage.KEYS.ENABLING3);
  }
  static setEnabling3(enabling3: any): void {
    this.clearEnabling3();
    Storage.setItem(Storage.KEYS.ENABLING3, enabling3);
  }

  static getCorporateEnabling3(): any {
    return Storage.getItem(Storage.KEYS.CORPORATE_ENABLING3);
  }
  static clearCorporateEnabling3(): void {
    Storage.removeItem(Storage.KEYS.CORPORATE_ENABLING3);
  }
  static setCorporateEnabling3(corporateEnabling3: any): void {
    this.clearCorporateEnabling3();
    Storage.setItem(Storage.KEYS.CORPORATE_ENABLING3, corporateEnabling3);
  }

  static clearOpEnabling3(): void {
    Storage.removeItem(Storage.KEYS.OP_ENABLING3);
  }
  static setOpEnabling3(Openabling3: any): void {
    this.clearOpEnabling3();
    Storage.setItem(Storage.KEYS.OP_ENABLING3, Openabling3);
  }
  static getOpEnabling3(): any {
    return Storage.getItem(Storage.KEYS.OP_ENABLING3);
  }

  static getOpEnabling4(): any {
    return Storage.getItem(Storage.KEYS.ENABLING4);
  }
  static clearOpEnabling4(): void {
    Storage.removeItem(Storage.KEYS.ENABLING4);
  }
  static setOpEnabling4(enabling4: any): void {
    this.clearOpEnabling4();
    Storage.setItem(Storage.KEYS.ENABLING4, enabling4);
  }
  static getProfileInfoStatus(): any {
    return this.getFinalStatus('', 'profile', 'profile');
  }

  static getDoctorStatus(): any {
    return this.getFinalStatus('', 'doctor', 'doctor');
  }

  static getBankPracticeStatus(): any {
    return this.getFinalStatus('', 'practice', 'practice_bank');
  }
  static getBankBranchStatus(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'branch_bank');
  }
  static getSchoolParams(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'school_parameters');
  }
  static getSchoolUsers(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'users_details');
  }
  static getSchoolClasses(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'add_classes_details');
  }
  static getSchoolStudents(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'add_students_details');
  }
  static getOpParams(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'parameters');
  }
  static getOpUsers(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'users');
  }
  static getServiceMasterGroup(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'servicegroup');
  }
  static getServiceMaster(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'servicemaster');
  }
  static getCorporateParams(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'corporate_parameters');
  }
  static getCorporateUsers(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'corporate_user');
  }
  static getCorporateDepartment(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'add_corporate_department');
  }
  static getCorporateEmployee(branchId: any): any {
    return this.getFinalStatus(branchId, 'branch', 'add_employee_details');
  }
  static getFinalStatus(
    branchId: string,
    type: string,
    finalStep: string
  ): any {
    if (this.getWorkFlowInfo() == '') return false;
    if (this.getBranchesWorkFlowInfo()) {
      if (type == 'branch') {
        const data: any = JSON.parse(this.getBranchesWorkFlowInfo());
        return data[branchId]
          ? data[branchId].find((o: any) => o?.name == finalStep)?.status ==
              'pending'
          : false;
      }
    } else {
      const flowData = JSON.parse(this.getWorkFlowInfo());
      const workFlowData: any = flowData.data ? flowData.data : flowData;

      return (
        workFlowData.find((o: any) => o?.name == finalStep)?.status == 'pending'
      );
    }
  }

  static clearPracticeModules(): void {
    Storage.removeItem(Storage.KEYS.PRACTICE_MODULES_FEATURE);
  }

  static setPracticeModules(PracticeModules: any): void {
    this.clearPracticeModules();
    Storage.setItem(Storage.KEYS.PRACTICE_MODULES_FEATURE, PracticeModules);
  }

  static getPracticeModules(): any {
    return Storage.getItem(Storage.KEYS.PRACTICE_MODULES_FEATURE);
  }

  static clearDoctorRole(): void {
    Storage.removeItem(Storage.KEYS.DOCTOR_ROLE);
  }

  static setDoctorRole(role: any): void {
    this.clearDoctorRole();
    Storage.setItem(Storage.KEYS.DOCTOR_ROLE, role);
  }

  static getDoctorRole(): any {
    return Storage.getItem(Storage.KEYS.DOCTOR_ROLE);
  }

  static clearRegCompleted(): void {
    Storage.removeItem(Storage.KEYS.REG_COMPLETED);
  }

  static setRegCompleted(reg: any): void {
    this.clearRegCompleted();
    Storage.setItem(Storage.KEYS.REG_COMPLETED, reg);
  }

  static getRegCompleted(): any {
    return Storage.getItem(Storage.KEYS.REG_COMPLETED);
  }

  static clearRegCompleted2(): void {
    Storage.removeItem(Storage.KEYS.REG_COMPLETED2);
  }

  static setRegCompleted2(reg2: any): void {
    this.clearRegCompleted2();
    Storage.setItem(Storage.KEYS.REG_COMPLETED2, reg2);
  }

  static getRegCompleted2(): any {
    return Storage.getItem(Storage.KEYS.REG_COMPLETED2);
  }

  static clearRegCompleted3(): void {
    Storage.removeItem(Storage.KEYS.REG_COMPLETED3);
  }

  static setRegCompleted3(reg3: any): void {
    this.clearRegCompleted3();
    Storage.setItem(Storage.KEYS.REG_COMPLETED3, reg3);
  }

  static getRegCompleted3(): any {
    return Storage.getItem(Storage.KEYS.REG_COMPLETED3);
  }

  static clearRegCompleted4(): void {
    Storage.removeItem(Storage.KEYS.REG_COMPLETED4);
  }

  static setRegCompleted4(reg4: any): void {
    this.clearRegCompleted4();
    Storage.setItem(Storage.KEYS.REG_COMPLETED4, reg4);
  }

  static getRegCompleted4(): any {
    return Storage.getItem(Storage.KEYS.REG_COMPLETED4);
  }

  static clearHealphaAdmin(): void {
    Storage.removeItem(Storage.KEYS.HEALPHA_ADMIN);
  }

  static setHealphaAdmin(data: any): void {
    this.clearHealphaAdmin();
    Storage.setItem(Storage.KEYS.HEALPHA_ADMIN, data);
  }

  static getHealphaAdmin(): any {
    return Storage.getItem(Storage.KEYS.HEALPHA_ADMIN);
  }

  static clearVirtual(): void {
    Storage.removeItem(Storage.KEYS.VIRTUAL_BOOL);
  }

  static setVirtual(data: any): void {
    this.clearHealphaAdmin();
    Storage.setItem(Storage.KEYS.VIRTUAL_BOOL, data);
  }

  static getVirtual(): any {
    return Storage.getItem(Storage.KEYS.VIRTUAL_BOOL);
  }

  static clearHealphaDoctor(): void {
    Storage.removeItem(Storage.KEYS.HEALPHA_DOCTOR);
  }

  static setHealphaDoctor(data: any): void {
    this.clearHealphaDoctor();
    Storage.setItem(Storage.KEYS.HEALPHA_DOCTOR, data);
  }

  static getHealphaDoctor(): any {
    return Storage.getItem(Storage.KEYS.HEALPHA_DOCTOR);
  }

  static clearEditUserFlag(): void {
    Storage.removeItem(Storage.KEYS.EDIT_USER_FLAG);
  }

  static setEditUserFlag(data: any): void {
    this.clearEditUserFlag();
    Storage.setItem(Storage.KEYS.EDIT_USER_FLAG, data);
  }

  static getEditUserFlag(): any {
    return Storage.getItem(Storage.KEYS.EDIT_USER_FLAG);
  }

  static clearCloudClinicFlag(): void {
    Storage.removeItem(Storage.KEYS.CLOUD_CLINIC_FLAG);
  }

  static setCloudClinicFlag(data: any): void {
    this.clearCloudClinicFlag();
    Storage.setItem(Storage.KEYS.CLOUD_CLINIC_FLAG, data);
  }

  static getCloudClinicFlag(): any {
    return Storage.getItem(Storage.KEYS.CLOUD_CLINIC_FLAG);
  }

  static clearEditProfileFlag(): void {
    Storage.removeItem(Storage.KEYS.EDIT_PROFILE_FLAG);
  }

  static setEditProfileFlag(data: any): void {
    this.clearEditProfileFlag();
    Storage.setItem(Storage.KEYS.EDIT_PROFILE_FLAG, data);
  }

  static getEditProfileFlag(): any {
    return Storage.getItem(Storage.KEYS.EDIT_PROFILE_FLAG);
  }

  static clearManageBankFlag(): void {
    Storage.removeItem(Storage.KEYS.MANAGE_BANK_FLAG);
  }

  static setManageBankFlag(data: any): void {
    this.clearManageBankFlag();
    Storage.setItem(Storage.KEYS.MANAGE_BANK_FLAG, data);
  }

  static getManageBankFlag(): any {
    return Storage.getItem(Storage.KEYS.MANAGE_BANK_FLAG);
  }

  static getStatus(): any {
    return Storage.getItem(Storage.KEYS.SELF_SERVICE_STATUS);
  }

  static getLabel(): any {
    const status = Storage.getItem(Storage.KEYS.SELF_SERVICE_STATUS);
    return status == 'all'
      ? 'Practice'
      : status == 'op'
      ? 'Clinic'
      : status == 'school'
      ? 'School'
      : status == 'corporate'
      ? 'Company'
      : status == 'virtual_clinic'
      ? 'Virtual Clinic'
      : status == 'lab'
      ? 'Lab'
      : status == 'pharmacy'
      ? 'Pharmacy'
      : 'null';
  }

  static clearStatus(): void {
    Storage.removeItem(Storage.KEYS.SELF_SERVICE_STATUS);
  }

  static setStatus(status: any): void {
    this.clearStatus();
    Storage.setItem(Storage.KEYS.SELF_SERVICE_STATUS, status);
  }

  static getOperationalFlag(): any {
    return Storage.getItem(Storage.KEYS.OPERATIONAL_FLAG);
  }

  static clearOperationalFlag(): void {
    Storage.removeItem(Storage.KEYS.OPERATIONAL_FLAG);
  }

  static setOperationalFlag(flag: any): void {
    this.clearOperationalFlag();
    Storage.setItem(Storage.KEYS.OPERATIONAL_FLAG, flag);
  }

  static getTechnicalFlag(): any {
    return Storage.getItem(Storage.KEYS.TECHNICAL_FLAG);
  }

  static clearTechnicalFlag(): void {
    Storage.removeItem(Storage.KEYS.TECHNICAL_FLAG);
  }

  static setTechnicalFlag(technicalFlag: any): void {
    this.clearTechnicalFlag();
    Storage.setItem(Storage.KEYS.TECHNICAL_FLAG, technicalFlag);
  }

  static getIsOperationalBranch(): any {
    return Storage.getItem(Storage.KEYS.IS_OPERATIONAL_BRANCH);
  }

  static clearIsOperationalBranch(): void {
    Storage.removeItem(Storage.KEYS.IS_OPERATIONAL_BRANCH);
  }

  static setIsOperationalBranch(IsOperationalBranch: any): void {
    this.clearIsOperationalBranch();
    Storage.setItem(Storage.KEYS.IS_OPERATIONAL_BRANCH, IsOperationalBranch);
  }

  static getPersonCorporate(): any {
    return Storage.getItem(Storage.KEYS.IS_PERSON_CORPORATE);
  }

  static clearPersonCorporate(): void {
    Storage.removeItem(Storage.KEYS.IS_PERSON_CORPORATE);
  }

  static setPersonCorporate(personCorporate: any): void {
    this.clearPersonCorporate();
    Storage.setItem(Storage.KEYS.IS_PERSON_CORPORATE, personCorporate);
  }

  static getModuleDetails(): any {
    return Storage.getItem(Storage.KEYS.MODULE_DETAILS);
  }

  static clearModuleDetails(): void {
    Storage.removeItem(Storage.KEYS.MODULE_DETAILS);
  }

  static setModuleDetails(module: any): void {
    this.clearModuleDetails();
    Storage.setItem(Storage.KEYS.MODULE_DETAILS, module);
  }

  static getModId(): any {
    return Storage.getItem(Storage.KEYS.SELF_SERVICE_MODULE_ID_);
  }

  static clearModId(): void {
    Storage.removeItem(Storage.KEYS.SELF_SERVICE_MODULE_ID_);
  }

  static setModId(moduleId: any): void {
    this.clearModId();
    Storage.setItem(Storage.KEYS.SELF_SERVICE_MODULE_ID_, moduleId);
  }
}
export default AuthUtils;
