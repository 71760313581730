/* eslint-disable camelcase */
import {GenderTypes} from 'src/constants/FormFields';

export const GirlHeight: any = {
  '0.0': {
    FIELD1: 46.1,
    FIELD2: 46.8,
    FIELD3: 47.9,
    FIELD4: 49.1,
    FIELD5: 50.4,
    FIELD6: 51.5,
    FIELD7: 52.2
  },
  0.1: {
    FIELD1: 50.5,
    FIELD2: 51.2,
    FIELD3: 52.4,
    FIELD4: 53.7,
    FIELD5: 55,
    FIELD6: 56.2,
    FIELD7: 56.9
  },
  0.2: {
    FIELD1: 53.7,
    FIELD2: 54.5,
    FIELD3: 55.7,
    FIELD4: 57.1,
    FIELD5: 58.4,
    FIELD6: 59.7,
    FIELD7: 60.4
  },
  0.3: {
    FIELD1: 56.3,
    FIELD2: 57.1,
    FIELD3: 58.4,
    FIELD4: 59.8,
    FIELD5: 61.2,
    FIELD6: 62.5,
    FIELD7: 63.3
  },
  0.4: {
    FIELD1: 58.5,
    FIELD2: 59.3,
    FIELD3: 60.6,
    FIELD4: 62.1,
    FIELD5: 63.5,
    FIELD6: 64.9,
    FIELD7: 65.7
  },
  0.5: {
    FIELD1: 60.4,
    FIELD2: 61.2,
    FIELD3: 62.5,
    FIELD4: 64,
    FIELD5: 65.5,
    FIELD6: 66.9,
    FIELD7: 67.7
  },
  0.6: {
    FIELD1: 62,
    FIELD2: 62.8,
    FIELD3: 64.2,
    FIELD4: 65.7,
    FIELD5: 67.3,
    FIELD6: 68.6,
    FIELD7: 69.5
  },
  0.7: {
    FIELD1: 63.5,
    FIELD2: 64.3,
    FIELD3: 65.7,
    FIELD4: 67.3,
    FIELD5: 68.8,
    FIELD6: 70.3,
    FIELD7: 71.1
  },
  0.8: {
    FIELD1: 64.9,
    FIELD2: 65.7,
    FIELD3: 67.2,
    FIELD4: 68.7,
    FIELD5: 70.3,
    FIELD6: 71.8,
    FIELD7: 72.6
  },
  0.9: {
    FIELD1: 66.2,
    FIELD2: 67,
    FIELD3: 68.5,
    FIELD4: 70.1,
    FIELD5: 71.8,
    FIELD6: 73.2,
    FIELD7: 74.1
  },
  '0.10': {
    FIELD1: 67.4,
    FIELD2: 68.3,
    FIELD3: 69.8,
    FIELD4: 71.5,
    FIELD5: 73.1,
    FIELD6: 74.6,
    FIELD7: 75.5
  },
  0.11: {
    FIELD1: 68.6,
    FIELD2: 69.5,
    FIELD3: 71.1,
    FIELD4: 72.8,
    FIELD5: 74.5,
    FIELD6: 76,
    FIELD7: 76.9
  },
  '1.0': {
    FIELD1: 69.8,
    FIELD2: 70.7,
    FIELD3: 72.3,
    FIELD4: 74,
    FIELD5: 75.8,
    FIELD6: 77.3,
    FIELD7: 78.3
  },
  1.1: {
    FIELD1: 70.9,
    FIELD2: 71.8,
    FIELD3: 73.4,
    FIELD4: 75.2,
    FIELD5: 77,
    FIELD6: 78.6,
    FIELD7: 79.5
  },
  1.2: {
    FIELD1: 72,
    FIELD2: 72.9,
    FIELD3: 74.6,
    FIELD4: 76.4,
    FIELD5: 78.2,
    FIELD6: 79.8,
    FIELD7: 80.8
  },
  1.3: {
    FIELD1: 73,
    FIELD2: 74,
    FIELD3: 75.7,
    FIELD4: 77.5,
    FIELD5: 79.4,
    FIELD6: 81,
    FIELD7: 82
  },
  1.4: {
    FIELD1: 74,
    FIELD2: 75,
    FIELD3: 76.7,
    FIELD4: 78.6,
    FIELD5: 80.5,
    FIELD6: 82.2,
    FIELD7: 83.2
  },
  1.5: {
    FIELD1: 75,
    FIELD2: 76,
    FIELD3: 77.7,
    FIELD4: 79.7,
    FIELD5: 81.6,
    FIELD6: 83.3,
    FIELD7: 84.4
  },
  1.6: {
    FIELD1: 75.9,
    FIELD2: 77,
    FIELD3: 78.7,
    FIELD4: 80.7,
    FIELD5: 82.7,
    FIELD6: 84.4,
    FIELD7: 85.5
  },
  1.7: {
    FIELD1: 76.9,
    FIELD2: 77.9,
    FIELD3: 79.7,
    FIELD4: 81.7,
    FIELD5: 83.7,
    FIELD6: 85.5,
    FIELD7: 86.6
  },
  1.8: {
    FIELD1: 77.7,
    FIELD2: 78.8,
    FIELD3: 80.7,
    FIELD4: 82.7,
    FIELD5: 84.7,
    FIELD6: 86.6,
    FIELD7: 87.7
  },
  1.9: {
    FIELD1: 78.6,
    FIELD2: 79.7,
    FIELD3: 81.6,
    FIELD4: 83.7,
    FIELD5: 85.7,
    FIELD6: 87.6,
    FIELD7: 88.7
  },
  '1.10': {
    FIELD1: 79.5,
    FIELD2: 80.6,
    FIELD3: 82.5,
    FIELD4: 84.6,
    FIELD5: 86.7,
    FIELD6: 88.6,
    FIELD7: 89.7
  },
  1.11: {
    FIELD1: 80.3,
    FIELD2: 81.5,
    FIELD3: 83.4,
    FIELD4: 85.5,
    FIELD5: 87.7,
    FIELD6: 89.6,
    FIELD7: 90.7
  },
  '2.0': {
    FIELD1: 80.4,
    FIELD2: 81.6,
    FIELD3: 83.5,
    FIELD4: 85.7,
    FIELD5: 87.9,
    FIELD6: 89.9,
    FIELD7: 91
  },
  2.1: {
    FIELD1: 81.2,
    FIELD2: 82.4,
    FIELD3: 84.4,
    FIELD4: 86.6,
    FIELD5: 88.8,
    FIELD6: 90.8,
    FIELD7: 92
  },
  2.2: {
    FIELD1: 82,
    FIELD2: 83.2,
    FIELD3: 85.2,
    FIELD4: 87.4,
    FIELD5: 89.7,
    FIELD6: 91.7,
    FIELD7: 92.9
  },
  2.3: {
    FIELD1: 82.7,
    FIELD2: 83.9,
    FIELD3: 86,
    FIELD4: 88.3,
    FIELD5: 90.6,
    FIELD6: 92.6,
    FIELD7: 93.8
  },
  2.4: {
    FIELD1: 83.5,
    FIELD2: 84.7,
    FIELD3: 86.8,
    FIELD4: 89.1,
    FIELD5: 91.4,
    FIELD6: 93.5,
    FIELD7: 94.7
  },
  2.5: {
    FIELD1: 84.2,
    FIELD2: 85.4,
    FIELD3: 87.6,
    FIELD4: 89.9,
    FIELD5: 92.2,
    FIELD6: 94.4,
    FIELD7: 95.6
  },
  2.6: {
    FIELD1: 84.9,
    FIELD2: 86.2,
    FIELD3: 88.3,
    FIELD4: 90.7,
    FIELD5: 93.1,
    FIELD6: 95.2,
    FIELD7: 96.5
  },
  2.7: {
    FIELD1: 85.6,
    FIELD2: 86.9,
    FIELD3: 89,
    FIELD4: 91.4,
    FIELD5: 93.9,
    FIELD6: 96,
    FIELD7: 97.3
  },
  2.8: {
    FIELD1: 86.2,
    FIELD2: 87.5,
    FIELD3: 89.7,
    FIELD4: 92.2,
    FIELD5: 94.6,
    FIELD6: 96.8,
    FIELD7: 98.2
  },
  2.9: {
    FIELD1: 86.9,
    FIELD2: 88.2,
    FIELD3: 90.4,
    FIELD4: 92.9,
    FIELD5: 95.4,
    FIELD6: 97.6,
    FIELD7: 99
  },
  '2.10': {
    FIELD1: 87.5,
    FIELD2: 88.9,
    FIELD3: 91.1,
    FIELD4: 93.6,
    FIELD5: 96.2,
    FIELD6: 98.4,
    FIELD7: 99.8
  },
  2.11: {
    FIELD1: 88.2,
    FIELD2: 89.5,
    FIELD3: 91.8,
    FIELD4: 94.4,
    FIELD5: 96.9,
    FIELD6: 99.2,
    FIELD7: 100.5
  },
  '3.0': {
    FIELD1: 88.8,
    FIELD2: 90.2,
    FIELD3: 92.5,
    FIELD4: 95.1,
    FIELD5: 97.6,
    FIELD6: 99.9,
    FIELD7: 101.3
  },
  3.1: {
    FIELD1: 89.4,
    FIELD2: 90.8,
    FIELD3: 93.1,
    FIELD4: 95.7,
    FIELD5: 98.3,
    FIELD6: 100.7,
    FIELD7: 102.1
  },
  3.2: {
    FIELD1: 90,
    FIELD2: 91.4,
    FIELD3: 93.8,
    FIELD4: 96.4,
    FIELD5: 99,
    FIELD6: 101.4,
    FIELD7: 102.8
  },
  3.3: {
    FIELD1: 90.6,
    FIELD2: 92,
    FIELD3: 94.4,
    FIELD4: 97.1,
    FIELD5: 99.7,
    FIELD6: 102.1,
    FIELD7: 103.6
  },
  3.4: {
    FIELD1: 91.2,
    FIELD2: 92.6,
    FIELD3: 95.1,
    FIELD4: 97.7,
    FIELD5: 100.4,
    FIELD6: 102.9,
    FIELD7: 104.3
  },
  3.5: {
    FIELD1: 91.8,
    FIELD2: 93.2,
    FIELD3: 95.7,
    FIELD4: 98.4,
    FIELD5: 101.1,
    FIELD6: 103.6,
    FIELD7: 105
  },
  3.6: {
    FIELD1: 92.4,
    FIELD2: 93.8,
    FIELD3: 96.3,
    FIELD4: 99,
    FIELD5: 101.8,
    FIELD6: 104.3,
    FIELD7: 105.7
  },
  3.7: {
    FIELD1: 92.9,
    FIELD2: 94.4,
    FIELD3: 96.9,
    FIELD4: 99.7,
    FIELD5: 102.4,
    FIELD6: 104.9,
    FIELD7: 106.4
  },
  3.8: {
    FIELD1: 93.5,
    FIELD2: 95,
    FIELD3: 97.5,
    FIELD4: 100.3,
    FIELD5: 103.1,
    FIELD6: 105.6,
    FIELD7: 107.1
  },
  3.9: {
    FIELD1: 94,
    FIELD2: 95.6,
    FIELD3: 98.1,
    FIELD4: 100.9,
    FIELD5: 103.7,
    FIELD6: 106.3,
    FIELD7: 107.8
  },
  '3.10': {
    FIELD1: 94.6,
    FIELD2: 96.1,
    FIELD3: 98.7,
    FIELD4: 101.5,
    FIELD5: 104.4,
    FIELD6: 107,
    FIELD7: 108.5
  },
  3.11: {
    FIELD1: 95.1,
    FIELD2: 96.7,
    FIELD3: 99.3,
    FIELD4: 102.1,
    FIELD5: 105,
    FIELD6: 107.6,
    FIELD7: 109.2
  },
  '4.0': {
    FIELD1: 95.6,
    FIELD2: 97.2,
    FIELD3: 99.8,
    FIELD4: 102.7,
    FIELD5: 105.6,
    FIELD6: 108.3,
    FIELD7: 109.8
  },
  4.1: {
    FIELD1: 96.2,
    FIELD2: 97.8,
    FIELD3: 100.4,
    FIELD4: 103.3,
    FIELD5: 106.3,
    FIELD6: 108.9,
    FIELD7: 110.5
  },
  4.2: {
    FIELD1: 96.7,
    FIELD2: 98.3,
    FIELD3: 100.9,
    FIELD4: 103.9,
    FIELD5: 106.9,
    FIELD6: 109.5,
    FIELD7: 111.1
  },
  4.3: {
    FIELD1: 97.2,
    FIELD2: 98.8,
    FIELD3: 101.5,
    FIELD4: 104.5,
    FIELD5: 107.5,
    FIELD6: 110.1,
    FIELD7: 111.8
  },
  4.4: {
    FIELD1: 97.7,
    FIELD2: 99.3,
    FIELD3: 102,
    FIELD4: 105,
    FIELD5: 108.1,
    FIELD6: 110.8,
    FIELD7: 112.4
  },
  4.5: {
    FIELD1: 98.2,
    FIELD2: 99.9,
    FIELD3: 102.6,
    FIELD4: 105.6,
    FIELD5: 108.6,
    FIELD6: 111.4,
    FIELD7: 113
  },
  4.6: {
    FIELD1: 98.7,
    FIELD2: 100.4,
    FIELD3: 103.1,
    FIELD4: 106.2,
    FIELD5: 109.2,
    FIELD6: 112,
    FIELD7: 113.6
  },
  4.7: {
    FIELD1: 99.2,
    FIELD2: 100.9,
    FIELD3: 103.6,
    FIELD4: 106.7,
    FIELD5: 109.8,
    FIELD6: 112.6,
    FIELD7: 114.3
  },
  4.8: {
    FIELD1: 99.7,
    FIELD2: 101.4,
    FIELD3: 104.2,
    FIELD4: 107.3,
    FIELD5: 110.4,
    FIELD6: 113.2,
    FIELD7: 114.9
  },
  4.9: {
    FIELD1: 100.2,
    FIELD2: 101.9,
    FIELD3: 104.7,
    FIELD4: 107.8,
    FIELD5: 111,
    FIELD6: 113.8,
    FIELD7: 115.5
  },
  '4.10': {
    FIELD1: 100.7,
    FIELD2: 102.4,
    FIELD3: 105.2,
    FIELD4: 108.4,
    FIELD5: 111.5,
    FIELD6: 114.4,
    FIELD7: 116.1
  },
  4.11: {
    FIELD1: 101.1,
    FIELD2: 102.8,
    FIELD3: 105.7,
    FIELD4: 108.9,
    FIELD5: 112.1,
    FIELD6: 114.9,
    FIELD7: 116.7
  },
  '5.0': {
    FIELD1: 101.6,
    FIELD2: 103.3,
    FIELD3: 106.2,
    FIELD4: 109.4,
    FIELD5: 112.6,
    FIELD6: 115.5,
    FIELD7: 117.2
  },
  5.1: {
    FIELD1: 101.75,
    FIELD2: 103.485,
    FIELD3: 106.382,
    FIELD4: 109.602,
    FIELD5: 112.821,
    FIELD6: 115.719,
    FIELD7: 117.453
  },
  5.2: {
    FIELD1: 102.221,
    FIELD2: 103.967,
    FIELD3: 106.884,
    FIELD4: 110.126,
    FIELD5: 113.367,
    FIELD6: 116.285,
    FIELD7: 118.031
  },
  5.3: {
    FIELD1: 102.686,
    FIELD2: 104.444,
    FIELD3: 107.382,
    FIELD4: 110.645,
    FIELD5: 113.909,
    FIELD6: 116.846,
    FIELD7: 118.604
  },
  5.4: {
    FIELD1: 103.147,
    FIELD2: 104.917,
    FIELD3: 107.874,
    FIELD4: 111.16,
    FIELD5: 114.445,
    FIELD6: 117.402,
    FIELD7: 119.172
  },
  5.5: {
    FIELD1: 103.606,
    FIELD2: 105.387,
    FIELD3: 108.363,
    FIELD4: 111.67,
    FIELD5: 114.976,
    FIELD6: 117.952,
    FIELD7: 119.733
  },
  5.6: {
    FIELD1: 104.059,
    FIELD2: 105.851,
    FIELD3: 108.847,
    FIELD4: 112.175,
    FIELD5: 115.504,
    FIELD6: 118.499,
    FIELD7: 120.292
  },
  5.7: {
    FIELD1: 104.509,
    FIELD2: 106.313,
    FIELD3: 109.327,
    FIELD4: 112.677,
    FIELD5: 116.026,
    FIELD6: 119.04,
    FIELD7: 120.844
  },
  5.8: {
    FIELD1: 104.955,
    FIELD2: 106.771,
    FIELD3: 109.804,
    FIELD4: 113.174,
    FIELD5: 116.544,
    FIELD6: 119.577,
    FIELD7: 121.393
  },
  5.9: {
    FIELD1: 105.398,
    FIELD2: 107.224,
    FIELD3: 110.276,
    FIELD4: 113.667,
    FIELD5: 117.058,
    FIELD6: 120.11,
    FIELD7: 121.937
  },
  '5.10': {
    FIELD1: 105.836,
    FIELD2: 107.674,
    FIELD3: 110.745,
    FIELD4: 114.156,
    FIELD5: 117.568,
    FIELD6: 120.639,
    FIELD7: 122.477
  },
  5.11: {
    FIELD1: 106.272,
    FIELD2: 108.12,
    FIELD3: 111.21,
    FIELD4: 114.642,
    FIELD5: 118.075,
    FIELD6: 121.164,
    FIELD7: 123.013
  },
  '6.0': {
    FIELD1: 106.703,
    FIELD2: 108.563,
    FIELD3: 111.671,
    FIELD4: 115.124,
    FIELD5: 118.578,
    FIELD6: 121.685,
    FIELD7: 123.545
  },
  6.1: {
    FIELD1: 107.135,
    FIELD2: 109.005,
    FIELD3: 112.131,
    FIELD4: 115.604,
    FIELD5: 119.077,
    FIELD6: 122.203,
    FIELD7: 124.073
  },
  6.2: {
    FIELD1: 107.564,
    FIELD2: 109.445,
    FIELD3: 112.588,
    FIELD4: 116.081,
    FIELD5: 119.574,
    FIELD6: 122.718,
    FIELD7: 124.599
  },
  6.3: {
    FIELD1: 107.989,
    FIELD2: 109.881,
    FIELD3: 113.043,
    FIELD4: 116.557,
    FIELD5: 120.07,
    FIELD6: 123.232,
    FIELD7: 125.125
  },
  6.4: {
    FIELD1: 108.417,
    FIELD2: 110.319,
    FIELD3: 113.499,
    FIELD4: 117.031,
    FIELD5: 120.563,
    FIELD6: 123.743,
    FIELD7: 125.645
  },
  6.5: {
    FIELD1: 108.842,
    FIELD2: 110.755,
    FIELD3: 113.952,
    FIELD4: 117.504,
    FIELD5: 121.057,
    FIELD6: 124.254,
    FIELD7: 126.167
  },
  6.6: {
    FIELD1: 109.266,
    FIELD2: 111.19,
    FIELD3: 114.405,
    FIELD4: 117.977,
    FIELD5: 121.549,
    FIELD6: 124.764,
    FIELD7: 126.688
  },
  6.7: {
    FIELD1: 109.691,
    FIELD2: 111.626,
    FIELD3: 114.858,
    FIELD4: 118.449,
    FIELD5: 122.04,
    FIELD6: 125.272,
    FIELD7: 127.207
  },
  6.8: {
    FIELD1: 110.115,
    FIELD2: 112.06,
    FIELD3: 115.31,
    FIELD4: 118.921,
    FIELD5: 122.532,
    FIELD6: 125.782,
    FIELD7: 127.727
  },
  6.9: {
    FIELD1: 110.54,
    FIELD2: 112.495,
    FIELD3: 115.762,
    FIELD4: 119.393,
    FIELD5: 123.023,
    FIELD6: 126.29,
    FIELD7: 128.246
  },
  '6.10': {
    FIELD1: 110.965,
    FIELD2: 112.931,
    FIELD3: 116.215,
    FIELD4: 119.865,
    FIELD5: 123.514,
    FIELD6: 126.799,
    FIELD7: 128.765
  },
  6.11: {
    FIELD1: 111.391,
    FIELD2: 113.367,
    FIELD3: 116.669,
    FIELD4: 120.337,
    FIELD5: 124.006,
    FIELD6: 127.308,
    FIELD7: 129.284
  },
  '7.0': {
    FIELD1: 111.819,
    FIELD2: 113.805,
    FIELD3: 117.123,
    FIELD4: 120.81,
    FIELD5: 124.498,
    FIELD6: 127.816,
    FIELD7: 129.802
  },
  7.1: {
    FIELD1: 112.245,
    FIELD2: 114.242,
    FIELD3: 117.578,
    FIELD4: 121.284,
    FIELD5: 124.991,
    FIELD6: 128.327,
    FIELD7: 130.323
  },
  7.2: {
    FIELD1: 112.674,
    FIELD2: 114.681,
    FIELD3: 118.034,
    FIELD4: 121.759,
    FIELD5: 125.484,
    FIELD6: 128.837,
    FIELD7: 130.843
  },
  7.3: {
    FIELD1: 113.102,
    FIELD2: 115.119,
    FIELD3: 118.489,
    FIELD4: 122.234,
    FIELD5: 125.978,
    FIELD6: 129.349,
    FIELD7: 131.366
  },
  7.4: {
    FIELD1: 113.532,
    FIELD2: 115.559,
    FIELD3: 118.946,
    FIELD4: 122.71,
    FIELD5: 126.473,
    FIELD6: 129.86,
    FIELD7: 131.887
  },
  7.5: {
    FIELD1: 113.965,
    FIELD2: 116.002,
    FIELD3: 119.405,
    FIELD4: 123.187,
    FIELD5: 126.968,
    FIELD6: 130.371,
    FIELD7: 132.408
  },
  7.6: {
    FIELD1: 114.397,
    FIELD2: 116.444,
    FIELD3: 119.864,
    FIELD4: 123.665,
    FIELD5: 127.465,
    FIELD6: 130.885,
    FIELD7: 132.932
  },
  7.7: {
    FIELD1: 114.83,
    FIELD2: 116.887,
    FIELD3: 120.324,
    FIELD4: 124.144,
    FIELD5: 127.963,
    FIELD6: 131.4,
    FIELD7: 133.457
  },
  7.8: {
    FIELD1: 115.266,
    FIELD2: 117.333,
    FIELD3: 120.786,
    FIELD4: 124.623,
    FIELD5: 128.461,
    FIELD6: 131.914,
    FIELD7: 133.981
  },
  7.9: {
    FIELD1: 115.702,
    FIELD2: 117.779,
    FIELD3: 121.249,
    FIELD4: 125.104,
    FIELD5: 128.96,
    FIELD6: 132.43,
    FIELD7: 134.507
  },
  '7.10': {
    FIELD1: 116.14,
    FIELD2: 118.227,
    FIELD3: 121.713,
    FIELD4: 125.587,
    FIELD5: 129.461,
    FIELD6: 132.947,
    FIELD7: 135.033
  },
  7.11: {
    FIELD1: 116.579,
    FIELD2: 118.676,
    FIELD3: 122.179,
    FIELD4: 126.071,
    FIELD5: 129.963,
    FIELD6: 133.465,
    FIELD7: 135.562
  },
  '8.0': {
    FIELD1: 117.02,
    FIELD2: 119.126,
    FIELD3: 122.645,
    FIELD4: 126.556,
    FIELD5: 130.466,
    FIELD6: 133.986,
    FIELD7: 136.092
  },
  8.1: {
    FIELD1: 117.461,
    FIELD2: 119.577,
    FIELD3: 123.114,
    FIELD4: 127.042,
    FIELD5: 130.971,
    FIELD6: 134.507,
    FIELD7: 136.623
  },
  8.2: {
    FIELD1: 117.906,
    FIELD2: 120.032,
    FIELD3: 123.584,
    FIELD4: 127.53,
    FIELD5: 131.477,
    FIELD6: 135.029,
    FIELD7: 137.155
  },
  8.3: {
    FIELD1: 118.352,
    FIELD2: 120.488,
    FIELD3: 124.056,
    FIELD4: 128.02,
    FIELD5: 131.984,
    FIELD6: 135.552,
    FIELD7: 137.687
  },
  8.4: {
    FIELD1: 118.8,
    FIELD2: 120.945,
    FIELD3: 124.529,
    FIELD4: 128.511,
    FIELD5: 132.493,
    FIELD6: 136.077,
    FIELD7: 138.222
  },
  8.5: {
    FIELD1: 119.249,
    FIELD2: 121.404,
    FIELD3: 125.004,
    FIELD4: 129.004,
    FIELD5: 133.003,
    FIELD6: 136.603,
    FIELD7: 138.758
  },
  8.6: {
    FIELD1: 119.699,
    FIELD2: 121.863,
    FIELD3: 125.48,
    FIELD4: 129.498,
    FIELD5: 133.515,
    FIELD6: 137.132,
    FIELD7: 139.296
  },
  8.7: {
    FIELD1: 120.153,
    FIELD2: 122.327,
    FIELD3: 125.958,
    FIELD4: 129.993,
    FIELD5: 134.028,
    FIELD6: 137.66,
    FIELD7: 139.833
  },
  8.8: {
    FIELD1: 120.608,
    FIELD2: 122.791,
    FIELD3: 126.438,
    FIELD4: 130.49,
    FIELD5: 134.543,
    FIELD6: 138.19,
    FIELD7: 140.372
  },
  8.9: {
    FIELD1: 121.063,
    FIELD2: 123.255,
    FIELD3: 126.919,
    FIELD4: 130.989,
    FIELD5: 135.059,
    FIELD6: 138.723,
    FIELD7: 140.915
  },
  '8.10': {
    FIELD1: 121.523,
    FIELD2: 123.725,
    FIELD3: 127.403,
    FIELD4: 131.49,
    FIELD5: 135.576,
    FIELD6: 139.254,
    FIELD7: 141.456
  },
  8.11: {
    FIELD1: 121.983,
    FIELD2: 124.193,
    FIELD3: 127.887,
    FIELD4: 131.991,
    FIELD5: 136.095,
    FIELD6: 139.789,
    FIELD7: 142
  },
  '9.0': {
    FIELD1: 122.443,
    FIELD2: 124.663,
    FIELD3: 128.373,
    FIELD4: 132.494,
    FIELD5: 136.616,
    FIELD6: 140.326,
    FIELD7: 142.546
  },
  9.1: {
    FIELD1: 122.907,
    FIELD2: 125.136,
    FIELD3: 128.861,
    FIELD4: 132.999,
    FIELD5: 137.137,
    FIELD6: 140.862,
    FIELD7: 143.09
  },
  9.2: {
    FIELD1: 123.372,
    FIELD2: 125.61,
    FIELD3: 129.35,
    FIELD4: 133.505,
    FIELD5: 137.659,
    FIELD6: 141.399,
    FIELD7: 143.637
  },
  9.3: {
    FIELD1: 123.839,
    FIELD2: 126.086,
    FIELD3: 129.84,
    FIELD4: 134.012,
    FIELD5: 138.183,
    FIELD6: 141.938,
    FIELD7: 144.185
  },
  9.4: {
    FIELD1: 124.307,
    FIELD2: 126.562,
    FIELD3: 130.332,
    FIELD4: 134.52,
    FIELD5: 138.708,
    FIELD6: 142.478,
    FIELD7: 144.734
  },
  9.5: {
    FIELD1: 124.778,
    FIELD2: 127.042,
    FIELD3: 130.826,
    FIELD4: 135.03,
    FIELD5: 139.234,
    FIELD6: 143.018,
    FIELD7: 145.282
  },
  9.6: {
    FIELD1: 125.248,
    FIELD2: 127.521,
    FIELD3: 131.32,
    FIELD4: 135.541,
    FIELD5: 139.762,
    FIELD6: 143.561,
    FIELD7: 145.834
  },
  9.7: {
    FIELD1: 125.721,
    FIELD2: 128.003,
    FIELD3: 131.816,
    FIELD4: 136.053,
    FIELD5: 140.29,
    FIELD6: 144.103,
    FIELD7: 146.386
  },
  9.8: {
    FIELD1: 126.198,
    FIELD2: 128.488,
    FIELD3: 132.315,
    FIELD4: 136.567,
    FIELD5: 140.819,
    FIELD6: 144.646,
    FIELD7: 146.936
  },
  9.9: {
    FIELD1: 126.674,
    FIELD2: 128.973,
    FIELD3: 132.814,
    FIELD4: 137.082,
    FIELD5: 141.35,
    FIELD6: 145.191,
    FIELD7: 147.49
  },
  '9.10': {
    FIELD1: 127.151,
    FIELD2: 129.459,
    FIELD3: 133.315,
    FIELD4: 137.599,
    FIELD5: 141.883,
    FIELD6: 145.739,
    FIELD7: 148.046
  },
  9.11: {
    FIELD1: 127.632,
    FIELD2: 129.948,
    FIELD3: 133.817,
    FIELD4: 138.117,
    FIELD5: 142.416,
    FIELD6: 146.285,
    FIELD7: 148.601
  },
  '10.0': {
    FIELD1: 128.115,
    FIELD2: 130.439,
    FIELD3: 134.322,
    FIELD4: 138.636,
    FIELD5: 142.951,
    FIELD6: 146.834,
    FIELD7: 149.158
  },
  10.1: {
    FIELD1: 128.601,
    FIELD2: 130.933,
    FIELD3: 134.829,
    FIELD4: 139.158,
    FIELD5: 143.486,
    FIELD6: 147.382,
    FIELD7: 149.714
  },
  10.2: {
    FIELD1: 129.086,
    FIELD2: 131.426,
    FIELD3: 135.336,
    FIELD4: 139.68,
    FIELD5: 144.024,
    FIELD6: 147.934,
    FIELD7: 150.274
  },
  10.3: {
    FIELD1: 129.576,
    FIELD2: 131.923,
    FIELD3: 135.846,
    FIELD4: 140.205,
    FIELD5: 144.563,
    FIELD6: 148.486,
    FIELD7: 150.834
  },
  10.4: {
    FIELD1: 130.067,
    FIELD2: 132.422,
    FIELD3: 136.358,
    FIELD4: 140.731,
    FIELD5: 145.104,
    FIELD6: 149.04,
    FIELD7: 151.396
  },
  10.5: {
    FIELD1: 130.56,
    FIELD2: 132.923,
    FIELD3: 136.872,
    FIELD4: 141.259,
    FIELD5: 145.647,
    FIELD6: 149.596,
    FIELD7: 151.959
  },
  10.6: {
    FIELD1: 131.054,
    FIELD2: 133.425,
    FIELD3: 137.387,
    FIELD4: 141.789,
    FIELD5: 146.191,
    FIELD6: 150.153,
    FIELD7: 152.524
  },
  10.7: {
    FIELD1: 131.552,
    FIELD2: 133.931,
    FIELD3: 137.905,
    FIELD4: 142.321,
    FIELD5: 146.736,
    FIELD6: 150.711,
    FIELD7: 153.089
  },
  10.8: {
    FIELD1: 132.052,
    FIELD2: 134.437,
    FIELD3: 138.424,
    FIELD4: 142.853,
    FIELD5: 147.283,
    FIELD6: 151.269,
    FIELD7: 153.655
  },
  10.9: {
    FIELD1: 132.552,
    FIELD2: 134.946,
    FIELD3: 138.944,
    FIELD4: 143.387,
    FIELD5: 147.83,
    FIELD6: 151.829,
    FIELD7: 154.222
  },
  '10.10': {
    FIELD1: 133.054,
    FIELD2: 135.454,
    FIELD3: 139.466,
    FIELD4: 143.922,
    FIELD5: 148.379,
    FIELD6: 152.39,
    FIELD7: 154.791
  },
  10.11: {
    FIELD1: 133.556,
    FIELD2: 135.964,
    FIELD3: 139.987,
    FIELD4: 144.458,
    FIELD5: 148.928,
    FIELD6: 152.951,
    FIELD7: 155.359
  },
  '11.0': {
    FIELD1: 134.06,
    FIELD2: 136.475,
    FIELD3: 140.51,
    FIELD4: 144.993,
    FIELD5: 149.476,
    FIELD6: 153.511,
    FIELD7: 155.925
  },
  11.1: {
    FIELD1: 134.565,
    FIELD2: 136.986,
    FIELD3: 141.032,
    FIELD4: 145.528,
    FIELD5: 150.024,
    FIELD6: 154.07,
    FIELD7: 156.491
  },
  11.2: {
    FIELD1: 135.068,
    FIELD2: 137.497,
    FIELD3: 141.554,
    FIELD4: 146.062,
    FIELD5: 150.57,
    FIELD6: 154.628,
    FIELD7: 157.056
  },
  11.3: {
    FIELD1: 135.573,
    FIELD2: 138.008,
    FIELD3: 142.075,
    FIELD4: 146.595,
    FIELD5: 151.115,
    FIELD6: 155.183,
    FIELD7: 157.617
  },
  11.4: {
    FIELD1: 136.074,
    FIELD2: 138.515,
    FIELD3: 142.594,
    FIELD4: 147.126,
    FIELD5: 151.658,
    FIELD6: 155.737,
    FIELD7: 158.178
  },
  11.5: {
    FIELD1: 136.575,
    FIELD2: 139.022,
    FIELD3: 143.111,
    FIELD4: 147.655,
    FIELD5: 152.198,
    FIELD6: 156.287,
    FIELD7: 158.735
  },
  11.6: {
    FIELD1: 137.073,
    FIELD2: 139.527,
    FIELD3: 143.626,
    FIELD4: 148.18,
    FIELD5: 152.735,
    FIELD6: 156.834,
    FIELD7: 159.287
  },
  11.7: {
    FIELD1: 137.568,
    FIELD2: 140.028,
    FIELD3: 144.137,
    FIELD4: 148.702,
    FIELD5: 153.268,
    FIELD6: 157.377,
    FIELD7: 159.836
  },
  11.8: {
    FIELD1: 138.062,
    FIELD2: 140.526,
    FIELD3: 144.644,
    FIELD4: 149.22,
    FIELD5: 153.795,
    FIELD6: 157.913,
    FIELD7: 160.378
  },
  11.9: {
    FIELD1: 138.548,
    FIELD2: 141.018,
    FIELD3: 145.146,
    FIELD4: 149.732,
    FIELD5: 154.318,
    FIELD6: 158.446,
    FIELD7: 160.916
  },
  '11.10': {
    FIELD1: 139.032,
    FIELD2: 141.507,
    FIELD3: 145.643,
    FIELD4: 150.239,
    FIELD5: 154.835,
    FIELD6: 158.971,
    FIELD7: 161.446
  },
  11.11: {
    FIELD1: 139.51,
    FIELD2: 141.99,
    FIELD3: 146.135,
    FIELD4: 150.739,
    FIELD5: 155.344,
    FIELD6: 159.489,
    FIELD7: 161.969
  },
  '12.0': {
    FIELD1: 139.981,
    FIELD2: 142.467,
    FIELD3: 146.619,
    FIELD4: 151.233,
    FIELD5: 155.846,
    FIELD6: 159.999,
    FIELD7: 162.484
  },
  12.1: {
    FIELD1: 140.448,
    FIELD2: 142.938,
    FIELD3: 147.097,
    FIELD4: 151.718,
    FIELD5: 156.34,
    FIELD6: 160.499,
    FIELD7: 162.988
  },
  12.2: {
    FIELD1: 140.905,
    FIELD2: 143.399,
    FIELD3: 147.565,
    FIELD4: 152.195,
    FIELD5: 156.825,
    FIELD6: 160.992,
    FIELD7: 163.485
  },
  12.3: {
    FIELD1: 141.355,
    FIELD2: 143.853,
    FIELD3: 148.026,
    FIELD4: 152.663,
    FIELD5: 157.3,
    FIELD6: 161.473,
    FIELD7: 163.97
  },
  12.4: {
    FIELD1: 141.794,
    FIELD2: 144.296,
    FIELD3: 148.476,
    FIELD4: 153.121,
    FIELD5: 157.765,
    FIELD6: 161.945,
    FIELD7: 164.447
  },
  12.5: {
    FIELD1: 142.226,
    FIELD2: 144.731,
    FIELD3: 148.917,
    FIELD4: 153.568,
    FIELD5: 158.219,
    FIELD6: 162.404,
    FIELD7: 164.909
  },
  12.6: {
    FIELD1: 142.648,
    FIELD2: 145.156,
    FIELD3: 149.347,
    FIELD4: 154.004,
    FIELD5: 158.661,
    FIELD6: 162.852,
    FIELD7: 165.36
  },
  12.7: {
    FIELD1: 143.059,
    FIELD2: 145.571,
    FIELD3: 149.767,
    FIELD4: 154.429,
    FIELD5: 159.091,
    FIELD6: 163.287,
    FIELD7: 165.799
  },
  12.8: {
    FIELD1: 143.463,
    FIELD2: 145.976,
    FIELD3: 150.176,
    FIELD4: 154.842,
    FIELD5: 159.509,
    FIELD6: 163.709,
    FIELD7: 166.222
  },
  12.9: {
    FIELD1: 143.852,
    FIELD2: 146.368,
    FIELD3: 150.573,
    FIELD4: 155.244,
    FIELD5: 159.915,
    FIELD6: 164.119,
    FIELD7: 166.635
  },
  '12.10': {
    FIELD1: 144.231,
    FIELD2: 146.749,
    FIELD3: 150.958,
    FIELD4: 155.633,
    FIELD5: 160.308,
    FIELD6: 164.517,
    FIELD7: 167.035
  },
  12.11: {
    FIELD1: 144.601,
    FIELD2: 147.121,
    FIELD3: 151.332,
    FIELD4: 156.01,
    FIELD5: 160.689,
    FIELD6: 164.899,
    FIELD7: 167.419
  },
  '13.0': {
    FIELD1: 144.957,
    FIELD2: 147.479,
    FIELD3: 151.693,
    FIELD4: 156.375,
    FIELD5: 161.057,
    FIELD6: 165.271,
    FIELD7: 167.793
  },
  13.1: {
    FIELD1: 145.304,
    FIELD2: 147.827,
    FIELD3: 152.043,
    FIELD4: 156.727,
    FIELD5: 161.411,
    FIELD6: 165.627,
    FIELD7: 168.15
  },
  13.2: {
    FIELD1: 145.64,
    FIELD2: 148.164,
    FIELD3: 152.381,
    FIELD4: 157.067,
    FIELD5: 161.752,
    FIELD6: 165.97,
    FIELD7: 168.493
  },
  13.3: {
    FIELD1: 145.964,
    FIELD2: 148.488,
    FIELD3: 152.707,
    FIELD4: 157.394,
    FIELD5: 162.081,
    FIELD6: 166.299,
    FIELD7: 168.824
  },
  13.4: {
    FIELD1: 146.274,
    FIELD2: 148.799,
    FIELD3: 153.019,
    FIELD4: 157.708,
    FIELD5: 162.397,
    FIELD6: 166.617,
    FIELD7: 169.143
  },
  13.5: {
    FIELD1: 146.574,
    FIELD2: 149.1,
    FIELD3: 153.321,
    FIELD4: 158.01,
    FIELD5: 162.7,
    FIELD6: 166.92,
    FIELD7: 169.446
  },
  13.6: {
    FIELD1: 146.864,
    FIELD2: 149.39,
    FIELD3: 153.61,
    FIELD4: 158.3,
    FIELD5: 162.989,
    FIELD6: 167.21,
    FIELD7: 169.736
  },
  13.7: {
    FIELD1: 147.142,
    FIELD2: 149.668,
    FIELD3: 153.888,
    FIELD4: 158.577,
    FIELD5: 163.266,
    FIELD6: 167.486,
    FIELD7: 170.012
  },
  13.8: {
    FIELD1: 147.409,
    FIELD2: 149.935,
    FIELD3: 154.154,
    FIELD4: 158.842,
    FIELD5: 163.531,
    FIELD6: 167.75,
    FIELD7: 170.276
  },
  13.9: {
    FIELD1: 147.663,
    FIELD2: 150.188,
    FIELD3: 154.408,
    FIELD4: 159.096,
    FIELD5: 163.784,
    FIELD6: 168.004,
    FIELD7: 170.529
  },
  '13.10': {
    FIELD1: 147.909,
    FIELD2: 150.433,
    FIELD3: 154.651,
    FIELD4: 159.338,
    FIELD5: 164.025,
    FIELD6: 168.243,
    FIELD7: 170.768
  },
  13.11: {
    FIELD1: 148.144,
    FIELD2: 150.667,
    FIELD3: 154.884,
    FIELD4: 159.569,
    FIELD5: 164.254,
    FIELD6: 168.471,
    FIELD7: 170.994
  },
  '14.0': {
    FIELD1: 148.369,
    FIELD2: 150.891,
    FIELD3: 155.106,
    FIELD4: 159.789,
    FIELD5: 164.472,
    FIELD6: 168.687,
    FIELD7: 171.209
  },
  14.1: {
    FIELD1: 148.584,
    FIELD2: 151.105,
    FIELD3: 155.318,
    FIELD4: 159.998,
    FIELD5: 164.679,
    FIELD6: 168.891,
    FIELD7: 171.412
  },
  14.2: {
    FIELD1: 148.788,
    FIELD2: 151.308,
    FIELD3: 155.518,
    FIELD4: 160.197,
    FIELD5: 164.876,
    FIELD6: 169.087,
    FIELD7: 171.607
  },
  14.3: {
    FIELD1: 148.984,
    FIELD2: 151.502,
    FIELD3: 155.71,
    FIELD4: 160.386,
    FIELD5: 165.061,
    FIELD6: 169.269,
    FIELD7: 171.788
  },
  14.4: {
    FIELD1: 149.171,
    FIELD2: 151.687,
    FIELD3: 155.892,
    FIELD4: 160.564,
    FIELD5: 165.236,
    FIELD6: 169.441,
    FIELD7: 171.958
  },
  14.5: {
    FIELD1: 149.346,
    FIELD2: 151.861,
    FIELD3: 156.064,
    FIELD4: 160.733,
    FIELD5: 165.403,
    FIELD6: 169.605,
    FIELD7: 172.12
  },
  14.6: {
    FIELD1: 149.516,
    FIELD2: 152.028,
    FIELD3: 156.227,
    FIELD4: 160.893,
    FIELD5: 165.558,
    FIELD6: 169.757,
    FIELD7: 172.27
  },
  14.7: {
    FIELD1: 149.674,
    FIELD2: 152.185,
    FIELD3: 156.381,
    FIELD4: 161.043,
    FIELD5: 165.705,
    FIELD6: 169.901,
    FIELD7: 172.412
  },
  14.8: {
    FIELD1: 149.827,
    FIELD2: 152.335,
    FIELD3: 156.527,
    FIELD4: 161.184,
    FIELD5: 165.842,
    FIELD6: 170.034,
    FIELD7: 172.542
  },
  14.9: {
    FIELD1: 149.969,
    FIELD2: 152.475,
    FIELD3: 156.664,
    FIELD4: 161.318,
    FIELD5: 165.971,
    FIELD6: 170.16,
    FIELD7: 172.666
  },
  '14.10': {
    FIELD1: 150.104,
    FIELD2: 152.608,
    FIELD3: 156.793,
    FIELD4: 161.442,
    FIELD5: 166.092,
    FIELD6: 170.277,
    FIELD7: 172.781
  },
  14.11: {
    FIELD1: 150.231,
    FIELD2: 152.733,
    FIELD3: 156.914,
    FIELD4: 161.56,
    FIELD5: 166.205,
    FIELD6: 170.386,
    FIELD7: 172.888
  },
  '15.0': {
    FIELD1: 150.354,
    FIELD2: 152.853,
    FIELD3: 157.029,
    FIELD4: 161.669,
    FIELD5: 166.309,
    FIELD6: 170.485,
    FIELD7: 172.984
  },
  15.1: {
    FIELD1: 150.468,
    FIELD2: 152.965,
    FIELD3: 157.137,
    FIELD4: 161.772,
    FIELD5: 166.407,
    FIELD6: 170.579,
    FIELD7: 173.075
  },
  15.2: {
    FIELD1: 150.576,
    FIELD2: 153.07,
    FIELD3: 157.237,
    FIELD4: 161.867,
    FIELD5: 166.498,
    FIELD6: 170.665,
    FIELD7: 173.159
  },
  15.3: {
    FIELD1: 150.675,
    FIELD2: 153.166,
    FIELD3: 157.33,
    FIELD4: 161.956,
    FIELD5: 166.583,
    FIELD6: 170.746,
    FIELD7: 173.238
  },
  15.4: {
    FIELD1: 150.77,
    FIELD2: 153.259,
    FIELD3: 157.418,
    FIELD4: 162.039,
    FIELD5: 166.66,
    FIELD6: 170.819,
    FIELD7: 173.308
  },
  15.5: {
    FIELD1: 150.861,
    FIELD2: 153.347,
    FIELD3: 157.501,
    FIELD4: 162.116,
    FIELD5: 166.732,
    FIELD6: 170.886,
    FIELD7: 173.372
  },
  15.6: {
    FIELD1: 150.946,
    FIELD2: 153.429,
    FIELD3: 157.578,
    FIELD4: 162.188,
    FIELD5: 166.798,
    FIELD6: 170.947,
    FIELD7: 173.43
  },
  15.7: {
    FIELD1: 151.024,
    FIELD2: 153.504,
    FIELD3: 157.649,
    FIELD4: 162.254,
    FIELD5: 166.859,
    FIELD6: 171.004,
    FIELD7: 173.485
  },
  15.8: {
    FIELD1: 151.099,
    FIELD2: 153.577,
    FIELD3: 157.716,
    FIELD4: 162.315,
    FIELD5: 166.915,
    FIELD6: 171.054,
    FIELD7: 173.531
  },
  15.9: {
    FIELD1: 151.168,
    FIELD2: 153.643,
    FIELD3: 157.778,
    FIELD4: 162.372,
    FIELD5: 166.966,
    FIELD6: 171.101,
    FIELD7: 173.576
  },
  '15.10': {
    FIELD1: 151.232,
    FIELD2: 153.704,
    FIELD3: 157.835,
    FIELD4: 162.424,
    FIELD5: 167.013,
    FIELD6: 171.143,
    FIELD7: 173.615
  },
  15.11: {
    FIELD1: 151.296,
    FIELD2: 153.764,
    FIELD3: 157.889,
    FIELD4: 162.472,
    FIELD5: 167.055,
    FIELD6: 171.179,
    FIELD7: 173.648
  },
  '16.0': {
    FIELD1: 151.353,
    FIELD2: 153.818,
    FIELD3: 157.938,
    FIELD4: 162.516,
    FIELD5: 167.093,
    FIELD6: 171.213,
    FIELD7: 173.679
  },
  16.1: {
    FIELD1: 151.406,
    FIELD2: 153.869,
    FIELD3: 157.984,
    FIELD4: 162.556,
    FIELD5: 167.128,
    FIELD6: 171.243,
    FIELD7: 173.706
  },
  16.2: {
    FIELD1: 151.457,
    FIELD2: 153.917,
    FIELD3: 158.027,
    FIELD4: 162.593,
    FIELD5: 167.16,
    FIELD6: 171.27,
    FIELD7: 173.73
  },
  16.3: {
    FIELD1: 151.505,
    FIELD2: 153.962,
    FIELD3: 158.067,
    FIELD4: 162.628,
    FIELD5: 167.189,
    FIELD6: 171.294,
    FIELD7: 173.75
  },
  16.4: {
    FIELD1: 151.551,
    FIELD2: 154.004,
    FIELD3: 158.104,
    FIELD4: 162.659,
    FIELD5: 167.215,
    FIELD6: 171.315,
    FIELD7: 173.768
  },
  16.5: {
    FIELD1: 151.592,
    FIELD2: 154.043,
    FIELD3: 158.138,
    FIELD4: 162.689,
    FIELD5: 167.24,
    FIELD6: 171.335,
    FIELD7: 173.786
  },
  16.6: {
    FIELD1: 151.633,
    FIELD2: 154.081,
    FIELD3: 158.172,
    FIELD4: 162.716,
    FIELD5: 167.261,
    FIELD6: 171.352,
    FIELD7: 173.8
  },
  16.7: {
    FIELD1: 151.671,
    FIELD2: 154.116,
    FIELD3: 158.202,
    FIELD4: 162.742,
    FIELD5: 167.283,
    FIELD6: 171.369,
    FIELD7: 173.814
  },
  16.8: {
    FIELD1: 151.71,
    FIELD2: 154.152,
    FIELD3: 158.233,
    FIELD4: 162.767,
    FIELD5: 167.301,
    FIELD6: 171.382,
    FIELD7: 173.824
  },
  16.9: {
    FIELD1: 151.745,
    FIELD2: 154.185,
    FIELD3: 158.261,
    FIELD4: 162.79,
    FIELD5: 167.32,
    FIELD6: 171.396,
    FIELD7: 173.836
  },
  '16.10': {
    FIELD1: 151.782,
    FIELD2: 154.218,
    FIELD3: 158.289,
    FIELD4: 162.813,
    FIELD5: 167.336,
    FIELD6: 171.407,
    FIELD7: 173.843
  },
  16.11: {
    FIELD1: 151.815,
    FIELD2: 154.249,
    FIELD3: 158.316,
    FIELD4: 162.834,
    FIELD5: 167.352,
    FIELD6: 171.419,
    FIELD7: 173.853
  },
  '17.0': {
    FIELD1: 151.848,
    FIELD2: 154.279,
    FIELD3: 158.341,
    FIELD4: 162.854,
    FIELD5: 167.368,
    FIELD6: 171.43,
    FIELD7: 173.861
  },
  17.1: {
    FIELD1: 151.88,
    FIELD2: 154.308,
    FIELD3: 158.366,
    FIELD4: 162.874,
    FIELD5: 167.383,
    FIELD6: 171.441,
    FIELD7: 173.869
  },
  17.2: {
    FIELD1: 151.911,
    FIELD2: 154.337,
    FIELD3: 158.39,
    FIELD4: 162.894,
    FIELD5: 167.397,
    FIELD6: 171.45,
    FIELD7: 173.876
  },
  17.3: {
    FIELD1: 151.941,
    FIELD2: 154.365,
    FIELD3: 158.413,
    FIELD4: 162.912,
    FIELD5: 167.411,
    FIELD6: 171.459,
    FIELD7: 173.883
  },
  17.4: {
    FIELD1: 151.972,
    FIELD2: 154.392,
    FIELD3: 158.436,
    FIELD4: 162.93,
    FIELD5: 167.424,
    FIELD6: 171.468,
    FIELD7: 173.888
  },
  17.5: {
    FIELD1: 152.001,
    FIELD2: 154.419,
    FIELD3: 158.459,
    FIELD4: 162.948,
    FIELD5: 167.436,
    FIELD6: 171.476,
    FIELD7: 173.894
  },
  17.6: {
    FIELD1: 152.028,
    FIELD2: 154.444,
    FIELD3: 158.48,
    FIELD4: 162.965,
    FIELD5: 167.45,
    FIELD6: 171.486,
    FIELD7: 173.901
  },
  17.7: {
    FIELD1: 152.057,
    FIELD2: 154.47,
    FIELD3: 158.502,
    FIELD4: 162.982,
    FIELD5: 167.461,
    FIELD6: 171.493,
    FIELD7: 173.906
  },
  17.8: {
    FIELD1: 152.084,
    FIELD2: 154.494,
    FIELD3: 158.523,
    FIELD4: 162.998,
    FIELD5: 167.474,
    FIELD6: 171.502,
    FIELD7: 173.913
  },
  17.9: {
    FIELD1: 152.112,
    FIELD2: 154.52,
    FIELD3: 158.544,
    FIELD4: 163.014,
    FIELD5: 167.485,
    FIELD6: 171.509,
    FIELD7: 173.917
  },
  '17.10': {
    FIELD1: 152.137,
    FIELD2: 154.543,
    FIELD3: 158.563,
    FIELD4: 163.03,
    FIELD5: 167.497,
    FIELD6: 171.517,
    FIELD7: 173.923
  },
  17.11: {
    FIELD1: 152.162,
    FIELD2: 154.566,
    FIELD3: 158.582,
    FIELD4: 163.045,
    FIELD5: 167.508,
    FIELD6: 171.524,
    FIELD7: 173.92
  },
  '18.0': {
    FIELD1: 152.189,
    FIELD2: 154.59,
    FIELD3: 158.602,
    FIELD4: 163.06,
    FIELD5: 167.517,
    FIELD6: 171.529,
    FIELD7: 173.93
  },
  18.1: {
    FIELD1: 152.213,
    FIELD2: 154.611,
    FIELD3: 158.62,
    FIELD4: 163.073,
    FIELD5: 167.527,
    FIELD6: 171.535,
    FIELD7: 173.934
  },
  18.2: {
    FIELD1: 152.235,
    FIELD2: 154.632,
    FIELD3: 158.637,
    FIELD4: 163.086,
    FIELD5: 167.536,
    FIELD6: 171.54,
    FIELD7: 173.937
  },
  18.3: {
    FIELD1: 152.257,
    FIELD2: 154.652,
    FIELD3: 158.653,
    FIELD4: 163.098,
    FIELD5: 167.544,
    FIELD6: 171.545,
    FIELD7: 173.939
  },
  18.4: {
    FIELD1: 152.278,
    FIELD2: 154.671,
    FIELD3: 158.668,
    FIELD4: 163.109,
    FIELD5: 167.551,
    FIELD6: 171.548,
    FIELD7: 173.94
  },
  18.5: {
    FIELD1: 152.296,
    FIELD2: 154.686,
    FIELD3: 158.681,
    FIELD4: 163.119,
    FIELD5: 167.557,
    FIELD6: 171.552,
    FIELD7: 173.943
  },
  18.6: {
    FIELD1: 152.315,
    FIELD2: 154.703,
    FIELD3: 158.694,
    FIELD4: 163.128,
    FIELD5: 167.562,
    FIELD6: 171.553,
    FIELD7: 173.941
  },
  18.7: {
    FIELD1: 152.332,
    FIELD2: 154.718,
    FIELD3: 158.706,
    FIELD4: 163.136,
    FIELD5: 167.565,
    FIELD6: 171.553,
    FIELD7: 173.939
  },
  18.8: {
    FIELD1: 152.346,
    FIELD2: 154.731,
    FIELD3: 158.715,
    FIELD4: 163.142,
    FIELD5: 167.569,
    FIELD6: 171.553,
    FIELD7: 173.937
  },
  18.9: {
    FIELD1: 152.362,
    FIELD2: 154.744,
    FIELD3: 158.724,
    FIELD4: 163.147,
    FIELD5: 167.569,
    FIELD6: 171.55,
    FIELD7: 173.932
  },
  '18.10': {
    FIELD1: 152.373,
    FIELD2: 154.754,
    FIELD3: 158.731,
    FIELD4: 163.151,
    FIELD5: 167.57,
    FIELD6: 171.548,
    FIELD7: 173.928
  },
  18.11: {
    FIELD1: 152.387,
    FIELD2: 154.765,
    FIELD3: 158.738,
    FIELD4: 163.153,
    FIELD5: 167.568,
    FIELD6: 171.542,
    FIELD7: 173.92
  },
  '19.0': {
    FIELD1: 152.396,
    FIELD2: 154.772,
    FIELD3: 158.743,
    FIELD4: 163.155,
    FIELD5: 167.567,
    FIELD6: 171.537,
    FIELD7: 173.914
  }
};

export const BoyHeight: any = {
  '0.0': {
    FIELD1: 46.8,
    FIELD2: 47.5,
    FIELD3: 48.6,
    FIELD4: 49.9,
    FIELD5: 51.2,
    FIELD6: 52.3,
    FIELD7: 53
  },
  0.1: {
    FIELD1: 51.5,
    FIELD2: 52.2,
    FIELD3: 53.4,
    FIELD4: 54.7,
    FIELD5: 56,
    FIELD6: 57.2,
    FIELD7: 57.9
  },
  0.2: {
    FIELD1: 55.1,
    FIELD2: 55.9,
    FIELD3: 57.1,
    FIELD4: 58.4,
    FIELD5: 59.8,
    FIELD6: 61,
    FIELD7: 61.7
  },
  0.3: {
    FIELD1: 58.1,
    FIELD2: 58.8,
    FIELD3: 60.1,
    FIELD4: 61.4,
    FIELD5: 62.8,
    FIELD6: 64,
    FIELD7: 64.8
  },
  0.4: {
    FIELD1: 60.5,
    FIELD2: 61.2,
    FIELD3: 62.5,
    FIELD4: 63.9,
    FIELD5: 65.3,
    FIELD6: 66.6,
    FIELD7: 67.3
  },
  0.5: {
    FIELD1: 62.4,
    FIELD2: 63.2,
    FIELD3: 64.5,
    FIELD4: 65.9,
    FIELD5: 67.3,
    FIELD6: 68.6,
    FIELD7: 69.4
  },
  0.6: {
    FIELD1: 64.1,
    FIELD2: 64.9,
    FIELD3: 66.2,
    FIELD4: 67.6,
    FIELD5: 69.1,
    FIELD6: 70.4,
    FIELD7: 71.1
  },
  0.7: {
    FIELD1: 65.6,
    FIELD2: 66.4,
    FIELD3: 67.7,
    FIELD4: 69.2,
    FIELD5: 70.6,
    FIELD6: 71.9,
    FIELD7: 72.7
  },
  0.8: {
    FIELD1: 67,
    FIELD2: 67.8,
    FIELD3: 69.1,
    FIELD4: 70.6,
    FIELD5: 72.1,
    FIELD6: 73.4,
    FIELD7: 74.2
  },
  0.9: {
    FIELD1: 68.3,
    FIELD2: 69.1,
    FIELD3: 70.5,
    FIELD4: 72,
    FIELD5: 73.5,
    FIELD6: 74.8,
    FIELD7: 75.7
  },
  '0.10': {
    FIELD1: 69.5,
    FIELD2: 70.4,
    FIELD3: 71.7,
    FIELD4: 73.3,
    FIELD5: 74.8,
    FIELD6: 76.2,
    FIELD7: 77
  },
  0.11: {
    FIELD1: 70.7,
    FIELD2: 71.6,
    FIELD3: 73,
    FIELD4: 74.5,
    FIELD5: 76.1,
    FIELD6: 77.5,
    FIELD7: 78.4
  },
  '1.0': {
    FIELD1: 71.8,
    FIELD2: 72.7,
    FIELD3: 74.1,
    FIELD4: 75.7,
    FIELD5: 77.4,
    FIELD6: 78.8,
    FIELD7: 79.7
  },
  1.1: {
    FIELD1: 72.9,
    FIELD2: 73.8,
    FIELD3: 75.3,
    FIELD4: 76.9,
    FIELD5: 78.6,
    FIELD6: 80,
    FIELD7: 80.9
  },
  1.2: {
    FIELD1: 74,
    FIELD2: 74.9,
    FIELD3: 76.4,
    FIELD4: 78,
    FIELD5: 79.7,
    FIELD6: 81.2,
    FIELD7: 82.1
  },
  1.3: {
    FIELD1: 75,
    FIELD2: 75.9,
    FIELD3: 77.4,
    FIELD4: 79.1,
    FIELD5: 80.9,
    FIELD6: 82.4,
    FIELD7: 83.3
  },
  1.4: {
    FIELD1: 76,
    FIELD2: 76.9,
    FIELD3: 78.5,
    FIELD4: 80.2,
    FIELD5: 82,
    FIELD6: 83.5,
    FIELD7: 84.5
  },
  1.5: {
    FIELD1: 76.9,
    FIELD2: 77.9,
    FIELD3: 79.5,
    FIELD4: 81.2,
    FIELD5: 83,
    FIELD6: 84.6,
    FIELD7: 85.6
  },
  1.6: {
    FIELD1: 77.8,
    FIELD2: 78.8,
    FIELD3: 80.4,
    FIELD4: 82.3,
    FIELD5: 84.1,
    FIELD6: 85.7,
    FIELD7: 86.7
  },
  1.7: {
    FIELD1: 78.7,
    FIELD2: 79.7,
    FIELD3: 81.4,
    FIELD4: 83.2,
    FIELD5: 85.1,
    FIELD6: 86.8,
    FIELD7: 87.8
  },
  1.8: {
    FIELD1: 79.6,
    FIELD2: 80.6,
    FIELD3: 82.3,
    FIELD4: 84.2,
    FIELD5: 86.1,
    FIELD6: 87.8,
    FIELD7: 88.8
  },
  1.9: {
    FIELD1: 80.4,
    FIELD2: 81.5,
    FIELD3: 83.2,
    FIELD4: 85.1,
    FIELD5: 87.1,
    FIELD6: 88.8,
    FIELD7: 89.9
  },
  '1.10': {
    FIELD1: 81.2,
    FIELD2: 82.3,
    FIELD3: 84.1,
    FIELD4: 86,
    FIELD5: 88,
    FIELD6: 89.8,
    FIELD7: 90.9
  },
  1.11: {
    FIELD1: 82,
    FIELD2: 83.1,
    FIELD3: 84.9,
    FIELD4: 86.9,
    FIELD5: 89,
    FIELD6: 90.8,
    FIELD7: 91.9
  },
  '2.0': {
    FIELD1: 82.1,
    FIELD2: 83.2,
    FIELD3: 85.1,
    FIELD4: 87.1,
    FIELD5: 89.2,
    FIELD6: 91,
    FIELD7: 92.1
  },
  2.1: {
    FIELD1: 82.8,
    FIELD2: 84,
    FIELD3: 85.9,
    FIELD4: 88,
    FIELD5: 90.1,
    FIELD6: 92,
    FIELD7: 93.1
  },
  2.2: {
    FIELD1: 83.6,
    FIELD2: 84.7,
    FIELD3: 86.7,
    FIELD4: 88.8,
    FIELD5: 90.9,
    FIELD6: 92.9,
    FIELD7: 94
  },
  2.3: {
    FIELD1: 84.3,
    FIELD2: 85.5,
    FIELD3: 87.4,
    FIELD4: 89.6,
    FIELD5: 91.8,
    FIELD6: 93.8,
    FIELD7: 94.9
  },
  2.4: {
    FIELD1: 85,
    FIELD2: 86.2,
    FIELD3: 88.2,
    FIELD4: 90.4,
    FIELD5: 92.6,
    FIELD6: 94.6,
    FIELD7: 95.8
  },
  2.5: {
    FIELD1: 85.7,
    FIELD2: 86.9,
    FIELD3: 88.9,
    FIELD4: 91.2,
    FIELD5: 93.4,
    FIELD6: 95.5,
    FIELD7: 96.7
  },
  2.6: {
    FIELD1: 86.3,
    FIELD2: 87.6,
    FIELD3: 89.6,
    FIELD4: 91.9,
    FIELD5: 94.2,
    FIELD6: 96.3,
    FIELD7: 97.5
  },
  2.7: {
    FIELD1: 87,
    FIELD2: 88.2,
    FIELD3: 90.3,
    FIELD4: 92.7,
    FIELD5: 95,
    FIELD6: 97.1,
    FIELD7: 98.4
  },
  2.8: {
    FIELD1: 87.6,
    FIELD2: 88.9,
    FIELD3: 91,
    FIELD4: 93.4,
    FIELD5: 95.7,
    FIELD6: 97.9,
    FIELD7: 99.2
  },
  2.9: {
    FIELD1: 88.2,
    FIELD2: 89.5,
    FIELD3: 91.7,
    FIELD4: 94.1,
    FIELD5: 96.5,
    FIELD6: 98.6,
    FIELD7: 99.9
  },
  '2.10': {
    FIELD1: 88.8,
    FIELD2: 90.1,
    FIELD3: 92.3,
    FIELD4: 94.8,
    FIELD5: 97.2,
    FIELD6: 99.4,
    FIELD7: 100.7
  },
  2.11: {
    FIELD1: 89.4,
    FIELD2: 90.7,
    FIELD3: 93,
    FIELD4: 95.4,
    FIELD5: 97.9,
    FIELD6: 100.1,
    FIELD7: 101.4
  },
  '3.0': {
    FIELD1: 90,
    FIELD2: 91.3,
    FIELD3: 93.6,
    FIELD4: 96.1,
    FIELD5: 98.6,
    FIELD6: 100.8,
    FIELD7: 102.2
  },
  3.1: {
    FIELD1: 90.6,
    FIELD2: 91.9,
    FIELD3: 94.2,
    FIELD4: 96.7,
    FIELD5: 99.3,
    FIELD6: 101.5,
    FIELD7: 102.9
  },
  3.2: {
    FIELD1: 91.1,
    FIELD2: 92.5,
    FIELD3: 94.8,
    FIELD4: 97.4,
    FIELD5: 99.9,
    FIELD6: 102.2,
    FIELD7: 103.6
  },
  3.3: {
    FIELD1: 91.7,
    FIELD2: 93.1,
    FIELD3: 95.4,
    FIELD4: 98,
    FIELD5: 100.6,
    FIELD6: 102.9,
    FIELD7: 104.3
  },
  3.4: {
    FIELD1: 92.2,
    FIELD2: 93.7,
    FIELD3: 96,
    FIELD4: 98.6,
    FIELD5: 101.3,
    FIELD6: 103.6,
    FIELD7: 105
  },
  3.5: {
    FIELD1: 92.8,
    FIELD2: 94.2,
    FIELD3: 96.6,
    FIELD4: 99.2,
    FIELD5: 101.9,
    FIELD6: 104.3,
    FIELD7: 105.7
  },
  3.6: {
    FIELD1: 93.3,
    FIELD2: 94.8,
    FIELD3: 97.2,
    FIELD4: 99.9,
    FIELD5: 102.5,
    FIELD6: 104.9,
    FIELD7: 106.4
  },
  3.7: {
    FIELD1: 93.9,
    FIELD2: 95.3,
    FIELD3: 97.7,
    FIELD4: 100.4,
    FIELD5: 103.1,
    FIELD6: 105.6,
    FIELD7: 107
  },
  3.8: {
    FIELD1: 94.4,
    FIELD2: 95.9,
    FIELD3: 98.3,
    FIELD4: 101,
    FIELD5: 103.8,
    FIELD6: 106.2,
    FIELD7: 107.7
  },
  3.9: {
    FIELD1: 94.9,
    FIELD2: 96.4,
    FIELD3: 98.9,
    FIELD4: 101.6,
    FIELD5: 104.4,
    FIELD6: 106.8,
    FIELD7: 108.3
  },
  '3.10': {
    FIELD1: 95.4,
    FIELD2: 96.9,
    FIELD3: 99.4,
    FIELD4: 102.2,
    FIELD5: 105,
    FIELD6: 107.5,
    FIELD7: 109
  },
  3.11: {
    FIELD1: 95.9,
    FIELD2: 97.4,
    FIELD3: 100,
    FIELD4: 102.8,
    FIELD5: 105.6,
    FIELD6: 108.1,
    FIELD7: 109.6
  },
  '4.0': {
    FIELD1: 96.4,
    FIELD2: 98,
    FIELD3: 100.5,
    FIELD4: 103.3,
    FIELD5: 106.2,
    FIELD6: 108.7,
    FIELD7: 110.2
  },
  4.1: {
    FIELD1: 96.9,
    FIELD2: 98.5,
    FIELD3: 101,
    FIELD4: 103.9,
    FIELD5: 106.7,
    FIELD6: 109.3,
    FIELD7: 110.8
  },
  4.2: {
    FIELD1: 97.4,
    FIELD2: 99,
    FIELD3: 101.6,
    FIELD4: 104.4,
    FIELD5: 107.3,
    FIELD6: 109.9,
    FIELD7: 111.5
  },
  4.3: {
    FIELD1: 97.9,
    FIELD2: 99.5,
    FIELD3: 102.1,
    FIELD4: 105,
    FIELD5: 107.9,
    FIELD6: 110.5,
    FIELD7: 112.1
  },
  4.4: {
    FIELD1: 98.4,
    FIELD2: 100,
    FIELD3: 102.6,
    FIELD4: 105.6,
    FIELD5: 108.5,
    FIELD6: 111.1,
    FIELD7: 112.7
  },
  4.5: {
    FIELD1: 98.9,
    FIELD2: 100.5,
    FIELD3: 103.2,
    FIELD4: 106.1,
    FIELD5: 109.1,
    FIELD6: 111.7,
    FIELD7: 113.3
  },
  4.6: {
    FIELD1: 99.4,
    FIELD2: 101,
    FIELD3: 103.7,
    FIELD4: 106.7,
    FIELD5: 109.6,
    FIELD6: 112.3,
    FIELD7: 113.9
  },
  4.7: {
    FIELD1: 99.9,
    FIELD2: 101.5,
    FIELD3: 104.2,
    FIELD4: 107.2,
    FIELD5: 110.2,
    FIELD6: 112.9,
    FIELD7: 114.5
  },
  4.8: {
    FIELD1: 100.4,
    FIELD2: 102,
    FIELD3: 104.7,
    FIELD4: 107.8,
    FIELD5: 110.8,
    FIELD6: 113.5,
    FIELD7: 115.2
  },
  4.9: {
    FIELD1: 100.9,
    FIELD2: 102.5,
    FIELD3: 105.3,
    FIELD4: 108.3,
    FIELD5: 111.4,
    FIELD6: 114.1,
    FIELD7: 115.8
  },
  '4.10': {
    FIELD1: 101.4,
    FIELD2: 103,
    FIELD3: 105.8,
    FIELD4: 108.9,
    FIELD5: 111.9,
    FIELD6: 114.7,
    FIELD7: 116.4
  },
  4.11: {
    FIELD1: 101.9,
    FIELD2: 103.5,
    FIELD3: 106.3,
    FIELD4: 109.4,
    FIELD5: 112.5,
    FIELD6: 115.3,
    FIELD7: 117
  },
  '5.0': {
    FIELD1: 102.3,
    FIELD2: 104,
    FIELD3: 106.8,
    FIELD4: 110,
    FIELD5: 113.1,
    FIELD6: 115.9,
    FIELD7: 117.6
  },
  5.1: {
    FIELD1: 102.7,
    FIELD2: 104.4,
    FIELD3: 107.2,
    FIELD4: 110.3,
    FIELD5: 113.4,
    FIELD6: 116.1,
    FIELD7: 117.8
  },
  5.2: {
    FIELD1: 103.2,
    FIELD2: 104.9,
    FIELD3: 107.7,
    FIELD4: 110.8,
    FIELD5: 113.9,
    FIELD6: 116.7,
    FIELD7: 118.4
  },
  5.3: {
    FIELD1: 103.7,
    FIELD2: 105.4,
    FIELD3: 108.2,
    FIELD4: 111.3,
    FIELD5: 114.5,
    FIELD6: 117.3,
    FIELD7: 119
  },
  5.4: {
    FIELD1: 104.2,
    FIELD2: 105.9,
    FIELD3: 108.7,
    FIELD4: 111.9,
    FIELD5: 115,
    FIELD6: 117.9,
    FIELD7: 119.6
  },
  5.5: {
    FIELD1: 104.6,
    FIELD2: 106.3,
    FIELD3: 109.2,
    FIELD4: 112.4,
    FIELD5: 115.6,
    FIELD6: 118.4,
    FIELD7: 120.1
  },
  5.6: {
    FIELD1: 105.1,
    FIELD2: 106.8,
    FIELD3: 109.7,
    FIELD4: 112.9,
    FIELD5: 116.1,
    FIELD6: 119,
    FIELD7: 120.7
  },
  5.7: {
    FIELD1: 105.6,
    FIELD2: 107.3,
    FIELD3: 110.2,
    FIELD4: 113.4,
    FIELD5: 116.7,
    FIELD6: 119.5,
    FIELD7: 121.3
  },
  5.8: {
    FIELD1: 106,
    FIELD2: 107.8,
    FIELD3: 110.7,
    FIELD4: 113.9,
    FIELD5: 117.2,
    FIELD6: 120.1,
    FIELD7: 121.8
  },
  5.9: {
    FIELD1: 106.5,
    FIELD2: 108.3,
    FIELD3: 111.2,
    FIELD4: 114.5,
    FIELD5: 117.7,
    FIELD6: 120.6,
    FIELD7: 122.4
  },
  '5.10': {
    FIELD1: 106.9,
    FIELD2: 108.7,
    FIELD3: 111.7,
    FIELD4: 115,
    FIELD5: 118.2,
    FIELD6: 121.2,
    FIELD7: 123
  },
  5.11: {
    FIELD1: 107.4,
    FIELD2: 109.2,
    FIELD3: 112.2,
    FIELD4: 115.5,
    FIELD5: 118.8,
    FIELD6: 121.7,
    FIELD7: 123.5
  },
  '6.0': {
    FIELD1: 107.8,
    FIELD2: 109.6,
    FIELD3: 112.6,
    FIELD4: 116,
    FIELD5: 119.3,
    FIELD6: 122.3,
    FIELD7: 124.1
  },
  6.1: {
    FIELD1: 108.3,
    FIELD2: 110.1,
    FIELD3: 113.1,
    FIELD4: 116.4,
    FIELD5: 119.8,
    FIELD6: 122.8,
    FIELD7: 124.6
  },
  6.2: {
    FIELD1: 108.7,
    FIELD2: 110.5,
    FIELD3: 113.6,
    FIELD4: 116.9,
    FIELD5: 120.3,
    FIELD6: 123.3,
    FIELD7: 125.1
  },
  6.3: {
    FIELD1: 109.2,
    FIELD2: 111,
    FIELD3: 114,
    FIELD4: 117.4,
    FIELD5: 120.8,
    FIELD6: 123.8,
    FIELD7: 125.7
  },
  6.4: {
    FIELD1: 109.6,
    FIELD2: 111.4,
    FIELD3: 114.5,
    FIELD4: 117.9,
    FIELD5: 121.3,
    FIELD6: 124.4,
    FIELD7: 126.2
  },
  6.5: {
    FIELD1: 110,
    FIELD2: 111.9,
    FIELD3: 115,
    FIELD4: 118.4,
    FIELD5: 121.8,
    FIELD6: 124.9,
    FIELD7: 126.7
  },
  6.6: {
    FIELD1: 110.5,
    FIELD2: 112.3,
    FIELD3: 115.4,
    FIELD4: 118.9,
    FIELD5: 122.3,
    FIELD6: 125.4,
    FIELD7: 127.3
  },
  6.7: {
    FIELD1: 110.9,
    FIELD2: 112.8,
    FIELD3: 115.9,
    FIELD4: 119.4,
    FIELD5: 122.8,
    FIELD6: 125.9,
    FIELD7: 127.8
  },
  6.8: {
    FIELD1: 111.3,
    FIELD2: 113.2,
    FIELD3: 116.3,
    FIELD4: 119.8,
    FIELD5: 123.3,
    FIELD6: 126.5,
    FIELD7: 128.3
  },
  6.9: {
    FIELD1: 111.8,
    FIELD2: 113.7,
    FIELD3: 116.8,
    FIELD4: 120.3,
    FIELD5: 123.8,
    FIELD6: 127,
    FIELD7: 128.9
  },
  '6.10': {
    FIELD1: 112.2,
    FIELD2: 114.1,
    FIELD3: 117.3,
    FIELD4: 120.8,
    FIELD5: 124.3,
    FIELD6: 127.5,
    FIELD7: 129.4
  },
  6.11: {
    FIELD1: 112.6,
    FIELD2: 114.5,
    FIELD3: 117.7,
    FIELD4: 121.3,
    FIELD5: 124.8,
    FIELD6: 128,
    FIELD7: 129.9
  },
  '7.0': {
    FIELD1: 113,
    FIELD2: 115,
    FIELD3: 118.2,
    FIELD4: 121.7,
    FIELD5: 125.3,
    FIELD6: 128.5,
    FIELD7: 130.4
  },
  7.1: {
    FIELD1: 113.5,
    FIELD2: 115.4,
    FIELD3: 118.6,
    FIELD4: 122.2,
    FIELD5: 125.8,
    FIELD6: 129,
    FIELD7: 130.9
  },
  7.2: {
    FIELD1: 113.9,
    FIELD2: 115.8,
    FIELD3: 119.1,
    FIELD4: 122.7,
    FIELD5: 126.3,
    FIELD6: 129.5,
    FIELD7: 131.5
  },
  7.3: {
    FIELD1: 114.3,
    FIELD2: 116.3,
    FIELD3: 119.5,
    FIELD4: 123.1,
    FIELD5: 126.8,
    FIELD6: 130,
    FIELD7: 132
  },
  7.4: {
    FIELD1: 114.7,
    FIELD2: 116.7,
    FIELD3: 120,
    FIELD4: 123.6,
    FIELD5: 127.3,
    FIELD6: 130.5,
    FIELD7: 132.5
  },
  7.5: {
    FIELD1: 115.1,
    FIELD2: 117.1,
    FIELD3: 120.4,
    FIELD4: 124.1,
    FIELD5: 127.7,
    FIELD6: 131,
    FIELD7: 133
  },
  7.6: {
    FIELD1: 115.5,
    FIELD2: 117.5,
    FIELD3: 120.8,
    FIELD4: 124.5,
    FIELD5: 128.2,
    FIELD6: 131.5,
    FIELD7: 133.5
  },
  7.7: {
    FIELD1: 116,
    FIELD2: 118,
    FIELD3: 121.3,
    FIELD4: 125,
    FIELD5: 128.7,
    FIELD6: 132,
    FIELD7: 134
  },
  7.8: {
    FIELD1: 116.4,
    FIELD2: 118.4,
    FIELD3: 121.7,
    FIELD4: 125.5,
    FIELD5: 129.2,
    FIELD6: 132.5,
    FIELD7: 134.5
  },
  7.9: {
    FIELD1: 116.8,
    FIELD2: 118.8,
    FIELD3: 122.2,
    FIELD4: 125.9,
    FIELD5: 129.7,
    FIELD6: 133,
    FIELD7: 135.1
  },
  '7.10': {
    FIELD1: 117.2,
    FIELD2: 119.2,
    FIELD3: 122.6,
    FIELD4: 126.4,
    FIELD5: 130.1,
    FIELD6: 133.5,
    FIELD7: 135.6
  },
  7.11: {
    FIELD1: 117.6,
    FIELD2: 119.6,
    FIELD3: 123,
    FIELD4: 126.8,
    FIELD5: 130.6,
    FIELD6: 134,
    FIELD7: 136.1
  },
  '8.0': {
    FIELD1: 118,
    FIELD2: 120,
    FIELD3: 123.5,
    FIELD4: 127.3,
    FIELD5: 131.1,
    FIELD6: 134.5,
    FIELD7: 136.6
  },
  8.1: {
    FIELD1: 118.4,
    FIELD2: 120.4,
    FIELD3: 123.9,
    FIELD4: 127.7,
    FIELD5: 131.5,
    FIELD6: 135,
    FIELD7: 137.1
  },
  8.2: {
    FIELD1: 118.8,
    FIELD2: 120.8,
    FIELD3: 124.3,
    FIELD4: 128.2,
    FIELD5: 132,
    FIELD6: 135.5,
    FIELD7: 137.5
  },
  8.3: {
    FIELD1: 119.2,
    FIELD2: 121.2,
    FIELD3: 124.7,
    FIELD4: 128.6,
    FIELD5: 132.5,
    FIELD6: 136,
    FIELD7: 138
  },
  8.4: {
    FIELD1: 119.6,
    FIELD2: 121.7,
    FIELD3: 125.2,
    FIELD4: 129,
    FIELD5: 132.9,
    FIELD6: 136.4,
    FIELD7: 138.5
  },
  8.5: {
    FIELD1: 120,
    FIELD2: 122.1,
    FIELD3: 125.6,
    FIELD4: 129.5,
    FIELD5: 133.4,
    FIELD6: 136.9,
    FIELD7: 139
  },
  8.6: {
    FIELD1: 120.3,
    FIELD2: 122.5,
    FIELD3: 126,
    FIELD4: 129.9,
    FIELD5: 133.9,
    FIELD6: 137.4,
    FIELD7: 139.5
  },
  8.7: {
    FIELD1: 120.7,
    FIELD2: 122.9,
    FIELD3: 126.4,
    FIELD4: 130.4,
    FIELD5: 134.3,
    FIELD6: 137.9,
    FIELD7: 140
  },
  8.8: {
    FIELD1: 121.1,
    FIELD2: 123.3,
    FIELD3: 126.8,
    FIELD4: 130.8,
    FIELD5: 134.8,
    FIELD6: 138.4,
    FIELD7: 140.5
  },
  8.9: {
    FIELD1: 121.5,
    FIELD2: 123.7,
    FIELD3: 127.3,
    FIELD4: 131.3,
    FIELD5: 135.2,
    FIELD6: 138.8,
    FIELD7: 141
  },
  '8.10': {
    FIELD1: 121.9,
    FIELD2: 124.1,
    FIELD3: 127.7,
    FIELD4: 131.7,
    FIELD5: 135.7,
    FIELD6: 139.3,
    FIELD7: 141.5
  },
  8.11: {
    FIELD1: 122.3,
    FIELD2: 124.5,
    FIELD3: 128.1,
    FIELD4: 132.1,
    FIELD5: 136.2,
    FIELD6: 139.8,
    FIELD7: 142
  },
  '9.0': {
    FIELD1: 122.7,
    FIELD2: 124.9,
    FIELD3: 128.5,
    FIELD4: 132.6,
    FIELD5: 136.6,
    FIELD6: 140.3,
    FIELD7: 142.5
  },
  9.1: {
    FIELD1: 123.1,
    FIELD2: 125.3,
    FIELD3: 128.9,
    FIELD4: 133,
    FIELD5: 137.1,
    FIELD6: 140.7,
    FIELD7: 142.9
  },
  9.2: {
    FIELD1: 123.5,
    FIELD2: 125.7,
    FIELD3: 129.3,
    FIELD4: 133.4,
    FIELD5: 137.5,
    FIELD6: 141.2,
    FIELD7: 143.4
  },
  9.3: {
    FIELD1: 123.8,
    FIELD2: 126.1,
    FIELD3: 129.8,
    FIELD4: 133.9,
    FIELD5: 138,
    FIELD6: 141.7,
    FIELD7: 143.9
  },
  9.4: {
    FIELD1: 124.2,
    FIELD2: 126.5,
    FIELD3: 130.2,
    FIELD4: 134.3,
    FIELD5: 138.4,
    FIELD6: 142.2,
    FIELD7: 144.4
  },
  9.5: {
    FIELD1: 124.6,
    FIELD2: 126.9,
    FIELD3: 130.6,
    FIELD4: 134.7,
    FIELD5: 138.9,
    FIELD6: 142.6,
    FIELD7: 144.9
  },
  9.6: {
    FIELD1: 125,
    FIELD2: 127.2,
    FIELD3: 131,
    FIELD4: 135.2,
    FIELD5: 139.4,
    FIELD6: 143.1,
    FIELD7: 145.4
  },
  9.7: {
    FIELD1: 125.4,
    FIELD2: 127.6,
    FIELD3: 131.4,
    FIELD4: 135.6,
    FIELD5: 139.8,
    FIELD6: 143.6,
    FIELD7: 145.9
  },
  9.8: {
    FIELD1: 125.8,
    FIELD2: 128,
    FIELD3: 131.8,
    FIELD4: 136.1,
    FIELD5: 140.3,
    FIELD6: 144.1,
    FIELD7: 146.3
  },
  9.9: {
    FIELD1: 126.1,
    FIELD2: 128.4,
    FIELD3: 132.2,
    FIELD4: 136.5,
    FIELD5: 140.7,
    FIELD6: 144.5,
    FIELD7: 146.8
  },
  '9.10': {
    FIELD1: 126.5,
    FIELD2: 128.8,
    FIELD3: 132.7,
    FIELD4: 136.9,
    FIELD5: 141.2,
    FIELD6: 145,
    FIELD7: 147.3
  },
  9.11: {
    FIELD1: 126.9,
    FIELD2: 129.2,
    FIELD3: 133.1,
    FIELD4: 137.3,
    FIELD5: 141.6,
    FIELD6: 145.5,
    FIELD7: 147.8
  },
  '10.0': {
    FIELD1: 127.3,
    FIELD2: 129.6,
    FIELD3: 133.5,
    FIELD4: 137.8,
    FIELD5: 142.1,
    FIELD6: 145.9,
    FIELD7: 148.3
  },
  10.1: {
    FIELD1: 127.7,
    FIELD2: 130,
    FIELD3: 133.9,
    FIELD4: 138.2,
    FIELD5: 142.5,
    FIELD6: 146.4,
    FIELD7: 148.7
  },
  10.2: {
    FIELD1: 128.1,
    FIELD2: 130.4,
    FIELD3: 134.3,
    FIELD4: 138.6,
    FIELD5: 143,
    FIELD6: 146.9,
    FIELD7: 149.2
  },
  10.3: {
    FIELD1: 128.4,
    FIELD2: 130.8,
    FIELD3: 134.7,
    FIELD4: 139.1,
    FIELD5: 143.4,
    FIELD6: 147.4,
    FIELD7: 149.7
  },
  10.4: {
    FIELD1: 128.8,
    FIELD2: 131.2,
    FIELD3: 135.1,
    FIELD4: 139.5,
    FIELD5: 143.9,
    FIELD6: 147.8,
    FIELD7: 150.2
  },
  10.5: {
    FIELD1: 129.2,
    FIELD2: 131.6,
    FIELD3: 135.6,
    FIELD4: 140,
    FIELD5: 144.4,
    FIELD6: 148.3,
    FIELD7: 150.7
  },
  10.6: {
    FIELD1: 129.6,
    FIELD2: 132,
    FIELD3: 136,
    FIELD4: 140.4,
    FIELD5: 144.8,
    FIELD6: 148.8,
    FIELD7: 151.2
  },
  10.7: {
    FIELD1: 130,
    FIELD2: 132.4,
    FIELD3: 136.4,
    FIELD4: 140.8,
    FIELD5: 145.3,
    FIELD6: 149.3,
    FIELD7: 151.7
  },
  10.8: {
    FIELD1: 130.4,
    FIELD2: 132.8,
    FIELD3: 136.8,
    FIELD4: 141.3,
    FIELD5: 145.7,
    FIELD6: 149.8,
    FIELD7: 152.2
  },
  10.9: {
    FIELD1: 130.8,
    FIELD2: 133.2,
    FIELD3: 137.3,
    FIELD4: 141.7,
    FIELD5: 146.2,
    FIELD6: 150.2,
    FIELD7: 152.7
  },
  '10.10': {
    FIELD1: 131.2,
    FIELD2: 133.6,
    FIELD3: 137.7,
    FIELD4: 142.2,
    FIELD5: 146.7,
    FIELD6: 150.7,
    FIELD7: 153.2
  },
  10.11: {
    FIELD1: 131.6,
    FIELD2: 134.1,
    FIELD3: 138.1,
    FIELD4: 142.7,
    FIELD5: 147.2,
    FIELD6: 151.2,
    FIELD7: 153.7
  },
  '11.0': {
    FIELD1: 132,
    FIELD2: 134.5,
    FIELD3: 138.6,
    FIELD4: 143.1,
    FIELD5: 147.7,
    FIELD6: 151.7,
    FIELD7: 154.2
  },
  11.1: {
    FIELD1: 132.5,
    FIELD2: 134.9,
    FIELD3: 139,
    FIELD4: 143.6,
    FIELD5: 148.1,
    FIELD6: 152.2,
    FIELD7: 154.7
  },
  11.2: {
    FIELD1: 132.9,
    FIELD2: 135.3,
    FIELD3: 139.5,
    FIELD4: 144.1,
    FIELD5: 148.6,
    FIELD6: 152.8,
    FIELD7: 155.2
  },
  11.3: {
    FIELD1: 133.3,
    FIELD2: 135.8,
    FIELD3: 139.9,
    FIELD4: 144.5,
    FIELD5: 149.1,
    FIELD6: 153.3,
    FIELD7: 155.7
  },
  11.4: {
    FIELD1: 133.7,
    FIELD2: 136.2,
    FIELD3: 140.4,
    FIELD4: 145,
    FIELD5: 149.6,
    FIELD6: 153.8,
    FIELD7: 156.3
  },
  11.5: {
    FIELD1: 134.2,
    FIELD2: 136.7,
    FIELD3: 140.9,
    FIELD4: 145.5,
    FIELD5: 150.1,
    FIELD6: 154.3,
    FIELD7: 156.8
  },
  11.6: {
    FIELD1: 134.6,
    FIELD2: 137.1,
    FIELD3: 141.3,
    FIELD4: 146,
    FIELD5: 150.6,
    FIELD6: 154.8,
    FIELD7: 157.4
  },
  11.7: {
    FIELD1: 135.1,
    FIELD2: 137.6,
    FIELD3: 141.8,
    FIELD4: 146.5,
    FIELD5: 151.2,
    FIELD6: 155.4,
    FIELD7: 157.9
  },
  11.8: {
    FIELD1: 135.5,
    FIELD2: 138.1,
    FIELD3: 142.3,
    FIELD4: 147,
    FIELD5: 151.7,
    FIELD6: 155.9,
    FIELD7: 158.5
  },
  11.9: {
    FIELD1: 136,
    FIELD2: 138.5,
    FIELD3: 142.8,
    FIELD4: 147.5,
    FIELD5: 152.2,
    FIELD6: 156.5,
    FIELD7: 159
  },
  '11.10': {
    FIELD1: 136.5,
    FIELD2: 139,
    FIELD3: 143.3,
    FIELD4: 148,
    FIELD5: 152.8,
    FIELD6: 157,
    FIELD7: 159.6
  },
  11.11: {
    FIELD1: 136.9,
    FIELD2: 139.5,
    FIELD3: 143.8,
    FIELD4: 148.5,
    FIELD5: 153.3,
    FIELD6: 157.6,
    FIELD7: 160.2
  },
  '12.0': {
    FIELD1: 137.4,
    FIELD2: 140,
    FIELD3: 144.3,
    FIELD4: 149.1,
    FIELD5: 153.9,
    FIELD6: 158.2,
    FIELD7: 160.7
  },
  12.1: {
    FIELD1: 137.9,
    FIELD2: 140.5,
    FIELD3: 144.8,
    FIELD4: 149.6,
    FIELD5: 154.4,
    FIELD6: 158.7,
    FIELD7: 161.3
  },
  12.2: {
    FIELD1: 138.4,
    FIELD2: 141,
    FIELD3: 145.4,
    FIELD4: 150.2,
    FIELD5: 155,
    FIELD6: 159.3,
    FIELD7: 161.9
  },
  12.3: {
    FIELD1: 138.9,
    FIELD2: 141.5,
    FIELD3: 145.9,
    FIELD4: 150.7,
    FIELD5: 155.6,
    FIELD6: 159.9,
    FIELD7: 162.5
  },
  12.4: {
    FIELD1: 139.4,
    FIELD2: 142.1,
    FIELD3: 146.4,
    FIELD4: 151.3,
    FIELD5: 156.1,
    FIELD6: 160.5,
    FIELD7: 163.1
  },
  12.5: {
    FIELD1: 140,
    FIELD2: 142.6,
    FIELD3: 147,
    FIELD4: 151.9,
    FIELD5: 156.7,
    FIELD6: 161.1,
    FIELD7: 163.8
  },
  12.6: {
    FIELD1: 140.5,
    FIELD2: 143.1,
    FIELD3: 147.5,
    FIELD4: 152.4,
    FIELD5: 157.3,
    FIELD6: 161.7,
    FIELD7: 164.4
  },
  12.7: {
    FIELD1: 141,
    FIELD2: 143.7,
    FIELD3: 148.1,
    FIELD4: 153,
    FIELD5: 157.9,
    FIELD6: 162.4,
    FIELD7: 165
  },
  12.8: {
    FIELD1: 141.6,
    FIELD2: 144.2,
    FIELD3: 148.7,
    FIELD4: 153.6,
    FIELD5: 158.6,
    FIELD6: 163,
    FIELD7: 165.7
  },
  12.9: {
    FIELD1: 142.1,
    FIELD2: 144.8,
    FIELD3: 149.3,
    FIELD4: 154.2,
    FIELD5: 159.2,
    FIELD6: 163.6,
    FIELD7: 166.3
  },
  '12.10': {
    FIELD1: 142.7,
    FIELD2: 145.4,
    FIELD3: 149.9,
    FIELD4: 154.8,
    FIELD5: 159.8,
    FIELD6: 164.3,
    FIELD7: 167
  },
  12.11: {
    FIELD1: 143.3,
    FIELD2: 145.9,
    FIELD3: 150.4,
    FIELD4: 155.4,
    FIELD5: 160.4,
    FIELD6: 164.9,
    FIELD7: 167.6
  },
  '13.0': {
    FIELD1: 143.8,
    FIELD2: 146.5,
    FIELD3: 151,
    FIELD4: 156,
    FIELD5: 161.1,
    FIELD6: 165.6,
    FIELD7: 168.3
  },
  13.1: {
    FIELD1: 144.4,
    FIELD2: 147.1,
    FIELD3: 151.6,
    FIELD4: 156.7,
    FIELD5: 161.7,
    FIELD6: 166.2,
    FIELD7: 168.9
  },
  13.2: {
    FIELD1: 145,
    FIELD2: 147.7,
    FIELD3: 152.2,
    FIELD4: 157.3,
    FIELD5: 162.3,
    FIELD6: 166.9,
    FIELD7: 169.6
  },
  13.3: {
    FIELD1: 145.5,
    FIELD2: 148.3,
    FIELD3: 152.8,
    FIELD4: 157.9,
    FIELD5: 162.9,
    FIELD6: 167.5,
    FIELD7: 170.2
  },
  13.4: {
    FIELD1: 146.1,
    FIELD2: 148.8,
    FIELD3: 153.4,
    FIELD4: 158.5,
    FIELD5: 163.6,
    FIELD6: 168.1,
    FIELD7: 170.9
  },
  13.5: {
    FIELD1: 146.7,
    FIELD2: 149.4,
    FIELD3: 154,
    FIELD4: 159.1,
    FIELD5: 164.2,
    FIELD6: 168.8,
    FIELD7: 171.5
  },
  13.6: {
    FIELD1: 147.2,
    FIELD2: 150,
    FIELD3: 154.6,
    FIELD4: 159.7,
    FIELD5: 164.8,
    FIELD6: 169.4,
    FIELD7: 172.2
  },
  13.7: {
    FIELD1: 147.8,
    FIELD2: 150.6,
    FIELD3: 155.2,
    FIELD4: 160.3,
    FIELD5: 165.4,
    FIELD6: 170,
    FIELD7: 172.8
  },
  13.8: {
    FIELD1: 148.4,
    FIELD2: 151.1,
    FIELD3: 155.7,
    FIELD4: 160.9,
    FIELD5: 166,
    FIELD6: 170.6,
    FIELD7: 173.4
  },
  13.9: {
    FIELD1: 148.9,
    FIELD2: 151.7,
    FIELD3: 156.3,
    FIELD4: 161.5,
    FIELD5: 166.6,
    FIELD6: 171.3,
    FIELD7: 174
  },
  '13.10': {
    FIELD1: 149.5,
    FIELD2: 152.2,
    FIELD3: 156.9,
    FIELD4: 162.1,
    FIELD5: 167.2,
    FIELD6: 171.9,
    FIELD7: 174.6
  },
  13.11: {
    FIELD1: 150,
    FIELD2: 152.8,
    FIELD3: 157.4,
    FIELD4: 162.6,
    FIELD5: 167.8,
    FIELD6: 172.5,
    FIELD7: 175.2
  },
  '14.0': {
    FIELD1: 150.5,
    FIELD2: 153.3,
    FIELD3: 158,
    FIELD4: 163.2,
    FIELD5: 168.4,
    FIELD6: 173,
    FIELD7: 175.8
  },
  14.1: {
    FIELD1: 151.1,
    FIELD2: 153.9,
    FIELD3: 158.5,
    FIELD4: 163.7,
    FIELD5: 168.9,
    FIELD6: 173.6,
    FIELD7: 176.4
  },
  14.2: {
    FIELD1: 151.6,
    FIELD2: 154.4,
    FIELD3: 159.1,
    FIELD4: 164.3,
    FIELD5: 169.5,
    FIELD6: 174.2,
    FIELD7: 177
  },
  14.3: {
    FIELD1: 152.1,
    FIELD2: 154.9,
    FIELD3: 159.6,
    FIELD4: 164.8,
    FIELD5: 170,
    FIELD6: 174.7,
    FIELD7: 177.5
  },
  14.4: {
    FIELD1: 152.6,
    FIELD2: 155.4,
    FIELD3: 160.1,
    FIELD4: 165.3,
    FIELD5: 170.5,
    FIELD6: 175.2,
    FIELD7: 178.1
  },
  14.5: {
    FIELD1: 153.1,
    FIELD2: 155.9,
    FIELD3: 160.6,
    FIELD4: 165.8,
    FIELD5: 171.1,
    FIELD6: 175.8,
    FIELD7: 178.6
  },
  14.6: {
    FIELD1: 153.5,
    FIELD2: 156.4,
    FIELD3: 161.1,
    FIELD4: 166.3,
    FIELD5: 171.5,
    FIELD6: 176.3,
    FIELD7: 179.1
  },
  14.7: {
    FIELD1: 154,
    FIELD2: 156.8,
    FIELD3: 161.5,
    FIELD4: 166.8,
    FIELD5: 172,
    FIELD6: 176.7,
    FIELD7: 179.6
  },
  14.8: {
    FIELD1: 154.4,
    FIELD2: 157.3,
    FIELD3: 162,
    FIELD4: 167.2,
    FIELD5: 172.5,
    FIELD6: 177.2,
    FIELD7: 180
  },
  14.9: {
    FIELD1: 154.9,
    FIELD2: 157.7,
    FIELD3: 162.4,
    FIELD4: 167.7,
    FIELD5: 172.9,
    FIELD6: 177.7,
    FIELD7: 180.5
  },
  '14.10': {
    FIELD1: 155.3,
    FIELD2: 158.1,
    FIELD3: 162.9,
    FIELD4: 168.1,
    FIELD5: 173.4,
    FIELD6: 178.1,
    FIELD7: 180.9
  },
  14.11: {
    FIELD1: 155.7,
    FIELD2: 158.6,
    FIELD3: 163.3,
    FIELD4: 168.5,
    FIELD5: 173.8,
    FIELD6: 178.5,
    FIELD7: 181.4
  },
  '15.0': {
    FIELD1: 156.1,
    FIELD2: 159,
    FIELD3: 163.7,
    FIELD4: 169,
    FIELD5: 174.2,
    FIELD6: 179,
    FIELD7: 181.8
  },
  15.1: {
    FIELD1: 156.5,
    FIELD2: 159.4,
    FIELD3: 164.1,
    FIELD4: 169.4,
    FIELD5: 174.6,
    FIELD6: 179.4,
    FIELD7: 182.2
  },
  15.2: {
    FIELD1: 156.9,
    FIELD2: 159.7,
    FIELD3: 164.5,
    FIELD4: 169.7,
    FIELD5: 175,
    FIELD6: 179.7,
    FIELD7: 182.6
  },
  15.3: {
    FIELD1: 157.3,
    FIELD2: 160.1,
    FIELD3: 164.8,
    FIELD4: 170.1,
    FIELD5: 175.4,
    FIELD6: 180.1,
    FIELD7: 183
  },
  15.4: {
    FIELD1: 157.6,
    FIELD2: 160.5,
    FIELD3: 165.2,
    FIELD4: 170.5,
    FIELD5: 175.7,
    FIELD6: 180.5,
    FIELD7: 183.3
  },
  15.5: {
    FIELD1: 158,
    FIELD2: 160.8,
    FIELD3: 165.6,
    FIELD4: 170.8,
    FIELD5: 176.1,
    FIELD6: 180.8,
    FIELD7: 183.7
  },
  15.6: {
    FIELD1: 158.3,
    FIELD2: 161.1,
    FIELD3: 165.9,
    FIELD4: 171.1,
    FIELD5: 176.4,
    FIELD6: 181.1,
    FIELD7: 184
  },
  15.7: {
    FIELD1: 158.6,
    FIELD2: 161.5,
    FIELD3: 166.2,
    FIELD4: 171.5,
    FIELD5: 176.7,
    FIELD6: 181.5,
    FIELD7: 184.3
  },
  15.8: {
    FIELD1: 159,
    FIELD2: 161.8,
    FIELD3: 166.5,
    FIELD4: 171.8,
    FIELD5: 177,
    FIELD6: 181.8,
    FIELD7: 184.6
  },
  15.9: {
    FIELD1: 159.3,
    FIELD2: 162.1,
    FIELD3: 166.8,
    FIELD4: 172.1,
    FIELD5: 177.3,
    FIELD6: 182.1,
    FIELD7: 184.9
  },
  '15.10': {
    FIELD1: 159.6,
    FIELD2: 162.4,
    FIELD3: 167.1,
    FIELD4: 172.4,
    FIELD5: 177.6,
    FIELD6: 182.3,
    FIELD7: 185.2
  },
  15.11: {
    FIELD1: 159.8,
    FIELD2: 162.7,
    FIELD3: 167.4,
    FIELD4: 172.6,
    FIELD5: 177.9,
    FIELD6: 182.6,
    FIELD7: 185.4
  },
  '16.0': {
    FIELD1: 160.1,
    FIELD2: 162.9,
    FIELD3: 167.7,
    FIELD4: 172.9,
    FIELD5: 178.1,
    FIELD6: 182.9,
    FIELD7: 185.7
  },
  16.1: {
    FIELD1: 160.4,
    FIELD2: 163.2,
    FIELD3: 167.9,
    FIELD4: 173.1,
    FIELD5: 178.4,
    FIELD6: 183.1,
    FIELD7: 185.9
  },
  16.2: {
    FIELD1: 160.6,
    FIELD2: 163.4,
    FIELD3: 168.2,
    FIELD4: 173.4,
    FIELD5: 178.6,
    FIELD6: 183.3,
    FIELD7: 186.1
  },
  16.3: {
    FIELD1: 160.9,
    FIELD2: 163.7,
    FIELD3: 168.4,
    FIELD4: 173.6,
    FIELD5: 178.8,
    FIELD6: 183.5,
    FIELD7: 186.4
  },
  16.4: {
    FIELD1: 161.1,
    FIELD2: 163.9,
    FIELD3: 168.6,
    FIELD4: 173.8,
    FIELD5: 179,
    FIELD6: 183.7,
    FIELD7: 186.6
  },
  16.5: {
    FIELD1: 161.3,
    FIELD2: 164.1,
    FIELD3: 168.8,
    FIELD4: 174,
    FIELD5: 179.2,
    FIELD6: 183.9,
    FIELD7: 186.7
  },
  16.6: {
    FIELD1: 161.5,
    FIELD2: 164.3,
    FIELD3: 169,
    FIELD4: 174.2,
    FIELD5: 179.4,
    FIELD6: 184.1,
    FIELD7: 186.9
  },
  16.7: {
    FIELD1: 161.7,
    FIELD2: 164.5,
    FIELD3: 169.2,
    FIELD4: 174.4,
    FIELD5: 179.6,
    FIELD6: 184.3,
    FIELD7: 187.1
  },
  16.8: {
    FIELD1: 161.9,
    FIELD2: 164.7,
    FIELD3: 169.4,
    FIELD4: 174.6,
    FIELD5: 179.8,
    FIELD6: 184.4,
    FIELD7: 187.2
  },
  16.9: {
    FIELD1: 162.1,
    FIELD2: 164.9,
    FIELD3: 169.6,
    FIELD4: 174.7,
    FIELD5: 179.9,
    FIELD6: 184.6,
    FIELD7: 187.4
  },
  '16.10': {
    FIELD1: 162.3,
    FIELD2: 165.1,
    FIELD3: 169.7,
    FIELD4: 174.9,
    FIELD5: 180.1,
    FIELD6: 184.7,
    FIELD7: 187.5
  },
  16.11: {
    FIELD1: 162.4,
    FIELD2: 165.2,
    FIELD3: 169.9,
    FIELD4: 175,
    FIELD5: 180.2,
    FIELD6: 184.8,
    FIELD7: 187.6
  },
  '17.0': {
    FIELD1: 162.6,
    FIELD2: 165.4,
    FIELD3: 170,
    FIELD4: 175.2,
    FIELD5: 180.3,
    FIELD6: 185,
    FIELD7: 187.7
  },
  17.1: {
    FIELD1: 162.7,
    FIELD2: 165.5,
    FIELD3: 170.1,
    FIELD4: 175.3,
    FIELD5: 180.4,
    FIELD6: 185.1,
    FIELD7: 187.8
  },
  17.2: {
    FIELD1: 162.9,
    FIELD2: 165.6,
    FIELD3: 170.3,
    FIELD4: 175.4,
    FIELD5: 180.5,
    FIELD6: 185.2,
    FIELD7: 187.9
  },
  17.3: {
    FIELD1: 163,
    FIELD2: 165.8,
    FIELD3: 170.4,
    FIELD4: 175.5,
    FIELD5: 180.6,
    FIELD6: 185.2,
    FIELD7: 188
  },
  17.4: {
    FIELD1: 163.1,
    FIELD2: 165.9,
    FIELD3: 170.5,
    FIELD4: 175.6,
    FIELD5: 180.7,
    FIELD6: 185.3,
    FIELD7: 188.1
  },
  17.5: {
    FIELD1: 163.2,
    FIELD2: 166,
    FIELD3: 170.6,
    FIELD4: 175.7,
    FIELD5: 180.8,
    FIELD6: 185.4,
    FIELD7: 188.1
  },
  17.6: {
    FIELD1: 163.3,
    FIELD2: 166.1,
    FIELD3: 170.7,
    FIELD4: 175.8,
    FIELD5: 180.9,
    FIELD6: 185.5,
    FIELD7: 188.2
  },
  17.7: {
    FIELD1: 163.4,
    FIELD2: 166.2,
    FIELD3: 170.8,
    FIELD4: 175.8,
    FIELD5: 180.9,
    FIELD6: 185.5,
    FIELD7: 188.3
  },
  17.8: {
    FIELD1: 163.5,
    FIELD2: 166.3,
    FIELD3: 170.8,
    FIELD4: 175.9,
    FIELD5: 181,
    FIELD6: 185.6,
    FIELD7: 188.3
  },
  17.9: {
    FIELD1: 163.6,
    FIELD2: 166.3,
    FIELD3: 170.9,
    FIELD4: 176,
    FIELD5: 181,
    FIELD6: 185.6,
    FIELD7: 188.3
  },
  '17.10': {
    FIELD1: 163.7,
    FIELD2: 166.4,
    FIELD3: 171,
    FIELD4: 176,
    FIELD5: 181.1,
    FIELD6: 185.7,
    FIELD7: 188.4
  },
  17.11: {
    FIELD1: 163.8,
    FIELD2: 166.5,
    FIELD3: 171,
    FIELD4: 176.1,
    FIELD5: 181.1,
    FIELD6: 185.7,
    FIELD7: 188.4
  },
  '18.0': {
    FIELD1: 163.9,
    FIELD2: 166.6,
    FIELD3: 171.1,
    FIELD4: 176.1,
    FIELD5: 181.2,
    FIELD6: 185.7,
    FIELD7: 188.4
  },
  18.1: {
    FIELD1: 163.9,
    FIELD2: 166.6,
    FIELD3: 171.2,
    FIELD4: 176.2,
    FIELD5: 181.2,
    FIELD6: 185.7,
    FIELD7: 188.5
  },
  18.2: {
    FIELD1: 164,
    FIELD2: 166.7,
    FIELD3: 171.2,
    FIELD4: 176.2,
    FIELD5: 181.3,
    FIELD6: 185.8,
    FIELD7: 188.5
  },
  18.3: {
    FIELD1: 164.1,
    FIELD2: 166.8,
    FIELD3: 171.3,
    FIELD4: 176.3,
    FIELD5: 181.3,
    FIELD6: 185.8,
    FIELD7: 188.5
  },
  18.4: {
    FIELD1: 164.1,
    FIELD2: 166.8,
    FIELD3: 171.3,
    FIELD4: 176.3,
    FIELD5: 181.3,
    FIELD6: 185.8,
    FIELD7: 188.5
  },
  18.5: {
    FIELD1: 164.2,
    FIELD2: 166.9,
    FIELD3: 171.4,
    FIELD4: 176.4,
    FIELD5: 181.3,
    FIELD6: 185.8,
    FIELD7: 188.5
  },
  18.6: {
    FIELD1: 164.2,
    FIELD2: 166.9,
    FIELD3: 171.4,
    FIELD4: 176.4,
    FIELD5: 181.4,
    FIELD6: 185.8,
    FIELD7: 188.5
  },
  18.7: {
    FIELD1: 164.3,
    FIELD2: 167,
    FIELD3: 171.4,
    FIELD4: 176.4,
    FIELD5: 181.4,
    FIELD6: 185.9,
    FIELD7: 188.5
  },
  18.8: {
    FIELD1: 164.3,
    FIELD2: 167,
    FIELD3: 171.5,
    FIELD4: 176.4,
    FIELD5: 181.4,
    FIELD6: 185.9,
    FIELD7: 188.5
  },
  18.9: {
    FIELD1: 164.4,
    FIELD2: 167.1,
    FIELD3: 171.5,
    FIELD4: 176.5,
    FIELD5: 181.4,
    FIELD6: 185.9,
    FIELD7: 188.5
  },
  '18.10': {
    FIELD1: 164.5,
    FIELD2: 167.1,
    FIELD3: 171.6,
    FIELD4: 176.5,
    FIELD5: 181.4,
    FIELD6: 185.9,
    FIELD7: 188.5
  },
  18.11: {
    FIELD1: 164.5,
    FIELD2: 167.2,
    FIELD3: 171.6,
    FIELD4: 176.5,
    FIELD5: 181.5,
    FIELD6: 185.9,
    FIELD7: 188.5
  },
  '19.0': {
    FIELD1: 164.5,
    FIELD2: 167.2,
    FIELD3: 171.6,
    FIELD4: 176.5,
    FIELD5: 181.5,
    FIELD6: 185.9,
    FIELD7: 188.5
  }
};

export const getHeightCalculation = (
  cal_age: any,
  gender: any,
  value: any
): any => {
  try {
    if (cal_age <= 19.0) {
      let calculation = null;
      if (gender === GenderTypes.MALE) {
        calculation = BoyHeight?.[cal_age];
      } else {
        calculation = GirlHeight?.[cal_age];
      }
      if (calculation) {
        if (
          (value >= calculation.FIELD3 && value <= calculation.FIELD7) ||
          value > calculation.FIELD7
        ) {
          return 1;
        } else if (value >= calculation.FIELD2 && value < calculation.FIELD3) {
          return 0.5;
        } else if (value < calculation.FIELD2) {
          return 0;
        }
      }
    } else {
      if (value >= 171.6) {
        return 1;
      } else if (
        (value >= 167.2 && value < 171.6) ||
        (value > 181.5 && value <= 185.9)
      ) {
        return 0.5;
      } else if (value < 167.2 || value > 188.5) {
        return 0;
      }
    }
    return null;
  } catch (e) {
    throw new Error((e as any)?.message);
  }
};
