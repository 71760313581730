/* eslint-disable camelcase */
import React from 'react';
import {Grid, TextField} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

import {getAppointmentDetails} from '@shared/services/PublicService';
import {
  getPersonAppointmentList,
  getVirtualAppointmentList
} from '@shared/services/CommonService';
import AuthUtils from 'src/utils/AuthUtils';

import Icons from '@components/system/MyIcons';
import PatientCard from './../PatientCard/PatientCard';
import Loader from '@components/framework/loader/Loader';
import {CommunicationService} from 'src/service/CommunicationService';
import {STANDALONE_APPOINTMENT_UPDATE} from 'src/constants/CommunicationServiceConstants';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import moment from 'moment';
import DoctorCard from '../PatientCard/DoctorCard';
import './AppointmentDetails.scss';

const AppointmentDetails = (props: any): React.ReactElement => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const subscription: any = React.useRef(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isAppointmentSearch, setIsAppointmentSearch] =
    React.useState<boolean>(false);
  const [appointmentsArray, setAppointmentsArray] = React.useState<any>([]);
  const [selectedPatient, setSelectedPatient] =
    React.useState<number | undefined>(0);
  const placeholderText = 'Search';

  const {
    clickedStatus,
    handlePatientDetails,
    handleAppointmentDetails,
    selectedDate,
    branchId,
    docId,
    setAppointmentId,
    setShowPdfFileDialog,
    setPrescription,
    setShowProvideVaccinePopUp,
    setShowBackground,
    setVaccineData,
    vaccineData,
    isFromClinicalDashboard,
    openNewAppointmentBookingPopup,
    encIdFromNurse,
    vitalsAdded,
    setVitalsAdded,
    healphaIdForPersonWeb,
    setProvideVaccinePassData,
    statusCount,
    isNurse,
    isAdminNurse
  } = props;

  const performResponseActions = (resList: any): void => {
    const res = resList?.filter(
      (item: any) => item?.appointment?.person_details
    );
    AuthUtils.setIsPrescription(res[0]?.prescription_url?.length > 0);

    const reversed = res && res.reverse();
    const index =
      vitalsAdded &&
      reversed &&
      reversed.findIndex((i: any) => {
        return i?.encounter_id == encIdFromNurse;
      });

    vitalsAdded && encIdFromNurse
      ? setSelectedPatient(index)
      : setSelectedPatient(0);
    setAppointmentsArray(props?.isFromViewMore ? res.reverse() : res);
    setLoading(false);
    const lastIndex = res?.length - 1;
    if (
      res?.[0]?.appointment?.id &&
      (index < 0 || !index) &&
      !props?.isFromPersonWeb
    ) {
      handleAppointmentDetails(
        res[0]?.appointment?.id,
        res?.[0]?.virtual_clinic,
        res?.[0]?.appointment?.virtual_clinic_branch
      );
      setAppointmentId(res[0]?.appointment?.id);
    } else if (
      res &&
      vitalsAdded &&
      encIdFromNurse &&
      index >= 0 &&
      res[index]?.appointment?.id
    ) {
      handleAppointmentDetails(
        res[index]?.appointment?.id,
        res?.[index]?.virtual_clinic,
        res?.[0]?.appointment?.virtual_clinic_branch
      );
      setAppointmentId(res[index]?.appointment?.id);
    } else if (
      res &&
      props?.isFromPersonWeb &&
      res[lastIndex]?.appointment?.id &&
      lastIndex >= 0
    ) {
      handleAppointmentDetails(
        res[lastIndex]?.appointment?.id,
        res?.[lastIndex]?.virtual_clinic,
        res?.[0]?.appointment?.virtual_clinic_branch
      );
      reversed[0]?.appointment?.id &&
        setAppointmentId(reversed[0]?.appointment?.id);
    } else {
      setAppointmentId(null);
      handleAppointmentDetails(null, null, null);
    }
  };

  const handlingDisplay = (searchData: any, date?: any) => {
    setVitalsAdded && setVitalsAdded(false);

    const today = moment().format('YYYY-MM-DD');

    if (
      props?.isFromVirtualClinic &&
      branchId &&
      (selectedDate || date || today) &&
      !props?.isFromPersonWeb
    ) {
      setLoading(true);
      getVirtualAppointmentList(
        clickedStatus,
        searchData,
        selectedDate || date || today,
        branchId,
        true
      )
        .then(response => {
          performResponseActions(response?.data?.appointments);
        })
        .catch(err => {
          setLoading(false);
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    } else if (branchId && (selectedDate || date || today)) {
      if (!docId && !props?.isFromClinicalDashboard) return null;
      setLoading(true);
      getAppointmentDetails({
        doctor_id:
          isNurse || isAdminNurse || (props?.isFrontOffice && !docId)
            ? ''
            : docId || '',
        branch_id: branchId,
        date: selectedDate || date || today,
        appointment_checked: clickedStatus,
        search_text: searchData,
        total_appts_req: true
      })
        .then(response => {
          performResponseActions(response?.data?.appointments);
        })
        .catch(err => {
          setLoading(false);
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    } else if (props?.isFromPersonWeb && healphaIdForPersonWeb) {
      setLoading(true);
      healphaIdForPersonWeb &&
        getPersonAppointmentList(
          healphaIdForPersonWeb,
          clickedStatus,
          searchData,
          '',
          searchData?.length > 2 ? true : false,
          true
        )
          .then(response => {
            performResponseActions(response?.data?.appointments);
          })
          .catch(err => {
            setLoading(false);
            enqueueSnackbar(
              err?.message ? err.message : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          });
    }
  };

  const onInputChange = (event: any) => {
    const text = event.target.value;
    if (text?.length > 2) {
      setIsAppointmentSearch(true);
      handlingDisplay(text);
    } else if (text?.length == 0) {
      setIsAppointmentSearch(false);
      handlingDisplay('');
    }
  };

  const callHandlingDisplay = () => {
    vitalsAdded && handlingDisplay('');
  };

  React.useEffect(callHandlingDisplay, [vitalsAdded]);

  const init = () => {
    handlingDisplay('');
  };

  React.useEffect(init, [
    !isNurse && !isAdminNurse && docId,
    branchId,
    clickedStatus,
    selectedDate,
    healphaIdForPersonWeb
  ]);

  const initAppointment = () => {
    if (!AuthUtils.getAppointmentNotify()) return;
    handlingDisplay('');
    AuthUtils.clearIsAppointmentNotify();
  };

  React.useEffect(initAppointment, [AuthUtils.getAppointmentNotify()]);
  const handlestatus = () => {
    window.addEventListener('handlingDisplay', (e: any) => {
      handlingDisplay('', e?.detail?.date);
    });
    return () => {
      window.removeEventListener('handlingDisplay', () => {
        handlingDisplay('');
      });
    };
  };
  React.useEffect(handlestatus, []);

  React.useEffect(() => {
    subscription.current = CommunicationService.getMessage().subscribe(
      function (message: any) {
        if (
          message?.data?.type === STANDALONE_APPOINTMENT_UPDATE &&
          message?.data?.value === true
        ) {
          handlingDisplay('');
        }
      }
    );

    return () => {
      return subscription?.current?.unsubscribe();
    };
  });

  const renderSearchBar = (): React.ReactElement => {
    return (
      <Grid item xs={12} className="insideDetails">
        <TextField
          variant="outlined"
          onChange={onInputChange}
          className="search-bar"
          placeholder={placeholderText}
          InputProps={{
            startAdornment: (
              <div>{loading && <Loader color="inherit" size={20} />}</div>
            ),
            endAdornment: (
              <div>
                <Icons.SearchIcon color="disabled" />
              </div>
            )
          }}
        />
      </Grid>
    );
  };

  const renderPatientCard = (): React.ReactElement => {
    const sortedAppointment =
      appointmentsArray &&
      appointmentsArray.sort(function (a: any, b: any) {
        return moment(a?.appointment?.appointment_date_start).diff(
          moment(b?.appointment?.appointment_date_start)
        );
      });

    const temp = [...sortedAppointment];
    const sortAppointmentByDate =
      temp &&
      temp.sort(function (a: any, b: any) {
        return moment(b?.appointment?.appointment_date_start).diff(
          moment(a?.appointment?.appointment_date_start)
        );
      });
    return (
      <Grid className="single-app">
        {loading ? (
          <Loader size={28} />
        ) : !props?.isFromPersonWeb ? (
          appointmentsArray?.length > 0 &&
          sortedAppointment?.map?.((item: any, index: any) => {
            return (
              <PatientCard
                key={index}
                currPatient={item?.appointment}
                keyGiven={item?.appointment?.appointment_status}
                handlePatientDetails={handlePatientDetails}
                handleAppointmentDetails={handleAppointmentDetails}
                setSelectedPatient={setSelectedPatient}
                selectedPatient={selectedPatient}
                index={index}
                setAppointmentId={setAppointmentId}
                appointmentTypeStatus={item?.appointment_type_status}
                prescription={item?.prescription_url}
                endId={item?.encounter_id}
                template_Id={item?.template_id}
                clickedStatus={clickedStatus}
                setShowPdfFileDialog={setShowPdfFileDialog}
                setPrescription={setPrescription}
                setShowProvideVaccinePopUp={setShowProvideVaccinePopUp}
                selectedDate={selectedDate}
                setVaccineData={setVaccineData}
                vaccineData={vaccineData}
                isFromClinicalDashboard={isFromClinicalDashboard}
                isFromViewMore={props?.isFromViewMore}
                isFromVirtualClinic={props?.isFromVirtualClinic}
                handlingDisplay={handlingDisplay}
                openNewAppointmentBookingPopup={openNewAppointmentBookingPopup}
                showProvideVaccine={item?.is_provide_vaccine}
                isFromPersonWeb={props?.isFromPersonWeb}
                isVirtualAppointment={item?.virtual_clinic}
                setProvideVaccinePassData={setProvideVaccinePassData}
                patientInfo={props?.patientInfo}
              />
            );
          })
        ) : (
          appointmentsArray?.length > 0 &&
          sortAppointmentByDate?.map?.((item: any, index: any) => {
            return (
              <DoctorCard
                key={index}
                currPatient={item?.appointment}
                keyGiven={item?.appointment?.appointment_status}
                handlePatientDetails={handlePatientDetails}
                handleAppointmentDetails={handleAppointmentDetails}
                setSelectedPatient={setSelectedPatient}
                selectedPatient={selectedPatient}
                index={index}
                setAppointmentId={setAppointmentId}
                appointmentTypeStatus={item?.appointment_type_status}
                prescription={item?.prescription_url}
                endId={item?.encounter_id}
                template_Id={item?.template_id}
                clickedStatus={clickedStatus}
                setShowPdfFileDialog={setShowPdfFileDialog}
                setPrescription={setPrescription}
                setShowProvideVaccinePopUp={setShowProvideVaccinePopUp}
                selectedDate={selectedDate}
                setVaccineData={setVaccineData}
                vaccineData={vaccineData}
                isFromClinicalDashboard={isFromClinicalDashboard}
                isFromViewMore={props?.isFromViewMore}
                isFromVirtualClinic={props?.isFromVirtualClinic}
                handlingDisplay={handlingDisplay}
                openNewAppointmentBookingPopup={openNewAppointmentBookingPopup}
                showProvideVaccine={item?.is_provide_vaccine}
                isFromPersonWeb={props?.isFromPersonWeb}
                healphaIdForPersonWeb={healphaIdForPersonWeb}
                isVirtualAppointment={item?.virtual_clinic}
                qrAppointmentId={props?.qrAppointmentId}
                compareAppointmentId={item?.appointment?.id}
              />
            );
          })
        )}
      </Grid>
    );
  };

  return (
    <Grid item xs={12} className="appointment-details-wrapper" spacing={2}>
      {isAppointmentSearch || appointmentsArray?.length > 0 ? (
        <Grid container>
          {setShowBackground(false)}
          {!props?.isFromViewMore && (
            <Grid item xs={2} className="time-app">
              {t('CLINIC_TIME')}
            </Grid>
          )}
          {!props?.isFromViewMore && (
            <Grid item xs={7} className="time-app">
              {t('CLINIC_APPOINTMENT')} ({appointmentsArray.length})
            </Grid>
          )}
          {!props?.isFromViewMore && renderSearchBar()}
          <Grid
            item
            container
            xs={12}
            className={
              appointmentsArray?.length &&
              (isFromClinicalDashboard
                ? statusCount > 6
                  ? 'clinical-detailed clinical-detailed-scroll'
                  : 'clinical-detailed'
                : 'detailed')
            }
            direction="column">
            {renderPatientCard()}
          </Grid>
        </Grid>
      ) : (
        setShowBackground(true)
      )}
    </Grid>
  );
};

export default AppointmentDetails;
