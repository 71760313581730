/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Link,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {Done} from '@material-ui/icons';
import moment from 'moment';
import {isMobile} from 'react-device-detect';

import {getAllUsers, sendOtpToUser} from '@shared/services/PublicService';
import {environment} from '@shared/environment/environment';

import RegisterDialogTitleComponent from '../CommonDialogTitle/RegisterDialogTitleComponent';
import Loader from '@components/framework/loader/Loader';
import CommonUtils from 'src/utils/CommonUtils';
import LoginUserComponent from '@components/system/Dialogs/LoginUser/LoginUserComponent';
import OtpScreenComponent from '@components/system/Dialogs/OtpScreen/OtpScreenComponent';
import TemporaryUserRegistration from '@components/system/Dialogs/TemporaryUserRegistration/TemporaryUserRegistration';
import Icons from '@components/system/MyIcons/index';
import FormCombinationField from '@components/system/CustomFormField/FormCombinationField';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import FormPhoneField from '@components/system/CustomFormField/FormPhoneField';
import FormDatePickerField from '@components/system/CustomFormField/FormDatePickerField';
import FormSelectField from '@components/system/CustomFormField/FormSelectField';
import FormPasswordField from '@components/system/CustomFormField/FormPasswordField';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {
  genderOptionsList,
  salutationOptionsList
} from 'src/constants/FormFields';
import {
  DEFAULT_WHITE_COLOR,
  BORDER_TERTIARY_COLOR,
  GET_8_PIXEL_BOX
} from 'src/style/variable';
import './RegisterUserComponent.scss';
import RelationShipPopupComponent from '../RelationShipPopup/RelationShipPopupComponent';
import {connect} from 'react-redux';

const textFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  border: `1px solid ${BORDER_TERTIARY_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.5),
  opacity: 1,
  fontSize: '1.2rem'
};

const dropDownProps = {
  height: '3.9rem',
  width: '100%',
  border: `1px solid ${BORDER_TERTIARY_COLOR}`
};

const RegisterUserComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(props?.open);
  const [showLoginPopup, setShowLoginPopup] = React.useState(false);
  const [showOtpPopup, setShowOtpPopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [openRelationPopup, setOpenRelationPopup] = React.useState(false);
  const [familyHeadObject, setFamilyHeadObject]: any = React.useState({});
  const [
    showTemporaryUserRegistrationPopup,
    setShowTemporaryUserRegistrationPopup
  ] = React.useState(false);
  const [passwordLengthRuleMatched, setPasswordLengthRuleMatched] =
    React.useState<boolean>(false);
  const [passwordReqMatched, setPasswordReqMatched] =
    React.useState<boolean>(false);
  const [values, setValues]: any = React.useState({
    salutation: 'Mr',
    first_name: '',
    last_name: '',
    email: '',
    dob: null,
    gender: '',
    password: '',
    showPassword: false,
    phone_code: environment.dialCode,
    phone_number: '',
    relation: '',
    family_head_id: ''
  });

  const handleClose = (event?: any, reason?: any) => {
    setOpen(false);
    props?.action();
  };

  const openLoginPopup = (): void => {
    setOpen(false);
    setShowLoginPopup(true);
  };

  const handleSubmit = (): void => {
    if (
      !values?.first_name ||
      !values?.last_name ||
      !values?.phone_code ||
      !values?.phone_number ||
      !values?.dob ||
      !values?.gender ||
      !values?.password ||
      !values?.salutation
    ) {
      enqueueSnackbar(t('PERSON_DETAILS_REQ'), SnackBarConfig('w'));
      return;
    }

    if (!moment(values?.dob).isValid()) {
      enqueueSnackbar(t('INVALID_DOB'), SnackBarConfig('w'));
      return;
    }

    if (values?.email && !CommonUtils.isEmailValid(values?.email)) {
      enqueueSnackbar(t('INVALID_EMAIL'), SnackBarConfig('w'));
      return;
    }

    if (!passwordLengthRuleMatched || !passwordReqMatched) {
      enqueueSnackbar(t('PWD_CRITERIA_TEXT'), SnackBarConfig('w'));
      return;
    }

    if (values?.phone_number?.length != environment.mobileNumberLength) {
      enqueueSnackbar(
        t('MOB_MAX_LENGTH_WARNING', {
          limit: environment.mobileNumberLength
        }),
        SnackBarConfig('w')
      );
      return;
    }

    const data = {
      phone_code: values?.phone_code,
      phone_number: values?.phone_number,
      email: values?.email,
      first_name: values?.first_name,
      last_name: values?.last_name
    };

    setIsLoading(true);
    getAllUsers(values?.phone_number)
      .then(res => {
        if (res?.data?.persons?.length > 0) {
          setIsLoading(false);
          setOpen(false);
          setOpenRelationPopup(true);

          res.data.persons?.map((item: any) => {
            if (item?.hlpid == res?.data?.family_head_id) {
              setFamilyHeadObject(item);
            }
          });
        } else {
          sendOtpToUser(data)
            .then(result => {
              setIsLoading(false);
              setOpen(false);
              setShowOtpPopup(true);
            })
            .catch(err => {
              setIsLoading(false);
              enqueueSnackbar(
                err?.message ? err.message : t('ERROR_ENCOUNTERED'),
                SnackBarConfig('e')
              );
            });
        }
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const closeOtpPopup = (): void => {
    setShowOtpPopup(false);
    handleClose();
  };

  const closeLoginPopup = (): void => {
    setShowLoginPopup(false);
    handleClose();
  };

  const closeTempRegPopup = (): void => {
    setShowTemporaryUserRegistrationPopup(false);
    handleClose();
  };

  const handleEscClose = (event: any, reason: string) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const openTemporaryUserRegistrationPopup = (): void => {
    setOpen(false);
    setShowTemporaryUserRegistrationPopup(true);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleChange = (prop: any) => (event: any) => {
    if (prop === 'first_name' || prop === 'last_name') {
      if (
        /^[a-zA-Z]+$/.test(event?.target?.value) ||
        event?.target?.value === ''
      ) {
        setValues({...values, [prop]: event.target.value});
      }
    } else if (prop === 'password') {
      setValues({...values, [prop]: event.target.value});

      if (
        event?.target?.value?.length >= 8 &&
        event?.target?.value?.length <= 16
      ) {
        setPasswordLengthRuleMatched(true);
      } else {
        setPasswordLengthRuleMatched(false);
      }

      if (
        event?.target?.value?.match(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]).*$/
        )
      ) {
        setPasswordReqMatched(true);
      } else {
        setPasswordReqMatched(false);
      }
    } else {
      setValues({...values, [prop]: event.target.value});
    }
  };

  const handleDobChange = (prop: any) => (newValue: any) => {
    setValues({...values, [prop]: newValue});
  };

  const handleCountryChange = (
    value: any,
    country: any,
    e: any,
    formattedValue: any
  ): void => {
    const countryCode = formattedValue?.split(' ')?.[0];
    const phoneNumber = value?.substr(country?.dialCode?.length);

    if (phoneNumber?.length <= 10) {
      setValues({
        ...values,
        phone_code: countryCode,
        phone_number: phoneNumber
      });
    }
  };

  const renderRelationPopup = (): React.ReactElement => {
    return (
      <RelationShipPopupComponent
        open={openRelationPopup}
        closeRelationPopup={handleClose}
        values={values}
        appointmentValues={props?.appointmentValues}
        familyHeadObject={familyHeadObject}
        isFromBookAppointment={true}
      />
    );
  };

  const renderOtpPopup = (): React.ReactElement => {
    return (
      <OtpScreenComponent
        open={showOtpPopup}
        values={values}
        appointmentValues={props?.appointmentValues}
        closeOtpPopup={closeOtpPopup}
        isFromBookAppointment={true}
      />
    );
  };

  const renderHelperTextInfo = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={9} container direction="column">
          <Typography className="information-text">
            {t('APPOINTMENT_INFO_TEXT')}
          </Typography>

          <Typography className="information-text">
            {t('APPOINTMENT_EMAIL_MOBILE_TEXT')}
          </Typography>
        </Grid>

        <Grid
          item
          xs={3}
          container
          justifyContent="flex-end"
          alignItems="center">
          <Button
            variant="outlined"
            className="login-button-container"
            onClick={() => openLoginPopup()}
            disabled={isLoading}>
            {t('LOGIN_HERE')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderFormSection = (): React.ReactElement => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <FormCombinationField
            dropDownName={'salutation'}
            dropdownValue={values['salutation']}
            callback={handleChange('salutation')}
            dropDownOptions={salutationOptionsList}
            textFieldCallBack={handleChange('first_name')}
            textFieldName={'first_name'}
            placeholder={t('FIRST_NAME')}
            textFieldValue={values['first_name']}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            placeholder={t('LAST_NAME')}
            name="last_name"
            value={values['last_name']}
            callback={handleChange('last_name')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormPhoneField
            name="phone_number"
            placeholder={t('MOBILE_NUMBER')}
            callback={handleCountryChange}
            phoneNumber={
              (values?.phone_code || values?.phone_number) &&
              values?.phone_code + ' ' + values?.phone_number
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            placeholder={t('EMAIL')}
            name="email"
            value={values['email']}
            callback={handleChange('email')}
            adornmentPosition="start"
            Icon={Icons.MailIcon}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormDatePickerField
            name="dob"
            value={values?.dob}
            textFieldProps={textFieldProps}
            Icon={Icons.DOBIcon}
            callback={handleDobChange('dob')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormSelectField
            name="gender"
            placeholder={t('GENDER')}
            value={values['gender']}
            dropDownProps={dropDownProps}
            Icon={Icons.DropDownIcon}
            StartAdornmentIcon={Icons.GenderIcon}
            dropDownOptions={genderOptionsList}
            callback={handleChange('gender')}
          />
        </Grid>

        <Grid item xs={12}>
          <FormPasswordField
            name="password"
            placeholder={t('CREATE_PASSWORD')}
            adornmentPosition="start"
            Icon={Icons.PasswordIcon}
            callback={handleChange('password')}
            onClickCallBack={handleClickShowPassword}
            OnMousePDownCallBack={handleMouseDownPassword}
            values={values}
          />
        </Grid>
      </Grid>
    );
  };

  const renderPasswordInfoSection = (): React.ReactElement => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Done
            fontSize="small"
            classes={{
              fontSizeSmall: passwordLengthRuleMatched
                ? 'done-pwd-length-success-icon'
                : 'done-icon-pwd-length'
            }}
          />
        </Grid>

        <Grid item xs={11}>
          <Typography className="password-info-text">
            {t('PASSWORD_LENGTH')}
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <Done
            fontSize="small"
            classes={{
              fontSizeSmall: passwordReqMatched
                ? 'done-pwd-req-success-icon'
                : 'done-pwd-req-icon'
            }}
          />
        </Grid>

        <Grid item xs={11}>
          <Typography className="password-info-text">
            {t('PASSWORD_RULES')}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <Grid>
        {renderHelperTextInfo()}

        <Grid style={{marginTop: '1rem'}}>{renderFormSection()}</Grid>

        <Grid style={{marginTop: '1rem'}}>{renderPasswordInfoSection()}</Grid>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className="permissions-text">
            {t('ACCOUNT_CREATE_INFO')}
            <Link
              component="button"
              variant="body2"
              className="terms-conditions-text"
              onClick={() =>
                CommonUtils.openNewTab(
                  props?.contactInfo?.termsAndConditionsUrl
                )
              }
              disabled={isLoading}>
              {t('TERMS_OF_SERVICE')}
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="create-account-button"
            onClick={() => handleSubmit()}
            disabled={isLoading}>
            {isLoading ? <Loader size={28} /> : t('CREATE_ACCOUNT')}
          </Button>
        </Grid>
        {props?.appointmentValues?.appointment?.appointment_type !==
          'online' && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Link
              component="button"
              variant="body2"
              underline="always"
              className="skip-link"
              onClick={() => openTemporaryUserRegistrationPopup()}
              disabled={isLoading}>
              {t('SKIP_ACCOUNT')}
            </Link>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderLoginPop = (): React.ReactElement => {
    return (
      <LoginUserComponent
        open={showLoginPopup}
        closeLoginPopup={closeLoginPopup}
        appointmentValues={props?.appointmentValues}
      />
    );
  };

  const renderTemporaryUserRegistrationPopup = (): React.ReactElement => {
    return (
      <TemporaryUserRegistration
        open={showTemporaryUserRegistrationPopup}
        closeTempRegPopup={closeTempRegPopup}
        appointmentValues={props?.appointmentValues}
      />
    );
  };

  return (
    <Grid className="register-user-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={(event: any, reason: string) => handleEscClose(event, reason)}
        aria-labelledby="person-registration-pop-up"
        aria-describedby="person-registration"
        classes={{paper: 'registration-user-popup'}}>
        <Grid container spacing={1}>
          <Grid item lg={5} className="popup-left-section">
            <DialogTitle
              id="user-registration-title"
              className="user-registration-title">
              <RegisterDialogTitleComponent
                close={handleClose}
                showChangeIcon={true}
                appointmentValues={props?.appointmentValues}
              />
            </DialogTitle>
          </Grid>

          <Grid item lg={7}>
            {!isMobile && (
              <Grid className="register-close-icon">
                <IconButton onClick={handleClose}>
                  <Icons.CloseBlackIcon />
                </IconButton>
              </Grid>
            )}
            <DialogContent className="user-registration-content">
              {renderDialogContent()}
            </DialogContent>

            <DialogActions
              disableSpacing={true}
              className={'user-registration-actions'}>
              {renderDialogActions()}
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      {showLoginPopup && renderLoginPop()}
      {showOtpPopup && renderOtpPopup()}
      {openRelationPopup && renderRelationPopup()}
      {showTemporaryUserRegistrationPopup &&
        renderTemporaryUserRegistrationPopup()}
    </Grid>
  );
};

function mapStateToProps(state: any) {
  // Properties defined here (read from state) will be available as props in the component
  return {
    contactInfo: state.common.contactInfo
  };
}

export default connect(mapStateToProps, {})(RegisterUserComponent);
