/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {useSnackbar} from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import {
  getAllUsers,
  loginPerson,
  setPhpSession
} from '@shared/services/PublicService';
import InitSharedRepo from '../../../../../../shared';

import AuthUtils from 'src/utils/AuthUtils';
import CommonUtils from 'src/utils/CommonUtils';
import {TokensObject} from 'src/constants/SystemConstants';
import Loader from '@components/framework/loader/Loader';
import PersonRegistrationComponent from '../PersonRegistration/PersonRegistrationComponent';
import DialogLeftSideIllustrationsComponent from 'src/modules/shared/DialogLeftSideIllustrations/DialogLeftSideIllustrationsComponent';
import ForgotPasswordComponent from '../ForgotPassword/ForgotPasswordComponent';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import FormPasswordField from '@components/system/CustomFormField/FormPasswordField';
import SecondaryCommonDialogTitleComponent from '../SecondaryCommonDialogTitle/SecondaryCommonDialogTitleComponent';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {BORDER_TERTIARY_COLOR} from 'src/style/variable';
import './PersonLoginComponent.scss';
import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';
import NavRoutes from 'src/constants/route/NavRoutes';

const dropDownProps = {
  height: '3.9rem',
  width: '100%',
  border: `1px solid ${BORDER_TERTIARY_COLOR}`
};

const PersonLoginComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(props?.open);
  const [openRegistrationPopup, setOpenRegistrationPopup] =
    React.useState(false);
  const [openForgotPasswordPopup, setOpenForgotPasswordPopup] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [dropDownOptions, setDropDownOptions]: any = React.useState([]);
  const [showLoginOption, setShowLoginOption] = React.useState<boolean>(false);
  const [values, setValues]: any = React.useState({
    email_mobile_number: '',
    user: '',
    password: '',
    showPassword: false,
    disabled: false,
    emailFieldDisabled: false
  });

  const handleClose = () => {
    setOpen(false);
    props?.closePersonLoginPopup();
  };

  const handleEscClose = (event: any, reason: string) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const showRegistrationPopup = (event: any) => {
    setOpenRegistrationPopup(true);
    setOpen(false);
  };

  const getUsersList = () => {
    setDropDownOptions([]);
    getAllUsers(values?.email_mobile_number)
      .then(res => {
        if (res?.data?.persons?.length === 1) {
          setValues({
            ...values,
            disabled: true,
            emailFieldDisabled: true,
            user: res?.data?.persons?.[0]?.hlpid
          });
          setDropDownOptions(res.data.persons);
          setShowLoginOption(true);
        } else if (res?.data?.persons?.length > 0) {
          setValues({
            ...values,
            emailFieldDisabled: true
          });
          setDropDownOptions(res.data.persons);
          setShowLoginOption(true);
        } else {
          enqueueSnackbar(t('USERS_NOT_FOUND'), SnackBarConfig('w'));
        }
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleSubmit = (): void => {
    if (!values?.password || !values?.user) {
      enqueueSnackbar(t('EMAIL_PWD_REQ'), SnackBarConfig('w'));
      return;
    }

    const data = {
      password: values?.password,
      healpha_id: values?.user
    };

    setIsLoading(true);

    loginPerson(data)
      .then(res => {
        setIsLoading(false);

        if (res?.data?.token) {
          CommonUtils.PerformPostLoginActions(
            res.data.token,
            res?.data?.user?.name,
            true
          );
          AuthUtils.setPHPToken(res?.data?.phpTimestampToken);
          AuthUtils.setHealphaId(res?.data?.user?.healpha_id);
          AuthUtils.setPersonCorporate(res?.data);
          AuthUtils.setHealphaIdForPersonWeb(res?.data?.user?.healpha_id);
          AuthUtils.setRegCompleted(res?.data?.user?.name);
          AuthUtils.setIsRoleType(res.data?.role_type || res.data?.user_type);

          const dataObject = {
            tk: res.data.token,
            type: CommonUtils.PhpPortalParamTypes.PERSON_LOGIN,
            jwt_token: TokensObject.REACT_PHP
          };

          setPhpSession(dataObject)
            .then(response => {
              InitSharedRepo.init();
              // history.push(
              //   `/${BASE_ROUTE_PORTAL}/user/${NavRoutes.PUBLIC.HOME_SCREEN}`
              // );
              history.push(
                `/${BASE_ROUTE_PORTAL}/user/${NavRoutes.PUBLIC.PATIENT_DASHBOARD}`
              );
            })
            .catch(err => {
              InitSharedRepo.init();

              enqueueSnackbar(
                err?.message ? err.message : t('ERROR_ENCOUNTERED'),
                SnackBarConfig('e')
              );
            });
        }
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const showForgotPassword = (): void => {
    setOpenForgotPasswordPopup(true);
    setOpen(false);
  };

  const renderDropDown = (): React.ReactElement => {
    return (
      <Select
        sx={dropDownProps}
        displayEmpty
        name="user"
        id="user"
        value={values?.user}
        disabled={values?.disabled}
        onChange={handleChange('user')}
        input={<OutlinedInput />}>
        <MenuItem disabled value="">
          <em style={{color: '#646467'}}>{t('SELECT_USER_LABEL')}</em>
        </MenuItem>

        {dropDownOptions?.map((item: any, index: number) => (
          <MenuItem key={index} value={item?.hlpid}>
            {`${item?.salutation}. ${item?.first_name} ${
              item?.middle_name ? item?.middle_name : ''
            } ${item?.last_name}`}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderRegistrationPopup = (): React.ReactElement => {
    return (
      <PersonRegistrationComponent
        open={openRegistrationPopup}
        closePersonSignUpPopup={handleClose}
      />
    );
  };

  const renderForgotPasswordPopup = (): React.ReactElement => {
    return (
      <ForgotPasswordComponent
        open={openForgotPasswordPopup}
        closeForgotPasswordPopup={handleClose}
      />
    );
  };

  const renderFormSection = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormTextField
            placeholder={t('EMAIL_OR_MOBILE_NUMBER')}
            name="email_mobile_number"
            value={values['email_mobile_number']}
            callback={handleChange('email_mobile_number')}
            autoFocus={true}
            maxLength={50}
            disabled={values?.emailFieldDisabled}
          />
        </Grid>

        {showLoginOption && (
          <Grid item xs={12}>
            {renderDropDown()}
          </Grid>
        )}

        {showLoginOption && (
          <Grid item xs={12}>
            <FormPasswordField
              name="password"
              placeholder={t('PASSWORD')}
              callback={handleChange('password')}
              onClickCallBack={handleClickShowPassword}
              OnMousePDownCallBack={handleMouseDownPassword}
              values={values}
            />
          </Grid>
        )}

        <Grid
          container
          item
          xs={12}
          justifyContent="flex-end"
          alignItems="center">
          <Link
            component="button"
            variant="body2"
            className="link-text"
            onClick={() => showForgotPassword()}
            disabled={isLoading}>
            {t('FORGOT_PASSWORD')}
          </Link>
        </Grid>
      </Grid>
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return <Grid style={{marginTop: '1rem'}}>{renderFormSection()}</Grid>;
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={1}>
        <Grid>
          <Typography className="permissions-text">
            {t('LOGIN_ACCEPTANCE_TEXT')}
            <Link
              component="button"
              variant="body2"
              className="link-text"
              style={{textDecoration: 'underLine'}}
              onClick={() =>
                CommonUtils.openNewTab(
                  props?.contactInfo?.termsAndConditionsUrl
                )
              }
              disabled={isLoading}>
              {t('TERMS_OF_SERVICE')}
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="submit-button"
            onClick={() => (showLoginOption ? handleSubmit() : getUsersList())}
            disabled={isLoading}>
            {isLoading ? (
              <Loader size={28} />
            ) : showLoginOption ? (
              t('LOGIN')
            ) : (
              t('NEXT')
            )}
          </Button>
        </Grid>

        <Grid
          xs
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Typography className="permissions-text" style={{marginTop: '3rem'}}>
            {t('ACCOUNT_TEXT')}
            <Link
              component="button"
              variant="body2"
              className="link-text"
              onClick={showRegistrationPopup}
              disabled={isLoading}>
              {t('CREATE_HERE')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className="person-login-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={(event: any, reason: string) => handleEscClose(event, reason)}
        aria-labelledby="person-login-pop-up"
        aria-describedby="person-login"
        classes={{paper: 'person-doctor-popup'}}>
        <Grid container spacing={1}>
          <Grid
            item
            sx={{display: {xs: 'none', lg: 'block'}}}
            lg={5}
            className="popup-left-section">
            <DialogLeftSideIllustrationsComponent
              title={t('PERSON_LOGIN_TITLE')}
              footerText={t('LOGIN_SIGN_UP_FOOTER_TEXT', {
                appName: `${t('APP_NAME')}`
              })}
              specialtiesList={CommonUtils.getLoginSignUpSpecialtiesList(t)}
            />
          </Grid>

          <Grid item lg={7} className="popup-right-section">
            <DialogTitle id="person-login-title" className="">
              <SecondaryCommonDialogTitleComponent
                title={t('PERSON_LOGIN')}
                subTitle={t('LOGIN_SUB_TITLE_TEXT', {
                  appName: `${t('APP_NAME')}`
                })}
                close={handleClose}
              />
            </DialogTitle>

            <DialogContent className="booking-appointment-popup-content">
              {renderDialogContent()}
            </DialogContent>

            <DialogActions
              disableSpacing={true}
              className="person-doctor-popup-actions">
              {renderDialogActions()}
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      {openRegistrationPopup && renderRegistrationPopup()}
      {openForgotPasswordPopup && renderForgotPasswordPopup()}
    </Grid>
  );
};

function mapStateToProps(state: any) {
  // Properties defined here (read from state) will be available as props in the component
  return {
    contactInfo: state.common.contactInfo
  };
}

export default connect(mapStateToProps, {})(PersonLoginComponent);
