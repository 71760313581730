import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as EmailIconSvg} from '@assets/images/healpha/emailIcon.svg';

const EmailIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={EmailIconSvg}
      viewBox="0 0 16 18"
    />
  );
};

export default EmailIcon;
