export const thresholdRangeArray = [
  {
    id: 1,
    name: 'Height',
    value: '154 - 189',
    min: 154,
    max: 189
  },
  {
    id: 2,
    name: 'Weight',
    min: 75,
    max: 80,
    value: '75 - 80'
  },
  {
    id: 3,
    name: 'BMI',
    min: 18.5,
    max: 24.9,
    value: '18.5 - 24.9'
  },
  {
    id: 3,
    name: 'Systolic',
    min: 154,
    max: 189,
    value: '154- 189'
  },
  {
    id: 4,
    name: 'Diastolic',
    min: 75,
    max: 80,
    value: '75 - 80'
  },
  {
    id: 5,
    name: 'Pulse Rate',
    min: 60,
    max: 100,
    value: '60 - 100'
  },
  {
    id: 6,
    name: 'Oxygen Saturation',
    min: 95,
    max: 100,
    value: '95% & more'
  },
  {
    id: 7,
    name: 'PEFR/Spirometry',
    min: 450,
    max: 550,
    value: '450 - 550'
  },
  {
    id: 8,
    name: 'Respiratory Rate',
    min: 75,
    max: 80,
    value: '75 - 80'
  },
  {
    id: 9,
    name: 'Temperature',
    min: 97,
    max: 99,
    value: '97°F - 99°F'
  }
];
