/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import {updatePersonProfile} from '@shared/services/PublicService';

import Icons from '@components/system/MyIcons/index';
import Loader from '@components/framework/loader/Loader';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import './EmailUpdateComponent.scss';
import CommonUtils from 'src/utils/CommonUtils';

const EmailUpdateComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [values, setValues]: any = React.useState({
    email: ''
  });

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      if (!values?.email) {
        enqueueSnackbar(t('EMAIL_REQ'), SnackBarConfig('w'));
        return;
      }

      if (!CommonUtils.isEmailValid(values.email)) {
        enqueueSnackbar(t('INVALID_EMAIL'), SnackBarConfig('w'));
        return;
      }

      const data = {
        email: values?.email
      };

      setIsLoading(true);
      await updatePersonProfile(props?.hlpId, data);
      props?.callback(values?.email);
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar(
        (err as any)?.message || t('ERROR_ENCOUNTERED'),
        SnackBarConfig('e')
      );
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="sm"
      className="email-update-wrapper"
      open={props?.open}>
      <DialogTitle className="title-wrapper">
        <Typography className="header-text person-doctor-sub-title">
          {t('EMAIL_MANDATORY_PAYMENT')}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormTextField
          placeholder={t('EMAIL_ID_PLACEHOLDER')}
          name="email"
          value={values['email']}
          callback={handleChange('email')}
          adornmentPosition="start"
          Icon={Icons.MailIcon}
        />
      </DialogContent>

      <DialogActions disableSpacing={true} className="action-wrapper">
        <Button
          variant="contained"
          size="medium"
          color="primary"
          style={{marginTop: '10rem'}}
          disabled={isLoading}
          className="submit-button"
          onClick={handleSubmit}>
          {isLoading ? <Loader size={28} /> : t('SUBMIT_LABEL')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailUpdateComponent;
