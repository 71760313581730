/* eslint-disable camelcase */
import React, {useEffect, useMemo, useState} from 'react';
import {
  //   Paper,
  Grid,
  IconButton,
  Box,
  FormControl,
  FormLabel,
  Typography,
  Button,
  Avatar,
  TextField,
  Dialog
} from '@material-ui/core';
import {
  AppointmentTypelist,
  CurrencyTypes
} from 'src/constants/SystemConstants';
import moment from 'moment';
import {StepSix} from '../AppointmentSteps';
import {
  // noop,
  capitalize,
  isEmpty,
  // every,
  upperCase,
  startCase,
  clone,
  get
} from 'lodash';
import {
  getDoctorById,
  bookAppointment,
  rescheduleAppointment,
  getAppointmentTypes,
  searchPerson,
  getDoctorsByBranch
  // getPaymentCriteria
} from '@shared/services/PublicService';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import CloseBlackIcon from '@components/system/MyIcons/CloseBlackIcon';
import CustomAutoCompleteField from '@components/system/CustomAutoCompleteField/CustomAutoCompleteField';
// import Icon from '@components/system/MyIcons/StethoscopeIcon';
// import SelectDropdown from '../SelectDropdown';
import AuthUtils from 'src/utils/AuthUtils';
import Icons from '@components/system/MyIcons';
import CommonUtils from 'src/utils/CommonUtils';
import {DoctorSpecializations} from 'src/constants/FormFields';
import {
  GET_8_PIXEL_BOX,
  DEFAULT_WHITE_COLOR,
  MESSAGE_BORDER_COLOR,
  APP_PRIMARY_FONT_REGULAR,
  FONT_OTHER_COLOR
} from 'src/style/variable';
import _ from 'lodash';
import './CreateAppointmentModal.scss';
import FormSelectField from '@components/system/CustomFormField/FormSelectField';
// import SelectDropdown from '../SelectDropdown';
// import FormSelectField from '@components/system/CustomFormField/FormSelectField';
import {BORDER_TERTIARY_COLOR} from 'src/style/variable';
import {Select, OutlinedInput, MenuItem} from '@material-ui/core';
import {isMobile} from 'react-device-detect';

const inputProps = {
  font: `normal normal 400 1.1rem/1.5rem ${APP_PRIMARY_FONT_REGULAR}`,
  color: FONT_OTHER_COLOR,
  paddingTop: '0.5rem'
};

const CreateAppointmentModal = ({
  data,
  selectedDate: selDate,
  onClose,
  branchId,
  doctorId,
  nextPage,
  selectedAppointmentType,
  encounter_id,
  selectedPatient: selectedPatientDetails,
  reschedule,
  isOnline,
  appointment_id,
  appointment_end_time,
  isCorporate,

  isFromVirtualClinic,
  selectedAppointmentTypes
}: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [searchFieldOptions, setSearchFieldOptions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [appointmentTypes, setAppointmentTypes] = useState<any>([]);
  const [doctors, setDoctors] = useState<any>([]);
  const [doctorSelected, setDoctorSelected] = React.useState<boolean>(false);
  const [doctorSearchText, setDoctorSearchText] = React.useState<string>('');
  const [selectedDoctorDetails, setSelectedDoctorDetails]: any = React.useState(
    {}
  );
  const [encounters]: //  setEncounters
  any = React.useState([]);

  const dropDownProps = {
    height: '2.9rem',
    width: '100%',
    border: `0.0625rem solid ${BORDER_TERTIARY_COLOR}`
  };

  const [appointmentState, setAppointmentState] = useState<any>({
    selectedPatient: selectedPatientDetails || {},
    appointmentType: selectedAppointmentType || '',
    selectedDate: selDate || null,
    compliant: '',
    doctorId: '' + doctorId || '',
    encounter_id: encounter_id || ''
  });
  const [searchText, setSearchText] = React.useState<string>(
    selectedPatientDetails
  );
  const isPatientSelected = !isEmpty(appointmentState?.selectedPatient);
  const getCurrentState = useMemo(
    () => (): Promise<any> =>
      new Promise(resolve =>
        setAppointmentState((currentState: any) => {
          resolve(currentState);
          return currentState;
        })
      ),
    [setAppointmentState]
  );
  const setPartialState = useMemo(
    () => async (field: string, value: any) => {
      const currentState = await getCurrentState();
      setAppointmentState({...currentState, [field]: value});
    },
    [getCurrentState]
  );

  const handleChange = (e: any) => {
    setPartialState(
      'appointmentType',
      appointmentTypes.find(
        (i: any) => i.appointment_type_value === e.target.value
      )
    );
  };

  const formatElement = (option: any): string => {
    let element = `${option?.hlpid} | ${CommonUtils.getFullName(option)}`;

    element = `${element}, ${option?.age} ${option?.gender} | ${option?.dob} | ${option?.phone_no} `;

    if (option?.father_name) {
      element = `${element} | ${option?.father_name}`;
    }

    return element;
  };

  const loadData = async (searchTerm: string): Promise<any> => {
    if (searchTerm) {
      setLoading(true);
      searchPerson(searchTerm)
        .then(res => {
          const response = res?.data?.persons;
          const formattedOptions = response.map((element: any) => ({
            ...element,
            formattedDetails: formatElement(element)
          }));
          setSearchFieldOptions(formattedOptions);
          setDoctors([]);
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const constructDateTime = () => {
    const copyDate = clone(appointmentState?.selectedDate);
    return moment(copyDate).format('DD/MM/YYYY, hh:mmA');
  };

  // const getInitAppointmentTypes = () => {
  //   if (branchId && appointmentState?.doctorId) {
  //     getAppointmentTypes(appointmentState?.doctorId, branchId)
  //       .then(res => {
  //         if (res?.status_code !== 200) throw new Error(res?.message);
  //         setAppointmentTypes(res?.data?.types || []);
  //       })
  //       .catch(err => {
  //         enqueueSnackbar(
  //           err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
  //           SnackBarConfig('e')
  //         );
  //       });
  //   }
  // };
  // useEffect(getInitAppointmentTypes, [branchId, appointmentState?.doctorId]);

  const getInitAppointmentTypes = () => {
    if (branchId && appointmentState?.doctorId) {
      getAppointmentTypes(appointmentState?.doctorId, branchId)
        .then(res => {
          if (res?.status_code !== 200) throw new Error(res?.message);
          // setAppointmentTypes(res?.data?.types || []);
          isFromVirtualClinic
            ? setAppointmentTypes(selectedAppointmentTypes)
            : setAppointmentTypes(res?.data?.types || []);
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    }
  };
  useEffect(getInitAppointmentTypes, [branchId, appointmentState?.doctorId]);

  // const getEncounter = () => {
  //   getPaymentCriteria(
  //     appointmentState?.selectedPatient?.hlpid,
  //     branchId,
  //     doctorId
  //   )
  //     .then(res => {
  //       setEncounters(res?.data?.criteria?.visit_ids || []);
  //     })
  //     .catch(err => {
  //       enqueueSnackbar(
  //         err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
  //         SnackBarConfig('e')
  //       );
  //     });
  // };

  const getDoctors = (text: string): void => {
    setDoctors([]);
    getDoctorsByBranch(branchId, text)
      .then((res: any) => {
        if (res?.data?.doctors?.length > 0) {
          const formattedOptions = res.data.doctors.map((element: any) => ({
            ...element,
            formattedDetails: CommonUtils.getFullName(element)
          }));

          setDoctors(formattedOptions);
        }
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const getDoctorDetails = (nonHealphaUserId: string): void => {
    getDoctorById(nonHealphaUserId, '', '')
      .then(response => {
        const doctor = response?.data;
        setSelectedDoctorDetails(doctor);
        doctor.branches = response?.data?.branches.filter(
          (item: any) => item.branch
        );
        doctor.appointment_types = CommonUtils.makeAppointmentTypes(
          doctor?.price_list
        );
        // const branch =
        //   doctor.branches.find((i: any) => i.branch_id === branchId) || {};
        const types = CommonUtils.formAppointmentTypes(branchId, doctor, true);
        types.sort(function (a: any, b: any) {
          return a.price - b.price;
        });
        setAppointmentTypes(types || []);
      })
      .catch((err: any) => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const onChange = (newValue: any, id: any) => {
    if (id == appointmentState?.selectedPatient) {
      setSearchText(newValue);
      setPartialState('selectedPatient', newValue);
    } else if (id == doctorId) {
      setDoctorSelected(true);
      setDoctorSearchText(newValue);
      setPartialState('doctorId', '' + newValue?.non_healpha_user_id);
      // setSelectedDoctorId(newValue?.non_healpha_user_id);
      getDoctorDetails(newValue?.non_healpha_user_id);
    }
  };

  // search input field on text change callback
  const onInputChange = (text: string, id: any) => {
    if (id == doctorId) {
      setDoctorSearchText(text);
    }
  };

  const apiCallback = (text: string, id: any) => {
    if (id == appointmentState?.selectedPatient) {
      setSearchText(text);
    } else if (id == doctorId) {
      setDoctorSearchText(text);
    }

    if (text?.length > 2) {
      if (id == appointmentState?.selectedPatient) {
        loadData(text);
      } else if (id == doctorId) {
        getDoctors(text);
      }
    }
  };

  const textFieldProps = {
    border: `0.0625rem solid ${MESSAGE_BORDER_COLOR}`,
    borderRadius: GET_8_PIXEL_BOX(0.75),
    background: `${DEFAULT_WHITE_COLOR} 0% 0% no-repeat padding-box`
  };

  const closeDoctorCard = (): void => {
    setDoctorSelected(false);
    setDoctors([]);
    setDoctorSearchText('');
    // setBranchSelected(false);
  };

  const renderSearchField = (
    options: any,
    field: any,
    id: any,
    placeholderText: string,
    noOptionsText: string,
    isLoading: boolean,
    disabled: boolean,
    fieldSearchText: any,
    className?: string
  ): React.ReactElement => {
    return (
      <Grid>
        <CustomAutoCompleteField
          options={options}
          field={field}
          infoField={''}
          id={id}
          disabled={disabled}
          required={false}
          placeHolder={placeholderText}
          onChange={onChange}
          onInputChange={onInputChange}
          apiCallback={apiCallback}
          defaultValue={fieldSearchText || null}
          loading={isLoading}
          debounce={true}
          noOptionsText={noOptionsText}
          customTextFieldProps={textFieldProps}
          size="small"
          className={className}
        />
      </Grid>
    );
  };

  const renderKeyValueObject = (
    value: string,
    Icon?: any
  ): React.ReactElement => {
    if (!value) return <Grid />;
    return (
      <Grid
        item
        container
        spacing={1}
        alignItems="center"
        style={{paddingTop: '0.75rem'}}>
        {Icon && (
          <Grid item>
            <Icon />
          </Grid>
        )}
        <Grid item className="card-value">
          {value}
        </Grid>
      </Grid>
    );
  };

  const renderDoctorCard = (): React.ReactElement => {
    const specializationList = selectedDoctorDetails?.branches?.map(
      (item: any) => {
        return DoctorSpecializations?.[item?.specialization];
      }
    );
    const uniqueSpecialization = _.uniqBy(specializationList, (e: any) => {
      return e;
    });
    const specialization = uniqueSpecialization.join();
    const educationList = selectedDoctorDetails?.education?.map((item: any) => {
      return item?.edu_college;
    });
    const uniqueEducation = _.uniqBy(educationList, (e: any) => {
      return e;
    });
    const education = uniqueEducation.join();
    if (!selectedDoctorDetails?.branches) return <Grid />;
    return (
      <Grid className="patient-card-container">
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Avatar
              alt=""
              className="vc-patient-icon"
              src={CommonUtils.getFullImageUrl(
                selectedDoctorDetails?.profile_image
              )}
            />
          </Grid>

          <Grid item xs={8} container>
            <Grid item xs={12}>
              <Typography className="patient-name">
                {`${
                  selectedDoctorDetails?.salutation
                } ${CommonUtils.getFullName(selectedDoctorDetails)}`}
              </Typography>
            </Grid>
            {renderKeyValueObject(specialization)}
            {renderKeyValueObject(education)}
          </Grid>

          <Grid
            item
            xs={2}
            container
            justifyContent="flex-end"
            style={{cursor: 'pointer'}}>
            <Icons.CloseBlackIcon onClick={closeDoctorCard} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDoctorPatientInfo = (): React.ReactElement => {
    return (
      <Grid className="doctor-patient-container">
        {isPatientSelected && (
          <Grid>
            <Typography className="doctor-patient-title">
              {doctorSelected ? 'Doctor' : 'Select Doctor'}
            </Typography>

            {doctorSelected
              ? renderDoctorCard()
              : renderSearchField(
                  doctors,
                  'formattedDetails',
                  appointmentState?.doctorId,
                  'Search Doctor',
                  'Doctor not found',
                  false,
                  !isPatientSelected,
                  doctorSearchText
                )}
          </Grid>
        )}
      </Grid>
    );
  };

  const validData = (): boolean => {
    const copyRequiredData = {
      ...appointmentState,
      // doctorId: '' + doctorId,
      branchId
    };
    const fieldsToCheck = [
      'doctorId',
      'branchId',
      'selectedPatient.hlpid',
      'appointmentType.appointment_type_value',
      'appointmentType.price',
      'selectedDate',
      'compliant'
    ];
    return fieldsToCheck.every(fieldPath => {
      const fieldValue = get(copyRequiredData, fieldPath);
      switch (typeof fieldValue) {
        case 'string':
          return !isEmpty(fieldValue);
        case 'object':
          if (fieldValue instanceof Date) return !isNaN(fieldValue.getTime());
          return !isEmpty(fieldValue);
        default:
          return false;
      }
    });
  };
  const handleEvent = (doc_id: any) => {
    const date = moment(appointmentState?.selectedDate).format('YYYY-MM-DD');
    const statusCount = new CustomEvent('handleStatusCount', {
      detail: {date: date}
    });
    const initListener = new CustomEvent('initListener', {
      detail: {doc_id: doc_id}
    });
    const handlingDisplay = new CustomEvent('handlingDisplay', {
      detail: {date: date}
    });
    window.dispatchEvent(initListener);
    window.dispatchEvent(statusCount);
    window.dispatchEvent(handlingDisplay);
  };
  const closeModal = (): void => {
    const evt = new CustomEvent('closeModal', {});
    window.dispatchEvent(evt);
  };
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    const {
      selectedPatient = {},
      appointmentType = {},
      selectedDate = null,
      compliant = ''
    } = appointmentState;

    if (validData()) {
      setLoading(true);
      const payload = {
        person_id: selectedPatient.hlpid.includes("*") ? selectedPatient.hlpid_full : selectedPatient.hlpid,
        doctor_id: appointmentState?.doctorId,
        branch_id: branchId,
        appointment_date: moment(selectedDate).isValid()
          ? moment(selectedDate).format('YYYY-MM-DD')
          : '',
        appointment_time: moment(selectedDate).isValid()
          ? moment(selectedDate).format('HH:mm')
          : '',
        appointment_start_time: moment(selectedDate).isValid()
          ? moment(selectedDate).format('HH:mm')
          : '',
        appointment_end_time: appointment_end_time,
        appointment_type: appointmentType?.appointment_type_value,
        encounter_id:
          appointmentType?.appointment_type_name === AppointmentTypelist.review
            ? appointmentState?.encounter_id
            : '',
        description: compliant,
        amount: appointmentType?.price,
        type: appointmentType?.appointment_type_value
      };
      if (reschedule) {
        rescheduleAppointment(appointment_id, payload)
          .then((res: any) => {
            if (res?.status_code === 200) {
              closeModal();
              handleEvent(appointmentState?.doctorId);
              nextPage();
              enqueueSnackbar(res?.message, SnackBarConfig('s'));
            } else {
              throw new Error(res?.message);
            }
          })
          .catch((err: any) => {
            enqueueSnackbar(
              err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        bookAppointment(selectedPatient.hlpid, payload)
          .then(res => {
            if (res?.status_code === 200) {
              handleEvent(appointmentState?.doctorId);
              nextPage({...appointmentState, ...res.data});
            } else {
              throw new Error(res?.message);
            }
          })
          .catch(err => {
            enqueueSnackbar(
              err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <Grid className="create-appointment-modal-wrapper">
      <Grid item xs={12} sm={4} />
      <Grid
        item
        xs={12}
        sm={4}
        id="create-appointment"
        className="create-appointment-container"
        style={{
          // height: selectedAppointmentType ? '38.9005rem' : '41.8125rem',
          width: isMobile ? '100%' : '31.1875rem'
        }}>
        <Grid container className="appointment-containerTitle">
          <Grid className="d-flex justify-content-between m-2">
            <Grid>
              <Box className="appointment-title mt-2">
                {t(
                  selectedAppointmentType
                    ? 'CLINIC_CONFIRM_APPOINTMENT'
                    : 'CLINIC_CREATE_APPOINTMENT'
                )}
              </Box>
            </Grid>
            <Grid>
              <IconButton
                id="confirm_appointment_close_popup"
                onClick={onClose}>
                <CloseBlackIcon />
              </IconButton>
            </Grid>
          </Grid>
          {/* </Grid> */}
          <Grid
            container
            item
            mb={2}
            wrap="nowrap"
            justifyContent="space-between"
            alignItems="flex-start">
            <Grid
              container
              item
              direction="column"
              className="appointment-type">
              {!selectedAppointmentType && (
                <Grid item>
                  <Typography className="my-2">
                    {t('CLINIC_SELECT_PATIENT')}
                  </Typography>
                  {renderSearchField(
                    searchFieldOptions,
                    'formattedDetails',
                    appointmentState?.selectedPatient || null,
                    t('CLINIC_SEARCH_PATIENT'),
                    '',
                    false,
                    false,
                    searchText,
                    'new-patient-appointment-search-option options-list-schedular'
                  )}
                </Grid>
              )}
              {isPatientSelected && (
                <>
                  <Grid container item xs={12} className="appointment-patient">
                    <Grid item>
                      <Typography fontWeight="bold" variant="h4">
                        {startCase(
                          CommonUtils.getFullName(
                            appointmentState?.selectedPatient
                          )
                        )}
                      </Typography>
                      <Typography className="appointment-patient-text text-capitalize">
                        {`${appointmentState?.selectedPatient?.gender || ''} ${
                          appointmentState?.selectedPatient?.age?.split(
                            ' '
                          )?.[0] || ''
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Avatar
                        src={CommonUtils.getFullImageUrl(
                          appointmentState?.selectedPatient?.person_image
                        )}
                        alt="Daniel"
                        className="avatar">
                        {upperCase(
                          appointmentState?.selectedPatient?.first_name?.[0]
                        )}
                      </Avatar>
                    </Grid>
                  </Grid>
                  {!doctorId && renderDoctorPatientInfo()}
                  {/* {!selectedAppointmentType ? ( */}
                  <FormControl
                    className="outlined-textfield create-appointment-wrapper"
                    variant="standard">
                    <FormLabel
                      htmlFor="create-appointment-type "
                      className="d-flex">
                      <Typography className="my-1">
                        {t('CLINIC_APPOINTMENT_TYPE')}
                      </Typography>
                    </FormLabel>
                    <Select
                      disabled={reschedule && isOnline}
                      sx={dropDownProps}
                      name="appointmentType"
                      displayEmpty
                      value={
                        appointmentState?.appointmentType
                          ?.appointment_type_value || ''
                      }
                      input={<OutlinedInput style={inputProps} />}
                      inputProps={inputProps}
                      onChange={handleChange}>
                      <MenuItem disabled value="">
                        <em>{t('SELECT_DOCTOR_APPOINTMENT_TYPE')}</em>
                      </MenuItem>

                      {appointmentTypes?.map((item: any, idx: any) => (
                        <MenuItem
                          key={idx}
                          value={item?.appointment_type_value}>
                          {`${item?.appointment_type_name} -${CurrencyTypes?.RUPEE?.label}.${item?.price}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* ) : (
                    <Grid>
                      <Typography variant="h5" className="appointment-subTitle">
                        {t('CLINIC_APPOINTMENT_TYPE')}
                      </Typography>
                      <Typography variant="h5" className="appoint-type-details">
                        {`${selectedAppointmentType?.appointment_type_name}-${CurrencyTypes?.RUPEE?.label}.${selectedAppointmentType?.price}`}
                      </Typography>
                    </Grid>
                  )} */}
                  {appointmentState?.appointmentType?.appointment_type_name ===
                  AppointmentTypelist.review ? (
                    !encounter_id ? (
                      <FormControl
                        className="outlined-textfield create-appointment-wrapper"
                        variant="standard">
                        <FormLabel htmlFor="encounter_id " className="d-flex">
                          <Typography className="my-1">
                            {t('SELECT_ENCOUNTER_ID')}
                          </Typography>
                        </FormLabel>
                        <FormSelectField
                          name="Appointment"
                          dropDownOptions={
                            encounters?.map((i: any) => {
                              return {
                                value: i?.id,
                                label: i.id
                              };
                            }) || []
                          }
                          value={appointmentState?.encounter_id}
                          placeholder={t('SELECT_ENCOUNTER_ID')}
                          dropDownProps={dropDownProps}
                          callback={(e: any) => {
                            setPartialState('encounter_id', e.target.value);
                          }}
                        />
                      </FormControl>
                    ) : (
                      <Grid>
                        <Typography
                          variant="h5"
                          className="appointment-subTitle">
                          {t('ENCOUNTER_ID')}
                        </Typography>
                        <Typography variant="h5">{encounter_id}</Typography>
                      </Grid>
                    )
                  ) : null}
                  <Grid item display="flex" flexDirection="column" mt={2}>
                    <Typography variant="h5" className="appointment-subTitle">
                      {t('CLINIC_APPOINTMENT_TIME')}
                    </Typography>
                    <Typography variant="h5" className="time-dates">
                      {constructDateTime()}
                    </Typography>
                  </Grid>
                  <Grid item mt={2} mb={1}>
                    <FormControl
                      fullWidth
                      className="outlined-textfield complaint-heading"
                      variant="standard">
                      <FormLabel htmlFor="step-five-compliant">
                        {t(reschedule ? 'REASON' : 'CLINIC_COMPLAINTS')}
                      </FormLabel>
                      <TextField
                        id="step-five-compliant"
                        aria-label="maximum height"
                        multiline
                        rows={3}
                        className="compliant-box"
                        placeholder={t('CLINIC_WRITE_HERE')}
                        defaultValue=""
                        value={appointmentState?.compliant}
                        onChange={event =>
                          setPartialState('compliant', event.target.value)
                        }
                        // style={{
                        //   border: '0.0625rem solid lightgrey',
                        //   borderRadius: '0.125rem',
                        //   padding: 2,
                        //   width: '100%'
                        // }}
                        required
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {isPatientSelected && (
            <Grid container item justifyContent="center" alignItems="center">
              <Grid item className="appointment-confirm-button">
                <Button
                  id="appointment_confirm_button"
                  disabled={loading || !validData()}
                  type="submit"
                  fullWidth
                  className="button-confirm"
                  onClick={onSubmitHandler}
                  variant="contained">
                  {t('CLINIC_CONFIRM_APPOINTMENT')}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} />
    </Grid>
  );
};

const appointmentBookingSteps = ['CREATE', 'BOOKED'];

const BasicLayoutComponent = ({
  modalVisible,
  setModalVisible,
  branch_id,
  cellData,
  doctor_id,
  appointmentType,
  encounter_id,
  selectedPatient,
  onCancel,
  init = null,
  reschedule,
  isOnline,
  appointment_id,
  isCorporate,

  isFromVirtualClinic,
  selectedAppointmentTypes
}: any): React.ReactElement => {
  const [currentModal, setCurrentModal] = useState<string>(
    appointmentBookingSteps[0]
  );
  const [commonData, setCommonData] = useState<any>({});

  // console.log(cellData?.endDate, 'endDate');

  return (
    <Dialog
      open={modalVisible}
      onClose={onCancel}
      classes={{paper: 'new-appointment-bookings-popup'}}>
      <Grid>
        {currentModal === appointmentBookingSteps[0] && (
          <CreateAppointmentModal
            selectedDate={cellData?.startDate}
            appointment_end_time={cellData?.endDate}
            doctorId={
              (cellData?.groupingInfo && cellData?.groupingInfo?.[0]?.id) ||
              doctor_id
            }
            selectedAppointmentType={appointmentType}
            encounter_id={encounter_id}
            reschedule={reschedule}
            isOnline={isOnline}
            selectedAppointmentTypes={selectedAppointmentTypes}
            isFromVirtualClinic={isFromVirtualClinic}
            appointment_id={appointment_id}
            selectedPatient={
              selectedPatient
                ? {
                    ...selectedPatient,
                    fullName: selectedPatient?.full_name,
                    displayText: `${selectedPatient?.full_name}, ${
                      selectedPatient?.age || ''
                    } ${capitalize(selectedPatient?.gender) || ''}`
                  }
                : {}
            }
            branchId={branch_id}
            onClose={onCancel}
            nextPage={(data: any) => {
              if (reschedule) {
                setModalVisible(false);
              } else {
                setCommonData(data);
                setCurrentModal(appointmentBookingSteps[1]);
              }
            }}
          />
        )}

        {currentModal === appointmentBookingSteps[1] && (
          <StepSix
            doctorId={
              (cellData?.groupingInfo && cellData?.groupingInfo?.[0]?.id) ||
              doctor_id
            }
            branchId={branch_id}
            data={{
              formFour: {
                ...commonData,
                selectedDate: moment(commonData.selectedDate)
              }
            }}
            dispatch={() => {
              setModalVisible(false);
              setCurrentModal(appointmentBookingSteps[0]);
              init && init();
              AuthUtils.setIsAppointmentNotify(true);
            }}
          />
        )}
      </Grid>
    </Dialog>
  );
};

export default BasicLayoutComponent;
