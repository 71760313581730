/* eslint-disable camelcase */
import React from 'react';
import './HospitalQrPopup.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@components/system/MyIcons/CloseIcon';
import CommonUtils from 'src/utils/CommonUtils';
import HospitalIcon from '@components/system/MyIcons/HospitalIcon';
import DownloadIconSvgIcon from '@components/system/MyIcons/DownloadIcon';
import {useTranslation} from 'react-i18next';
const HospitalQrPopUp = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(props?.open);
  const [info, setInfo]: any = React.useState({});

  const getHospitalDetails = () => {
    setInfo(props?.hospitalInfo);
  };
  const init = () => {
    getHospitalDetails();
  };

  React.useEffect(init, [props?.hospitalInfo]);
  const handleClose = () => {
    setOpen(false);
    if (
      props?.closeQrpagePopup &&
      typeof props.closeQrpagePopup === 'function'
    ) {
      props.closeQrpagePopup();
    }
  };
  const handleDownload = (e: any, val: any) => {
    fetch(val, {
      method: 'GET',
      headers: {}
    }).then(response => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'qr.png');
        document.body.appendChild(link);
        link.click();
      });
    });
  };
  const renderHeading = () => {
    const profileImage = CommonUtils.getBranchImageUrl(info.image);
    const name = info.branch_name || info.practice_name;
    return (
      <Grid className="headingWrapper" container xs={12}>
        <Grid className="closeIcon" item xs={12}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid className="headingGrid" container spacing={1}>
          <Grid className="imageGrid" item>
            {profileImage ? (
              <img className="img" src={profileImage} />
            ) : (
              <HospitalIcon className="img default-img" />
            )}
          </Grid>
          <Grid className="hospitalName" item>
            <Typography className="name">{name ? name : '-'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const renderQr = () => {
    const QrImage = CommonUtils.getBranchImageUrl(info.qrcode);
    return (
      <Grid className="QrWrapper" container xs={12}>
        <Grid className="QrGrid" item>
          {QrImage ? (
            <img className="Qr" src={QrImage} />
          ) : (
            <Typography>{t('QR_NOT_GENERATED')}</Typography>
          )}
        </Grid>
        <Grid className="TextGrid" item>
          <Typography>
            {t('SCAN_QR_TO_BOOK_APPOINTMENT')}
            <Typography className="branchName">
              {' '}
              {info.branch_name || info.practice_name}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const renderDownloadButtons = () => {
    return (
      <Grid className="ButtonWrapper" container xs={12}>
        <Grid item xs={12} className="buttonGrid">
          <Button
            className="button"
            variant="contained"
            startIcon={<DownloadIconSvgIcon />}
            onClick={e => {
              handleDownload(e, CommonUtils.getFullImageUrl(info.qrcode));
            }}>
            <Typography className="downloadFont">{t('DOWNLOAD')}</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid>
      <Dialog
        className="mainWrapper"
        open={open}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '440px',
              minHeight: '500px'
            }
          }
        }}>
        <DialogTitle>{renderHeading()}</DialogTitle>
        <DialogContent>{renderQr()}</DialogContent>
        <DialogActions>{renderDownloadButtons()}</DialogActions>
      </Dialog>
    </Grid>
  );
};
export default HospitalQrPopUp;
