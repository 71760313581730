// import React from 'react';
// import { StepOneProps } from 'src/modules/user/modules/MicroTemplate/AppointmentSteps/StepOne';
import {
  StepThree,
  StepTwo,
  StepFive,
  StepFour,
  StepOne,
  StepSix
} from '../modules/user/modules/ClinicDashboard/AppointmentSteps';

export const appointmentSteps: any = {
  stepOne: StepOne,
  stepTwo: StepTwo,
  stepThree: StepThree,
  stepFour: StepFour,
  stepFive: StepFive,
  stepSix: StepSix
  // stepOne: (props: StepOneProps): React.ReactElement => <StepOne {...props} />,
  // stepTwo: (props: StepOneProps): React.ReactElement => <StepOne {...props} />,
};
