export const walletList: any = [
  {
    value: 'paytm',
    label: 'Paytm'
  },
  {
    value: 'phone pe',
    label: 'Phone pe'
  },
  {
    value: 'google pay',
    label: 'Google pay'
  },
  {
    value: 'tata nu',
    label: 'Tata nu'
  }
];
export const modulesList: any = [
  {
    value: 'school',
    label: 'School'
  },
  {
    value: 'panchayat',
    label: 'Panchayat'
  },
  {
    value: 'general',
    label: 'General'
  },
  {
    value: 'communtity',
    label: 'Communtity'
  }
];
export const genderOptionsList: any = [
  {
    value: 'male',
    label: 'MALE',
    key: 'male'
  },
  {
    value: 'female',
    label: 'FEMALE',
    key: 'female'
  },
  {
    value: 'others',
    label: 'OTHERS',
    key: 'others'
  }
];

export const onlineOffline: any = [
  {
    value: 'online',
    label: 'ONLINE',
    key: 'online'
  },
  {
    value: 'offline',
    label: 'OFFLINE',
    key: 'offline'
  }
];

export const GenderTypes: any = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other'
};

export const VitalKeys: any = {
  HEIGHT: 'height',
  WEIGHT: 'weight',
  BMI: 'bmi',
  SYSTOLIC: 'systolic',
  DIASTOLIC: 'diastolic',
  PULSE_RATE: 'pulse_rate',
  OXYGEN_SATURATION: 'oxygen_saturation',
  PEFR: 'pefr',
  RESPIRATORY_RATE: 'respiratory_rate',
  TEMPERATURE: 'temperature'
};

export const Relations: any = {
  SON: 'S/O',
  DAUGHTER: 'D/O'
};

export const salutationOptionsList: any = [
  {
    value: 'Mr',
    label: 'COMMON_MR'
  },
  {
    value: 'Mrs',
    label: 'COMMON_MRS'
  },
  {
    value: 'Ms',
    label: 'COMMON_MS'
  },
  {
    value: 'Dr',
    label: 'COMMON_DR'
  }
];

export const familyRelationsList: any = [
  {
    value: 'Father',
    label: 'FATHER'
  },
  {
    value: 'Mother',
    label: 'MOTHER'
  },
  {
    value: 'Brother',
    label: 'BROTHER'
  },
  {
    value: 'Sister',
    label: 'SISTER'
  },
  {
    value: 'Wife',
    label: 'WIFE'
  },
  {
    value: 'Son',
    label: 'SON'
  },
  {
    value: 'Daughter',
    label: 'DAUGHTER'
  },
  {
    value: 'Guardian',
    label: 'GUARDIAN'
  }
];

export const topicOptionsList: any = [
  {
    value: 'symptoms',
    label: 'SYMPTOMS'
  },
  {
    value: 'treatment',
    label: 'TREATMENT'
  },
  {
    value: 'info',
    label: 'INFO'
  }
];

export const allergyTypeList: any = [
  {
    value: 'food',
    label: 'MD_FOOD'
  },
  {
    value: 'animal',
    label: 'MD_ANIMAL'
  },
  {
    value: 'plants/flowers',
    label: 'PLANTS_FLOWERS'
  },
  {
    value: 'medicine',
    label: 'CONSULTANT_MEDICINE_NAME'
  },

  {
    value: 'dust/smoke',
    label: 'DUST_SMOKE'
  }
];

export const severityList: any = [
  {
    value: 'Absent',
    label: 'MD_ABSENT'
  },
  {
    value: 'Mild',
    label: 'MILD'
  },
  {
    value: 'Moderate',
    label: 'MODERATE'
  },
  {
    value: 'Severe',
    label: 'SEVERE'
  }
];

export const statusList: any = [
  {
    value: '1',
    label: 'COMMON_ACTIVE'
  },
  {
    value: '0',
    label: 'COMMON_INACTIVE'
  }
];

export const healthEventTypeList: any = [
  {
    value: 'Surgery',
    label: 'SURGERY'
  },
  {
    value: 'Illness',
    label: 'MD_ILLNESS'
  },
  {
    value: 'Accident',
    label: 'ACCIDENT'
  }
];

export const vaccineForList: any = [
  {
    value: 'Cholera',
    label: 'CHOLERA'
  },
  {
    value: 'Typhoid',
    label: 'MD_TYPHOID'
  },
  {
    value: 'Fever',
    label: 'FEVER'
  }
];

export const medicineFrequencyDropDown: any = [
  {
    value: 'Daily',
    label: 'DAILY'
  },
  {
    value: 'Weekly',
    label: 'CLINIC_WEEKLY'
  },
  {
    value: 'Monthly',
    label: 'MONTHLY'
  }
];

export const medicineDurationDropDown: any = [
  {
    value: 'Days',
    label: 'DAYS'
  },
  {
    value: 'Weeks',
    label: 'WEEKS'
  },
  {
    value: 'Months',
    label: 'MONTHS'
  },
  {
    value: 'Years',
    label: 'YEARS'
  }
];

export const medicineTypeDropDown: any = [
  {
    value: 'TAB',
    label: 'TAB'
  },
  {
    value: 'OINTMENT',
    label: 'OINTMENT'
  },
  {
    value: 'GEL',
    label: 'GEL'
  },
  {
    value: 'CAP',
    label: 'CAP'
  },
  {
    value: 'SYRUP',
    label: 'SYRUP'
  },
  {
    value: 'DROPS',
    label: 'DROPS'
  },
  {
    value: 'SUSPENSION',
    label: 'SUSPENSION'
  }
];

export const medicineUnitDropDown: any = [
  {
    value: 'ML',
    label: 'ML'
  },
  {
    value: 'MG',
    label: 'MG'
  },
  {
    value: 'TAB',
    label: 'TAB'
  },
  {
    value: 'GM',
    label: 'GM'
  },
  {
    value: 'CAP',
    label: 'CAP'
  }
];

export const patientVitalsList: any = [
  {
    id: 1,
    label: 'HEIGHT',
    value: 'height',
    isTextField: true
  },
  {
    id: 2,
    label: 'WEIGHT',
    value: 'weight',
    isTextField: true
  },
  {
    id: 3,
    label: 'MD_BMI',
    value: 'bmi',
    isBorderNeeded: true,
    isTextField: false
  },
  {
    id: 4,
    label: 'MD_SYSTOLIC',
    value: 'systolic',
    isTextField: true
  },
  {
    id: 5,
    label: 'MD_DIASTOLIC',
    value: 'diastolic',
    isTextField: true
  },
  {
    id: 6,
    label: 'PR(MIN)',
    value: 'pulse_rate',
    isTextField: true,
    isBorderNeeded: true
  },

  {
    id: 8,
    label: 'PEFR(MIN)',
    value: 'pefr',
    isTextField: true
  },

  {
    id: 10,
    label: 'MD_TEMP',
    value: 'temperature',
    isTextField: true
  },
  {
    id: 7,
    label: 'O2_SAT',
    value: 'oxygen_saturation',
    isTextField: true
  },
  {
    id: 9,
    label: 'RR(MIN)',
    value: 'respiratory_rate',
    isTextField: true
  }
];

export const DoctorSpecializations: any = {
  anesthesiology: 'Anesthesiology',
  cardiology: 'Cardiology',
  corona: 'Corona',
  dental: 'Dental',
  ent: 'ENT',
  family_practice: 'Family Practice',
  general_medicine: 'General Medicine',
  general_surgeon: 'General surgeon',
  genetics: 'Genetics',
  geriatric_medicine: 'Geriatric Medicine',
  hematology: 'Hematology',
  neurophysiology: 'Neurophysiology',
  nutrition: 'Nutrition',
  opthamology: 'Opthamology',
  pediatrics: 'Pediatrics',
  psychiatry: 'Psychiatry',
  'obstetrics & gynecology': 'Obstetrics & Gynecology',
  'clinical & laboratory': 'Clinical & Laboratory',
  'allergy & immunology': 'Allergy & Immunology'
};
// }
// export default formLable
export const countryOptionsList: any = [
  {
    value: 'Afghanistan',
    label: 'Afghanistan'
  },
  {
    value: 'Albania',
    label: 'Albania'
  },
  {
    value: 'Algeria',
    label: 'Algeria'
  },
  {
    value: 'Australia',
    label: 'Australia'
  },
  {
    value: '	Austria',
    label: '	Austria'
  },
  {
    value: 'Bangladesh',
    label: 'Bangladesh'
  },
  {
    value: 'Belgium',
    label: 'Belgium'
  },
  {
    value: '	Bhutan',
    label: '	Bhutan'
  },
  {
    value: 'Bolivia',
    label: 'Bolivia'
  },
  {
    value: 'Brazil',
    label: 'Brazil'
  },
  {
    value: 'Bulgaria',
    label: 'Bulgaria'
  },
  {
    value: 'Canada',
    label: 'Canada'
  },
  {
    value: 'China',
    label: 'China'
  },
  {
    value: 'Colombia',
    label: 'Colombia'
  },
  {
    value: 'Cuba',
    label: 'Cuba'
  },
  {
    value: '	Djibouti',
    label: '	Djibouti'
  },
  {
    value: 'Dominica',
    label: 'Dominica'
  },
  {
    value: 'Egypt',
    label: 'Egypt'
  },
  {
    value: 'Estonia',
    label: 'Estonia'
  },
  {
    value: 'Finland',
    label: 'Finland'
  },
  {
    value: 'France',
    label: 'France'
  },
  {
    value: 'Georgia',
    label: 'Georgia'
  },
  {
    value: 'Germany',
    label: 'Germany'
  },
  {
    value: 'Greece',
    label: 'Greece'
  },
  {
    value: '	Iceland',
    label: '	Iceland'
  },
  {
    value: 'India',
    label: 'India'
  },
  {
    value: '	Indonesia',
    label: '	Indonesia'
  },
  {
    value: 'Iran',
    label: 'Iran'
  },
  {
    value: 'Iraq',
    label: 'Iraq'
  },
  {
    value: 'Italy',
    label: 'Italy'
  },
  {
    value: '	Japan',
    label: '	Japan'
  },
  {
    value: 'Madagascar',
    label: 'Madagascar'
  },
  {
    value: 'Malaysia',
    label: 'Malaysia'
  },
  {
    value: 'Maldives',
    label: 'Maldives'
  },
  {
    value: 'Mexico',
    label: 'Mexico'
  },
  {
    value: '	Monaco',
    label: '	Monaco'
  },
  {
    value: 'Nepal',
    label: 'Nepal'
  },
  {
    value: 'Netherlands',
    label: 'Netherlands'
  },
  {
    value: 'New Zealand',
    label: 'New Zealand'
  },
  {
    value: '	North Korea',
    label: '	North Korea'
  },
  {
    value: 'Oman',
    label: 'Oman'
  },
  {
    value: 'Pakistan',
    label: 'Pakistan'
  },
  {
    value: '	Philippines',
    label: '	Philippines'
  },
  {
    value: '	Qatar',
    label: '	Qatar'
  },
  {
    value: '	Russia',
    label: '	Russia'
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia'
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia'
  },
  {
    value: '	South Africa',
    label: '	South Africa'
  },
  {
    value: 'South Korea',
    label: 'South Korea'
  },
  {
    value: 'Spain',
    label: 'Spain'
  },
  {
    value: 'Sri Lanka',
    label: 'Sri Lanka'
  },
  {
    value: 'Switzerland',
    label: 'Switzerland'
  },
  {
    value: 'Thailand',
    label: 'Thailand'
  },
  {
    value: 'Ukraine',
    label: 'Ukraine'
  },
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates'
  },
  {
    value: '	United Kingdom',
    label: '	United Kingdom'
  },
  {
    value: 'United States of America',
    label: 'United States of America'
  },
  {
    value: 'Zimbabwe',
    label: 'Zimbabwe'
  }
];

export const countryCodeOptionsList: any = [
  {
    value: '93',
    label: '93'
  },
  {
    value: '355',
    label: '355'
  },
  {
    value: '213',
    label: '213'
  },
  {
    value: '61',
    label: '61'
  },
  {
    value: '43',
    label: '43'
  },
  {
    value: '880',
    label: '880'
  },
  {
    value: '32',
    label: '32'
  },
  {
    value: '975',
    label: '975'
  },
  {
    value: '591',
    label: '591'
  },
  {
    value: '55',
    label: '55'
  },
  {
    value: '359',
    label: '359'
  },
  {
    value: '1',
    label: '1'
  },
  {
    value: '86',
    label: '86'
  },
  {
    value: '57',
    label: '57'
  },
  {
    value: '253',
    label: '253'
  },
  {
    value: '20',
    label: '20'
  },
  {
    value: '372',
    label: '372'
  },
  {
    value: '358',
    label: '358'
  },
  {
    value: '33',
    label: '33'
  },
  {
    value: '49',
    label: '49'
  },
  {
    value: '30',
    label: '30'
  },
  {
    value: '354',
    label: '354'
  }
];

export const roleOptionsList: any = [
  {
    value: 'Doctor',
    label: 'Doctor'
  },
  {
    value: 'OP Biller',
    label: 'OP Biller'
  },
  {
    value: 'Accountant',
    label: 'Accountant'
  },
  {
    value: 'Nurse',
    label: 'Nurse'
  }
];

export const valueOptionsList: any = [
  {
    value: 'School',
    label: 'School'
  },
  {
    value: 'Panchayat',
    label: 'Panchayat'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];
