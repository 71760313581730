import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as FaceBookIconSvg} from '@assets/images/healpha/FaceBook.svg';

const FaceBookIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={FaceBookIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default FaceBookIcon;
