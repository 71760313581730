/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Button,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {isMobile} from 'react-device-detect';

import {getAllUsers, loginPerson} from '@shared/services/PublicService';

import CommonUtils from 'src/utils/CommonUtils';
import Loader from '@components/framework/loader/Loader';
import Icons from '@components/system/MyIcons/index';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import FormPasswordField from '@components/system/CustomFormField/FormPasswordField';
import RegisterDialogTitleComponent from '../CommonDialogTitle/RegisterDialogTitleComponent';
import RegisterUserComponent from '../RegisterUser/RegisterUserComponent';
import TemporaryUserRegistration from '../TemporaryUserRegistration/TemporaryUserRegistration';
import ReviewUserAppointmentComponent from '../ReviewUserAppointment/ReviewUserAppointmentComponent';
import ForgotPasswordComponent from '../ForgotPassword/ForgotPasswordComponent';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {BORDER_TERTIARY_COLOR} from 'src/style/variable';
import './LoginUserComponent.scss';
import {connect} from 'react-redux';

const dropDownProps = {
  height: '3.9rem',
  width: '100%',
  border: `1px solid ${BORDER_TERTIARY_COLOR}`
};

const LoginUserComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(props?.open);
  const [openRegisterUserPopup, setOpenRegisterUserPopup] =
    React.useState(false);
  const [openTempUserRegPopup, setOpenTempUserRegPopup] = React.useState(false);
  const [openReviewPopup, setOpenReviewPopup] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [openForgotPasswordPopup, setOpenForgotPasswordPopup] =
    React.useState(false);
  const [dropDownOptions, setDropDownOptions]: any = React.useState([]);
  const [values, setValues]: any = React.useState({
    email_mobile_number: '',
    password: '',
    user: '',
    showPassword: false,
    disabled: false
  });

  const handleClose = (event?: any, reason?: any) => {
    setOpen(false);
    props?.closeLoginPopup();
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleBlur = () => {
    getAllUsers(values?.email_mobile_number)
      .then(res => {
        if (res?.data?.persons?.length === 1) {
          setValues({
            ...values,
            ['disabled']: true,
            ['user']: res?.data?.persons?.[0]?.hlpid
          });
          setDropDownOptions(res.data.persons);
        } else if (res?.data?.persons?.length > 0) {
          setDropDownOptions(res.data.persons);
        }
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const showUserRegPopup = (): void => {
    setOpen(false);
    setOpenRegisterUserPopup(true);
  };

  const showTemporaryUserRegistrationPopup = (): void => {
    setOpen(false);
    setOpenTempUserRegPopup(true);
  };

  const handleSubmit = async (): Promise<any> => {
    try {
      if (!values?.password || !values?.user) {
        enqueueSnackbar(t('EMAIL_PWD_REQ'), SnackBarConfig('w'));
        return;
      }

      const data = {
        password: values?.password,
        healpha_id: values?.user
      };

      setIsLoading(true);
      const res = await loginPerson(data);

      if (res?.data?.token) {
        CommonUtils.PerformPostLoginActions(
          res.data.token,
          res.data.user?.name
        );
        CommonUtils.setUserInfo(res.data.user);
        setIsLoading(false);
        setOpen(false);
        setOpenReviewPopup(true);

        // TODO: once payment integration done, remove below
        // if (
        //   props?.appointmentValues?.appointment?.appointment_type === 'online'
        // ) {
        //   const params: any = CommonUtils.formBookAppointmentProps(props);
        //   params.token = res.data.token;
        //   params.type = CommonUtils.PhpPortalParamTypes.PERSON_APPOINTMENT;
        //   CommonUtils.launchPhpPortal(params);
        // } else {
        //   setOpenReviewPopup(true);
        // }
      }
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar(
        (err as any)?.message || t('ERROR_ENCOUNTERED'),
        SnackBarConfig('e')
      );
    }
  };

  const closePopup = (): any => {
    setOpenRegisterUserPopup(false);
    handleClose();
  };

  const closeTempRegPopup = (): void => {
    setOpenTempUserRegPopup(false);
    handleClose();
  };

  const closeReviewPopUp = () => {
    setOpenReviewPopup(false);
    handleClose();
  };

  const showForgotPassword = (): void => {
    setOpenForgotPasswordPopup(true);
    setOpen(false);
  };

  const renderForgotPasswordPopup = (): React.ReactElement => {
    return (
      <ForgotPasswordComponent
        open={openForgotPasswordPopup}
        closeForgotPasswordPopup={handleClose}
      />
    );
  };

  const renderReviewPopup = (): React.ReactElement => {
    return (
      <ReviewUserAppointmentComponent
        open={openReviewPopup}
        appointmentValues={props?.appointmentValues}
        healphaId={values?.user}
        showAddPatientLabel={true}
        closeReviewPopUp={closeReviewPopUp}
      />
    );
  };

  const renderRegisterUserPopup = (): React.ReactElement => {
    return (
      <RegisterUserComponent
        open={openRegisterUserPopup}
        appointmentValues={props?.appointmentValues}
        action={closePopup}
      />
    );
  };

  const renderTempUserRegPopup = (): React.ReactElement => {
    return (
      <TemporaryUserRegistration
        open={openTempUserRegPopup}
        closeTempRegPopup={closeTempRegPopup}
        appointmentValues={props?.appointmentValues}
      />
    );
  };

  const renderHelperTextInfo = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={8} container direction="column">
          <div className="information-text">{t('LOGIN_EMAIL_MOBILE')}</div>

          <div className="information-text">
            {t('APPOINTMENT_EMAIL_MOBILE_TEXT')}
          </div>
        </Grid>

        <Grid
          xs
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center">
          <Button
            variant="outlined"
            className="action-button-container"
            onClick={() => showUserRegPopup()}
            disabled={isLoading}>
            {t('CREATE_AN_ACCOUNT')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderUsersDropDown = (): React.ReactElement => {
    return (
      <Select
        sx={dropDownProps}
        displayEmpty
        name="user"
        id="user"
        value={values?.user}
        disabled={values?.disabled}
        onChange={handleChange('user')}
        input={<OutlinedInput />}>
        <MenuItem disabled value="">
          <em style={{color: '#646467'}}>{t('SELECT_USER_LABEL')}</em>
        </MenuItem>

        {dropDownOptions?.map((item: any, index: number) => (
          <MenuItem key={index} value={item?.hlpid}>
            {`${item?.salutation}. ${item?.first_name} ${
              item?.middle_name ? item?.middle_name : ''
            } ${item?.last_name}`}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderFormSection = (): React.ReactElement => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormTextField
            placeholder={t('EMAIL_OR_MOBILE_NUMBER')}
            name="email_mobile_number"
            value={values['email_mobile_number']}
            callback={handleChange('email_mobile_number')}
            onBlurCallback={handleBlur}
          />
        </Grid>

        <Grid item xs={12}>
          {renderUsersDropDown()}
        </Grid>

        <Grid item xs={12}>
          <FormPasswordField
            name="password"
            placeholder={t('PASSWORD')}
            callback={handleChange('password')}
            onClickCallBack={handleClickShowPassword}
            OnMousePDownCallBack={handleMouseDownPassword}
            values={values}
          />
        </Grid>

        <Grid item xs={6}>
          <div className="permissions-text" style={{marginTop: '1rem'}}>
            {t('LOGGING_INFO')}
            <Link
              component="button"
              variant="body2"
              className="link-text"
              onClick={() =>
                CommonUtils.openNewTab(
                  props?.contactInfo?.termsAndConditionsUrl
                )
              }
              disabled={isLoading}>
              {t('TERMS_OF_SERVICE')}
            </Link>
          </div>
        </Grid>

        <Grid
          xs
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center">
          <Link
            component="button"
            variant="body2"
            className="link-text"
            onClick={() => showForgotPassword()}
            disabled={isLoading}>
            {t('FORGOT_PASSWORD')}
          </Link>
        </Grid>
      </Grid>
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <div>
        {renderHelperTextInfo()}

        <div style={{marginTop: '1rem'}}>{renderFormSection()}</div>
      </div>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="submit-button"
            onClick={() => handleSubmit()}
            disabled={isLoading}>
            {isLoading ? <Loader size={28} /> : t('LOGIN')}
          </Button>
        </Grid>
        {props?.appointmentValues?.appointment?.appointment_type !==
          'online' && (
          <Grid
            xs
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Link
              component="button"
              variant="body2"
              underline="always"
              className="skip-link"
              onClick={() => showTemporaryUserRegistrationPopup()}
              disabled={isLoading}>
              {t('SKIP_LOGIN')}
            </Link>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <div className="login-user-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        aria-labelledby="login-user-pop-up"
        aria-describedby="login-user"
        classes={{
          paper: 'landscape-orientation-popup booking-appointment-popup'
        }}>
        <Grid container spacing={1}>
          <Grid item lg={5} className="popup-left-section">
            <DialogTitle
              id="user-login-title"
              className="booking-appointment-popup-title">
              <RegisterDialogTitleComponent
                appointmentValues={props?.appointmentValues}
                close={handleClose}
              />
            </DialogTitle>
          </Grid>

          <Grid item lg={7}>
            {!isMobile && (
              <Grid className="register-close-icon">
                <IconButton onClick={handleClose}>
                  <Icons.CloseBlackIcon />
                </IconButton>
              </Grid>
            )}

            <DialogContent className="booking-appointment-popup-content">
              {renderDialogContent()}
            </DialogContent>

            <DialogActions
              disableSpacing={true}
              className={'booking-appointment-popup-actions'}>
              {renderDialogActions()}
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      {openRegisterUserPopup && renderRegisterUserPopup()}
      {openTempUserRegPopup && renderTempUserRegPopup()}
      {openReviewPopup && renderReviewPopup()}
      {openForgotPasswordPopup && renderForgotPasswordPopup()}
    </div>
  );
};

function mapStateToProps(state: any) {
  // Properties defined here (read from state) will be available as props in the component
  return {
    contactInfo: state.common.contactInfo
  };
}

export default connect(mapStateToProps, {})(LoginUserComponent);
