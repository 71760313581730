export default function CalendarBuild(
  value: any,
  startDay: any,
  endDay: any
): any {
  const day = startDay.clone().subtract(1, 'day');
  const calendar: any = [];
  while (day.isBefore(endDay, 'day')) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, 'day').clone())
    );
  }

  return calendar;
}
