import {
  SET_USER_INFO,
  CLEAR_USER_INFO,
  SET_BRANCH_ID,
  SET_ORG_ID,
  SET_DOCTOR_ID,
  SET_CLIENT_INFO,
  SET_ADVISOR_INFO,
  SET_CLIENT_FEATURES,
  SET_BRANCH_INFO,
  SET_DOCTOR_INFO,
  SNACKBAR_ACKNOWLEDGEMENT,
  SET_WHITELABLING_INFO,
  SET_CONTACTDETAILS_INFO,
  SET_USER_DETAILS
} from './Index';

export const _setUserInfo =
  (data: any, primaryColor?: string): any =>
  async (dispatch: any) => {
    dispatch({type: SET_USER_INFO, payload: data, primaryColor});
  };

export const _clearUserInfo = (): any => async (dispatch: any) => {
  dispatch({type: CLEAR_USER_INFO});
};

// org id info action
export const _setOrgId =
  (data: {orgId: string}): any =>
  async (dispatch: any) => {
    dispatch({type: SET_ORG_ID, payload: data});
  };

// Branch's Info actions
export const _setBranchId =
  (data: {branchId: string; practiceId: string}): any =>
  async (dispatch: any) => {
    dispatch({type: SET_BRANCH_ID, payload: data});
  };

export const _setClientInfo =
  (data: any): any =>
  async (dispatch: any) => {
    dispatch({type: SET_CLIENT_INFO, payload: data});
  };

export const _setBranchInfo =
  (data: any): any =>
  async (dispatch: any) => {
    dispatch({type: SET_BRANCH_INFO, payload: data});
  };

export const _setWhitelablingInfo =
  (data: any): any =>
  async (dispatch: any) => {
    dispatch({type: SET_WHITELABLING_INFO, payload: data});
  };

export const _setContactDetailsInfo =
  (data: any): any =>
  async (dispatch: any) => {
    dispatch({type: SET_CONTACTDETAILS_INFO, payload: data});
  };

// Advisor Info actions
export const _setDoctorId =
  (data: {doctorId: string}): any =>
  async (dispatch: any) => {
    dispatch({type: SET_DOCTOR_ID, payload: data});
  };

export const _setAdvisorInfo =
  (data: any): any =>
  async (dispatch: any) => {
    dispatch({type: SET_ADVISOR_INFO, payload: data});
  };

export const _setClientFeatures =
  (data: any[]): any =>
  async (dispatch: any) => {
    dispatch({type: SET_CLIENT_FEATURES, payload: data});
  };

export const _setDoctorInfo =
  (data: any): any =>
  async (dispatch: any) => {
    dispatch({type: SET_DOCTOR_INFO, payload: data});
  };

export const _snackbarAcknowledgement =
  (snackBarEnabled: any, snackBarMessage: any, snackBarType: any): any =>
  async (dispatch: any) => {
    dispatch({
      type: SNACKBAR_ACKNOWLEDGEMENT,
      payload: {snackBarEnabled, snackBarMessage, snackBarType}
    });
  };

export const _setUserDetails =
  (data: any): any =>
  async (dispatch: any) => {
    dispatch({type: SET_USER_DETAILS, payload: data});
  };
