/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {isMobile} from 'react-device-detect';

import {
  temporaryPersonSignUp,
  temporaryPersonLogin,
  bookDoctorAppointment,
  sendOtpToUser
} from '@shared/services/PublicService';
import {environment} from '@shared/environment/environment';

import RegisterDialogTitleComponent from '../CommonDialogTitle/RegisterDialogTitleComponent';
import ConfirmationPopupComponent from '../ConfirmationPopUp/ConfirmationPopupComponent';
import ConfirmationBoxComponent from '../ConfirmationBox/ConfirmationBoxComponent';
import CommonUtils from 'src/utils/CommonUtils';
import Icons from '@components/system/MyIcons/index';
import Loader from '@components/framework/loader/Loader';
import OtpScreenComponent from '@components/system/Dialogs/OtpScreen/OtpScreenComponent';
import FormCombinationField from '@components/system/CustomFormField/FormCombinationField';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import FormPhoneField from '@components/system/CustomFormField/FormPhoneField';
import FormSelectField from '@components/system/CustomFormField/FormSelectField';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {
  genderOptionsList,
  salutationOptionsList
} from 'src/constants/FormFields';
import {BORDER_TERTIARY_COLOR} from 'src/style/variable';
import './TemporaryUserRegistration.scss';

const dropDownProps = {
  height: '3.9rem',
  width: '100%',
  border: `1px solid ${BORDER_TERTIARY_COLOR}`
};

const TemporaryUserRegistration = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = React.useState(props?.open);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showOtpPopup, setShowOtpPopup] = React.useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] =
    React.useState<boolean>(false);
  const [openConfirmationBox, setOpenConfirmationBox] = React.useState(false);
  const [temporaryUserObject, setTemporaryUserObject]: any = React.useState();
  const [values, setValues]: any = React.useState({
    salutation: 'Mr',
    first_name: '',
    last_name: '',
    gender: '',
    phone_code: environment.dialCode,
    phone_number: ''
  });

  const handleClose = (event?: any, reason?: any) => {
    setOpen(false);
    props?.closeTempRegPopup();
  };

  const handleChange = (prop: any) => (event: any) => {
    if (prop === 'first_name' || prop === 'last_name') {
      if (
        /^[a-zA-Z]+$/.test(event?.target?.value) ||
        event?.target?.value === ''
      ) {
        setValues({...values, [prop]: event.target.value});
      }
    } else {
      setValues({...values, [prop]: event.target.value});
    }
  };

  const handleCountryChange = (
    value: any,
    country: any,
    e: any,
    formattedValue: any
  ): void => {
    const countryCode = formattedValue?.split(' ')?.[0];
    const phoneNumber = value?.substr(country?.dialCode?.length);

    if (phoneNumber?.length <= 10) {
      setValues({
        ...values,
        phone_code: countryCode,
        phone_number: phoneNumber
      });
    }
  };

  const getTemporaryUserDetails = (): void => {
    const userObj = {
      phone_code: values?.phone_code,
      phone_number: values?.phone_number
    };

    temporaryPersonLogin(userObj)
      .then(result => {
        if (result?.data) {
          setTemporaryUserObject(result.data);
          setOpenConfirmationPopup(true);
        }
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const bookAppointment = (): void => {
    const dataObj = {
      phone_code: values?.phone_code,
      phone_number: values?.phone_number
    };

    temporaryPersonLogin(dataObj)
      .then(result => {
        if (result?.data?.token && result?.data?.id) {
          CommonUtils.PerformPostLoginActions(result.data.token);

          bookDoctorAppointment(
            CommonUtils.formBookAppointmentProps(props),
            result.data.id
          )
            .then(response => {
              setIsLoading(false);
              enqueueSnackbar(response?.message, SnackBarConfig('s'));
              handleClose();
            })
            .catch(err => {
              setIsLoading(false);
              enqueueSnackbar(
                err?.message ? err.message : t('ERROR_ENCOUNTERED'),
                SnackBarConfig('e')
              );
            });
        }
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const registerAndBookAppointment = (): void => {
    const data = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      phone_code: values?.phone_code,
      phone_number: values?.phone_number,
      gender: values?.gender,
      salutation: values?.salutation,
      person_type: 'appointment'
    };

    setIsLoading(true);
    temporaryPersonSignUp(data)
      .then(res => {
        enqueueSnackbar(res?.message, SnackBarConfig('s'));

        bookAppointment();
      })
      .catch(err => {
        setIsLoading(false);

        if (err?.data?.user_type === 'temporary_person') {
          getTemporaryUserDetails();
        } else {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
          handleClose();
        }
      });
  };

  const handleSubmit = (): void => {
    if (
      !values?.first_name ||
      !values?.last_name ||
      !values?.phone_code ||
      !values?.phone_number ||
      !values?.gender ||
      !values?.salutation
    ) {
      enqueueSnackbar(t('TEMP_USER_REQ_DETAILS'), SnackBarConfig('w'));
      return;
    }

    if (values?.phone_number?.length != environment.mobileNumberLength) {
      enqueueSnackbar(
        t('MOB_MAX_LENGTH_WARNING', {
          limit: environment.mobileNumberLength
        }),
        SnackBarConfig('w')
      );
      return;
    }

    const data = {
      phone_code: values?.phone_code,
      phone_number: values?.phone_number,
      email: values?.email,
      first_name: values?.first_name,
      last_name: values?.last_name
    };

    setIsLoading(true);
    sendOtpToUser(data)
      .then(result => {
        setIsLoading(false);
        setOpen(false);
        setShowOtpPopup(true);
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const closeOtpPopup = (): void => {
    setShowOtpPopup(false);
    handleClose();
  };

  const callBackAction = (action: boolean): void => {
    setOpenConfirmationPopup(false);

    if (action) {
      bookAppointment();
    } else {
      setOpenConfirmationBox(true);
    }
  };

  const openUrl = (): void => {
    handleClose();

    const url = `${environment.portalUrl}/permanent_registration/${temporaryUserObject?.id}`;
    window.open(url, '_blank');
  };

  const renderConfirmationBox = (): React.ReactElement => {
    return (
      <ConfirmationBoxComponent
        open={openConfirmationBox}
        closeConfirmationBox={openUrl}
        message={t('MANAGE_APPOINTMENT_TEXT')}
      />
    );
  };

  const renderConfirmationPopup = (): React.ReactElement => {
    return (
      <ConfirmationPopupComponent
        open={openConfirmationPopup}
        action={callBackAction}
        message={t('MOB_USED_OTHERS_TEXT', {
          userName: CommonUtils.getFullName(temporaryUserObject)
        })}
        yesLabel={t('yes, Same Person')}
        noLabel={t('No, Different Person')}
      />
    );
  };

  const renderOtpPopup = (): React.ReactElement => {
    return (
      <OtpScreenComponent
        open={showOtpPopup}
        values={values}
        appointmentValues={props?.appointmentValues}
        closeOtpPopup={closeOtpPopup}
        isFromBookAppointment={true}
        isFromTemporaryBookAppointment={true}
        isTemporaryAction={registerAndBookAppointment}
      />
    );
  };

  const renderGridHelperInformation = (text: string): React.ReactElement => {
    return (
      <Grid item xs={12}>
        <div className="information-text">{text}</div>
      </Grid>
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        {renderGridHelperInformation(t('APPOINTMENT_QUESTION'))}

        {renderGridHelperInformation(t('PROVIDE_PATIENT_DETAILS'))}

        <Grid item xs={12} md={6}>
          <FormCombinationField
            dropDownName={'salutation'}
            dropdownValue={values['salutation']}
            callback={handleChange('salutation')}
            dropDownOptions={salutationOptionsList}
            textFieldCallBack={handleChange('first_name')}
            textFieldName={'first_name'}
            placeholder={t('FIRST_NAME')}
            textFieldValue={values['first_name']}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextField
            placeholder={t('LAST_NAME')}
            name="last_name"
            value={values['last_name']}
            callback={handleChange('last_name')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormPhoneField
            name="phone_number"
            placeholder={t('MOBILE_NUMBER')}
            callback={handleCountryChange}
            phoneNumber={
              (values?.phone_code || values?.phone_number) &&
              values?.phone_code + ' ' + values?.phone_number
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormSelectField
            name="gender"
            placeholder={t('GENDER')}
            value={values['gender']}
            dropDownProps={dropDownProps}
            Icon={Icons.DropDownIcon}
            StartAdornmentIcon={Icons.GenderIcon}
            dropDownOptions={genderOptionsList}
            callback={handleChange('gender')}
          />
        </Grid>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="submit-button"
            onClick={() => handleSubmit()}
            disabled={isLoading}>
            {isLoading ? (
              <Loader size={28} />
            ) : (
              t('REVIEW_AND_BOOK_APPOINTMENT')
            )}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="temporary-user-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        open={open}
        aria-labelledby="temporary-user-pop-up"
        aria-describedby="temporary-user-otp"
        classes={{
          paper: 'temporary-user-registration-popup booking-appointment-popup'
        }}>
        <Grid container spacing={1}>
          <Grid item lg={5} className="popup-left-section">
            <DialogTitle
              id="temporary-user"
              className="booking-appointment-popup-title">
              <RegisterDialogTitleComponent
                close={handleClose}
                showChangeIcon={true}
                appointmentValues={props?.appointmentValues}
              />
            </DialogTitle>
          </Grid>

          <Grid item lg={7}>
            {!isMobile && (
              <Grid className="register-close-icon">
                <IconButton onClick={handleClose}>
                  <Icons.CloseBlackIcon />
                </IconButton>
              </Grid>
            )}
            <DialogContent className="booking-appointment-popup-content">
              {renderDialogContent()}
            </DialogContent>

            <DialogActions
              disableSpacing={true}
              className="booking-appointment-popup-actions">
              {renderDialogActions()}
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      {showOtpPopup && renderOtpPopup()}
      {openConfirmationPopup && renderConfirmationPopup()}
      {openConfirmationBox && renderConfirmationBox()}
    </div>
  );
};

export default TemporaryUserRegistration;
