import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core';
import TimeSelector from './TimeSelector';
import moment from 'moment';

import './SelectTimePopUp.scss';

const SelectTimePopUp = (props: any): React.ReactElement => {
  const [open, setOpen] = React.useState(props?.open);
  const timeFormatted: any = moment();
  const {encId} = props;
  const [time, setTime] = React.useState(timeFormatted);

  const {selectedMedicine} = props;

  const handleClose = () => {
    setOpen(false);
    props?.closeSelectTimePopUp();
  };

  const handleUpdateStatus = () => {
    const takenTime = time.format('YYYY-MM-DD HH:mm:00');
    const data = {drug_taken_time: takenTime, encounter_id: encId};
    props?.updateMedicineTakenTime(selectedMedicine?.id, data);
    handleClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle className="medicine-taken-heading-title">
          <p className="medicine-taken-title">Medicine Taken</p>
        </DialogTitle>
        <DialogContent className="time-selector-wrapper">
          <p className="select-time-heading">Select Time</p>
          <Grid container className="time-selector-main-grid">
            <TimeSelector setTime={setTime} time={time} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            className="medicine-taken-button">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateStatus}
            color="primary"
            autoFocus
            variant="contained"
            className="medicine-taken-button">
            Update Status
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectTimePopUp;
