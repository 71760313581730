import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as ConnectWithDoctorsIconSvg} from '@assets/images/healpha/ConnectWithDoctors.svg';

const ConnectWithDoctorsIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={ConnectWithDoctorsIconSvg}
      viewBox="0 0 25 27.567"
    />
  );
};

export default ConnectWithDoctorsIcon;
