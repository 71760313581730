import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as ForumsIconSvg} from '@assets/images/healpha/Forums.svg';

const ForumsIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={ForumsIconSvg}
      viewBox="0 0 28 24.555"
    />
  );
};

export default ForumsIcon;
