/* eslint-disable camelcase */
import React from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import Icons from '@components/system/MyIcons';
import PdfViewerComponentNew from './PdfViewerComponentNew';

import './PdfViewerDialog.scss';

const PdfViewerDialog = (props: any): React.ReactElement => {
  const [open, setOpen] = React.useState(props?.open);
  const {pdfURL, title} = props;

  const handleClose = () => {
    setOpen(false);
    props?.closeShowPdfFileDialog();
  };

  const renderPdfViewerDialogTitle = (): React.ReactElement => {
    return (
      <Grid item container xs={12} className="pdf-title-wrapper">
        <Grid item xs={11} className="pdf-title">
          {title ? title : 'View Report'}
        </Grid>
        <Grid item xs={1} className="close-icon">
          <IconButton onClick={handleClose} className="close-icon">
            <Icons.CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const renderPdfViewerDialogContent = (): React.ReactElement => {
    return (
      <Grid item xs={12} style={{flex: 1, overflow: 'hidden'}}>
        <PdfViewerComponentNew samplePdf={pdfURL} />
      </Grid>
    );
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="pdf-dialog-wrapper">
        <DialogTitle id="alert-dialog-title">
          {renderPdfViewerDialogTitle()}
        </DialogTitle>
        <DialogContent>{renderPdfViewerDialogContent()}</DialogContent>
      </Dialog>
    </div>
  );
};

export default PdfViewerDialog;
