import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as BlueStethSvg} from '@assets/images/healpha/BlueSteth.svg';

const BlueSteth = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={BlueStethSvg}
      viewBox="0 0 25 25"
    />
  );
};

export default BlueSteth;
