/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react';
import './CalendarTabs.scss';
import CalendarDayWise from './CalendarDayWise';
import CalendarWeekWise from './CalendarWeekWise';
import CalendarMonthWise from './CalendarMonthWise';
import {getSchedularData} from '@shared/services/PublicService';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import AuthUtils from 'src/utils/AuthUtils';
// import MenuItem from '@material-ui/core/MenuItem';
import DownArrowBlackIcon from '@components/system/MyIcons/DownArrowBlackIcon';
import {
  Typography,
  Checkbox,
  Menu,
  Grid,
  Button,
  Dialog
} from '@material-ui/core';
import {
  APP_COMPLETED_STATUS_DOT_COLOR,
  APP_PRIMARY_COLOR,
  APP_BOOKED_STATUS_DOT_COLOR,
  APP_RE_CONSULTING_STATUS_DOT_COLOR,
  BORDER_TERTIARY_COLOR,
  APP_BOOKED_STATUS_DOT_COLOR_1,
  APP_BOOKED_STATUS_DOT_COLOR_2,
  APP_RE_CONSULTING_STATUS_DOT_COLOR_1,
  APP_RE_CONSULTING_STATUS_DOT_COLOR_2,
  APP_RE_CONSULTING_STATUS_DOT_COLOR_3,
  APP_RE_CONSULTING_STATUS_DOT_COLOR_4,
  APP_RE_CONSULTING_STATUS_DOT_COLOR_5
} from 'src/style/variable';
import moment from 'moment';
import FormSelectField from '@components/system/CustomFormField/FormSelectField';
import Icons from '@components/system/MyIcons/index';
import {filterDefaultOptions} from 'src/constants/SystemConstants';

const tabConstants = {
  today: 'Today',
  weekly: 'Weekly',
  monthly: 'Monthly'
};

const dropDownProps = {
  height: '2.9rem',
  width: '100%',
  border: `1px solid ${BORDER_TERTIARY_COLOR}`
};

const filterColors = [
  APP_COMPLETED_STATUS_DOT_COLOR,
  APP_PRIMARY_COLOR,
  APP_BOOKED_STATUS_DOT_COLOR,
  APP_RE_CONSULTING_STATUS_DOT_COLOR,
  APP_COMPLETED_STATUS_DOT_COLOR,
  APP_BOOKED_STATUS_DOT_COLOR,
  APP_BOOKED_STATUS_DOT_COLOR_1,
  APP_BOOKED_STATUS_DOT_COLOR_2,
  APP_RE_CONSULTING_STATUS_DOT_COLOR_1,
  APP_RE_CONSULTING_STATUS_DOT_COLOR_2,
  APP_RE_CONSULTING_STATUS_DOT_COLOR_3,
  APP_RE_CONSULTING_STATUS_DOT_COLOR_4,
  APP_RE_CONSULTING_STATUS_DOT_COLOR_5
];

const CalendarTabs = ({selectedDate, setDoctorID}: any): React.ReactElement => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const [tab, setTab] = useState(tabConstants.today);
  const [doctors, setDoctors] = useState<any>([]);
  const [filter, setFilter] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [specialization, setSpecialization] = useState<string>('');
  const [modalVisible, setModalVisible] = useState(false);

  const branch_id = AuthUtils.getMenuBranchId();

  const MonthlyDialog = () => {
    const onCancel = () => {
      setModalVisible(false);
    };
    return (
      <Dialog onClose={onCancel} open={modalVisible} className="p-5">
        <div className="p-4 text-center">
          <Typography
            style={{fontSize: '16px', fontWeight: 'bold'}}
            className="my-2">
            Please select single Doctor
          </Typography>
          <Button
            onClick={() => setModalVisible(false)}
            variant="contained"
            sx={{
              whiteSpace: 'nowrap'
            }}
            className="m-2">
            Okay
          </Button>
        </div>
      </Dialog>
    );
  };

  const getDoctors = () => {
    getSchedularData({branch_id, date: selectedDate})
      .then(res => {
        setDoctors(
          res.data.response?.map((i: any, index: number) => {
            const {doctor_appointments, ...rest} = i;
            return {
              ...doctor_appointments,
              ...rest,
              color: filterColors[index]
            };
          })
        );
        setFilter(
          res.data?.response?.map(
            (i: any) => i?.doctor_appointments?.non_healpha_user_id
          ) || []
        );
      })
      .catch((err: any) => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  useEffect(getDoctors, [branch_id, selectedDate]);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let specializationList = doctors
    .map((i: any) => {
      if (!i.specialization) return null;
      return {
        value: i.specialization,
        label: i.specialization?.replace(/_/g, ' ')
      };
    })
    .filter(Boolean);

  specializationList = specializationList.filter(
    (value: any, index: any, self: any) =>
      self.findIndex((m: any) => m?.value === value?.value) === index
  );
  specializationList = [
    {
      value: '',
      label: filterDefaultOptions.ALL
    },
    ...specializationList
  ];

  const filteredDocs = doctors.filter((i: any) => {
    if (specialization) {
      return i.specialization === specialization;
    } else {
      return i;
    }
  });

  const checkedDocs = filteredDocs.filter((i: any) => {
    return filter.includes(i.non_healpha_user_id);
  });

  return (
    <Grid className="scheduler-wrapper vh-90">
      <Grid container className="p-3">
        {filteredDocs.length !== 1 && <MonthlyDialog />}
        <Grid item xs={12} sm={4}>
          <h6>{moment(selectedDate)?.format('MMMM DD, YYYY')}</h6>
        </Grid>
        <Grid container item xs={12} sm={8}>
          <Grid
            container
            item
            xs={12}
            sm={6}
            className="align-items-center border rounded px-1 my-1">
            <Grid
              item
              xs={4}
              role="button"
              className={`p-2 tabInactive1 ${
                tab === tabConstants.today ? 'tabActive' : ''
              }`}
              onClick={() => {
                setTab(tabConstants.today);
                setDoctorID('');
              }}>
              <span className="px-1">{t('CLINIC_TODAY')}</span>
            </Grid>
            <Grid
              item
              xs={4}
              role="button"
              className={`p-2 tabInactive1 ${
                tab === tabConstants.weekly ? 'tabActive' : ''
              }`}
              onClick={() => {
                const element = document.querySelector(
                  '[aria-controls="simple-menu"]'
                );
                setDoctorID(filter[0]);
                setAnchorEl(element);
                setFilter((prev: any) => (prev.length ? [prev[0]] : []));
                setTab(tabConstants.weekly);
              }}>
              <span className="px-1">{t('CLINIC_WEEKLY')}</span>
            </Grid>

            <Grid
              item
              xs={4}
              role="button"
              className={`p-2 tabInactive1 ${
                tab === tabConstants.monthly ? 'tabActive' : ''
              }`}
              onClick={() => {
                const element = document.querySelector(
                  '[aria-controls="simple-menu"]'
                );
                setDoctorID(filter[0]);
                setAnchorEl(element);
                setFilter((prev: any) => (prev.length ? [prev[0]] : []));
                setTab(tabConstants.monthly);
              }}>
              <span className="px-1">{t('CLINIC_MONTHLY')}</span>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={5}>
            <div
              className="w-100 d-flex justify-content-between p-2 tabInactive"
              role="button"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpen}>
              <span>
                {tab !== tabConstants.today
                  ? checkedDocs?.[0]
                    ? `${checkedDocs?.[0]?.first_name} ${checkedDocs?.[0]?.last_name}`
                    : t('CLINIC_SELECT_DOCTOR')
                  : t('CLINIC_SELECT_DOCTOR')}
              </span>
              <DownArrowBlackIcon />
            </div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <div className="p-2 px-3">
                <span className="mb-1">{t('TEMPLATE_SPECIALIZATION')}</span>
                <Grid item xs={12}>
                  <FormSelectField
                    name="relation"
                    placeholder={t('COMMON_SELECT')}
                    value={specialization}
                    dropDownProps={dropDownProps}
                    Icon={Icons.DropDownIcon}
                    dropDownOptions={specializationList}
                    callback={(e: any) => setSpecialization(e.target.value)}
                  />
                </Grid>
              </div>
              <div className="p-2 px-3">
                <Typography className="mb-1">
                  {t('CLINIC_PICK_DOCTORS')}
                </Typography>
                {filteredDocs.map((i: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="d-flex align-items-center p-1 mb-1 filterColors"
                      style={{
                        backgroundColor: filterColors[index]
                      }}>
                      <Checkbox
                        className="p-0 text-white"
                        checked={filter.includes(i.non_healpha_user_id)}
                        onChange={e => {
                          if (tab !== tabConstants.today) {
                            setFilter([i.non_healpha_user_id]);
                            setDoctorID(i.non_healpha_user_id);
                            setAnchorEl(null);
                          } else {
                            if (e.target.checked) {
                              setDoctorID('');
                              setFilter((prev: any) => [
                                ...prev,
                                i.non_healpha_user_id
                              ]);
                            } else {
                              setDoctorID('');
                              setFilter((prev: any) =>
                                prev.filter(
                                  (x: any) => x !== i.non_healpha_user_id
                                )
                              );
                            }
                          }
                        }}
                      />
                      <Typography className="px-1 text-white">
                        {i.first_name} {i.last_name}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        {tab === tabConstants.today && (
          <CalendarDayWise
            selectedDate={selectedDate}
            doctors={checkedDocs}
            getDoctors={getDoctors}
          />
        )}
        {tab === tabConstants.weekly && (
          <CalendarWeekWise selectedDate={selectedDate} doctors={checkedDocs} />
        )}
        {tab === tabConstants.monthly && (
          <CalendarMonthWise
            selectedDate={selectedDate}
            doctors={checkedDocs}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CalendarTabs;
