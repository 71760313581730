import React from 'react';
import BaseIcon from './BaseIcon';
import { ReactComponent as InPersonVisitIconSvg } from '@assets/images/healpha/InPersonVisit.svg';

const InPersonVisitIcon = (props: any): React.ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest}
      className={className}
      component={InPersonVisitIconSvg}
      viewBox="0 0 24 24" />
  );
};

export default InPersonVisitIcon;