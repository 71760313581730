/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, Typography} from '@material-ui/core';

import Icons from '@components/system/MyIcons/index';
import './ConsultationFees.scss';

const ConsultationFees = (props: any): React.ReactElement => {
  const {t} = useTranslation();

  return (
    <Grid className="consultation-fees-wrapper">
      <Grid className="consultation-fees-container">
        {props?.appointmentTypesList &&
          Array.isArray(props.appointmentTypesList) &&
          props?.appointmentTypesList?.length > 0 && (
            <Grid className="consultation-fees-content">
              <Grid
                className="fee-section"
                container
                alignItems="center"
                spacing={2}>
                <Grid item>
                  <Icons.FeeIcon className="img-sec" />
                </Grid>
                <Grid item>
                  <Typography className="title">
                    {t('CONSULTATION_FEE')}
                  </Typography>
                </Grid>
              </Grid>

              {props?.appointmentTypesList?.map((item: any, index: any) => (
                <Grid
                  className="row-container"
                  container
                  spacing={2}
                  key={index}>
                  <Grid item xs>
                    <Typography className="subtitle">
                      {item?.appointment_type_name}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography className="subtitle">
                      - Rs.{item?.price}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};
export default ConsultationFees;
