import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as SettingsIconSvg} from '@assets/images/healpha/Settings.svg';

const SettingsIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={SettingsIconSvg}
      viewBox="0 0 32 32"
    />
  );
};

export default SettingsIcon;
