/* eslint-disable camelcase */
import React, {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {
  Redirect,
  Switch,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom';
import {Grid, LinearProgress} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import {getBranchById, getPracticeById} from '@shared/services/PublicService';

import SubTabsComponent from '@components/system/SubTabs/SubTabsComponent';
import CustomRoute from 'src/constants/route/CustomRoute';
import NavRoutes from 'src/constants/route/NavRoutes';
import HospitalViewDescriptionComponent from 'src/modules/shared/HospitalViewDescription/HospitalViewDescriptionComponent';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {
  _setBranchId,
  _setBranchInfo
} from '@shared/store/actions/CommonActions';
import Loader from '@components/framework/loader/Loader';
import HospitalHealthForumsComponent from 'src/modules/shared/HospitalHealthForums/HospitalHealthForumsComponent';
import './HospitalViewComponent.scss';

// This is a parent component which includes  HospitalViewDescriptionComponent
const HospitalViewComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const [hospital, setHospital]: any = useState({});
  const params: any = useParams();
  const history = useHistory();
  // eslint-disable-next-line
  const {enqueueSnackbar} = useSnackbar();
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState<string>('');
  const [isLoading, setIsLoading]: any = useState(false);
  const [tabs, setTabs]: any = useState([]);
  const tabsStruct = {
    overview: {
      id: NavRoutes.PUBLIC.HOSPITAL.TABS.OVERVIEW,
      label: t('OVERVIEW'),
      route: NavRoutes.PUBLIC.HOSPITAL.TABS.OVERVIEW
    },
    doctors: {
      id: NavRoutes.PUBLIC.HOSPITAL.TABS.DOCTORS,
      label: t('DOCTORS'),
      route: NavRoutes.PUBLIC.HOSPITAL.TABS.DOCTORS
    },
    services: {
      id: NavRoutes.PUBLIC.HOSPITAL.TABS.SERVICES,
      label: t('SERVICES'),
      route: NavRoutes.PUBLIC.HOSPITAL.TABS.SERVICES
    },
    feedback: {
      id: NavRoutes.PUBLIC.HOSPITAL.TABS.FEEDBACK,
      label: t('FEEDBACK'),
      route: NavRoutes.PUBLIC.HOSPITAL.TABS.FEEDBACK
    },
    otherLocations: {
      id: NavRoutes.PUBLIC.HOSPITAL.TABS.OTHER_LOCATIONS,
      label: t('OTHER_LOCATIONS'),
      route: NavRoutes.PUBLIC.HOSPITAL.TABS.OTHER_LOCATIONS
    },
    locations: {
      id: NavRoutes.PUBLIC.HOSPITAL.TABS.OTHER_LOCATIONS,
      label: t('LOCATIONS'),
      route: NavRoutes.PUBLIC.HOSPITAL.TABS.OTHER_LOCATIONS
    }
  };

  // call get hospitaldetails api
  const getHospitalDetails = async () => {
    try {
      setIsLoading(true);
      const localTabs = [];
      let localBranches: any = [];
      localTabs.push(tabsStruct.overview);
      // eslint-disable-next-line
      const {practice_id, branch_id} = params;
      let locResponse = null;
      // eslint-disable-next-line

      if (practice_id) {
        const response = await getPracticeById(practice_id);
        locResponse = response?.data;
        if (locResponse.practice_module?.[0].access_module_id == 2) {
          localTabs.push(tabsStruct.doctors);
        }
        localTabs.push(tabsStruct.locations);
        if (locResponse.doctors_count == 0) {
          localTabs.splice(1, 1);
          if (!locResponse.branches) {
            if (localTabs.length == 3) {
              localTabs.splice(2, 1);
            }
          }
        }
        if (!locResponse.branches) {
          if (localTabs.length == 4) {
            localTabs.splice(3, 1);
          }
        }
        // eslint-disable-next-line
      } else if (branch_id) {
        const response = await getBranchById(branch_id);
        locResponse = response?.data;
        if (locResponse.practice_module?.[0].access_module_id == 2) {
          localTabs.push(tabsStruct.doctors);
          localTabs.push(tabsStruct.services);
        }
        if (locResponse?.feedback) {
          localTabs.push(tabsStruct.feedback);
        }
        // localTabs.push(tabsStruct.otherLocations);
        if (response?.data?.branches && Array.isArray(response.data.branches)) {
          localBranches = response.data.branches?.filter(
            (item: any) => item.branch_id != branch_id
          );
          if (localBranches.length > 0)
            localTabs.push(tabsStruct.otherLocations);
        }
        if (locResponse.doctors_count == 0) {
          localTabs.splice(1, 1);
        }
        // if (!locResponse.branches) {
        //   localTabs.length == 3
        //     ? localTabs.splice(2, 1)
        //     : localTabs.splice(3, 1);
        // }
      }
      setHospital(locResponse);
      setTabs(localTabs);
      props._setBranchInfo(locResponse);
      props._setBranchId({
        branchId: branch_id,
        practiceId: practice_id
      });
      setIsLoading(false);
    } catch (err) {
      enqueueSnackbar(
        (err as any)?.message || t('ERROR_ENCOUNTERED'),
        SnackBarConfig('e')
      );
      setIsLoading(false);
    }
  };

  const init = () => {
    getHospitalDetails();
  };

  // eslint-disable-next-line
  useEffect(init, [params?.branch_id, params?.practice_id]);

  // Listener for route change
  const updateStepperBasedOnLocation = () => {
    const currentRouteArray = location.pathname.split('/');
    const currentRoutePath = currentRouteArray[currentRouteArray.length - 1];
    setCurrentRoute(currentRoutePath);
  };

  useEffect(updateStepperBasedOnLocation, [location]);

  const getCurrentRoute = (route: string): void => {
    history.push(route);
  };

  const renderSubTabComponent = (): ReactElement => {
    return (
      <SubTabsComponent
        initialRoute={currentRoute || tabs?.[0]?.route}
        routesData={tabs}
        action={getCurrentRoute}
      />
    );
  };

  const renderRoutes = (): React.ReactElement => {
    const {routes, redirectTo, exactFrom} = props;
    const route = routes.map((r: any, i: any) => {
      return CustomRoute.privateRouting({
        key: i,
        path: r.path,
        exact: r.exact,
        Component: r.component,
        exactFrom: r.exactFrom,
        redirectTo: r.redirectTo,
        routes: r.routes
      });
    });

    return (
      <React.Suspense fallback={<LinearProgress />}>
        <Switch>
          <Redirect from={exactFrom} to={redirectTo} exact />
          {route}
        </Switch>
      </React.Suspense>
    );
  };

  return (
    <Grid className="hospital-view-wrapper">
      {isLoading && <Loader />}

      <Grid container spacing={2} className="hospital-view-container">
        <Grid item className="left-section" xs={12} md={8}>
          <Grid className="description-section">
            <HospitalViewDescriptionComponent
              hospital={hospital}
              showRoute={false}
            />
          </Grid>

          <Grid className="tabs-section">
            {renderSubTabComponent()}
            <Grid className="body-section">{renderRoutes()}</Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} className="right-section">
          <HospitalHealthForumsComponent
            moduleId={hospital?.practice_module?.[0].access_module_id}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state: any) {
  return {};
}

export default connect(mapStateToProps, {
  _setBranchId,
  _setBranchInfo
})(HospitalViewComponent);
