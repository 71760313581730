import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Grid, Typography} from '@material-ui/core';

import './AvailabilityButtonsComponent.scss';

const AvailabilityButtonsComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {branchData} = props;

  const handleClick = (): void => {
    if (
      props?.bookAppointmentAction &&
      typeof props.bookAppointmentAction === 'function'
    ) {
      props.bookAppointmentAction();
    }
  };

  return (
    <Grid className="availability-buttons-wrapper">
      <Grid className="divider-container" />
      <Grid
        className="appointment-section"
        container
        spacing={2}
        justifyContent={{sm: 'flex-end', xs: 'flex-start'}}
        alignItems="center">
        <Grid
          className="today-status"
          sx={{marginBottom: {xs: '1rem', sm: '0'}}}>
          <Grid
            className={[
              'bubble-view',
              branchData?.isTodayAvailable
                ? 'available-color'
                : 'not-available-color'
            ].join(' ')}
          />

          <Typography
            className={
              branchData?.isTodayAvailable
                ? 'available-text'
                : 'not-available-text'
            }>
            {t(
              branchData?.isTodayAvailable
                ? 'AVAILABLE_TODAY'
                : 'NOT_AVAILABLE_TODAY'
            )}
          </Typography>
        </Grid>

        <Button
          variant="contained"
          size="medium"
          className="book-appointment-button"
          onClick={handleClick}
          color="primary">
          {t('BOOK_APPOINTMENT')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AvailabilityButtonsComponent;
