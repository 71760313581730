import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as LabDetailSvg} from '@assets/images/healpha/LabDetailsIcon.svg';

const LabDetailsIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={LabDetailSvg}
      viewBox="0 0 26 26"
    />
  );
};

export default LabDetailsIcon;
