/* eslint-disable camelcase */
import _moment from 'moment';
const moment = _moment;
import CommonUtils from './CommonUtils';

class HelperUtils {
  static deepCopyFunction(inObject: any): any {
    if (typeof inObject !== 'object' || inObject === null) {
      return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    const outObject: any = Array.isArray(inObject) ? [] : {};

    for (const key in inObject) {
      if (key) {
        const value = inObject[key];

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = this.deepCopyFunction(value);
      }
    }

    return outObject;
  }

  static fixTo2Decimals(value: any): any {
    if (isNaN(value)) {
      return value;
    }
    return (Math.round(value * 100) / 100).toFixed(2);
  }

  static getReadableDateDiff(targetDate: string): string {
    if (!targetDate) {
      return '-';
    }
    let label = 'year',
      val = moment().diff(moment(targetDate), 'y');
    if (!val) {
      label = 'day';
      val = moment().diff(moment(targetDate), 'd');
      if (!val) {
        label = 'hour';
        val = moment().diff(moment(targetDate), 'h');
        if (!val) {
          label = 'minute';
          val = moment().diff(moment(targetDate), 'm');
          if (!val) {
            label = 'second';
            val = moment().diff(moment(targetDate), 's');
          }
        }
      }
    }
    if (val > 1) {
      label = `${label}s`;
    }
    return `${val} ${label} ago`;
  }

  static calculateAgeFromDOB(user_dob: any): any {
    try {
      const dob: any = new Date(user_dob),
        dobYear = dob.getYear(),
        dobMonth = dob.getMonth(),
        dobDate = dob.getDate();

      const now: any = new Date();

      const currentYear: any = now.getYear(),
        currentMonth = now.getMonth(),
        currentDate = now.getDate();

      let yearAge = currentYear - dobYear,
        monthAge: any = '',
        dateAge: any = '',
        ageString = '';

      // get months
      if (currentMonth >= dobMonth) {
        // get months when current month is greater
        monthAge = currentMonth - dobMonth;
      } else {
        yearAge--;
        monthAge = 12 + currentMonth - dobMonth;
      }

      // get days
      if (currentDate >= dobDate) {
        // get days when the current date is greater
        dateAge = currentDate - dobDate;
      } else {
        monthAge--;
        dateAge = 31 + currentDate - dobDate;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }
      // group the age in a single variable
      const age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
      };

      if (age.years > 0 && age.months > 0 && age.days > 0) {
        ageString = `${age.years}Y ${age.months}M ${age.days}D`;
      } else if (age.years == 0 && age.months == 0 && age.days > 0) {
        ageString = `${age.days}D`;
      }
      // when current month and date is same as birth date and month
      else if (age.years > 0 && age.months == 0 && age.days == 0) {
        ageString = `${age.years}Y`;
      } else if (age.years > 0 && age.months > 0 && age.days == 0) {
        ageString = `${age.years}Y ${age.months}M`;
      } else if (age.years == 0 && age.months > 0 && age.days > 0) {
        ageString = `${age.months}M ${age.days}D`;
      } else if (age.years > 0 && age.months == 0 && age.days > 0) {
        ageString = `${age.years}Y ${age.days}D`;
      } else if (age.years == 0 && age.months > 0 && age.days == 0) {
        ageString = `${age.months}M`;
      }
      return ageString;
    } catch (e) {
      throw new Error((e as any)?.message);
    }
  }

  static getVitalCalculations(
    gender: string,
    dob: any,
    vital_key: string,
    value?: any,
    height?: any,
    weight?: any
  ): any {
    let year: any = 0,
      month: any = 0,
      yearIndex = null;

    const created_date = moment().format('YYYY-MM-DD');
    if (dob) {
      let age = this.calculateAgeFromDOB(dob);
      if (age) {
        const searchRegExp = new RegExp(' ', 'g');
        age = age.replace(searchRegExp, '');
        if (age.includes('Y')) {
          yearIndex = age.indexOf('Y');
          year = age.substring(0, age.indexOf('Y'));
          year = parseInt(year);
        }
        if (age.includes('M')) {
          if (yearIndex) {
            month = age.substring(yearIndex + 1, age.indexOf('M'));
          } else {
            month = age.substring(0, age.indexOf('M'));
          }
          month = parseInt(month);
        }
      }
    }
    const cal_age = `${year}.${month}`,
      age_year = year;

    return CommonUtils.getVitalCalc(
      vital_key,
      value,
      height,
      weight,
      cal_age,
      gender,
      dob,
      created_date,
      age_year
    );
  }
}

export default HelperUtils;
