import {OptionsObject, SnackbarOrigin} from 'notistack';

export const MAX_SNACKBARS = 5;
export const DEFAULT_DURATION = 5000;
export const DEFAULT_ANCHOR: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center'
};
export type SnackBarType =
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'default'
  | 'e'
  | 's'
  | 'w'
  | 'i'
  | 'd';

export const SnackBarConfig = (type?: SnackBarType): OptionsObject => {
  const config: OptionsObject = {
    variant: 'default',
    autoHideDuration: DEFAULT_DURATION,
    anchorOrigin: DEFAULT_ANCHOR
  };

  switch (type) {
    case 'error':
    case 'e':
      config.variant = 'error';
      break;
    case 'success':
    case 's':
      config.variant = 'success';
      break;
    case 'warning':
    case 'w':
      config.variant = 'warning';
      break;
    case 'info':
    case 'i':
      config.variant = 'info';
      break;
    default:
      config.variant = 'default';
  }

  return config;
};
