import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as NotesIconSvg} from '@assets/images/healpha/ant-design_file-text-twotone.svg';

const NotesIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={NotesIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default NotesIcon;
