export const APPOINTMENT_BOOKED = 'Appointment Booked';

export const TRAIGE = 'Traige';

export const COMPLETED = 'Completed';

export const CONFIRM = 'Confirm';

export const APPOINTMENT_RESCHEDULED = 'Appointment Rescheduled';

export const RECONSULTING = 'Reconsulting';

export const CANCELLED = 'Cancelled';

export const CHECKEDIN = 'Checkedin';

export const CLOSED = 'Closed';

export const CONSULTING = 'Consulting';

export const INPROCESS = 'In Process';

export const UNDERGOING = 'Undergoing';
