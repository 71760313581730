/* eslint-disable camelcase */
import React from 'react';
import {
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
  Link,
  Checkbox,
  Divider
} from '@material-ui/core';
import Icons from '@components/system/MyIcons';
import CaptureVitalsCommonComponent from '../CaptureVitalsCommonComponent/CaptureVitalsCommonComponent';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';

import {IMAGING, LAB, NURSING, VACCINE} from 'src/constants/PlanPageButtons';

import {
  addVitalsAnswers,
  getBMIValue,
  getMedicineReminders,
  getServicesFromTemplate,
  getTemplateVitals
} from '@shared/services/TemplateService';
import {
  addImagingAttachments,
  deletePlanAttachments,
  getImagingAllData,
  getPlanNotes,
  getVaccinesInformation,
  updateMedicineTakenTiming
} from '@shared/services/PublicService';
import Loader from '@components/framework/loader/Loader';
import CommonUtils from 'src/utils/CommonUtils';
import UploadAttachmentDialog from 'src/modules/shared/UploadAttachment/UploadAttachmentDialog';
import PdfViewerDialog from 'src/modules/shared/PdfViewerComponent/PdfViewerDialog';

import {nurseDashboardServices} from 'src/constants/SystemConstants';
import moment from 'moment';
import {COMPLETED} from 'src/constants/statusList';
import './NursingSectionComponent.scss';
import SelectTimePopUp from '../SelectTimePopUp/SelectTimePopUp';
import AddVitals from '../../../PatientObservations/Observations/AddVitals/AddVitals';
import DeleteAttachmentConfirmationPopUp from '../../../PatientObservations/Plan/DeleteAttachmentConfirmationPopUp';
import DisplayImagingData from '../../../PatientObservations/Plan/PlanButtons/Imaging/DisplayImagingData';

const NursingSectionComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [categoryArray, setCategoryArray] = React.useState<any>([]);
  const [vitalsAnswerArray, setVitalsAnswerArray] = React.useState<any>([]);
  const {
    encId,
    docId,
    personId,
    tempId,
    appointmentStatus,
    todaysDate,
    setVitalsAdded,
    patientDetails
  } = props;

  const [imagingArray, setImagingArray] = React.useState<any>([]);
  const [labArray, setLabArray] = React.useState<any>([]);
  const [nursingArray, setNursingArray] = React.useState<any>([]);
  const [attachedFiles, setAttachedFiles] = React.useState([]);
  const [showUploadAttachmentPopUp, setShowUploadAttachmentPopUp] =
    React.useState(false);
  const [showPdfFileDialog, setShowPdfFileDialog] = React.useState(false);
  const [prescription, setPrescription] = React.useState('');
  const [vaccineArray, setVaccineArray] = React.useState([]);
  const [deleteAttachmentId, setDeleteAttachmentId] =
    React.useState<boolean>(false);
  const [deleteType, setDeleteType] = React.useState<any>('');
  const [
    showDeleteAttachmentConfirmationPopUp,
    setShowDeleteAttachmentConfirmationPopUp
  ] = React.useState(false);

  const [showSelectTimePopUp, setShowSelectTimePopUp] = React.useState(false);
  const [selectedMedicine, setSelectedMedicine] = React.useState({});

  const [values, setValues]: any = React.useState({
    height: '',
    weight: '',
    bmi: '',
    pulse_rate: '',
    oxygen_saturation: '',
    pefr: '',
    respiratory_rate: '',
    temperature: '',
    systolic: '',
    diastolic: '',
    notes: ''
  });
  const [showVitalsPopUp, setShowVitalsPopUp] = React.useState<boolean>(false);
  const [bmiVal, setBMIVal] = React.useState('');
  const [reminderData, setReminderData] = React.useState<any>([]);
  const [selectedDate, setSelectedDate] = React.useState<any>(todaysDate);

  const closeShowUploadAttachmentPopUp = (): void => {
    setShowUploadAttachmentPopUp(false);
  };

  const handleGetNotes = () => {
    getPlanNotes(encId, docId, personId, tempId)
      .then(res => {
        setAttachedFiles(res?.data?.attachments);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleVaccineData = () => {
    setIsLoading(true);
    getVaccinesInformation(encId, docId, personId, tempId)
      .then(res => {
        setIsLoading(false);
        setVaccineArray(res?.data?.services);
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleAddAttachment = (fileData: any, file?: any) => {
    return (
      file?.length > 0 &&
      addImagingAttachments(encId, docId, personId, fileData, 'encounter', '')
        .then(res => {
          enqueueSnackbar(res?.message, SnackBarConfig('s'));
          handleGetNotes();
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        })
    );
  };

  const renderShowUploadAttachmentPopUp = (): React.ReactElement => {
    return (
      <UploadAttachmentDialog
        open={showUploadAttachmentPopUp}
        closeShowUploadAttachmentPopUp={closeShowUploadAttachmentPopUp}
        handleAddAttachment={handleAddAttachment}
      />
    );
  };

  const closeShowPdfFileDialog = () => {
    setShowPdfFileDialog(false);
  };

  const renderPdfViewerDialog = (): React.ReactElement => {
    return (
      <PdfViewerDialog
        open={showPdfFileDialog}
        closeShowPdfFileDialog={closeShowPdfFileDialog}
        pdfURL={prescription}
      />
    );
  };
  const handleGetVitalsAnswers = () => {
    const isDefault = tempId == 'default' || tempId == '' ? '' : tempId;

    getTemplateVitals(encId, docId, personId, isDefault, true, true)
      .then(res => {
        const vitals = res?.data?.categories;
        setCategoryArray(vitals);
        setVitalsAnswerArray(res?.data?.vitals);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleGetMedicineReminders = () => {
    getMedicineReminders(personId, selectedDate || todaysDate, encId)
      .then(res => {
        setReminderData(res);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };
  const clearVitalsData = () => {
    setValues({
      height: '',
      weight: '',
      bmi: '',
      pulse_rate: '',
      oxygen_saturation: '',
      pefr: '',
      respiratory_rate: '',
      temperature: '',
      systolic: '',
      diastolic: '',
      notes: ''
    });
  };

  const handleOpenAddVitals = () => {
    clearVitalsData();
    setShowVitalsPopUp(true);
  };

  const refreshAppointments = () => {
    const date = moment(todaysDate).format('YYYY-MM-DD');
    const statusCount = new CustomEvent('handleStatusCount', {
      detail: {date: date}
    });
    const initListener = new CustomEvent('initListener', {
      detail: {doc_id: docId}
    });
    // const handlingDisplay = new CustomEvent('handlingDisplay', {});
    window.dispatchEvent(initListener);
    window.dispatchEvent(statusCount);
    // window.dispatchEvent(handlingDisplay);
  };

  const closeAddVitalsPopUp = (): void => {
    setValues({
      height: '',
      weight: '',
      bmi: '',
      pulse_rate: '',
      oxygen_saturation: '',
      pefr: '',
      respiratory_rate: '',
      temperature: '',
      systolic: '',
      diastolic: '',
      notes: ''
    });
    setShowVitalsPopUp(false);
  };

  const handleAddVitalsAnswer = (answer: any) => {
    const temp = tempId ? tempId : ' ';
    addVitalsAnswers(encId, docId, personId, answer, temp)
      .then(res => {
        handleGetVitalsAnswers();
        enqueueSnackbar(res?.message, SnackBarConfig('s'));
        appointmentStatus !== COMPLETED && refreshAppointments();
        setVitalsAdded(true);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleBMICalculation = (height: any, weight: any) => {
    getBMIValue(encId, docId, personId, height, weight)
      .then(res => {
        setBMIVal(res?.data?.bmi_value);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const renderAddVitalsPopUp = (): React.ReactElement => {
    return (
      <AddVitals
        open={showVitalsPopUp}
        closeAddVitalsPopUp={closeAddVitalsPopUp}
        categoryArray={categoryArray}
        handleBMICalculation={handleBMICalculation}
        bmiVal={bmiVal}
        setValues={setValues}
        values={values}
        handleAddVitalsAnswer={handleAddVitalsAnswer}
        patientDetails={patientDetails}
      />
    );
  };

  const renderCaptureVitals = (): any => {
    return (
      <CaptureVitalsCommonComponent
        categoryArray={categoryArray}
        vitalsAnswerArray={vitalsAnswerArray}
        encId={encId}
        handleOpenAddVitals={handleOpenAddVitals}
      />
    );
  };

  const handleGetImagingData = (type: any, dataIsSet?: any) => {
    setIsLoading(true);
    type == VACCINE;
    tempId?.length <= 0 && !dataIsSet
      ? getServicesFromTemplate(type, tempId)
          .then(res => {
            setIsLoading(false);
            if (type == IMAGING) {
              setImagingArray(res?.data?.services);
            } else if (type == LAB) {
              setLabArray(res?.data?.services);
            } else if (type == NURSING) {
              setNursingArray(res?.data?.services);
            }
          })
          .catch(err => {
            setIsLoading(false);
            enqueueSnackbar(
              err?.message ? err.message : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          })
      : getImagingAllData(encId, docId, personId, type)
          .then(res => {
            setIsLoading(false);
            if (type == IMAGING) {
              setImagingArray(res?.data?.services);
            } else if (type == LAB) {
              setLabArray(res?.data?.services);
            } else if (type == NURSING) {
              setNursingArray(res?.data?.services);
            }
          })
          .catch(err => {
            setIsLoading(false);
            enqueueSnackbar(
              err?.message ? err.message : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          });
  };

  const runOnAppointmentChange = () => {
    setSelectedDate(todaysDate);

    handleGetVitalsAnswers();

    appointmentStatus === COMPLETED && tempId && handleGetImagingData(IMAGING);
    appointmentStatus === COMPLETED && tempId && handleGetImagingData(LAB);
    appointmentStatus === COMPLETED && tempId && handleGetImagingData(NURSING);
    appointmentStatus === COMPLETED && tempId && handleVaccineData();
    appointmentStatus === COMPLETED && tempId && handleGetNotes();

    appointmentStatus === COMPLETED &&
      tempId &&
      todaysDate &&
      handleGetMedicineReminders();
  };

  React.useEffect(runOnAppointmentChange, [encId, appointmentStatus]);

  const callMedicineReminders = () => {
    appointmentStatus === COMPLETED &&
      tempId &&
      todaysDate &&
      handleGetMedicineReminders();
  };

  React.useEffect(callMedicineReminders, [selectedDate, todaysDate]);

  const handleDeleteAttachment = (type: any, id: any) => {
    setDeleteAttachmentId(id);
    setDeleteType(type);
    setShowDeleteAttachmentConfirmationPopUp(true);
  };

  const deleteAttachment = (type: any, id: any) => {
    deletePlanAttachments(encId, docId, personId, type, deleteAttachmentId)
      .then(res => {
        handleGetNotes();
        type == VACCINE
          ? handleVaccineData()
          : type == 'encounter'
          ? handleGetNotes()
          : handleGetImagingData(type, true);
        enqueueSnackbar(res?.message, SnackBarConfig('s'));
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const closeDeleteAttachmentConfirmationPopUp = (): void => {
    setShowDeleteAttachmentConfirmationPopUp(false);
  };

  const renderShowDeleteAttachmentConfirmationPopUp =
    (): React.ReactElement => {
      return (
        <DeleteAttachmentConfirmationPopUp
          open={showDeleteAttachmentConfirmationPopUp}
          closeDeleteAttachmentConfirmationPopUp={
            closeDeleteAttachmentConfirmationPopUp
          }
          type={deleteType}
          deleteAttachment={deleteAttachment}
        />
      );
    };

  const handlePrescription = (url: any) => {
    setPrescription(CommonUtils.getFullImageUrl(url));
    setShowPdfFileDialog(true);
  };

  const handleSelectTakenTime = (e: any, name: any, id: any) => {
    e.target.checked && setShowSelectTimePopUp(true);
    setSelectedMedicine({name: name, id: id});
  };

  const updateMedicineTakenTime = (id: any, data: any) => {
    updateMedicineTakenTiming(personId, id, data)
      .then(res => {
        enqueueSnackbar(res?.message, SnackBarConfig('s'));
        handleGetMedicineReminders();
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const closeSelectTimePopUp = () => {
    setShowSelectTimePopUp(false);
  };

  const renderSelectTimePopUp = () => {
    return (
      <SelectTimePopUp
        open={showSelectTimePopUp}
        closeSelectTimePopUp={closeSelectTimePopUp}
        selectedMedicine={selectedMedicine}
        updateMedicineTakenTime={updateMedicineTakenTime}
        encId={encId}
      />
    );
  };

  const renderSingleMedicineReminder = (reminder: any) => {
    const name = reminder?.drug_name;
    const time = reminder?.drug_timing;
    const completed = reminder?.drug_intake_status;
    const takenTime =
      reminder?.drug_taken_time &&
      moment(reminder?.drug_taken_time).format('HH:mm A');

    const conertedToAMPM =
      takenTime && CommonUtils.convertTo12Hours(takenTime.slice(0, 5));
    const takenStatus =
      reminder?.drug_intake_status == 1
        ? 'taken-status-green'
        : reminder?.drug_intake_status == 2
        ? 'taken-status-amber'
        : 'taken-status-red';
    const takenStatusText =
      reminder?.drug_intake_status == 1
        ? 'taken-status-green-text'
        : reminder?.drug_intake_status == 2
        ? 'taken-status-amber-text'
        : 'taken-status-red-text';

    const checkBoxColor =
      reminder?.drug_intake_status == 1
        ? '#09B75A'
        : reminder?.drug_intake_status == 2
        ? '#ffbf00'
        : reminder?.drug_intake_status == 3
        ? '#ff0000'
        : '#b7b7b7';
    return (
      <Grid
        container
        className={
          completed
            ? `single-medicine-reminder ${takenStatus}`
            : 'single-medicine-reminder '
        }>
        <Grid xs={10} item className="single-reminder-name-time-main-grid">
          <Grid item className="medicine-name-grid">
            <Typography
              className={
                completed
                  ? `medicine-name-text ${takenStatusText} `
                  : 'medicine-name-text'
              }>
              {' '}
              {name}
            </Typography>
          </Grid>
          <Grid item className="medicine-reminder-time-grid">
            <Typography className={'medicine-reminder-time-text'}>
              {' '}
              {time}
            </Typography>

            {reminder?.drug_taken_time && (
              <Typography className={'medicine-reminder-taken-time-text'}>
                {' '}
                Taken: {conertedToAMPM}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid xs={2} item className="single-reminder-checkbox-grid">
          <Checkbox
            style={{color: checkBoxColor}}
            onChange={(e: any) =>
              handleSelectTakenTime(
                e,
                reminder?.drug_name,
                reminder?.push_notification_id
              )
            }
            checked={completed}
          />
        </Grid>
      </Grid>
    );
  };

  const medicineReminderIntervals = (interval: any, intervalArr: any) => {
    return (
      <Grid item xs={12} className="morning-remainders-main-grid">
        <Divider textAlign="left">
          {' '}
          <p className="reminder-interval-text">{interval} </p>
        </Divider>
        <Grid container className="single-medicine-reminder-main-grid">
          {intervalArr &&
            intervalArr?.map((reminder: any, index: any) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={index}
                  className="single-medicine-reminder-grid">
                  {renderSingleMedicineReminder(reminder)}
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    );
  };

  const handleSingleDate = (date: any) => {
    const changedDate = moment(todaysDate);
    const nextDay = moment(changedDate).add(1, 'days').format('MM-DD-YYYY');
    const displayDate =
      date == todaysDate
        ? 'Today'
        : date == nextDay
        ? 'Tomorrow'
        : moment(date).format('DD-MMM');
    return (
      <Typography
        className={
          (selectedDate || todaysDate) == date
            ? 'calendar-day-text selected-date'
            : 'calendar-day-text'
        }
        onClick={() => setSelectedDate(date)}>
        {displayDate}
      </Typography>
    );
  };

  const renderMedicineReminders = () => {
    const dateArray: any = [];
    const changedDate = moment(todaysDate);
    for (let i = 1; i <= 6; i++) {
      dateArray.push(moment(changedDate).add(i, 'days').format('MM-DD-YYYY'));
    }
    return (
      <Grid item container className="sections" direction="row" xs={12} sm={12}>
        <Box className="health-box-styles">
          <Typography className="text-field">
            <Grid className="test">
              <Grid>
                <Typography className="text">
                  {t('NURSE_DASHBOARD_MEDICINE_REMINDERS')}
                </Typography>
              </Grid>
            </Grid>
          </Typography>
          <Grid item className="calendar-main-grid" display="flex">
            {handleSingleDate(todaysDate)}
            {dateArray?.map((i: any) => {
              return handleSingleDate(i);
            })}
          </Grid>
          <Grid
            item
            container
            className="medicine-reminder-function-main-grid"
            xs={12}
            direction="row">
            {reminderData?.data?.morning?.length > 0 &&
              medicineReminderIntervals('Morning', reminderData?.data?.morning)}

            {reminderData?.data?.afternoon?.length > 0 &&
              medicineReminderIntervals(
                'Afternoon',
                reminderData?.data?.afternoon
              )}
            {reminderData?.data?.evening?.length > 0 &&
              medicineReminderIntervals('Evening', reminderData?.data?.evening)}
          </Grid>
        </Box>
      </Grid>
    );
  };

  const renderDisplayImagingData = (arr: any, category: any) => {
    return (
      arr?.length > 0 && (
        <Grid
          item
          container
          className="sections"
          direction="row"
          xs={12}
          sm={12}>
          <Box className="health-box-styles">
            <Typography className="text-field">
              <Grid className="test">
                <Grid>
                  <Typography className="text">{category}</Typography>
                </Grid>
                <Grid className="num">
                  <Typography>({arr?.length})</Typography>
                </Grid>
              </Grid>
            </Typography>
            <Grid item container className="imaging-single-grid" xs={12}>
              {isLoading ? (
                <Loader size={28} />
              ) : (
                arr?.map((item: any, index: any) => {
                  return (
                    <DisplayImagingData
                      key={index}
                      {...item}
                      type={category}
                      isNurse={true}
                      doctorID={docId}
                      handleDeleteAttachment={handleDeleteAttachment}
                      handleGetImagingData={
                        category == VACCINE
                          ? handleVaccineData
                          : handleGetImagingData
                      }
                    />
                  );
                })
              )}
            </Grid>
          </Box>
        </Grid>
      )
    );
  };

  const renderMainAddAttachment = () => {
    return (
      <Grid item xs={12} sm={10} className="sections">
        <Box className="health-box-styles ">
          <Typography className="text-field">
            {t('CONSULTANT_ATTACHMENTS')}
          </Typography>

          <Grid container className="inside-box add-attachment-button">
            {attachedFiles?.map((item: any, index: any) => {
              return (
                <Grid item key={index} className="single-pdf-attachment">
                  <p
                    className="single-attachment-name-and-icon"
                    onClick={() => handlePrescription(item?.file_path)}>
                    <Icons.PDFIcon />
                    <p className="single-pdf-file-name">
                      {item?.file_name}
                    </p>{' '}
                  </p>
                  <IconButton
                    onClick={() =>
                      handleDeleteAttachment('encounter', item?.id)
                    }>
                    {' '}
                    <Icons.CloseBlackIcon />
                  </IconButton>
                </Grid>
              );
            })}
          </Grid>
          <Link
            className=" add-attachment-button-link"
            onClick={() => setShowUploadAttachmentPopUp(true)}>
            <Icons.AddAttachmentIcon /> {t('COMMON_ADD_ATTACHMENT')}
          </Link>
        </Box>
      </Grid>
    );
  };

  const conditionForNursingServices = nurseDashboardServices?.includes(
    appointmentStatus?.toLowerCase()
  );
  return (
    <Grid className="nursing-wrapper">
      <Grid className="nursing-inside-grid">
        <Typography className="nursing-heading">
          {t('TEMPLATE_NURSING')}{' '}
        </Typography>
        <Grid className="nursing-sub-text-grid">
          <Typography className="nursing-sub-text">
            {' '}
            {t(
              'MEDICAL_ HISTORY_PLEASE_ENTER_ALL_THE_NECESSARY_INFORMATION_FOR_THE_FOLLOWING'
            )}
            .{' '}
          </Typography>
        </Grid>
        <Grid className="nursing-button-main-grid">
          <Button
            variant="outlined"
            className="nursing-buttons"
            startIcon={<Icons.CaptureVitalsIcon />}
            onClick={handleOpenAddVitals}>
            {t('VITALS')}
          </Button>
        </Grid>

        <Grid xs={12} className="sections-main-grid">
          {conditionForNursingServices && renderCaptureVitals()}
          {appointmentStatus === COMPLETED &&
            renderDisplayImagingData(imagingArray, IMAGING)}
          {appointmentStatus === COMPLETED &&
            renderDisplayImagingData(nursingArray, NURSING)}
          {appointmentStatus === COMPLETED &&
            renderDisplayImagingData(vaccineArray, VACCINE)}
          {appointmentStatus === COMPLETED &&
            renderDisplayImagingData(labArray, LAB)}
          {appointmentStatus === COMPLETED && renderMedicineReminders()}
          {appointmentStatus === COMPLETED && renderMainAddAttachment()}
        </Grid>
      </Grid>
      {showUploadAttachmentPopUp && renderShowUploadAttachmentPopUp()}
      {showPdfFileDialog && renderPdfViewerDialog()}
      {showDeleteAttachmentConfirmationPopUp &&
        renderShowDeleteAttachmentConfirmationPopUp()}
      {showVitalsPopUp && renderAddVitalsPopUp()}
      {showSelectTimePopUp && renderSelectTimePopUp()}
    </Grid>
  );
};

export default NursingSectionComponent;
