export const OxygenSaturation = [
  {
    min: 90,
    max: 100,
    value: 1
  },
  {
    min: 0,
    max: 89,
    value: 0
  },
  {
    min: 101,
    max: 'above',
    value: 0
  }
];

export const getOxygenSaturationCalculation = (value: any): any => {
  try {
    for (const os of OxygenSaturation) {
      if (value >= os.min && (value <= os.max || os.max === 'above')) {
        return os.value;
      }
    }
    return null;
  } catch (e) {
    throw new Error((e as any)?.message);
  }
};
