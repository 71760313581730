import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import { ReactComponent as ForumsReplyIconSvg } from '@assets/images/healpha/reply_forums.svg';

const ForumsReplyIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={ForumsReplyIconSvg} viewBox="0 0 35 35" />
  );
};

export default ForumsReplyIcon;