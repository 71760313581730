import React from 'react';
import {
  Button,
  Divider,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import WriteMessageNew from './WriteMessageNew';
import Icons from '@components/system/MyIcons';
import './Connect.scss';

const ConnectNowNew = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(props?.open);
  const [openWriteMessage, setOpenWriteMessage] = React.useState(false);
  const {runOnClick, appointmentId} = props;

  const handleClose = (openVideoCall?: boolean) => {
    setOpenWriteMessage(false);
    setOpen(false);
    props?.closeConnectNow(openVideoCall);
  };

  const renderWriteMessage = (): React.ReactElement => {
    return (
      <WriteMessageNew
        open={openWriteMessage}
        closeWriteMessage={handleClose}
        runOnClick={runOnClick}
        appointmentId={appointmentId}
      />
    );
  };

  const handleOpenWriteMessageNow = () => {
    setOpenWriteMessage(true);
  };

  const renderVideoCallCard = (): React.ReactElement => {
    return (
      <Grid
        item
        container
        xs={12}
        id="video_call_button"
        className="video-call-card"
        onClick={() => handleClose(true)}>
        <Grid item>
          <Icons.VideoCallIcon className="video-call-icon" />
        </Grid>
        <Grid item className="videoButton">
          Healpha{' '}
          <Typography className="textBelow">
            {t('CONNECT_NOW_USE_OUR_VIDEO_CALL_FEATURE')}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderSubmitButton = (): React.ReactElement => {
    return (
      <Grid item xs={12} className="unavailable">
        <Button
          id="patient_unavailable"
          className="button"
          onClick={handleOpenWriteMessageNow}>
          {t('CONNECT_NOW_MARK_PATIENT_AS_UNAVAILABLE')}
        </Button>
      </Grid>
    );
  };

  const renderConnectNowTitle = (): React.ReactElement => {
    return (
      <Grid item container xs={12} className="title-wrapper">
        <Grid item xs={11} className="title">
          {t('CONNECT_NOW_CONNECT_TO_THE_DOCTOR_WITH')}
        </Grid>
        <Grid item xs={1}>
          <Icons.CloseIcon
            cursor="pointer"
            onClick={handleClose}
            className="close-icon"
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      className="connect-now-wrapper">
      <DialogTitle>{renderConnectNowTitle()}</DialogTitle>
      <Divider />
      <DialogContent className="title">
        <Grid item container xs={12}>
          {renderVideoCallCard()}
          <Divider />
          <Grid item container xs={12} className="alternative">
            <Grid item xs={12} className="alternative-text">
              {t('CONNECT_NOW_IF_YOU_ARE_UNABLE_TO_CONNECT')}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>{renderSubmitButton()}</DialogActions>
      {openWriteMessage && renderWriteMessage()}
    </Dialog>
  );
};

export default ConnectNowNew;
