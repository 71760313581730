/* eslint-disable camelcase */
import React, {useEffect, useRef, useState} from 'react';
import {
  Box,
  Grid,
  FormControl,
  TextField,
  IconButton,
  FormLabel,
  Button,
  Link
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import CloseBlackIcon from '@components/system/MyIcons/CloseBlackIcon';
import {actionType, StepsCommonProps} from '../Appointment';
import './AppointmentSteps.scss';
import {useTranslation} from 'react-i18next';
import {inRange, isEmpty} from 'lodash';
import {sendOtpToUser, verifyOtp} from '@shared/services/PublicService';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';

const useStyles = makeStyles({
  container: {
    flexWrap: 'nowrap',
    flexDirection: 'column'
  },
  header: {},
  headerTitle: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const OTPInputComponent = ({
  values,
  onChange,
  id
}: {
  values: Array<string>;
  onChange: any;
  id: string;
}): React.ReactElement => {
  const inputRefs = useRef([
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>()
  ]);
  return (
    <Box sx={{marginBottom: '1rem'}}>
      {values.map((x, index) => (
        <TextField
          type="number"
          inputRef={inputRefs.current[index]}
          variant="outlined"
          size="small"
          id={id}
          className="step-two-otp"
          value={x}
          key={index}
          onChange={(e: any) => {
            const temp = [...values];
            const value = e.target.value;
            temp[index] = value && inRange(value, 0, 10) ? value : '';
            onChange(temp);
            if (index < values.length - 1 && e.target.value.length === 1) {
              inputRefs.current[index + 1]?.current?.focus();
            }
          }}
          // inputProps={{maxLength: 1}}
          InputProps={{inputProps: {maxLength: 1, min: 0, max: 9}}}
          sx={{
            border: '1px solid lightgrey',
            borderRadius: '2px',
            p: 2,
            width: '3rem',
            direction: 'ltr',
            marginTop: '1rem',
            marginRight: '2px'
          }}
        />
      ))}
    </Box>
  );
};

type FormTwoState = {
  otpArr: string[];
};

function StepTwo({data, dispatch}: StepsCommonProps): React.ReactElement {
  const styles = useStyles();
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [seconds, setSeconds] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formTwo, setFormTwo] = useState<FormTwoState>({
    otpArr: ['', '', '', '', '', '']
  });
  const isValidForm = (): boolean => {
    return formTwo.otpArr?.every(num => !isEmpty(num));
  };
  const resendOtp = (): void => {
    const otpData = {
      phone_code: data.formOne.phoneCode,
      phone_number: data.formOne.mobileNumber
    };
    if (data?.formOne?.mobileNumber && data?.formOne?.phoneCode) {
      setLoading(true);
      sendOtpToUser(otpData)
        .then(res => {
          if (res?.status_code === 200) {
            setLoading(false);
            setSeconds(30);
          } else {
            throw new Error(res?.message);
          }
        })
        .catch(err => {
          setLoading(false);
        });
    }
  };
  const onSubmitHandler = (e: any): void => {
    e.preventDefault();
    const otpData = {
      code: formTwo.otpArr.join(''),
      phone_code: data.formOne.phoneCode,
      phone_number: data.formOne.mobileNumber
    };
    if (isValidForm() && !loading) {
      verifyOtp(otpData)
        .then(res => {
          if (res?.status_code === 200) {
            dispatch({type: actionType.NEXT_STEP, payload: {...data, formTwo}});
          } else {
            throw new Error(res?.message);
          }
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <form id="step-two" onSubmit={onSubmitHandler}>
      <Grid container p={1} className={styles.container}>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          className={styles.header}>
          <Grid item>
            <Box className={styles.headerTitle}>
              <Box>{t('Enter the six digit code sent to')}</Box>
            </Box>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() =>
                dispatch({type: actionType.CLOSE_MODAL, payload: null})
              }>
              <CloseBlackIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item sx={{justifyContent: 'space-between'}}>
            <FormControl className="outlined-textfield" variant="standard">
              <FormLabel sx={{fontWeight: 'bold'}} htmlFor="step-two-otp">
                {`+91${data.formOne.mobileNumber}`}
              </FormLabel>
              <OTPInputComponent
                id="step-two-otp"
                values={formTwo.otpArr}
                onChange={(val: string[]) =>
                  setFormTwo(state => ({...state, otpArr: val}))
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        {!loading && (
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="center">
            <Grid item sx={{justifyContent: 'space-around'}}>
              <div className="normal-text">
                {t('STILL_NOT_RECEIVED_OTP')}{' '}
                <Link
                  component="button"
                  type="button"
                  variant="body2"
                  className="link-text"
                  disabled={seconds > 0} // [TODO] Add logic
                  onClick={resendOtp}>
                  {/* [TODO] add callback */}
                  {seconds > 0
                    ? `${t('RESEND_OTP')} in ${seconds}`
                    : t('RESEND_OTP')}
                </Link>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container item justifyContent="center" alignItems="center">
          <Grid item sx={{justifyContent: 'center', width: '100%'}}>
            <Button
              disabled={loading || !isValidForm()}
              fullWidth
              variant="contained"
              type="submit">
              {t('CLINIC_CONTINUE')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default StepTwo;
