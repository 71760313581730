import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as HealphaLogoWhiteIconSvg} from '@assets/images/healpha/HealphaLogoWhite.svg';

const HealphaLogoWhiteIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={HealphaLogoWhiteIconSvg}
      viewBox="0 0 99.284 20.718"
    />
  );
};

export default HealphaLogoWhiteIcon;
