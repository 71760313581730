/* eslint-disable camelcase */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import {FCM_EXIT_ROOM} from 'src/constants/CommunicationServiceConstants';
import {CommunicationService} from 'src/service/CommunicationService';
import './ConfirmationPopupComponent.scss';

const ConfirmationPopupComponent = (props: any): React.ReactElement => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const subscription: any = React.useRef(null);

  const [open, setOpen] = React.useState(props?.open);

  const closePopup = (action: boolean): void => {
    setOpen(false);
    if (props?.action && typeof props.action === 'function') {
      props.action(action);
    }
  };

  React.useEffect(() => {
    subscription.current = CommunicationService.getMessage().subscribe(
      function (message: any) {
        if (
          message?.data?.type === FCM_EXIT_ROOM &&
          message?.data?.value === true
        ) {
          setOpen(false);
          if (props?.removePopup && typeof props.removePopup === 'function') {
            props.removePopup();
          }
        }
      }
    );

    return () => {
      return subscription?.current?.unsubscribe();
    };
  });

  const renderDialogContent = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        <Typography style={{paddingTop: '1rem'}}>{props?.message}</Typography>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        {props?.noLabel && (
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="yes-button no-button"
              onClick={() => closePopup(false)}>
              {props?.noLabel}
            </Button>
          </Grid>
        )}
        <Grid item xs={6} container justifyContent="flex-end">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="yes-button"
            onClick={() => closePopup(true)}>
            {props?.yesLabel}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className="confirmation-popup-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        open={open}
        aria-labelledby="confirmation-popup"
        aria-describedby="confirmation-popup"
        classes={{paper: 'confirmation-popup'}}>
        <DialogContent className="confirmation-popup-content">
          {renderDialogContent()}
        </DialogContent>

        <DialogActions
          disableSpacing={true}
          className="confirmation-popup-actions">
          {renderDialogActions()}
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ConfirmationPopupComponent;
