import * as CryptoJS from 'crypto-js';
import {environment} from '../environment/environment';

const isEncryptLocalStorage = environment.encryptLocalStorage;
const key = environment.encryptLocalStorageKey;

export const encrypt = (value: string): string => {
  return isEncryptLocalStorage
    ? CryptoJS.AES.encrypt(value, key).toString()
    : value;
};

export const decrypt = (value: string | null): string => {
  return value
    ? isEncryptLocalStorage
      ? CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8)
      : value
    : '';
};
