import NavRoutes from './NavRoutes';
import HospitalViewComponent from 'src/modules/hospital/modules/HospitalView/HospitalViewComponent';
import DoctorViewComponent from 'src/modules/doctor/modules/DoctorView/DoctorViewComponent';

const HospitalHomeRoute = NavRoutes.PUBLIC.HOSPITAL;
const DoctorHomeRoute = NavRoutes.PUBLIC.DOCTOR;

export const CommonRoute = {
  exactFrom: '/',
  redirectTo: `/${NavRoutes.PUBLIC.LOGIN}`,
  routes: [
    {
      path: `/${HospitalHomeRoute.BASE}/view/:id`,
      component: HospitalViewComponent,
      exact: true
    },
    {
      path: `/${DoctorHomeRoute.BASE}/view/:id`,
      component: DoctorViewComponent,
      exact: true
    }
  ]
};
