import {AxiosCall} from '../axios';
import {environment} from '../environment/environment';

const apiEndPoints = environment.apiEndPoints;
const menuDataApiUrl = apiEndPoints.commonApi.menuDataApi;
const branchesApiUrl = apiEndPoints.commonApi.branchesApi;
const menuDataPersonApiUrl = apiEndPoints.commonApi.personMenuDataApi;
const meApiUrl = apiEndPoints.commonApi.meApi;

export const getMeUser = async (): Promise<any> => {
  return AxiosCall.get(`${meApiUrl}`);
};

export const getUserMenuData = async (
  nh_id: any,
  branch_id: string
): Promise<any> => {
  const url = `${menuDataApiUrl}?nh_id=${nh_id}&branch_id=${branch_id}&is_new_response=${1}`;
  return AxiosCall.get(url);
};

export const getDomain = (): string => {
  return environment.baseHost;
};

export const getNonHealphaUserBranches = async (
  searchText?: string,
  is_practice?: boolean,
  is_practice_listing?: boolean,
  is_pagination?: boolean,
  limit?: number,
  offset?: number,
  data?: any
): Promise<any> => {
  const url = `${branchesApiUrl}?search_text=${searchText || ''}&is_practice=${
    is_practice || ''
  }&is_practice_listing=${is_practice_listing || ''}&is_pagination=${
    is_pagination || ''
  }&limit=${limit || ''}&offset=${offset || ''}&practice_id=${data || ''}`;
  return AxiosCall.get(url);
};

export const getPersonMenuData = async (): Promise<any> => {
  return AxiosCall.get(menuDataPersonApiUrl);
};

export const generateVideoToken = async (data: any): Promise<any> => {
  const url = '/colloboration/twilio/generate-access-token';
  return AxiosCall.post(url, data);
};

export const checkRoomParticipantStatus = async (data: any): Promise<any> => {
  const url = `/colloboration/twilio/room-participant`;
  return AxiosCall.post(url, data);
};

export const getVirtualPatientsList = async (
  branchId: string,
  searchText: any
): Promise<any> => {
  const url = `/user/person/all?branch_id=${branchId}&search_text=${searchText}`;
  return AxiosCall.get(url);
};

export const getVirtualPatient = async (
  branchId: string,
  searchText: any
): Promise<any> => {
  const url = `/user/person/all?virtual_clinic_branch=${branchId}&search_text=${searchText}`;
  return AxiosCall.get(url);
};

export const getVirtualDoctorsList = async (
  branchId: string,
  searchText: any
): Promise<any> => {
  const url = `/virtual-clinic/${branchId}/doctor/all?search_text=${searchText}`;
  return AxiosCall.get(url);
};

export const getVirtualAppointmentStatusList = async (
  virtualClinicBranchId: any,
  date: any
): Promise<any> => {
  const url = `/appointment/status/all?virtual_clinic_branch=${virtualClinicBranchId}&date=${date}`;
  return AxiosCall.get(url);
};

export const getVirtualAppointmentList = async (
  appointmentChecked: any,
  searchText: any,
  date: any,
  virtualClinicBranchId: any,
  total_appts_req?: any
): Promise<any> => {
  const url = `/appointment/list/all?virtual_clinic_branch=${virtualClinicBranchId}\
  &date=${date}&appointment_checked=${appointmentChecked}&search_text=${searchText}&total_appts_req=${
    total_appts_req || false
  }`;
  return AxiosCall.get(url);
};

export const getPersonAppointmentList = async (
  hlpId: any,
  appointmentChecked: any,
  searchText: any,
  date: any,
  isDoctorSearch: any,
  total_appts_req?: any,
  is_upcoming?: any
): Promise<any> => {
  const url = `/appointment/list/all?healpha_id=${hlpId}\
&date=${date}&appointment_checked=${appointmentChecked}&search_text=${searchText}&is_doctor_search=${isDoctorSearch}&total_appts_req=${
    total_appts_req || false
  }&is_upcoming=${is_upcoming}`;
  return AxiosCall.get(url);
};

export const getPersonAppointmentListQr = async (
  hlpId: any,
  docId: any,
  searchText: any,
  date: any,
  endDate: any,
  isDoctorSearch: any,
  total_appts_req?: any
): Promise<any> => {
  const url = `/appointment/list/all?healpha_id=${hlpId}\
&doctor_id=${docId}&date=${date}&search_text=${searchText}&end_date=${endDate}&is_doctor_search=${isDoctorSearch}&total_appts_req=${
    total_appts_req || false
  }`;
  return AxiosCall.get(url);
};

export const getVitals = async (data: any): Promise<any> => {
  const url = `/appointment/encounter/${data.enc_id}/doctor/${data.doc_id}/person/${data.hlp_id}/vital`;
  return AxiosCall.get(url);
};

export const saveVitals = async (data: any): Promise<any> => {
  const url = `/appointment/encounter/${data.enc_id}/doctor/${data.doc_id}/person/${data.hlp_id}/vital`;
  const payload = {
    vitals: data.vitals,
    comments: data.comments
  };
  return AxiosCall.post(url, payload);
};

export const linkPersonToVirtualClinic = async (data: any): Promise<any> => {
  const url = `/virtual-clinic/${data.branchId}/person/${data.hlpid}`;
  return AxiosCall.post(url, {});
};

export const linkPersonsToBranches = async (data: any): Promise<any> => {
  const url = `/person/${data.hlpid}/branch/${data.branchId}/create-credit`;
  return AxiosCall.post(url, {});
};

export const getVirtualAdmins = async (branchId: string): Promise<any> => {
  const url = `/virtual-clinic/${branchId}/users/all`;
  return AxiosCall.get(url);
};

export const createEncounter = async (
  appointmentId: any,
  data: any
): Promise<any> => {
  const url = `/appointment/${appointmentId}/encounter/create`;
  return AxiosCall.post(url, data);
};

export const getDegrees = async (): Promise<any> => {
  const url = `/reference-data/master-degree`;
  return AxiosCall.get(url);
};

export const getGooglePredictions = async (text: string): Promise<any> => {
  const url = `reference-data/places-list/all?search_text=${text || ''}`;
  return AxiosCall.get(url);
};

export const getPlaceDetails = async (placeId: string): Promise<any> => {
  const url = `reference-data/places-details/${placeId}`;
  return AxiosCall.get(url);
};

export const getPlaceDetailsByLatLang = async (
  latitude: any,
  longitude: any
): Promise<any> => {
  const url = `reference-data/gecode-details/latitude/${latitude}/longitude/${longitude}`;
  return AxiosCall.get(url);
};

export const getStatesCountries = async (
  countryId?: any,
  searchText?: any,
  text?: any
): Promise<any> => {
  const url = `/reference-data/country-state/all?country_id=${
    countryId || ''
  }&search_text1=${searchText || ''}&search_text2=${text || ''}`;
  return AxiosCall.get(url);
};
