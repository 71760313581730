import React from 'react';
import {CircularProgress} from '@material-ui/core';
import './Loader.scss';

interface LoaderProps {
  className?: string;
  classes?: any;
  color?: 'inherit' | 'primary' | 'secondary';
  disableShrink?: boolean;
  size?: number | string;
  thickness?: number;
  value?: number;
  variant?: 'determinate' | 'indeterminate';
}

const Loader = (props: LoaderProps): React.ReactElement => {
  const {
    className,
    classes,
    color,
    disableShrink,
    size,
    thickness,
    value,
    variant
  } = props;

  return (
    <div className={['loader', className].join(' ')}>
      <CircularProgress
        classes={classes}
        color={color}
        disableShrink={disableShrink}
        size={size}
        thickness={thickness}
        value={value}
        variant={variant}
      />
    </div>
  );
};
export default Loader;
