/* eslint-disable camelcase */
import React from 'react';
import './DoctorQrCodePopup.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@components/system/MyIcons/CloseIcon';
import DownloadIconSvgIcon from '@components/system/MyIcons/DownloadIcon';
import {useTranslation} from 'react-i18next';

const DoctorQrPopUp = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(props?.open);
  const [info, setInfo]: any = React.useState({});

  const getHospitalDetails = () => {
    setInfo(props?.doctorInfo);
  };
  const init = () => {
    getHospitalDetails();
  };

  React.useEffect(init, [props?.doctorInfo]);
  const handleClose = () => {
    setOpen(false);
    if (
      props?.closeQrpagePopup &&
      typeof props.closeQrpagePopup === 'function'
    ) {
      props.closeQrpagePopup();
    }
  };
  const handleDownload = (e: any, val: any) => {
    fetch(val, {
      method: 'GET',
      headers: {}
    }).then(response => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'qr.png');
        document.body.appendChild(link);
        link.click();
      });
    });
  };
  const RenderQr = () => {
    const QrImage = info?.docQrcode;

    return (
      <DialogContent>
        <Grid className="QrWrapper" container xs={12}>
          <Grid className="closeIcon" item xs={12}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid className="QrGrid" item>
            {QrImage ? (
              <img className="Qr" src={QrImage} />
            ) : (
              <Typography>{t('QR_NOT_GENERATED')}</Typography>
            )}
          </Grid>
          <Grid className="TextGrid" item>
            <Typography>
              {t('SCAN_QR_TO_BOOK_APPOINTMENT')}
              <Typography className="branchName"> {info.name}</Typography>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    );
  };
  const RenderDownloadButtons = () => {
    return (
      <DialogActions>
        <Grid className="ButtonWrapper" container xs={12}>
          <Grid item xs={12} className="buttonGrid">
            <Button
              className="button"
              variant="contained"
              startIcon={<DownloadIconSvgIcon />}
              onClick={e => {
                handleDownload(e, info?.docQrcode);
              }}>
              <Typography className="downloadFont">{t('DOWNLOAD')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    );
  };
  return (
    <Grid>
      <Dialog
        className="mainWrapper"
        open={open}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '27.5rem',
              maxHeight: '31.25rem',
              borderRadius: '.625rem'
            }
          }
        }}>
        <RenderQr />
        <RenderDownloadButtons />
      </Dialog>
    </Grid>
  );
};
export default DoctorQrPopUp;
