/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import {sendOtpToUser} from '@shared/services/PublicService';

import Icons from '@components/system/MyIcons/index';
import Loader from '@components/framework/loader/Loader';
import FormSelectField from '@components/system/CustomFormField/FormSelectField';
import {familyRelationsList} from 'src/constants/FormFields';
import PersonLoginComponent from '../PersonLogin/PersonLoginComponent';
import OtpScreenComponent from '../OtpScreen/OtpScreenComponent';
import LoginUserComponent from '../LoginUser/LoginUserComponent';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {BORDER_TERTIARY_COLOR} from 'src/style/variable';
import './RelationShipPopupComponent.scss';

const dropDownProps = {
  height: '3.9rem',
  width: '100%',
  border: `1px solid ${BORDER_TERTIARY_COLOR}`
};

const RelationShipPopupComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const phoneNumber = props?.values?.phone_number;
  const familyMemberName =
    props?.familyHeadObject.first_name +
    ' ' +
    props?.familyHeadObject.last_name;

  const [open, setOpen] = React.useState(props?.open);
  const [openLoginPopup, setOpenLoginPopup] = React.useState(false);
  const [openOtpPopup, setOpenOtpPopup] = React.useState(false);
  const [showLoginUserPopup, setShowLoginUserPopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [values, setValues]: any = React.useState(props?.values);

  const handleClose = (event?: any, reason?: any) => {
    setOpen(false);
    props?.closeRelationPopup();
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({...values, [prop]: event.target.value});
  };

  const showLoginPopup = () => {
    if (props?.isFromBookAppointment) {
      setShowLoginUserPopup(true);
    } else {
      setOpenLoginPopup(true);
    }

    setOpen(false);
  };

  const closeOtpPopup = (): void => {
    setOpenOtpPopup(false);
    handleClose();
  };

  const closeLoginPopup = (): void => {
    setShowLoginUserPopup(false);
    handleClose();
  };

  const handleSubmit = (): void => {
    const data = {
      phone_code: props?.values?.phone_code,
      phone_number: props?.values?.phone_number,
      email: props?.values?.email,
      first_name: props?.values?.first_name,
      last_name: props?.values?.last_name
    };

    setIsLoading(true);
    sendOtpToUser(data)
      .then(res => {
        setValues({
          ...values,
          ['family_head_id']: props?.familyHeadObject?.hlpid || ''
        });
        setIsLoading(false);
        setOpen(false);
        setOpenOtpPopup(true);
      })
      .catch(err => {
        setIsLoading(false);
        enqueueSnackbar(
          err?.message ? err.message : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const renderLoginPopup = (): React.ReactElement => {
    return (
      <PersonLoginComponent
        open={openLoginPopup}
        closePersonLoginPopup={handleClose}
      />
    );
  };

  const renderLoginUserPopup = (): React.ReactElement => {
    return (
      <LoginUserComponent
        open={showLoginUserPopup}
        closeLoginPopup={closeLoginPopup}
        appointmentValues={props?.appointmentValues}
      />
    );
  };

  const renderOtpScreen = (): React.ReactElement => {
    return (
      <OtpScreenComponent
        open={openOtpPopup}
        closeOtpPopup={closeOtpPopup}
        values={values}
        appointmentValues={props?.appointmentValues}
        isFromRelation={true}
        isFromBookAppointment={props?.isFromBookAppointment}
        isFromVirtualClinic={props?.isFromVirtualClinic}
        isFromClinicalDashboard={props?.isFromClinicalDashboard}
        isFromStandAloneDashboard={props?.isFromStandAloneDashboard}
      />
    );
  };

  const renderFormSection = (): React.ReactElement => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormSelectField
            name="relation"
            placeholder={t('SELECT_RELATION')}
            value={values?.relation}
            dropDownProps={dropDownProps}
            Icon={Icons.DropDownIcon}
            StartAdornmentIcon={Icons.GenderIcon}
            dropDownOptions={familyRelationsList}
            callback={handleChange('relation')}
          />
        </Grid>
      </Grid>
    );
  };

  const renderDialogTitle = (): React.ReactElement => {
    return (
      <Grid
        container
        spacing={2}
        className="secondary-common-dialog-title-wrapper">
        <Grid container item xs={12}>
          <Grid item xs={10}>
            <Typography className="relationship-header-text">
              {t('FAMILY_MEMBER_RELATION_INFO', {
                phoneNumber: phoneNumber,
                familyMemberName: familyMemberName
              })}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <IconButton
              id="close_realtion_popup"
              style={{marginTop: '-1rem', marginLeft: '2rem'}}
              onClick={handleClose}>
              <Icons.CloseBlackIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDialogContent = (): React.ReactElement => {
    return (
      <Grid>
        <Grid style={{marginTop: '1rem'}}>{renderFormSection()}</Grid>
      </Grid>
    );
  };

  const renderDialogActions = (): React.ReactElement => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            id="save_and_create_account"
            variant="contained"
            size="medium"
            style={{marginTop: '0.5rem'}}
            color="primary"
            className="submit-button"
            disabled={isLoading}
            onClick={() => handleSubmit()}>
            {isLoading ? <Loader size={28} /> : t('SAVE_CREATE_ACCOUNT')}
          </Button>
        </Grid>

        {!props?.isFromVirtualClinic && (
          <Grid
            xs={12}
            item
            container
            style={{marginTop: '1rem'}}
            justifyContent="center"
            alignItems="center">
            <Typography className="or-text">{t('OR')}</Typography>
          </Grid>
        )}

        {!props?.isFromVirtualClinic && (
          <Grid
            xs={12}
            item
            container
            style={{marginTop: '1rem'}}
            justifyContent="center"
            alignItems="center">
            <Link
              id="back_to_login"
              component="button"
              variant="body2"
              className="link-text"
              style={{textDecoration: 'underLine'}}
              disabled={isLoading}
              onClick={showLoginPopup}>
              {t('GO_BACK_LOGIN')}
            </Link>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Grid className="person-relation-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        aria-labelledby="person-relation-pop-up"
        aria-describedby="person-relation"
        classes={{paper: 'person-doctor-popup'}}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} className="popup-left-section" />

          <Grid item xs={12} md={8} className="popup-right-section">
            <DialogTitle id="person-registration-title" className="">
              {renderDialogTitle()}
            </DialogTitle>

            <DialogContent className="person-doctor-popup-content">
              {renderDialogContent()}
            </DialogContent>

            <DialogActions
              disableSpacing={true}
              className="person-doctor-popup-actions">
              {renderDialogActions()}
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      {openLoginPopup && renderLoginPopup()}
      {openOtpPopup && renderOtpScreen()}
      {showLoginUserPopup && renderLoginUserPopup()}
    </Grid>
  );
};

export default RelationShipPopupComponent;
