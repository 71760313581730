/* eslint-disable camelcase */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Grid,
  FormControl,
  IconButton,
  FormLabel,
  Button,
  Typography,
  Stack,
  Chip
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import CloseBlackIcon from '@components/system/MyIcons/CloseBlackIcon';
import StethoscopeIcon from '@components/system/MyIcons/StethoscopeIcon';
import './AppointmentSteps.scss';
import CustomAutoCompleteField from '@components/system/CustomAutoCompleteField/CustomAutoCompleteField';
import {CalendarPicker, LocalizationProvider} from '@material-ui/lab';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import {actionType, StepsCommonProps} from '../Appointment';
import moment from 'moment';
import SelectDropdown from '../SelectDropdown';
import {CardView} from '../CardView';
import styled from '@emotion/styled';
import {
  AccessTimeSharp as AccessTimeSharpIcon,
  WbSunnyOutlined,
  Brightness4Outlined
} from '@material-ui/icons';
import {capitalize, every, get, isEmpty} from 'lodash';
// import InitSharedRepo from '../../../../../../../shared';
import {
  getAppointmentTypes,
  getAvailableTimeSlots,
  searchPerson
} from '@shared/services/PublicService';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles({
  container: {
    flexWrap: 'nowrap',
    flexDirection: 'column'
  },
  header: {},
  headerTitle: {
    display: 'flex',
    flexDirection: 'column'
  }
});

interface ChipData {
  label: string;
  value: string;
}

const ListItem = styled('li')(({theme}) => ({
  margin: 0.5
}));

interface ChipsArrayProps {
  list: Array<ChipData>;
  icon: any;
  selected: string;
  onClick: any;
}

function ChipsArray(props: ChipsArrayProps): React.ReactElement {
  const {list, icon, selected, onClick} = props;

  return (
    <Stack
      direction="row"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
        marginBottom: '10px',
        height: '200px',
        overflowY: 'scroll'
      }}
      component="ul">
      {list.map((data, index) => {
        const isSelected = selected === data.value;
        return (
          <ListItem key={index}>
            <Chip
              label={data.label}
              icon={icon}
              clickable
              color={isSelected ? 'primary' : 'default'}
              variant={isSelected ? 'filled' : 'outlined'}
              sx={{
                borderRadius: '4px'
              }}
              className="slot-chip"
              onClick={() => onClick(data.value)}
            />
          </ListItem>
        );
      })}
    </Stack>
  );
}

type FormFourState = {
  selectedPatient: any;
  appointmentType: string;
  selectedDate: moment.Moment | null;
  selectedTimeSlot: string;
};

function StepFour({
  data,
  dispatch,
  doctorId,
  branchId
}: StepsCommonProps): React.ReactElement {
  const styles = useStyles();
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [searchFieldOptions, setSearchFieldOptions] = useState<any>([]);
  const [availableSlots, setAvailableSlots] = useState<any>({
    morning: [],
    evening: []
  });
  const [appointmentTypes, setAppointmentTypes] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formFourState, setFormFourState] = useState<FormFourState>({
    selectedPatient: {},
    appointmentType: '',
    selectedDate: moment(),
    selectedTimeSlot: ''
  });
  const getCurrentState = useMemo(
    () => (): Promise<FormFourState> =>
      new Promise(resolve =>
        setFormFourState(currentState => {
          resolve(currentState);
          return currentState;
        })
      ),
    [setFormFourState]
  );
  const setPartialState = useMemo(
    () => async (field: string, value: any) => {
      const currentState = await getCurrentState();
      setFormFourState({...currentState, [field]: value});
    },
    [getCurrentState]
  );
  const loadData = async (searchTerm: string): Promise<any> => {
    if (searchTerm) {
      setLoading(true);
      searchPerson(searchTerm)
        .then(res => {
          const listOfPersons = res?.data?.persons
            ? res.data.persons.map((person: any) => ({
                ...person,
                fullName: person.full_name,
                displayText: `${person.full_name}, ${person.age || ''} ${
                  capitalize(person.gender) || ''
                }`
              }))
            : [];
          setSearchFieldOptions(listOfPersons);
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const isValidForm = (): boolean => {
    return every(formFourState, (value, _) => !isEmpty(value));
  };
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    if (isValidForm() && !loading) {
      dispatch({
        type: actionType.NEXT_STEP,
        payload: {
          ...data,
          formFour: formFourState
        }
      });
    }
  };
  const getSelectedTimeSlots = useCallback(
    (newDate?: moment.Moment | undefined) => {
      if (branchId && doctorId) {
        const todayDate = newDate ? newDate : new Date();
        const formattedDate = moment(todayDate).format('YYYY-MM-DD');
        getAvailableTimeSlots(doctorId, branchId, formattedDate)
          .then(res => {
            if (res.status_code !== 200) throw new Error(res?.message);
            setAvailableSlots({
              morning: get(res, 'data.free_slots.morning', []),
              evening: get(res, 'data.free_slots.evening', [])
            });
          })
          .catch(err => {
            setAvailableSlots({morning: [], evening: []});
            enqueueSnackbar(
              err?.message ? err.message : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [doctorId, branchId]
  );
  const onDateChangeHandler = (newDate: moment.Moment | null): void => {
    if (formFourState.selectedDate !== newDate && newDate) {
      const n1 = newDate;
      const n2 = moment();

      if (n1 > n2) {
        setPartialState('selectedDate', newDate);
        getSelectedTimeSlots(newDate);
      } else {
        setPartialState('selectedDate', moment());
        getSelectedTimeSlots();
      }
    }
  };

  const initGetSelectedTimeSlots = () => {
    // selected patient flow
    if (data?.formThree?.hlpid) {
      setFormFourState(state => ({
        ...state,
        selectedPatient: {
          ...data?.formThree,
          phone_no: data?.formThree?.phone_number
        }
      }));
    }
    getSelectedTimeSlots();
  };
  useEffect(initGetSelectedTimeSlots, []);

  const initGetTypes = () => {
    if (branchId && doctorId) {
      getAppointmentTypes(doctorId, branchId)
        .then(res => {
          if (res?.status_code !== 200) throw new Error(res?.message);
          setAppointmentTypes(res?.data?.types || []);
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
    }
  };
  useEffect(initGetTypes, [branchId, doctorId]);
  return (
    <form id="step-four" onSubmit={onSubmitHandler}>
      <Grid container p={1} className={styles.container}>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          className={styles.header}>
          <Grid item>
            <Box className={styles.headerTitle}>
              <Box>New Appointment</Box>
            </Box>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() =>
                dispatch({type: actionType.CLOSE_MODAL, payload: null})
              }>
              <CloseBlackIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          item
          mb={1}
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="flex-start">
          <Grid
            container
            item
            direction="column"
            sx={{
              justifyContent: 'space-between',
              marginRight: '10px',
              width: 300
            }}>
            <CustomAutoCompleteField
              options={searchFieldOptions}
              field="displayText"
              // infoField={searchField === 2 ? 'city' : ''}
              id="step-four-patient-search"
              disabled={false}
              required={false}
              placeHolder={t('CLINIC_SEARCH_PATIENT')}
              onChange={(newValue: string, id: string) =>
                // setSelectPatient(newValue)
                setPartialState('selectedPatient', newValue)
              } // after selection
              // onInputChange={onInputChange}
              apiCallback={loadData}
              defaultValue={formFourState.selectedPatient || null}
              loading={loading}
              debounce={true}
              noOptionsText={t('CLINIC_NO_PATIENT_FOUND')}
            />
            {!isEmpty(formFourState.selectedPatient) && (
              <>
                <FormControl className="outlined-textfield" variant="standard">
                  <FormLabel htmlFor="step-four-appointment-type">
                    <StethoscopeIcon />
                    <Typography>{t('CLINIC_APPOINTMENT_TYPE')}</Typography>
                  </FormLabel>
                  <SelectDropdown
                    id="step-four-appointment-type"
                    options={appointmentTypes}
                    field="appointment_type_name"
                    value={formFourState.appointmentType}
                    placeHolder="Select Appointment type"
                    onChange={(value: any) =>
                      setPartialState('appointmentType', value)
                    }
                    // required
                  />
                </FormControl>
                <CardView
                  avatarUrl={
                    formFourState.selectedPatient?.person_image || undefined
                  }
                  name={formFourState.selectedPatient?.fullName || ''}
                  gender={formFourState.selectedPatient?.gender || ''}
                  age={formFourState.selectedPatient?.age || ''}
                  email={formFourState.selectedPatient?.email || ''}
                  phoneNumber={formFourState.selectedPatient?.phone_no || ''}
                  containerStyle={{
                    marginTop: '10px'
                  }}
                />
              </>
            )}
          </Grid>
          <Grid
            container
            item
            visibility={
              !isEmpty(formFourState.selectedPatient) ? 'visible' : 'hidden'
            }
            direction="column"
            sx={{justifyContent: 'space-between'}}>
            <Typography component="h3" sx={{fontWeight: 'bold'}}>
              {t('CLINIC_PICK_DATE')}
            </Typography>
            <LocalizationProvider dateAdapter={MomentAdapter}>
              <CalendarPicker
                className="appointment-calendar-picker"
                date={formFourState.selectedDate}
                minDate={moment()}
                onChange={newDate => onDateChangeHandler(newDate)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            container
            item
            visibility={
              !isEmpty(formFourState.selectedPatient) ? 'visible' : 'hidden'
            }
            direction="column"
            ml={1}
            sx={{justifyContent: 'space-between'}}>
            <Typography component="h3" sx={{fontWeight: 'bold'}}>
              {t('CLINIC_SLOT')}
            </Typography>
            <Box width={300}>
              {!isEmpty(availableSlots.morning) && (
                <>
                  <Typography>
                    <WbSunnyOutlined /> Morning
                  </Typography>
                  <ChipsArray
                    icon={<AccessTimeSharpIcon />}
                    list={availableSlots.morning}
                    selected={formFourState.selectedTimeSlot}
                    onClick={(val: string) =>
                      setPartialState('selectedTimeSlot', val)
                    }
                  />
                </>
              )}
              {!isEmpty(availableSlots.evening) && (
                <>
                  <Typography>
                    <Brightness4Outlined />
                    Evening
                  </Typography>
                  <ChipsArray
                    icon={<AccessTimeSharpIcon />}
                    list={availableSlots.evening}
                    selected={formFourState.selectedTimeSlot}
                    onClick={(val: string) =>
                      setPartialState('selectedTimeSlot', val)
                    }
                  />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" alignItems="center">
          <Grid
            item
            sx={{justifyContent: 'center', margin: 'auto', width: '320px'}}>
            <Button
              disabled={loading || !isValidForm()}
              type="submit"
              fullWidth
              variant="contained">
              {t('CLINIC_CONTINUE')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default StepFour;
