/* eslint-disable camelcase */
import React from 'react';
import {
  Typography,
  Grid,
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import Icons from '@components/system/MyIcons';
import CommonUtils from 'src/utils/CommonUtils';
import PdfViewerDialog from 'src/modules/shared/PdfViewerComponent/PdfViewerDialog';
import './ShowAttachments.scss';

const ShowAttachments = (props: any): React.ReactElement => {
  const [open, setOpen] = React.useState(props?.open);
  const {attachments, type, isFromTimeline} = props;
  const [showPdfFileDialog, setShowPdfFileDialog] = React.useState(false);
  const [prescription, setPrescription] = React.useState('');

  const handleClose = () => {
    setOpen(false);
    props?.closeShowAttachmentPopUp();
  };

  const handleDeleteAttachment = (id: any) => {
    props?.handleDeleteAttachment(type, id);
  };

  const closeShowPdfFileDialog = () => {
    setShowPdfFileDialog(false);
  };

  const handlePrescription = (url: any) => {
    setPrescription(CommonUtils.getFullImageUrl(url));
    setShowPdfFileDialog(true);
  };

  const renderPdfViewerDialog = (): React.ReactElement => {
    return (
      <PdfViewerDialog
        open={showPdfFileDialog}
        closeShowPdfFileDialog={closeShowPdfFileDialog}
        pdfURL={prescription}
      />
    );
  };

  const renderImagingDialogTitle = (): React.ReactElement => {
    return (
      <Grid container>
        <Grid item xs={11}>
          <Typography className="add-medicine">
            {attachments?.length} Attachments
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="end"
          className="dialog-close">
          <IconButton onClick={handleClose}>
            <Icons.CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const renderShowAttachmentsAdded = () => {
    return (
      <Grid item xs={12}>
        {attachments?.map((item: any, index: any) => {
          return (
            <Grid key={index} item xs={12} className="single-attachment">
              {' '}
              <Typography
                id={'view_file_id_' + index}
                className="single-attachment-name"
                onClick={() =>
                  handlePrescription(
                    isFromTimeline ? item?.pdf : item?.file_path
                  )
                }>
                {isFromTimeline
                  ? item?.pdf?.split('/').reverse()[0]
                  : item?.file_name ||
                    item?.file_path?.split('/').reverse()[0] ||
                    'File Name: '}
              </Typography>
              {!isFromTimeline && (
                <IconButton
                  id="delete_file"
                  onClick={() => handleDeleteAttachment(item?.id)}>
                  <Icons.CloseIcon />
                </IconButton>
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  };
  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="show-attachments-wrapper">
        <DialogTitle id="alert-dialog-title" className="popup-border">
          {renderImagingDialogTitle()}
        </DialogTitle>
        <DialogContent>{renderShowAttachmentsAdded()}</DialogContent>
      </Dialog>
      {showPdfFileDialog && renderPdfViewerDialog()}
    </div>
  );
};

export default ShowAttachments;
