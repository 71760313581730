import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as RadioBoxSelectedIconSvg} from '@assets/images/healpha/RadioBoxSelected.svg';

const RadioBoxSelectedIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={RadioBoxSelectedIconSvg}
      viewBox="0 0 24 24"
    />
  );
};

export default RadioBoxSelectedIcon;
