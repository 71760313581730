/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
  Avatar,
  TextField,
  // Divider,
  Select,
  OutlinedInput,
  MenuItem
  // Menu
} from '@material-ui/core';
import moment from 'moment';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import {LocalizationProvider, StaticDatePicker} from '@material-ui/lab';
import _ from 'lodash';

import {
  getDoctorById,
  getAppointmentTimings,
  getDoctorsByBranch,
  searchPatient,
  getPaymentCriteria,
  sendOtpToUser,
  getPatientById,
  getCorporateDoctor
} from '@shared/services/PublicService';

import CommonUtils from 'src/utils/CommonUtils';
import AuthUtils from 'src/utils/AuthUtils';
import NewPersonRegistrationComponent from '@components/system/Dialogs/NewPersonRegistration/NewPersonRegistrationComponent';
import CustomAutoCompleteField from '@components/system/CustomAutoCompleteField/CustomAutoCompleteField';
import CheckupImage from '@assets/images/healpha/Checkup.png';
import {
  genderOptionsList,
  Relations,
  DoctorSpecializations
} from 'src/constants/FormFields';
import {
  GET_8_PIXEL_BOX,
  DEFAULT_WHITE_COLOR,
  MESSAGE_BORDER_COLOR,
  FONT_OTHER_COLOR,
  APP_PRIMARY_FONT_REGULAR,
  APPOINTMENT_BORDER_COLOR
} from 'src/style/variable';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import Card from '@material-ui/core/Card';
import '@components/system/Dialogs/NewAppointmentBookings/NewAppointmentBookingsComponent.scss';
import BasicLayoutComponent from './CreateAppointmentModal';
import {
  AppointmentTypelist,
  CurrencyTypes,
  AppointmentTypes,
  PatientCategory
} from 'src/constants/SystemConstants';
import './AppointmentBooking.scss';
import OtpScreenComponent from '@components/system/Dialogs/OtpScreen/OtpScreenComponent';
import Icons from '@components/system/MyIcons';
import {environment} from '@shared/environment/environment';
import {getVirtualPatient} from '@shared/services/CommonService';

const textFieldProps = {
  border: `0.0625rem solid ${MESSAGE_BORDER_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.75),
  background: `${DEFAULT_WHITE_COLOR} 0% 0% no-repeat padding-box`
};

const dropDownProps = {
  height: '2.85rem',
  width: '100%',
  border: `0.0625rem solid ${APPOINTMENT_BORDER_COLOR}`,
  background: `${DEFAULT_WHITE_COLOR} 0% 0% no-repeat padding-box`
};

const inputProps = {
  font: `normal normal 400 1.1rem/1.5rem ${APP_PRIMARY_FONT_REGULAR}`,
  color: FONT_OTHER_COLOR,
  paddingTop: '0.5rem'
};

const NewAppointmentBookingsComponent = (props: any): React.ReactElement => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState<boolean>(props?.open);
  const [patientSelected, setPatientSelected] = React.useState<boolean>(false);
  const [corporateFlag, setCorporateFlag] = React.useState<any>('');
  const [doctorSelected, setDoctorSelected] = React.useState<boolean>(false);
  const [branchSelected, setBranchSelected] = React.useState<boolean>(false);
  const [isCorporate, setIsCorporate] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>(props?.personName);
  const [doctorSearchText, setDoctorSearchText] = React.useState<string>('');
  const [searchFieldOptions, setSearchFieldOptions]: any = React.useState([]);
  const [doctorSearchFieldOptions, setDoctorSearchFieldOptions]: any =
    React.useState([]);
  const [calendarValue, setCalendarValue]: any = React.useState(moment());
  const [selectedBranch, setSelectedBranch]: any = React.useState();
  const [selectedPatientDetails, setSelectedPatientDetails]: any =
    React.useState({});
  const [selectedDoctorDetails, setSelectedDoctorDetails]: any = React.useState(
    {}
  );
  const [selectedAppointmentTypes, setSelectedAppointmentTypes]: any =
    React.useState([]);
  const [selectedDate, setSelectedDate]: any = React.useState();
  const [slotTimings, setSlotTimings]: any = React.useState([]);
  const [slotdif, setslotdif] = React.useState<any>(0);
  const [encounters, setEncounters]: any = React.useState([]);
  const [selectedDoctorId, setSelectedDoctorId]: any = React.useState([]);
  const [selectedSlotButton, setSelectedSlotButton] =
    React.useState<string>('');
  const [slotTime, setSlotTime] = React.useState<string>('');
  const [showPersonSignUpPopup, setShowPersonSignUpPopup] =
    React.useState<boolean>(false);
  const [appointmentObject, setAppointmentObject]: any = React.useState({});
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [openOtpPopup, setOpenOtpPopup] = React.useState(false);
  const patientId = 1;
  const doctorId = 2;
  const branchId = props?.branchId || AuthUtils.getMenuBranchId();
  const [values, setValues] = React.useState<any>({
    appointmentType: '',
    compliant: '',
    encounter_id: ''
  });

  const [slotsDetails, setSlotsDetails]: any = React.useState([
    {
      label: 'MORNING_SLOTS_AVAILABLE',
      label1: 'MORNING',
      key: 'morning',
      itemsToShow: 12,
      expanded: false
    },
    {
      label: 'AFTERNOON_SLOTS_AVAILABLE',
      label1: 'AFTERNOON',
      key: 'afternoon',
      itemsToShow: 12,
      expanded: false
    },
    {
      label: 'EVENING_SLOTS_AVAILABLE',
      label1: 'EVENING',
      key: 'evening',
      itemsToShow: 12,
      expanded: false
    }
  ]);

  const closePopup = (): void => {
    setOpen(false);

    if (
      props?.closeNewBookingsPopup &&
      typeof props.closeNewBookingsPopup === 'function'
    ) {
      props.closeNewBookingsPopup();
    }
  };

  const handleSlotTimeButtonClick = (timeObject: any) => {
    setSelectedSlotButton(timeObject?.value);
    setSlotTime(timeObject?.label);
  };

  const getAppointmentSlots = async (
    date: any,
    doctor_branch_id: any,
    nonHealphaUserId: any
  ) => {
    const dateFormat = moment(date).format('YYYY-MM-DD');
    // props?.selectedDate
    setSelectedDate(dateFormat);
    getAppointmentTimings(
      nonHealphaUserId || selectedDoctorId,
      doctor_branch_id,
      dateFormat
    )
      .then(res => {
        const timings: any = res?.data?.free_slots;
        if (props?.selectedDate && props?.hasTimeSlot) {
          const format = 'hh:mm:ss';
          Object.keys(timings).map((slot: any) => {
            timings[slot] = timings[slot]?.filter((i: any) => {
              const time = moment(props?.selectedDate)
                .set('hour', i.value.slice(0, 2))
                .set('minute', i.value.slice(3, 5));
              const beforeTime = moment(props?.selectedDate, format),
                afterTime = moment(props?.endDate, format);
              return time.isSameOrAfter(beforeTime) && time.isBefore(afterTime);
            });
          });
        }
        setSlotTimings(timings);
        setslotdif(res?.data?.slot_timing);
        props?.selectedDate &&
          props?.hasTimeSlot &&
          handleSlotTimeButtonClick(
            timings?.[Object.keys(timings)?.[0]]?.[0] || {}
          );
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const getEncounter = () => {
    getPaymentCriteria(
      selectedPatientDetails?.hlpid || props?.healphaId,
      branchId,
      selectedDoctorId || props?.docId
    )
      .then(res => {
        setEncounters(res?.data?.criteria?.visit_ids || []);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleAppointmentClick = (item: any) => {
    setAppointmentObject(item);
    item.appointment_type_name === AppointmentTypelist.review && getEncounter();
  };

  const openRightSection = (
    item: any,
    appointmentTypes: any,
    nonHealphaUserId: any
  ): void => {
    setBranchSelected(true);
    setSelectedBranch(item);
    props?.appointmentType &&
      handleAppointmentClick(
        appointmentTypes.find((i: any) => {
          setValues({...values, appointmentType: i.appointment_type_value});
          return (
            i.appointment_type_value.toLowerCase() ===
            props?.appointmentType.toLowerCase()
          );
        }) || {}
      );
    setSelectedAppointmentTypes(appointmentTypes);
    getAppointmentSlots(
      selectedDate || props?.selectedDate || moment().format('YYYY-MM-DD'),
      item?.branch_id || branchId,
      nonHealphaUserId
    );
  };

  const getDoctorDetails = (nonHealphaUserId: string): void => {
    getDoctorById(
      nonHealphaUserId,
      '',
      props?.isFromVirtualClinic ? branchId : '',
      isCorporate ? PatientCategory.CORPORATE : null
    )
      .then(response => {
        const doctor = response?.data;
        setSelectedDoctorDetails(doctor);
        doctor.branches = response?.data?.branches.filter(
          (item: any) => item.branch
        );
        doctor.appointment_types = CommonUtils.makeAppointmentTypes(
          doctor?.price_list
        );
        const branch =
          doctor.branches.find((i: any) => i.branch_id === branchId) || {};
        const appointmentTypes = CommonUtils.formAppointmentTypes(
          branchId,
          doctor,
          true
        );
        appointmentTypes.sort(function (a: any, b: any) {
          return a.price - b.price;
        });
        // setDoctorData(doctor);
        openRightSection(branch, appointmentTypes, nonHealphaUserId);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const formatElement = (option: any): string => {
    let element = `${option?.hlpid} | ${CommonUtils.getFullName(option)}`;

    if (option?.is_clinic_associated || option?.is_branch_associated) {
      element = `${element}, ${option?.age} ${option?.gender} | ${option?.dob} | ${option?.phone_no} `;

      if (option?.father_name) {
        element = `${element} | ${option?.father_name}`;
      }
    }
    return element;
  };

  const onChange = (newValue: any, id: any) => {
    if (id == patientId) {
      setPatientSelected(true);
      setSearchText(newValue);
      setSelectedPatientDetails(newValue);
      if (AuthUtils.getIsOperationalBranch() == 'true') {
        getCorporateDoctor(
          newValue?.hlpid_full
            ? newValue?.hlpid_full?.charAt(newValue?.hlpid_full?.length - 1) !=
              '*'
              ? newValue?.hlpid_full
              : newValue?.hlpid
            : newValue?.hlpid,
          '',
          AuthUtils.getMenuBranchId()
        )
          .then((person_res: any) => {
            setCorporateFlag('');
          })
          .catch(err => {
            setCorporateFlag(
              err?.message ? t(err.message) : t('ERROR_ENCOUNTERED')
            );
          });
      }
      getPatientById(newValue?.hlpid || props?.healphaId, branchId).then(
        (person_res: any) => {
          setIsCorporate(person_res?.data?.tpa_corporate_type);
        }
      );
    } else if (id == doctorId) {
      setDoctorSelected(true);
      setDoctorSearchText(newValue);
      setSelectedDoctorId(newValue?.non_healpha_user_id);
      getDoctorDetails(newValue?.non_healpha_user_id);
    }
  };

  const getPatients = (text: string): void => {
    setSearchFieldOptions([]);
    setPatientSelected(false);

    props?.isFromVirtualClinic && props?.reschedule
      ? getVirtualPatient(branchId, text)
          .then(res => {
            const response = res?.data?.patients;
            if (response?.length > 0) {
              const formattedOptions = response.map((element: any) => ({
                ...element,
                formattedDetails: formatElement(element)
              }));
              setSearchFieldOptions(formattedOptions);
              if (props?.healphaId) {
                onChange(response[0], patientId);
              }
            }
          })
          .catch(err => {
            enqueueSnackbar(
              err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          })
      : searchPatient(text, branchId)
          .then(res => {
            const response = res?.data?.patients;
            if (response?.length > 0) {
              const formattedOptions = response.map((element: any) => ({
                ...element,
                formattedDetails: formatElement(element)
              }));
              setSearchFieldOptions(formattedOptions);
              if (props?.healphaId) {
                onChange(response[0], patientId);
              }
            }
          })
          .catch(err => {
            enqueueSnackbar(
              err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          });
  };

  const getDoctors = (text: string): void => {
    setDoctorSearchFieldOptions([]);
    if (props?.docId) {
      onChange({non_healpha_user_id: props?.docId}, doctorId);
    }
    AuthUtils.getIsOperationalBranch() != 'true'
      ? !props?.docId &&
        getDoctorsByBranch(branchId, text)
          .then(res => {
            if (res?.data?.doctors?.length > 0) {
              const formattedOptions = res.data.doctors.map((element: any) => ({
                ...element,
                formattedDetails: CommonUtils.getFullName(element)
              }));

              setDoctorSearchFieldOptions(formattedOptions);
            }
          })
          .catch(err => {
            enqueueSnackbar(
              err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          })
      : getCorporateDoctor(
          selectedPatientDetails?.hlpid_full
            ? selectedPatientDetails?.hlpid_full?.charAt(
                selectedPatientDetails?.hlpid_full?.length - 1
              ) != '*'
              ? selectedPatientDetails?.hlpid_full
              : selectedPatientDetails?.hlpid
            : selectedPatientDetails?.hlpid,
          text,
          AuthUtils.getMenuBranchId()
        )
          .then((person_res: any) => {
            const formattedOptions = person_res.data.doctors.map(
              (element: any) => ({
                ...element,
                formattedDetails: CommonUtils.getFullName(element)
              })
            );

            setDoctorSearchFieldOptions(formattedOptions);
          })
          .catch(err => {
            enqueueSnackbar(
              err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
              SnackBarConfig('e')
            );
          });
  };

  const init = () => {
    if (props?.healphaId) {
      getPatients(props.healphaId);
      props?.setHlpId && props?.setHlpId('');
    }
    if (props?.docId) {
      getDoctors(props?.docId);
    }
    if (props?.selectedDate) {
      setCalendarValue(props?.selectedDate);
      setSelectedDate(props?.selectedDate);
    }
    if (props?.enc_id) {
      setValues({...values, encounter_id: props?.enc_id});
    }
  };

  React.useEffect(init, []);

  // search input field on text change callback
  const onInputChange = (text: string, id: any) => {
    if (id == doctorId) {
      setDoctorSearchText(text);
    }
  };

  const apiCallback = (text: string, id: any) => {
    if (id == patientId) {
      setSearchText(text);
    } else if (id == doctorId) {
      setDoctorSearchText(text);
    }

    if (text?.length > 2) {
      if (id == patientId) {
        getPatients(text);
      } else if (id == doctorId) {
        getDoctors(text);
      }
    }
  };

  const closePatientCard = (): void => {
    setPatientSelected(false);
    setDoctorSelected(false);
    setSearchFieldOptions([]);
    setSearchText('');
    setDoctorSearchText('');
    setPatientSelected(false);
  };

  const closeDoctorCard = (): void => {
    setDoctorSelected(false);
    setDoctorSearchFieldOptions([]);
    setDoctorSearchText('');
    setBranchSelected(false);
  };

  const handleChange = (name: any) => (event: any) => {
    setValues({...values, [name]: event.target.value});
  };

  const showMore = (index: number, length: any): any => {
    const data = [...slotsDetails];
    data[index].expanded = data?.[index]?.itemsToShow === 12 ? true : false;
    data[index].itemsToShow = data?.[index]?.itemsToShow === 12 ? length : 12;
    setSlotsDetails(data);
  };

  const closeOtpPopup = (): void => {
    setOpenOtpPopup(false);
  };

  const openOtpScreen = (): void => {
    const data = {
      phone_code: selectedPatientDetails?.phone_code || environment.dialCode,
      phone_number:
        selectedPatientDetails?.phone_no_full ||
        selectedPatientDetails?.phone_no
    };

    sendOtpToUser(data)
      .then(res => {
        setOpenOtpPopup(true);
        setOpen(false);
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handleSubmit = (): void => {
    if (
      slotTime &&
      moment(calendarValue).date() === moment().date() &&
      moment(slotTime, 'HH:mm a').isSameOrBefore(moment())
    ) {
      enqueueSnackbar(t('PAST_TIME_SLOT_WARNING'), SnackBarConfig('w'));
    } else if (values?.appointmentType && slotTime) {
      setModalVisible(true);
      setOpen(false);
    } else {
      enqueueSnackbar(t('APPOINTMENT_WARNING_TEXT'), SnackBarConfig('w'));
    }
  };

  const closePersonSignUpPopup = (): void => {
    setShowPersonSignUpPopup(false);
  };

  const openPersonRegPopup = (): void => {
    setShowPersonSignUpPopup(true);
  };

  const getRelation = (): any => {
    if (
      selectedPatientDetails?.gender === genderOptionsList?.[0]?.value ||
      selectedPatientDetails?.gender === genderOptionsList?.[0]?.key
    ) {
      return Relations.SON;
    }

    if (
      selectedPatientDetails?.gender === genderOptionsList?.[1]?.value ||
      selectedPatientDetails?.gender === genderOptionsList?.[1]?.key
    ) {
      return Relations.DAUGHTER;
    }
  };

  const renderCloseIcon = (): React.ReactElement => {
    return (
      <IconButton
        id="close_book_appointmnet_popup"
        onClick={closePopup}
        className="close-icon">
        <Icons.CloseBlackIcon />
      </IconButton>
    );
  };

  const renderTitle = (): React.ReactElement => {
    return (
      <Typography className="popup-title">
        {props.reschedule
          ? 'Reschedule Appointment'
          : AuthUtils.getIsOperationalBranch() == 'true'
          ? 'New Corporate Appointment'
          : 'New Appointment'}
      </Typography>
    );
  };

  const renderSearchField = (
    options: any,
    field: any,
    id: any,
    placeholderText: string,
    noOptionsText: string,
    loading: boolean,
    disabled: boolean,
    fieldSearchText: any,
    className?: string
  ): React.ReactElement => {
    return (
      <Grid>
        <CustomAutoCompleteField
          options={options}
          field={field}
          id={id}
          disabled={disabled}
          required={false}
          placeHolder={placeholderText}
          onChange={onChange}
          onInputChange={onInputChange}
          apiCallback={apiCallback}
          defaultValue={fieldSearchText || null}
          loading={loading}
          debounce={true}
          noOptionsText={noOptionsText}
          customTextFieldProps={textFieldProps}
          size="small"
          className={className}
        />
      </Grid>
    );
  };

  const renderImage = (): React.ReactElement => {
    return (
      <Grid className="image-view">
        {/* classes={{paper: 'appointment-detail-wrapper'}} */}
        <img src={CheckupImage} alt="check up image" />
      </Grid>
    );
  };

  const renderKeyValueObject = (
    value: string,
    Icon?: any
  ): React.ReactElement => {
    if (!value) return <Grid />;
    return (
      <Grid item container alignItems="center" style={{paddingTop: '0.75rem'}}>
        <div className="d-flex">
          {Icon && (
            <Grid item>
              <Icon style={{width: 15, height: 15}} />
            </Grid>
          )}
          <Grid item className="card-value">
            {value}
          </Grid>
        </div>
      </Grid>
    );
  };

  const renderPatientCard = (): React.ReactElement => {
    return (
      <Card className="patient-card">
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Avatar
              alt=""
              className="vc-patient-icon"
              src={CommonUtils.getFullImageUrl(
                selectedPatientDetails?.person_image
              )}
            />
          </Grid>

          <Grid item xs={9} container className="card-displywh">
            <Grid item xs={12} className="d-flex">
              <Typography className="patient-name mt-2 ml-2">
                {`${
                  selectedPatientDetails?.salutation
                } ${CommonUtils.getFullName(selectedPatientDetails)}`}
              </Typography>
            </Grid>
            {selectedPatientDetails?.father_name &&
              selectedPatientDetails?.gender && (
                <Typography className="father-name">
                  {`${getRelation()} ${selectedPatientDetails?.father_name}`}
                </Typography>
              )}

            {(selectedPatientDetails?.gender || selectedPatientDetails?.age) &&
              renderKeyValueObject(
                `${selectedPatientDetails?.gender || ''},${
                  selectedPatientDetails?.age || ''
                }`,
                Icons.GenderDarkIcon
              )}

            {selectedPatientDetails?.email &&
              renderKeyValueObject(
                selectedPatientDetails.email,
                Icons.EmailIcon
              )}
            {selectedPatientDetails?.phone_no &&
              renderKeyValueObject(
                selectedPatientDetails?.phone_no,
                Icons.CallDarkIcon
              )}
            {renderKeyValueObject(
              selectedPatientDetails?.hlpid,
              Icons.PatientIcon
            )}
          </Grid>

          {!props?.reschedule && (
            <Grid
              item
              xs={1}
              container
              justifyContent="flex-end"
              style={{cursor: 'pointer'}}>
              <Icons.CloseBlackIcon
                onClick={closePatientCard}
                id="close_patient_card"
              />
            </Grid>
          )}
        </Grid>
      </Card>
    );
  };

  const renderDoctorCard = (): React.ReactElement => {
    const specializationList = selectedDoctorDetails?.branches?.map(
      (item: any) => {
        return DoctorSpecializations?.[item?.specialization];
      }
    );
    const uniqueSpecialization = _.uniqBy(specializationList, (e: any) => {
      return e;
    });
    const specialization = uniqueSpecialization.join();
    const educationList = selectedDoctorDetails?.education?.map((item: any) => {
      return item?.edu_college;
    });
    const uniqueEducation = _.uniqBy(educationList, (e: any) => {
      return e;
    });
    const education = uniqueEducation.join();
    if (!selectedDoctorDetails?.branches) return <Grid />;
    return (
      <Grid className="patient-card-container">
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Avatar
              alt=""
              className="vc-patient-icon"
              src={CommonUtils.getFullImageUrl(
                selectedDoctorDetails?.profile_image
              )}
            />
          </Grid>

          <Grid item xs={8} container>
            <Grid item xs={12}>
              <Typography className="patient-name">
                {`${
                  selectedDoctorDetails?.salutation
                } ${CommonUtils.getFullName(selectedDoctorDetails)}`}
              </Typography>
            </Grid>
            {renderKeyValueObject(specialization)}
            {renderKeyValueObject(education)}
          </Grid>

          {!props?.reschedule && !props?.docId && (
            <Grid
              item
              xs={2}
              container
              justifyContent="flex-end"
              style={{cursor: 'pointer'}}>
              <Icons.CloseBlackIcon onClick={closeDoctorCard} />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };
  const renderDoctorPatientInfo = (): React.ReactElement => {
    return (
      <Grid className="doctor-patient-container">
        {!props?.isFromPersonWeb && (
          <Typography className="doctor-patient-title">
            {patientSelected ? 'Patient' : 'Select Patient'}
          </Typography>
        )}
        {patientSelected &&
        props?.is_clinical_dashboard &&
        !props?.isFromPersonWeb
          ? renderPatientCard()
          : !props?.isFromPersonWeb &&
            renderSearchField(
              searchFieldOptions,
              'formattedDetails',
              patientId,
              'Search Patient',
              '',
              false,
              false,
              searchText,
              'new-patient-appointment-search-option options-list-appointment-booking'
            )}

        {searchFieldOptions?.length === 0 && searchText?.length > 2 ? (
          AuthUtils.getIsOperationalBranch() != 'true' ? (
            <Typography className="patient-info-text">
              No user found. Do you want to add this user as new patient?
              <span className="patient-link-text" onClick={openPersonRegPopup}>
                Add new patient
              </span>
            </Typography>
          ) : (
            <Typography className="patient-info-text">
              No user found.{' '}
            </Typography>
          )
        ) : (
          patientSelected &&
          !props?.reschedule &&
          props?.is_clinical_dashboard &&
          !selectedPatientDetails?.is_branch_associated &&
          !props?.isFromPersonWeb &&
          (AuthUtils.getIsOperationalBranch() != 'true' ? (
            <Typography className="patient-info-text">
              User found in healpha database. Do you want to add this user to
              the clinic?
              <span className="patient-link-text" onClick={openOtpScreen}>
                Add
              </span>
            </Typography>
          ) : (
            <Typography className="patient-info-text">
              {corporateFlag ? (
                <Typography className="patient-info-text">
                  {corporateFlag}
                </Typography>
              ) : (
                <Typography className="patient-info-text">
                  User found in healpha database. Do you want to add this user
                  to the Corporate?
                  <span className="patient-link-text" onClick={openOtpScreen}>
                    Add
                  </span>
                </Typography>
              )}
            </Typography>
          ))
        )}

        {patientSelected &&
          (selectedPatientDetails?.is_branch_associated ||
            props?.isFromPersonWeb) && (
            <Grid>
              <Typography className="doctor-patient-title">
                {doctorSelected
                  ? 'Doctor'
                  : AuthUtils.getIsOperationalBranch() == 'true'
                  ? corporateFlag?.length == 0
                    ? 'Select Doctor'
                    : ''
                  : 'Select Doctor'}
              </Typography>

              {doctorSelected ? (
                renderDoctorCard()
              ) : AuthUtils.getIsOperationalBranch() == 'true' ? (
                corporateFlag?.length != 0 ? (
                  <Typography className="patient-info-text">
                    {corporateFlag}{' '}
                  </Typography>
                ) : (
                  renderSearchField(
                    doctorSearchFieldOptions,
                    'formattedDetails',
                    doctorId,
                    'Search Doctor',
                    'Doctor not found',
                    false,
                    !patientSelected,
                    doctorSearchText
                  )
                )
              ) : (
                renderSearchField(
                  doctorSearchFieldOptions,
                  'formattedDetails',
                  doctorId,
                  'Search Doctor',
                  'Doctor not found',
                  false,
                  !patientSelected,
                  doctorSearchText
                )
              )}
            </Grid>
          )}
      </Grid>
    );
  };

  const renderNormalDropDown = (): React.ReactElement => {
    return (
      <div>
        <Typography>
          <Icons.StethoscopeIconblack />
          <b>{t('CLINIC_APPOINTMENT_TYPE')}</b>
        </Typography>
        <Select
          sx={dropDownProps}
          name="appointmentType"
          disabled={
            (props?.reschedule &&
              props?.appointmentTypeStatus === AppointmentTypes.online) ||
            (props?.reschedule && props?.isFromPersonWeb)
          }
          displayEmpty
          value={values?.appointmentType}
          defaultValue={props?.appointmentType ? props?.appointmentType : ''}
          input={<OutlinedInput style={inputProps} />}
          inputProps={inputProps}
          onChange={handleChange('appointmentType')}>
          <MenuItem disabled value="">
            <em>{t('SELECT_DOCTOR_APPOINTMENT_TYPE')}</em>
          </MenuItem>
          {selectedAppointmentTypes?.map((item: any, index: number) => (
            <MenuItem
              key={index}
              value={item?.appointment_type_value}
              onClick={() => handleAppointmentClick(item)}>
              {`${item?.appointment_type_name} -${CurrencyTypes?.RUPEE?.label}.${item?.price}`}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  const encounterDropdown = (): React.ReactElement => {
    return (
      <div className="pt-3">
        <Typography>
          <b>{t('SELECT_ENCOUNTER_ID')}</b>
        </Typography>
        <Select
          sx={dropDownProps}
          name="encounter_id"
          displayEmpty
          value={values?.encounter_id}
          input={<OutlinedInput style={inputProps} />}
          inputProps={inputProps}
          onChange={handleChange('encounter_id')}>
          <MenuItem disabled value="">
            <em>{t('SELECT_ENCOUNTER_ID')}</em>
          </MenuItem>
          {encounters?.map((item: any, index: number) => (
            <MenuItem key={index} value={item?.id}>
              {item.id}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  const renderDateContainer = (): React.ReactElement => {
    return (
      <Grid className="date-appointment-container">
        {renderNormalDropDown()}
        {appointmentObject.appointment_type_name ===
          AppointmentTypelist.review && encounterDropdown()}

        <Grid className="date-picker-container border-none">
          <Typography className="date-picker-label">
            {t('CLINIC_PICK_DATE')}
          </Typography>
          <LocalizationProvider dateAdapter={MomentAdapter}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              openTo="day"
              views={['day']}
              defaultCalendarMonth={moment()}
              value={calendarValue}
              maxDate={moment().add(30, 'days')}
              minDate={moment()}
              onChange={newValue => {
                setCalendarValue(newValue);
                getAppointmentSlots(
                  newValue,
                  selectedBranch?.branch_id,
                  selectedDoctorId
                );
              }}
              renderInput={params => <TextField {...params} />}
              inputFormat="ddd-MM-YYYY"
              toolbarFormat="ddd"
            />
          </LocalizationProvider>

          {branchSelected &&
            patientSelected &&
            (selectedPatientDetails?.is_branch_associated ||
              props?.isFromPersonWeb) && (
              <Grid container>
                <Grid item xs={12} md={12} />
                <Grid item xs={12} md={12}>
                  <Button
                    variant="outlined"
                    id="appointment_continue_button"
                    className={`continue-button ${
                      (!values?.appointmentType ||
                        !slotTime ||
                        (appointmentObject.appointment_type_name ===
                          AppointmentTypelist.review &&
                          !values?.encounter_id)) &&
                      'disabled-button'
                    }`}
                    disabled={
                      !values?.appointmentType ||
                      !slotTime ||
                      (appointmentObject.appointment_type_name ===
                        AppointmentTypelist.review &&
                        !values?.encounter_id)
                    }
                    onClick={handleSubmit}>
                    Continue
                  </Button>
                </Grid>
                <Grid item xs={12} md={4} />
              </Grid>
            )}
        </Grid>
      </Grid>
    );
  };

  const renderSlots = (): React.ReactElement => {
    return slotsDetails.map((item: any, index: any) => {
      return (
        <Grid key={index} className="sessions-container">
          <Grid container spacing={1}>
            <Grid item>
              {item?.key === 'morning' && <Icons.MorningIcon />}
              {item?.key === 'afternoon' && <Icons.AfternoonIcon />}
              {item?.key === 'evening' && <Icons.EveningIcon />}
            </Grid>

            <Grid item>
              <Typography className="session-name-text">
                {slotTimings?.[item?.key] && slotTimings?.[item.key]?.length > 0
                  ? t(item?.label, {
                      count: slotTimings?.[item.key]?.length
                    })
                  : t(item?.label1)}
              </Typography>

              {(!slotTimings?.[item?.key] ||
                (slotTimings?.[item?.key] &&
                  slotTimings?.[item?.key]?.length === 0)) && (
                <Typography className="not-available-text">
                  {t('NO_SLOTS_AVAILABLE')}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{paddingTop: '1rem'}}>
            {slotTimings?.[item?.key] &&
              slotTimings?.[item?.key]?.length > 0 &&
              slotTimings?.[item?.key]
                .slice(0, item?.itemsToShow)
                .map((time: any, index1: any) => {
                  return (
                    <Grid key={index1} item xs={3}>
                      <Button
                        variant="outlined"
                        id={'slot_time' + time?.label}
                        size="small"
                        className={[
                          'slot-button',
                          selectedSlotButton === time?.value && 'border-button'
                        ].join(' ')}
                        onClick={() => handleSlotTimeButtonClick(time)}
                        startIcon={<Icons.TimeIcon />}>
                        {time?.label}
                      </Button>
                    </Grid>
                  );
                })}
          </Grid>
          {slotTimings?.[item.key]?.length > 12 && (
            <a
              id="slot_view_button"
              onClick={() => showMore(index, slotTimings?.[item?.key]?.length)}>
              {item?.expanded ? (
                <Typography className="expanded-button">
                  {t('VIEW_LESS')}
                </Typography>
              ) : (
                <Typography className="expanded-button">
                  {t('VIEW_MORE')}
                </Typography>
              )}
            </a>
          )}
        </Grid>
      );
    });
  };

  const renderSlotsContainer = (): React.ReactElement => {
    return (
      <Grid className="slots-container">
        <Typography className="slots-text">{t('CLINIC_SLOT')}</Typography>
        {/* <Divider className="divider" /> */}

        {renderSlots()}
      </Grid>
    );
  };

  const renderContent = (): React.ReactElement => {
    return (
      <Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                {renderDoctorPatientInfo()}
              </Grid>
              {(!branchSelected ||
                !patientSelected ||
                !selectedPatientDetails?.is_branch_associated) &&
                !props?.isFromPersonWeb && (
                  <Grid item xs={12} md={6}>
                    {renderImage()}
                  </Grid>
                )}
              {/* <Grid item xs={8}>
          <Grid container> */}
              {branchSelected &&
                patientSelected &&
                (selectedPatientDetails?.is_branch_associated ||
                  props?.isFromPersonWeb) && (
                  <Grid item xs={12} md={6}>
                    {renderDateContainer()}
                  </Grid>
                )}
            </Grid>
          </Grid>
          {branchSelected &&
            patientSelected &&
            (selectedPatientDetails?.is_branch_associated ||
              props?.isFromPersonWeb) && (
              <Grid item xs={12} md={5}>
                {renderSlotsContainer()}
              </Grid>
            )}
          {/* </Grid>
        </Grid> */}
        </Grid>
      </Grid>
    );
  };

  const renderDate = () => {
    if (!slotTime || !selectedDate) return '';
    const hours = +slotTime?.slice(0, 2);
    const mins = slotTime.slice(3, 5);
    const time = slotTime.includes('PM')
      ? hours === 12
        ? hours
        : 12 + hours
      : hours === 12
      ? 0
      : hours;
    const startDate = `${selectedDate} ${time}:${mins}`;
    return startDate;
  };

  const renderendDate = () => {
    // const endDate = moment(slotTime).add(slotdif, 'minutes').format('hh:mm A');
    if (!slotTime || !selectedDate) return '';
    const endDate = moment(selectedDate + ' ' + slotTime).toLocaleString();
    const returnendDate = moment(endDate)
      .add(slotdif, 'minutes')
      .format('HH:mm');
    return returnendDate;
  };

  const renderPopUp = (): React.ReactElement => {
    return (
      <BasicLayoutComponent
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        branch_id={branchId}
        selectedAppointmentTypes={selectedAppointmentTypes}
        isFromVirtualClinic={props?.isFromVirtualClinic}
        cellData={{startDate: renderDate(), endDate: renderendDate()}}
        appointmentType={appointmentObject}
        encounter_id={values.encounter_id}
        selectedPatient={selectedPatientDetails}
        doctor_id={selectedDoctorId}
        reschedule={props?.reschedule}
        isOnline={props?.appointmentTypeStatus === AppointmentTypes.online}
        appointment_id={props?.appointment_id}
        init={closePopup}
        onCancel={() => {
          setOpen(true);
          setModalVisible(false);
        }}
      />
    );
  };

  const renderPersonRegPopup = (): React.ReactElement => {
    return (
      <NewPersonRegistrationComponent
        branchId={branchId}
        open={showPersonSignUpPopup}
        closePersonSignUpPopup={closePersonSignUpPopup}
        is_virtual_clinic={false}
        is_clinical_dashboard={true}
      />
    );
  };

  const renderOtpPopup = (): React.ReactElement => {
    return (
      <OtpScreenComponent
        open={openOtpPopup}
        {...props}
        values={{
          phone_code: selectedPatientDetails?.phone_code,
          phone_number: selectedPatientDetails?.phone_no,
          phone_no_full: selectedPatientDetails?.phone_no_full,
          first_name: selectedPatientDetails?.first_name,
          last_name: selectedPatientDetails?.last_name,
          hlpid:
            selectedPatientDetails?.hlpid_full || selectedPatientDetails?.hlpid,
          branchId: branchId
        }}
        closeOtpPopup={closeOtpPopup}
        closePopup={closePopup}
        isFromClinicalBookings={true}
        isFromRelation={true}
      />
    );
  };

  return (
    <Grid className="new-appointment-bookings-wrapper appointment-detail-wrapper">
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        aria-labelledby="new-appointment-bookings-pop-up"
        aria-describedby="new-appointment-bookings"
        classes={{
          paper: 'new-appointment-bookings-popup'
        }}>
        <DialogContent className="new-appointment-bookings-content appointment-detail-wrapper">
          {renderTitle()}
          {renderCloseIcon()}
          {renderContent()}
        </DialogContent>
      </Dialog>
      {showPersonSignUpPopup && renderPersonRegPopup()}
      {openOtpPopup && renderOtpPopup()}
      {modalVisible && renderPopUp()}
    </Grid>
  );
};

export default NewAppointmentBookingsComponent;
