/* eslint-disable camelcase */
import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
  Popover,
  Grid,
  Typography
} from '@material-ui/core';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import {
  LocalizationProvider,
  StaticDatePicker,
  MobileDatePicker
} from '@material-ui/lab';
import {isMobile} from 'react-device-detect';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import {getAppointmentTimings} from '@shared/services/PublicService';

import RegisterUserComponent from '@components/system/Dialogs/RegisterUser/RegisterUserComponent';
import {CommunicationService} from 'src/service/CommunicationService';
import {AVAILABLE_LOCATION_BOOK_APPOINTMENT} from 'src/constants/CommunicationServiceConstants';
import MorningIcon from '@components/system/MyIcons/MorningIcon';
import AfternoonIcon from '@components/system/MyIcons/AfternoonIcon';
import EveningIcon from '@components/system/MyIcons/EveningIcon';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import DropDownIcon from '@components/system/MyIcons/DropDownIcon';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import {
  BORDER_TERTIARY_COLOR,
  GET_8_PIXEL_BOX,
  FONT_PRIMARY_COLOR,
  APP_PRIMARY_FONT_REGULAR,
  DEFAULT_WHITE_COLOR
} from 'src/style/variable';
import './AppointmentComponent.scss';
import CommonUtils from 'src/utils/CommonUtils';

const MenuProps = {
  PaperProps: {
    style: {}
  }
};

const dropDownProps = {
  height: '3.5rem',
  width: '100%',
  border: `1px solid ${BORDER_TERTIARY_COLOR}`
};

const inputProps = {
  font: `normal normal 600 1rem/1.4rem ${APP_PRIMARY_FONT_REGULAR}`,
  letterSpacing: 0,
  color: FONT_PRIMARY_COLOR,
  opacity: 1
};

const textFieldProps = {
  backgroundColor: DEFAULT_WHITE_COLOR,
  border: `1px solid ${BORDER_TERTIARY_COLOR}`,
  borderRadius: GET_8_PIXEL_BOX(0.5),
  opacity: 1,
  fontSize: '1.2rem'
};

const AppointmentComponent = (props: any): ReactElement => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [calendarValue, setCalendarValue]: any = useState(moment());
  const [appointment, setAppointment] = useState<string>('');
  const [appointmentObject, setAppointmentObject]: any = useState({});
  const [compliant, setCompliant] = useState<string | undefined>('');
  const [anchorElClinicPopover, setAnchorElClinicPopover] = useState(null);
  const [slotTimings, setSlotTimings]: any = useState([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [branches, setBranches]: any = useState();
  const [selectedBranch, setSelectedBranch]: any = useState({});
  const [appointmentTypes, setAppointmentTypes]: any = useState([]);
  const subscription: any = useRef(null);
  const [currentDate, setCurrentDate]: any = useState();
  const [selectedButton, setSelectedButton] = useState<string>('');
  const [slotTime, setSlotTime] = useState<string>('');
  const [clickedBookingAppointment, setClickedBookingAppointment] =
    useState<boolean>(false);
  const myRef: any = React.useRef(null);
  const [showCalender, setShowCalender] = useState<boolean>(false);
  const [slotsDetails, setSlotDetails]: any = useState([
    {
      label: 'MORNING_SLOTS_AVAILABLE',
      label1: 'MORNING',
      key: 'morning',
      itemsToShow: 12,
      expanded: false
    },
    {
      label: 'AFTERNOON_SLOTS_AVAILABLE',
      label1: 'AFTERNOON',
      key: 'afternoon',
      itemsToShow: 12,
      expanded: false
    },
    {
      label: 'EVENING_SLOTS_AVAILABLE',
      label1: 'EVENING',
      key: 'evening',
      itemsToShow: 12,
      expanded: false
    }
  ]);

  const handleChange = (event: any) => {
    setAppointment(event?.target?.value);
  };

  const handleTextChange = (event: any) => {
    setCompliant(event?.target?.value);
  };

  const getAppointmentSlots = async (date: any, branch_id: any) => {
    try {
      const dateFormat = moment(date).format('YYYY-MM-DD');
      setCurrentDate(dateFormat);
      const branchId = branch_id;
      const doctorId = props?.doctor?.non_healpha_user_id;
      const res = await getAppointmentTimings(doctorId, branchId, dateFormat);
      setSlotTimings(res?.data?.free_slots);
    } catch (err) {
      enqueueSnackbar(
        (err as any)?.message || t('ERROR_ENCOUNTERED'),
        SnackBarConfig('e')
      );
    }
  };

  const setCurrentBranch = (branch: any) => {
    if (branch) {
      setSelectedBranch(branch);
      const doctorAppointmentTypes = CommonUtils.formAppointmentTypes(
        branch?.branch_id,
        props?.doctor
      );
      setAppointmentTypes(doctorAppointmentTypes);
      getAppointmentSlots(
        currentDate || moment().format('YYYY-MM-DD'),
        branch?.branch_id
      );

      if (doctorAppointmentTypes?.length === 1) {
        setAppointment(doctorAppointmentTypes[0]?.appointment_type_value);
        setAppointmentObject(doctorAppointmentTypes[0]);
      }
    }
  };

  const init = () => {
    if (props?.doctor?.branches) {
      const filterBranches = props.doctor.branches.map(
        (item: any) => item?.branch
      );
      setBranches(filterBranches);
      if (filterBranches?.[0]) {
        setCurrentBranch(filterBranches[0]);
      }
    }
  };

  useEffect(init, [props?.doctor]);

  useEffect(() => {
    subscription.current = CommunicationService.getMessage().subscribe(
      function (message: any) {
        if (
          message?.data?.type === AVAILABLE_LOCATION_BOOK_APPOINTMENT &&
          message?.data?.branch_id
        ) {
          if (selectedBranch?.branch_id !== message.data.branch_id) {
            const [branch] = branches.filter(
              (item: any) => item.branch_id === message.data.branch_id
            );
            setCurrentBranch(branch);
          }
          // setClickedBookingAppointment(true);
          window.scrollTo({
            behavior: 'smooth',
            top: myRef.current.offsetTop - 150
          });
        }
      }
    );

    return () => {
      return subscription?.current?.unsubscribe();
    };
  });

  const handleChangeClinicPopover = (event: any) => {
    setAnchorElClinicPopover(event?.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorElClinicPopover(null);
  };

  const handleClinicSelect = (item: any): void => {
    setCurrentBranch(item);
    handleClose();
  };

  const closePopup = (): any => {
    setShowDialog(false);
  };

  const showMore = (index: number, length: any): any => {
    const data = [...slotsDetails];
    data[index].expanded = data?.[index]?.itemsToShow === 12 ? true : false;
    data[index].itemsToShow = data?.[index]?.itemsToShow === 12 ? length : 12;
    setSlotDetails(data);
  };

  const handleSlotTimeButtonClick = (timeObject: any) => {
    setSelectedButton(timeObject?.value);
    setSlotTime(timeObject?.label);
  };

  const handleAppointmentClick = (item: any) => {
    setAppointmentObject(item);
  };

  const handleSubmit = (): void => {
    if (
      slotTime &&
      moment(calendarValue).date() === moment().date() &&
      moment(slotTime, 'HH:mm a').isSameOrBefore(moment())
    ) {
      enqueueSnackbar(t('PAST_TIME_SLOT_WARNING'), SnackBarConfig('w'));
    } else if (appointment && slotTime && compliant) {
      setShowDialog(true);
    } else {
      enqueueSnackbar(t('APPOINTMENT_WARNING_TEXT'), SnackBarConfig('w'));
    }
  };

  const handleDropDown = (isOpen: boolean) => {
    setClickedBookingAppointment(isOpen);
  };

  const updateCalenderDisplay = (display: boolean): void => {
    setShowCalender(display);
  };

  const renderPopUp = (): ReactElement => {
    return (
      <RegisterUserComponent
        open={showDialog}
        action={closePopup}
        appointmentValues={{
          appointment: appointmentObject,
          branch: selectedBranch,
          compliant: compliant,
          calendarValue: calendarValue,
          slotTime: slotTime,
          selectedTime: selectedButton,
          doctor: props?.doctor
        }}
      />
    );
  };

  const renderClinicOptions = (): ReactElement => {
    return branches.map((item: any, index: any) => (
      <MenuItem
        key={index}
        value={item.value}
        className={`${
          selectedBranch?.branch_id === item?.branch_id ? 'menu-item-bg' : ''
        }`}
        style={{display: 'block'}}
        onClick={() => handleClinicSelect(item)}>
        <Typography className="menu-item-hospital">
          {item?.branch_name}
        </Typography>
        <Typography className="menu-item-branch">{item?.city}</Typography>
      </MenuItem>
    ));
  };

  const renderMobileCalendarView = (): ReactElement => {
    return (
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <Grid item xs>
          <LocalizationProvider dateAdapter={MomentAdapter}>
            <MobileDatePicker
              onOpen={() => updateCalenderDisplay(true)}
              onClose={() => updateCalenderDisplay(false)}
              open={showCalender}
              openTo="day"
              views={['day']}
              defaultCalendarMonth={moment()}
              value={calendarValue}
              maxDate={moment().add(30, 'days')}
              minDate={moment()}
              onChange={newValue => {
                setCalendarValue(newValue);
                getAppointmentSlots(newValue, selectedBranch?.branch_id);
              }}
              renderInput={params => <TextField {...params} />}
              inputFormat="DD-MMM-YYYY"
              toolbarFormat="ddd"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs>
          <IconButton onClick={() => updateCalenderDisplay(true)}>
            <CalendarTodayIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const renderCalendarView = (): ReactElement => {
    return (
      <LocalizationProvider dateAdapter={MomentAdapter}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          views={['day']}
          defaultCalendarMonth={moment()}
          value={calendarValue}
          maxDate={moment().add(30, 'days')}
          minDate={moment()}
          onChange={newValue => {
            setCalendarValue(newValue);
            getAppointmentSlots(newValue, selectedBranch?.branch_id);
          }}
          renderInput={params => <TextField {...params} />}
          inputFormat="ddd-MM-YYYY"
          toolbarFormat="ddd"
        />
      </LocalizationProvider>
    );
  };

  const renderNormalDropDown = (
    options: any,
    optionValueKey: string,
    optionLabelKey: string,
    optionPriceKey: string
  ): ReactElement => {
    return (
      <Select
        sx={dropDownProps}
        displayEmpty
        value={appointment}
        disabled={options?.length === 1}
        open={clickedBookingAppointment}
        onChange={handleChange}
        onOpen={() => handleDropDown(true)}
        onClose={() => handleDropDown(false)}
        input={<OutlinedInput style={inputProps} />}
        MenuProps={MenuProps}
        inputProps={{'aria-label': 'Without label'}}
        IconComponent={DropDownIcon}>
        <MenuItem disabled value="">
          <em>{t('SELECT_DOCTOR_APPOINTMENT_TYPE')}</em>
        </MenuItem>

        {options?.map((item: any, index: number) => (
          <MenuItem
            key={index}
            value={item[optionValueKey]}
            onClick={() => handleAppointmentClick(item)}>
            <span style={{width: '50%'}}>{item[optionLabelKey]}</span>
            {optionPriceKey && item[optionPriceKey] && (
              <span>{` - RS.${item[optionPriceKey]}`}</span>
            )}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderTextField = (): ReactElement => {
    return (
      <FormTextField
        placeholder={t('COMPLAINT_PLACEHOLDER')}
        name="compliant"
        value={compliant}
        componentTextFieldProps={textFieldProps}
        callback={handleTextChange}
        multiline={true}
      />
    );
  };

  const renderPopOver = (): ReactElement => {
    return (
      <Popover
        id={anchorElClinicPopover ? 'clinic-popover' : undefined}
        open={Boolean(anchorElClinicPopover)}
        onClose={handleClose}
        classes={{paper: 'MuiPopover-paper'}}
        anchorEl={anchorElClinicPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        {branches && renderClinicOptions()}
      </Popover>
    );
  };

  const renderFormSection = (): ReactElement => {
    return (
      <Grid className="appointment-container">
        <Typography className="title-text">
          {t('CHOOSE_APPOINTMENT_TYPE')}
        </Typography>

        <Grid className="fee-dropdown-container" ref={myRef}>
          {renderNormalDropDown(
            appointmentTypes,
            'appointment_type_value',
            'appointment_type_name',
            'price'
          )}
        </Grid>

        <Grid className="booking-container">
          <Grid className="row-container">
            <Typography className="booking-label">
              {t('BOOKING_APPOINTMENT_AT')}
            </Typography>
            {branches?.length > 1 && (
              <Button
                disableElevation={true}
                className="change-clinic-button"
                variant="contained"
                size="small"
                endIcon={<DropDownIcon />}
                onClick={handleChangeClinicPopover}>
                {t('CHANGE_CLINIC')}
              </Button>
            )}
          </Grid>

          <Typography className="hospital-name">
            {selectedBranch?.branch_name}
          </Typography>

          <Typography className="address-text">
            {selectedBranch?.city}
          </Typography>
        </Grid>

        <Typography className="title-text" style={{marginTop: '1rem'}}>
          {t('COMPLIANCE_FOR_YOUR_VISIT')}
        </Typography>

        <Grid className="fee-dropdown-container">{renderTextField()}</Grid>
      </Grid>
    );
  };

  const renderCalendarViewSection = (): ReactElement => {
    return (
      <Grid className="appointment-container">
        <Grid className="date-picker-container">
          <Typography className="time-label">
            {t('SELECT_AVAILABLE_DATE_TIME')}
          </Typography>
        </Grid>
        <Grid>
          {isMobile ? renderMobileCalendarView() : renderCalendarView()}
        </Grid>
        {slotsDetails.map((item: any, index: any) => {
          return (
            <Grid key={index} className="slots-parent">
              <Grid className="header-parent">
                {item?.key === 'morning' && <MorningIcon />}
                {item?.key === 'afternoon' && <AfternoonIcon />}
                {item?.key === 'evening' && <EveningIcon />}
                <Typography className="slots-availability-text">
                  {slotTimings?.[item?.key] &&
                  slotTimings?.[item.key]?.length > 0
                    ? t(item?.label, {count: slotTimings?.[item.key]?.length})
                    : t(item?.label1)}
                </Typography>
              </Grid>
              {(!slotTimings?.[item?.key] ||
                (slotTimings?.[item?.key] &&
                  slotTimings?.[item?.key]?.length === 0)) && (
                <Typography className="not-available-text">
                  {t('NO_SLOTS_AVAILABLE')}
                </Typography>
              )}
              <Grid className="slots-container">
                {slotTimings?.[item?.key] &&
                  slotTimings?.[item?.key]?.length > 0 &&
                  slotTimings?.[item?.key]
                    .slice(0, item?.itemsToShow)
                    .map((time: any, index1: any) => {
                      return (
                        <Button
                          key={index1}
                          variant="outlined"
                          size="small"
                          className={[
                            'slot-time-text',
                            selectedButton === time?.value && 'border-button'
                          ].join(' ')}
                          onClick={() => handleSlotTimeButtonClick(time)}>
                          {time?.label}
                        </Button>
                      );
                    })}
              </Grid>
              {slotTimings?.[item.key]?.length > 12 && (
                <a
                  onClick={() =>
                    showMore(index, slotTimings?.[item?.key]?.length)
                  }>
                  {item?.expanded ? (
                    <Typography className="expanded-button">
                      {t('VIEW_LESS')}
                    </Typography>
                  ) : (
                    <Typography className="expanded-button">
                      {t('VIEW_MORE')}
                    </Typography>
                  )}
                </a>
              )}
            </Grid>
          );
        })}

        <Button
          variant="contained"
          size={'medium'}
          className="btn-section continue-booking-button"
          color="primary"
          onClick={handleSubmit}>
          {t('CONTINUE_BOOKING')}
        </Button>
      </Grid>
    );
  };

  return (
    <Grid className="appointment-wrapper">
      {renderFormSection()}
      <Grid className="divider-container" />
      {renderCalendarViewSection()}
      {renderPopOver()}
      {showDialog && renderPopUp()}
    </Grid>
  );
};

export default AppointmentComponent;
