class NavRoutes {
  static PUBLIC = {
    LOGIN: 'login',
    LOGOUT: 'logout',
    LANDING: 'landing',
    REGISTER: 'register',
    CREATE_USER: 'nonHealpha_createUser',
    FORGOT_PASSWORD: 'forgotPassword',
    FORGOT_PASSWORD_PERSON: 'forgotPasswordPerson',
    PAYMENT_SUCCESS: 'payment',
    SELF_SERVICE: 'selfservice',
    SUCCESS: 'success',
    RESET_PASSWORD: 'resetPassword',
    VERIFY_ACCOUNT: 'verifyAccount',
    WHITE_LABEL_PREFIX_REDIRECT: 'prefixRedirection',
    '404': '404',
    HOME: 'home',
    SCREENING: 'Screening',
    SERVICES: 'Services',
    PARTNERS: 'Partners',
    CONTACT_US: 'Contact Us',
    LOGIN_SIGN_UP: 'Login / Signup',
    SIGN_UP: 'signup',
    DASHBOARD: 'dashboard',
    HOME_SCREEN: 'home_screen',
    PATIENT_DASHBOARD: 'patient_dashboard',
    ASK_QUESTION_SHARED: 'askQuestionShared',
    REPLIES_QUESTION_SHARED: 'repliesQuestionShared',
    DOCTOR_DASHBOARD: 'doctor_landing_page',
    DOCTOR_LOGINPAGE: 'doctor_loginpage',
    VIRTUAL_CLINIC_DASHBOARD_NEW: 'virtual_landing_dashboard',
    OP_DASHBOARD: 'op_dashboard',
    ALL_TEMPLATES: 'templates',
    ALL_MICRO_TEMPLATE: 'all_micro_template',
    SYMPTOMPS_TEMPLATE: 'SymptompsTemplate',
    CUSTOM_TEMPLATES: 'customTemplates',
    PREVIEW_TEMPLATE: 'previewtemplate',
    PLAN_TEMPLATES: 'PlanTemplate',
    OBSERVATIONAL_TEMPLATE: 'ObservtionalTemplate',
    MEDICINE_TEMPLATE: 'MedicineTemplate',
    NEW_TEMPLATE: 'NewTemplate',
    APPOINTMENT: 'appointment',
    REPLIES: 'replies',
    ASK_QUESTION: 'askQuestion',
    MULTI_LANGUAGE: 'multi_languages',
    APPOINTMENTS: 'doctor_dashboard',
    VIEW_PATIENT_HISTORY: 'view_patient_history',
    VIRTUAL_CLINIC_DASHBOARD: 'virtual_clinic_dashboard',
    NURSE_DASHBOARD: 'nurse_dashboard',
    PERSON_WEB_TIMELINE: 'person_web_timeline',
    DOCTOR_WEB_TIMELINE: 'doctor_web_timeline',
    PRESCRIPTION_DETAILS: 'prescription_details',
    OBSERVATIONS: 'observations',
    NON_HEALPHA_USER: 'nonhealpha_user',
    NON_HEALPHA_OP_USER: 'nonhealpha_op_user',
    OP_CREATE_USER: 'op_create_user',
    NON_HEALPHA_SCHOOL_USER: 'nonhealpha_school_user',
    SCHOOL_CREATE_USER: 'school_create_user',
    NON_HEALPHA_CORPORATE_USER: 'nonhealpha_corporate_user',
    CORPORATE_CREATE_USER: 'corporate_create_user',
    NON_HEALPHA_VIRTUAL_USER: 'nonhealpha_virtual_user',
    VIRTUAL_CREATE_USER: 'virtual_create_user',
    NON_HEALPHA_LAB_USER: 'nonhealpha_lab_user',
    LAB_CREATE_USER: 'lab_create_user',
    NON_HEALPHA_PHARMA_USER: 'nonhealpha_pharma_user',
    PHARMA_CREATE_USER: 'pharma_create_user',
    PAYMENT: 'payment',
    HOSPITAL: {
      BASE: 'hospital',
      LIST: 'list',
      VIEW: 'view',
      TABS: {
        OVERVIEW: 'overview',
        DOCTORS: 'doctors',
        SERVICES: 'services',
        FEEDBACK: 'feedback',
        OTHER_LOCATIONS: 'otherlocations'
      }
    },
    USER: {
      PROFILE: 'profile',

      PRACTICE: {
        BASE: 'practice',
        TABS: {
          INFO: 'info',
          BANK_DETAILS: 'bank_details',
          LOCATION: 'location',
          ABOUT: 'about',
          FEATURES: 'features',
          ADD_DETAILS: 'add_details',
          ADD_CHILD_HEALTH: 'add_child_health',
          ADD_CORPORATE_DETAILS: 'add_corporate_details',
          ADD_VIRTUAL_CLINIC_DETAILS: 'add_virtual_clinic_details',
          ADD_LAB_DETAILS: 'add_lab_details',
          ADD_PHARMACY_DETAILS: 'add_pharmacy_details'
        }
      },
      BRANCHES: 'branch',
      DOCTOR: 'doctor',
      INTERMEDIATE_REVIEW: 'intermediate_review',
      PAYMENTS: {
        BASE: 'payment',
        REVIEW: 'review',
        INVOICE: 'invoice',
        HISTORY: 'history'
      },
      PRACTICE_SETUP: {
        BASE: 'setup',
        PARAMETERS: 'parameters',
        SERVICE_GROUP: 'servicegroup',
        SERVICE_MASTER: 'servicemaster',
        USERS: 'users',
        ADD_USER: 'add_users',
        ADD_ROLE: 'add_role',
        EDIT_ROLE: 'edit_role',
        PATIENT: 'patient'
      },
      PRACTICE_CHILD_HEALTH: {
        BASE: 'child_health',
        SCHOOL_PARAMETERS: 'school_parameters',
        USERS: 'users',
        ADD_USER: 'add_users',
        ADD_ROLE: 'add_role',
        EDIT_ROLE: 'edit_role',
        ADD_CLASSES_DETAILS: 'add_classes_details',
        ADD_STUDENTS_DETAILS: 'add_students_details'
      },
      PRACTICE_CORPORATE: {
        BASE: 'corporate',
        CORPORATE_PARAMETERS: 'corporate_parameters',
        ADD_EMPLOYEE_DETAILS: 'add_employee_details',
        USERS: 'users',
        ADD_USER: 'add_users',
        ADD_ROLE: 'add_role',
        EDIT_ROLE: 'edit_role',
        DEPARTMENT: 'add_corporate_department'
      },
      PRACTICE_VIRTUAL_CLINIC: {
        BASE: 'virtual_clinic',
        VIRTUAL_CLINIC_PARAMETERS: 'virtual_clinic_parameters'
      },
      PRACTICE_LAB: {
        BASE: 'lab',
        LAB_PARAMETERS: 'lab_parameters',
        USERS: 'users',
        ADD_USER: 'add_users',
        ADD_ROLE: 'add_role',
        EDIT_ROLE: 'edit_role'
      },
      PRACTICE_PHARMACY: {
        BASE: 'pharmacy',
        PHARMACY_PARAMETERS: 'pharmacy_parameters',
        USERS: 'users',
        ADD_USER: 'add_users',
        ADD_ROLE: 'add_role',
        EDIT_ROLE: 'edit_role'
      },
      SUBSCRIPTION: {
        BASE: 'subscription',
        MY_SUBSCRIPTIONS: 'my_subscriptions',
        MY_CART: 'my_cart',
        VIEW_PLANS: 'view_plans',
        VIEW_CART: 'view_cart',
        SUBSCRIPTION_REDIRECT_BACK: 'subscription_redirect_back'
      }
    },
    DOCTOR: {
      BASE: 'doctor',
      LIST: 'list',
      VIEW: 'view',
      TABS: {
        INFO: 'info',
        EDUCATION_EXPERIENCE: 'educationExperience',
        AWARDS_RECOGNITIONS: 'awardsRecognitions',
        PUBLICATIONS: 'publications',
        MEMBERSHIP: 'membership',
        FEEDBACK: 'feedback'
      }
    },
    BILLING: {
      BILLINGHOMEPAGE: 'billing',
      BILLING_PATIENT_SCREEN: 'patient'
    },
    ACCESS_CONTROL: {
      LIST_OF_MODULES: 'list_of_modules',
      ASSIGN_SCREEN_TO_MODULE: 'assign_screen_to_module',
      USER_ROLES: 'user_roles',
      PRACTICE_LIST: 'view_practice',
      ADD_PRACTICE: 'add_practice',
      PRACTICE_PLANS: 'practicePlans',
      BRANCH_PLANS: 'branchPlans',
      BRANCH_SELF_PLANS: 'branchSelfPlans',
      MYCART: 'MyCart',
      AGREEMENT: 'Agreement',
      VIEW_AGREEMENTS: 'view_agreements',
      ADD_AGREEMENT: 'add_agreement',
      PRACTICE_SELECTION: 'practiceSelection',
      LIST_OF_SCREENS_WITH_MODULES: 'list_of_screens_with_modules',
      NEW_USER: 'new_user',
      MENU_STRUCTURE: 'menu_structure',
      ADD_NEW_ROLES: 'add_new_role',
      EDIT_NEW_ROLES: 'edit_new_role',
      CART_COMPONENT: 'cartComponent',
      NEW_SMS_PLAN_COMPONENT: 'new_sms_plan_component',
      SMS_CREDIT_COMPONENT: 'smsCreditComponent',
      CREDIT_PLAN_BY_BRANCH: 'credit_plan_by_branch',
      SMS_TABS_COMPONENT: 'sms_tabs_component',
      ALL_INVOICES_COMPONENT: 'allinvoicescomponent',
      ONBOARD_TABS: 'onboard',
      EDIT_USER_DETAILS: 'edit_user_details',
      HOME_SCREEN: 'home_screen',
      MANAGE_PRACTICE: {
        TABS: {
          BASE: 'manage_practice',
          BANK_DETAILS: 'bank_details',
          ADD_DETAILS: 'add_details',
          ADD_CHILD_HEALTH: 'add_child_health',
          ADD_CORPORATE_DETAILS: 'add_corporate_details',
          ADD_VIRTUAL_CLINIC_DETAILS: 'add_virtual_clinic_details',
          ADD_LAB_DETAILS: 'add_lab_details',
          ADD_PHARMACY_DETAILS: 'add_pharmacy_details'
        }
      },
      MANAGE_BRANCH: {
        BASE: 'manage_branch',
        BANK_DETAILS: 'bank_details',
        ADD_DETAIL: 'add_detail',
        CHILD_HEALTH: 'child_health',
        CORPORATE_DETAILS: 'corporate_details',
        VIRTUAL_CLINIC_DETAILS: 'virtual_clinic_details',
        LAB_DETAILS: 'lab_details',
        PHARMACY_DETAILS: 'pharmacy_details'
      },
      MANAGE_PRACTICE_PARAMETER: 'manage_practice_parameter',
      MANAGE_CHILDHEALTH_PARAMETER: 'manage_childhealth_parameter',
      MANAGE_CORPORATE_PARAMETER: 'manage_corporate_parameter',
      MANAGE_VIRTUAL_CLINIC_PARAMETER: 'manage_virtual_clinic_parameter',
      HEALPHA_CHARGES: 'healpha_charges'
    },
    MANAGE: {
      EDIT_USER: 'edit_user'
    }
  };
  static HEALPHA = {
    HOME: '/',
    ABOUT_PAGE: 'about-us',
    TEAM: 'team',
    HEALPHA_SCHOOL: 'healpha-school',
    HEALPHA_CORPORATE: 'healpha-corporate',
    COMMUNITY_HEALTH: 'community-health',
    RESEDENTIAL_WELFARE_ASSOCIATION: 'residential-welfare-association',
    MARINE: 'marine',
    DIGITAL_SMART_CLINICS: 'digital-smart-clinics',
    TELECONSULTATION: 'teleconsultation',
    COVID_HOMECARE: 'covid-homecare',
    COVID: 'covid',
    VIRTUAL_CLINICS: 'virtual-clinics-for-corporates',
    DOCTORS: 'doctors',
    PARTNERS: 'partners',
    INVESTORS: 'investors',
    PRESS_AND_MEDIA: 'press-media',
    GALLERY: 'gallery',
    BLOG: 'blog',
    VIDEOS: 'videos',
    TESTIMONIALS: 'testimonials',
    FAQ: 'frequently-asked-questions',
    CONTACT_US: 'contact-us',
    NEWS: 'news'
  };
}

export default NavRoutes;
