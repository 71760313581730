import React from 'react';
import {Typography, Grid} from '@material-ui/core';

import Icons from '@components/system/MyIcons/index';
import './AvailableTimings.scss';

const AvailableTimings = (props: any): React.ReactElement => {
  return (
    <Grid className="available-timings-wrapper">
      <Grid className="available-timings-container">
        {props?.workTimings &&
          Array.isArray(props.workTimings) &&
          props.workTimings.length > 0 &&
          props.workTimings.map((item: any, index: any) => {
            return (
              <Grid key={index} className="timing-item">
                <Grid
                  className="section-1"
                  container
                  alignItems="center"
                  spacing={2}>
                  <Grid item>
                    <Icons.TimeIcon className="img-sec" />
                  </Grid>

                  <Grid item>
                    <Typography className="title">{item.label}</Typography>
                  </Grid>
                </Grid>

                <Grid container style={{paddingTop: '0.35rem'}}>
                  <Grid item xs md>
                    <Typography className="subtitle">{item.value}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};
export default AvailableTimings;
