import TooltipIcon from './TooltipIcon';
import LikeIcon from './LikeIcon';
import FeeIcon from './FeeIcon';
import TimeIcon from './TimeIcon';
import SearchIcon from './SearchIcon';
import DoctorIcon from './DoctorIcon';
import HospitalIcon from './HospitalIcon';
import MailIcon from './MailIcon';
import CallIcon from './CallIcon';
import EmergencyIcon from './EmergencyIcon';
import GetDirectionsIcon from './GetDirectionsIcon';
import BackIcon from './BackIcon';
import NextIcon from './NextIcon';
import InPersonVisitIcon from './InPersonVisitIcon';
import VideoVisitIcon from './VideoVisitIcon';
import CommentIcon from './CommentIcon';
import MedicationRegistrationVerificationIcon from './MedicationRegistrationVerificationIcon';
import PlayButtonIcon from './PlayButton';
import ChangeIcon from './ChangeIcon';
import CloseWhiteIcon from './CloseWhiteIcon';
import MorningIcon from './MorningIcon';
import AfternoonIcon from './AfternoonIcon';
import EveningIcon from './EveningIcon';
import ForumsIllustrationIcon from './ForumsIllustrationIcon';
import CovidCareIcon from './CovidCareIcon';
import TeleMedicineIcon from './TeleMedicineIcon';
import DoctorForumsIcon from './DoctorForumsIcon';
import ForumsReplyIcon from './ForumsReplyIcon';
import ReportIcon from './ReportIcon';
import ForumsAvatarIcon from './ForumsAvatarIcon';
import DOBIcon from './DOBIcon';
import GenderIcon from './GenderIcon';
import PasswordIcon from './PasswordIcon';
import DropDownIcon from './DropDownIcon';
import CloseIcon from './CloseIcon';
import PreviewIcon from './PreviewIcon';
import CloseBlackIcon from './CloseBlackIcon';
import StarGreenIcon from './StarGreenIcon';
import FaceBookIcon from './FaceBookIcon';
import YouTubeIcon from './YouTubeIcon';
import LinkedInIcon from './LinkedInIcon';
import RadioBoxSelectedIcon from './RadioBoxSelectedIcon';
import DropDownBigIcon from './DropDownBigIcon';
import ConnectWithDoctorsIcon from './ConnectWithDoctorsIcon';
import ForumsIcon from './ForumsIcon';
import ReceiptsIcon from './ReceiptsIcon';
import RemaindersIcon from './RemaindersIcon';
import VideoIcon from './VideoIcon';
import PracticeIcon from './PracticeIcon';
import SecurePlatformIcon from './SecurePlatformIcon';
import CorporateIcon from './CorporateIcon';
import AppStoreIcon from './AppStoreIcon';
import GooglePalyIcon from './GooglePalyIcon';
import HealphaLogoWhiteIcon from './HealphaLogoWhiteIcon';
import TwitterIcon from './TwitterIcon';
import QuoraIcon from './QuoraIcon';
import InstagramIcon from './InstagramIcon';
import SendActiveIcon from './SendActiveIcon';
import SendInActiveIcon from './SendInActiveIcon';
import NotificationIcon from './NotificationIcon';
import SettingsIcon from './SettingsIcon';
import LanguageIcon from './LanguageIcon';
import ForwardArrowIcon from './ForwardArrowIcon';
import ProfileIcon from './ProfileIcon';
import ChangePasswordIcon from './ChangePasswordIcon';
import LogoutIcon from './LogoutIcon';
import CovidCareBlueIcon from './CovidCareBlueIcon';
import CovidCareGrayIcon from './CovidCareGrayIcon';
import CovidCareWhiteIcon from './CovidCareWhiteIcon';
import SchoolHealthBlueIcon from './SchoolHealthBlueIcon';
import SchoolHealthGrayIcon from './SchoolHealthGrayIcon';
import SchoolHealthWhiteIcon from './SchoolHealthWhiteIcon';
import OPCorporateBlueIcon from './OPCorporateBlueIcon';
import OPCorporateGrayIcon from './OPCorporateGrayIcon';
import OPCorporateWhiteIcon from './OPCorporateWhiteIcon';
import DownArrowBlackIcon from './DownArrowBlackIcon';
import DownArrowWhiteIcon from './DownArrowWhiteIcon';
import CamWhiteIcon from './CamWhiteIcon';
import MicWhiteIcon from './MicWhiteIcon';
import StethoscopeIcon from './StethoscopeIcon';
import SingleChoiceIcon from './SingleChoiceIcon';
import MultiChoiceIcon from './MultiChoiceIcon';
import HeadingIcon from './HeadingIcon';
import SimpleAnswerIcon from './SimpleAnswerIcon';
import YesNoIcon from './YesNoIcon';
import NotesIcon from './NotesIcon';
import EditIcon from './EditIcon';
import DeleteIcon from './DeleteIcon';
import CloneIcon from './CloneIcon';
import DoneIcon from './DoneIcon';
import UserIcon from './UserIcon';
import TimelineIcon from './TimelineIcon';
import BackArrowBlackIcon from './BackArrowBlackIcon';
import DownArrowIcon from './DownArrowIcon';
import CashIcon from './CashIcon';
import CardIcon from './CardIcon';
import ChequeIcon from './ChequeIcon';
import UpiIcon from './UpiIcon';
import WalletIcon from './WalletIcon';
import SliderIcon from './SliderIcon';
import TableIcon from './TableIcon';
import EllipseIcon from './EllipseIcon';
import PDFIcon from './PDFIcon';
import EmailIcon from './EmailIcon';
import SearchFileIcon from './SearchFileIcon';
import CallDarkIcon from './CallDarkIcon';
import GenderDarkIcon from './GenderDarkIcon';
import VideoCallIcon from './VideoCallIcon';
import PreviousIcon from './PreviousIcon';
import AddAttachmentIcon from './AddAttachmentIcon';
import HospitalIconDark from './HospitalIconDark';
import ReportsIcon from './ReportsIcon';
import PrescriptionIcon from './PrescriptionIcon';
import UploadIcon from './UploadIcon';
import AllergyIcon from './AllergyIcon';
import HealthEventIcon from './HealthEventIcon';
import FamilyHistoryIcon from './FamilyHistoryIcon';
import BackArrowIcon from './BackArrowIcon';
import PrescriptionOrangeIcon from './PrescriptionOrangeIcon';
import MedicineIcon from './MedicineIcon';
import SupplementIcon from './SupplementIcon';
import LabIcon from './LabIcon';
import ImagingIcon from './ImagingIcon';
import VaccineIcon from './VaccineIcon';
import NursingIcon from './NursingIcon';
import CaptureVitalsIcon from './CaptureVitalsIcon';
import PlusIcon from './PlusIcon';
import RightTickIcon from './RightTickIcon';
import SupplimentIcon from './SupplimentIcon';
import CameraIcon from './CameraIcon';
import DoneIconBlack from './DoneIconBlack';
import CloseIconRed from './CloseIconRed';
import DoubleForwardArrowIcon from './DoubleForwardArrowIcon';
import ViewPatientHistoryIcon from './ViewPatientHistoryIcon';
import PatientIcon from './PatientIcon';
import MakeBillIcon from './MakeBillIcon';
import UpArrowIcon from './UpArrowIcon';
import NextArrowIcon from './NextArrowIcon';
import BackArrowIconBlue from './BackArrowIconBlue';
import EditPrimaryIcon from './EditPrimaryIcon';
import BluePlusIcon from './BluePlusIcon';
import WhitePlusIcon from './WhitePlusIcon';
import MessageIcon from './MessageIcon';
import MailsIcon from './MailsIcon';
import WhatsappIcon from './WhatsappIcon';
import CartIcon from './CartIcon';
import QRIcon from './QRIcon';
import WhiteAddIcon from './WhiteAddIcon';
import FilterIcon from './FilterIcon';
import BlacksearchIcon from './BlacksearchIcon';
import HeadLogoIcon from './HeadLogoIcon';
import PersonIcon from './PersonIcon';
import CalendarIcon from './CalendarIcon';
import AddIcon from './AddIcon';
import SmallAddIcon from './SmallAddIcon';
import WhiteAddPlus from './WhitePlus';
import Profile from './Profile';
import DDIcon from './DDIcon';
import SuccessIcon from './SuccessIcon';
import VaccineCloseIcon from './VaccineCloseIcon';
import StethoscopeIconblack from './StethoscopeIconblack';
import SuppIcon from './SuppIcon';
import LabsIcon from './LabsIcon';
import ImaginIcon from './ImaginIcon';
import NurIcon from './NurIcon';
import QRCodeIcon from './QRCodeIcon';
import KebabmenuIcon from './KebabmenuIcon';
import ProfileImgIcon from './ProfileImgIcon';
import MenuStructureIcon from './MenuStructureIcon';
import ToggleIcon from './ToggleIcon';
import MenuLeftArrowIcon from './MenuLeftArrow';
import MenuRightArrowIcon from './MenuRightArrow';
import AddScreenIcon from './AddScrennIcon';
import AddSubmenuIcon from './AddSubMenuIcon';
import RedDeleteIcon from './RedDeleteIcon';
import SaveMenuIcon from './SaveMenuIcon';
import MoveDownSideIcon from './MoveDownSideIcon';
import MoveUpSideIcon from './MoveUpSideIcon';
import HealphaVerificationIcon from './HealphaVerification';
import DropDownBlueArrowIcon from './DropDownBlueArrowIcon';
import CloudUpload from './CloudUploadIcon';
import TrayIcon from './TrayIcon';
import HealphaSymbol from './HealphaSymbol';
import WebsiteIcon from './Website';
import PositiveUpArrowIcon from './PositiveUpArrowIcon';
import NegativeDownArrowIcon from './NegativeDownArrowIcon';
import PatientRecordsIcon from './PatientRecordsIcon';
import AppointmentsCountIcon from './AppointmentsCountIcon';
import ShareIcon from './ShareIcon';
import CalIcon from './CalIcon';
import BoxImportantIcon from './BoxImportantIcon';
import PatientCheckedIcon from './PatientChecked';
import PatientBookedIcon from './PatientBooked';
import LabDetailsIcon from './LabDetailsIcon';
import LabServiceIcon from './LabServiceIcon';
import CancelShareIcon from './CancelShareIcon';
import DownloadIconSvgIcon from './DownloadIcon';
import BlueSteth from './BlueStethIcon';

export default {
  TooltipIcon,
  LikeIcon,
  FeeIcon,
  TimeIcon,
  SearchIcon,
  DoctorIcon,
  HospitalIcon,
  MailIcon,
  CallIcon,
  EmergencyIcon,
  GetDirectionsIcon,
  BackIcon,
  NextIcon,
  InPersonVisitIcon,
  VideoVisitIcon,
  MedicationRegistrationVerificationIcon,
  PlayButtonIcon,
  ChangeIcon,
  CloseWhiteIcon,
  MorningIcon,
  AfternoonIcon,
  EveningIcon,
  ForumsIllustrationIcon,
  CovidCareIcon,
  TeleMedicineIcon,
  DoctorForumsIcon,
  ForumsReplyIcon,
  ReportIcon,
  ForumsAvatarIcon,
  DOBIcon,
  GenderIcon,
  PasswordIcon,
  DropDownIcon,
  CloseIcon,
  PreviewIcon,
  CloseBlackIcon,
  StarGreenIcon,
  LinkedInIcon,
  YouTubeIcon,
  FaceBookIcon,
  RadioBoxSelectedIcon,
  DropDownBigIcon,
  ConnectWithDoctorsIcon,
  ForumsIcon,
  ReceiptsIcon,
  RemaindersIcon,
  VideoIcon,
  SecurePlatformIcon,
  PracticeIcon,
  CorporateIcon,
  AppStoreIcon,
  GooglePalyIcon,
  HealphaLogoWhiteIcon,
  TwitterIcon,
  QuoraIcon,
  InstagramIcon,
  SendActiveIcon,
  SendInActiveIcon,
  NotificationIcon,
  SettingsIcon,
  LanguageIcon,
  ForwardArrowIcon,
  ProfileIcon,
  ChangePasswordIcon,
  LogoutIcon,
  CovidCareBlueIcon,
  CovidCareGrayIcon,
  CovidCareWhiteIcon,
  SchoolHealthWhiteIcon,
  SchoolHealthGrayIcon,
  SchoolHealthBlueIcon,
  OPCorporateWhiteIcon,
  OPCorporateBlueIcon,
  OPCorporateGrayIcon,
  DownArrowWhiteIcon,
  DownArrowBlackIcon,
  CamWhiteIcon,
  MicWhiteIcon,
  StethoscopeIcon,
  SingleChoiceIcon,
  MultiChoiceIcon,
  HeadingIcon,
  SimpleAnswerIcon,
  YesNoIcon,
  NotesIcon,
  EditIcon,
  CloneIcon,
  DeleteIcon,
  DoneIcon,
  UserIcon,
  TimelineIcon,
  BackArrowBlackIcon,
  DownArrowIcon,
  CashIcon,
  CardIcon,
  ChequeIcon,
  UpiIcon,
  WalletIcon,
  SliderIcon,
  TableIcon,
  EllipseIcon,
  PDFIcon,
  EmailIcon,
  SearchFileIcon,
  CallDarkIcon,
  GenderDarkIcon,
  VideoCallIcon,
  PreviousIcon,
  AddAttachmentIcon,
  HospitalIconDark,
  ReportsIcon,
  PrescriptionIcon,
  UploadIcon,
  AllergyIcon,
  HealthEventIcon,
  FamilyHistoryIcon,
  BackArrowIcon,
  PrescriptionOrangeIcon,
  MedicineIcon,
  SupplementIcon,
  LabIcon,
  ImagingIcon,
  VaccineIcon,
  NursingIcon,
  CaptureVitalsIcon,
  PlusIcon,
  RightTickIcon,
  SupplimentIcon,
  CameraIcon,
  DoneIconBlack,
  CloseIconRed,
  DoubleForwardArrowIcon,
  ViewPatientHistoryIcon,
  PatientIcon,
  MakeBillIcon,
  UpArrowIcon,
  NextArrowIcon,
  BackArrowIconBlue,
  EditPrimaryIcon,
  BluePlusIcon,
  WhitePlusIcon,
  MessageIcon,
  MailsIcon,
  WhatsappIcon,
  CartIcon,
  QRIcon,
  WhiteAddIcon,
  FilterIcon,
  BlacksearchIcon,
  HeadLogoIcon,
  PersonIcon,
  CalendarIcon,
  AddIcon,
  SmallAddIcon,
  WhiteAddPlus,
  Profile,
  DDIcon,
  SuccessIcon,
  VaccineCloseIcon,
  StethoscopeIconblack,
  SuppIcon,
  LabsIcon,
  ImaginIcon,
  NurIcon,
  QRCodeIcon,
  KebabmenuIcon,
  ProfileImgIcon,
  MenuStructureIcon,
  ToggleIcon,
  MenuLeftArrowIcon,
  MenuRightArrowIcon,
  AddScreenIcon,
  AddSubmenuIcon,
  RedDeleteIcon,
  SaveMenuIcon,
  MoveDownSideIcon,
  MoveUpSideIcon,
  HealphaVerificationIcon,
  DropDownBlueArrowIcon,
  CloudUpload,
  TrayIcon,
  HealphaSymbol,
  CommentIcon,
  WebsiteIcon,
  PositiveUpArrowIcon,
  NegativeDownArrowIcon,
  PatientRecordsIcon,
  AppointmentsCountIcon,
  ShareIcon,
  CalIcon,
  BoxImportantIcon,
  PatientCheckedIcon,
  PatientBookedIcon,
  LabDetailsIcon,
  LabServiceIcon,
  CancelShareIcon,
  DownloadIconSvgIcon,
  BlueSteth
};
