import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import { ReactComponent as EmergencyIconSvg } from '@assets/images/healpha/emergency.svg';

const EmergencyIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={EmergencyIconSvg} viewBox="0 0 24 24" />
  );
};

export default EmergencyIcon;