/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import {
  GroupingState,
  IntegratedGrouping,
  ViewState
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  Appointments,
  AppointmentTooltip,
  GroupingPanel,
  Resources,
  CurrentTimeIndicator
} from '@devexpress/dx-react-scheduler-material-ui';
import AuthUtils from 'src/utils/AuthUtils';
import {
  ThemeProvider,
  Paper,
  Backdrop,
  CircularProgress
} from '@material-ui/core';
import theme from 'src/materialtheme';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import AppointmentCard from '../Appointment/AppointmentCard';
import BasicLayoutComponent from '../Appointment/CreateAppointmentModal';
import './CalendarDayWise.scss';
import moment from 'moment';
import NewAppointmentBookingsComponent from '../Appointment/AppointmentBooking';

const layoutStyling = {
  width: '25rem',
  top: {
    xs: '0',
    lg: '15.625rem !important'
  },
  right: {
    xs: '0',
    lg: '15rem !important'
  },
  left: 'auto !important'
};
const schedularStyles = {width: '100%', boxShadow: 'none', height: '75vh'};

const CalendarDayWise = ({
  doctors = [],
  selectedDate,
  getDoctors
}: any): React.ReactElement => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const doctor_id = AuthUtils.getDoctorId();
  const branch_id = AuthUtils.getMenuBranchId();

  const [appointments, setAppointments] = useState<any>([]);
  const [availability, setAvailability] = useState<any>({});
  const [docs, setDocs] = useState<any>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [cellData, setCellData] = useState<any>({});
  const [cellDuration, setCellDuration] = useState<any>([]);
  const [
    showNewAppointmentBookingUpPopup,
    setShowNewAppointmentBookingUpPopup
  ] = useState<boolean>(false);
  const [resources, setResources] = useState<any>([
    {
      fieldName: 'members',
      title: 'Doctors',
      instances: [{text: 'No Doctors', id: ''}],
      allowMultiple: true
    }
  ]);
  const [grouping] = useState<any>([
    {
      resourceName: 'members'
    }
  ]);

  const doctorsInit = () => {
    if (JSON.stringify(doctors) === JSON.stringify(docs)) return;
    doctors.length
      ? setResources([
          {
            fieldName: 'members',
            title: 'Doctors',
            instances: doctors?.map((i: any, index: number) => {
              return {
                id: '' + i.non_healpha_user_id,
                text: `${i.first_name} ${i.last_name}`,
                color: i.color
              };
            }),
            allowMultiple: true
          }
        ])
      : setResources([
          {
            fieldName: 'members',
            title: 'Doctors',
            instances: [{text: 'No Doctors', id: ''}],
            allowMultiple: true
          }
        ]);
    setCellData({});
  };

  useEffect(doctorsInit, [doctors]);

  const getDoctorTimings = (isUpdate?: any) => {
    try {
      if (JSON.stringify(doctors) === JSON.stringify(docs) && !isUpdate)
        return setLoading(false);
      setLoading(true);
      setDocs(doctors);
      setAvailability({});
      setAppointments([]);
      let finalList: any = {};
      doctors.map(async (i: any, index: number) => {
        const tmp = i?.appointments.map((appointment: any) => {
          const endDate: any = new Date(appointment?.date_end);
          return {
            title: appointment?.person_details?.full_name,
            startDate: new Date(appointment?.date_start),
            endDate: endDate,
            id: appointment?.id,
            members: ['' + i.non_healpha_user_id],
            location: 'Appointments',
            ...appointment
          };
        });
        setAppointments((prev: any) => [...prev, ...tmp]);
        i.slot_timing &&
          setCellDuration((rest: any) => [...rest, i.slot_timing]);
        let timings: any = [];
        Object.keys(i.free_slots).map((slot: any) => {
          timings = [
            ...timings,
            ...i.free_slots[slot]?.map((x: any) => x.value)
          ];
        });
        finalList = {
          ...finalList,
          [i.non_healpha_user_id]: timings || []
        };
      });
      setAvailability(finalList);
      setLoading(false);
      return;
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(
        (err as any)?.message || t('ERROR_ENCOUNTERED'),
        SnackBarConfig('e')
      );
    }
    return () => {
      setAppointments([]);
    };
  };
  useEffect(getDoctorTimings, [doctors]);

  const initListener = () => {
    window.addEventListener('initListener', () => {
      getDoctors();
    });
    return () => {
      window.removeEventListener('initListener', () => {
        getDoctors();
      });
    };
  };
  useEffect(initListener, []);

  const closeModal = () => {
    window.addEventListener('closeModal', () => {
      setVisible(false);
    });
    return () => {
      window.removeEventListener('closeModal', () => {
        setVisible(false);
      });
    };
  };
  useEffect(closeModal, []);

  const indicatorRef = React.useRef<any>(null);
  const Indicator = ({...restProps}: any) => {
    return <div {...restProps} ref={indicatorRef} />;
  };
  React.useEffect(() => {
    indicatorRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  });

  const openNewAppointmentBookingPopup = (): void => {
    setShowNewAppointmentBookingUpPopup(true);
  };

  const closeNewAppointmentBookingPopup = (): void => {
    setShowNewAppointmentBookingUpPopup(false);
  };

  const Layout = ({appointmentData, ...restProps}: any) => {
    return (
      <AppointmentTooltip.Layout
        PaperProps={{sx: layoutStyling}}
        className="w-100"
        {...restProps}
        appointmentData={appointmentData}
      />
    );
  };

  const Content = ({appointmentData, ...restProps}: any) => {
    return (
      <div className="appointment-view-card">
        <AppointmentCard
          appointmentData={appointmentData}
          selectedDate={selectedDate}
          setVisible={setVisible}
        />
      </div>
    );
  };

  const Appointment = ({children, style, ...restProps}: any) => {
    return (
      <Appointments.Appointment {...restProps}>
        {children}
      </Appointments.Appointment>
    );
  };

  const renderNewAppointmentBookingPopup = (): React.ReactElement => {
    return (
      <NewAppointmentBookingsComponent
        branchId={branch_id}
        open={showNewAppointmentBookingUpPopup}
        closeNewBookingsPopup={closeNewAppointmentBookingPopup}
        is_clinical_dashboard={true}
        selectedDate={cellData?.startDate}
        endDate={cellData?.endDate}
        docId={cellData.groupingInfo?.[0]?.id}
        hasTimeSlot
      />
    );
  };

  const TimeTableCell = ({onDoubleClick, ...restProps}: any) => {
    const isBefore = moment().isAfter(restProps.startDate);
    const format = 'HH:mm';
    const time = moment(restProps.startDate).format(format);
    // const isDisable =
    //   isBefore || !availability?.[restProps?.groupingInfo?.[0]?.id]?.[time];
    const isDisable =
      isBefore ||
      !availability?.[restProps?.groupingInfo?.[0]?.id]?.includes(time);
    return (
      <DayView.TimeTableCell
        className={isDisable ? 'cell-disable' : 'cell'}
        onClick={() => {
          if (isDisable) return;
          setCellData(restProps);
          openNewAppointmentBookingPopup();
          // onDoubleClick();
        }}
        {...restProps}
      />
    );
  };

  const DayScaleCell = () => null;

  if (loading) {
    return (
      <Backdrop className={'backdrop'} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Paper sx={schedularStyles} className="schedular-wrapper">
          <Scheduler data={appointments}>
            <GroupingState grouping={grouping} />
            <ViewState currentDate={selectedDate} />
            <DayView
              cellDuration={
                cellDuration?.length ? Math.min(...cellDuration) : 5
              }
              startDayHour={0}
              endDayHour={24}
              timeTableCellComponent={TimeTableCell}
              dayScaleCellComponent={DayScaleCell}
            />
            <Appointments appointmentComponent={Appointment} />
            <CurrentTimeIndicator indicatorComponent={Indicator} />
            <Resources data={resources} mainResourceName="members" />

            <IntegratedGrouping />
            <AppointmentTooltip
              contentComponent={Content}
              layoutComponent={Layout}
              visible={visible}
              onVisibilityChange={setVisible}
            />
            <GroupingPanel />
            {showNewAppointmentBookingUpPopup &&
              renderNewAppointmentBookingPopup()}
            <BasicLayoutComponent
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              branch_id={branch_id}
              cellData={cellData}
              doctor_id={doctor_id}
              onCancel={() => setModalVisible(false)}
            />
          </Scheduler>
        </Paper>
      </ThemeProvider>
    </div>
  );
};

export default CalendarDayWise;
