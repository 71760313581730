import React, { ReactElement } from 'react';
import BaseIcon from './BaseIcon';
import { ReactComponent as EveningIconSvg } from '@assets/images/healpha/evening.svg';

const EveningIcon = (props: any): ReactElement => {
  const { className, ...rest } = props;
  return (
    <BaseIcon {...rest} className={className} component={EveningIconSvg} viewBox="0 0 24 24" />
  );
};

export default EveningIcon;