/* eslint-disable camelcase */
import React, {useState} from 'react';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Grid, Typography, Button, Menu, MenuItem} from '@material-ui/core';

import {
  cancelAppointment,
  getServiceByEncounter,
  getVaccinesInformation,
  updateEncStatus
} from '@shared/services/PublicService';

import TemplateUtils from 'src/utils/TemplateUtils';
import CommonUtils from 'src/utils/CommonUtils';
import AuthUtils from 'src/utils/AuthUtils';
import Icons from '@components/system/MyIcons';
import {statusColor} from 'src/constants/statusColors';
import NavRoutes from 'src/constants/route/NavRoutes';
import {AppointmentStatusTypes} from 'src/constants/SystemConstants';
import {SnackBarConfig} from 'src/utils/SnackBarConfig';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '../../../../../../../assets/images/healpha/Close.svg';
import './PatientCard.scss';
import FormTextField from '@components/system/CustomFormField/FormTextField';
import NewAppointmentBookingsComponent from 'src/modules/user/modules/ClinicDashboard/Appointment/AppointmentBooking';
import PdfViewerDialog from 'src/modules/shared/PdfViewerComponent/PdfViewerDialog';
import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';

const PatientCard = (props: any): React.ReactElement => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [cancel, setcancel] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const [refund, setRefund] = useState<boolean>(false);
  const [cancelReason, setcancelReason] = useState<any>('');
  const [showPdfFileDialog, setShowPdfFileDialog] = useState<boolean>(false);
  const [paymentStatus, setPaymentStatus] = useState<boolean>(false);
  const [vaccineAttachmentAdded, setVaccineAttachmentAdded] =
    useState<boolean>(false);
  const [pdfUrl, setpdfUrl] = useState<any>('');
  const [processOpen, setProcessOpen] = useState<any>(false);
  const history = useHistory();
  const [
    showNewAppointmentBookingUpPopup,
    setShowNewAppointmentBookingUpPopup
  ] = useState<boolean>(false);
  const {
    currPatient,
    setSelectedPatient,
    index,
    appointmentTypeStatus,
    prescription,
    keyGiven,
    clickedStatus,
    template_Id,
    setVaccineData,
    setShowProvideVaccinePopUp,
    isFromClinicalDashboard,
    isFromVirtualClinic,
    selectedDate,
    isFromViewMore,
    setProvideVaccinePassData,
    patientInfo
  } = props;
  const isStandAloneDoctor = CommonUtils.checkIsStandAloneDoctor();
  const isNurse = CommonUtils.checkIsNurse();
  const isAdminNurseDashboard = CommonUtils.checkIsAdminNurseDashboard();

  const conditionNotForNormalDoc =
    isStandAloneDoctor ||
    isNurse ||
    isAdminNurseDashboard ||
    isFromVirtualClinic ||
    isFromClinicalDashboard;

  const isFrontOffice = CommonUtils.checkIsFrontOfficeAndAdmin();
  // const options = ['Cancel', 'Reschedule', 'View Prescription', 'Process'];
  const ITEM_HEIGHT = 48;
  const {
    person_details,
    appointment_type,
    date_start,
    appointment_status,
    id,
    healpha_id,
    doc_id,
    branch_id,
    appointment_date_start
  } = currPatient;

  const encId = AuthUtils.getEncId();
  const dateOfApp: any = date_start && date_start.slice(0, 5);
  const BASE_ROUTE_USER = `${BASE_ROUTE_PORTAL}/user`;

  const goToBilling = (): void => {
    TemplateUtils.setSelectedPatientId(person_details?.hlpid);
    TemplateUtils.clearPaymentDetails();
    TemplateUtils.clearPaymentCriteria();
    TemplateUtils.clearServices();
    TemplateUtils.clearCorporatePersonDetails();
    AuthUtils.setBillingAppointmentDetails(
      JSON.stringify({
        appointment_id: id,
        appointment_type: appointment_type.toLowerCase(),
        appointment_status: appointment_status,
        doc_id: doc_id,
        enc_id: props?.endId,
        appointment_date: appointment_date_start,
        cloud_clinic_branch_id: branch_id,
        is_virtual: isFromVirtualClinic
      })
    );

    let url = NavRoutes.PUBLIC.BILLING.BILLING_PATIENT_SCREEN;
    if (isStandAloneDoctor) {
      url += `?isFromStandAlone=${true}`;
    }
    if (isStandAloneDoctor) {
      history.push(url);
    } else {
      history.push({
        pathname: `/${BASE_ROUTE_USER}/${url}`,
        state: {
          isFromVirtualClinic: isFromVirtualClinic
        }
      });
    }
  };

  const handleEvent = () => {
    const date = selectedDate;
    const evt = new CustomEvent('handleStatusCount', {detail: {date: date}});
    window.dispatchEvent(evt);
    window.dispatchEvent(new Event('handlingDisplay'));
  };

  const onProcess = (): void => {
    updateEncStatus(id)
      .then(res => {
        setProcessOpen(false);
        handleEvent();
        enqueueSnackbar(t(res.message), SnackBarConfig('s'));
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };

  const handlePersonCard = () => {
    AuthUtils.setIsPrescription(prescription?.length > 0);
    props?.handlePatientDetails(currPatient);
    props?.handleAppointmentDetails(
      currPatient?.id,
      props?.isVirtualAppointment,
      currPatient?.virtual_clinic_branch
    );
    setSelectedPatient(index);
  };

  const handlePdfViewer = (e: any) => {
    e.stopPropagation();
    setShowPdfFileDialog(true);
    setpdfUrl(CommonUtils.getFullImageUrl(prescription));
  };
  const closeShowPdfFileDialog = () => {
    setShowPdfFileDialog(false);
    setRefund(false);
    refund && handleEvent();
  };
  const renderPdfViewerDialog = (): React.ReactElement => {
    return (
      <PdfViewerDialog
        open={showPdfFileDialog}
        closeShowPdfFileDialog={closeShowPdfFileDialog}
        pdfURL={pdfUrl}
        title={refund ? 'Refund Receipt' : 'View Prescription'}
      />
    );
  };

  const handleBoth = (status?: any, vaccine?: any) => {
    const tempVacc = vaccine?.filter((i: any) => {
      return i?.service_group_name?.toLowerCase() == 'vaccination';
    });
    setPaymentStatus(status && tempVacc?.length > 0);
  };
  const handlePaymentStatus = (data: any, vac?: any): any => {
    const temp: any = [];
    data?.map((item: any) => {
      return (
        (item?.payment_status == 'Paid' || item?.pay_later) &&
        item?.prescription_status == 1 &&
        item?.service_group_name !== 'Consultation' &&
        temp.push(item)
      );
    });

    const newData = data?.filter((i: any) => {
      return i?.service_group_name !== 'Consultation';
    });

    handleBoth(newData?.length == temp?.length, temp);
    return data?.length == temp?.length;
  };

  const isVaccineAttachmentAdded = (data: any) => {
    const isAllAdded = data?.filter((i: any) => {
      return i?.attachments?.length > 0;
    });

    setVaccineAttachmentAdded(data?.length == isAllAdded?.length);
  };

  const handleGetVaccine = (status?: any): any => {
    let temp1;
    template_Id &&
      appointment_status === 'Completed' &&
      getVaccinesInformation(
        props?.endId,
        currPatient?.doc_id,
        healpha_id,
        template_Id
      )
        .then(res => {
          setVaccineData(res?.data?.services);
          status && handleBoth(status, res?.data?.services);
          temp1 = res?.data?.services;
          isVaccineAttachmentAdded(res?.data?.services);
          return temp1;
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
  };

  const handlePaidStatus = () => {
    const data = {hlp_id: healpha_id, branch_id, encounter_id: props?.endId};
    healpha_id &&
      branch_id &&
      props?.endId &&
      appointment_status === 'Completed' &&
      getServiceByEncounter(data)
        .then(res => {
          handlePaymentStatus(res?.data?.result?.services_in_registration);
        })
        .catch(err => {
          enqueueSnackbar(
            err?.message ? err.message : t('ERROR_ENCOUNTERED'),
            SnackBarConfig('e')
          );
        });
  };

  const init = () => {
    if (
      patientInfo?.appointment?.healpha_id.toString() == healpha_id.toString()
    ) {
      setClick(true);
    }
    appointment_status === 'Completed' &&
      !props.isFromPersonWeb &&
      handlePaidStatus();
    appointment_status === 'Completed' &&
      !props.isFromPersonWeb &&
      // paymentStatus &&
      handleGetVaccine();
  };

  React.useEffect(init, [healpha_id, encId]);

  const handleProvideVaccine = (e: any) => {
    e.stopPropagation();
    setProvideVaccinePassData({
      encId: props?.endId,
      docId: currPatient?.doc_id,
      personId: healpha_id,
      template: template_Id
    });
    setShowProvideVaccinePopUp(true);
    handleGetVaccine();
  };

  const renderStatusAndDot = (): React.ReactElement => {
    const bgColor = statusColor.filter(
      item => item.name === keyGiven || item.name === clickedStatus
    );
    const newDotColor = bgColor[0]?.dotColor;
    return (
      <Grid
        container
        spacing={0.25}
        alignItems="center"
        className="status-dot-grid">
        <Grid item>
          <Typography className="status-name">{appointment_status}</Typography>
        </Grid>

        <Grid
          item
          className="status-dot"
          style={{backgroundColor: newDotColor}}
        />
      </Grid>
    );
  };

  const handleClick = (event: any) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e?: any) => {
    e && e.stopPropagation();

    setAnchorEl(null);
  };

  const CancelAppointment = (e: any) => {
    cancelAppointment(cancelReason, id)
      .then(res => {
        setcancelReason('');
        setcancel(false);
        if (res?.data?.receipt) {
          setRefund(true);
          setShowPdfFileDialog(true);
          setpdfUrl(CommonUtils.getFullImageUrl(res?.data?.receipt));
        } else {
          handleEvent();
        }

        enqueueSnackbar(
          res?.message ? t(res.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('s')
        );
      })
      .catch(err => {
        enqueueSnackbar(
          err?.message ? t(err.message) : t('ERROR_ENCOUNTERED'),
          SnackBarConfig('e')
        );
      });
  };
  const handleCloseProcess = () => {
    setProcessOpen(false);
  };

  const ProcessModal = () => {
    return (
      <Dialog
        className="cancel-appointment-wrapper p-2"
        onClose={handleCloseProcess}
        aria-labelledby="simple-dialog-title"
        open={processOpen}>
        <div className="dialog">
          <DialogTitle id="simple-dialog-title">
            <Grid container xs={6} md={12} sm={12} className="addservice">
              <Grid item xs={8} md={10} sm={10} className="add fontback ml-0">
                <h5>
                  <b>{t('HAS_THE_PATIENT_ARRIVED_FOR_CONSULTATION?')}</b>
                </h5>
              </Grid>
              <Grid item xs={2} className="clos">
                <img
                  src={Close}
                  alt="close"
                  width={20}
                  height={20}
                  onClick={handleCloseProcess}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <Grid xs={8} md={10} sm={12} className="add-clik">
            <Typography>
              {t(
                'CLICKING_ON_YES_WILL_GENERATE_AN_ENCOUNTER_TO_CONTINUE_THE_CONSULTATION'
              )}
              .
            </Typography>
          </Grid>
        </div>
        <div className="add-bt ">
          <Button
            onClick={onProcess}
            variant="contained"
            className="text-nowrap mx-4 my-2 fontback btn-primary add-wt">
            {t('COMMON_YES')}
          </Button>
        </div>
        <div className="add-bt ">
          <Button
            onClick={handleCloseProcess}
            size="medium"
            color="primary"
            variant="outlined"
            className="text-nowrap my-2 mx-4 fontback add-wt">
            {t('TEMPLATE_CANCEL')}
          </Button>
        </div>
      </Dialog>
    );
  };

  const CancelAppointmentModal = () => {
    return (
      <Dialog
        className="cancel-appointment-wrapper p-2"
        // onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={cancel}>
        <div className="dialog">
          <DialogTitle id="simple-dialog-title">
            <div className="row d-flex addservice">
              <div className="col-sm-10 add fontback">
                <h6>
                  <b>{t('REASON')}</b>
                </h6>
              </div>
              <div className="col-sm-2 clos">
                <img
                  src={Close}
                  alt="close"
                  width={20}
                  height={20}
                  onClick={() => {
                    setcancel(false);
                    setcancelReason('');
                  }}
                />
              </div>
            </div>
          </DialogTitle>
          <div className="px-4 py-2 conform conformText">
            {/* <span>{t('ARE_YOU_SURE_WANT_TO_DELETE_SERVICES')}</span> */}
            <FormTextField
              name="reason"
              value={cancelReason}
              callback={(e: any) => {
                setcancelReason(e.target.value);
              }}
            />
          </div>
          <div className="px-4 py-2 conform">
            <Button
              onClick={CancelAppointment}
              variant="contained"
              className="text-nowrap mx-2 my-2 fontback btn-primary">
              {/* {t('delete')} */}
              {t('SUBMIT')}
            </Button>
            <Button
              onClick={() => {
                setcancel(false);
                setcancelReason('');
              }}
              color="primary"
              variant="outlined"
              className="text-nowrap my-2 mr-0 fontback">
              {t('TEMPLATE_CANCEL')}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const closeNewAppointmentBookingPopup = () => {
    setShowNewAppointmentBookingUpPopup(false);
  };

  const handleNewAppointmentBookingPopup = () => {
    setShowNewAppointmentBookingUpPopup(true);
  };

  const renderNewAppointmentBookingPopup = (): React.ReactElement => {
    return (
      <NewAppointmentBookingsComponent
        branchId={branch_id}
        open={showNewAppointmentBookingUpPopup}
        closeNewBookingsPopup={closeNewAppointmentBookingPopup}
        is_clinical_dashboard={true}
        selectedDate={selectedDate}
        isFromVirtualClinic={isFromVirtualClinic}
        docId={doc_id}
        healphaId={healpha_id}
        reschedule={true}
        appointment_id={id}
        enc_id={
          currPatient?.enc_version?.enc_id
            ? currPatient?.enc_version?.enc_id
            : ''
        }
        appointmentType={appointment_type}
        appointmentTypeStatus={appointmentTypeStatus}
      />
    );
  };
  return (
    <Grid spacing={3} id={'patient_card_' + index}>
      <Grid
        container
        spacing={0.25}
        alignItems="center"
        // className={[
        //   'patient-card-wrapper',
        //   selectedPatient === index && 'border-class'
        // ].join(' ')}
        className={[
          'patient-card-wrapper',
          patientInfo?.appointment?.healpha_id.toString() ==
            healpha_id.toString() && 'border-class'
        ].join(' ')}
        onClick={handlePersonCard}>
        <Grid item xs={2}>
          <Typography className="time">
            {dateOfApp
              ? CommonUtils.convertTo12Hours(dateOfApp)?.slice(0, -2)
              : ''}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className="name">{person_details?.full_name}</Typography>
        </Grid>
        <Grid item xs={4} container>
          <Grid item xs={10}>
            {renderStatusAndDot()}
          </Grid>
          {(isFromClinicalDashboard ||
            isStandAloneDoctor ||
            isFromVirtualClinic) &&
            !isNurse &&
            !isAdminNurseDashboard &&
            keyGiven !== AppointmentStatusTypes.CANCELLED && (
              <Grid item xs={2}>
                <Icons.KebabmenuIcon
                  onClick={handleClick}
                  className="kebab-icon"
                  id={'id_' + index}
                />
              </Grid>
            )}
        </Grid>
        <Grid item xs={2}>
          <Typography className="time1">
            {dateOfApp
              ? CommonUtils.convertTo12Hours(dateOfApp)?.slice(-2)
              : ''}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className="category">
            {isFromClinicalDashboard
              ? `${currPatient?.doctor_details?.salutation}.${currPatient?.doctor_details?.full_name}`
              : appointment_type}
          </Typography>
        </Grid>

        <Grid item xs={3} className="online-offline-grid">
          <Typography className="online-offline">
            {appointmentTypeStatus}
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={(e: any) => handleClose(e)}
            // className="pop-menu"
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.9,
                width: '21ch'
              }
            }}>
            {appointment_status === AppointmentStatusTypes.BOOKED ||
            appointment_status === AppointmentStatusTypes.CONFIRM ||
            appointment_status === AppointmentStatusTypes.PAID ||
            appointment_status === AppointmentStatusTypes.RESCHEDULED ? (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setcancel(true);
                }}>
                {t('TEMPLATE_CANCEL')}
              </MenuItem>
            ) : null}
            {appointment_status === AppointmentStatusTypes.BOOKED ||
            appointment_status === AppointmentStatusTypes.CONFIRM ||
            appointment_status === AppointmentStatusTypes.RESCHEDULED ||
            appointment_status === AppointmentStatusTypes.PAID ? (
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleNewAppointmentBookingPopup();
                }}>
                {t('RESCHEDULE')}
              </MenuItem>
            ) : null}
            {prescription?.length > 1 ? (
              <MenuItem
                onClick={e => {
                  handlePdfViewer(e);
                  handleClose();
                }}>
                {t('VIEW_PRESCRIPTION')}
              </MenuItem>
            ) : null}
            {appointment_status !== AppointmentStatusTypes.CANCELLED &&
            appointment_status !== AppointmentStatusTypes.CLOSED ? (
              <MenuItem
                onClick={() => {
                  appointment_status !== AppointmentStatusTypes.PAID
                    ? goToBilling()
                    : setProcessOpen(true);
                  handleClose();
                }}>
                {t('PROCESS')}
              </MenuItem>
            ) : null}
          </Menu>
        </Grid>
        {isFromVirtualClinic && <Grid item xs={2} />}
        {isFromVirtualClinic && (
          <Grid item xs={10}>
            <Typography className="category">
              {t('APPOINTMENT_WITH')} :
              <span className="doctor-name">
                {currPatient?.doctor_details?.full_name}
              </span>
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} display="flex">
          <Grid container textAlign="center">
            {!isFromClinicalDashboard && !paymentStatus && (
              <Grid xs={2}>{}</Grid>
            )}
            <Grid
              item
              xs={!paymentStatus ? 10 : 6}
              textAlign={!paymentStatus ? 'start' : 'center'}>
              {((!isFromViewMore &&
                !isFromClinicalDashboard &&
                prescription?.length > 1) ||
                ((isNurse || isAdminNurseDashboard) &&
                  prescription?.length > 1)) && (
                <Button
                  id={'view_prescription_' + index}
                  className={
                    !paymentStatus
                      ? 'prescription-button'
                      : 'prescription-button change-font-size'
                  }
                  startIcon={<Icons.PDFIcon />}
                  onClick={handlePdfViewer}>
                  {t('VIEW_PRESCRIPTION')}
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              {((!isFromViewMore &&
                !isFromClinicalDashboard &&
                // appointment_status == AppointmentStatusTypes.IN_PROCESS
                paymentStatus &&
                !vaccineAttachmentAdded) ||
                ((isNurse || isAdminNurseDashboard) &&
                  paymentStatus &&
                  !vaccineAttachmentAdded)) && (
                <Button
                  id={'provide-vaccine_' + index}
                  className={
                    !paymentStatus
                      ? 'provide-vaccine-button'
                      : 'provide-vaccine-button change-font-size'
                  }
                  startIcon={<Icons.VaccineIcon />}
                  onClick={handleProvideVaccine}>
                  {t('PROVIDE_VACCINE')}
                </Button>
              )}
            </Grid>
          </Grid>
          {keyGiven === AppointmentStatusTypes.PAID &&
          conditionNotForNormalDoc ? (
            <Grid item xs={6}>
              <Button
                id={'checkedin_' + index}
                className="provide-vaccine-button checkedin-button"
                onClick={() => setProcessOpen(true)}>
                {t('CHECKED_IN')}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6} className="billing-button-grid">
              {!isFromViewMore &&
                ((isFromClinicalDashboard &&
                  isFrontOffice &&
                  !isNurse &&
                  !isAdminNurseDashboard &&
                  keyGiven !== AppointmentStatusTypes.CANCELLED &&
                  keyGiven !== AppointmentStatusTypes.CLOSED) ||
                  (isFromVirtualClinic &&
                    !isNurse &&
                    !isAdminNurseDashboard &&
                    keyGiven === AppointmentStatusTypes.BOOKED) ||
                  (isStandAloneDoctor &&
                    !isNurse &&
                    !isAdminNurseDashboard &&
                    keyGiven !== AppointmentStatusTypes.CANCELLED &&
                    keyGiven !== AppointmentStatusTypes.CLOSED)) && (
                  <Button
                    id={'biiling_id_' + index}
                    className="provide-vaccine-button billing-button"
                    startIcon={<Icons.MakeBillIcon />}
                    onClick={goToBilling}>
                    {t('BILLING')}
                  </Button>
                )}
            </Grid>
          )}
        </Grid>
        {click && handlePersonCard()}
      </Grid>
      {processOpen && ProcessModal()}
      {cancel && CancelAppointmentModal()}
      {showNewAppointmentBookingUpPopup && renderNewAppointmentBookingPopup()}
      {showPdfFileDialog && renderPdfViewerDialog()}
    </Grid>
  );
};

export default PatientCard;
