import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as PreviousIconSvg} from '@assets/images/healpha/prescriptionIcon.svg';

const PrescriptionIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={PreviousIconSvg}
      viewBox="0 0 22.053 17.121"
    />
  );
};

export default PrescriptionIcon;
