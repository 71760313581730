/* eslint-disable camelcase */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {BASE_ROUTE_PORTAL} from 'src/constants/route/BaseRoute';
import NavRoutes from 'src/constants/route/NavRoutes';
import {getConsultationItem} from '@shared/services/PublicService';
import moment from 'moment';

const PersonPaymentRedirection = (props: any): React.ReactElement => {
  const history = useHistory();
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(props?.open);
  const [qrPayment, setQrPayment] = React.useState('');

  const handleClose = (): void => {
    setOpen(false);
    props?.closePopup();
  };

  const handleYes = () => {
    history.push(
      `/${BASE_ROUTE_PORTAL}/landing/${NavRoutes.PUBLIC.PAYMENT_SUCCESS}` +
        `?doctor_id=${props?.appointId?.doc_id}&branch_id=${
          props?.appointId?.branch_id
        }&is_booked=${true}&amount=${qrPayment}&type=qr_payment` +
        `&appointment_type=${props?.appointId?.appointment_type.toLowerCase()}` +
        `&appointment_date=${moment(
          props?.appointId?.appointment_date_start
        ).format('YYYY-MM-DD')}&hlp_id=${
          props?.appointId?.healpha_id
        }&appointment_time=${
          props?.appointId?.appointment_date_end.split(' ')[1]
        }` +
        `&is_person_web=${true}&is_online_appointment=${true}&appointment_id=${
          props?.appointId?.id
        }`
    );
    setOpen(false);
  };
  const init = () => {
    getConsultationItem(
      props?.appointId?.healpha_id,
      props?.appointId?.branch_id,
      props?.appointId?.doc_id,
      props?.appointId?.appointment_type.toLowerCase(),
      moment(props?.appointId?.appointment_date_start).format('YYYY-MM-DD'),
      '?',
      'General',
      false,
      '',
      ''
    ).then(res => {
      // eslint-disable-next-line no-console
      setQrPayment(res?.data?.consultation?.service_amount);
    });
  };
  React.useEffect(init, [props?.appointId]);

  const renderHeader = () => {
    return (
      <Typography className="class_heading">
        {`${t('YOU_HAVE_AN_APPOINTMENT_BOOKED_WITH_DOCTOR ')} ${
          props?.appointId?.doctor_details?.first_name
        } ${t('AT')} ${moment(props?.appointId?.appointment_date_start).format(
          'YYYY-MM-DD'
        )} ${props?.appointId?.appointment_date_end.split(' ')[1]} ${t(
          'PAY_AND_CHECK_IN_NOW'
        )}`}
      </Typography>
    );
  };

  const renderActionButtons = () => {
    return (
      <Grid className="ActionsBtn">
        <Button onClick={handleClose} className="can-btn">
          {t('NO')}
        </Button>
        <Button onClick={handleYes} className="add-btn">
          {t('COMMON_YES')}
        </Button>
      </Grid>
    );
  };

  return (
    <Dialog
      className="main-wrapper"
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
            maxHeight: '400px'
          }
        }
      }}>
      <DialogTitle>{renderHeader()}</DialogTitle>
      <DialogActions>{renderActionButtons()}</DialogActions>
    </Dialog>
  );
};

export default PersonPaymentRedirection;
