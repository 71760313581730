import React, {ReactElement} from 'react';
import BaseIcon from './BaseIcon';
import {ReactComponent as FamilyHistoryIconSvg} from '@assets/images/healpha/familyHistoryIcon.svg';

const FamilyHistoryIcon = (props: any): ReactElement => {
  const {className, ...rest} = props;
  return (
    <BaseIcon
      {...rest}
      className={className}
      component={FamilyHistoryIconSvg}
      viewBox="0 0 30 30"
    />
  );
};

export default FamilyHistoryIcon;
