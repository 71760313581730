/* eslint-disable camelcase */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';
import {Avatar, Grid, IconButton, Button, Typography} from '@material-ui/core';
import moment from 'moment';

import Icons from '@components/system/MyIcons';
import CommonUtils from 'src/utils/CommonUtils';
import './CommonDialogTitleComponent.scss';

const RegisterDialogTitleComponent = (props: any): React.ReactElement => {
  const {t} = useTranslation();

  const closePopup = (): any => {
    props?.close();
  };

  return (
    <Grid container spacing={2} className="common-dialog-title-wrapper">
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={isMobile ? 10 : 12}>
          <Typography className="appointment-header-text">
            {t('APPOINTMENT_DETAILS')}
          </Typography>
        </Grid>

        {isMobile && (
          <Grid item xs={2}>
            <IconButton
              style={{marginTop: '-1rem', marginLeft: '2rem'}}
              onClick={closePopup}>
              <Icons.CloseWhiteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {!isMobile && (
        <Grid
          item
          sm={12}
          container
          alignItems="center"
          justifyContent="center">
          <Avatar
            alt="profile-image"
            src={CommonUtils.getFullImageUrl(
              props?.appointmentValues?.doctor?.profile_image
            )}
            sx={{width: '7rem', height: '7rem'}}
          />
        </Grid>
      )}

      <Grid item sm={10} container direction="column" spacing={2}>
        <Grid item sm={12} container direction="column" spacing={2}>
          <Grid item sm={12}>
            <Typography className="doctor-name">
              {t(
                `Dr. ${CommonUtils.getFullName(
                  props?.appointmentValues?.doctor
                )}`
              )}
            </Typography>

            <Typography className="appointment-details">
              {`${moment(props?.appointmentValues?.calendarValue).format(
                'dddd'
              )}, 
              ${moment(props?.appointmentValues?.calendarValue).format('MMM')} 
              ${moment(props?.appointmentValues?.calendarValue).format(
                'D'
              )}  - ${props?.appointmentValues?.slotTime}, Consultation - Rs.${
                props?.appointmentValues?.appointment?.price
              }(${
                props?.appointmentValues?.appointment?.appointment_type_name
              })`}
            </Typography>

            <Typography className="appointment-details">
              {t(
                `${props?.appointmentValues?.branch?.branch_name}, ${props?.appointmentValues?.branch?.address1}`
              )}
            </Typography>

            {props?.showChangeIcon && (
              <Button
                disableElevation={true}
                variant="contained"
                size="small"
                style={{marginLeft: '-0.6rem'}}
                className="address-details"
                startIcon={<Icons.ChangeIcon />}
                onClick={closePopup}>
                {t('CHANGE')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegisterDialogTitleComponent;
